import React, { useState, useEffect, useCallback } from "react";
import _ from "lodash";
import Layout from "../../Layout";
import { BodyContent, Container } from "../../Common/GridSystem";
import TabCustom from "../../Common/TabCustom";
import BackNav, { BackCheck } from "../../Common/BackNav";
import { H2 } from "../../Common/Typography";
import SkillHeader from "./SkillHeader";
import TabPanel from "../../Common/TabPanel";
import useFullLoader from "../../../Hooks/useFullLoader";
import { AuthService } from "../../../services/AuthService";
import { SkillService } from "../../../services/SkillService";
import SkillContent from "./SkillContent";
import ModalPopup from "../../Common/ModalPopup";
import SkillUpdatePopup from "./SkillContent/SkillUpdatePopup";
import useAlert from "../../../Hooks/useAlert";
import { SortString, sortArrayWithDesireOrder, sortByDisableRow } from "../../../helpers/SortString";
import ConfirmationButton from "../../Common/ConfirmationButton";
import styled from "styled-components";
import { PrimaryButton } from "../../Common/Buttons/PrimaryButton";
import COLORS from "../../../assets/colors";
import useRoles from "../../../Hooks/useRoles";
import { SecondaryButton } from "../../Common/Buttons/SecondaryButton";
import { navigate, useLocation } from "@reach/router";
import { Images } from "../../../assets/images";
import { Videos } from "../../../assets/videos";
import { SkillStore, UIStore, skillMappingStore } from "../../../store/store_state";
import { Common } from "../../../store";
import ArchChart from "./SkillContent/ArchChart";
import MarqueeContent from "./SkillContent/MarqueeContent";
import ToolBar from "../../Common/ToolBar";
import CustomizedTables from "../../Common/CustomizedTables";
import SkillSelection from "../SkillSelection";
import { Inferred_Disprz_Skills_Table, Inferred_Non_Disprz_Skills_Table, } from "../SkillSelection/InferredSkillsContentTable";
import { ButtonBlock } from "../../Common/Buttons/ButtonBlock";
import useAuth from "../../../Hooks/useAuth";
import { FilterTableRowObject } from "../../../helpers/FilterTableRow";
import { AISuggestedSkills } from "../../../enums/AISuggestedSkills";
import { SkillMappingType } from "../../../enums/SkillMappingType";
import { SkillSourceType } from "../../../enums/SkillSourceType";
import { getOrDefault, isUserHasBulkImportAccess, isUserRestricted } from "../../../helpers/CommonHelper";
import { RoleServices } from "../../../services/RoleServices";
import RolesAndResponsibilitiesPopup from "../../Common/PopUp/RolesAndResponsibilitiesPopup/RolesAndResponisbilitiesPopup";
import text from "../../../locale.en.json";
import DownloadFile from "../../../helpers/DownloadFile";
import { UserType } from "../../../enums/UserType";
import { ExportProcessType } from "../../../enums/ExportProcessType";
import { ClientSkillSuggestionStatus } from "../../../enums/ClientSkillSuggestionStatus";
import { PersonaService } from "../../../services/PersonaService";
import { AlertType } from "../../../enums/AlertType";

const trendingIds = [2439, 2305];
const trendingJSONArray = [
  {
    skillId: 1009,
    skill: "Software Design",
    link: "/visual/arch_tech_modals/software_design.json",
  },
  {
    skillId: 914,
    skill: "Cloud Computing",
    link: "/visual/arch_tech_modals/cloud_technologies.json",
  },
  {
    skillId: 2780,
    skill: "API Design",
    link: "/visual/arch_tech_modals/api_design.json",
  },
];

const trendingSkills = [
  { skillId: 895, skill: "Agile Software Development", value: 150 },
  { skillId: 2763, skill: "API", value: 200 },
  { skillId: 1009, skill: "Software Design", value: 150 },
  { skillId: 2659, skill: "React", value: 125 },
  { skillId: 2654, skill: "Microsoft SQL Server", value: 40 },
  { skillId: 2652, skill: "Azure", value: 125 },
  { skillId: 2435, skill: "Emotional Intelligence", value: 100 },
  { skillId: 2429, skill: "Analytical Thinking and Reasoning", value: 50 },
];

 const inferredSkillsTabTitle = [text.skill.inferredSkills.withinDisprzRepository, text.skill.inferredSkills.outsideDisprzRepository];
 const inferresSkillsTabToolTipMessage = [text.skill.inferredSkills.tooltipMessage.withinDisprzRepository, text.skill.inferredSkills.tooltipMessage.outsideDisprzRepository];
 const inferredSkillsTable = [Inferred_Disprz_Skills_Table, Inferred_Non_Disprz_Skills_Table];
 const desiredOrder = [
 'INDUSTRY_ROLE' ,
 'ADD_SKILL',
 'SKILL_MATCH',
 'INFERRED_SKILL',
];
let allSkillIds = [];
let skillCategoryIds = {}
export default (props) => {
  //console.log(props)
  const { id, mode = '', roleId } = props;
  const { setFullLoader } = useFullLoader();
  const { showAlert } = useAlert();
  const { clientName } = useRoles();
  const location = useLocation();
  const { state } = location;
  const { importTokenId, personaName } = state;
  const isNavigatedFromRoleMatch = mode === 'role-matching';
  const { userDetails } = useAuth();
  const { User_Type } = userDetails;
  const importedSkillList = skillMappingStore.useState((s) => s.importedSkillList);
  const tabs = [  
    text.persona.architectSkills.finalSkillsList, 
    text.persona.architectSkills.skillMatch, 
    text.persona.architectSkills.inferredSkills
  ];
  const skillTabTitle = isUserHasBulkImportAccess(User_Type) ? tabs : tabs.slice(0, 1);

  const [clientDetail, setClientDetail] = useState(null);
  const [currentTab, setCurrentTab] = useState(0);
  const [personaID, setPersonaID] = useState(!!id ? Number(id) : Number(0));
  const [personaDetail, setPersonaDetail] = useState(null);
  const [newPersonaDetail, setNewPersonaDetail] = useState(null);
  const [industryroleDetail, setIndustryroleDetail] = useState(null);
  const [skillArray, setSkillArray] = useState(null);
  const [skillArrayCopy, setSkillArrayCopy] = useState(null);
  const [skillDetail, setSkillDetail] = useState(null);
  const [skillProficiency, setSkillProficiency] = useState(null);
  const [skillPopup, setSkillPopup] = useState(false);
  const [addSkillPopup, setAddSkillPopup] = useState(false);
  const [chartPopup, setChartPopup] = useState(false);
  const [chartPopupData, setChartPopupData] = useState(null);
  const [skillTabs, setSkillTabs] = useState([]);
  const [allskills, setAllskills] = useState(null);
  const [allPersonaList, setAllPersonaList] = useState(null);
  const [skillValueUpdated, setSkillValueUpdated] = useState(false);
  const [criticalList, setCriticalList] = useState([]);

  const [confirmPopup, setConfirmPopup] = useState(false);
  const [confirmTitle, setConfirmTitle] = useState("");
  const [confirmType, setConfirmType] = useState(null);
  const [noText, setNoText] = useState('No');
  const [yesText, setYesText] = useState('Yes');
  const [detailPopup, setDetailPopup] = useState(false);
  const [confirmNavigation, setConfirmNavigation] = useState("");
  const [confirmNavigationState, setConfirmNavigationState] = useState({});
  const [showVideoPopup, setShowVideoPopup] = useState(false);

  const [skillCurrentTab, setSkillCurrentTab] = useState(0);
  const [inferredSkillCurrentTab, setInferredSkillCurrentTab] = useState(0);

  const skillArrayOriginData = skillMappingStore.useState((s) => s.skillMatchData);
  const [clientSkillSelectionData, setClientSkillSelectionData] = useState([]);
  const [inferredSkillArray, setInferredSkillArray] = useState({
    [AISuggestedSkills.Disprz_Skills]: [],
    [AISuggestedSkills.Non_Disprz_Skills]: []
  });
  const [inferredSkillArrayCopy, setInferredSkillArrayCopy] = useState({
    [AISuggestedSkills.Disprz_Skills]: [],
    [AISuggestedSkills.Non_Disprz_Skills]: []
  });
  const [isSkillMappingInProgress, setIsSkillMappingInProgress] = useState(false);
  const [loading, setLoading] = useState(false);
  
  const exportToDisprzStatusData = JSON.parse(Common?.getExportData());
  const [exportStatusData, setExportStatusData] = useState(
    exportToDisprzStatusData
  );
  const [showDownload, setShowDownload] = useState(false);
  const [exportParserMeta, setExportParserMeta] = useState(null);
  const [roleSkillsForBulkPersona, setRoleSkillsForBulkPersona] = useState([]);
  
  const funSkillKey = "Functional Skills";

  const isUserValid = isUserHasBulkImportAccess(User_Type)

  const fetchSkillCategories = async () => {
    skillCategoryIds = {};
      const res = await SkillService.getSkillCategoriesV3();
      skillCategoryIds = res.reduce((acc, skill) => {
        acc[skill.skillCategory] = skill.skillCategoryId;
        return acc;
      }, {});
  };

  useEffect(() => {
    isNavigatedFromRoleMatch && fetchSkillCategories();
  }, [isNavigatedFromRoleMatch]);

  const getRoleSelectionStatus = async () => {
    const interval = setInterval(async () => {
      try {
        const excelStatus = await RoleServices.GetRoleSelectionDataExportExcelStatusV3(clientName, importTokenId, personaName);
        if (!!excelStatus && excelStatus.status === ExportProcessType.COMPLETED && !!excelStatus.fileUrl) {
          setShowDownload(true);
          setExportParserMeta(excelStatus);
          clearInterval(interval);
          setFullLoader(false);
        } else if (!!excelStatus && excelStatus.status !== ExportProcessType.IN_PROGRESS) {
          clearInterval(interval);
          setFullLoader(false);
          showAlert(excelStatus.errorMessage, "error", 2000);
        } 
      } catch (error) {
        console.error("Error in fetching data:", error);
        setFullLoader(false);
        clearInterval(interval);
      }
    }, 1000);
  }

  const fetchDataAndUpdateState = async (triggerExport = false) => {
    try {
      setFullLoader(true);
      if(triggerExport){
        await RoleServices.ExportRoleSelectionDataV3(clientName, importTokenId, personaName);
      }
      const initialExcelStatus = await RoleServices.GetRoleSelectionDataExportExcelStatusV3(clientName, importTokenId, personaName);
      if (!!initialExcelStatus && initialExcelStatus.status === ExportProcessType.COMPLETED && !!initialExcelStatus.fileUrl) {
        setShowDownload(true);
        setExportParserMeta(initialExcelStatus);
        setFullLoader(false);
      } else if (!!initialExcelStatus && initialExcelStatus.status !== ExportProcessType.IN_PROGRESS) {
        setFullLoader(false);
      } else if (!!initialExcelStatus && initialExcelStatus.status === ExportProcessType.IN_PROGRESS) {
        await getRoleSelectionStatus();
      }
    } catch (error) {
      console.error("Error fetching initial data:", error);
      setFullLoader(false);
    }
  };
  
  useEffect(() => {
    const restrictedUserTypes = [UserType.GENERAL_USER, UserType.GENERAL_USER_INTERNAL];
    if(isNavigatedFromRoleMatch && importTokenId && clientName && isUserRestricted(User_Type, restrictedUserTypes)){
      fetchDataAndUpdateState();
    }
  }, [importTokenId, clientName]);

  useEffect(() => {
    allSkillIds = [];
    if (skillArrayCopy) {
      allSkillIds = Object?.values(skillArrayCopy)
        .reduce((allIds, category) => allIds.concat(category?.map(skill => skill.skillId)), []);
    }
  },[skillArrayCopy]);

  const skillsSuggestionGenerationStatusAction = {
    [ClientSkillSuggestionStatus.COMPLETED]: async(intervalId) => {
      clearInterval(intervalId);
      await getImportSkillData("", {}, true);
    },
    [ClientSkillSuggestionStatus.NOT_STARTED]: async(intervalId) => {
      clearInterval(intervalId);
      setLoading(false)
    },
    [ClientSkillSuggestionStatus.IN_PROGRESS]: (intervalId) => setLoading(true)
  };

  const checkClientSkillSuggestionStatus = async() => {
    const intervalId = setInterval(async () => {
      try {
        let response = await SkillService.getClientSkillsSuggestionGenerationStatus(id);
        if (!response) {
          clearInterval(intervalId); // Stop the interval
          return;
        }
        await skillsSuggestionGenerationStatusAction[response.value](intervalId);

      } catch (error) {
        clearInterval(intervalId);
        setIsSkillMappingInProgress(false);
        setLoading(false);
      }
      
    }, 1000);
  }

  const getImportSkillData = async(groupSkillIds = "", newClientSkillData = {}, isSkillSuggestionGenerated = false) => {
    let currAllSkillIds = groupSkillIds ? groupSkillIds : allSkillIds
    try{
      let res, skillMappingResList;
      if (isNavigatedFromRoleMatch) {
        res = newClientSkillData;
        skillMappingResList = res.aiSuggestedSkills;
      } else if(isSkillSuggestionGenerated){
        res = await SkillService.GetClientSkillDataV3(id);
        skillMappingResList = res;
        setLoading(false);
        setIsSkillMappingInProgress(true);
      }
      else{
        await checkClientSkillSuggestionStatus();
      }
      if (!isNavigatedFromRoleMatch) {
        try {
          setLoading(true);
          var inferredSkills = await PersonaService.getInferredSkillsV3(id);
          if (skillMappingResList) {
            skillMappingResList.inferredSkillSuggestions = inferredSkills
          }else{
            skillMappingResList = {
              clientSKillAISuggestions: [],
              inferredSkillSuggestions: inferredSkills
            }
          }
        } catch (error) {
          showAlert(text.persona.architectSkills.inferredSkillsErrorMessage, AlertType.ERROR, 2000);
        }
        finally{
          setLoading(false);
        }
        
      }
      if(skillMappingResList){
         const Client_Skill_Data_array = skillMappingResList.clientSKillAISuggestions.map((data, index) => {
            const anySelected = data.skillSuggestions.some((recData) => recData.isSelected);
            const selectedRecData = data.skillSuggestions.find((recData) => recData.isSelected);
            
            const matchingSkillIds = currAllSkillIds.filter(skillId => {
              return data.skillSuggestions.some(recData => recData?.skillId === skillId && !recData?.isSelected);
            });            
            const initialSkillData = {
              clientId: index,
              clientSkill: data.clientSkill,
              selectedSkill: selectedRecData ? selectedRecData.skillName : '',
              selectedSkillId: selectedRecData ? selectedRecData.skillId : '',
              disableRow: anySelected && currAllSkillIds.includes(selectedRecData.skillId),
              idToDisable: matchingSkillIds,
              proficiencyId: selectedRecData?.proficiencyId || 0,
              proficiencyLevel: selectedRecData?.proficiencyLevel || null,
              selectedLevel: selectedRecData?.proficiencyLevel?.level || "",
              disableLevelDrop: !!selectedRecData ? false : true
            };
            data.skillSuggestions.forEach((recData, recDataIndex) => {
              initialSkillData[`skillMatch${recDataIndex + 1}`] = recData.skillName || '';
              initialSkillData[`skillScore${recDataIndex + 1}`] = `${recData.score}%` || '';
              initialSkillData[`skillId${recDataIndex + 1}`] = recData.skillId || '';
            });
          
            return initialSkillData;
        });

        const sortedClientSkillData = SortString([...Client_Skill_Data_array], 'clientSkill', 'asc');
        sortByDisableRow(sortedClientSkillData);

        skillMappingStore.update((s) => {
          s.importedSkillList = skillMappingResList; 
        })
        skillMappingStore.update((s) => {s.skillMatchData = sortedClientSkillData});
        setClientSkillSelectionData(sortedClientSkillData);
        let updatedDisprzSkillArray = [...skillMappingResList.inferredSkillSuggestions.skills];
        updatedDisprzSkillArray = updatedDisprzSkillArray.map((item, i) => {
            return {
                ...item,
                checked: item.isSelected,
                selectedLevel: item?.proficiencyLevel?.level || "",
                disableRow: currAllSkillIds.includes(item.skillId),
                disableLevelDrop: !!item.isSelected ? false : true
            };
        });

        const sortedDisprzSkills = SortString([...updatedDisprzSkillArray], 'skillName', 'asc');
        sortByDisableRow(sortedDisprzSkills)
        const sortedNonDisprzSkills = SortString([...skillMappingResList.inferredSkillSuggestions.nonExistingSkills], 'skillName', 'asc');
        setInferredSkillArray((prevSkillArray) => ({
          [AISuggestedSkills.Disprz_Skills]: [...sortedDisprzSkills],
          [AISuggestedSkills.Non_Disprz_Skills]: [...sortedNonDisprzSkills]
        }))
        setInferredSkillArrayCopy((prevSkillArray) => ({
          [AISuggestedSkills.Disprz_Skills]: [...sortedDisprzSkills],
          [AISuggestedSkills.Non_Disprz_Skills]: [...sortedNonDisprzSkills]
        }))
      }
      else{
        resetAiSuggestedSkillArrays();
      }
    }catch(error){
      console.log(error, "error", 2000);
    }
  };

  const resetAiSuggestedSkillArrays = () => {
    setClientSkillSelectionData([]);
    if (isNavigatedFromRoleMatch) {
      setInferredSkillArray({
        [AISuggestedSkills.Disprz_Skills]: [],
        [AISuggestedSkills.Non_Disprz_Skills]: []
      });
      setInferredSkillArrayCopy({
        [AISuggestedSkills.Disprz_Skills]: [],
        [AISuggestedSkills.Non_Disprz_Skills]: []
      })
    }
      skillMappingStore.update((s) => {
        s.importedSkillList = {};
        s.skillMatchData = [];
      })
      setIsSkillMappingInProgress(false);
  }

  const updateAssignSkill = (updatedSkillDetail, roleDetail, newClientSkillData = {}) => {
    
    setAllskills(
      [...updatedSkillDetail]?.map((el) => ({
        ...el,
        category: el.skillCategory.skillCategory,
      }))
    );

    const groupSkillCopy = _.groupBy(
      SortString(
        [...updatedSkillDetail]?.map((el) => ({
          ...el,
          category: el.skillCategory.skillCategory,
        })),
        "skillName"
      ),
      "category"
    );

    const groupSkill = {};

    Object.keys(groupSkillCopy).forEach(category => {
      // Sort the skills within each category in the desired order
      const sortedSkills = sortArrayWithDesireOrder(
        groupSkillCopy[category], 
        'skillAddedFrom', 
        desiredOrder, 
        false
      );

      // Assign the sorted skills to the corresponding category in the new object
      groupSkill[category] = sortedSkills;
    });
    
   
    setSkillTabs([
      funSkillKey,
      ...Object.keys(groupSkill)
        .filter((el) => el !== funSkillKey)
        .sort()
        .map((item) => item),
    ]);

    const groupSkillObjLen = Object.keys(groupSkill).length - 1;
    if(currentTab > 0 && groupSkillObjLen < currentTab){
      setCurrentTab(groupSkillObjLen);
    };

    setIndustryroleDetail(roleDetail);

    const groupSkillIds = Object.values(groupSkill)
    .filter(Boolean)
    .reduce((acc, categorySkills) => {
      return acc.concat(categorySkills.map(skill => {
        skill.initalProficiencyId = skill.proficiencyId;
        skill.initalproficiencyLevel = skill.proficiencyLevel
        return skill.skillId;
      }));
    }, []);


    setSkillArray(groupSkill);
    setSkillArrayCopy(groupSkill);
    
    if(isUserHasBulkImportAccess(User_Type) || isNavigatedFromRoleMatch){
      getImportSkillData(groupSkillIds, newClientSkillData);
    }
  }

  const handleSkillMatchingDone = async() => {
    setFullLoader(true);
    try{
      await SkillService.SaveSkillDataV3(id);
      resetAiSuggestedSkillArrays();
      setFullLoader(false);
    }catch(errors){
      console.log('errors', errors);
      setFullLoader(false);
    }
  };

  const getClientSkillSuggestionsToBeSaved = (isAddSkillTrigger, isSaveClientSkill, proficiencies) => {
    const updatedClientSkillSuggestions = importedSkillList.clientSKillAISuggestions?.map((data) => {
      const dataToUpdate = skillArrayOriginData.find((d) => d.clientSkill === data.clientSkill);
      const skillIdToBeRemoved = getSkillIdToBeRemoved(data.skillSuggestions, dataToUpdate);
      const updatedSkillSuggestions = updateSkillSuggestions(dataToUpdate, data.skillSuggestions, isAddSkillTrigger, isSaveClientSkill, proficiencies);
  
      if (shouldAddSearchedSkill(dataToUpdate, updatedSkillSuggestions)) {
        addSearchedSkill(dataToUpdate, updatedSkillSuggestions, proficiencies);
      }
  
      if (skillIdToBeRemoved) {
        removeSkillById(updatedSkillSuggestions, skillIdToBeRemoved);
      }
  
      return !!dataToUpdate ? { ...data, skillSuggestions: updatedSkillSuggestions } : null;
    }).filter(u => !!u);
    return updatedClientSkillSuggestions;
  }
  
  const getSkillIdToBeRemoved = (skillSuggestions, dataToUpdate) => {
    const skillToRemove = skillSuggestions?.find(item => 
        item?.isSearchedSkill && item?.isSelected && item.skillId !== dataToUpdate?.selectedSkillId
    );
    return skillToRemove ? skillToRemove.skillId : '';
  }
  
  const updateSkillSuggestions = (dataToUpdate, skillSuggestions, isAddSkillTrigger, isSaveClientSkill, proficiencies) => {
    return skillSuggestions?.map((item) => {
      const isSkillSelected = item.skillName === dataToUpdate?.selectedSkill;
      if (isSkillSelected && isNavigatedFromRoleMatch && isAddSkillTrigger && isSaveClientSkill) {
        proficiencies.push({
          skillId: dataToUpdate?.selectedSkillId,
          skillName: dataToUpdate?.selectedSkill,
          proficiencyId: dataToUpdate?.proficiencyId,
          skillCriticality: dataToUpdate?.skillCriticality || "",
          skillAddedFrom:  SkillMappingType.SKILL_MATCH,
          skillCategory: {skillCategory: item?.skillCategory},
          proficiencyLevel: dataToUpdate?.proficiencyLevel
        });
      }
      return {
        ...item,
        isSelected: isSkillSelected,
        proficiencyId: isSkillSelected ? dataToUpdate?.proficiencyId :  0,
        proficiencyLevel: isSkillSelected ? dataToUpdate?.proficiencyLevel :  null
      };
    });
  }
  
  const shouldAddSearchedSkill = (dataToUpdate, updatedSkillSuggestions) => {
    return dataToUpdate?.isSearchedSkill && !updatedSkillSuggestions.some((item) => item.skillId === dataToUpdate?.selectedSkillId);
  }
  
  const addSearchedSkill = (dataToUpdate, updatedSkillSuggestions, proficiencies) => {
    updatedSkillSuggestions.push({
      isSearchedSkill: true,
      isSelected: true,
      skillName: dataToUpdate?.selectedSkill,
      skillId: dataToUpdate?.selectedSkillId,
      skillCategory: dataToUpdate?.skillCategory?.skillCategory,
      skillDescription: dataToUpdate?.skillDescription,
      proficiencyId: dataToUpdate?.proficiencyId,
      proficiencyLevel: dataToUpdate?.proficiencyLevel
    });
    if(isNavigatedFromRoleMatch){
      proficiencies.push({
        skillId: dataToUpdate?.selectedSkillId,
        skillName: dataToUpdate?.selectedSkill,
        proficiencyId: dataToUpdate?.proficiencyId,
        skillCriticality: dataToUpdate?.skillCriticality || "",
        skillAddedFrom:  SkillMappingType.SKILL_MATCH,
        skillCategory: dataToUpdate?.skillCategory,
        proficiencyLevel: dataToUpdate?.proficiencyLevel
      });
    }
  }
  
  const removeSkillById = (updatedSkillSuggestions, skillIdToBeRemoved) => {
    return updatedSkillSuggestions.filter((item) => item.skillId !== skillIdToBeRemoved);
  }  

  const handlePartialSaveSkills = async (isAddSkillTrigger, aiSuggestedSkills) => {
    try {
      await SkillService.PartialSaveSkillDataV3(id, aiSuggestedSkills);
      if (!isAddSkillTrigger) {
        setFullLoader(false);
      }
      showAlert("Client Skills are updated partially", "success", 1500);
    } catch (errors) {
      console.log(errors);
      setFullLoader(false);
      showAlert(errors, "error", 2000);
    }
  }


// actions to perform update on personaskills and proficiencies
const skillActions = {
  deleteSkill: (deleteItem, proficiencies) => {
    proficiencies = proficiencies?.filter(skillObj => skillObj.skillId !== deleteItem.skillId) || [];
    return proficiencies;
  },
  updatePersonaProficiencies: (data, proficiencies, updateNewProficiencies, isDeleteSkillTrigger, industryRoleSkills) => {
    if (updateNewProficiencies && !isDeleteSkillTrigger) {
      const res = updateProficiencies(data, proficiencies, industryRoleSkills);
      proficiencies = res.proficiencies;
      industryRoleSkills = res.industryRoleSkills;
    }
    return {proficiencies, industryRoleSkills};
  },
};

// function to update personaskills and proficiencies
const updatePersonaSkillsAndProficiencies = (aiSuggestedSkills, data, isAddSkillTrigger, isDeleteSkillTrigger, deleteItem, proficiencies, updateNewProficiencies) => {
  if (data.personaName === personaName) {
    let dataProficiencies = getOrDefault(data?.proficiencies, []);
    let industryRoleSkills = getOrDefault(data?.industryRoleSkills, []);
    let excludedRoleSkills = getOrDefault(data?.excludedRoleSkills, []);
    data.aiSuggestedSkills = aiSuggestedSkills;
    if (isAddSkillTrigger) {
      dataProficiencies = [...dataProficiencies, ...proficiencies];
    }

    if (isDeleteSkillTrigger) {
      dataProficiencies = skillActions.deleteSkill(deleteItem, dataProficiencies);
      if(deleteItem.skillAddedFrom === SkillMappingType.INDUSTRY_ROLE && !!deleteItem?.industryProficiencyID){
        const skillIdsToBeExcluded = roleSkillsForBulkPersona.find((s) => s.skillId === deleteItem.skillId);
        excludedRoleSkills.push(skillIdsToBeExcluded);
        data.excludedRoleSkills = excludedRoleSkills;
        industryRoleSkills = industryRoleSkills.filter((s) => s.skillId !== skillIdsToBeExcluded.skillId)
      }
    }

    const res = skillActions.updatePersonaProficiencies(data, dataProficiencies, updateNewProficiencies, isDeleteSkillTrigger, industryRoleSkills);
    data.proficiencies = [...res.proficiencies];
    data.industryRoleSkills = [...res.industryRoleSkills]
  }
}

// function to update proficiencies
const updateProficiencies = (data, proficiencies, industryRoleSkills) => {
  const hasProficiency = data?.proficiencies && data.proficiencies.length > 0;
  const allSkills = [].concat(...Object.values(skillArrayCopy));

  for (const skill of allSkills) {
    if (!skill?.proficiencyLevel || !skill?.proficiencyId) {
      proficiencies = removeProficiencyAndSkill(data, proficiencies, skill, industryRoleSkills) || [];

      continue;
    }

    const existingProficiencyIndex = hasProficiency
      ? proficiencies.findIndex((item) => item.skillId === skill.skillId)
      : -1;

    if (existingProficiencyIndex === -1) {
      proficiencies.push(createProficiencyObject(skill));
      if(skill.skillAddedFrom === SkillMappingType.INDUSTRY_ROLE){
        industryRoleSkills = [...industryRoleSkills].filter((s) => s.skillId !== skill.skillId)
      }
    } 
    
    else if (!!proficiencies[existingProficiencyIndex]) {
      updateExistingProficiency(proficiencies, existingProficiencyIndex, skill);
    }
  }

  return {proficiencies , industryRoleSkills};
}

// function to create a proficiency object
const createProficiencyObject = (skill) => ({
  skillId: skill.skillId,
  skillName: skill.skillName,
  skillCategory: { skillCategoryId: skillCategoryIds[skill.category], skillCategory: skill.category },
  skillAddedFrom: skill.skillAddedFrom,
  proficiencyId: skill.proficiencyId,
  skillCriticality: skill.skillCriticality,
  proficiencyLevel: skill?.proficiencyLevel,
});

// function to update an existing proficiency
const updateExistingProficiency = (proficiencies, existingProficiencyIndex, skill) => {
  proficiencies[existingProficiencyIndex] = {
    ...proficiencies[existingProficiencyIndex],
    proficiencyLevel: skill?.proficiencyLevel,
    proficiencyId: skill?.proficiencyId,
    skillCriticality: skill?.skillCriticality,
  };
}

// function to remove proficiency and skill
const removeProficiencyAndSkill = (data, proficiencies, skill, industryRoleSkills) => {
  proficiencies = proficiencies.filter((item) => item.skillId !== skill.skillId);
  if(skill.skillAddedFrom === SkillMappingType.INDUSTRY_ROLE && !!industryRoleSkills && !industryRoleSkills?.some((s) => s.skillId === skill.skillId)){
    const industryRoleSkillToBeAdded = roleSkillsForBulkPersona.filter((s) => s.skillId === skill.skillId);
    industryRoleSkills.push(...industryRoleSkillToBeAdded);
  }
  return proficiencies;
}

// Main function
const getRoleSelectionDataToBeSaved = async(aiSuggestedSkills, isAddSkillTrigger, proficiencies, isDeleteSkillTrigger, deleteItem, updateNewProficiencies) => {
  const roleSelectionData = await RoleServices.getImportPersonaDetails(clientName);
  const updatedRoleSelectionDataToSave = roleSelectionData
    .map((data) => {
      const dataToUpdate = state?.roleSelectionOriginData.find((d) => d.clientRole === data.personaName);
      updatePersonaSkillsAndProficiencies(aiSuggestedSkills, data, isAddSkillTrigger, isDeleteSkillTrigger, deleteItem, proficiencies, updateNewProficiencies);
      return !!dataToUpdate ? data : null;
    })
    .filter(u => !!u);

  return updatedRoleSelectionDataToSave;
}

  const handlePartialSaveRoles = async(updatedRoleSelectionDataToSave, isAddSkillTrigger, isDeleteSkillTrigger, updateNewProficiencies) => {
    try {
      await RoleServices.partialSaveClientRoles(updatedRoleSelectionDataToSave, clientName, importTokenId);
      if (isAddSkillTrigger || isDeleteSkillTrigger || updateNewProficiencies) {
        await getNewClientSkillData();
      }
      setFullLoader(false);
    } catch (error) {
      console.log(error);
      setFullLoader(false);
      showAlert(error, "error", 2000);
    }
  }

  const handlePartialSave = async({
     isPartialSaveClientSkill = false,
     isSaveClientSkill = false,
     isPartialSaveInferredSkill = false,
     isSaveInferredSkill = false,
     isAddSkillTrigger = false, 
     isDeleteSkillTrigger = false, 
     deleteItem = {},
     updateNewProficiencies = false,
  }) => {
    setFullLoader(true);

    let aiSuggestedSkills = {
      clientSKillAISuggestions: [...importedSkillList.clientSKillAISuggestions],
      inferredSkillSuggestions:{
        skills: isPartialSaveClientSkill ? [...importedSkillList.inferredSkillSuggestions.skills] : [...inferredSkillArrayCopy[AISuggestedSkills.Disprz_Skills]],
        nonExistingSkills: isPartialSaveClientSkill ? [...importedSkillList.inferredSkillSuggestions.nonExistingSkills] : [...inferredSkillArrayCopy[AISuggestedSkills.Non_Disprz_Skills]]
      }
    }

    let proficiencies = [];

    if(isNavigatedFromRoleMatch && isAddSkillTrigger && isSaveInferredSkill){
      proficiencies = inferredSkillArrayCopy[AISuggestedSkills.Disprz_Skills]
      .filter((item) => !allSkillIds.includes(item?.skillId) && item?.isSelected && item?.proficiencyId)
      .map((item) => ({
        skillId: item?.skillId,
        skillName: item?.skillName,
        proficiencyId: item?.proficiencyId,
        skillCriticality: item?.skillCriticality,
        skillAddedFrom:  SkillMappingType.INFERRED_SKILL,
        skillCategory: {
          skillCategory: item?.skillCategory,
        },
        proficiencyLevel: item?.proficiencyLevel
      }));
    }

    if((isAddSkillTrigger || isPartialSaveClientSkill || isPartialSaveInferredSkill) && !isSaveInferredSkill){
      if(!isPartialSaveInferredSkill){
        let clientSKillAISuggestionsToBeSaved = getClientSkillSuggestionsToBeSaved(isAddSkillTrigger, isSaveClientSkill, proficiencies);
        aiSuggestedSkills.clientSKillAISuggestions = [...clientSKillAISuggestionsToBeSaved];
      }
      !isNavigatedFromRoleMatch && await handlePartialSaveSkills(isAddSkillTrigger, aiSuggestedSkills);
    }

    let newClientSkillData = {};
    if(isNavigatedFromRoleMatch){
        const updatedRoleSelectionDataToSave = await getRoleSelectionDataToBeSaved(aiSuggestedSkills, isAddSkillTrigger, proficiencies, isDeleteSkillTrigger, deleteItem, updateNewProficiencies);
        await handlePartialSaveRoles(updatedRoleSelectionDataToSave, isAddSkillTrigger, isDeleteSkillTrigger, updateNewProficiencies);
        newClientSkillData = await SkillService.newClientSkillDataV3({importTokenId: state.importTokenId, personaName: state.personaName, clientId: clientName, IsSkillMappingViewed: true})
    }  
    await getImportSkillData(allSkillIds, newClientSkillData);
  };

  const selectedDisprzSkills = [...inferredSkillArrayCopy[AISuggestedSkills.Disprz_Skills]]?.filter(
    (s) => s?.checked && !s?.disableRow
  );

  const handleInferredSkillSave = async () => {
    handlePartialSave({isPartialSaveInferredSkill: true, isAddSkillTrigger: false}) 
  }

  const createSkillDetail = (skillSuggestion, skillAddedFrom) => ({
    ...skillSuggestion,
    newSkill: false,
    updateRow: false,
    skillAddedFrom,
    skillCategory: { skillCategory: skillSuggestion.skillCategory },
    proficiencyId: '',
    proficiencyLevel: '',
    level: '',
    skillCriticality: '',
    industryProficiencyID: '',
    skillDescription: ''
  });

  const pushSkillDetails = (skillSuggestions, addedSkillData, skillMappingType, skillDetailModyfied, proficienciesData) => {
    skillDetailModyfied.push(
      ...skillSuggestions
        .filter(skillSuggestion =>
          skillSuggestion.isSelected &&
          addedSkillData?.includes(skillSuggestion.skillId) &&
          !proficienciesData?.some(item => item.skillId === skillSuggestion.skillId)
        )
        .map(skillSuggestion => createSkillDetail(skillSuggestion, skillMappingType))
    );
  }

  const getNewClientSkillData = async () => {
    setFullLoader(true);
    try{
      const roleDetail = await AuthService.getSkillListV3("/" + state.roleId);
      setIndustryroleDetail(roleDetail);
      const roleSkills = await RoleServices.GetSkillsForRoleIdV3(state.roleId, false, clientName);
      setRoleSkillsForBulkPersona(roleSkills);
      let tempPersonaDetail = {
        personaName: personaName, 
        leadershipRole: roleDetail.leadershipRole, 
        reportingTo: ''
      };
      setNewPersonaDetail(tempPersonaDetail);
      
      let skillDetailModyfied = [];

      let postData = {importTokenId: importTokenId, personaName: personaName, clientId: clientName, IsSkillMappingViewed: true}
      const newClientSkillData = await SkillService.newClientSkillDataV3(postData);
      let skillMatchData = newClientSkillData?.aiSuggestedSkills?.clientSKillAISuggestions;
      let inferredDisprzSkillsData =  newClientSkillData?.aiSuggestedSkills?.inferredSkillSuggestions?.skills;
      let addedSkillMatchData = getOrDefault(newClientSkillData?.personaSkills?.skillMatchSkills, []);
      let addedInferredSkillData = getOrDefault(newClientSkillData?.personaSkills?.inferredSkills, []);
      let proficienciesData = getOrDefault(newClientSkillData?.proficiencies, []);
      let industryRoleSkills = getOrDefault(newClientSkillData?.industryRoleSkills, []);

      pushSkillDetails(skillMatchData.flatMap(clientSkill => clientSkill.skillSuggestions), addedSkillMatchData, SkillMappingType.SKILL_MATCH, skillDetailModyfied, proficienciesData);
      pushSkillDetails(inferredDisprzSkillsData, addedInferredSkillData, SkillMappingType.INFERRED_SKILL, skillDetailModyfied, proficienciesData);

      skillDetailModyfied.push(
        ...proficienciesData.map(skillSuggestion => ({
            ...createSkillDetail(skillSuggestion, skillSuggestion.skillAddedFrom),
            proficiencyLevel: skillSuggestion?.proficiencyLevel,
            skillCriticality: skillSuggestion?.skillCriticality,
            skillCategory: { skillCategory: skillSuggestion.skillCategory.skillCategory },
            proficiencyId: skillSuggestion?.proficiencyId,
        }))
      )

      const skillsForIndustryRoles = await AuthService.getSkillListV3("/" + state.roleId + "/skills");
      skillsForIndustryRoles.forEach((el) => {
        let proficiencyLevelAddedElement = skillDetailModyfied.find((item) => item.skillId === el.skillId && item.skillAddedFrom === SkillMappingType.INDUSTRY_ROLE);
        if (proficiencyLevelAddedElement) {
          const index = skillDetailModyfied.findIndex((item) => item.skillId === el.skillId);
          const updatedSkill = {
            ...el,
            newSkill: false,
            updateRow: false,
            level: el.proficiencyLevel,
            industryProficiencyID: el.proficiencyId,
            proficiencyId: proficiencyLevelAddedElement.proficiencyId,
            proficiencyLevel: proficiencyLevelAddedElement.proficiencyLevel,
            skillCriticality: proficiencyLevelAddedElement.skillCriticality
          }
          if(index !== -1){
            skillDetailModyfied[index] = updatedSkill;
          }
        }
      });
      setFullLoader(false);
      let modifiedIndustryRoleSkills = industryRoleSkills.map((skill) => ({
        ...skill,
        newSkill: false,
        updateRow: false,
        level: skill.proficiencyLevel,
        industryProficiencyID: skill.proficiencyId,
        proficiencyLevel: "",
      }));
      skillDetailModyfied = [...skillDetailModyfied, ...modifiedIndustryRoleSkills];
      updateAssignSkill(skillDetailModyfied, roleDetail, newClientSkillData);
    } catch (error) {
      setFullLoader(false);
      console.log('error', error);
      showAlert(error, 'error', 2000);
    }
  }

  const getOldClientSkillData = async () => {
    const resList = await AuthService.getPersonasListV3(
      `?clientId=${clientName}`
    );
    const personaListArrange = SortString(
      resList.map((el) => ({
        ...el,
        personaGroupName: el.personaGroup.personaGroupName,
      })),
      "personaGroupName"
    );

    const perList = personaListArrange?.map((el) => ({
      label: el.personaName,
      value: el.personaId,
      groupName: el.personaGroupName,
    }));

    setAllPersonaList(perList);
    const detail = await AuthService.getPersonaDetailV3("/" + Number(id));
    setPersonaDetail(detail);
    getIndustryRole(detail, Number(id));
  }

  useEffect(() => {
    if (!!clientName) {
      getClientDetail();
      getDetails();
    }
    return () => {
      setPersonaDetail(null);
      SkillStore.update((s) => {
        s.skillAdded = false;
      });
      setCurrentTab(0);
      resetAiSuggestedSkillArrays();
    };
  }, [id, clientName, roleId]);

  const getClientDetail = async () => {
    try {
      const res = await AuthService.getClientDetailV3(clientName);
      setClientDetail(res);
    } catch (error) {
      console.log(error);
    }
  };

  const getDetails = async () => {
    try { 
      !isNavigatedFromRoleMatch ? await getOldClientSkillData() : await getNewClientSkillData();
      const skillCriticalityList = await SkillService.getSkillCriticalityListV3();
      setCriticalList([
        ...skillCriticalityList.map((el) => ({ label: el, value: el })),
      ]);
    } catch (error) {
      showAlert(error.error, "error", 1500);
    }
  };

  const getIndustryRole = async (detail, perID) => {
    setFullLoader(true);
    try {
      const roleDetail = await AuthService.getSkillListV3(
        "/" + detail.industryRole.roleId
      );
      const roleSkill = await AuthService.getSkillListV3(
        "/" + detail.industryRole.roleId + `/persona-skills?personaId=${personaID}&clientId=${clientName}`
      );

      const personaSkill = await AuthService.getPersonaSkillsV3(perID);
      setFullLoader(false);
      let roleDetailModyfied = roleSkill.map((el) => ({
        ...el,
        newSkill: false,
        updateRow: false,
        level: el.proficiencyLevel,
        industryProficiencyID: el.proficiencyId,
        proficiencyLevel: "",
      }));

      roleSkill.map((el, i) =>
        personaSkill.forEach((elem) => {
          if (el.skillId === elem.skillId) {
            roleDetailModyfied[i].proficiencyLevel = elem.proficiencyLevel;
            roleDetailModyfied[i].skillCriticality = elem.skillCriticality;
            roleDetailModyfied[i].proficiencyId = elem.proficiencyId;
          }
        })
      );

      const missingPersonasSkill = personaSkill.filter(function (cv) {
        return !roleSkill.find(function (e) {
          return e.skillId === cv.skillId;
        });
      });

      const skillDetail = [...roleDetailModyfied, ...missingPersonasSkill];
      updateAssignSkill(skillDetail, roleDetail);
    } catch (error) {
      console.log(error);
      setFullLoader(false);
    } finally {
      setFullLoader(false);
    }
  };

  const handleTab = (val) => setCurrentTab(val);

  const changePersonaForIndividualScreen = (val) => {
    const targetUrl = "/persona/architect-skills/" + val.value;
    if (!skillValueUpdated) {
      setPersonaID(val.value);
      navigate(targetUrl);
    } else {
      navigateConfirmTrigger(targetUrl);
    }
  }

  const changePersonaForBulkScreen = (val) => {
    const role = state?.roleSelectionOriginData?.find((r) => r.clientRole === val.label);
    const targetUrl = `/personas/role-matching/architect-skills/` + role?.selectedRoleId;
    const targetUrlState = { 
      state: { 
        roleId: role?.selectedRoleId, 
        personaName: role?.clientRole, 
        importTokenId: state?.importTokenId, 
        roleSelectionOriginData: state?.roleSelectionOriginData, 
        roleSelectionList: state?.roleSelectionList
      } 
    }
    if(!skillValueUpdated) navigate(targetUrl, targetUrlState);
    else navigateConfirmTrigger(targetUrl, targetUrlState);
  }

  const handlePersonaChange = (val) => {
    if(isNavigatedFromRoleMatch)  changePersonaForBulkScreen(val);
    else changePersonaForIndividualScreen(val);
  };

  const handleCompany = useCallback(
    (e, item, index) => {
      updateSkillValue(
        item,
        e.target.value.replace(/^\s+/g, ""),
        "client_baseline_proficiency"
      );
    },
    [skillArray]
  );

  const handlecompanyBlur = useCallback(
    (e, item, index) => {
      updateSkillValue(
        item,
        e.target?.value?.trim(),
        "client_baseline_proficiency"
      );
    },
    [skillArray]
  );

  const handleRecommend = async (val, item, reason) => {
    const proObject = {
      level: getOrDefault(val?.level, ''),
      levelId: getOrDefault(val?.levelId, 0),
      levelNumber: getOrDefault(val?.levelNumber, 0)
    };
    updateSkillValue(
      {
        ...val,
        skillId: item.skillId,
        skillCategory: item.skillCategory,
        proficiencyId: val.proficiencyId,
        notSaved: true,
      },
      proObject,
      "proficiencyLevel"
    );
  };

  const handleCriticalChange = (val, item, reason) => {
    // console.log(val, "val", !!val, item, reason);
    updateSkillValue(
      {
        ...item,
        skillCriticality: val?.value || "",
        proficiencyId: item.proficiencyId,
      },
      val?.value || "",
      "skillCriticality"
    );
  };

  // updating skill based on category and skill ID
  const updateSkillValue = (item, val, objKey) => {
    // console.log(item, val, objKey);
    const skillCric = "skillCriticality";
    const category = item.skillCategory.skillCategory;
  
    const updateSkillArray = (sourceArray) => {
      const res = Object.keys(sourceArray).map((elem) => {
        if (category === elem) {
          return sourceArray[category].map((el) =>
            el.skillId === item.skillId
              ? !val && objKey === "proficiencyLevel"
                ? {
                    ...el,
                    [objKey]: val,
                    proficiencyLevel: "",
                    proficiencyId: "",
                    [skillCric]: "",
                    updateRow: true,
                  }
                : {
                    ...el,
                    [objKey]: val,
                    proficiencyId: item.proficiencyId,
                    updateRow: true,
                  }
              : el
          );
        }
        return sourceArray[elem];
      });
      return res;
    };
  
    const updatedSkillArray = updateSkillArray(skillArray);
    const updatedSkillArrayCopy = updateSkillArray(skillArrayCopy);
  
    const groupSkill = _.groupBy(updatedSkillArray.flat(Infinity), "category");
    const groupSkillCopy = _.groupBy(updatedSkillArrayCopy.flat(Infinity), "category");
  
    setSkillArray(groupSkill);
    setSkillArrayCopy(groupSkillCopy);
  };

  useEffect(() => {
    // Skill value updated check
    const listActive = document.querySelector("li.active");
    if (!!skillArray) {
      UIStore.update((s) => {
        s.architectUpdate = !!listActive;
      });
      setSkillValueUpdated(!!listActive);
    }
  }, [skillArray]);

  // adding new skill based on category
  const updateSkillArray = (item) => {
    const category = item.category;
    const res = Object.keys(skillArray).map((elem) => {
      if (category === elem) {
        return (skillArray[category] = [...skillArray[elem], item]);
      }
      return skillArray[elem];
    });
    const groupSkill = _.groupBy(res.flat(Infinity), "category");
    setSkillArray(groupSkill);
    setSkillArrayCopy(groupSkill);
  };

  const deleteSkillFromSkillList = async(skill) => {
    skill?.initalProficiencyId 
      ? await SkillService.DeleteSkillProficiencyV3(id, skill.initalProficiencyId) 
      : await SkillService.DeleteSkillFromSkillListV3(id, skill?.skillId, false);
  }

  // remove skill based on category and skill ID
  const handleCancelSkill = async (item, index) => {
    if(item.skillAddedFrom === SkillMappingType.INDUSTRY_ROLE && !isNavigatedFromRoleMatch) {
      await SkillService.DeleteSkillFromSkillListV3(id, item?.skillId, true);
      await triggerGetIndustryRole();
      return;
    }
    const category = item.category;
    const res = Object.keys(skillArray).map((elem) => {
      if (category === elem) {
        return skillArray[category].filter((el) => el.skillId !== item.skillId);
      }
      return skillArray[elem];
    });
    await setFullLoader(true);
    try {
      const groupSkill = _.groupBy(res.flat(Infinity), "category");

      if(!isNavigatedFromRoleMatch){
        await deleteSkillFromSkillList(item);
        await triggerGetIndustryRole();
      }
      else{
        await handlePartialSave({isDeleteSkillTrigger:true, deleteItem: item});
        setSkillArray(groupSkill);
        setSkillArrayCopy(groupSkill);
      }
      if(!groupSkill[category] && currentTab > 0){
        setCurrentTab(currentTab - 1);
      }
      showAlert("Skill deleted successfully", "success", 2000);
    } catch (error) {
      console.error("Error in handleCancelSkill:", error);
      showAlert(error, "error", 1500);
      setFullLoader(false);
    }
  };

  //sort skill based on skillName
  const handleSkillSort = (items, ascending = true) => {
    if(items?.length === 0){
      return;
    }
    const sortedArray = SortString(skillArray[items[0]?.category], 'skillName', !ascending ? "asc" : "desc");
    setSkillArray({ ...skillArray, [items[0]?.category]: [...sortedArray] });
  };

  //sort skill based on source
  const handleSourceSort = (items, ascending = true) => {
    if(items?.length === 0){
      return;
    }
    const sortedArray = sortArrayWithDesireOrder(skillArray[items[0]?.category], 'skillAddedFrom', desiredOrder, ascending);
    setSkillArray({ ...skillArray, [items[0]?.category]: [...sortedArray] });
  };

  const handleFilter = (item, reason, category) => {
    if(reason === 'clear'){
      setSkillArray(skillArrayCopy);
      return;
     }
     const filteredObjects = skillArrayCopy[category].filter((skill) => SkillSourceType[skill.skillAddedFrom] === item.value);
     setSkillArray({ ...skillArray, [category]: filteredObjects });
  }
  
  const handleAddClick = () =>{
    const targetUrl = isNavigatedFromRoleMatch
                        ? (`/role/${state?.roleId}/recommended_proficiency/skills`)
                        : `/role/${personaDetail?.industryRole?.roleId}/persona/skills/${personaDetail?.personaId}/${encodeURIComponent(personaDetail?.personaGroup?.personaGroupId)}`;
    const baseTargetUrlState = {isExcludeSkills: true, skillIdsToBeExcluded: allSkillIds, skillsMappedToPersona: getAssignSkillsCombinedData(), showIndustryRoleSkills: true };
    const targetUrlState = isNavigatedFromRoleMatch
                            ? {
                                isNavigatedFromRoleMatch,
                                importTokenId: importTokenId,
                                personaName: personaName,
                                isNavigatedFromArchitectSkills: true,
                                ...baseTargetUrlState
                              }
                            : baseTargetUrlState;
    skillValueUpdated ? navigateConfirmTrigger(targetUrl, {state: targetUrlState}) : navigate(targetUrl, {state: targetUrlState});
 }

  const handleChart = (val) => {
    setChartPopup(true);
    setChartPopupData(val);
  };

  const handleSkillPopup = async (skillId, row) => {
    // setFullLoader(true);
    try {
      const resDetail = await SkillService.getSkillDetailV3(
        "/" + skillId,
        "/?includeProficiencies=true"
      );
      setSkillDetail(resDetail);
      const resProficiency = await SkillService.getSkillTypesArrayV3(skillId, "proficiencies");
      setSkillProficiency(SortString(resProficiency, "levelNumber"));
      setSkillPopup(true);
    } catch (errors) {
      console.log(errors);
      setFullLoader(false);
    } finally {
      setFullLoader(false);
    }
  };

  const skillPopupClose = () => {
    setSkillPopup(false);
    setAddSkillPopup(false);
    setChartPopup(false);
  };

  const handleYes = async () => {
    await closePopup();
    confirmSwitch(confirmType);
  };

  const confirmSwitch = (type) => {
    switch (type) {
      case "update":
        return setTimeout(() => handleUpdate(), 1000);
      case "redirect":
        return navigate(confirmNavigation, confirmNavigationState);
      case "back":
        return navigate(-1);
      case "export":
        return handleExportToDisprz();
      case "clearMapping":
        return clearMappingClick();
      case "add inferred skill":
        return setTimeout(() => handleAddDisprzSkills(), 500);
      default:
        return closePopup();
    }
  };

  const handleNo = () => closePopup();

  const closePopup = () => {
    setConfirmPopup(false);
    setConfirmTitle("");
    setConfirmType(null);
    setNoText('No');
    setYesText('Yes');
  };

  const navigateConfirmTrigger = (navUrl, state={}) => {
    setConfirmTitle("Proceed without Saving Changes?");
    setConfirmPopup(true);
    setConfirmType("redirect");
    setConfirmNavigation(navUrl);
    setConfirmNavigationState(state);
  };

  const handleCheckBack = () => {
    setConfirmTitle("Proceed without Saving Changes?");
    setConfirmPopup(true);
    setConfirmType("back");
  };

  const handleSaveColumns = () => {
    setConfirmTitle("Confirm Changes");
    setConfirmPopup(true);
    setConfirmType("update");
  };

  const handleExportToDisprzTrigger = () => {
    setConfirmTitle(`Confirm Persona Export to Disprz`);
    setConfirmPopup(true);
    setConfirmType("export");
  };

  const handleClearMapping = () => {
    const isMappingAvailable = Object.values(skillArray).flat(Infinity).some(el => 
      isUserValid 
        ? !!el.proficiencyLevel 
        : el?.skillAddedFrom !== SkillMappingType.SKILL_MATCH && el?.skillAddedFrom !== SkillMappingType.INFERRED_SKILL && !!el.proficiencyLevel
    );
    if (
      isMappingAvailable
    ) {
      setConfirmTitle(
        `<p style="font-size:14px;">This action will clear all the skill proficiency and criticality mappings for the Persona</p> Do you wish to Proceed?`
      );
      setConfirmPopup(true);
      setConfirmType("clearMapping");
    } else {
      showAlert("No mapping available to clear!", "info", 1500);
    }
  };

  const handleInferredSkillMapping = () => {
    if(!selectedDisprzSkills.every((skill) => !!skill?.proficiencyId)){
      showAlert("please select levels for choosen skills", "info", 1500);
      return;
    }
    setConfirmPopup(true);
    setConfirmTitle(`Selected #${selectedSkillData?.length} Skill${selectedSkillData?.length > 1 ? "s" : ""} will be mapped to Skill List`);
    setConfirmType("add inferred skill");
    setNoText('Cancel');
    setYesText('Proceed');
  };

  const getAssignSkillsCombinedData = (item = {}) => {
    return Object.keys(skillArrayCopy)
    .reduce((result, el) => {
      const filteredSkills = skillArrayCopy[el].filter((el) => !!el.proficiencyId && !!el.proficiencyLevel && el.skillId !== item?.skillId);
      const skillsToAdd = filteredSkills.map(({ proficiencyId, skillCriticality, skillAddedFrom }) => ({
        proficiencyId,
        skillCriticality,
        skillAddedFrom
      }));
      const skillIdsToRemove = filteredSkills.map(({ skillId }) => skillId);

      return {
        skillProficiencyMappings: result.skillProficiencyMappings.concat(skillsToAdd),
        skillIdsToRemoveFromPersona: result.skillIdsToRemoveFromPersona.concat(skillIdsToRemove),
        ExcludedSkillIds: []
      };
    }, { skillProficiencyMappings: [], skillIdsToRemoveFromPersona: [], ExcludedSkillIds: [] });

  }

  const handleUpdate = async (item = {}) => {
    if(isNavigatedFromRoleMatch){
      handlePartialSave({updateNewProficiencies: true});
      return;
    }
    setFullLoader(true);
    const combinedData = getAssignSkillsCombinedData(item);

    try {
      await AuthService.updatePersonaSkillV3(personaID, combinedData);
      await triggerGetIndustryRole();
      await setFullLoader(false);
      await showAlert("Skill Mapping for Persona successful", "success", 1500);
      document.getElementsByTagName("body")[0].style = "";
    } catch (errors) {
      showAlert(errors, "error", 1500);
      setFullLoader(false);
      console.log(errors);
    }
  };

  const getCurrentItem = (val) => {
    const category = val.skillCategory;
    const selectTabIndex = skillTabs.indexOf(category);

    const skiillArr =
      selectTabIndex !== -1 ? skillArray : { ...skillArray, [category]: [val] };
    setSkillArray(
      selectTabIndex !== -1 ? skillArray : { ...skillArray, [category]: [] }
    );
    setSkillArrayCopy(
      selectTabIndex !== -1 ? skillArray : { ...skillArray, [category]: [] }
    )
    setSkillTabs(Object.keys(skiillArr));

    setCurrentTab(
      selectTabIndex !== -1
        ? selectTabIndex
        : [
            funSkillKey,
            ...Object.keys(skiillArr)
              .filter((el) => el !== funSkillKey)
              .sort(),
          ].indexOf(category)
    );

    const arr = Object.keys(skiillArr)
      .map((el) => {
        return skiillArr[el].map((elem) => ({ ...elem, newSkill: false }));
      })
      .flat(Infinity);

    if (
      arr.some((el) => el.skill === val.skillName || el.skill === val.skill) &&
      selectTabIndex !== -1
    ) {
      showAlert(val.skillName + " skill already added!", "info", 1500);
    }
  };

  // just copy industry level value to recommendation column
  const handleDefaultReccomend = () => {
    const res = Object.values(skillArray)
      .flat(Infinity)
      .map((el) =>
        !!el.industryProficiencyID
          ? {
              ...el,
              proficiencyLevel: el.level,
              proficiencyId: el.industryProficiencyID,
              updateRow: true,
            }
          : el
      );

    const groupSkill = _.groupBy(res.flat(Infinity), "category");
    setSkillArray(groupSkill);
    setSkillArrayCopy(groupSkill);
    showAlert(
      "Recommended Proficiency for all skills defaulted to Industry Proficiency",
      "info",
      2500
    );
  };

  // console.log({ personaDetail });
  const handleOpenCareer = () => {
    skillValueUpdated
      ? navigateConfirmTrigger(
          `/career-progress/${personaDetail?.industryRole?.roleId}`
        )
      : navigate(`/career-progress/${personaDetail?.industryRole?.roleId}`);
  };

  const handleExportToDisprz = async () => {
    if (!!personaDetail.personaId) {
      try {
        if (exportStatusData === null) {
          //if prev export don't have, create one
          ExportAPITrigger([personaDetail.personaId]);
        } else {
          if (exportStatusData.processStatus < 3) {
            try {
              const resExportStatus = await AuthService.personasExportToDisprzStatusV3(
                exportStatusData
              );
              if (resExportStatus.processStatus > 2) {
                //if prev export is pass or fail
                ExportAPITrigger([personaDetail.personaId]);
              } else {
                //if prev export is start process or pending
                showAlert(resExportStatus.statusMessage, "info", 2000);
                Common.setExportData(resExportStatus);
                setExportStatusData(resExportStatus);
              }
            } catch (errors) {
              console.log(errors);
            }
          } else {
            //if prev export is pass or fail
            ExportAPITrigger([personaDetail.personaId]);
          }
        }
      } catch (errors) {
        showAlert(errors, "error", 2000);
      } finally {
        setFullLoader(false);
      }
    } else {
      showAlert("Select Personas to export to Disprz", "info", 2000);
    }
  };

  const ExportAPITrigger = async (personaId) => {
    try {
      var data = {
        personaIds: personaId, 
        excludePersonas: false,
        personaGroupIds: []
      }
      const resExport = await AuthService.personasExportToDisprzV3(clientName, data);
      const resExportStatus = await AuthService.personasExportToDisprzStatusV3(resExport);
      showAlert(resExportStatus.statusMessage, "info", 2000);
      Common.setExportData(resExportStatus);
      setExportStatusData(resExportStatus);
    } catch (errors) {
      console.log(errors);
    } finally {
    }
  };

  const VideoModal = () => (
      <ModalPopup
          open={showVideoPopup}
          maxWidth="lg"
          title={'Assign Skills for Client Persona (Job Roles)'}
          center={true}
          closePopup={() => setShowVideoPopup(false)}
      >
        <video src={Videos.Assign_Skills_Persona} width="100%" height="100%" controls />
      </ModalPopup>
  );

  const getClearSkillCondition = (skill) => {
    if(isUserValid){
      return true;
    }
    return (skill.skillAddedFrom !== SkillMappingType.SKILL_MATCH && skill.skillAddedFrom !== SkillMappingType.INFERRED_SKILL)
  }

  const clearMappingClick = () => {
    const res = Object.values(skillArray)
      .flat(Infinity)
      .map((el) =>
        getClearSkillCondition(el) && (el?.proficiencyLevel !== "" || el?.proficiencyId !== 0)
          ? {
              ...el,
              proficiencyLevel: "",
              proficiencyId: 0,
              skillCriticality: "",
            }
          : el
      );

    const groupSkill = _.groupBy(res.flat(Infinity), "category");
    setSkillArray(groupSkill);
    setSkillArrayCopy(groupSkill);
  };

  const getSkillTab = (val) => setSkillCurrentTab(val);
  const getInferredSkillTab = (val) => setInferredSkillCurrentTab(val);

  const getSelectedInferredSkills = ({isCheckAll, array, isChecked, item = {}}) => {
      return isCheckAll 
               ? array?.map((el) => ({ ...el, checked: isChecked, isSelected: isChecked, disableLevelDrop: !isChecked  })) 
               : array?.map((el, i) =>
                  el.skillId === item.skillId
                      ? { ...el, checked: isChecked, isSelected: isChecked, disableLevelDrop: !isChecked }
                      : { ...el }
                );
  }


  const handleCheckAll = (e, el, i) => {
    const updatedInferredSkillsArray = getSelectedInferredSkills({isCheckAll: true, array: [...inferredSkillArray[inferredSkillCurrentTab]], isChecked: e.target.checked});
    setInferredSkillArray((prevSkillArray) => ({
      ...prevSkillArray,
      [inferredSkillCurrentTab]: updatedInferredSkillsArray
    }));
    const updatedInferredSkillsArrayCopy = getSelectedInferredSkills({isCheckAll: true, array: [...inferredSkillArrayCopy[inferredSkillCurrentTab]], isChecked: e.target.checked});
    setInferredSkillArrayCopy((prevSkillArray) => ({
      ...prevSkillArray,
      [inferredSkillCurrentTab]: updatedInferredSkillsArrayCopy
    }));
  }
  const handleCheckBox = (e, item) => {
     const updatedInferredSkillsArray = getSelectedInferredSkills({isCheckAll: false, array: [...inferredSkillArray[inferredSkillCurrentTab]], isChecked: e.target.checked, item: item});
      setInferredSkillArray((prevSkillArray) => ({
        ...prevSkillArray,
        [inferredSkillCurrentTab]: updatedInferredSkillsArray
      }));
      const updatedInferredSkillsArrayCopy = getSelectedInferredSkills({isCheckAll: false, array: [...inferredSkillArrayCopy[inferredSkillCurrentTab]], isChecked: e.target.checked, item: item});
      setInferredSkillArrayCopy((prevSkillArray) => ({
        ...prevSkillArray,
        [inferredSkillCurrentTab]: updatedInferredSkillsArrayCopy
      }));
  };

  const triggerGetIndustryRole = async () => {
    await getIndustryRole(personaDetail, Number(id));
  }

  const selectedSkillData = inferredSkillArrayCopy[AISuggestedSkills.Disprz_Skills]?.filter(
    (r) => r?.isSelected && !r?.disableRow
  );

  const handleAddDisprzSkills = async() => {
    if(inferredSkillArrayCopy[AISuggestedSkills.Disprz_Skills].every((item) => !item?.checked)){
      showAlert("No skills are selected for mapping", "info", 1500);
      return;
    }
    setFullLoader(true);
    let dataToBeSave = getAssignSkillsCombinedData();
    inferredSkillArrayCopy[AISuggestedSkills.Disprz_Skills].forEach((item) => {
     if(item?.checked && !item.disableRow){
      dataToBeSave.skillIdsToRemoveFromPersona.push(item?.skillId);
      dataToBeSave.skillProficiencyMappings.push({
        proficiencyId: item.proficiencyId,
        skillAddedFrom: SkillMappingType.INFERRED_SKILL,
        skillCriticality: ""
      })
     }
    });
    try{
      if(!isNavigatedFromRoleMatch){
        await handlePartialSave({isAddSkillTrigger: true});
        await AuthService.updatePersonaSkillV3(id, dataToBeSave);
        await triggerGetIndustryRole();
      }else{
        await handlePartialSave({isPartialSaveInferredSkill: true, isAddSkillTrigger: true, isSaveInferredSkill: true});
      }
      // setFullLoader(false);
      showAlert("Skill Mapping was successful", "success", 1500);
    }catch(error){
      console.log(error);
      setFullLoader(false);
      showAlert(error, "error", 2000);
    }
  };

  const downloadRoleSelectionData = async() => {
    try {
        if(exportParserMeta?.fileUrl){
          DownloadFile(exportParserMeta.fileUrl, "RoleSelectionData.xlsx");
          var data = exportParserMeta;
          data.status = ExportProcessType.DOWNLOADED;
          await RoleServices.UpdateRoleSelectionDataExportExcelStatusV3(clientName, data, personaName);
          setShowDownload(false);
          setExportParserMeta(null);
        } else {
          fetchDataAndUpdateState(true);
        }
    } catch (error) {
        showAlert(error, "error", 2000);
    }
  };
  
  const triggerExportExcel = async () => {
    try{
      setFullLoader(true);
      const file = await SkillService.exportSkillDetailsV3(clientName, personaDetail.personaId, personaDetail.industryRole.roleId);
      let blob = new Blob([file], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      }),
      url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Persona_ArchitectSkills.xlsx');
      link.click();
      // window.open(url);
      setFullLoader(false);
    }catch(error){
      console.log(error);
      setFullLoader(false);
      showAlert(error, "error", 2000);
    }finally{
      setFullLoader(false);
    }
  }

  const triggerApi = (id) =>
    SkillService.getSkillTypesArrayV3(id, "proficiencies");
    
  const levelChange = (val, item, reason) => {
    const updatedInferredSkillArray = [...inferredSkillArray[inferredSkillCurrentTab]].map((skill) =>
      skill.skillId === item.skillId
        ? {
            ...skill,
            proficiencyLevel: { level: val?.level || "", levelId: val?.levelId || 0, levelNumber: val?.levelNumber || 0 },
            proficiencyId: val?.proficiencyId || "",
            selectedLevel: val?.level || "",
            updated: true,
          }
        : { ...skill }
    );
    setInferredSkillArray((prevSkillArray) => ({
      ...prevSkillArray,
      [inferredSkillCurrentTab]: updatedInferredSkillArray
    }));
    const updatedInferredSkillsArrayCopy = [...inferredSkillArrayCopy[inferredSkillCurrentTab]].map((skill) =>
      skill.skillId === item.skillId
        ? {
            ...skill,
            proficiencyLevel: { level: val?.level || "", levelId: val?.levelId || 0, levelNumber: val?.levelNumber || 0 },
            proficiencyId: val?.proficiencyId || "",
            selectedLevel: val?.level || "",
            updated: true,
          }
        : { ...skill }
    );
    setInferredSkillArrayCopy((prevSkillArray) => ({
      ...prevSkillArray,
      [inferredSkillCurrentTab]: updatedInferredSkillsArrayCopy
    }));
  };

  return (
    <>
      <Layout>
        <H2>
          Assign Skills for Client Persona (Job Roles)
          <ToolBar title="Click to watch how to assign skills to a persona" arrow={true}>
            <img
                src={Images.icons.videoIcon}
                alt="persona video"
                onClick={() => setShowVideoPopup(true)}
                style={{ maxWidth: 20, position: 'relative', top: '3px', left: '15px', cursor: 'pointer' }}
            />
          </ToolBar>
        </H2>
        <BodyContent>
          <Container>
            <TopBlock>
              {skillValueUpdated ? (
                <BackCheck handleCheckBack={handleCheckBack} />
              ) : (
                <BackNav />
              )}
              {!isNavigatedFromRoleMatch && <div style={{ columnGap: 15 }}>
                {/* <RowCount
                text="Skills mapped to Persona: "
                count={personaSkills?.length}
              /> */}
                {!!clientDetail &&
                !!clientDetail?.disprzInstanceName &&
                !!clientDetail?.region ? (
                  <PrimaryButton onClick={handleExportToDisprzTrigger}>
                    Export to Disprz
                  </PrimaryButton>
                ) : null}

                <PrimaryButton onClick={() => setDetailPopup(true)}>
                  View Roles and Responsibilities
                </PrimaryButton>
                <SecondaryButton
                  style={{
                    backgroundColor: COLORS.PRIMARY_WHITE,
                  }}
                  onClick={() =>
                    skillValueUpdated
                      ? navigateConfirmTrigger(
                          `/persona_role/compare-skills/${clientName}/${personaDetail.personaId}/${personaDetail.industryRole.roleId}`
                        )
                      : navigate(
                          `/persona_role/compare-skills/${clientName}/${personaDetail.personaId}/${personaDetail.industryRole.roleId}`
                        )
                  }
                >
                  Compare Personas / Roles
                </SecondaryButton>
              </div>}
            </TopBlock>
            {/* <ArchChart jsonUrl="visual" /> */}
            <SkillHeader
              {...props}
              data={isNavigatedFromRoleMatch ? getOrDefault(newPersonaDetail, {}) : getOrDefault(personaDetail, {})}
              list={isNavigatedFromRoleMatch ? getOrDefault(state?.roleSelectionList, []) : allPersonaList}
              roleData={industryroleDetail}
              changePersona={handlePersonaChange}
              isNavigatedFromRoleMatch={isNavigatedFromRoleMatch}
              isSkillMappingInProgress={isSkillMappingInProgress}
              setIsSkillMappingInProgress={setIsSkillMappingInProgress}
              id={id}
              getImportSkillData={getImportSkillData}
              handleSkillMatchingDone={handleSkillMatchingDone}
              triggerExportExcel={isNavigatedFromRoleMatch ? downloadRoleSelectionData :  triggerExportExcel}
              showDownload={showDownload}
              loading={loading}
              setLoading={setLoading}
            />

            {(!!personaDetail || isNavigatedFromRoleMatch) && (
              <TabBlocks>
                <TabCustom tabTitle={skillTabTitle} getTabNumber={getSkillTab} currentTab={skillCurrentTab}>
                  <TabPanel value={skillCurrentTab} index={0}>
                    <TabCustom
                    tabTitle={
                      !!skillArray
                        ? [
                            funSkillKey,
                            ...Object.keys(skillArray)
                              .filter((el) => el !== funSkillKey)
                              .sort(),
                          ]
                        : []
                    }
                    getTabNumber={handleTab}
                    currentTab={currentTab}
                  >
                    <div className="right-element">
                      <ToolBar
                        title="total number of mapped skills for the persona across different skill categories"
                        arrow={true}
                      >
                        <div>
                          Mapped skills:{" "}
                          <strong>
                            {allskills?.filter((el) => !!el.proficiencyLevel)
                              .length ?? 0}
                          </strong>
                        </div>
                      </ToolBar>
                    </div>
                    {!!skillArray &&
                      [
                        funSkillKey,
                        ...Object.keys(skillArray)
                          .filter((el) => el !== funSkillKey)
                          .sort(),
                      ].map((item, i) => {
                        return (
                          <TabPanel value={currentTab} index={i} key={i}>
                            {trendingIds.includes(personaID) && (
                              <MarqueeContent trendingSkills={trendingSkills} />
                            )}
                          <SkillContent
                              data={!!skillArray ? skillArray[item] : []}
                              dataCopy={!!skillArrayCopy ? skillArrayCopy[item] : []}
                              clientName={clientName}
                              CopyList={allskills}
                              companyChange={handleCompany}
                              companyBlur={handlecompanyBlur}
                              recommandChange={handleRecommend}
                              addClick={handleAddClick}
                              updateClick={handleSaveColumns}
                              criticalList={criticalList}
                              handleCriticalChange={handleCriticalChange}
                              handleSkillPopup={handleSkillPopup}
                              handleChart={handleChart}
                              cancelSkill={handleCancelSkill}
                              handleFilter={handleFilter}
                              recommandDefaultClick={handleDefaultReccomend}
                              showTree={trendingIds.includes(personaID)}
                              trendingSkills={trendingSkills}
                              trendingJSONArray={trendingJSONArray}
                              clearMappingClick={handleClearMapping}
                              skillValueUpdated={skillValueUpdated}
                              index={i}
                              handleSourceSort={handleSourceSort}
                              isValidUser={isUserValid}
                              handleSkillSort={handleSkillSort}
                              isUserValid={isUserValid}
                            />
                          </TabPanel>
                        );
                      })}
                    </TabCustom>
                  </TabPanel>
                  <TabPanel value={skillCurrentTab} index={1} className="skill-selection">
                      <SkillSelection 
                        id={id} 
                        isSkillMappingInProgress={isSkillMappingInProgress || isNavigatedFromRoleMatch} 
                        clientSkillSelectionData={clientSkillSelectionData} 
                        handlePartialSave={handlePartialSave}
                        triggerGetIndustryRole={triggerGetIndustryRole}
                        isNavigatedFromRoleMatch={isNavigatedFromRoleMatch}
                        allSkillIds={allSkillIds}
                        handleSkillPopup={handleSkillPopup}
                        getAssignSkillsCombinedData={getAssignSkillsCombinedData}
                      />
                  </TabPanel>
                  <TabPanel value={skillCurrentTab} index={2} className="inferred-skills outer-box" >
                    <TabCustom tabTitle={inferredSkillsTabTitle} getTabNumber={getInferredSkillTab} currentTab={inferredSkillCurrentTab} toolTipTitle={inferresSkillsTabToolTipMessage}>
                      {inferredSkillsTabTitle?.map((el, i) => (
                          <TabPanel value={inferredSkillCurrentTab} index={i} key={i} className={`inferred-skills ${!inferredSkillCurrentTab ? 'disprz-skills' : 'non-disprz-skills'}`}>
                            <InferredSkillsBlock>
                                <>
                                  <div className={`content-table ${!inferredSkillCurrentTab ? 'disprz-skills' : 'non-disprz-skills'} ${`${(!isSkillMappingInProgress || inferredSkillArray[i].length === 0)  ? 'no-data' : ''}`}`}>
                                    <CustomizedTables
                                      columns={inferredSkillsTable[i]}
                                      rowList={inferredSkillArray[i]}
                                      rowListCopy={inferredSkillArrayCopy[i]}
                                      defaultSortColumn="skillName"
                                      handleCheckAll={handleCheckAll}
                                      handleCheckBox={handleCheckBox}
                                      sortList={async (sortValue, sortBy) =>{
                                        const sortedArray = await SortString(inferredSkillArray[inferredSkillCurrentTab], sortValue, sortBy);
                                        await sortByDisableRow(sortedArray);
                                        await setInferredSkillArray((prevSkillArray) => ({
                                          ...prevSkillArray,
                                          [inferredSkillCurrentTab]: sortedArray
                                        }))
                                      }}
                                      filterChange={(item, reason, colKey, list) =>
                                        FilterTableRowObject(item, reason, colKey, inferredSkillArrayCopy, setInferredSkillArray, inferredSkillCurrentTab)
                                      }
                                      resetList={() => setInferredSkillArray(inferredSkillArrayCopy)}
                                      theme="customizedTheme"
                                      noData={inferredSkillArray[i].length === 0}
                                      noDataToShow={inferredSkillArray[i].length === 0 ? "Import Client Skill Data" : ''}
                                      noDataTabelHeight="100px"
                                      stickyHeader={true}
                                      handleSkillRolePopUp={(id, row) => handleSkillPopup(id, row)}
                                      triggerApi={triggerApi}
                                      levelChange={levelChange}
                                    /> 
                                  </div>
                                </>
                                {(!inferredSkillCurrentTab &&  inferredSkillArray[i].length > 0) &&
                                  <ButtonBlock 
                                    showSave={false}
                                    handleAddToSkillList={() => 
                                      selectedSkillData.length !== 0 
                                      ? handleInferredSkillMapping()
                                      : showAlert("No skills are selected for mapping", "info", 1500)}
                                  />}
                            </InferredSkillsBlock>
                        </TabPanel>
                      ))}
                    </TabCustom>
                  </TabPanel>
                </TabCustom>
              </TabBlocks>
            )}
            <ModalPopup
              open={chartPopup}
              maxWidth="lg"
              closePopup={skillPopupClose}
              title={chartPopupData?.skill || "Skill Title"}
            >
              {!!chartPopupData && (
                <ArchChart
                  jsonUrl={
                    trendingJSONArray?.find(
                      (el) => el.skillId === chartPopupData.skillId
                    )?.link || ""
                  }
                />
              )}
            </ModalPopup>
            <ModalPopup
              open={skillPopup}
              maxWidth="lg"
              closePopup={skillPopupClose}
              title={!!skillDetail && skillDetail.skillName}
            >
              <SkillUpdatePopup
                data={skillDetail}
                listProficiency={skillProficiency}
                closePopup={skillPopupClose}
                withCilentID={true}
                clientView
              />
            </ModalPopup>
            <ModalPopup
              maxWidth="md"
              open={addSkillPopup}
              closePopup={skillPopupClose}
              title="Skill Definition"
            >
              <SkillUpdatePopup
                data={skillDetail}
                closePopup={skillPopupClose}
                addForm={true}
                list={allskills}
                getCurrentItem={getCurrentItem}
                updateSkillArray={updateSkillArray}
                withCilentID={true}
              />
            </ModalPopup>

            <ModalPopup
              open={confirmPopup}
              maxWidth="sm"
              title={confirmTitle}
              center={true}
            >
              <ConfirmationButton 
                 yesClick={handleYes} 
                 noClick={handleNo} 
                 noText={noText} 
                 yesText={yesText}
               />
            </ModalPopup>
            <RolesAndResponsibilitiesPopup
              open={detailPopup}
              close={() => setDetailPopup(false)}
              personaDetail = {personaDetail}
              industryroleDetail = {industryroleDetail}
            />
          </Container>
        </BodyContent>
        <VideoModal />
      </Layout>
    </>
  );
}; 

const TopBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  & > div {
    align-items: center;
    display: flex;
    margin: 0px;
  }
`;

const TabBlocks = styled.div`
.MuiTabs-root{
  margin-bottom: 15px;
}
> div > div > div > div > .MuiTabs-flexContainer{
  justify-content: space-between;
  gap: 0;
  width: 100%;
  flex-grow: 1;
  button{
    margin: 0px 0px;
    min-width: 33.33%;
    flex-grow: 1;
    &.Mui-selected{
      background: linear-gradient(rgba(255, 255, 255, 0), rgba(251, 22, 101, 0.10));
    }
  }
}
.skill-selection > div,
.inferred-skills > div {
  padding: 0;
  background-color: ${COLORS.BODY_ASH};
}
.outer-box{
  >div >div >div >div{
      border-bottom: 0px;
      .MuiTabs-flexContainer{
        width: 422px;
        border-bottom: 1px solid #b0b4d0;
      }
    }
}
.inferred-skills{
  &.disprz-skills{
    tbody{
      tr{
        td:first-child{
          padding-left: 15px !important;
        }
      }
    }
  }
  &.non-disprz-skills{
    thead{
      th{
        .MuiTableSortLabel-root{
          justify-content: center;
          width: 210px;
        }
        &:first-child{
          .filter-box{
            > div {
             width: 60%
            }
          }
        }
        &:first-child div[title="Open Filter"], &:first-child div[title="Clear"] {
           right: 50px;
          }
      }
    }
  }
  tbody{
    tr{
        td{
          padding-left: 0px !important;
            .triggerDrop{
              width: fit-content;
            }
        }
        &.disabled{
          .triggerDrop{
            border: none;
            .MuiAutocomplete-endAdornment{
              visibility: hidden;
            }
          }
        }
    }
  }
  .btn-block{
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
`;

const InferredSkillsBlock = styled.div`
 .content-table > div{
    table{
      .MuiCheckbox-root{
        color: ${COLORS.PRIMARY_WHITE};
        padding: 3px 6px !important;
      }
    }
  }
 .content-table {
    position: relative;
    > div:first-child {
      overflow: auto !important;
        &::-webkit-scrollbar {
          height: 8px;
          width: 8px;
        }
        &::-webkit-scrollbar-thumb {
          background: rgba(0, 0, 0, 0.2);
          border-radius: 6px;
        }
        .MuiTable-root{
          padding-right: 10px;
        }
    }
    thead{
      th{
        position: sticky !important;
      }
    }
    &.disprz-skills{
        & > div {
        max-height: calc(100vh - 240px);
        thead {
          th {
            text-align: left !important;
            &:nth-child(2) {
              padding-left: 60px !important;
            }
          }
        }

        tbody {
          tr {
            td {
              text-align: left !important;
              padding-left: 15px !important;
              &:nth-child(2) {
                padding-left: 60px !important;
              }
            }
          }
        }
      }
    }
    &.non-disprz-skills{
        & > div {
        max-height: calc(100vh - 400px);
        thead {
          th {
            text-align: left !important;
            &:nth-child(1) {
              padding-left: 185px !important;
            }
          }
        }

        tbody {
          tr {
            td {
              text-align: left !important;
              padding-left: 15px !important;
              &:nth-child(1) {
                padding-left: 185px !important;
              }
              &:nth-child(2) {
                padding-right: 80px !important
              }
            }
          }
        }
      }
    }
    &.no-data{
      & > div{
        height: calc(100vh - 415px) !important;
        tbody{
          tr{
            td{
              &:nth-child(1) {
                padding-left: 15px !important;
              }
            }
          }
        }
      }
    }
  }
 .btn-block{
  margin: 0px;
  padding-top: 20px;
  background-color: ${COLORS.BODY_ASH};
 }
`