import usePopUp from "../../../../../Hooks/usePopUp";
import text from "../../../../../locale.en.json"

const useRemoveSkillColumn = ({cancelSkill}) => {
  const { popUp } = usePopUp();

  const handleRemoveIconClick = (item) => {
    popUp.confirm(
      text.skill.removeSkillConfirmation.message.replace('{{skillName}}', item?.skillName),
      text.skill.removeSkillConfirmation.title,
      () => cancelSkill(item)
    );
  };

  return {
    handleRemoveIconClick,
  };
};

export default useRemoveSkillColumn;
