import { useState, useEffect} from 'react';
import { useLocation } from "@reach/router";
import { SkillService } from '../../../services/SkillService';
import { AuthService } from '../../../services/AuthService';
import { getOrDefault, isAddSkillsPath } from '../../../helpers/CommonHelper';
import { SortString } from '../../../helpers/SortString';
import useFullLoader from '../../../Hooks/useFullLoader';
import useAlert from '../../../Hooks/useAlert';
import useRoles from '../../../Hooks/useRoles';
import { RoleServices } from '../../../services/RoleServices';
import { AssignSkillsStore, resetStore } from '../../../store/store_state';
import { InitialAssignSkillsStore } from '../../../store/initial_store_state';

const useAssignSkills = ({ type, personaId }) => {
  const { setFullLoader } = useFullLoader();
  const { showAlert } = useAlert();
  const { clientName } = useRoles();
  const location = useLocation();
  const isNavigatedFromRoleMatch = getOrDefault(location.state?.isNavigatedFromRoleMatch, false);
  const isNavigatedFromRoleScreen = getOrDefault(location.state?.isNavigatedFromRoleScreen, false);
  const skillIdsToBeExcluded  = getOrDefault(location.state?.skillIdsToBeExcluded, []);
  const isExcludeSkills = getOrDefault(location.state?.isExcludeSkills, false);
  const skillSourceIds = getOrDefault(location.state?.skillSourceIds, []);
  const isNavigatedFromComparePersona = getOrDefault(location.state?.isNavigatedFromComparePersona, false);
  const personasCompared = getOrDefault(location.state?.personasCompared, []);

  const [categoryMenu, setCategoryMenu] = useState([]);
  const [familyMenu, setFamilyMenu] = useState([]);
  const [personaSkills, setPersonaSkills] = useState([]);
  const [skillDetail, setSkillDetail] = useState(null);
  const [skillProficiency, setSkillProficiency] = useState(null);
  const [skillPopup, setSkillPopup] = useState(false);

  const [roleSelectionData, setRoleSelectionData] = useState([]);
  const [excludedRoleSkills, setExcludedRoleSkills] = useState([]);

  const getListData = async () => {
    try {
      const resCat = await SkillService.getSkillCategoriesV3(type);

      if (type === "persona" && !isAddSkillsPath()) {
        const personaSkill = await AuthService.getPersonaSkillsV3(personaId);
        setPersonaSkills(personaSkill);
      }

      setCategoryMenu(
        SortString(resCat, "skillCategory").map((el) => ({
          label: el.skillCategory,
          value: el.skillCategoryId,
        }))
      );

      const resFam = await SkillService.getSkillFamiliesV3();
      setFamilyMenu(
        SortString(resFam, "skillFamily").map((el) => ({
          label: el.skillFamily,
          value: el.skillFamilyId,
        }))
      );
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    resetStore(AssignSkillsStore, InitialAssignSkillsStore);
    getListData();
  }, []);

  useEffect(() => {
    const fetchRoleSelectionData = async() => {
      setFullLoader(true);
      try {
        const response = await RoleServices.getImportPersonaDetails(clientName);
        if(location.state?.isNavigatedFromArchitectSkills){
         const role = response.find((role) => role.personaName === location.state.personaName);
         setExcludedRoleSkills(role?.excludedRoleSkills ?? [])
        } else {
          const roles = response.filter((role) => (personasCompared).includes(role.personaName));
          const excludedSkills = roles.flatMap(role => getOrDefault(role?.excludedRoleSkills, []));
          AssignSkillsStore.update((s) => {
            s.roleSkillIds = excludedSkills?.map((skill) => skill.skillId);
          })
        }
        setRoleSelectionData(response);
        setFullLoader(false);
      } catch( error) {
        showAlert(error, "error", 2000);
        setFullLoader(false);
      }
    }
    if(isNavigatedFromRoleMatch && clientName){
      fetchRoleSelectionData();
    }
  }, [clientName, isNavigatedFromRoleMatch])

  useEffect(() => {
    const fetchExcludedSkillsData = async () => {
        let excludedRoleSkills = [];
        for (const id of personasCompared) {
          if(!!id){
            const res = await AuthService.getExcludedSkillsFromPersonaV3(id);
            if (res && res.length > 0) {
              excludedRoleSkills = [...excludedRoleSkills, ...res]
            }
          }
        }
        AssignSkillsStore.update((s) => {
          s.roleSkillIds = excludedRoleSkills.map((skill) => skill.skillId);
        });
    };
    if(isNavigatedFromComparePersona){
      fetchExcludedSkillsData();
    }
  }, [isNavigatedFromComparePersona]);
  

  const handleSkillPopup = async (item) => {
    try {
      const resDetail = await SkillService.getSkillDetail(item.skillId, true);
      setSkillDetail(resDetail);
      const resProficiency = await SkillService.getSkillTypesArrayV3(item.skillId, "proficiencies");

      setSkillProficiency(SortString(resProficiency, "levelNumber"));
      setSkillPopup(true);
    } catch (errors) {
      console.log(errors);
    }
  };

  const closePopup = () => setSkillPopup(false);

  return {
    categoryMenu,
    familyMenu,
    handleSkillPopup,
    isNavigatedFromRoleMatch,
    isExcludeSkills,
    skillIdsToBeExcluded,
    personaSkills,
    skillSourceIds,
    skillPopup,
    closePopup,
    skillDetail,
    skillProficiency,
    isNavigatedFromRoleScreen,
    roleSelectionData,
    excludedRoleSkills
  }
}

export default useAssignSkills;