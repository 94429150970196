import React from "react";
import styled from "styled-components";
import { Field, useField } from "formik";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { TextField } from "formik-material-ui";

import COLORS from "../../../assets/colors";
import { ListSubheader } from "@material-ui/core";

export default ({
  list = [],
  name,
  label,
  width,
  margin,
  disabled = false,
  handleChange = () => {},
  value = "",
  isRequired,
  title = "",
}) => {
  const [field, meta] = useField(name);
  const classes = useStyles();

  // console.log(meta);
  return (
    <FormControlTag width={width} margin={margin}>
      <Field
        component={TextField}
        type="text"
        name={name}
        label={
          <span>
            {label} {isRequired && <span className="astric">*</span>}
          </span>
        }
        select
        disabled={disabled}
        variant="standard"
        // helperText="Please select Range"
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
        value={value}
        onChange={handleChange}
        title={
          !!list.length && !!list?.find((item) => item?.value == value)
            ? list?.find((item) => item?.value == value).label
            : ""
        }
      >
        {list.some((el) => el.value === "Any") && (
          <StyledMenuItem value="Any">Any</StyledMenuItem>
        )}
        <ListSubheader className={classes.subHeader}>Functions</ListSubheader>
        {!!list.length &&
          list
            .filter((el) => el.type === 2)
            .map((item, i) => (
              <StyledMenuItem value={item.value} key={i}>
                {item.label}
              </StyledMenuItem>
            ))}
        <ListSubheader className={classes.subHeader}>Industries</ListSubheader>
        {!!list.length &&
          list
            .filter((el) => el.type === 1)
            .map((item, i) => (
              <StyledMenuItem value={item.value} key={i}>
                {item.label}
              </StyledMenuItem>
            ))}
      </Field>
    </FormControlTag>
  );
};

const StyledMenuItem = withStyles((theme) => ({
  root: {
    fontSize: 12,
    "&:focus": {
      backgroundColor: COLORS.ASH_WHITE,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const useStyles = makeStyles((theme) => ({
  subHeader: {
    backgroundColor: `#a6b8d8`,
    color: COLORS.PRIMARY_WHITE,
    fontWeight: 700,
    padding: `7px 15px`,
  },
}));

const FormControlTag = styled(FormControl)`
  &.MuiFormControl-root {
    padding: 0px;
    width: ${(props) => (props.width ? props.width : "150px")};
    margin: ${(props) => (props.margin ? props.margin : undefined)};
    .MuiFormControl-marginNormal {
      margin-top: 0px;
      margin-bottom: 0px;
    }
    .MuiSelect-selectMenu {
      font-size: 12px;
      font-weight: 400;
      padding-left: 10px;
    }
    label {
      color: ${COLORS.TITLE_BLUE};
      font-size: 16px;
      font-weight: 600;
      font-family: inherit;
      line-height: 20px;
      transform: none;
      white-space: nowrap;
      .astric {
        color: ${COLORS.PRIMARY_RED};
      }
    }
    label + .MuiInput-formControl {
      border: solid 1px ${COLORS.C4_BORDER};
      border-radius: 4px;
      margin-top: 32px;
      min-height: 38px;
      &:before {
        display: none;
      }
    }
  }
`;
