export const Role_Persona_Table_Colmns = [
    {
      colKey: "personaName",
      colValue: "Persona Name",
      sortable: true,
    },
    {
      colKey: "personaGroupName",
      colValue: "Group Name",
      sortable: true,
    },
    {
      colKey: "leadershipName",
      colValue: "Leadership Role",
      sortable: true,
    },
    {
      colKey: "clientName",
      colValue: "Client",
      sortable: true,
    },
  ];