import React from "react";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import ToolBar from "../../../../Common/ToolBar";
import text from "../../../../../locale.en.json"

/**
 * RemoveIcon component for rendering a remove icon within a ToolBar.
 *
 * @param {Object} item - object in an array which contains skill details.
 * @param {number} index - Index of the item.
 * @param {Function} handleRemoveIconClick - Function to handle the click event on the remove icon.
 * @returns {JSX.Element} - Rendered RemoveIcon component.
 */
const RemoveIcon = (item, index, handleRemoveIconClick) => (
  <li key={index}>
    <ToolBar
      title={text.skill.removeSkillFromPersona}
      arrow={true}
      placement={"right"}
      darkMode={true}
    >
      <RemoveCircleOutlineIcon
        onClick={() => {
          handleRemoveIconClick(item);
        }}
      />
    </ToolBar>
  </li>
);

export default RemoveIcon;
