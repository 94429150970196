const ContentSkillRelevancy = {
    NotVerified: 0,
    Relevant: 1,
    Irrelevant: 2
};

const _getContentSkillRelevancyStatus = (relevancy) => {
    let dict = {
        [ContentSkillRelevancy.NotVerified]: "Not verified",
        [ContentSkillRelevancy.Relevant]: "Yes",
        [ContentSkillRelevancy.Irrelevant]: "No"
    };
    return dict[relevancy];
}
export {
    ContentSkillRelevancy,
    _getContentSkillRelevancyStatus as getContentSkillRelevancyStatus
}