import React, { useState, useEffect } from "react";
import { PrimaryButton } from "../Common/Buttons/PrimaryButton";
import styled from "styled-components";
import SelectMenu from "../Common/FormElements/SelectMenu";
import Suggestions from "./Suggestions";
import { AuthService } from "../../services/AuthService";
import useAlert from "../../Hooks/useAlert";
import useFullLoader from "../../Hooks/useFullLoader";
import { SortString } from "../../helpers/SortString";
import SelectMenuGroup from "../Common/FormElements/SelectMenuGroup";

export default ({
  data = {},
  subSector,
  subSectors,
  functions,
  industryRole,
  handleRoleChange,
  handleSubSector,
  page,
}) => {
  // console.log(data);
  const [funVal, setFunVal] = useState("Any");
  const [industryRoleList, setIndustryRoleList] = useState(null);
  const { setFullLoader } = useFullLoader();
  const { showAlert } = useAlert();

  const changeSubSector = (sector) => {
    handleSubSector(sector);
  };
  const handleFunc = (val) => setFunVal(val);
  const handleIndustryRole = (role) => handleRoleChange(role);

  useEffect(() => {
    setIndustryRoleList(null);
    setIndustryRoleList(null);
    if (!!data) setFunVal(data.function !== "" ? data.function : "Any");
  }, [page, data]);

  useEffect(() => {
    const initData = {
      sector: [],
      subSectorIds: subSector !== "Any" ? [subSector] : [],
      function: funVal !== "Any" ? [funVal] : [],
      framework: [],
    };

    const getIndustryRoles = async () => {
      try {
        const res = await AuthService.getIndustryRoleList(initData);
        setIndustryRoleList(SortString(res, "role_cluster"));
      } catch (error) {
        console.log(error);
      }
    };

    if (subSector !== "Any") getIndustryRoles();

    return () => {
      setIndustryRoleList(null);
    };
  }, [subSector, funVal]);

  const handleAnnotateJob = async () => {
    setFullLoader(true);
    try {
      if (industryRole !== "Any") {
        await AuthService.postAnnotateJob(
          `?jobPostingId=${data.id}`,
          "&roleId=" + industryRole
        );
        showAlert("Successfully job annotated", "success", 1500);
      } else {
        showAlert("Before annotate job, Get industry role", "info", 1500);
      }
    } catch (error) {
      showAlert(error.Message, "error", 1500);
    } finally {
      setFullLoader(false);
    }
  };

  const handleListClick = async (initData) => {
    const industryRolesFilter = { roleName: initData };
    const getIndustryRoles = async () => {
      try {
        const res = await AuthService.getIndustryRoleList(industryRolesFilter);
        setIndustryRoleList(SortString(res, "role_cluster"));
        handleSubSector(res[0].sub_sector);
        setFunVal(res[0].function);
        handleRoleChange(res[0].role_id);
      } catch (error) {
        showAlert(error.Message, "error", 1500);
      }
    };

    getIndustryRoles();
  };

  // console.log(subSector);
  return (
    <AnnotateBlock>
      <div className="dropDownWrapper">
        <div className="dropDownLeft multi">
          <div style={{ width: 180 }}>
            <SelectMenuGroup
              width="180px"
              label={
                !!subSectors?.find((el) => el.sub_sector_id === subSector) &&
                subSectors?.find((el) => el.sub_sector_id === subSector)
                  .sub_sector_type === 1
                  ? "Industry"
                  : "Function"
              }
              value={subSector}
              list={
                !!subSectors
                  ? subSectors.map((el, i) => ({
                      label: el.sub_sector_name,
                      value: el.sub_sector_id,
                      type: el.sub_sector_type,
                    }))
                  : []
              }
              handleSelect={changeSubSector}
            />
          </div>

          <div style={{ width: 180, marginLeft: 15 }}>
            <SelectMenu
              width="180px"
              label={
                !!subSectors?.find((el) => el.sub_sector_id === subSector) &&
                subSectors?.find((el) => el.sub_sector_id === subSector)
                  .sub_sector_type === 1
                  ? "Department"
                  : "Track"
              }
              value={funVal}
              list={
                !!functions
                  ? functions.map((el, i) => ({ label: el, value: el }))
                  : []
              }
              handleSelect={handleFunc}
            />
          </div>

          <div style={{ width: 180, marginLeft: 15 }}>
            <SelectMenu
              width="180px"
              label="Industry Role"
              value={industryRole}
              list={
                !!industryRoleList
                  ? industryRoleList.map((el, i) => ({
                      label: el.role_cluster,
                      value: el.role_id,
                    }))
                  : []
              }
              handleSelect={handleIndustryRole}
            />
          </div>
        </div>

        <PrimaryButton onClick={handleAnnotateJob}>Annotate Job</PrimaryButton>
      </div>

      {!!data && (
        <Suggestions
          list={data.top3_role_suggestion.split(",")}
          handleListClick={handleListClick}
        />
      )}
    </AnnotateBlock>
  );
};

const AnnotateBlock = styled.div`
  .dropDownWrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    @media only screen and (max-width: 1024px) {
      margin-bottom: 15px;
    }
    .dropDownLeft {
      &.multi {
        display: inherit;
      }
      margin-right: 15px;
      /* width: 100%; */
    }
  }
`;
