import React from 'react';
import { LinearProgress, Box } from "@material-ui/core";
import styled from 'styled-components';

export default ({ message = "" }) => {
  return (
    <ProgressBar>
      <Box className="report-box">
        <div className="report-box-text">{`${message}...`}</div>
        <LinearProgress className="report-box-progress" />
      </Box>
    </ProgressBar>
  )
}

const ProgressBar = styled.div`{
    .report-box {
      width: 250px;
      height: 52px;
      position: fixed;
      top: 12%;
      right: 35px;
      background-color: #f5f5f5;
      border-radius: 4px;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
      padding: 11px 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      border: 1px solid #424a79;
      z-index: 999;
    }

    .report-box-text {
      margin-right: 10px;
    }

    .report-box-progress {
      flex: 1;
      width: 100%;
    }
}
`