import { navigate } from "@reach/router";
import { RoleServices } from "../../../services/RoleServices";
import { SkillMappingType } from "../../../enums/SkillMappingType";
import text from "../../../locale.en.json";

export const getAndSaveClientRoles = async(listCopy, roleSelectionData, personaNames, clientName, importTokenId, setFullLoader, showAlert, isIndustryRoleSkills = false) => {
    setFullLoader(true);
    const selectedSkills = [...listCopy].filter((skill) => !!skill?.checked && !!skill?.proficiencyId);
    const updatedSkills = selectedSkills?.map((skill) => ({
      proficiencyId: skill.proficiencyId,
      skillCriticality: '',
      skillAddedFrom: isIndustryRoleSkills ? SkillMappingType.INDUSTRY_ROLE : SkillMappingType.ADD_SKILL,
      skillCategory: skill.skillCategory,
      proficiencyLevel: { level:  skill?.target_proficiency || "", levelId: skill?.levelId || 0, levelNumber: skill?.levelNumber || 0 },
      skillId: skill.skillId,
      skillName: skill.skillName,
    }));

    const getUpdatedExcludedRoleSkills = (excludedRoleSkills) => {
      return excludedRoleSkills.filter((excludedSkill) => {
        return !updatedSkills.find((skill) => skill.skillId === excludedSkill.skillId);
      });
    }

    const updatedRoleSelectionDataToSave = [...roleSelectionData].map(
      (data) => {
        if(personaNames.includes(data.personaName)){
          data.proficiencies = data?.proficiencies?.length > 0 ? [...data.proficiencies, ...updatedSkills] : [...updatedSkills];
          data.excludedRoleSkills = getUpdatedExcludedRoleSkills((data?.excludedRoleSkills ?? []))
        }
        return data;
      }
    );
    try {
      await RoleServices.partialSaveClientRoles(updatedRoleSelectionDataToSave, clientName, importTokenId);
      await showAlert(text.skill.addedSkillsToPersona.replace('{{count}}', selectedSkills.length), "success", 1500);
      setFullLoader(false);
      navigate(-1);
    } catch (error) {
      console.log(error);
      setFullLoader(false);
      showAlert(error, "error", 2000);
    }
  }