import React, {
  createContext,
  useContext,
  useMemo,
  useState,
  useEffect,
} from "react";
import useAuth from "../Hooks/useAuth";
import { SkillService } from "../services/SkillService";

export const SkillTableDataContext = createContext();

export const SkillTableDataProvider = ({ children }) => {
  const { isUserLoggedIn } = useAuth();

  const [contentDetailModal, setContentDetailModal] = useState(false);
  const [contentDetailData, setContentDetailData] = useState(null);
  const [skillDetailData, setskillDetailData] = useState(null);
  // const [criticalOptions, setCriticalOptions] = useState(null);

  // const GetCriticality = async () => {
  //   const skillCriticalityList = await SkillService.getSkillCriticalityListV3();

  //   setCriticalOptions([
  //     ...skillCriticalityList.map((el) => ({ label: el, value: el })),
  //   ]);
  // };

  useEffect(() => {
    // if (!contentDetailModal) {
    //   setContentDetailData(null);
    // }
    // console.log({ isUserLoggedIn });
    // !!isUserLoggedIn && GetCriticality();
  }, [isUserLoggedIn]);

  const value = useMemo(() => {
    return {
      contentDetailModal,
      setContentDetailModal,
      contentDetailData,
      setContentDetailData,
      skillDetailData,
      setskillDetailData,
    };
  }, [
    contentDetailModal,
    setContentDetailModal,
    contentDetailData,
    setContentDetailData,
    skillDetailData,
    setskillDetailData,
  ]);

  return (
    <SkillTableDataContext.Provider value={value}>
      {children}
    </SkillTableDataContext.Provider>
  );
};

export function useSkillTableData() {
  const context = useContext(SkillTableDataContext);
  if (context === undefined) {
    throw new Error("alert");
  }
  return context;
}
