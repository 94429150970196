import React from "react";
// import "./FullPageLoader.scss";
import "./SpinnerLoader.scss";
function DotLoader() {
  return (
    <>
      <div className="loader">Loading...</div>
    </>
  );
}

export default DotLoader;
