import React from "react";
import PersonaCompareBody from "./PersonaCompareBody";
import PersonaCompareHeader from "./PersonaCompareHeader";

export default (props) => {
  return (
    <div>
      <PersonaCompareHeader {...props} />
      <PersonaCompareBody {...props} />
    </div>
  );
};
