import React, { useState, useEffect } from "react";
import { navigate } from "@reach/router";
import styled from "styled-components";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core";

import { Images } from "../../assets/images";
import { User, Common } from "../../store";
import useAuth from "../../Hooks/useAuth";
import useAlert from "../../Hooks/useAlert";
import useRoles from "../../Hooks/useRoles";
import LandingBox from "./LandingBox";
import DashBox from "./DashBox";
import { SkillBoxList } from "./LandContent";
import { H5, H4 } from "../Common/Typography";
import COLORS from "../../assets/colors";
import ClientModal from "../Common/Compos/ClientModal";
import { SortString } from "../../helpers/SortString";
import { AuthService } from "../../services/AuthService";
import { clearStore } from "../../store/store_state";
import { UserType } from "../../enums/UserType";

export default (props) => {
  const classes = useStyles();
  const { setIsUserLoggedIn, userDetails } = useAuth();
  const { User_Type } = userDetails;
  const { showAlert } = useAlert();

  const { clientCount, clientName, setClientName } = useRoles();
  const userData = User.getUserDetails();

  const [clientPopup, setClientPopup] = useState(false);
  const [currentType, setCurrentType] = useState("");
  const [contentList, setContentList] = useState([]);

  // User_Type === 1 is general user, User_Type === 2 is Super user
  // User_Type === 3 is SME user, User_Type === 4 is Skilling Architect

  useEffect(() => {
    sessionStorage.clear();
    const filterContent = SkillBoxList.filter((el) =>
      el.accessUserTypes.includes(User_Type)
    );
    setContentList(filterContent);

    if (User_Type === UserType.SME) {
      setClientName(0);
    }
  }, [User_Type]);

  useEffect(() => {
    if (!userData) {
      signOut();
      // navigate("/", { state: props?.location?.state.redirectPath || "" });
    }
  }, [userData]);

  useEffect(() => {
    if (props?.location?.state?.message) {
      showInfo();
    }
  }, [props?.location?.state?.message]);

  const showInfo = async () => {
    if (!!props?.location?.state?.message) {
      await showAlert(
        props?.location?.state?.message || "Session timed out!",
        "Info",
        1500
      );
    }
  };

  const handleLogout = () => {
    signOut();
    showAlert("Logout Successful", "success", 1500);
    navigate("/");
  };

  const signOut = () => {
    clearStore();
    User.clearAll();
    Common.clearAll();
    setIsUserLoggedIn(false);
    setClientName(null);
  };

  const handleList = (val) => {
    setCurrentType(val);
    if (val === "ARCH_SKILL") {
      if (clientCount === 1) {
        // navigate("/persona/architect-skills");

        getAllPersonas(val);
      } else {
        setClientPopup(true);
        // setCurrentLink("/persona/architect-skills");
      }
      // getAllPersonas();
    } else if (val === "COMPARE_PERSONA") {
      clientCount === 1 ? getAllPersonas(val) : setClientPopup(true);
    } else if (val === "COMPARE_ROLE") {
      if (User_Type === UserType.GENERAL_USER || User_Type === UserType.GENERAL_USER_INTERNAL) {
        clientCount === 1 ? navigate("/compare-role") : setClientPopup(true);
      } else {
        navigate("/compare-role");
      }
    }
  };

  const getAllPersonas = async (type) => {
    try {
      const resList = await AuthService.getPersonasListV3(
        `?clientId=${clientName}`
      );
      if (resList.length > 0) {
        await navigate(
          type === "ARCH_SKILL"
            ? "/persona/architect-skills/" +
                SortString(
                  resList.map((el) => ({
                    ...el,
                    personaGroupName: el.personaGroup.personaGroupName,
                  })),
                  "personaGroupName"
                )[0].personaId
            : "compare-persona"
        );
        window.location.reload();
      } else {
        showAlert(
          "No Personas found! Kindly define a persona first",
          "info",
          2500
        );

        window.location.pathname !== "/" && navigate("/personas");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <LandingBlock>
        <div className="landing__inner">
          <div className="userInfo">
            <Avatar
              alt={userDetails?.name?.toUpperCase()}
              src="/broken-image.jpg"
              className={classes.avatarBg}
            />
            <H4>{userDetails?.name}</H4>
            <div className="logout" onClick={handleLogout}>
              <img src={Images.icons.logoutIcon} alt="logout" />
            </div>
          </div>

          <div className="landing__block">
            {contentList?.map((elem, index) => (
              <DashBox className={elem.styleName} key={index}>
                <H5>{elem.title}</H5>
                <div className="box-block">
                  {elem.content.map((el, i) => (
                    <LandingBox {...el} key={i} listClick={handleList} />
                  ))}
                </div>
              </DashBox>
            ))}
          </div>
        </div>
      </LandingBlock>
      {props.path === "/" &&
      [UserType.GENERAL_USER, UserType.GENERAL_USER_INTERNAL, UserType.SUPER_USER, UserType.SKILLING_ARCHITECT].some((el) => el === User_Type) &&
      !!userData ? (
        <ClientModal
          open={clientPopup}
          closePopup={() => setClientPopup(false)}
          title="Select Client"
          assignName={true}
          type={currentType}
        />
      ) : null}
    </>
  );
};

const LandingBlock = styled.div`
  height: 100vh;
  position: relative;
  &:before {
    content: "";
    background: url(${Images.bgImgs.discussionImg}) center no-repeat;
    background-size: cover;
    filter: blur(5px);
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
  }
  .landing {
    &__inner {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: inherit;
      padding-top: 70px;
    }
    &__block {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
  .userInfo {
    background-color: ${COLORS.ASH_RED};
    border-radius: 28px;
    display: flex;
    align-items: center;

    padding: 10px;
    position: absolute;
    right: 10px;
    top: 10px;
    h4 {
      padding: 0px 15px;
      text-transform: capitalize;
    }
    .logout {
      cursor: pointer;
      line-height: 1;
      margin-right: 5px;
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  avatarBg: {
    color: "white",
    backgroundColor: COLORS.BODY_BLUE,
    margin: 0,
    padding: 0,
  },
}));
