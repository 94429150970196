import { useTableContext } from "../Provider/TableProvider";
import { SortString } from "./SortString";

export const FilterTableRow = (item, reason, colKey, list, setList) => {
  const value = item?.value === "Blank" ? "" : item?.value;
  const filterList =
    item !== null
      ? [...list]
          .map((el) => (el[colKey] === null ? { ...el, [colKey]: "" } : el)) // handle null values as emprty string
          .filter((el) => el[colKey] === value)
      : [...list];
  setList(filterList);
};

export const filterTableRowList = (item, reason, colKey, list) => {
  const value = item?.value === "Blank" ? "" : item?.value;
  const filterList =
    item !== null
      ? [...list]
          .map((el) => (el[colKey] === null ? { ...el, [colKey]: "" } : el)) // handle null values as emprty string
          .filter((el) => el[colKey] === value)
      : [...list];
  return filterList;
};

export const FilterTableRowByTextValue = (value, colKey, list, setList) => {
  const filterList =
    value !== null
      ? [...list]
          .map((el) => (el[colKey] === null ? { ...el, [colKey]: "" } : el))
          .filter((el) =>
            el[colKey].toLowerCase().includes(value.toLowerCase())
          )
      : [...list];

  setList(filterList);
};

export const FilterTableRowObject = (
  item,
  reason,
  colKey,
  listObject,
  setListObject,
  objectKey
) => {
  const value = item?.value === "Blank" ? "" : item?.value;
  const filterList =
    item !== null
      ? listObject[objectKey]
          .map((el) => (el[colKey] === null ? { ...el, [colKey]: "" } : el)) // handle null values as emprty string
          .filter((el) => el[colKey] === value)
      : listObject[objectKey];

  setListObject({
    ...listObject,
    [objectKey]: filterList,
  });
};

export const FilterSortTableCondition = () => {
  const { sortAndFilterValues } = useTableContext();

  const getList = (list) => {
    if (
      sortAndFilterValues?.orderBy !== "" &&
      sortAndFilterValues?.filterBy !== ""
    ) {
      let sortList = SortString(
        list,
        sortAndFilterValues?.orderBy,
        sortAndFilterValues?.order
      );

      return filterTableRowList(
        sortAndFilterValues.filterValue,
        "",
        sortAndFilterValues?.filterBy,
        sortList
      );
    } else if (sortAndFilterValues?.filterBy !== "") {
      return filterTableRowList(
        sortAndFilterValues.filterValue,
        "",
        sortAndFilterValues?.filterBy,
        list
      );
    } else if (sortAndFilterValues?.orderBy !== "") {
      return SortString(
        list,
        sortAndFilterValues?.orderBy,
        sortAndFilterValues?.order
      );
    } else {
      return list;
    }
  };

  return { getList };
};
