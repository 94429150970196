import React from "react";

export default [
  {
    colKey: "checkbox",
    colValue: "checkbox",
    width: 60,
  },
  {
    colKey: "personaName",
    colValue: "Client Persona / Role",
    width: 200,
  },
  {
    colKey: "reportingTo",
    colValue: "Reporting to",
    splitVal: false,
    width: 200,
  },
  {
    colKey: "industryRole",
    colValue: "Matching Industry Role",
    colType: "inside_Function",
    getTableData: (val) => {
      return <>{val.industryRole.roleName}</>;
    },
  },
  {
    colKey: "button",
    colValue: "",
    width: 180,
  },
  {
    colKey: "more_action",
    colValue: "",
    width: 60,
  },
];
