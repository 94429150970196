import React from 'react'
import { H4 } from '../../../Common/Typography'
import CustomizedTables from '../../../Common/CustomizedTables'
import { PrimaryButton } from '../../../Common/Buttons/PrimaryButton'
import { isAddSkillsPath } from '../../../../helpers/CommonHelper'
import text from '../../../../locale.en.json'
import { TableColumnsAssignSkills } from '../TableColumnAssignSkills'

const SelectedSearchedSkills = ({
    selectedSearchedSkills,
    skillType,
    isNavigatedFromRoleMatch,
    handleSelectedSearchedSkillCheckBox,
    handleSelectedSearchedSkillCheckAll,
    handleSkillPopup,
    handleSelectedSearchedSkillLevelChange,
    triggerApi,
    handleSkillAdd
}) => {
  return (
    <div className={!!selectedSearchedSkills?.length && "selected-skills-container"}>
        {!!selectedSearchedSkills.length && (
          <>
            <div className="selected-skills-header">
              <H4>{text.skill.selectedSkills}</H4>
            </div>
            <CustomizedTables
              columns={TableColumnsAssignSkills({
                skillType: skillType,
                isNavigatedFromRoleMatch: isNavigatedFromRoleMatch,
                isIndustryRoleTable: false
              })}
              rowList={!!selectedSearchedSkills ? selectedSearchedSkills : []}
              stickyHeader={selectedSearchedSkills?.length > 10}
              tableHeight={selectedSearchedSkills?.length > 10 ? 488 : "auto"}
              handleCheckBox={handleSelectedSearchedSkillCheckBox}
              handleCheckAll={handleSelectedSearchedSkillCheckAll}
              innerTabel={true}
              rowClick={handleSkillPopup}
              levelChange={handleSelectedSearchedSkillLevelChange}
              triggerApi={triggerApi}
            />
            {!!selectedSearchedSkills?.length && (
              <PrimaryButton
                style={{ marginTop: 20 }}
                onClick={handleSkillAdd}
                disabled={selectedSearchedSkills?.length === 0 || selectedSearchedSkills.every((skill) => !skill.checked)}
              >
                {text.skill.addSkillsTo}{" "}
                {skillType === "persona" ||
                isAddSkillsPath() ||
                isNavigatedFromRoleMatch
                  ? text.persona.Persona
                  : text.role.Role}
              </PrimaryButton>
            )}
          </>
        )}
      </div>
  )
}

export default SelectedSearchedSkills