import React from "react";
import { Popper } from "@material-ui/core";
import styled from "styled-components";
import COLORS from "../../assets/colors";

export default (props) => {
  return (
    <Popper {...props} placement="bottom-start" style={{ width: props.width }}>
        {!props.toFetchWithoutTitle && <PopperTitle>
            <li>Role</li>
            <li>Industry</li>
            <li>Department</li>
            <li>Occupation</li>
          </PopperTitle>}
      <PopperBlock>{props.children}</PopperBlock>
    </Popper>
  );
};

const PopperTitle = styled.ul`
  background-color: ${COLORS.LIGHT_MERON};
  color: ${COLORS.TITLE_BLUE};
  font-size: 14px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  li {
    padding: 10px;
    /* flex: 0 0 33.3333%; */
  }
  li:first-child {
    flex: 0 0 40%;
  }
  li:nth-child(2) {
    flex: 0 0 20%;
  }
  li:nth-child(3) {
    flex: 0 0 20%;
  }
  li:last-child {
    flex: 0 0 20%;
  }
`;

const PopperBlock = styled.div`
  padding: 0px;
  .MuiAutocomplete-option {
    padding: 5px 0px;
  }
  .MuiAutocomplete-paper {
    margin: 0px;
  }
`;
