import { useEffect, useState } from 'react';
import useSubSectorList from "../../../Hooks/useSubSectorList";
import { AuthService } from '../../../services/AuthService';
import { SortString } from '../../../helpers/SortString';
import { getOrDefault } from '../../../helpers/CommonHelper';

const usePersonaForm = ({props}) => {
    
  const { setPersonaGroupList } = useSubSectorList();

  const [groupList, setGroupList] = useState([]);
  const { mode, data, dublicate, personaList, clientName } = props;

  useEffect(() => {
    if(!data){
      const fetchGroupList = async () => {
        try {
          const grp = await AuthService.getPersonaGroupV3(`/${clientName}`);
          const grpSorted = SortString(grp, "personaGroupName").map(
            ({ personaGroupName, personaGroupId }) => ({
              label: personaGroupName,
              value: personaGroupId,
            })
          );
          setGroupList(grpSorted);
          setPersonaGroupList(grpSorted);
        } catch (errors) {
          console.log(errors);
        }
      };
  
      fetchGroupList();
      return () => {
        setGroupList(null);
      };
    }
  }, []);

  const checkPersonaNameExists = async (name) => {
    var response = await AuthService.checkPersonaNameExistsV3(name, clientName, mode === "edit" && !dublicate ? data?.personaId : "")
    if (response.value)
      return "This persona already exists";
    return "";
  }

  const initData = {
    personaGroupId:  getOrDefault(data?.personaGroup?.personaGroupName, ""),
    personaName: getOrDefault(dublicate ? "": data?.personaName, ""),
    reportingTo: getOrDefault(data?.reportingTo, ""),
    rolesResponsibilities: getOrDefault(data?.rolesResponsibilities, ""),
    roleDescription: getOrDefault(data?.roleDescription, "")
  };
    return {
      initData,
      groupList,
      checkPersonaNameExists,
      personaList,
    };
};

export default usePersonaForm;