import React from "react";
import CustomizedTables from "../../../Common/CustomizedTables";
import useSelectRoleSkills from "./useSelectRoleSkills";
import { H5 } from "../../../Common/Typography";
import { PrimaryButton } from "../../../Common/Buttons/PrimaryButton";
import "./SelectRoleSkills.scss";
import { TableColumnsAssignSkills } from "../TableColumnAssignSkills";
import text from '../../../../locale.en.json';


const SelectRoleSkills = ({ roleId, personaId, skillType, roleSelectionData, excludedRoleSkills }) => {
  const {
    roleSkillList,
    isNavigatedFromRoleMatch,
    triggerApi,
    levelChange,
    handleCheckBox,
    handleCheckAll,
    handleAddClick,
    handleDefaultRecommend
  } = useSelectRoleSkills({ roleId, personaId, roleSelectionData, excludedRoleSkills });

  return (
    <div className="role-skills-container">
      {!!roleSkillList && !!roleSkillList.length ? (
        <CustomizedTables
          columns={TableColumnsAssignSkills({
            skillType: skillType,
            isNavigatedFromRoleMatch: isNavigatedFromRoleMatch,
            isIndustryRoleTable: true
          })}
          rowList={roleSkillList}
          triggerApi={triggerApi}
          levelChange={levelChange}
          innerTabel={true}
          tableHeight={roleSkillList?.length > 10 ? 488 : "auto"}
          stickyHeader={roleSkillList?.length > 10}
          handleCheckBox={handleCheckBox}
          handleCheckAll={handleCheckAll}
          handleHeaderMessageClick={handleDefaultRecommend}
        />
      ) : (
        <>
          <H5 className={"display-message"}>{text.skill.allIndustryRoleSkillsAlreadyIncludedInPersona}</H5>
        </>
      )}
      <div className="role-skills-bottom-block">
        {!!roleSkillList?.length && (
          <PrimaryButton
            style={{ marginTop: 20 }}
            onClick={handleAddClick}
            disabled={!roleSkillList.some((skill) => !!skill?.checked)}
          >
            {text.skill.addSkillsToPersona}
          </PrimaryButton>
        )}
      </div>
    </div>
  );
};

export default SelectRoleSkills;
