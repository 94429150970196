import React from 'react';
import { Images } from '../../../../assets/images';
import ModalPopup from '../../../Common/ModalPopup';
import text from "../../../../locale.en.json";
import "./FetchingPersonasModal.scss";

function FetchingPersonasModal({ open, close, closeDurationInSec }) {
    const FetchingPersonasContainer = () => {
        return (
            <div className="create-persona-in-progress-container">
                <div className="in-progress-image">
                    <img src={Images.icons.timeTrackIcon}/>
                </div>
                <div className="in-progress-message">
                    <div>{text.fileSelection.fileProcessing}</div>
                    <div>{text.fileSelection.fileProcessingNote}</div>
                </div>
            </div>
        );
    };
     
    return open ? (
        <ModalPopup
            open={true}
            title={text.persona.jobDescriptiondDataInProgress}
            center={true}   
            closePopup={close}
            closeDurationInSec={closeDurationInSec}
        >
            <FetchingPersonasContainer />
        </ModalPopup>
    ) : null;
}

export default FetchingPersonasModal;