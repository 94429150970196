import React from "react";
import COLORS from "../../../assets/colors";
import { H5 } from "../Typography";

export default ({
  color = COLORS.BAR_RED,
  padding = 20,
  textAlign = "center",
  text = "",
}) => {
  return <H5 style={{ color, padding, textAlign }}>{text}</H5>;
};
