import React, { useState, useEffect, useRef } from "react";
import { navigate } from "@reach/router";
import { Formik, Form } from "formik";

import Layout from "../../Layout";
import { H2 } from "../../Common/Typography";
import {
  BodyContent,
  Container,
  BodyWhitePattern,
} from "../../Common/GridSystem";
import styled from "styled-components";
import BackNav, { BackCheck } from "../../Common/BackNav";
import { PrimaryButton } from "../../Common/Buttons/PrimaryButton";
import FormTextField from "../../Common/FormElements/FormTextField";
import DropDownState from "../../Common/FormElements/DropDownState";
import { AuthService } from "../../../services/AuthService";
import { RoleServices } from "../../../services/RoleServices";
import { SortString } from "../../../helpers/SortString";
import { roleManageMentValidation } from "../../../validations/roleValidation";
import ConfirmationButton from "../../Common/ConfirmationButton";
import ModalPopup from "../../Common/ModalPopup";
import useAlert from "../../../Hooks/useAlert";
import useFullLoader from "../../../Hooks/useFullLoader";
import { SecondaryButton } from "../../Common/Buttons/SecondaryButton";
import OccupationProvider from "../../../Provider/OccupationProvider";
import useOccupation from "../../../Hooks/useOccupation";
import RoleSkills from "./RoleSkills";
import DropDownStateSubSector from "../../Common/FormElements/DropDownStateSubSector";
import DropDown from "../../Common/FormElements/DropDown";
import useAuth from "../../../Hooks/useAuth";
import AutoSelect from "../../Common/FormElements/AutoSelect";
import { GenAiButton } from "../../Common/Buttons/GenAiButton";
import { isDisprzAtomicSkillSource, getAiGeneratorTitle, getConfirmText, getGeneratorType, getServiceUrl, showAiGeneratorModal } from "../../../helpers/CommonHelper";
import AIGenerator from "../../Common/AIGenerator";
import { roleGeneratorMapping } from "../../../helpers/GeneratorMappingHelper";
import { AIStore, IndustreRoleStore } from "../../../store/store_state";
import { SourceType } from "../../../enums/SourceType";
import _ from "lodash";
import COLORS from "../../../assets/colors";
import { renderAIButton } from "../../Common/Buttons/RenderAiButton";
import { RolePersonas } from "./RolePersonas/RolePersonas";

export default ({ children, ...props }) => (
  <Layout>
    <OccupationProvider>
      <RoleManagementComponent {...props}>{children}</RoleManagementComponent>
    </OccupationProvider>
  </Layout>
);

// let roleDetail = {};
export const RoleManagementComponent = (props) => {
  const { skillstatusMenuList } = useAuth();

  const { mode, id } = props;

  const initData = {
    roleName: "",
    roleDescription: "",
    roleandResponsibilities: "",
    occupation: "",
    roleStatus: 0,
    sourceId: mode === "add" ? SourceType.DISPRZ_ATOMIC_SKILLS : "",
    functionId: "",
    subsectorId: 0,
    leadershipRoleId: 0,
  };
  const { occupations, setSubsectorIds } = useOccupation();

  let [formValues, setFormValues] = useState(initData);
  let [detailData, setDetailData] = useState(null);

  const [leaderRoles, setLeaderRoles] = useState([]);
  const [subSectors, setSubSectors] = useState([]);
  const [subSectorType, setSubSectorType] = useState(1);
  const [functionList, setFunctionList] = useState([]);
  const [functionLoading, setFunctionLoading] = useState(false);
  const [frameworkList, setFrameworkList] = useState([]);
  const [formUpdate, setFormUpdate] = useState(false);

  const [confirmPopup, setConfirmPopup] = useState(false);
  const [confirmType, setConfirmType] = useState(null);
  const [confirmTitle, setConfirmTitle] = useState("");

  const initialAiGeneratorPopUpState = {
    showRoleDescriptionAiSuggestion: false,
    showWorkFunctionAndTasksAiSuggestion: false
  }
  const [showAiGeneratorPopUp, setShowAiGeneratorPopUp] = useState(initialAiGeneratorPopUpState);
  const roleSourcedId = IndustreRoleStore.useState((s) => s.sourceId);
  const roleDetail = AIStore.useState((s) => s.roleDetail);

  const formRef = useRef();
  const { showAlert } = useAlert();
  const { setFullLoader } = useFullLoader();

  const getStaticList = async () => {
    try {
      const resSubSectors = await AuthService.subSectorListV3();
      const sorted = SortString(resSubSectors, "subsector");
      setSubSectors(
        sorted.map((el) => ({
          label: el.subsector,
          value: el.subsectorId,
          type: el.subsectorTypeId,
        }))
      );
      getFrameWorkList();
    } catch (err) {
      console.log(err);
    }
  };

  const getLeaderRoles = async () => {
    try {
      const res = await AuthService.leaderShipRolesV3();
      setLeaderRoles(
        res.map((el) => ({
          label: el.leadershipRoleName,
          value: el.leadershipRoleId,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  const getFunctionsList = async (subsectorId) => {
    setFunctionLoading(true);
    try {
      const res = await AuthService.functionsListV3(
        subsectorId !== 0 ? `?subSectorIds=${subsectorId}` : ""
      );
      const sorted = SortString(res, "functionName");
      setFunctionList(
        sorted.map((el) => ({ label: el.functionName, value: el.functionId }))
      );
    } catch (error) {
      console.log(error);
    } finally {
      setFunctionLoading(false);
    }
  };

  const getFrameWorkList = async () => {
    try {
      const res = await AuthService.sourceListV3();
      // const sorted = await res.map((el) => Boolean && el.trim()).sort();
      const sorted = SortString(
        res.filter((el) => !!el.source),
        "source"
      );
      setFrameworkList(
        sorted.map((el) => ({ label: el.source, value: el.sourceId }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  const resetAiRoleStore = () => {
    AIStore.update((s) => {
      s.aiSuggestedRoleDescription = [];
      s.aiSuggestedWorkFunctionAndTasks = [];
    })
  };

  const getRoleDetail = async () => {
    setFullLoader(true);
    try {
      const res = await RoleServices.getRoleV3(`/${id}`);
      // console.log("getRoleDetail -> res", res);
      let newRoleDetail = {
        roleName: res?.roleName, 
        industry: res?.subsector, 
        department: res?.functionName, 
        leadershipRole: res?.leadershipRole.leadershipRoleName
      }
      if(JSON.stringify(roleDetail) !== JSON.stringify(newRoleDetail)){
        AIStore.update((s) => {
          s.roleDetail = newRoleDetail;
        })
        resetAiRoleStore();
      }
      IndustreRoleStore.update((s) => {s.sourceId = mode === "add" ? SourceType.DISPRZ_ATOMIC_SKILLS  : res?.sourceId});
      setDetailData({
        ...res,
        functionId: { label: res?.functionName, value: res?.functionId },
        leadershipRoleId: res?.leadershipRole?.leadershipRoleId,
      });
      setFormValues({
        ...res,
        functionId: { label: res?.functionName, value: res?.functionId },
        leadershipRoleId: res?.leadershipRole?.leadershipRoleId,
      });
      getFunctionsList(res.subsectorId);

      setSubsectorIds([res.subsectorId]);
      setSubSectorType(res.subsectorTypeId);
    } catch (error) {
      showAlert(error.error, "error", 1500);
      console.log(error);
    } finally {
      setFullLoader(false);
    }
  };

  useEffect(() => {
    getStaticList();
    getLeaderRoles();
    if (mode === "edit") {
      getRoleDetail();
    }
    setSubsectorIds([""]);
    return () => {
      setSubSectors([]);
    };
  }, []);

  useEffect(() => {
    if (!!skillstatusMenuList?.length && !!detailData) {
      const checkStatus = skillstatusMenuList.some(
        (status) => status.value === detailData?.roleStatus
      );
      if (!checkStatus) {
        showInfoAndNavigate();
      }
    }
  }, [skillstatusMenuList, detailData]);

  const showInfoAndNavigate = async () => {
    await showAlert("User doesn't have access to this page", "Info", 1500);
    navigate("/");
  };

  const formSubmit = (val) => {
    setFormValues(val);
    // console.log(makeVal);
    // return false;
    if (mode === "edit") {
      setConfirmTitle("Confirm Changes");
    } else {
      setConfirmTitle("Confirm Role Addition");
    }
    setConfirmPopup(true);
    setConfirmType("addUpdate");
  };

  const addOrUpdate = async () => {
    setFullLoader(true);
    const functionIdPostData = {
      functionName: formValues.functionId,
      subsectorId: formValues.subsectorId,
    };
    const makeVal = {
      ...formValues,
      occupation:
        typeof formValues.occupation === "string"
          ? formValues.occupation
          : formValues.occupation.value,
      functionId:
        typeof formValues.functionId === "string"
          ? await handleCreateFunctionId(functionIdPostData)
          : formValues.functionId.value,
    };
    // console.log("addOrUpdate -> makeVal", makeVal);
    // return false;
    // setFullLoader(true);
    try {
      if (mode === "edit") {
        await RoleServices.updateRoleV3("/" + Number(id), makeVal);
        await showAlert("Role Updation Successful", "success", 1500);
        closePopup();
      } else {
        const roleId = await RoleServices.addRoleV3(makeVal);
        await showAlert("Role Creation Successful", "success", 1500);
        closePopup();
        setTimeout(() => navigate(`edit/${roleId}`), 100);
      }
    } catch (err) {
      showAlert(err, "error", 1500);
    } finally {
      setFullLoader(false);
    }
  };

  const handleCreateFunctionId = async (values) => {
    if (functionList.some((el) => el.label === values.functionName)) {
      return functionList.find((el) => el.label === values.functionName).value;
    } else {
      try {
        const res = await RoleServices.addFunctionIdV3(values);
        return res;
      } catch (err) {
        showAlert(err, "error", 1500);
      } finally {
        setFullLoader(false);
      }
    }
  };

  const handleDeleteRole = async () => {
    setFullLoader(true);
    try {
      await RoleServices.deleteRoleV3("/" + Number(id));
      await showAlert("Role Deletion Successful", "success", 2500);
      closePopup();
      await setFullLoader(false);
      setTimeout(() => navigate(`/role`), 100);
    } catch (err) {
      showAlert(err, "error", 1500);
    } finally {
      setFullLoader(false);
    }
  };

  const onChange = (e, setFieldValue) => {
    const targetEl = e.target;
    const fieldName = targetEl.name;
    // setFormValues({
    //   ...formValues,
    //   [fieldName]: targetEl.value,
    // });
    setFieldValue(fieldName, targetEl.value);
    if (targetEl.name === "subsectorId") {
      setSubSectorType(
        subSectors.find((el) => el.value === targetEl.value).type
      );
      setFieldValue("functionId", "");
      getFunctionsList(targetEl.value);
      setSubsectorIds([targetEl.value]);
    }
  };

  const handleDelete = () => {
    setConfirmType("delete");
    setConfirmTitle("Delete Role - " + formValues.roleName + " ?");
    setConfirmPopup(true);
  };

  const handleYes = () => {
    if (confirmType === "delete") {
      handleDeleteRole();
    } else if (confirmType === "addUpdate") {
      addOrUpdate();
    } else if (confirmType === "navi") {
      navigate("/role");
    } else {
      closePopup();
    }
  };

  const handleNo = () => closePopup();

  const closePopup = () => {
    setConfirmPopup(false);
    setConfirmType(null);
    setConfirmTitle("");
  };

  const handleCheckBack = () => {
    setConfirmPopup(true);
    setConfirmType("navi");
    setConfirmTitle("Proceed without saving changes?");
  };

  const formChanges = (dirty) => setFormUpdate(dirty);


  const handleAiGeneratorCancelClick = () => {
    setShowAiGeneratorPopUp(initialAiGeneratorPopUpState);
  }

  const handleAiGeneratorConfirmClick = (value = "", values = "", setFieldValue) => {
    if(showAiGeneratorPopUp.showRoleDescriptionAiSuggestion){
      setFieldValue('roleDescription', values?.roleDescription ? `${values.roleDescription} ${value}` : value);
    } else if(showAiGeneratorPopUp.showWorkFunctionAndTasksAiSuggestion){
      setFieldValue('roleandResponsibilities', values?.roleandResponsibilities ? `${values.roleandResponsibilities}\r\n\r\n${value}` : value);
    }
    handleAiGeneratorCancelClick();
    return;
  }

  const getStoreKey = () => {
    if(showAiGeneratorPopUp.showRoleDescriptionAiSuggestion){
      return 'aiSuggestedRoleDescription';
    }else if(showAiGeneratorPopUp.showWorkFunctionAndTasksAiSuggestion){
      return 'aiSuggestedWorkFunctionAndTasks';
    }
  }

  const checkAndShowAiGeneratorPopUp = (values, popUpToShow) => {
    let roleName = values?.roleName;
    let industry = values?.subsectorId
                      ? subSectors.reduce((acc, item) => {
                          if (item.value === values.subsectorId) {
                            return item.label;
                          }
                          return acc;
                        }, '')
                      : '';
    let department =  (values?.functionId && typeof values?.functionId === 'object')
                          ? values?.functionId?.label
                          : values?.functionId;
    let leadershipRole = values?.leadershipRoleId
                          ? leaderRoles.reduce((acc, item) => {
                              if (item.value === values.leadershipRoleId) {
                                return item.label;
                              }
                              return acc;
                            }, '')
                          : '';
    const emptyFields = [];
    if (!roleName) {
      emptyFields.push('Role Name');
    }
    if (!industry) {
      emptyFields.push('Industry');
    }
    if (!department) {
      emptyFields.push('Department');
    }
    if (!leadershipRole) {
      emptyFields.push('Leadership Role');
    }

    if (emptyFields.length > 0) {
      const emptyFieldsList = emptyFields.join(', ');
      showAlert(`Please fill in the following fields: ${emptyFieldsList}`, "info", 1500);
      return;
    }else {
      let  currRoleDetail = {
        roleName: roleName, 
        industry: industry, 
        department: department, 
        leadershipRole: leadershipRole
      };
        if(!_.isEqual(roleDetail, currRoleDetail)){
          AIStore.update((s) => {
            s.roleDetail = currRoleDetail;
          });
          resetAiRoleStore();
        }
        setShowAiGeneratorPopUp({[popUpToShow]: true});
    }
  }

  //   console.log(formRef);formUpdate
  // console.log({ functionList });
  return (
    <>
      <H2>Role Management</H2>
      <BodyContent>
        <Container>
          {formUpdate ? (
            <BackCheck handleCheckBack={handleCheckBack} />
          ) : (
            <BackNav linkNav="/role" />
          )}
          {/* <BackNav linkNav="/role" /> */}
          <BodyWhitePattern>
            <Formik
              enableReinitialize={true}
              initialValues={formValues}
              validationSchema={roleManageMentValidation}
              onSubmit={formSubmit}
              innerRef={formRef}
            >
              {({ setFieldValue, setFieldTouched, values, dirty }) => {
                // console.log("RoleManagementComponent -> values", values);
                // formChanges(dirty);
                return (
                  <FormBlock>
                    <FormTextField
                      label="Role Name"
                      name="roleName"
                      style={{ width: "50%" }}
                      setVal={setFieldValue}
                      setTouched={setFieldTouched}
                    />
                    <SelectBlock>
                      <DropDownStateSubSector
                        list={subSectors}
                        name="subsectorId"
                        width="190px"
                        label={subSectorType === 1 ? "Industry" : "Function"}
                        handleChange={(e) => onChange(e, setFieldValue)}
                        value={values.subsectorId}
                      />
                      {/* <DropDownState
                        list={functionList}
                        name="function"
                        width="190px"
                        label={subSectorType === 1 ? "Department" : "Track"}
                        handleChange={(e) => onChange(e, setFieldValue)}
                        value={values.function}
                      /> */}
                      {/* <DropDownState
                        list={occupations}
                        name="occupation"
                        width="190px"
                        label="Occupation"
                        handleChange={(e) => onChange(e, setFieldValue)}
                        value={values.occupation}
                      /> */}
                      {!!functionList && (
                        <div className="auto-search-role">
                          <AutoSelect
                            label={subSectorType === 1 ? "Department" : "Track"}
                            name="functionId"
                            list={functionList}
                            optionLabel="label"
                            renderOption={(option) => (
                              <OptionCutom>{option.label}</OptionCutom>
                            )}
                            freeSolo={false}
                            disabled={
                              functionLoading || values.subsectorId === 0
                            }
                          />
                        </div>
                      )}
                      {!!occupations && (
                        <div className="auto-search-role">
                          <AutoSelect
                            label="Occupation"
                            name="occupation"
                            list={occupations}
                            optionLabel="label"
                            renderOption={(option) => (
                              <OptionCutom>{option.label}</OptionCutom>
                            )}
                            freeSolo={false}
                          />
                        </div>
                      )}
                      <DropDownState
                        list={leaderRoles}
                        name="leadershipRoleId"
                        width="190px"
                        label="Leadership Role"
                        handleChange={(e) => onChange(e, setFieldValue)}
                        value={values.leadershipRoleId}
                      />
                      <div className="source-dropDown">
                        <DropDown
                          list={frameworkList}
                          name="sourceId"
                          width="175px"
                          label="Source"
                          disabled={true}
                        />
                      </div>

                      <DropDown
                        list={skillstatusMenuList}
                        name="roleStatus"
                        width="190px"
                        label="Status"
                      />
                    </SelectBlock>
                    <RoleDescriptionX>
                      {renderAIButton({mode, sourceId: roleSourcedId, clickHandler:() => checkAndShowAiGeneratorPopUp(values, 'showRoleDescriptionAiSuggestion'), style: {position: 'absolute', top: '-5px', left: '135px'}})}
                      <FormTextField
                        multiline={true}
                        disabled={false}
                        rows="5"
                        rowsMax="1000"
                        name="roleDescription"
                        label="Role Description"
                        setVal={setFieldValue}
                        setTouched={setFieldTouched}
                      />
                    </RoleDescriptionX>
                    <RoleDescriptionX>
                       {renderAIButton({mode, sourceId: roleSourcedId, clickHandler:()=> checkAndShowAiGeneratorPopUp(values, 'showWorkFunctionAndTasksAiSuggestion'), style:{position: 'absolute', top: '-5px', left: '260px'}})}
                      <FormTextField
                        multiline={true}
                        disabled={false}
                        rows="5"
                        rowsMax="1000"
                        name="roleandResponsibilities"
                        label="Critical Work Functions and Tasks"
                        helperText="(Separate the various work functions by $ i.e. dollar symbol and tasks by | i.e. pipe symbol)"
                        setVal={setFieldValue}
                        setTouched={setFieldTouched}
                      />
                    </RoleDescriptionX>
                    {mode === "edit" && <>
                        <div className="industry-role-skills">
                            <RoleSkills roleId={id} type="INDUSTRY" />
                        </div>
                        <RolePersonas roleId={id} type="INDUSTRY"/>
                      </>}
                    <PrimaryButton type="submit">
                      {mode === "edit" ? "Update Role" : "Add Role"}
                    </PrimaryButton>
                    {mode === "edit" && (
                      <SecondaryButton
                        onClick={handleDelete}
                        style={{ marginLeft: 15 }}
                      >
                        Delete Role
                      </SecondaryButton>
                    )}
                    <ModalPopup 
                      open={showAiGeneratorModal(showAiGeneratorPopUp)} 
                      title={getAiGeneratorTitle(showAiGeneratorPopUp, roleGeneratorMapping)} 
                      maxWidth="lg"
                      center={true}
                    >
                        <AIGenerator
                          generatorType={getGeneratorType(showAiGeneratorPopUp, roleGeneratorMapping)}
                          getServiceUrl={getServiceUrl(showAiGeneratorPopUp, roleGeneratorMapping)}
                          params={roleDetail}
                          cancelText="Close"
                          confirmText={getConfirmText(showAiGeneratorPopUp, roleGeneratorMapping)}
                          onCancel={() => handleAiGeneratorCancelClick()}
                          onConfirm={(value) => handleAiGeneratorConfirmClick(value, values, setFieldValue)}
                          aiStoreKey={getStoreKey()}
                        />
                    </ModalPopup>
                  </FormBlock>
                );
              }}
            </Formik>
          </BodyWhitePattern>
        </Container>
      </BodyContent>
      <ModalPopup
        open={confirmPopup}
        maxWidth="sm"
        title={confirmTitle}
        center={true}
      >
        <ConfirmationButton yesClick={handleYes} noClick={handleNo} />
      </ModalPopup>
    </>
  );
};

const FormBlock = styled(Form)`
  width: 80%;
  @media (max-width: 1100px) {
    width: 100%;
  }
  .auto-search-role {
    width: 220px;
    label {
      line-height: 21px;
    }
    & > div {
      padding-bottom: 0px;
    }
    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]
      .MuiAutocomplete-input {
      padding: 9px 4px;
    }
  }
  .industry-role-skills{
      border-radius: 4px;
      margin-bottom: 30px;
      margin-top: 10px;
      border: 1px solid gainsboro;
      width: calc(100vw - 245px);
    >:first-child{
      padding: 20px !important;
      box-shadow: none !important;
    }
  }
`;

const SelectBlock = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 30px;
  & > div {
    margin-right: 15px;
  }
  .source-dropDown {
    .MuiSelect-selectMenu{
      color: ${COLORS.PRIMARY_BLACK};
      padding-right: 10px;
      text-align: center
    }
    .MuiSelect-icon{
      display: none;
    }
  }
`;

const OptionCutom = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  width: 100%;
  span {
    padding: 10px;
    /* flex: 0 0 33.3333%; */
    &:first-child {
      flex: 0 0 50%;
    }
    &:nth-child(2) {
      flex: 0 0 25%;
    }
    &:last-child {
      flex: 0 0 25%;
    }
  }
`;

const RoleDescriptionX = styled.div`
 position: relative;
 label{
  max-width: fit-content;
 }
 >div{
  margin-top: 10px;
  >div{
    >div{
      margin-top: 10px;
    }
  }
 }
`
