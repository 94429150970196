import React, { memo } from "react";
import styled from "styled-components";
import SkillColumn from "./SkillColumn";
import IndustryProficiency from "./IndustryProficiency";
import RecommendColumn from "./RecommendColumn";
import { PrimaryButton } from "../../../Common/Buttons/PrimaryButton";
import { SecondaryButton } from "../../../Common/Buttons/SecondaryButton";
import SkillCriticalityColumn from "./SkillCriticalityColumn";
import SourceColumn from "./SourceColumn";
import RemoveSkillColumn from "./RemoveSkillColumn/RemoveSkillColumn";

export default memo((props) => {

  return (
    <TableContentBlock>
      <div className="tabBlock">
        <SkillColumn {...props} />
        <RecommendColumn {...props} />
        <SkillCriticalityColumn {...props} />
        <IndustryProficiency {...props} />
        <SourceColumn {...props} />
        <RemoveSkillColumn {...props} />
      </div>
      <div className="btn-block">
        <SecondaryButton onClick={props.addClick}>Add Skill +</SecondaryButton>
        <PrimaryButton
          onClick={props.updateClick}
        >
          Save
        </PrimaryButton> 
        <SecondaryButton onClick={props.clearMappingClick} className="clear-mapping">
          Clear Mapping
        </SecondaryButton>
      </div>
    </TableContentBlock>
  );
});

const TableContentBlock = styled.div`
  min-height: 300px;
  display: inline-flex;
  flex-direction: column;
  .tabBlock {
    display: flex;
    padding-bottom: 25px;
    & > div > h5 {
      padding-bottom: 38px;
    }
    ul {
      li {
        margin: 5px 2px 5px;
        line-height: 22px;
        min-height: 44px;
        padding: 10px 15px;
        & > * {
          padding-bottom: 0px;
        }
      }
    }
  }
  .compare-btn {
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-top: 48px;
  }
  .btn-block {
    display: flex;
    justify-content: space-between;
    .clear-mapping{
      margin-right: 58px;
      visibility: hidden;
    }
  }
`;
