import React, { createContext, useState, useMemo, useEffect } from "react";
import { SkillStatusMenu, SkillStatusMenuSME } from "../components/utils";
import { User } from "../store";

export const AuthContext = createContext();

export default function AuthProvider({ children }) {
  const userDetailsFromCookies = User.getUserDetails();

  const [isUserLoggedIn, setIsUserLoggedIn] = useState(
    !!userDetailsFromCookies
  );
  const [userDetails, setUserDetails] = useState({});
  const [skillstatusMenuList, setSkillStatusMenuList] = useState([]);

  useEffect(() => {
    const userDetailsFromCookies = User.getUserDetails();

    if (isUserLoggedIn) {
      // console.log(userDetailsFromCookies);
      setUserDetails(userDetailsFromCookies);
      setIsUserLoggedIn(true);

      if (userDetailsFromCookies.User_Type === 3) {
        setSkillStatusMenuList(SkillStatusMenuSME);
      } else {
        setSkillStatusMenuList(SkillStatusMenu);
      }
    } else {
      setIsUserLoggedIn(false);
    }
  }, []);

  const value = useMemo(() => {
    return {
      isUserLoggedIn,
      setIsUserLoggedIn,

      userDetails,
      setUserDetails,

      skillstatusMenuList,
      setSkillStatusMenuList,
    };
  }, [
    isUserLoggedIn,
    setIsUserLoggedIn,
    userDetails,
    setUserDetails,
    skillstatusMenuList,
    setSkillStatusMenuList,
  ]);
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
