import React, { useEffect, useState } from "react";
import { BodyContent, Container } from "../../Common/GridSystem";
import CustomizedTables from "../../Common/CustomizedTables";
import { Role_Selection_Table } from "../RoleSelection/RoleSelectionContentTable";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import { useLocation } from "@reach/router";
import styled from "styled-components";
import ConfirmationButton from "../../Common/ConfirmationButton";
import SelectMenu from "../../Common/FormElements/SelectMenu";
import { AuthService } from "../../../services/AuthService";
import PaginationCustom from "../../Common/PaginationCustom";
import { RoleServices } from "../../../services/RoleServices";
import useFullLoader from "../../../Hooks/useFullLoader";
import useAlert from "../../../Hooks/useAlert";
import Layout from "../../Layout";
import { H2, H5 } from "../../Common/Typography";
import { BackCheck } from "../../Common/BackNav";
import { PrimaryButton } from "../../Common/Buttons/PrimaryButton";
import { SecondaryButton } from "../../Common/Buttons/SecondaryButton";
import { navigate } from "@reach/router";
import ModalPopup from "../../Common/ModalPopup";
import ProgressIndicator from "../../Common/ProgressIndicator";
import { getOrDefault, isValidUser } from "../../../helpers/CommonHelper";
import { ComparePersonaStore, SelectedRoleSkillsStore, resetStore } from "../../../store/store_state";
import { UserType } from "../../../enums/UserType";
import useAuth from "../../../Hooks/useAuth";
import RolesAndResponsibilitiesPopup from "../../Common/PopUp/RolesAndResponsibilitiesPopup/RolesAndResponisbilitiesPopup";
import { SortString } from "../../../helpers/SortString";
import COLORS from "../../../assets/colors";
import DownloadFile from "../../../helpers/DownloadFile";
import { ExportProcessType } from "../../../enums/ExportProcessType";
import { InitialComparePersonaStore, InitialSelectedRoleSkillsStore } from "../../../store/initial_store_state";
import text from "../../../locale.en.json";

export default (props) => (
  <Layout>
    <RoleSelection {...props} />
  </Layout>
);

// let progress = 0;
export const RoleSelection = (props) => {
  const { fullLoader, setFullLoader } = useFullLoader();
  const { showAlert } = useAlert();
  const { userDetails } = useAuth();
  const { User_Type } = userDetails;
  const [roleSelectionData, setRoleSelectionData] = useState([]);
  const [subSectorIds, setSubSectorIds] = useState([]);
  const [roleSelectionDataCopy, setRoleSelectionDataCopy] = useState([]);
  const [contentLoading, setContentLoading] = useState(false);
  const [pageOffset, setPageOffset] = useState(null);
  const [rowCount, setrowCount] = useState(null);
  const [currPage, setCurrPage] = useState(null);
  const [proceedConfirmModal, setProceedConfirmModal] = useState(false);
  const [roleSelectionDataToSave, setRoleSelectionDataToSave] = useState([]);
  const [groupNames, setGroupNames] = useState([]);
  const [roleSelectionOriginData, setRoleSelectionOriginData] = useState([]);
  const [roleSelectionOriginDataCopy, setRoleSelectionOriginDataCopy] =
    useState([]); // used for Global search
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [warningPopUp, setWarningPopUp] = useState(false);
  const [confirmType, setConfirmType] = useState(null);
  const [confirmTitle, setConfirmTitle] = useState("");
  const [roleSearchText, setRoleSearchText] = useState("");
  const [currentItem, setCurrentItem] = useState(null);
  const [showSearchIcon, setShowSearchIcon] = useState(true);
  const [selectedGroup, setSelectedGroup] = useState(0);
  const { state } = useLocation();
  const { clientName, importTokenId } = state;
  const [selectedContentItemsContainer, setSelectedContentItemsContainer] = useState([]);
  const [isSavingNewlySelectedRole, setIsSavingNewlySelectedRole] = useState(false);
  const [personaDetail, setPersonaDetail] = useState(null);
  const [industryroleDetail, setIndustryroleDetail] = useState(null);
  const [isRoleDetailPopupOpen, setIsRoleDetailPopupOpen] = useState(false);
  const [rolesAndResponsibilitiesDictionary, setRolesAndResponsibilitiesDictionary] = useState({});
  const [showDownload, setShowDownload] = useState(false);
  const [exportParserMeta, setExportParserMeta] = useState(null);
  const [progress, setProgress] = useState(0);
  const [isExportInProgress, setIsExportInProgress] = useState(false);
  const [paginationKey, setPaginationKey] = useState(Date.now());

  const handlePagination = (e, page) => {
    setCurrPage(page);
    setPageOffset((page - 1) * rowCount);
  };
  const changeRowCount = (count) => {setrowCount(count); setPageOffset(0); setCurrPage(1); setPaginationKey(Date.now());};

  useEffect(() => {
    if (roleSelectionDataToSave.length > 0) {
      getRoleSelectionData(rowCount, pageOffset);
    }
  }, [pageOffset, rowCount]);

  useEffect(() => {
    if (roleSelectionDataToSave.length === 0) {
      getRoleSelectionDataToSave();
      getSubSectorsList();
    }
  }, []);

  const getRoleSelectionStatus = async () => {
    setIsExportInProgress(true);
    const interval = setInterval(async () => {
      try {
        const excelStatus = await RoleServices.GetRoleSelectionDataExportExcelStatusV3(clientName, importTokenId, "");
        excelStatus.totalItems !== 0 &&  setProgress((excelStatus.itemsCompleted / excelStatus.totalItems) * 100);
        if (!!excelStatus && excelStatus.status === ExportProcessType.COMPLETED && !!excelStatus.fileUrl) {
          setIsExportInProgress(false);
          setShowDownload(true);
          setExportParserMeta(excelStatus);
          clearInterval(interval);
          setFullLoader(false);
          setProgress(0);
          await getRoleSelectionDataToSave(true);
        } else if (!!excelStatus && excelStatus.status !== ExportProcessType.IN_PROGRESS) {
          clearInterval(interval);
          setFullLoader(false);
          showAlert(excelStatus.errorMessage, "error", 2000);
        } else if(!fullLoader) {
          setFullLoader(true);
        }
      } catch (error) {
        console.error("Error in fetching data:", error);
        setFullLoader(false);
        clearInterval(interval);
      }
    }, 1500); 
  }

  const fetchDataAndUpdateState = async (triggerExport = false) => {
    try {
      setFullLoader(true);
      if(triggerExport) {
        await partialSaveRoleSelectionData(triggerExport);
        await RoleServices.ExportRoleSelectionDataV3(clientName, importTokenId, "");
      }
      const initialExcelStatus = await RoleServices.GetRoleSelectionDataExportExcelStatusV3(clientName, importTokenId, "");
      if (!!initialExcelStatus && initialExcelStatus?.status === ExportProcessType.COMPLETED && !!initialExcelStatus?.fileUrl) {
        setShowDownload(true);
        setExportParserMeta(initialExcelStatus);
        setFullLoader(false);
        setProgress(0);
        await getRoleSelectionDataToSave(true);
      } else if (!!initialExcelStatus && initialExcelStatus.status !== ExportProcessType.IN_PROGRESS) {
        setFullLoader(false);
      } else if (!!initialExcelStatus && initialExcelStatus.status === ExportProcessType.IN_PROGRESS) {
        initialExcelStatus.totalItems !== 0 && setProgress((initialExcelStatus.itemsCompleted / initialExcelStatus.totalItems) * 100);
        await getRoleSelectionStatus();
      }
    } catch (error) {
      console.error("Error fetching initial data:", error);
      setFullLoader(false);
    }
  };
  
  useEffect(() => {
    if(importTokenId && !isValidUser(User_Type, UserType.GENERAL_USER) && !isValidUser(User_Type, UserType.GENERAL_USER_INTERNAL)) {
      fetchDataAndUpdateState();
    }
  }, [importTokenId]);

  

  const getSubSectorsList = async () => {
      try {
          const subSectorList = await AuthService.subSectorListV3(`?clientId=${clientName}`);
          const subSectorIdsForCurrentClient = subSectorList?.map(r=>r.subsectorId);
          setSubSectorIds(subSectorIdsForCurrentClient);
          setFullLoader(false);
      } catch (error) {
          console.log(error);
          setFullLoader(false);
      }
  };

  const getRoleSelectionDataToSave = async (isContentFetched = false) => {
    try {
      setFullLoader(true);
      if(currPage === null && !isContentFetched){
        setContentLoading(true);
      }
      const response = await RoleServices.getImportPersonaDetails(clientName);
      response.sort((a, b) => a.isDuplicatePersona - b.isDuplicatePersona);
      let Role_Selection_array = [];
      const duplicatePersonaCount = response?.filter(r => r.isDuplicatePersona)?.length;
      const uniqueGroupNames = ["All"].concat([...new Set(response?.map(r=>r.groupName))]);
      let updatedRoleandResponsibilities = {}
      response.forEach((data, index) => {
        updatedRoleandResponsibilities[data.personaName] = data?.rolesAndResponsibilities;
        const selectedRecData = data.roleRecommendations.find((recData) => recData.isSelected);
        Role_Selection_array.push({
          clientId: index,
          clientRole: data.personaName,
          selectedRole: getOrDefault(selectedRecData?.roleName , ''),
          selectedRoleId: getOrDefault(selectedRecData?.roleId , ''),
          roleIdSearched: selectedRecData?.isSearchedRole ? selectedRecData?.roleId : '',
          groupName: data.groupName,
          isSkillMappingEdited: data.isSkillMappingEdited,
          isSkillMappingViewed: data.isSkillMappingViewed,
          industryRoleSkills: data?.industryRoleSkills || [],
          checked: !!data?.isSelected,
          initialSelectedRoleId: getOrDefault(selectedRecData?.roleId , ''),
          shouldCallGetIndustryRoleSkills: getOrDefault(data?.shouldCallGetIndustryRoleSkills, false),
          disableRow: data.isDuplicatePersona,
          disableRowTitle: text.persona.personaExists
        });
        data.roleRecommendations.forEach((recData, recDataIndex) => {
          Role_Selection_array = Role_Selection_array.map((roleData) =>
            roleData.clientId === index
              ? {
                  ...roleData,
                  [`roleMatch${recDataIndex + 1}`]: recData?.roleName || "",
                  [`roleScore${recDataIndex + 1}`]: recData?.score || "",
                  [`roleId${recDataIndex + 1}`]: recData?.roleId || ""
                }
              : { ...roleData }
          );
        });
      });
      setRoleSelectionDataToSave(response);
      setRoleSelectionOriginData(Role_Selection_array);
      setRoleSelectionOriginDataCopy(Role_Selection_array);
      setGroupNames(uniqueGroupNames);
      setRolesAndResponsibilitiesDictionary(updatedRoleandResponsibilities);
      setFullLoader(false);
      setContentLoading(false);
      if(currPage === null){
        setrowCount(10);
        setPageOffset(0);
        setCurrPage(1);
      }
      else {
        const roleSelectionDataX = [...Role_Selection_array].slice(pageOffset, rowCount + pageOffset);
        setRoleSelectionData(roleSelectionDataX);
        setRoleSelectionDataCopy(roleSelectionDataX);
      }
      setSelectedContentItemsContainer(Role_Selection_array.filter((role) => role.checked).map((elem) => elem.clientId) || []);
      if (duplicatePersonaCount > 0) {
          showAlert(`#${duplicatePersonaCount} Client roles already exist and will be omitted for persona creation`, "info", 2500);
          if (duplicatePersonaCount === response.length) {
              handleCheckBack();
          }
      }
    } catch (error) {
      console.log(error);
      setFullLoader(false);
      setContentLoading(false);
    }
  };

  const getRoleSelectionData = (rowCountX, pageOffsetX) => {
    let roleSelectionData = roleSelectionOriginData.slice(
      pageOffsetX,
      rowCountX + pageOffsetX
    );
    setRoleSelectionData(roleSelectionData);
    setRoleSelectionDataCopy(roleSelectionData);
    setFullLoader(false);
    setContentLoading(false);
  };

  const handleGroupFilter = (val) => {
    setSelectedGroup(val);
    const selectedGroupName = groupNames.find((_, index) => index === val);
    let updatedRoleSelectionOriginData = [...roleSelectionOriginDataCopy];
    if (selectedGroupName && val !== 0) {
        updatedRoleSelectionOriginData = updatedRoleSelectionOriginData?.filter((data) => data?.groupName === selectedGroupName);
    }
    let roleSelectionData = updatedRoleSelectionOriginData.slice(0, 10);
    setPageOffset(0);
    setrowCount(10);
    setCurrPage(1);
    setRoleSelectionOriginData(updatedRoleSelectionOriginData);
    setRoleSelectionData(roleSelectionData);
  };

  const updateRoleSelectionData = (
    updateKey,
    itemToBeUpdated,
    clientId,
    toToggle,
    selectedRoleId,
    isSearchedRole = false
  ) => {
    const updatedRoleSelectionOriginData = [...roleSelectionOriginData].map((data) => {
      const isAlreadySelected = toToggle === true && data[updateKey] === itemToBeUpdated;
        if (data?.clientId === clientId) {
            return {
                ...data,
                [updateKey]: isAlreadySelected ? "" : itemToBeUpdated,
                ["showError"]: ![updateKey] || isAlreadySelected,
                ["selectedRoleId"]: !isAlreadySelected ? selectedRoleId : "",
                ["isSearchedRole"]: isSearchedRole,
                ["shouldCallGetIndustryRoleSkills"]: data?.initialSelectedRoleId !== selectedRoleId
            };
        } else {
            return { ...data };
        }
    });
    let roleSelectionData = updatedRoleSelectionOriginData.slice(
      pageOffset,
      rowCount + pageOffset
    );
    setRoleSelectionOriginData(updatedRoleSelectionOriginData);
    setRoleSelectionData(roleSelectionData);
    setRoleSelectionDataCopy(roleSelectionData);
    updateOriginRoleSelectionDataCopy(updatedRoleSelectionOriginData);
  };

  const handleCheckBack = () => navigate("/personas");

  const triggerSearchApi = async (searchString, reason) => {
    const searchData = {
      roleName: searchString,
      subSectorIds: subSectorIds,
      functionIds: [],
      occupations: [],
      roleStatuses: [4],
      sourceIds: [],
      pagesize: 30,
      clientId: clientName,
    };
    const res = await RoleServices.searchRoleV3(searchData, "");
    const filterResult = res?.roles || [];
    return filterResult;
  };

  const levelChange = (item, clientId, isSearchedRole = false) => {
    updateRoleSelectionData("selectedRole", item?.roleName, clientId, false, item?.roleId, isSearchedRole);
  };

  const deleteTrigger = (e, item) => {
    e.stopPropagation();
    setConfirmType("delete");
    setConfirmTitle(`Are you sure you want to delete ${item?.clientRole}?`);
    setConfirmPopup(true);
    setCurrentItem(item);
  };

  const handleYes = () => {
    if (confirmType === "delete") {
      const updatedRoleSelectionOriginData = roleSelectionOriginData?.filter(
        (d) => d.clientId !== currentItem?.clientId
      );
      let roleSelectionData = updatedRoleSelectionOriginData?.slice(
        pageOffset,
        rowCount + pageOffset
      );
      setRoleSelectionOriginData(updatedRoleSelectionOriginData);
      setRoleSelectionData(roleSelectionData);
      setRoleSelectionDataCopy(roleSelectionData);
      closePopup();
    }
  };

  const handleNo = () => closePopup();
  const closePopup = () => {
    setConfirmPopup(false);
    setConfirmType(null);
    setConfirmTitle("");
    setCurrentItem(null);
  };

  const ConfirmModal = () => (
    <ModalPopup
      open={confirmPopup}
      maxWidth="sm"
      title={confirmTitle}
      center={true}
    >
      <ConfirmationButton yesClick={handleYes} noClick={handleNo} />
    </ModalPopup>
  );

  const toggleChange = (item, clientId, roleId) => {
    updateRoleSelectionData("selectedRole", item, clientId, true, roleId);
  };

  const saveRoleSelectionData = async () => {
    setFullLoader(true);
    const updatedRoleSelectionDataToSave = [...roleSelectionDataToSave]
      .map((data) => {
        const dataToUpdate = selectedRoleData.find(
          (d) => d.clientRole === data.personaName
        );
        if (data.personaName === dataToUpdate?.clientRole) {
          const currentReccomendation = data.roleRecommendations?.find(
                  (dr) => dr.roleName === dataToUpdate?.selectedRole
          );
          data.industryRoleId = !!currentReccomendation ? currentReccomendation?.roleId : dataToUpdate?.selectedRoleId;
        }
        const excludedRoleSkillIds = getOrDefault(data?.excludedRoleSkills, [])?.map((skill) => skill.skillId) ?? [];
        return {
          personaName: data.personaName,
          groupName: data.groupName,
          rolesAndResponsibilities: data.rolesAndResponsibilities,
          industryRoleId: data.industryRoleId,
          personaSkills: data.personaSkills,
          proficiencies: data.proficiencies,
          excludedSkillIds: excludedRoleSkillIds,
          roleDescription: data.roleDescription,
        };
      })
      .filter((r) => !!r.industryRoleId);
    const dataToSave = {
      clientId: clientName,
      importTokenId: importTokenId,
      personas: updatedRoleSelectionDataToSave,
    };
    try {
      await RoleServices.saveClientRoles(dataToSave);
      setFullLoader(false);
      showAlert("Client Roles are updated", "success", 1500);
      navigate("/personas");
    } catch (error) {
      console.log(error);
      setFullLoader(false);
      showAlert(error, "error", 2000);
    }
  };

  const partialSaveRoleSelectionData = async (triggerExport = false) => {
    setFullLoader(true);
    
    const updatedRoleSelectionDataToSave = [...roleSelectionDataToSave].map((data) => {
      const dataToUpdate = roleSelectionOriginData.find((d) => d.clientRole === data.personaName);

      if (data.personaName === dataToUpdate?.clientRole) {
        const roleIdToBeRemoved = data.roleRecommendations?.find(item => item?.isSearchedRole && item.isSelected && item.roleId !== dataToUpdate.selectedRoleId)?.roleId;
        data.isSelected = !!dataToUpdate?.checked;
        data.shouldCallGetIndustryRoleSkills = !!dataToUpdate.shouldCallGetIndustryRoleSkills;
        data.roleRecommendations = data.roleRecommendations?.map((item) => {
          return {
            ...item,
            isSelected: item.roleName === dataToUpdate?.selectedRole,
          }
        }).filter(item => item.roleId !== roleIdToBeRemoved);
        if(dataToUpdate?.isSearchedRole && !data.roleRecommendations.some((item) => item.roleId === dataToUpdate.selectedRoleId)){
          data.roleRecommendations.push({
            isSearchedRole: true,
            isSelected: true,
            roleName: dataToUpdate.selectedRole,
            roleId: dataToUpdate.selectedRoleId
          })
        }
      }
      return data;
    });
    
    try {
      await RoleServices.partialSaveClientRoles(updatedRoleSelectionDataToSave, clientName, importTokenId);
      !triggerExport &&  setFullLoader(false);
      showAlert("Client Roles are updated partially", "success", 1500);
    } catch (error) {
      console.log(error);
      setFullLoader(false);
      showAlert(error, "error", 2000);
    }
  };

  const downloadRoleSelectionData = async() => {
      try {
          if(exportParserMeta?.fileUrl){
            DownloadFile(exportParserMeta.fileUrl, "RoleSelectionData.xlsx");
            var data = exportParserMeta;
            data.status = ExportProcessType.DOWNLOADED;
            await RoleServices.UpdateRoleSelectionDataExportExcelStatusV3(clientName, data, "");
            setShowDownload(false);
            setExportParserMeta(null);
          } else {
            fetchDataAndUpdateState(true);
          }
      } catch (error) {
          showAlert(error, "error", 2000);
      }
  };

  const updateErrorInRoleSelectionData = () => {
    const updatedRoleSelectionOriginData = [...roleSelectionOriginData].map(
      (data) =>
        !data.selectedRole
          ? {
              ...data,
              ["showError"]: true,
            }
          : { ...data }
    );
    let roleSelectionData = updatedRoleSelectionOriginData.slice(
      pageOffset,
      rowCount + pageOffset
    );
    setRoleSelectionOriginData(updatedRoleSelectionOriginData);
    setRoleSelectionData(roleSelectionData);
    setRoleSelectionDataCopy(roleSelectionData);
    setProceedConfirmModal(true);
  };

  const updateOriginRoleSelectionDataCopy = (updatedData) => {
    const updatedRoleSelectionOriginData = [...roleSelectionOriginDataCopy].map(
      (data) => {
        const dataToUpdate = updatedData?.find(
          (d) => d.clientId === data.clientId
        );
        return !!dataToUpdate ? { ...dataToUpdate } : { ...data };
      }
    );
    setRoleSelectionOriginDataCopy(updatedRoleSelectionOriginData);
  };

  const onRoleSearch = (toClear = false) => {
    const searchText = toClear === true ? "" : roleSearchText;
    const updatedRoleSelectionOriginData = [
      ...roleSelectionOriginDataCopy,
    ].filter(
      (data) =>
        data?.clientRole?.toLowerCase().indexOf(searchText.toLowerCase()) >
        -1
    );
    let roleSelectionData = updatedRoleSelectionOriginData.slice(
      pageOffset,
      rowCount + pageOffset
    );
    setRoleSelectionOriginData(updatedRoleSelectionOriginData);
    setRoleSelectionData(roleSelectionData);
  };

  const selectedRoleData = roleSelectionOriginData?.filter(
    (r) => r.selectedRole
  );

  const unSelectedRoleDataLength =
    roleSelectionOriginDataCopy?.length - selectedRoleData?.length;

  const getUpdatedCheckAllData = (array, isChecked) => {
     return [...array]?.map((el) => ({
      ...el,
      checked: isChecked,
    }));
  }

  const handleCheckAll = (e, el, i) => {
    const updatedRoleSelectionData = getUpdatedCheckAllData(roleSelectionData, e.target.checked);
    setRoleSelectionData(updatedRoleSelectionData);
    setRoleSelectionDataCopy(updatedRoleSelectionData);
    setSelectedContentItemsContainer(e.target.checked ? roleSelectionData.map((elem) => elem.clientId) : []);
    setRoleSelectionOriginData(getUpdatedCheckAllData(roleSelectionOriginData, e.target.checked));
    setRoleSelectionOriginDataCopy(getUpdatedCheckAllData(roleSelectionOriginDataCopy, e.target.checked));
  }

  const handleSelectedItems = (array, item) => {
    const indexToRemove = array.indexOf(item.clientId);
      const isItemSelected = array?.includes(item.clientId);
      if (!isItemSelected) {
        array.push(item.clientId);
      } else {
        indexToRemove !== -1 && array.splice(indexToRemove, 1);
      }
    setSelectedContentItemsContainer(array);
  }

  const handleComparePersonaClick = async() => {
    if(selectedContentItemsContainer.length > 4){
      setWarningPopUp(true);
      return;
    }
    await partialSaveRoleSelectionData();
    const personasToBeCompared = roleSelectionDataToSave
    .filter((role, i) => selectedContentItemsContainer.includes(i))
    .map(role => role.personaName);


    while (personasToBeCompared.length < 4) {
      personasToBeCompared.push('');
    }

    resetStore(ComparePersonaStore, InitialComparePersonaStore);
    resetStore(SelectedRoleSkillsStore, InitialSelectedRoleSkillsStore);
    navigate("/compare-persona", {state: {isNavigatedFromRoleMatch: true, personasToBeCompared, importTokenId, clientName }});
  }

  const getUpdatedCheckBoxData = (array, isChecked, clientId) => {
    return [...array]?.map((el, i) =>
      el.clientId === clientId
       ? { ...el, checked: isChecked }
       : { ...el }
    );
  };

  const handleCheckBox = (e, item) => {
      handleSelectedItems(selectedContentItemsContainer, item);
      const updatedRoleSelectionData = getUpdatedCheckBoxData(roleSelectionData, e.target.checked, item.clientId);
      setRoleSelectionData(updatedRoleSelectionData);
      setRoleSelectionDataCopy(updatedRoleSelectionData);
      setRoleSelectionOriginData(getUpdatedCheckBoxData(roleSelectionOriginData, e.target.checked, item.clientId));
      setRoleSelectionOriginDataCopy(getUpdatedCheckBoxData(roleSelectionOriginDataCopy, e.target.checked, item.clientId));
  };

  const saveClick = async (e, el, row) => {
    setFullLoader(true);
    try{
      await setIsSavingNewlySelectedRole(true);
      await partialSaveRoleSelectionData();
      await setIsSavingNewlySelectedRole(false);
      setFullLoader(false);
      const roleSelectionList = roleSelectionOriginData
      .filter((r) => !!r.selectedRoleId)
      .map((role) => ({
        groupName: role.groupName,
        label: role.clientRole,
        value: role.selectedRoleId,
      }));
      const sortedRoleSelectionList = SortString(roleSelectionList, "groupName");
      setTimeout(()=>{
        navigate(`/personas/role-matching/architect-skills/` + row?.selectedRoleId, {
          state: { roleId: row?.selectedRoleId , personaName: row?.clientRole, importTokenId, roleSelectionOriginData, roleSelectionList: sortedRoleSelectionList}
        });
      },1500);
    }catch(error){
      await setIsSavingNewlySelectedRole(false);
      showAlert(error, 'error', 2000);
      setFullLoader(false);
    }
  }

  const handleRoleLinkClick = async (roleId, row) => {
    try {
      const roleDetails = await RoleServices.getRoleV3("/" + roleId);
      setIndustryroleDetail(roleDetails);
      setIsRoleDetailPopupOpen(true);
      setPersonaDetail({rolesResponsibilities: rolesAndResponsibilitiesDictionary[row.clientRole] || ""});
    } catch (error) {
       showAlert(error, "error", 2000);
    }
  };

  const handleCloseRolesAndResponsibilitiesPopup = () => {
    setIsRoleDetailPopupOpen(false)
    setIndustryroleDetail(null);
    setPersonaDetail(null);
  }

  console.log(650, progress);

  return (
    <>
      <HeaderNav>
        <H2>Disprz Role Selection</H2>
      </HeaderNav>
      <BodyContent>
        <Container>
          <BackCheck handleCheckBack={handleCheckBack} />
          {!contentLoading && (
            <RoleSelectionBlockX>
              <div className={"role-header"}>
                <div className={"client-filters"}>
                  <div className={"client-role-search"}>
                    <input
                      className={"search-input"}
                      value={roleSearchText}
                      onChange={(event) => {
                        const searchText = event.target.value;
                        !searchText && setShowSearchIcon(true);
                        setRoleSearchText(searchText);
                      }}
                      placeholder={"Search Client Role"}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          onRoleSearch();
                          roleSearchText && setShowSearchIcon(false);
                        }
                      }}
                    />
                    {showSearchIcon ? (
                      <span
                        className={"search-icon"}
                        onClick={() => {
                          roleSearchText && setShowSearchIcon(false);
                          onRoleSearch();
                        }}
                      >
                        <SearchIcon />
                      </span>
                    ) : (
                      roleSearchText && (
                        <span
                          className={"search-icon"}
                          onClick={() => {
                            setRoleSearchText("");
                            setShowSearchIcon(true);
                            onRoleSearch(true);
                          }}
                        >
                          <ClearIcon />
                        </span>
                      )
                    )}
                  </div>
                  <div className={"group-filter"}>
                    <SelectMenu
                        width="180px"
                        label="Filter By Department"
                        list={
                            !!groupNames?.length > 0
                            ? groupNames?.map(
                            (name, index) => ({
                            label: name,
                            value: index,
                        })
                            )
                            : []
                        }
                        value={`${selectedGroup}`}
                        handleSelect={handleGroupFilter}
                    />
                  </div>
                </div>
                {
                  !isValidUser(User_Type, UserType.GENERAL_USER) &&
                    <div className={`export-btn ${showDownload && "download-btn"}`}>
                      {isExportInProgress &&
                        <H5 className="export-progress-message-btn">
                          {text.persona.exportInProgress}
                        </H5>
                      }
                      <SecondaryButton
                        type="submit"
                        onClick={() => downloadRoleSelectionData()}
                        style={{ background: showDownload ? COLORS.DOWNLOAD_GREEN : !!progress && `linear-gradient(to right, ${COLORS.PRIMARY_PURPLE_BACKGROUND} ${progress}%, #ffffff ${progress}%)` }}
                        disabled={!showDownload && !progress && !roleSelectionOriginData?.some((data) => data?.checked)}
                      >
                        {showDownload && <SaveAltIcon fontSize={"small"}/>}
                        {showDownload ? "Download" : "Export to Excel" }
                      </SecondaryButton>
                    </div>
                }
              </div>
              <div
                className={`content-table ${
                  roleSelectionData.length === 0 && "no-data"
                }`}
              >
                <CustomizedTables
                  columns={Role_Selection_Table}
                  rowList={roleSelectionData}
                  rowListCopy={roleSelectionDataCopy}
                  saveAndCancel={true}
                  resetList={() => setRoleSelectionData(roleSelectionDataCopy)}
                  triggerSearchApi={triggerSearchApi}
                  selectedValue={levelChange}
                  deleteClick={deleteTrigger}
                  toggleChange={toggleChange}
                  toShowNoDataInfo={true}
                  handleCheckAll={handleCheckAll}
                  handleCheckBox={handleCheckBox}
                  theme={"customizedTheme"}
                  saveClick={saveClick}
                  noData={roleSelectionData?.length === 0}
                  noDataToShow={roleSelectionData?.length === 0 ? "No data to show" : ""}
                  handleSkillRolePopUp={(roleId, row) =>  handleRoleLinkClick(roleId, row)}
                />
              </div>
              {roleSelectionOriginData?.length > 10 && !contentLoading ? (
                <PaginationCustom
                  pageTotal={roleSelectionOriginData?.length}
                  handlePagination={handlePagination}
                  countRows={rowCount}
                  changeRowCount={changeRowCount}
                  changeCurrentPage={currPage}
                  showPaginatedInfo={true}
                  key={paginationKey}
                />
              ) : null}
              <ConfirmModal />
              <div className="btn-block">
                  <SecondaryButton
                    onClick={() => {
                      partialSaveRoleSelectionData();
                    }}
                    style={{ marginRight: 15 }}
                  >
                    Save
                  </SecondaryButton>
                  <SecondaryButton style={{ marginRight: 15 }} onClick={() => handleComparePersonaClick()} disabled={selectedContentItemsContainer?.length === 0}>
                    Compare Persona
                  </SecondaryButton>
                  <PrimaryButton
                    onClick={() => {
                      unSelectedRoleDataLength === 0
                        ? saveRoleSelectionData()
                        : updateErrorInRoleSelectionData();
                    }}
                  >
                    Create Personas
                  </PrimaryButton>
              </div>
              {!!proceedConfirmModal && (
                <ModalPopup
                  open={proceedConfirmModal}
                  maxWidth="md"
                  closePopup={() => setProceedConfirmModal(false)}
                  title={`#${selectedRoleData?.length} Persona${
                    selectedRoleData?.length > 1 ? "s" : ""
                  } will be created. #${unSelectedRoleDataLength} Persona${
                    unSelectedRoleDataLength > 1 ? "s are" : " is"
                  } incomplete and will be omitted`}
                  titleStyle={{ padding: "15px" }}
                >
                  <div className="btn-block" style={{ textAlign: "center" }}>
                    <SecondaryButton
                      onClick={() => setProceedConfirmModal(false)}
                      style={{ marginRight: 15 }}
                    >
                      Cancel
                    </SecondaryButton>

                    <PrimaryButton
                      type="submit"
                      onClick={() => saveRoleSelectionData()}
                    >
                      Proceed
                    </PrimaryButton>
                  </div>
                </ModalPopup>
              )}
              <ModalPopup
                open={warningPopUp}
                maxWidth="sm"
                title={"Please select maximum of 4 personas"}
                center={true}
                className="warning-modal"
              >
              <ConfirmBlock>
                  <PrimaryButton onClick={()=>setWarningPopUp(false)}>
                  Ok
                  </PrimaryButton>
              </ConfirmBlock>
              </ModalPopup>
            </RoleSelectionBlockX>
          )}
        </Container>
      </BodyContent>
      <ProgressIndicatorX>
        {isSavingNewlySelectedRole ?
          <ProgressIndicator  message={"Saving Selected Role..."}/>
          :null
        }
      </ProgressIndicatorX>
      <RolesAndResponsibilitiesPopup
        open={isRoleDetailPopupOpen}
        close={handleCloseRolesAndResponsibilitiesPopup}
        personaDetail={personaDetail}
        industryroleDetail={industryroleDetail}
      />
    </>
  );
};

const HeaderNav = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 35px 15px 0px;
  h2 {
    padding-bottom: 0px;
  }
`;

const ConfirmBlock = styled.div`
  text-align: center;
`;

const RoleSelectionBlockX = styled.div`
  .role-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .client-filters {
      display: flex;
      .client-role-search {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        .search-input {
          padding: 6px 10px;
          font-size: 15px;
          border: 1px solid #ccc;
          box-sizing: border-box;
          outline: none;
        }
        .search-icon {
          display: flex;
          cursor: pointer;
          margin-left: 0px;
          font-size: 28px;
          padding: 5px;
          background: linear-gradient(
            111deg,
            rgb(250, 21, 101) 22%,
            rgb(115, 20, 173) 155%
          );
          justify-content: center;
          align-items: center;
          svg {
            fill: white;
          }
        }
      }
      .group-filter {
        margin-top: -15px;
        margin-left: 20px;
      }
    }
    .export-btn{
      margin-bottom: 10px;
      position: relative
    }
    .download-btn{
      button{
        color: ${COLORS.PRIMARY_WHITE};
        background: ${COLORS.DOWNLOAD_GREEN};
        border: solid 1px ${COLORS.DOWNLOAD_GREEN};
        span{
          display: flex; 
          justify-content: center; 
          align-items: center;
          gap: 5px;
        }
      }
    }
    .export-progress-message-btn{
      position: absolute;
      padding-bottom: 10px;
      bottom: 34px;
      right: -61px;
      left: -61px;
    }
  } 
  .btn-block {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  .no-data {
    > div:first-child {
    }
  }
  .content-table {
    position: relative;
    > div {
      overflow-x: auto !important;
      &::-webkit-scrollbar {
        height: 8px;
      }
      &::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.2);
        border-radius: 6px;
      }
    }
  }
`

const ProgressIndicatorX = styled.div`
 .report-box{
  top: 8%
 }
`