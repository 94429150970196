import React from "react";
import { Images } from "../../../../assets/images";
import { SecondaryButton } from "../../../Common/Buttons/SecondaryButton";
import ModalPopup from "../../../Common/ModalPopup";
import ConfirmationButton from "../../../Common/ConfirmationButton";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import { FileFormats } from "../../../../enums/FileFormats";
import DragAndDropFileModal from "../../../Common/DragAndDropFileModal/DragAndDropFileModal";
import useDownloadJDModal from "./useDownloadJDModal";
import text from "../../../../locale.en.json";
import DownloadFile from "../../../../helpers/DownloadFile";
import "./DownloadJDModal.scss";
import { PersonaCreationTypes } from "../../../../enums/PersonaCreationType";
import ErrorLabel from "../../../Common/ErrorLabel/ErrorLabel";

function DownloadJDModal({ open, close, status, handleFileSelect, handleYesClick, error, setError }) {
  const { showDragAndDropFileModal, handleReuploadClick, closeDragAndDropFileModal, isInvalidTemplate } = useDownloadJDModal({setError});

  const JDModalContainer = () => {
    return (
      <div className="jd-modal-container">
        <div className="download-message">
          <div>{text.persona.jobDescription.downloadAndReview}</div>
          <div>{text.persona.jobDescription.extractionConfirmation}</div>
          <div>
            {text.persona.jobDescription.editNote}
          </div>
          <div>
            {text.common.or}
          </div>
          <div>
            {text.persona.jobDescription.proceedNextStep}
          </div>
        </div>
        <div className="download-upload-container">
          <SecondaryButton
            className={`download-btn downloaded`}
            onClick={() => DownloadFile(status.successfulResponsesFileUrl, "SuccessfulResponsesFile")}
          >
            <SaveAltIcon fontSize="small" />
              {text.common.download}
            </SecondaryButton>
          <SecondaryButton classes={`re-upload-btn`} onClick={handleReuploadClick}>
            {text.common.reUpload}
          </SecondaryButton>
        </div>
        {status?.failedResponsesFileUrl &&
          <div className="failed-response-container">
            <span className="note">{text.common.note}: </span>
            <span className="note-info">{text.fileSelection.someFilesWereOmitted} </span>
            <span className="click-here" onClick={() => DownloadFile(status.failedResponsesFileUrl, "FailedResponsesFile")}>{text.fileSelection.clickHere}{" "}
              <img
                src={Images.icons.downloadErrorFileIcon}
                className="download-icon"
                alt="Download Icon"
              /> {" "}
            </span>
            <span className="note-info">{text.fileSelection.toDownload}</span>
          </div>
        }
        {(!!error) && (<ErrorLabel message={error}/>)}
      </div>
    );
  };

  return (
    <>
      <ModalPopup
        open={open}
        title={text.persona.reviewExtractedData}
        center={true}
        closePopup={close}
        actions={
          <ConfirmationButton
            yesText={text.common.proceed}
            showNoButton={false}
            yesClick={handleYesClick}
            primaryButtonStyle={{fontSize: "16px"}}
          />
        }
      >
        {JDModalContainer()}
      </ModalPopup>
      <DragAndDropFileModal
        open={showDragAndDropFileModal}
        title={text.common.importFromExcel}
        allowedFileTypes={[FileFormats.XLSX]}
        maxWidth="sm"
        closePopup={closeDragAndDropFileModal}
        multiSelect={false}
        isInvalidTemplate={isInvalidTemplate}
        yesClick={(selectedFiles) => {handleFileSelect(selectedFiles, closeDragAndDropFileModal, PersonaCreationTypes.UPLOAD_ZIP_FILE);}}
        error={error}
        setError={setError}
      />
    </>
  );
}

export default  DownloadJDModal;
