import React from "react";

import styled from "styled-components";
import SideMenu from "./SideMenu";

export default ({ showContent = false, children }) => {
  return (
    <Layout>
      <SideMenu />
      <Content showContent={showContent}>{children}</Content>
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  padding-left: 60px;
`;
const Content = styled.div`
  overflow: ${(props) => (props.showContent ? "visible" : "hidden")};
  padding: 12px 0px 0px 32px;
  width: 100%;
`;
