import React from "react";
import styled from "styled-components";
import COLORS from "../../../assets/colors";

export default ({ children, ...props }) => (
  <CutomScroll {...props}>{children}</CutomScroll>
);

const CutomScroll = styled.div`
  overflow-x: auto;
  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
    background-color: transparent;
    border-radius: 3px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${COLORS.DARK_BLUE};
    border-radius: 3px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
    background-color: ${COLORS.BLUE_ASH};
    border-radius: 3px;
  }
`;
