import React from "react";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ToolBar from "../../Common/ToolBar";

export const Role_Selection_Table = [
    {
        colKey: "showError",
        colValue: "",
        colType: "column_render",
        RenderColumn: (data) => {
            return !!data?.showError && (
                <ToolBar title="Not Selected" arrow={true}>
                    <ErrorOutlineIcon style={{ marginTop: '6px' }}/>
                </ToolBar>
            );
        },
    },
    {
        colKey: "checkbox",
        colValue: "checkbox",
        width: 60,
    },
    {
        colKey: "clientRole",
        colValue: "Client Role"
    },
    {
        colKey: "roleMatch1",
        colId: "roleId1",
        highLighColKey: "roleScore1",
        colValue: "Role Match 1",
        colType: "chip",
        width: 300
    },
    {
        colKey: "roleMatch2",
        colId : "roleId2", 
        highLighColKey: "roleScore2",
        colValue: "Role Match 2",
        colType: "chip",
        width: 300
    },
    {
        colKey: "roleMatch3",
        colId : "roleId3", 
        highLighColKey: "roleScore3",
        colValue: "Role Match 3",
        colType: "chip",
        width: 300
    },
    {
        colKey: "searchRoles",
        colValue: "Search Roles",
        colType: "autoSelect",
        noOptionsText: "No Roles Available",
        width: 300
    },
    {
        colType: "selectedRole",
        colKey: "selectedRole",
        colId: "selectedRoleId",
        colValue: "Disprz Role Selected"
    },
    {
        colKey: "skillMatch",
        colValue: "",
        dynamic: false
    },
    {
        colKey: "buttonDelete",
        colValue: "",
        dynamic: false,
        toIncludeWhiteIcon: true
    }
];