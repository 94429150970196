import React, { useState, memo, useEffect, useRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import COLORS from "../../assets/colors";
import text from "../../locale.en.json";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    color: COLORS.TITLE_BLUE,
    fontFamily: "inherit",
    fontWeight: 600,
  },
  center: {
    textAlign: "center",
    padding: 25,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  closeText: {
    position: "absolute",
    right: theme.spacing(4),
    top: theme.spacing(4),
  },
  closeTime: {
    color: "#4285F6",
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const {
    children,
    classes,
    onClose,
    center,
    closeTime,
    closeDurationInSec,
    ...other
  } = props;

  return (
    <MuiDialogTitle
      disableTypography
      className={[classes.root, classes.center]}
      {...other}
    >
      <Typography variant="h6">{children}</Typography>
      {!!closeDurationInSec ? (
        <div className={classes.closeText}>
          <span>{text.common.closingIn} </span>
          <span className={classes.closeTime}>
            {closeTime} {text.common.sec}
          </span>
        </div>
      ) : onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    borderBottom: "unset",
    "&::-webkit-scrollbar": {
      width: "8px",
      marginRight: "5px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "rgba(0, 0, 0, 0.2)",
      borderRadius: "6px",
    },
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: "0 0 1.7rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}))(MuiDialogActions);

const ModalPopup = ({
  open,
  closePopup,
  maxWidth = "md",
  title,
  center = false,
  children,
  fullWidth = true,
  titleStyle = {},
  customStyle = {},
  closeDurationInSec = 0,
  actions,
}) => {
  const [scroll, setScroll] = useState("paper");
  const [closeTime, setCloseTime] = useState(closeDurationInSec);

  const contentRef = useRef(null);

  useEffect(() => {
    let intervalId;

    if (closeTime > 0) {
      intervalId = setInterval(() => {
        const timer = closeTime - 1;
        setCloseTime(timer);
        if (timer === 0) closePopup();
      }, 1000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [closeTime]);

  useEffect(() => {
    if (contentRef?.current) {
      handleScroll();
    }
  }, [children]);

  const handleScroll = () => {
    setTimeout(() => {
      if(contentRef?.current){
        contentRef.current.scroll({
          top: contentRef.current.scrollHeight,
          behavior: "smooth",
        });
      }
    }, 10);
  };

  return (
    <div>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        onClose={closePopup}
        aria-labelledby="customized-dialog-title"
        scroll={scroll}
        open={open}
      >
        {!!title && (
          <DialogTitle
            center={center}
            id="customized-dialog-title"
            onClose={closePopup}
            style={titleStyle}
            closeTime={closeTime}
            closeDurationInSec={closeDurationInSec}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: title || "",
              }}
            />
          </DialogTitle>
        )}
        <DialogContent
          dividers={scroll === "paper"}
          style={customStyle}
          ref={contentRef}
        >
          {children}
        </DialogContent>
        {actions && <DialogActions>{actions}</DialogActions>}
      </Dialog>
    </div>
  );
};

export default memo(ModalPopup);
