import React from 'react';
import { getBezierPath, getMarkerEnd } from 'react-flow-renderer';

export default ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  arrowHeadType,
  markerEndId
}) => {
  const edgePath = getBezierPath({ sourceX, sourceY, sourcePosition, targetX, targetY, targetPosition });
  const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);
  return (
    <>
        <defs>
            <marker 
                className="react-flow__arrowhead" 
                id="react-flow__arrowclosedReverse" 
                markerWidth="12.5" 
                markerHeight="12.5" 
                viewBox="-10 -10 20 20" 
                orient="auto-start-reverse" 
                refX="0" 
                refY="0">
                    <polyline 
                        stroke="#b1b1b7" 
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                        fill="#b1b1b7"
                        points="-5,-4 0,0 -5,4 -5,-4"></polyline>
            </marker>
        </defs>
        <path id={id} style={style} className="react-flow__edge-path" d={edgePath} markerStart={"url(#react-flow__arrowclosedReverse)"} markerEnd={markerEnd} />
    </>
  );
}