import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import AddSharpIcon from "@material-ui/icons/AddSharp";
import RemoveSharpIcon from "@material-ui/icons/RemoveSharp";
import { H4 } from "./Typography";
import COLORS from "../../assets/colors";

export default ({ list = [], initOpen = 0 }) => {
  const [expanded, setExpanded] = useState(initOpen);

  const handleChange = (panel) => (event, newExpanded) =>
    setExpanded(newExpanded ? panel : false);

  return (
    <>
      {list.map((item, i) => (
        <>
          <Accordion
            square
            expanded={expanded === i}
            onChange={handleChange(i)}
            minHeightShow={false}
            id={"accordian-custom" + i}
          >
            <AccordionSummary
              expandIcon={
                expanded === i ? <RemoveSharpIcon /> : <AddSharpIcon />
              }
              aria-controls={`panel${i}d-content`}
              id={`panel${i}d-header`}
            >
              <H4>{item.title}</H4>
            </AccordionSummary>
            <AccordionDetails data-test={i}>
              <ul>
                {item.content.map((el, idx) => {
                  return <li key={idx}>{el}</li>;
                })}
              </ul>
            </AccordionDetails>
          </Accordion>
        </>
      ))}
    </>
  );
};

const Accordion = withStyles({
  root: {
    border: "none",
    borderRadius: 12,
    boxShadow: "none",
    marginBottom: "1px !important",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      height: 0,
      //   display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
    "& > .MuiCollapse-container.MuiCollapse-hidden": {
      visibility: (props) => {
        return props.minHeightShow && !!props.minheight ? "visible" : "hidden";
      },
    },
  },
  expanded: {},
})((props) => <MuiAccordion {...props} />);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "transparent",
    border: "none",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    flexDirection: "row-reverse",
    padding: 0,
    "&$expanded": {
      minHeight: 56,
      // backgroundColor: COLORS.BODY_BLUE,

      "& h4": {
        // paddingLeft: 15,
      },
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
    "& h4": {
      padding: "0px 0px 0px 10px",
      color: COLORS.TITLE_BLUE,
      fontWeight: 600,
      fontSize: 14,
      textTransform: "none",
    },
  },
  expandIcon: {
    border: `solid 1px ${COLORS.DARK_BLUE}`,
    borderRadius: 2,
    boxShadow: "none",
    height: 20,
    marginRight: 0,
    width: 20,
    padding: 0,
    "& .MuiSvgIcon-root": {
      fill: COLORS.DARK_BLUE,
      fontSize: 18,
      fontWeight: 300,
    },
    "&.Mui-expanded": {
      boxShadow: "none",
      // opacity: 0.4,
      // borderColor: COLORS.PRIMARY_WHITE,
      "& .MuiSvgIcon-root": {
        // fill: COLORS.PRIMARY_WHITE,
      },
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    flexDirection: "column",
    padding: 15,
    "& ul": {
      padding: 0,
    },
    "& ul li": {
      marginLeft: 22,
      padding: "0px 0px 10px 8px",
      position: "relative",
      fontSize: 12,
      fontWeight: 400,
      color: COLORS.TITLE_BLUE,
    },
    "& ul li::marker": {
      content: `'✦'`,
      color: "#ec156c",
      fontSize: 12,
    },
  },
}))((props) => <MuiAccordionDetails {...props} />);
