import * as Yup from "yup";

const titleValid = /^([a-zA-Z0-9?-]+\s)*[a-zA-Z0-9?-]+$/;
export const disprzopediaFormValidation = Yup.object({
  name: Yup.string()
    .min(3, "Title must be at least 3 characters")
    .max(255, "Title must be upto 255 characters")
    .matches(titleValid, "Title is not valid")
    .required("Title is required"),
  status: Yup.number()
    .min(1, "Status is required")
    .required("Status is required")
    .nullable(),
});
