import * as Yup from "yup";
import { AssessmentType } from "../enums/AssessmentType";
import { AssessmentSubType } from "../enums/AssessmentSubType";
import { isAssessmentAllowed } from "../helpers/CommonHelper";
const urlValid = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;

const personaValidation = Yup.object({
  personaGroupId: Yup.string().required("Group name is required").nullable(),
  personaName: Yup.string().required("Persona name is required"),
  reportingTo: Yup.string().nullable(),
  roleDescription: Yup.string().nullable(),
  rolesResponsibilities: Yup.string().nullable()
});

const addSkillValidation = Yup.object({
  skillName: Yup.string().required("Skill name is required").nullable(),
  skillDescription: Yup.string()
    .required("Skill description is required")
    .nullable(),
});

const updateSkillValidation = Yup.object({
  skillDescription: Yup.string()
    .required("Skill description is required")
    .nullable(),
});

const skillManageMentValidation = Yup.object({
  skillName: Yup.string().required("Skill Name is required").nullable(),
  skillDescription: Yup.string()
    .required("Skill description is required")
    .nullable(),
  // wikipedia: Yup.string().required("knowledge base is required").nullable(),
  skillCategoryId: Yup.string().required("Category is required").nullable(),
  skillFamilyId: Yup.string().required("Family is required").nullable(),
  sourceId: Yup.string().required("Source is required").nullable(),
  skillStatus: Yup.string().required("Status is required").nullable(),
});

const skillAssessmentAddValidation = Yup.object({
  assessmentTypeId: Yup.number()
    .min(1, "Assessment type is required")
    .required("Assessment type is required")
    .nullable(),
  assessmentSubTypeId: Yup.number().when("assessmentTypeId", {
    is: (val) => val === AssessmentType.EVALUATION,
    then: Yup.number()
      .min(1, "Assessment sub type is required")
      .required("Assessment sub type is required"),
    otherwise: Yup.number().nullable(),
  }),
  assessmentEvaluatorTypeId: Yup.number().when("assessmentTypeId", {
    is: (val) => val === AssessmentType.EVALUATION,
    then: Yup.number()
      .min(1, "Evaluator is required")
      .required("Evaluator is required"),
    otherwise: Yup.number().nullable(),
  }),
  status: Yup.number()
    .min(1, "Status is required")
    .required("Status is required")
    .nullable(),
  levelId: Yup.number()
    .min(1, "Level is required")
    .required("Level is required")
    .nullable(),
  assessmentUrl: Yup.string().when("assessmentTypeId", {
    is: (val) => val === AssessmentType.CODERBYTE,
    then: Yup.string()
      .matches(urlValid, "Assessment URL is not valid")
      .required("Assessment URL is required"),
    otherwise: Yup.string().nullable(),
  }),
  assessmentTestId: Yup.string().when("assessmentTypeId", {
    is: (val) => isAssessmentAllowed(val),
    then: Yup.string().required("Assessment Test ID is required"),
    otherwise: Yup.string().nullable(),
  }),
  assessmentText: Yup.string().when(
    ["assessmentTypeId", "assessmentSubTypeId"],
    {
      is: (assessmentTypeId, assessmentSubTypeId) =>
          (assessmentTypeId === AssessmentType.EVALUATION &&
        (assessmentSubTypeId === AssessmentSubType.RATING_1To5 || assessmentSubTypeId === AssessmentSubType.RATING_1To10)) || assessmentTypeId === AssessmentType.ADAPTIVE,
      then: Yup.string().required("Assessment Question is required"),
      otherwise: Yup.string().nullable(),
    }
  ),
  isCorrectErrorMessage: Yup.string().when(
      ["assessmentTypeId", "assessmentOptions"],
      {
          is: (assessmentTypeId, assessmentOptions) => assessmentTypeId === AssessmentType.ADAPTIVE && !assessmentOptions.some(assessmentOption => assessmentOption.isCorrect),
          then: Yup.string().required("Kindly ensure at least one option is marked as correct!"),
          otherwise: Yup.string().nullable(),
      }
  ),
  assessmentOptions: Yup.array().when(
      ["assessmentTypeId"],
      {
          is: (assessmentTypeId) => assessmentTypeId === AssessmentType.ADAPTIVE,
          then: Yup.array().of(
                Yup.object({
                  option: Yup.string().required("Kindly define option")
              })
          )
      }
  ),
  isQuestionAlreadyDefinedErrorMessage: Yup.bool().when(
      ["assessmentTypeId", "assessmentText"],
      {
          is: (assessmentTypeId, assessmentText) => assessmentTypeId === AssessmentType.ADAPTIVE && assessmentText !== "",
          then: Yup.bool().oneOf([false], 'Question already exists!'),
          otherwise: Yup.bool().nullable(),
      }
  )
});

export {
  personaValidation,
  addSkillValidation,
  updateSkillValidation,
  skillManageMentValidation,
  skillAssessmentAddValidation,
};
