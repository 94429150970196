import { useContext } from "react";
import { MasterFunctionsListContext } from "../Provider/functionsListProvider";

export default () => {
  const context = useContext(MasterFunctionsListContext);
  if (context === undefined) {
    throw new Error("use list must be used within a Master list provider");
  }

  return context;
};
