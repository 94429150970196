import React, { memo, useState, useEffect } from "react";
import styled from "styled-components";
import { H5 } from "../../../Common/Typography";
import COLORS from "../../../../assets/colors";
import { Images } from "../../../../assets/images";
import TableSortLabel from "@material-ui/core/TableSortLabel";

export default memo(
  ({
    data,
    handleSkillPopup,
    handleChart,
    cancelSkill,
    showTree = false,
    trendingSkills = [],
    trendingJSONArray = [],
    handleSkillSort,
  }) => {
    const [sortSkillAscending, setSortSkillAscending] = useState('');
    const category = (data ? data[0].category : null);
    useEffect(()=>{
      setSortSkillAscending('');
    },[category])
    const trendingSkillIds = trendingSkills?.map(({ skillId }) => skillId);

    return (
      <SkillColumnX>
        <H5>
          Skill
          <TableSortLabel
            active={sortSkillAscending !== ''}
            direction={(sortSkillAscending || sortSkillAscending === '') ? "asc" : "desc"}
            onClick={() => {
              if (category) {
                handleSkillSort(data, sortSkillAscending);
                setSortSkillAscending(sortSkillAscending === '' ? true : !sortSkillAscending);
              }
            }}
          ></TableSortLabel>
        </H5>
        <ul>
          {data?.map((el, i) => (
            <li key={i}>
              <span onClick={() => handleSkillPopup(el.skillId, i)}>
                {el?.skillName}{" "}
                {showTree && trendingSkillIds?.includes(el.skillId) ? (
                  <img src={Images.icons.icStackUp} alt="stack" />
                ) : null}
              </span>
              {trendingJSONArray
                ?.map(({ skillId }) => skillId)
                .includes(el.skillId) ? (
                <>
                  {showTree && (
                    <div
                      className="map-link"
                      onClick={() => handleChart(el, i)}
                    >
                      Tree
                    </div>
                  )}
                </>
              ) : null}

              {el.newSkill && (
                <img
                  src={Images.icons.cancelIcon}
                  alt="cancel"
                  className="cancel-img"
                  onClick={() => cancelSkill(el, i)}
                />
              )}
            </li>
          ))}
        </ul>
      </SkillColumnX>
    );
  }
);

const SkillColumnX = styled.div`
  width: 250px;
  ul {
    li {
      border: solid 1px ${COLORS.PRIMARY_WHITE};
      color: ${COLORS.TITLE_BLUE};
      /* cursor: pointer; */
      font-weight: 600;
      /* line-height: 44px; */
      overflow: hidden;
      padding-left: 0px !important;
      /* text-decoration: underline; */
      /* text-overflow: ellipsis;
      white-space: nowrap; */
      padding: 0px !important;
      font-size: 12px;
      line-height: 18px !important;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      span:not(.map-link) {
        display: flex;
        text-decoration: underline;
        img {
          margin: 0px 5px;
        }
      }
      .map-link {
        color: ${COLORS.SECONDARY_PURPLE};
        text-decoration: none;
      }
      & .cancel-img {
        margin-left: 10px;
      }
      & > * {
        cursor: pointer;
      }
    }
  }
  h5 {
    .MuiTableSortLabel-root {
      bottom: 2px;
    }
    .MuiTableSortLabel-icon{
      opacity: 0.3
    }
  }
`;
