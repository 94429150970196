import React, { createContext, useState, useMemo, useEffect } from "react";
import { SortString } from "../helpers/SortString";
import useRoles from "../Hooks/useRoles";
import { AuthService } from "../services/AuthService";

export const MasterSubSectorListContext = createContext();

export default function MasterSubSectorListProvider({
  children,
  passClientId,
}) {
  const [subSectorsList, setSubSectorsList] = useState(null);
  const [personaGroupList, setPersonaGroupList] = useState([]);

  const { clientName } = useRoles();

  const getSectorsList = async () => {
    try {
      const res = await AuthService.subSectorListV3(
        passClientId ? `?clientId=${clientName}` : ""
      );
      const sorted = SortString(res, "subsector");
      sorted.unshift({
        subsector: "Any",
        subsectorId: "Any",
      });

      setSubSectorsList(sorted);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchGroupList = async () => {
    try {
      const grp = await AuthService.getPersonaGroupV3(`/${clientName}`);
      const grpSorted = SortString(grp, "personaGroupName").map(
        ({ personaGroupName, personaGroupId }) => ({
          label: personaGroupName,
          value: personaGroupId,
        })
      );
      setPersonaGroupList(grpSorted);
    } catch (errors) {
      console.log(errors);
    }
  };

  useEffect(() => {
    if(clientName){
      getSectorsList();
      fetchGroupList();
    }

    return () => {
      setSubSectorsList(null);
      setPersonaGroupList(null);
    };
  }, [clientName]);

  const value = useMemo(() => {
    return {
      subSectorsList,
      setSubSectorsList,
      personaGroupList,
      setPersonaGroupList,
    };
  }, [
    subSectorsList,
    setSubSectorsList,
    personaGroupList,
    setPersonaGroupList,
  ]);
  return (
    <MasterSubSectorListContext.Provider value={value}>
      {children}
    </MasterSubSectorListContext.Provider>
  );
}
