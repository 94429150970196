import React from "react";
import styled from "styled-components";
import COLORS from "../../../assets/colors";

export default ({ children, ...props }) => <Label {...props}>{children}</Label>;

const Label = styled.label`
  color: ${COLORS.TEXT_BLUE};
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 10px;
`;
