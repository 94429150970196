import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { navigate } from "@reach/router";
import { Formik, Form } from "formik";

import useAuth from "../../Hooks/useAuth";
import BackNav from "../Common/BackNav";
import { PrimaryButton } from "../Common/Buttons/PrimaryButton";
import DropDown from "../Common/FormElements/DropDown";
import { BodyContent, BodyWhitePattern, Container } from "../Common/GridSystem";
import { H2 } from "../Common/Typography";
import Layout from "../Layout";
import FormTextField from "../Common/FormElements/FormTextField";
import { disprzopediaFormValidation } from "../../validations/disprzopediaValidation";
import { SkillService } from "../../services/SkillService";
import useFullLoader from "../../Hooks/useFullLoader";
import useAlert from "../../Hooks/useAlert";
import CustomizedTables from "../Common/CustomizedTables";
import { SecondaryButton } from "../Common/Buttons/SecondaryButton";
import { Disprezopedia_ContentItems_Table } from "./DisprzopediaContentTable";
import ErrorTitle from "../Common/Error/ErrorTitle";
import { User } from "../../store";
import ModalPopup from "../Common/ModalPopup";
import { useSkillTableData } from "../../Provider/SkillTableProvider";
import SkillContentDetail from "../Personas/SkillManagement/SkillContentDetail";
import IconChip from "../Common/IconChip";
import { Images } from "../../assets/images";

function DisprzopediaManagement(props) {
  const { mode, location } = props;
  const id = `${location?.search.split("=")[1]}` || 0;
  const userData = User.getUserDetails();

  const { contentDetailModal, setContentDetailModal } = useSkillTableData();

  const isEdit = () => mode === "edit";

  const { setFullLoader } = useFullLoader();
  const { showAlert, closeSnackbar } = useAlert();
  const { skillstatusMenuList } = useAuth();

  const [data, setData] = useState(null);
  const [contentList, setContentList] = useState(null);
  const [hasSampleContent, setHasSampleContent] = useState(false);
  const [isSamplingContent, setIsSamplingContent] = useState(false);

  useEffect(() => {
    isEdit() && getDisprzopedia();
  }, [mode]);

  const getDisprzopedia = async () => {
    setFullLoader(true);
    try {
      const res = await SkillService.GetDisprzopediaV3(`/${id}`);
      setHasSampleContent(false);
      setData(res);
      setIsSamplingContent(res.isSampling);
    } catch (error) {
      showAlert(error.error, "error", 1500);
    } finally {
      setFullLoader(false);
    }
  };

  const alertInfo = () => {
    !!userData && showAlert("Initializing Google Docs", "info", 1800);

    const alertInfo2 = setTimeout(() => {
      !!userData && showAlert("Creating Disprzopedia", "info", 1800);
    }, 1800);
    const alertInfo3 = setTimeout(() => {
      !!userData && showAlert("Assigning relevant permissions", "info", 1800);
    }, 3600);

    // clearAlertInfo(alertInfo1, alertInfo2, alertInfo3);
  };

  const clearAlertInfo = (alertInfo1, alertInfo2, alertInfo3) => {
    clearTimeout(alertInfo1);
    clearTimeout(alertInfo2);
    clearTimeout(alertInfo3);
  };

  const formSubmit = async (values) => {
    setFullLoader(true);
    try {
      const { name, status } = values;
      const postData = {
        name,
        status,
      };
      if (isEdit()) {
        await SkillService.EditDisprzopediaV3(`/${id}`, postData);
        getDisprzopedia();
        showAlert("Disprzopedia Updated Successfully", "success", 1500);
      } else {
        alertInfo();
        const res = await SkillService.CreateDisprzopediaV3(postData);
        await showAlert("Disprzopedia Created Successfully", "success", 1500);
        setTimeout(() => {
          navigate(`/disprzopedia/edit?id=${res.disprzopediaId}`);
          window.open(res?.url, "_blank");
        }, 1000);
      }
    } catch (error) {
      closeSnackbar();
      showAlert(error.error, "error", 1500);
      // clearAlertInfo(
      //   alertInfo().alertInfo1(),
      //   alertInfo().alertInfo2(),
      //   alertInfo().alertInfo3()
      // );
    } finally {
      setFullLoader(false);
    }
  };
  // console.log(alertInfo());

  const handleTriggerSampleContent = async () => {
    const disprzopediaId = `?disprzopediaId=${id}`;
    setFullLoader(true);
    try {
      const res = await SkillService.PostSampleContentDisprzopediaV3(
        disprzopediaId
      );
      // countdownAPIcall(callCounter);
      showAlert("Processing sample content", "info", 1500, true);
      setIsSamplingContent(true);
      handleMappingSample(15);
      console.log(res);
    } catch (error) {
      setFullLoader(false);
      showAlert(error.error, "error", 1500);
    } finally {
      // setFullLoader(false);
    }
  };

  // EventEmitter.subscribe("progress", (value) => console.log(value));

  const handleMappingSample = async (counter) => {
    const disprzopediaId = `?disprzopediaId=${id}`;
    // console.log("handleMappingSample -> disprzopediaId", disprzopediaId);
    setFullLoader(true);

    try {
      const res = await SkillService.MappingSampleContentDisprzopediaV3(
        disprzopediaId
      );
      // setSampleMinute(0);isSampling
      if (!!res.isSampling) {
        setTimeout(() => {
          handleMappingSample(counter - 1);
        }, 1000 * 60);
      } else {
        setHasSampleContent(true);
        setIsSamplingContent(false);
        closeSnackbar();
        setContentList(res);
        setFullLoader(false);
      }
    } catch (error) {
      // showAlert(error.error, "error", 1500);
      if (counter > 0 && !!error.isSampling) {
        setTimeout(() => {
          handleMappingSample(counter - 1);
        }, 1000 * 60);
      } else {
        closeSnackbar();
        setFullLoader(false);
        showAlert(error.error, "error", 1500);
        setIsSamplingContent(false);
      }
    } finally {
      // setFullLoader(false);
    }
  };

  const handleMappingAll = async () => {
    const disprzopediaId = `?disprzopediaId=${id}`;
    setFullLoader(true);
    try {
      const res = await SkillService.MappingAllContentDisprzopediaV3(
        disprzopediaId
      );
      getDisprzopedia();
      console.log(res);
    } catch (error) {
      showAlert(error.error, "error", 1500);
      setFullLoader(false);
    } finally {
      // setFullLoader(false);
    }
  };

  const initValues = {
    name: !!data ? data?.name : "",
    status: isEdit() ? (!!data ? data?.status : 0) : 1,
    url: !!data ? data?.url : "",
  };

  // console.log(data, !!data && data?.status === 4);
  // console.log(contentList);
  return (
    <Layout>
      <H2>Disprzopedia Management</H2>
      <BodyContent>
        <Container>
          <BackNav linkNav="/disprzopedia" />
          <BodyWhitePattern>
            <Formik
              enableReinitialize={true}
              initialValues={initValues}
              validationSchema={disprzopediaFormValidation}
              onSubmit={formSubmit}
            >
              {({ values }) => {
                return (
                  <FormBlock>
                    {/* {console.log("DisprzopediaManagement -> values", values)} */}
                    <div className="form-sameLevel">
                      <FormTextField
                        label="Title"
                        name="name"
                        style={{ marginRight: 20 }}
                      />

                      <DropDown
                        list={skillstatusMenuList}
                        name="status"
                        width="300px"
                        label="Status"
                      />
                    </div>
                    <div>
                      {!!data?.url && isEdit() ? (
                        <>
                          <IconChip
                            icon={<img src={Images.icons.docIcon} alt="doc" />}
                            value="Document Link"
                            url={values.url}
                          />
                        </>
                      ) : null}
                    </div>
                    {/* !!data?.mappedContent?.length ||  */}
                    <PrimaryButton
                      type="submit"
                      style={{ marginRight: 20 }}
                      disabled={data?.isClassified || data?.toBeClassified}
                    >
                      {isEdit() ? "Save" : "Create"}
                    </PrimaryButton>
                    {/* && data?.status === 4 && !!data?.sampleContent?.length || */}
                    {/* Content Mapping is in Progress */}
                    {!!data ? (
                      <SecondaryButton
                        onClick={handleTriggerSampleContent}
                        disabled={
                          data?.isClassified ||
                          data?.toBeClassified ||
                          data?.isSampling ||
                          isSamplingContent
                        }
                      >
                        {!!isSamplingContent
                          ? "Content Mapping is in Progress"
                          : "Map Sample Content"}
                      </SecondaryButton>
                    ) : null}
                  </FormBlock>
                );
              }}
            </Formik>
          </BodyWhitePattern>

          <br />
          {contentList?.data?.length === 0 ? (
            <ErrorTitle text="Sample content is not available!" />
          ) : null}
          {((!!contentList?.data?.length && !!hasSampleContent) ||
            (!hasSampleContent && !!data?.mappedContent?.length) ||
            (!hasSampleContent && !!data?.sampleContent?.length)) &&
          isEdit() ? (
            <BodyWhitePattern>
              <CustomizedTables
                columns={Disprezopedia_ContentItems_Table}
                rowList={
                  !!contentList?.data?.length
                    ? contentList?.data
                    : !!data?.mappedContent?.length
                    ? data?.mappedContent
                    : !!data?.sampleContent?.length
                    ? data?.sampleContent
                    : []
                }
                innerTabel={true}
              />

              <br />
              {/* !!data?.mappedContent?.length ||  */}
              {!!data && (
                <div style={{ textAlign: "center" }}>
                  <PrimaryButton
                    onClick={handleMappingAll}
                    disabled={
                      data?.status !== 4 ||
                      data?.isClassified ||
                      data?.toBeClassified
                    }
                  >
                    Map All Content
                  </PrimaryButton>
                </div>
              )}
            </BodyWhitePattern>
          ) : null}
        </Container>
      </BodyContent>
      <ModalPopup
        open={contentDetailModal}
        maxWidth="md"
        closePopup={() => setContentDetailModal(false)}
        title="Details"
      >
        <SkillContentDetail />
      </ModalPopup>
    </Layout>
  );
}

const FormBlock = styled(Form)`
  width: 80%;
  @media (max-width: 1100px) {
    width: 100%;
  }
  .form-sameLevel {
    display: flex;
    & > div.MuiFormControl-root {
      min-width: 200px;
      width: 200px;
    }
  }
`;

export default DisprzopediaManagement;
