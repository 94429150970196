import { useEffect, useState } from 'react';
import useAlert from '../../../../Hooks/useAlert';
import { SortString } from '../../../../helpers/SortString';
import { FilterTableRow } from '../../../../helpers/FilterTableRow';
import { getUpdatedPageAndOffsetBasedOnRowCount } from '../../../../helpers/CommonHelper';
import { getPersonasMappedToRole } from "./RolePersonasService"
import useFullLoader from '../../../../Hooks/useFullLoader';

const useRolePersonas = ({ roleId }) => { 
  const { showAlert} = useAlert();
  const { setFullLoader } = useFullLoader();
  const [rolePersonaList, setRolePersonaList] = useState([]);
  const [rolePersonaOriginData, setRolePersonaOriginData] = useState([]);
  const [rolePersonaOriginDataCopy, setRolePersonaOriginDataCopy] = useState([]);
  const [pageOffset, setPageOffset] = useState(0);
  const [rowCount, setRowCount] = useState(10);
  const [currPage, setCurrPage] = useState(1);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setFullLoader(true);
        const personasList = await getPersonasMappedToRole(roleId);
        setRolePersonaOriginData(personasList);
        setRolePersonaOriginDataCopy(personasList);
        setFullLoader(false);
      } catch (error) {
        setFullLoader(false);
        showAlert(error, "error", 2000);
      }
    };

    fetchData();
  }, [roleId]);

  useEffect(() => {
    if (rolePersonaOriginData?.length > 0) {
      getPersonaSelectionData(rowCount, pageOffset, rolePersonaOriginData);
    }
  }, [pageOffset, rowCount, rolePersonaOriginData]);

  const resetPersonaOriginData = () => {
    setRolePersonaOriginData(rolePersonaOriginDataCopy);
  }

  const changeRowCount = (count) => {
    const { updatedCurrPage, updatedPageOffset } = getUpdatedPageAndOffsetBasedOnRowCount(pageOffset, count);
    setRowCount(count); 
    setCurrPage(updatedCurrPage);
    setPageOffset(updatedPageOffset);
  };

  const handlePagination = (e, page) => {
    setCurrPage(page)
    setPageOffset((page - 1) * rowCount);
  };

  const getPersonaSelectionData = (rowCountX, pageOffsetX, originDataX) => {
    let selectionData = originDataX.slice(
      pageOffsetX,
      rowCountX + pageOffsetX
    );
    setRolePersonaList(selectionData);
  }

  const handleSort = (sortValue, sortBy) => {
    const sortedData = SortString(rolePersonaOriginData, sortValue, sortBy);
    setRolePersonaOriginData(sortedData);
    getPersonaSelectionData(rowCount, pageOffset, sortedData);
  }

  const handleFilter = (item, reason, colKey, originDataCopy) => {
    setPageOffset(0);
    setCurrPage(1);
    FilterTableRow(item, reason, colKey, originDataCopy, setRolePersonaOriginData);
  }

  return {
    rolePersonaList,
    rolePersonaOriginDataCopy,
    rowCount,
    currPage,
    changeRowCount,
    handlePagination,
    handleSort,
    handleFilter,
    resetPersonaOriginData
  };
};

export default useRolePersonas;