import React, { useState, useEffect } from "react";
import { navigate } from "@reach/router";
import Layout from "../../Layout";
import { H2 } from "../../Common/Typography";
import {
  BodyContent,
  Container,
  BodyWhitePattern,
} from "../../Common/GridSystem";
import BackNav from "../../Common/BackNav";
import { AuthService } from "../../../services/AuthService";
import RoleSkills from "./RoleSkills";
import SelectMenu from "../../Common/FormElements/SelectMenu";
import { IndustreRoleStore } from "../../../store/store_state";
import { SourceType } from "../../../enums/SourceType";

export default (props) => {
  const { id } = props;

  const [leaderRoles, setLeaderRoles] = useState([]);
  const [leader, setLeader] = useState(id);

  const getLeaderRoles = async () => {
    try {
      const res = await AuthService.leaderShipRolesV3();
      setLeaderRoles(
        res.map((el) => ({
          label: el.leadershipRoleName,
          value: el.leadershipRoleId,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getLeaderRoles();
    IndustreRoleStore.update((s) => {s.sourceId = SourceType.DISPRZ_ATOMIC_SKILLS});
    return () => {
      setLeaderRoles([]);
    };
  }, []);

  const handleLeader = (val) => {
    setLeader(val);
    navigate(`/leader/${val}`);
    window.location.reload();
  };

  return (
    <Layout>
      <H2>Leadership Role Management</H2>
      <BodyContent>
        <Container>
          <BackNav linkNav="/role" />
          <BodyWhitePattern style={{ paddingBottom: 10 }}>
            <SelectMenu
              width="250px"
              label="Leadership Role"
              value={leader}
              list={leaderRoles}
              handleSelect={handleLeader}
              // disabled={true}
            />
          </BodyWhitePattern>
          <br />
          <RoleSkills roleId={leader} type="LEADER" />
        </Container>
      </BodyContent>
    </Layout>
  );
};
