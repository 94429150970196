import React from 'react';
import styled from 'styled-components';

import DotLoader from './DotLoader';
import useFullLoader from '../../../Hooks/useFullLoader';


function FullPageLoader() {
  const { fullLoader } = useFullLoader();

  if (fullLoader) {
    return (
      <Backdrop>
        <DotLoader />
      </Backdrop>
    );
  } else {
    return null;
  }
}

export default FullPageLoader;

const Backdrop = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 9999999;
  top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
