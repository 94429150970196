import React from "react";

import { useSkillTableData } from "../../Provider/SkillTableProvider";
import { LinkColumn } from "../Common/GridSystem";

export const Disprezopedia_ContentItems_Table = [
  {
    colKey: "contentType",
    colValue: "Type",
    width: 150,
  },
  {
    colKey: "title",
    colValue: "Title",
    colType: "column_render",
    RenderColumn: (val) => {
      const {
        setContentDetailModal,
        setContentDetailData,
      } = useSkillTableData();

      const handleContentClick = () => {
        setContentDetailModal(true);
        setContentDetailData(val);
      };
      return <LinkColumn onClick={handleContentClick}>{val.title}</LinkColumn>;
    },
  },

  {
    colKey: "providerName",
    colValue: "Provider",
  },
  {
    colKey: "rating",
    colValue: "Rating",
    width: 150,
  },
  // {
  //   colKey: "mappedSkills",
  //   colValue: "Mapped skills",
  // },
  // {
  //   colKey: "proficiecny",
  //   colValue: "Proficiency",
  // },
];
