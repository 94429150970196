import React, { useEffect, useState } from "react";
import { SortString } from "../../helpers/SortString";
import useRoles from "../../Hooks/useRoles";
import { AuthService } from "../../services/AuthService";
import AutoFilterLocal from "../Common/FormElements/AutoFilterLocal";

export default ({
  label = "",
  subSector = "",
  passClientId = false,
  triggerApi = true,
  value,
  inputVal,
  list = [],
  filterLabel = "functionName",
  placeholder = "",
  handleSearch = () => {},
  valueCarry = false,
  groupLabel = "",
}) => {
  const [inputValueLocal, setInputValueLocal] = useState(inputVal);
  const [funcListLoading, setFuncListLoading] = useState(false);

  const { clientName } = useRoles();

  const [functions, setFunctions] = useState(list || []);
  useEffect(() => {
    setInputValueLocal("");
    const getFunctionList = async () => {
      setFuncListLoading(true);
      try {
        //passClientId ? `?clientId=${clientName}` : ""
        const res = await AuthService.functionsListV3(
          subSector !== "" ? `?subSectorIds=${subSector}` : "",
          passClientId
            ? `${subSector !== "" ? "&" : "?"}clientId=${clientName}`
            : ""
        );
        const sorted = await SortString(res, "functionName");
        // await sorted.unshift({ functionId: "Any", functionName: "Any" });
        setFunctions(sorted);
      } catch (error) {
        console.log(error);
      } finally {
        setFuncListLoading(false);
      }
    };

    if (!!subSector && !!clientName) triggerApi && getFunctionList();
    return () => {
      // setFunctions([]);
    };
  }, [subSector, clientName]);

  return (
    <>
      <AutoFilterLocal
        list={!!subSector ? functions || [] : list}
        filterLabel={filterLabel}
        label={label}
        style={{ padding: 0, width: "100%" }}
        optionChange={(val, reason) => handleSearch(val, reason)}
        value={value || undefined}
        inputVal={inputValueLocal}
        inputBoxChange={(val) => setInputValueLocal(val)}
        groupLabel={groupLabel}
        placeholder={placeholder}
        valueCarry={valueCarry}
        PopperComponent={(props) => (
          <div {...props} className="subSectorPopper" />
        )}
        disabled={funcListLoading}
      />
    </>
  );
};
