import React from "react";
import styled from "styled-components";
import COLORS from "../../assets/colors";

const Wrapper = styled.div`
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;

  @media (max-width: 1310px) {
    width: 100%;
  }
  @media (min-width: 1400px) {
    width: 100%;
  }
`;

const BodyBlock = styled.div`
  background-color: ${COLORS.BODY_ASH};
  border-top-left-radius: 30px;
  min-height: calc(100vh - 65px);
  padding: 25px 20px;
  p {
    font-size: 12px;
    line-height: 22px;
    letter-spacing: -0.06px;
  }
`;

const BodyWhiteBg = styled.div`
  background-color: ${COLORS.PRIMARY_WHITE};
  border-radius: 14px;
  box-shadow: 0 1px 3px 0 rgba(97, 122, 163, 0.24);
  padding: 30px;
`;

const RowBlock = styled.div`
  display: flex;
  margin: 0 -15px;
`;

const Column6 = styled.div`
  flex: 0 0 60%;
  padding: 0 15px;
`;

const Column4 = styled.div`
  flex: 0 0 40%;
  padding: 0 15px;
`;

const Column5 = styled.div`
  flex: 0 0 50%;
  padding: 0 15px;
`;

const BodyContent = ({ children, ...props }) => (
  <BodyBlock {...props}>{children}</BodyBlock>
);
const BodyWhitePattern = ({ children, ...props }) => (
  <BodyWhiteBg {...props}>{children}</BodyWhiteBg>
);
const Container = ({ children, ...props }) => (
  <Wrapper {...props}>{children}</Wrapper>
);
const Row = ({ children, ...props }) => (
  <RowBlock {...props}>{children}</RowBlock>
);
const Col6 = ({ children, ...props }) => (
  <Column6 {...props}>{children}</Column6>
);
const Col4 = ({ children, ...props }) => (
  <Column4 {...props}>{children}</Column4>
);
const Col5 = ({ children, ...props }) => (
  <Column5 {...props}>{children}</Column5>
);

export const LinkColumn = styled.div`
  color: ${COLORS.TITLE_BLUE};
  text-decoration: underline;
`;

export { Container, Row, Col6, Col4, Col5, BodyContent, BodyWhitePattern };
