import React, { useState, createContext, memo, useMemo } from "react";

export const PopUpContext = createContext();

const initialPopUpState = {
  isOpen: false,
  title: "",
  message: "",
  yesText: "Yes",
  noText: "No",
  onConfirm: () => {},
  showCloseIcon: false,
  center: true,
  maxWidth: "sm",
  titleStyle: { padding: "22px" },
};

const PopUpProvider = memo(({ children }) => {
  const [popUpState, setPopUpState] = useState(initialPopUpState);
  
  const alert = (message, title = "", yesText = "Ok") => {
    return openPopUp({ message, title, yesText, noText: "" });
  }

  const confirm = (message, title = "", onConfirm = () => {}, noText = "No", yesText = "Yes") => {
    return openPopUp({ message, title, onConfirm, noText, yesText })
  }

  const openPopUp = ({
    title = "",
    message = "",
    yesText = "Yes",
    noText = "No",
    onConfirm = () => {},
    showCloseIcon = false,
    center = true,
    maxWidth = "sm",
    titleStyle = { padding: "22px" },
  }) => {
    setPopUpState({
      isOpen: true,
      title,
      message,
      yesText,
      noText,
      onConfirm,
      showCloseIcon,
      center,
      maxWidth,
      titleStyle
    });
  };

  const closePopUp = async () => {
    await setPopUpState(initialPopUpState);
  };

  const popUp = {
    alert: alert,
    confirm: confirm
  }

  const value = useMemo(() => {
    return {
      popUpState,
      setPopUpState,
      openPopUp,
      closePopUp,
      popUp
    }
  }, [popUpState, setPopUpState])

  return (
    <PopUpContext.Provider value={value}>
      {children}
    </PopUpContext.Provider>
  );
});

export default PopUpProvider;
