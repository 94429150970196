import React from "react";
import styled from "styled-components";
import COLORS from "../../assets/colors";
import { H2 } from "../Common/Typography";

export default () => {
  return (
    <NotFound>
      <div className="white-box">
        <H2>404 Not found</H2>
      </div>
    </NotFound>
  );
};

const NotFound = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  .white-box {
    background-color: ${COLORS.PRIMARY_WHITE};
    border-radius: 12px;
    display: inherit;
    align-items: center;
    justify-content: center;
    padding: 20px 30px;
    height: 300px;
    text-align: center;
    width: 376px;
    h2 {
      color: ${COLORS.TITLE_BLUE};
      padding-bottom: 0;
    }
  }
`;
