import _ from "lodash";

export const PageCount = 5;
export const WikiLink = (val) =>
  window.open(`https://en.wikipedia.org/wiki/${val.trim()}`, "_blank");

export const UniqueArray = (arr, key) => [
  ...new Map(arr.map((item) => [item[key], item])).values(),
];

export const ClientMenu = [
  { label: "Angel Broking", value: "Angel Broking" },
  { label: "Disprz", value: "Disprz" },
  { label: "Entrans", value: "Entrans" },
];

export const SkillStatusMenu = [
  { label: "Draft", value: 1 },
  { label: "SME Approved", value: 2 },
  { label: "SA Approved", value: 3 },
  { label: "Published", value: 4 },
  { label: "Retired", value: 5 },
];

export const SkillStatusMenuSME = [
  { label: "Draft", value: 1 },
  { label: "SME Approved", value: 2 },
  { label: "SA Approved", value: 3 },
];

export const UserTypeMenu = [
  { value: "1", label: "General User" },
  { value: "2", label: "Super User" },
  { value: "8", label: "General User - Internal" },
  { value: "4", label: "Skilling Architect" },
];

export const UserTypeMenuNumber = [
  { value: 1, label: "General User" },
  { value: 2, label: "Super User" },
  { value: 8, label: "General User - Internal" },
  { value: 4, label: "Skilling Architect" },
];

export const Turncate = (str, num, init = 0) =>
  str.length > num ? str.substring(init, num) + ".." : str;

export const GroupByKey = (arr, keyname) =>
  arr.reduce((r, a) => {
    let key = a[keyname] || "others";
    r[key] = r[key] || [];
    r[key].push(a);
    return r;
  }, Object.create(null));

export const CheckSameArray = (arr1, arr2) =>
  JSON.stringify(arr1) === JSON.stringify(arr2);

export const FunSkillKey = "Functional Skills";

export const finalLevelLegendColors = [
  {
    type: "Functional Skills",
    color: "rgba(48, 159, 219, 1)",
    hexCode: "#309fdb",
  },
  { type: "Digital Tools", color: "rgba(216, 134, 13, 1)", hexCode: "#d8860d" },
  {
    type: "Leadership Skills",
    color: "rgba(170, 82, 155, 1)",
    hexCode: "#aa529b",
  },
  {
    type: "Mindset / Meta Skills",
    color: "rgba(109, 99, 193, 1)",
    hexCode: "#6d63c1",
  },
];

export const firstLevelLegendColors = [
  {
    type: "Industries",
    color: "rgb(128, 62, 176)",
    hexCode: "#aa529b"
  },
  { type: "Functions",
    color: "rgb(170, 82, 155)",
    hexCode: "#803eb0"
  },
  {
      type: "Department",
      color: "rgb(48, 159, 219)",
      hexCode: "#309fdb",
  },
  {
      type: "Track",
      color: "rgb(26, 214, 177)",
      hexCode: "#1ad6b1",
  }
];

export const secondLevelDepartmentLegendColors = [
  {
      type: "Roles",
      color: "rgb(110, 64, 170)",
      hexCode: "#6e40aa",
  },
  {
    type: "Department",
    color: "rgb(48, 159, 219)",
    hexCode: "#309fdb",
  }
];

export const secondLevelTrackLegendColors = [
  {
      type: "Roles",
      color: "rgb(110, 64, 170)",
      hexCode: "#6e40aa",
  },
  {
    type: "Track",
    color: "rgb(26, 214, 177)",
    hexCode: "#1ad6b1",
  }
];

export const thirdLevelLegendColors = [
  {
    type: "Roles",
    color: "rgb(110, 64, 170)",
    hexCode: "#6e40aa",
  }
];

export const PlaceholderText = `Sample Description of Roles and Responsibilities -

A Data Scientist will support our product, sales, leadership and marketing teams with insights gained from analyzing company data. He/She should be adept at using large data sets to find opportunities for product and process optimization and using models to test the effectiveness of different courses of action. He/She must have a proven ability to drive business results with their data-based insights. 
The responsibilities primarily include -

* Working with stakeholders throughout the organization to identify opportunities for leveraging company data to drive business solutions.
* Mine and analyze data from company databases to drive improvement of product development, marketing techniques and business strategies.
* Assess the effectiveness and accuracy of new data sources
* Develop custom data models and algorithms to apply to data sets.

Additionally, the Data Scientist should have the following skills -

* Strong problem-solving skills with an emphasis on product development.
* Experience working with and creating data architectures.
* Knowledge of a variety of machine learning techniques (clustering, decision tree, artificial neural networks, etc.) and their real-world advantages/drawbacks.
* Excellent written and verbal communication skills for coordinating across teams.
* A drive to learn and master new technologies and techniques.
* Coding knowledge and experience with several languages: C, C++, Java, etc.
`;
