/* eslint-disable no-use-before-define */
import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

import COLORS from "../../../assets/colors";
import { useField } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-multiline": {
      // borderRadius: "0px",
      padding: 0,
    },
    "& .MuiOutlinedInput-input": {
      color: COLORS.TEXT_BLUE,
      fontSize: 12,
      padding: 12,
    },
  },
  autocomp: {
    "& .MuiAutocomplete-inputRoot": {
      padding: 3,
    },
  },
}));

const StyledErrorMessage = styled.div`
  font-size: 12px;
  color: ${COLORS.PRIMARY_RED};
  &:before {
    content: "";
    font-size: 10px;
  }
`;

const Label = styled.label`
  color: ${COLORS.TITLE_BLUE};
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 10px;
  i {
    color: ${COLORS.PRIMARY_RED};
    font-size: 11px;
    font-weight: 400;
  }
`;

const TextInputContainer = styled.div`
  position: relative;
  padding-bottom: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: start;
`;

export default ({
  label,
  list,
  optionLabel = null,
  renderOption,
  PopperComponent,
  optionChange = () => {},
  children,
  ...props
}) => {
  const [open, setOpen] = useState(false);

  const [optionsList, setOptionsList] = useState(list);
  const [listUpdate, setListUpdate] = useState(false);
  const [field, meta, helpers] = useField(props);
  const classes = useStyles();

  const handleChange = (e, val) => {
    const res = list.filter((el) =>
      !!optionLabel
        ? el[optionLabel].toLowerCase().indexOf(val.toLowerCase()) !== -1
        : el.toLowerCase().indexOf(val.toLowerCase()) !== -1,
    );
    setOptionsList(res);
    if (field.value !== val) setListUpdate(true);
  };
  // console.log(field);
  // console.log(meta);
  // console.log(helpers);
  // console.log(list, listUpdate, field);
  return (
    <TextInputContainer>
      {props.labelShow !== false && (
        <Label htmlFor={props.id || props.name}>
          {label}{" "}
          {optionsList.length === 0 && !!listUpdate ? <i>(New)</i> : null}
        </Label>
      )}
      <Autocomplete
        // open={true}
        className={classes.autocomp}
        freeSolo
        // field={...field}
        // {...field}
        {...props}
        onOpen={() => setOpen(true)}
        onClose={() => {
          setOpen(false);
          setOptionsList(list);
        }}
        options={optionsList}
        getOptionLabel={(option) =>
          !!optionLabel ? option[optionLabel] : option
        }
        // value={field.value}
        onChange={(event, newValue) => {
          // console.log(newValue);
          optionChange(newValue);
          helpers.setValue(newValue);
        }}
        // inputValue={!!optionsList ? meta.value : undefined}
        onInputChange={(event, newInputValue) => {
          handleChange(event, newInputValue);
          // console.log(newInputValue);
          // !!newInputValue && helpers.setValue(newInputValue);
        }}
        renderOption={(option) => renderOption(option)}
        PopperComponent={
          !!PopperComponent && !!optionsList.length
            ? PopperComponent
            : undefined
        }
        renderInput={
          (params) => (
            <TextField
              {...field}
              {...props}
              {...params}
              className={classes.root}
              variant="outlined"
            />
          )
          //   <TextField {...params} label="" margin="normal" variant="outlined" />
        }
      />
      {meta.touched && meta.error ? (
        <StyledErrorMessage>{meta.error}</StyledErrorMessage>
      ) : null}
    </TextInputContainer>
  );
};
