import React from "react";
import { SnackbarProvider } from "notistack";
import { unstable_createMuiStrictModeTheme } from "@material-ui/core/styles";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import AlertProvider from "./Provider/AlertProvider.js";
import FullPageLoaderProvider from "./Provider/FullPageLoaderProvider.js";
import FullPageLoader from "./components/Common/Loader/FullPageLoader.js";
import AuthProvider from "./Provider/AuthProvider.js";
import Routing from "./Routing/index.js";
import PersonaRolesProvider from "./Provider/PersonaRolesProvider.js";
import MasterListProvider from "./Provider/MasterListProvider.js";
import { SkillTableDataProvider } from "./Provider/SkillTableProvider.js";
import TableProvider from "./Provider/TableProvider.js";
import RoleExplorerProvider from "./Provider/RoleExplorerProvider.js";
import PopUpProvider from "./Provider/PopUpProvider.js";
import PopUp from "./components/Common/PopUp/PopUp.jsx";

const theme = unstable_createMuiStrictModeTheme();

function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <FullPageLoaderProvider>
        <PopUpProvider>
          <SnackbarProvider>
            <AlertProvider>
              <TableProvider>
                <AuthProvider>
                  <PersonaRolesProvider>
                    <MasterListProvider>
                      <SkillTableDataProvider>
                        <RoleExplorerProvider>
                          <>
                            <FullPageLoader />
                            <PopUp />
                            <Routing />
                          </>
                        </RoleExplorerProvider>
                      </SkillTableDataProvider>
                    </MasterListProvider>
                  </PersonaRolesProvider>
                </AuthProvider>
              </TableProvider>
            </AlertProvider>
          </SnackbarProvider>
        </PopUpProvider>
      </FullPageLoaderProvider>
    </MuiThemeProvider>
  );
}
export default App;
