import React from "react";
import styled from "styled-components";
import TextField from "@material-ui/core/TextField";
import COLORS from "./../../../assets/colors";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-multiline": {
      // borderRadius: "0px",
      padding: 0,
    },
    "& .MuiOutlinedInput-input": {
      color: COLORS.TEXT_BLUE,
      fontSize: 12,
      padding: 12,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      // border: "none",
    },
  },
}));

const Label = styled.label`
  color: ${COLORS.TITLE_BLUE};
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 10px;
`;

const TextInputContainer = styled.div`
  position: relative;
  padding-bottom: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: start;
`;

export default ({ label, children, style, ...props }) => {
  const classes = useStyles();
  return (
    <TextInputContainer style={style}>
      {!!label && <Label htmlFor={props.id || props.name}>{label}</Label>}
      <TextField {...props} className={classes.root} variant="outlined" />
    </TextInputContainer>
  );
};
