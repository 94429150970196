import React, { createRef, useState, useEffect } from "react";
import { navigate } from "@reach/router";
import Layout from "../Layout";
import { H2 } from "../Common/Typography";
import { BodyContent, Container, BodyWhitePattern } from "../Common/GridSystem";
import BackNav, { BackCheck } from "../Common/BackNav";
import { Formik, Form } from "formik";
import {
  userManagementValidation,
  userManagementValidationApplication,
} from "../../validations/authValidation";
import FormTextField from "../Common/FormElements/FormTextField";
import { PrimaryButton } from "../Common/Buttons/PrimaryButton";
import FormRadioGroup from "../Common/FormElements/FormRadioGroup";
import useAlert from "../../Hooks/useAlert";
import { AuthService } from "../../services/AuthService";
import ModalPopup from "../Common/ModalPopup";
import AutoFilterLocal from "../Common/FormElements/AutoFilterLocal";
import useFullLoader from "../../Hooks/useFullLoader";
import ChipList from "../Common/Others/ChipList";
import ConfirmationButton from "../Common/ConfirmationButton";
import { UniqueArray, UserTypeMenu } from "../utils";
import { SortString } from "../../helpers/SortString";
import { UserType } from "../../enums/UserType";
import { isUserAllowed } from "../../helpers/CommonHelper";

export default ({ ...props }) => {
  const { mode, id } = props;
  const editMode = mode === "edit";

  const { showAlert } = useAlert();
  const { setFullLoader } = useFullLoader();

  const formikRef = createRef(null);
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [confirmTitle, setConfirmTitle] = useState("");
  const [confirmType, setConfirmType] = useState(null);

  const [clientLocalList, setClientLocalList] = useState([]);
  const [clientCurrentList, setClientCurrentList] = useState([]);
  const [detailData, setdetailData] = useState(null);

  const getClientList = async () => {
    setFullLoader(true);
    try {
      const res = await AuthService.getAllClientsV3();
      setClientLocalList(res);
    } catch (error) {
      console.log(error);
    } finally {
      setFullLoader(false);
    }
  };

  const getUser = async () => {
    setFullLoader(true);
    try {
      const res = await AuthService.getUserDetailV3("/" + id);
      setdetailData(res);
      if (isUserAllowed(res.userType.userTypeId, [UserType.GENERAL_USER, UserType.GENERAL_USER_INTERNAL])) {
        setClientCurrentList(
          SortString(UniqueArray(res.clientList, "clientId"), "clientName")
        );
      } else {
        setClientCurrentList([]);
      }
    } catch (error) {
      showAlert(error.error, "error", 1500);
      console.log(error);
    } finally {
      setFullLoader(false);
    }
  };

  useEffect(() => {
    getClientList();
    if (editMode) getUser();
  }, []);

  const handleSearch = (item, reason) => {
    console.log("handleSearch -> item", item, clientCurrentList);
    if (reason === "select-option") {
      if (!clientCurrentList.some((el) => el.clientId === item.clientId)) {
        setClientCurrentList((prev) =>
          reason === "select-option" ? [...prev, item] : prev
        );
      } else {
        showAlert("Client already added", "info", 1500);
      }
    }
  };

  const removeChip = (item) =>
    setClientCurrentList((prev) =>
      prev.filter((el) => el.clientId !== item.clientId)
    );

  // console.log(editMode, detailData);
  const editData = editMode && !!detailData;

  const formSubmit = async (fieldValues) => {
    // console.log("formSubmit -> fieldValues", fieldValues);
    if (
      isUserAllowed(Number(fieldValues.userTypeId), [UserType.GENERAL_USER, UserType.GENERAL_USER_INTERNAL]) &&
      clientCurrentList.length === 0
    ) {
      showAlert("Associate User to Clients", "info", 1500);
    } else {
      setFullLoader(true);
      try {
        let payload = {
          ...fieldValues,
          userTypeId: Number(fieldValues.userTypeId),
          ssoTypeId: Number(fieldValues.ssoTypeId),
        };
        if (editMode) {
          await setFullLoader(false);
          setConfirmTitle(`Confirm User Updation`);
          setConfirmPopup(true);
          setConfirmType("update");
        } else {
          const res = await AuthService.addUsersV3(payload);
          // console.log("formSubmit -> res", res);
          if (isUserAllowed(Number(fieldValues.userTypeId), [UserType.GENERAL_USER, UserType.GENERAL_USER_INTERNAL])) {
            await associateClient(res);
          }
          await showAlert(`User Creation Successful`, "success", 2000);
          await setFullLoader(false);
          navigate("/users");
        }
      } catch (error) {
        console.log("formSubmit -> error", error);
        showAlert(error.error, "error", 1500);
      } finally {
        setFullLoader(false);
      }
    }
  };

  const associateClient = async (userId) => {
    try {
      const Ids = clientCurrentList.map((el) => el.clientId);
      await AuthService.userAssociateClientsV3(
        `/${editMode ? id : userId}/clients`,
        Ids
      );
    } catch (err) {
      console.log(err);
    }
  };

  const handleUpdateYes = async () => {
    const { values } = formikRef.current;
    closePopup();
    setFullLoader(true);
    try {
      await AuthService.updateUsersV3("/" + Number(id), {
        ...values,
        userTypeId: Number(values.userTypeId),
        ssoTypeId: Number(values.ssoTypeId),
      });

      if (isUserAllowed(Number(values.userTypeId), [UserType.GENERAL_USER, UserType.GENERAL_USER_INTERNAL])) {
        await associateClient();
      }
      await showAlert("User Updation Successful", "success", 2000);
      setFullLoader(false);
      navigate("/users");
    } catch (error) {
      showAlert(error.error, "error", 1500);
      console.log(error);
    } finally {
      setFullLoader(false);
    }
  };

  const handleYes = () => {
    if (confirmType === "update") {
      handleUpdateYes();
    } else if (confirmType === "back") {
      navigate("/users");
    }
  };

  const handleNo = () => closePopup();
  const closePopup = () => {
    setConfirmPopup(false);
    setConfirmTitle("");
    setConfirmType(null);
  };

  const handleCheckBack = () => {
    const hasFormChange = !!Object.keys(formikRef.current.touched).length;
    console.log(formikRef.current.touched);
    if (hasFormChange) {
      setConfirmPopup(true);
      setConfirmType("back");
      setConfirmTitle(`Proceed without saving changes?`);
    } else {
      navigate("/users");
    }
  };
  const handleSelectAll = () =>{
    if (clientCurrentList.length === clientLocalList.length) {
      setClientCurrentList([]);
    }else{
      setClientCurrentList(clientLocalList);
    }
  };
  const ConfirmModal = () => (
    <ModalPopup
      open={confirmPopup}
      maxWidth="sm"
      title={confirmTitle}
      center={true}
    >
      <ConfirmationButton yesClick={handleYes} noClick={handleNo} />
    </ModalPopup>
  );

  const initValue = {
    name: editData ? detailData?.name : "",
    email: editData ? detailData?.email : "",
    userTypeId: editData
      ? String(detailData?.userType.userTypeId)
      : UserTypeMenu[0].value,
    ssoTypeId: editData ? String(detailData?.ssoType?.ssoTypeId) : "1",
    loginId: "",
    password: "",
  };
  // console.log(clientCurrentList);
  return (
    <Layout>
      <H2>{mode === "add" ? "Create New User" : "Manage User Account"}</H2>
      <BodyContent>
        <Container>
          {/* <BackNav linkNav="/users" /> */}
          <BackCheck handleCheckBack={handleCheckBack} />
          <ConfirmModal />
          <BodyWhitePattern>
            <Formik
              enableReinitialize={true}
              initialValues={initValue}
              innerRef={formikRef}
              validationSchema={
                editMode && detailData?.ssoType?.ssoTypeId == 1
                  ? userManagementValidationApplication
                  : userManagementValidation
              }
              onSubmit={formSubmit}
            >
              {({
                touched,
                errors,
                values,
                setFieldValue,
                setFieldTouched,
              }) => {
                return (
                  <Form style={{ width: 340 }}>
                    {/* {console.log("values", values)} */}
                    <FormTextField
                      placeholder=""
                      name="name"
                      type="text"
                      label="Display Name"
                      setVal={setFieldValue}
                      setTouched={setFieldTouched}
                    />
                    <FormTextField
                      placeholder=""
                      name="email"
                      type="text"
                      label="Email ID"
                      setVal={setFieldValue}
                      setTouched={setFieldTouched}
                    />
                    <FormRadioGroup
                      name="userTypeId"
                      label="User Type *"
                      onChange={() => setClientCurrentList([])}
                      radioList={UserTypeMenu}
                      style={{ whiteSpace: "nowrap" }}
                      // halfWidth={true}
                    />
                    {(isUserAllowed(Number(values.userTypeId), [UserType.GENERAL_USER, UserType.GENERAL_USER_INTERNAL])) && (
                      <>
                        <AutoFilterLocal
                          list={clientLocalList}
                          filterLabel="clientName"
                          label="Associate Client *"
                          style={{ padding: "0px 0px 30px 0px", width: "100%" }}
                          placeholder="Search client"
                          optionChange={handleSearch}
                          value=""
                          inputValue=""
                          selectAll={Number(values.userTypeId) === UserType.GENERAL_USER_INTERNAL}
                          unselectAll={clientCurrentList.length === clientLocalList.length}
                          selectAllClick={handleSelectAll}
                        />                        
                        {!!clientCurrentList.length && (
                          <ChipList
                            list={clientCurrentList}
                            optionLabel="clientName"
                            onDelete={removeChip}
                            chipClick={undefined}
                          />
                        )}
                      </>
                    )}

                    <FormRadioGroup
                      name="ssoTypeId"
                      label="Login Type *"
                      radioList={[
                        { value: "1", label: "Application" },
                        { value: "2", label: "Google" },
                      ]}
                    />
                    {(values.ssoTypeId === "1" && mode === "add") ||
                    (mode === "edit" &&
                      values.ssoTypeId === "1" &&
                      !!detailData &&
                      String(detailData?.ssoType?.ssoTypeId) !== "1") ? (
                      <>
                        <FormTextField
                          placeholder=""
                          name="loginId"
                          type="text"
                          label="Login ID"
                          setVal={setFieldValue}
                          setTouched={setFieldTouched}
                        />
                        <FormTextField
                          placeholder=""
                          name="password"
                          type="password"
                          label="Password"
                          setVal={setFieldValue}
                          setTouched={setFieldTouched}
                        />
                      </>
                    ) : null}
                    <PrimaryButton type="submit">Submit</PrimaryButton>
                    {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}

                    {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                  </Form>
                );
              }}
            </Formik>
          </BodyWhitePattern>
        </Container>
      </BodyContent>
    </Layout>
  );
};
