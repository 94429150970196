import React from 'react';
import { Col5 } from '../../GridSystem';
import { H5 } from '../../Typography';
import CustomAccordianList from '../../CustomAccordianList';
import { AccordArrays } from '../../../../helpers/AccordArray';
import './RolesAndResponsibilitiesPopup.scss';
import COLORS from '../../../../assets/colors';
import ModalPopup from '../../ModalPopup';

const RolesAndResponsibilitiesPopup = ({
  open = false,
  close = () => {},
  personaDetail = null,
  industryroleDetail = null,
}) => {

  return (
    <ModalPopup
      open={open}
      closePopup={close}
      maxWidth="lg"
      center={true}
      className="detailPopup"
    >
      <div className="detail-block">
        {!!personaDetail && (
          <Col5 style={{ borderRight: `solid 1px ${COLORS.ASH_BORDER}` }}>
            <H5>Roles and Responsibilities As per company</H5>
            <div
              className=""
              dangerouslySetInnerHTML={{
                __html: personaDetail?.rolesResponsibilities.replace(
                  /\|/g,
                  "<br /><br />"
                ),
              }}
            />
          </Col5>
        )}

        <Col5>
          <H5>Roles as per Industry</H5>
          <div className="detail-box">
            {!!industryroleDetail && (
              <>
                <div
                  dangerouslySetInnerHTML={{
                    __html: industryroleDetail?.roleDescription.replace(
                      /\|/g,
                      "<br /><br />"
                    ),
                  }}
                />
                <div className="border-btm">&nbsp;</div>
              </>
            )}

            {!!industryroleDetail && (
              <>
                <H5>Critical Work Functions and Tasks</H5>
                <CustomAccordianList
                  list={AccordArrays(
                    industryroleDetail?.roleandResponsibilities
                  )}
                  initOpen={null}
                />
              </>
            )}
          </div>
        </Col5>
      </div>
    </ModalPopup>
  );
};

export default RolesAndResponsibilitiesPopup;