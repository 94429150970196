import { Images } from "../../assets/images";
import { Videos } from "../../assets/videos";
import { UserType } from "../../enums/UserType";

// User_Type === 1 is general user, User_Type === 2 is Super user
// User_Type === 3 is SME user, User_Type === 4 is Skilling Architect

export const SkillBoxList = [
  {
    styleName: "right-sharp",
    title: "As a Client L&D Head / HR Manager",
    accessUserTypes: [UserType.GENERAL_USER, UserType.GENERAL_USER_INTERNAL, UserType.SUPER_USER, UserType.SKILLING_ARCHITECT],
    content: [
      {
        pageLink: "/personas",
        imgSrc: Images.dashboardIcons.define_client_persona,
        list: [
          {
            link: "/personas",
            linkText: `Define Client Personas (Job Roles)`,
            showVideoIcon: true,
            videoSrc: Videos.Persona_Creation,
            videoTitle: 'Define Client Personas (Job Roles)',
            tooltipTitle: 'Click to watch how a client persona is defined'
          },
        ],
      },
      {
        pageLink: "ARCH_SKILL",
        imgSrc: Images.dashboardIcons.architect_skills_for_roles,
        list: [
          {
            link: "/",
            linkText: `Assign Skills for Client Persona (Job Roles)`,
            showVideoIcon: true,
            videoSrc: Videos.Assign_Skills_Persona,
            videoTitle: 'Assign Skills for Client Persona (Job Roles)',
            tooltipTitle: 'Click to watch how to assign skills to a persona'
          },
        ],
        clickable: true,
      },
      {
        pageLink: "/role_explorer",
        imgSrc: Images.dashboardIcons.role_explorer,
        list: [
          {
            link: "/role_explorer",
            linkText: `Role Explorer`,
          },
        ],
      },
      {
        // pageLink: "/compare-persona",
        pageLink: "COMPARE_PERSONA",
        imgSrc: Images.dashboardIcons.compare_personas,
        list: [
          {
            link: "/",
            linkText: `Compare Personas`,
          },
        ],
        clickable: true,
      },
      {
        pageLink: "COMPARE_ROLE",
        // pageLink: "/compare-role",
        imgSrc: Images.dashboardIcons.compare_roles,
        list: [
          {
            // link: "/compare-role",
            link: "/",
            linkText: `Compare Industry Roles`,
          },
        ],
        clickable: true,
      },
    ],
  },
  {
    styleName: "left-sharp",
    title: "As a Skilling Architect",
    accessUserTypes: [2, 4],
    content: [
      {
        pageLink: "/skill/search",
        imgSrc: Images.dashboardIcons.add_maintain_skills,
        list: [
          {
            link: "/skill/search",
            linkText: `Manage Skills`,
          },
        ],
      },
      {
        pageLink: "/role",
        imgSrc: Images.dashboardIcons.add_maintain_roles,
        list: [
          {
            link: "/role",
            linkText: `Manage Industry Roles`,
          },
        ],
      },
      {
        pageLink: "/disprzopedia",
        imgSrc: Images.dashboardIcons.add_maintain_disprzopedia,
        list: [
          {
            link: "/disprzopedia",
            linkText: `Manage Disprzopedia`,
          },
        ],
      },
    ],
  },
  {
    styleName: "left-sharp",
    title: "As a Skilling Architect",
    accessUserTypes: [3],
    content: [
      {
        pageLink: "/skill/search",
        imgSrc: Images.dashboardIcons.add_maintain_skills,
        list: [
          {
            link: "/skill/search",
            linkText: `Manage Skills`,
          },
        ],
      },
      {
        pageLink: "/role",
        imgSrc: Images.dashboardIcons.add_maintain_roles,
        list: [
          {
            link: "/role",
            linkText: `Manage Industry Roles`,
          },
        ],
      },
    ],
  },

  {
    styleName: "right-sharp",
    title: "As an Administrator",
    accessUserTypes: [2],
    content: [
      {
        pageLink: "/users",
        imgSrc: Images.dashboardIcons.add_manage_users,
        list: [
          {
            link: "/users",
            linkText: `User Management`,
          },
        ],
      },
      {
        pageLink: "/clients",
        imgSrc: Images.dashboardIcons.add_manage_clients,
        list: [
          {
            link: "/clients",
            linkText: `Client Management`,
          },
        ],
      },
    ],
  },
];
