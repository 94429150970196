import React from "react";
import styled from "styled-components";
import { Images } from "../../assets/images";
import COLORS from "../../assets/colors";
import ToolBar from "../Common/ToolBar";
import ScrollBarX from "../Common/CutomScroll/ScrollBarX";

export default ({ data }) => {
  const strCount = data?.leadership_role_name !== "" ? 15 : 20;
  return (
    <PrimaryBlock>
      <ul>
        <li>
          <img src={Images.icons.idIcon} alt="job-id" />
          <div>
            <span>Id</span>
            <ToolBar title={data?.id} arrow={true}>
              <strong>{data?.id?.substring(0, 10)}...</strong>
            </ToolBar>
          </div>
        </li>
        <li>
          <img src={Images.icons.titleIcon} alt="job-id" />
          <div>
            <span>Title</span>
            {!!data?.job_posting_title &&
            data?.job_posting_title.length < strCount ? (
              <strong>{data?.job_posting_title}</strong>
            ) : (
              <ToolBar title={data?.job_posting_title} arrow={true}>
                <strong>
                  {data?.job_posting_title.substring(0, strCount)}...
                </strong>
              </ToolBar>
            )}
          </div>
        </li>
        <li>
          <img src={Images.icons.companyIcon} alt="job-id" />
          <div>
            <span>Company</span>
            {!!data?.company && data?.company.length < strCount ? (
              <strong>{data?.company}</strong>
            ) : (
              <ToolBar title={data?.company} arrow={true}>
                <strong>{data?.company.substring(0, strCount)}...</strong>
              </ToolBar>
            )}
          </div>
        </li>
        <li>
          <img src={Images.icons.locateIcon} alt="job-id" />
          <div>
            <span>Location</span>
            {!!data?.country && data?.country.length < strCount ? (
              <strong>{data?.country}</strong>
            ) : (
              <ToolBar title={data?.country} arrow={true}>
                <strong>{data?.country.substring(0, strCount)}...</strong>
              </ToolBar>
            )}
          </div>
        </li>
        <li>
          <img src={Images.icons.sectorIcon} alt="job-id" />
          {!!data && !data.sub_sector ? (
            <div>
              <span>Sub-Sector</span>
              {!!data?.industry && data?.industry.length < strCount ? (
                <strong>{data?.industry}</strong>
              ) : (
                <ToolBar title={data?.industry} arrow={true}>
                  <strong>{data?.industry.substring(0, strCount)}...</strong>
                </ToolBar>
              )}
            </div>
          ) : (
            <div>
              <span>Sub-Sector</span>
              {!!data?.sub_sector && data?.sub_sector.length < strCount ? (
                <strong>{data?.sub_sector}</strong>
              ) : (
                <ToolBar title={data?.sub_sector} arrow={true}>
                  <strong>{data?.sub_sector.substring(0, strCount)}...</strong>
                </ToolBar>
              )}
            </div>
          )}
        </li>

        <li>
          <img src={Images.icons.roleIcon} alt="job-id" />
          {!!data && !data.industry_role_name ? (
            <div>
              <span>Annotated Role</span>
              {!!data?.normalised_title &&
              data?.normalised_title.length < strCount ? (
                <strong>{data?.normalised_title}</strong>
              ) : (
                <ToolBar title={data?.normalised_title} arrow={true}>
                  <strong>
                    {data?.normalised_title?.substring(0, strCount)}...
                  </strong>
                </ToolBar>
              )}
            </div>
          ) : (
            <div>
              <span>Annotated Role</span>
              {!!data?.industry_role_name &&
              data?.industry_role_name.length < strCount ? (
                <strong>{data?.industry_role_name}</strong>
              ) : (
                <ToolBar title={data?.industry_role_name} arrow={true}>
                  <strong>
                    {data?.industry_role_name.substring(0, strCount)}...
                  </strong>
                </ToolBar>
              )}
            </div>
          )}
        </li>
        <li>
          <img src={Images.icons.functionIcon} alt="job-id" />
          <div>
            <span>Source</span>
            {!!data?.source && data?.source.length < strCount ? (
              <strong>{data?.source}</strong>
            ) : (
              <ToolBar title={data?.source} arrow={true}>
                <strong>{data?.source.substring(0, strCount)}...</strong>
              </ToolBar>
            )}
          </div>
        </li>
        {!!data && data.leadership_role_name ? (
          <li>
            <img src={Images.icons.leaderIcon} alt="job-id" />
            <div>
              <span>Annotated Leadership role</span>
              <strong>{data.leadership_role_name}</strong>
            </div>
          </li>
        ) : null}
      </ul>
    </PrimaryBlock>
  );
};

const PrimaryBlock = styled(ScrollBarX)`
  padding: 0px 32px 20px 0px;

  ul {
    width: calc(100vw - 124px);
    white-space: nowrap;
    li {
      border-left: solid 1px ${COLORS.BLUE_ASH};
      color: ${COLORS.PRIMARY_WHITE};
      display: inline-flex;
      align-items: flex-end;
      padding: 0px 15px;
      &:first-child {
        padding-left: 0px;
        border: none;
      }
      img {
        bottom: 4px;
        margin-right: 5px;
        position: relative;
      }
      span {
        display: block;
        font-size: 12px;
        font-weight: 300;
      }
      strong {
        cursor: pointer;
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
`;
