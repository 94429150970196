import React, {useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import COLORS from "../../assets/colors";
import styled from "styled-components";
import SelectMenu from "./FormElements/SelectMenu";
import PaginationInfo from "./PaginationInfo";

const localMenu = [
  { label: 10, value: 10 },
  { label: 20, value: 20 },
  { label: 30, value: 30 },
  { label: 40, value: 40 },
  { label: 50, value: 50 },
];

export const CountInit = 30;

export default function PaginationCustom({
  pageTotal,
  handlePagination,
  countRows,
  changeRowCount,
  countRowsList,
  changeCurrentPage = '',
  showPaginatedInfo = false
}) {
  const classes = useStyles();
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    handlePagination(event, page);
  };
  return (
    <div className={classes.root}>
      <div>
        <Pagination
          count={Math.ceil(pageTotal / countRows)}
          onChange={(event, page) => {
            // handlePagination(event, page);
            handlePageChange(event, page);
          }}
          variant="outlined"
          shape="rounded"
          // page={2}
          page={!!changeCurrentPage ? changeCurrentPage : currentPage}
        />
        <RowCounter>
          <SelectMenu
            label=""
            value={countRows || 30}
            list={!!countRowsList ? countRowsList : localMenu}
            handleSelect={changeRowCount}
            width="70px"
          />
        </RowCounter>
      </div>
      {
        showPaginatedInfo &&
        <PaginationInfo
          pageSize={countRows}
          currentPage={currentPage}
          totalCount={pageTotal}
        />
      }

    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    columnGap: 32,
    alignItems: "center",
    "& > *": {
      marginTop: theme.spacing(2),
    },
    "& .MuiPaginationItem-page": {
      border: "none",
      color: COLORS.NUMBER_BLUE,
      fontFamily: "inherit",
      fontSize: 14,
    },
    "& li:first-child button, & li:last-child button": {
      backgroundColor: COLORS.PRIMARY_WHITE,
    },
    "& .MuiPaginationItem-page.Mui-selected": {
      background: "linear-gradient(111deg, #fa1565 22%, #7314ad 155%)",
      border: "none",
      color: COLORS.PRIMARY_WHITE,
    },
    "& > div": {
      display: "flex",
    },
    "& > .pagination-info": {
      marginTop: 0,
      marginBottom: 8
    }
  },
}));

const RowCounter = styled.div`
  margin-left: 14px;
  width: 60px;
  .MuiInputBase-formControl,
  .MuiFormControl-root {
    margin-top: 0;
    padding-top: 0;
  }
  .MuiSelect-select.MuiSelect-select {
    padding: 9px 26px 9px 12px;
  }
`;
