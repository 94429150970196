import React from "react";
import styled from "styled-components";

export default function IconChip({ icon, value, url }) {
  return (
    <IconChipStyled onClick={() => window.open(url, "_blank")}>
      {icon} {value}
    </IconChipStyled>
  );
}

const IconChipStyled = styled.div`
  background-color: #ebedf8;
  border-radius: 18px;
  color: #4050b5;
  cursor: pointer;
  align-items: center;
  display: inline-flex;
  font-weight: 600;
  padding: 6px 15px 6px 12px;
  margin-bottom: 20px;
  img {
    margin-right: 5px;
  }
`;
