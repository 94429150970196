export const Skill_Update_Proficiencies_Table = [
  {
    colKey: "levelId",
    colValue: "ID",
    dynamic: false,
    width: 60,
  },
  {
    colKey: "level",
    colValue: "Proficiency Level",
    select: true,
    dynamic: true,
    width: 150,
  },
  {
    colType: "aiDescription",
    colKey: "description",
    colValue: "Description",
    dynamic: true,
    width: 260,
    includeGenAiButton: true
  },
  { 
    colKey: "ability", 
    colValue: "Abilities",
    dynamic: true, 
    width: 200 
  },
  { 
    colType: "aiDescription",
    colKey: "knowledge", 
    colValue: "Knowledge", 
    dynamic: true, 
    width: 260, 
    includeGenAiButton: true 
  },
  {
    colKey: "buttonEdit",
    saveBtn: false,
    colValue: "",
    dynamic: false,
    width: 30,
  },
  {
    colKey: "buttonDelete",
    colValue: "",
    dynamic: false,
    width: 30,
  },
];
