import React, { createContext, useState, useMemo, useEffect } from "react";
import { Common } from "../store";

export const PersonaRoleContext = createContext();

export default function PersonaRolesProvider({ children }) {
  const [subsector, setSubsector] = useState("Any");
  const [funcval, setFuncval] = useState("Any");
  const [industryRole, setIndustryRole] = useState("Any");
  const [laederRole, setLaederRole] = useState("Any");
  const [clientName, setClientName] = useState(null);
  const [clientCount, setClientCount] = useState(0);
  const [clientList, setClientList] = useState([]);

  useEffect(() => {
    const clientNameFromCookies = Common.getClientName();
    console.log(
      "PersonaRolesProvider -> clientNameFromCookies",
      clientNameFromCookies
    );
    // console.log(
    //   "PersonaRolesProvider -> clientNameFromCookies",
    //   clientNameFromCookies
    // );
    const clientListFromCookies = Common.getClientList();
    if (clientNameFromCookies) {
      setClientName(Number(clientNameFromCookies));
      setClientList(clientListFromCookies);
    } else {
      // setClientName("Angel Broking");
      setClientList([]);
    }
  }, []);

  const value = useMemo(() => {
    return {
      subsector,
      setSubsector,
      funcval,
      setFuncval,
      industryRole,
      setIndustryRole,
      laederRole,
      setLaederRole,
      clientName,
      setClientName,
      clientList,
      setClientList,
      clientCount,
      setClientCount,
    };
  }, [
    subsector,
    setSubsector,
    funcval,
    setFuncval,
    industryRole,
    setIndustryRole,
    laederRole,
    setLaederRole,
    clientName,
    setClientName,
    clientList,
    setClientList,
    clientCount,
    setClientCount,
  ]);
  return (
    <PersonaRoleContext.Provider value={value}>
      {children}
    </PersonaRoleContext.Provider>
  );
}
