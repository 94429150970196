import React, { useState, useLayoutEffect, useRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { H4 } from "./Typography";
import COLORS from "../../assets/colors";

export default ({
  list,
  initOpen = 0,
  showCollaps = false,
  selectedItems = [],
}) => {
  const heightRef = useRef();
  const [expanded, setExpanded] = useState(initOpen);

  const handleChange = (panel) => (event, newExpanded) =>
    setExpanded(newExpanded ? panel : false);

  useLayoutEffect(() => {
    Object.keys(list).forEach((item, i) => {
      const minHeight =
        document.querySelector(`#accordian-custom${i} .showCollaps`)
          ?.scrollHeight + "px";
      // console.log("minHeight", minHeight); .MuiCollapse-container//MuiCollapse-root
      let styles = `
      min-height:${!!minHeight ? minHeight : 0} !important;
      visibility: ${!!minHeight ? "visible" : "hidden"}`;

      return (document.querySelector(
        `#accordian-custom${i} .MuiCollapse-root`
      ).style = styles);
    });
  }, [expanded, list, showCollaps]);

  // console.log(selectedItems);
  return (
    <>
      {Object.keys(list).map((item, i) => (
        <>
          <Accordion
            square
            expanded={expanded === i}
            onChange={handleChange(i)}
            minHeightShow={true}
            minheight={
              document.querySelector(`#accordian-custom${i} .showCollaps`)
                ?.offsetHeight
            }
            id={"accordian-custom" + i}
          >
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon />}
              aria-controls={`panel${i}d-content`}
              id={`panel${i}d-header`}
            >
              <H4>{item}</H4>
            </AccordionSummary>
            <AccordionDetails data-test={i} ref={heightRef}>
              {list[item]}
            </AccordionDetails>
          </Accordion>
        </>
      ))}
    </>
  );
};

const Accordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    borderRadius: 12,
    boxShadow: "0 2px 5px 0 rgba(95, 130, 188, 0.26)",
    marginBottom: "15px !important",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      height: 0,
      //   display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
    "& > .MuiCollapse-container.MuiCollapse-hidden": {
      visibility: (props) => {
        return props.minHeightShow && !!props.minheight ? "visible" : "hidden";
      },
      "& .MuiTableContainer-root": {
        opacity: 0,
      },
    },
  },
  expanded: {},
})((props) => <MuiAccordion {...props} />);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "transparent",
    // borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    "&$expanded": {
      minHeight: 56,
      backgroundColor: COLORS.BODY_BLUE,

      "& h4": {
        color: COLORS.PRIMARY_WHITE,
      },
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
    "& h4": {
      paddingBottom: 0,
    },
  },
  expandIcon: {
    border: `solid 1px ${COLORS.ASH_BORDER}`,
    borderRadius: 8,
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.16)",
    height: 24,
    marginRight: 0,
    width: 38,
    "&.Mui-expanded": {
      boxShadow: "none",
      // opacity: 0.4,
      borderColor: COLORS.PRIMARY_WHITE,
      "& .MuiSvgIcon-root": {
        fill: COLORS.PRIMARY_WHITE,
      },
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    flexDirection: "column",
    padding: 15,
  },
}))((props) => <MuiAccordionDetails {...props} />);
