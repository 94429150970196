import React, { useState, useEffect } from "react";
import { navigate } from "@reach/router";
import styled from "styled-components";
import Layout from "../Layout";
import { H2 } from "../Common/Typography";
import { Container } from "../Common/GridSystem";
import COLORS from "../../assets/colors";
import JobCount from "./JobCount";
import CustomizedTables from "../Common/CustomizedTables";
import { AuthService } from "../../services/AuthService";
import { Table_Colums } from "./JobTableElements";
import PaginationCustom from "../Common/PaginationCustom";
import useFullLoader from "../../Hooks/useFullLoader";
import BackNav from "../Common/BackNav";

export default () => {
  const [pageOffset, setPageOffset] = useState(0);
  const [rowCount, setrowCount] = useState(10);
  const [jobsCount, setJobsCount] = useState(0);
  const [jobTableList, setjobTableList] = useState(null);
  const [sectorValue, setSectorValue] = useState("All");
  const [sourceValue, setSourceValue] = useState("All");
  const { setFullLoader } = useFullLoader();

  const handleSector = (val) => setSectorValue(val);
  const handleSource = (val) => setSourceValue(val);

  const handlePagination = (e, page) => setPageOffset((page - 1) * rowCount);

  useEffect(() => {
    const initData = {
      sectorList: sectorValue !== "All" ? [sectorValue] : [],
      sourceList: sourceValue !== "All" ? [sourceValue] : [],
    };

    const getJobData = async () => {
      setFullLoader(true);
      try {
        const resCount = await AuthService.getJobCount(initData);
        const resList = await AuthService.getJobList(
          initData,
          `?pagesize=${rowCount}`,
          `&offset=${pageOffset}`
        );
        console.log(resCount);
        setJobsCount(resCount);
        setjobTableList(resList);
      } catch (error) {
        console.log(error);
      } finally {
        setFullLoader(false);
      }
    };

    getJobData();
  }, [pageOffset, sectorValue, sourceValue, rowCount]);

  const handleRow = (row, idx) => {
    const rowNumber = pageOffset + idx;
    navigate(`job/${row.id}/${jobsCount.AllJobsCount}/${rowNumber}`);
  };
  const changeRowCount = (count) => setrowCount(count);

  return (
    <div>
      <Layout>
        <H2>Annotate Jobs</H2>
        <DetailContent>
          <Container>
            <BackNav linkNav="/" />
            <JobCount
              count={jobsCount}
              sectorValue={sectorValue}
              sourceValue={sourceValue}
              handleSector={handleSector}
              handleSource={handleSource}
            />
            <CustomizedTables
              columns={Table_Colums}
              rowList={jobTableList}
              rowClick={handleRow}
              countRows={rowCount}
            />
            <PaginationCustom
              pageTotal={jobsCount?.AllJobsCount}
              handlePagination={handlePagination}
              countRows={rowCount}
              changeRowCount={changeRowCount}
            />
          </Container>
        </DetailContent>
      </Layout>
    </div>
  );
};

const DetailContent = styled.div`
  background-color: ${COLORS.BODY_ASH};
  border-top-left-radius: 30px;
  min-height: calc(100vh - 65px);
  padding: 25px 20px;
  p {
    font-size: 12px;
    line-height: 22px;
    letter-spacing: -0.06px;
  }
`;
