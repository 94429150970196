import React from "react";
import styled from "styled-components";
import COLORS from "../../assets/colors";

const Header1 = styled.h1`
  color: ${COLORS.TITLE_BLUE};
  font-weight: 700;
  font-size: 34px;
  line-height: 44px;
  padding-bottom: 15px;
`;

const Header2 = styled.h2`
  color: ${COLORS.PRIMARY_WHITE};
  font-weight: 600;
  font-size: 24px;
  line-height: 38px;
  padding-bottom: 15px;
`;

// need to custom
const Header3 = styled.h3`
  color: ${COLORS.PRIMARY_WHITE};
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  padding-bottom: 15px;
`;

const Header4 = styled.h4`
  color: ${COLORS.TITLE_BLUE};
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  padding-bottom: 15px;
`;

const Header5 = styled.h5`
  color: ${COLORS.TITLE_BLUE};
  font-family: inherit;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  padding-bottom: 15px;
`;

const Header6 = styled.h6`
  color: ${COLORS.TITLE_BLUE};
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  padding-bottom: 15px;
`;

const Text = styled.p`
  color: ${COLORS.MIDNIGHT_EXPRESS};
  font-family: inherit;
  font-weight: normal;
  font-size: 16px;
  line-height: 32px;
  padding-bottom: 18px;
  margin: 0;
`;

const H1 = ({ children, ...props }) => <Header1 {...props}>{children}</Header1>;
const H2 = ({ children, ...props }) => <Header2 {...props}>{children}</Header2>;
const H3 = ({ children, ...props }) => <Header3 {...props}>{children}</Header3>;
const H4 = ({ children, ...props }) => <Header4 {...props}>{children}</Header4>;
const H5 = ({ children, ...props }) => <Header5 {...props}>{children}</Header5>;
const H6 = ({ children, ...props }) => <Header6 {...props}>{children}</Header6>;
const Paragraph = ({ children, ...props }) => (
  <Text {...props}>{children}</Text>
);

export { H1, H2, H3, H4, H5, H6, Paragraph };
