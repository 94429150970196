import React from "react";
import PropTypes from "prop-types";
import ModalPopup from "../ModalPopup";
import FileUploader from "../FileUploader/FileUploader";
import ConfirmationButton from "../ConfirmationButton";
import { FileFormats } from "../../../enums/FileFormats";
import useDragAndDropFileModal from "./useDragAndDropFileModal";

const DragAndDropFileModal = ({
  open,
  title,
  closePopup,
  maxWidth,
  noText,
  yesText,
  yesClick,
  isInvalidTemplate,
  allowedFileTypes,
  multiSelect,
  error,
  setError
}) => {
  const { selectedFiles } = useDragAndDropFileModal();

  return (
    <ModalPopup
      open={open}
      title={title}
      center={true}
      closePopup={closePopup}
      maxWidth={maxWidth}
      actions={
        <ConfirmationButton
          noText={noText}
          yesText={yesText}
          yesClick={() => {yesClick(selectedFiles)}}
          showNoButton={!!noText}
          disableYesClick={!selectedFiles.length}
        />
      }
    >
      <FileUploader
        allowedFileTypes={allowedFileTypes}
        multiSelect={multiSelect}
        isInvalidTemplate={isInvalidTemplate}
        error={error}
        setError={setError}
      />
    </ModalPopup>
  );
};

DragAndDropFileModal.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  closePopup: PropTypes.func,
  maxWidth: PropTypes.string,
  noText: PropTypes.string,
  yesText: PropTypes.string,
  yesClick: PropTypes.func,
  isInvalidTemplate: PropTypes.bool,
  allowedFileTypes: PropTypes.arrayOf(PropTypes.string),
  multiSelect: PropTypes.bool,
  error: PropTypes.string,
  setError: PropTypes.func,
};

DragAndDropFileModal.defaultProps = {
  open: false,
  title: "",
  closePopup: () => {},
  maxWidth: "md",
  noText: "",
  yesText: "Continue",
  yesClick: () => {},
  isInvalidTemplatecustomErrorMessage: false,
  allowedFileTypes: [FileFormats.PNG, FileFormats.MP4],
  multiSelect: true,
  error: "",
  setError: () => {},
};

export default DragAndDropFileModal;
