import React from "react";
import PropTypes from "prop-types";
import { Box, LinearProgress, Typography } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import "./ProgressBar.scss";
import text from "../../../locale.en.json";
import { Images } from "../../../assets/images";

const ProgressBar = ({ download, cancel, status }) => {
  const progress = (status.completedItems / status.totalItems) * 100;
  const isDataFetched = (Math.round(progress) === 100) && !!status?.showDownloadData;

  return (
    <Box className="progress-bar-container">
      <Box>
        <Box className="progress-info">
          <Box className="progress-text">
            {isDataFetched ? (
              <>
                <img src={Images.icons.checkIcon} className="check-icon" alt="Check Icon" />
                {text.fileSelection.dataFetchCompleted}
              </>
            ) : (
              `${text.common.fetching} ${status.completedItems}/${status.totalItems} ${status.entity}`
            )}
          </Box>
          <Typography
            variant="body2"
            color="text.secondary"
            className={isDataFetched ? "show-cross-icon" : "progress-percentage"}
          >
            {isDataFetched ? (
              <ClearIcon className="clear-icon" onClick={cancel} />
            ) : (
              `${Math.round(progress)}%`
            )}
          </Typography>
        </Box>
        <Box className={isDataFetched ? "download-data" : "progress-bar"}>
          {isDataFetched ? (
            <Box onClick={download}>
              {text.fileSelection.downloadData}
              <img
                src={Images.icons.downloadFileIcon}
                className="download-icon"
                alt="Download Icon"
              />
            </Box>
          ) : (
            <LinearProgress variant="determinate" value={progress} />
          )}
        </Box>
      </Box>
    </Box>
  );
}

ProgressBar.propTypes = {
  download: PropTypes.func,
  cancel: PropTypes.func,
  status: PropTypes.shape({
    completedItems: PropTypes.number,
    totalItems: PropTypes.number,
    entity: PropTypes.string
  })
};

ProgressBar.defaultProps = {
  download: () => {},
  cancel: () => {},
  status: {
    completedItems: 0,
    totalItems: 0,
    entity: ""
  }
};

export default ProgressBar;
