import React from "react";
import { withStyles, Button } from "@material-ui/core";
import COLORS from "../../../assets/colors";

export const secondaryButtonStyles = {
  root: {
    background: "transparent",
    border: `solid 1px ${COLORS.PRIMARY_PURPLE}`,
    color: COLORS.LABEL_BLUE,
    fontFamily: "inherit",
    fontWeight: 600,
    fontSize: 12,
    textTransform: "none",
    borderRadius: 4,
    minHeight: 38,
    width: "auto",
    padding: "8px 22px",
    maxwidth: "auto",
    "&:hover": {
      backgroundColor: "transparent",
      boxShadow: "none",
      borderColor: COLORS.PRIMARY_PURPLE,
      opacity: "0.9",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "transparent",
      borderColor: COLORS.PRIMARY_PURPLE,
    },
    "&:focus": {
      boxShadow: "none",
      borderColor: COLORS.PRIMARY_PURPLE,
    },
    "&:disabled": {
      background: "#abafb5",
      borderColor: "transparent",
      color: COLORS.PRIMARY_WHITE,
    },
  },
  label: {
    lineHeight: "inherit",
  },
};

export const SecondaryButton = withStyles(secondaryButtonStyles)((props) => <Button {...props} />);
