import React, { useState, useEffect } from "react";
import { Add, Delete, Save, Cancel } from "@material-ui/icons";
import SortableTree, {
  addNodeUnderParent,
  removeNodeAtPath,
  changeNodeAtPath,
  getFlatDataFromTree,
  getNodeAtPath,
} from "react-sortable-tree";
import { useDebouncedCallback } from "use-debounce";

import "react-sortable-tree/style.css";
import useAlert from "../../../Hooks/useAlert";

import ButtonIcon from "../../Common/Buttons/ButtonIcon";
import AutoSelectSearch from "../../Common/FormElements/AutoSelectSearch";
import { SkillService } from "../../../services/SkillService";
import useFullLoader from "../../../Hooks/useFullLoader";

const expandedAddRecursion = (obj, field, children, parent) => {
  if (obj.hasOwnProperty(field) === false) {
    return {
      ...obj,
      [field]: true,
      exists: true,
      parentID: parent?.skillId || 0,
      [children]: obj[children]
        .filter((el) => !!el.skillName)
        .map((el) => expandedAddRecursion(el, field, children, obj)),
    };
  }
};

const CreateSkillTree = ({ data, getTreeData }) => {
  // console.log("CreateSkillTree -> data", data);
  const dummyState = [
    {
      skillName: data?.skillName || "Master Skill",
      skillId: data?.skillId,
      expanded: true,
      exists: true,
      children: [],
    },
  ];

  const { setFullLoader } = useFullLoader();
  const { showAlert } = useAlert();

  const [state, setState] = useState(null);
  const [skillLoading, setSkillLoading] = useState(false);
  const [editingSkill, setEditingSkill] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [currentData, setCurrentData] = useState(null);
  const [flatData, setFlatData] = useState(null);
  const getNodeKey = ({ treeIndex }) => treeIndex;
  //   console.log(state);

  useEffect(() => {
    getDetailData();
  }, []);

  useEffect(() => {
    getTreeData(state);
  }, [state]);

  const getDetailData = async () => {
    setFullLoader(true);
    try {
      const res = await SkillService.GetSkillTreeV3(
        "?rootSkillId=" + data?.skillId
      );

      const dataGet = await expandedAddRecursion(
        res.masterSkill,
        "expanded",
        "children"
      );
      console.log("getDetailData -> dataGet", dataGet);
      setState(dataGet.skillId === 0 ? dummyState : [dataGet]);
      setFullLoader(false);
    } catch (errors) {
      console.log(errors);
    } finally {
      setFullLoader(false);
    }
  };

  const triggerBounce = useDebouncedCallback((postData) => {
    // console.log("triggerBounce -> triggerBounce");
    // if (postData.searchString?.length > 2)
    return SkillService.SearchSkillV3(postData);
  }, 500);

  const triggerApi = async (searchString, reason, node, path, rest) => {
    // console.log("triggerApi -> searchString", searchString);
    // console.log("triggerApi -> rest", rest);
    const postData = {
      searchString,
      searchInDescription: false,
      searchAcrossFields: false,
      skillCateogryId: [data?.skillCategory?.skillCategoryId],
      skillFamilyId: [data?.skillFamily?.skillFamilyId],
    };
    const preObject = { treeData: state, path, getNodeKey };

    if (reason === "input") {
      // console.log(searchString);
      setState(
        changeNodeAtPath({
          ...preObject,
          newNode: { ...node, skillName: searchString, skillId: 0 },
        })
      );
    }
    setSkillLoading(true);
    try {
      // if (searchString.length > 3)
      // const res = await triggerBounce(postData);
      // setSkillLoading(false);
      // return res;
      if (searchString?.length % 2 === 0) {
        const res = await SkillService.SearchSkillV3(postData);
        return res?.skills || [];
      }

      // return await SkillService.searchSkill(postData);
    } catch (errors) {
      console.log(errors);
    }
  };

  const handleChange = (item, reason, node, path) => {
    console.log("handleChange -> item, reason", item, reason, data);
    const preObject = { treeData: state, path, getNodeKey };
    // console.log(item.skillId, data.skillId, item.skillId !== data.skillId);
    if (reason === "select-option") {
      if (item.skillId !== data.skillId) {
        setState(
          changeNodeAtPath({
            ...preObject,
            newNode: {
              ...node,
              skillName: item.skillName,
              skillId: item.skillId,
            },
          })
        );
        setCurrentData({
          ...node,
          skillName: item.skillName,
          skillId: item.skillId,
        });
      } else {
        // alert("Master skill");
        showAlert("Skill Exists", "info", 1500);
      }
      //   setSkillName(item.skillName);
    } else if (reason === "clear") {
      setState(
        changeNodeAtPath({
          ...preObject,
          newNode: { ...node, skillName: "", skillItem: "" },
        })
      );
    }
  };

  const CancelSkill = (node, path) => {
    const preObject = { treeData: state, path, getNodeKey };

    setState(
      changeNodeAtPath({
        ...preObject,
        newNode: {
          ...node,
          skillName: currentData.skillName,
          skillId: currentData.skillId,
        },
      })
    );

    setCurrentItem(null);
    setCurrentData(null);
  };

  const getParentSkillIds = (arr, item) => {
    // console.log("getParentSkillIds -> item", item);
    // const item_parent_ID = item.node.parentID;
    const nextParentNode_ID = item?.nextParent?.skillId || item?.parent;
    // const nextParentNode_ID = item?.nextParent?.skillId;

    let idArray = [nextParentNode_ID];
    if (arr.some((el) => el.skillId === nextParentNode_ID)) {
      // has next parent in array
      // find node next parent in array
      const findParentNode = arr.find(
        (el) => el?.skillId === nextParentNode_ID
      );
      // next parent id push in idArray
      idArray.push(findParentNode?.parent);
      getParentSkillIds(arr, findParentNode);
    }
    // console.log(arr, item, idArray);
    return idArray;
  };

  const saveSkillData = async (node, rest, path) => {
    const { parentNode } = rest;

    const postData = {
      skillId: 0,
      skillName: node.skillName,
      parentSkillId: parentNode.skillId,
      skillCategoryId: data?.skillCategory?.skillCategoryId,
      skillFamilyId: data?.skillFamily?.skillFamilyId,
      sourceId: data?.skillSource?.sourceId,
      skillStatus: data?.skillStatus,
    };

    const saveItem = { ...node, parent: parentNode.skillId };
    let copySkill = null;
    const checkAllParents = (item) => {
      const currentParent = flatData.find((el) => el.skillId === item?.parent);
      if (!!currentParent?.parent) {
        if (currentParent?.skillId !== saveItem?.skillId) {
          checkAllParents(currentParent);
        } else {
          showAlert("Skill Exists", "info", 1500);
          copySkill = item;
        }
      }
    };
    await checkAllParents(saveItem);

    // return false;
    if (!copySkill) {
      const prevData = flatData.find(
        (el) => el.skillId === currentData?.skillId
      );
      console.log("saveSkillData -> prevData", flatData, prevData, currentData);

      const addSkill = async () => {
        setFullLoader(true);
        if (node?.skillId) {
          // exist skill add
          try {
            await SkillService.CreateSkillTreeV3(
              "/" + parentNode.skillId,
              "/relationship/",
              node?.skillId
            );
          } catch (err) {
            console.log(err);
          } finally {
            setFullLoader(false);
          }
        } else {
          //custom skill add
          try {
            const res = await SkillService.CreateSkillTreeWithoutIDV3(postData);
            const preObject = { treeData: state, path, getNodeKey };

            setState(
              changeNodeAtPath({
                ...preObject,
                newNode: {
                  ...node,
                  skillName: res?.skillName || "",
                  skillId: res?.skillId || 0,
                },
              })
            );
          } catch (err) {
            console.log(err);
          } finally {
            setFullLoader(false);
          }
        }
      };

      try {
        if (!node.exists) {
          addSkill();
        } else {
          await SkillService.DeleteSkillTreeV3(
            prevData.parent,
            "/relationship/",
            prevData?.skillId
          );
          addSkill();
        }
        setCurrentItem(null);
        setCurrentData(null);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const deleteSkillData = async (node, rest) => {
    setCurrentItem(null);
    const { parentNode } = rest;

    if (!!node?.skillId) {
      setFullLoader(true);
      try {
        await SkillService.DeleteSkillTreeV3(
          parentNode.skillId,
          "/relationship/",
          node?.skillId
        );
      } catch (err) {
        console.log(err);
      } finally {
        setFullLoader(false);
      }
    }
  };

  const makeFlatData = getFlatDataFromTree({
    treeData: state,
    getNodeKey: ({ node }) => node.skillId, // This ensures your "id" properties are exported in the path
    ignoreCollapsed: false, // Makes sure you traverse every node in the tree, not just the visible ones
  }).map(({ node, path, getNodeKey }) => ({
    skillId: node.skillId,
    skillName: node.skillName,
    expanded: node.expanded,
    exists: node.exists,
    treeIndex: getNodeKey,
    // The last entry in the path is this node's key
    // The second to last entry (accessed here) is the parent node's key
    parent: path.length > 1 ? path[path.length - 2] : null,

    children: `${getNodeAtPath({
      treeData: state,
      path: [1],
      getNodeKey,
    })}`,
  }));

  const onMoveNode = async (moveData) => {
    // console.log("onMoveNode -> moveData", moveData);
    // return false;
    const { nextParentNode, node, path, prevPath } = moveData;

    if (moveData?.node?.parentID !== moveData?.nextParentNode?.skillId) {
      const prevData = flatData.find((el) => el.skillId === node?.skillId);
      const preObject = { treeData: state, path: path, getNodeKey };

      const saveItem = { ...node, parent: nextParentNode.skillId };
      let copySkill = null;
      // const checkAllParents = (item) => {
      //   const currentParent = flatData.find(
      //     (el) => el.skillId === item?.parent
      //   );
      //   if (!!currentParent?.parent) {
      //     if (currentParent?.skillId !== saveItem?.skillId) {
      //       checkAllParents(currentParent);
      //     } else {
      //       showAlert("Skill Exists", "info", 1500);
      //       copySkill = item;
      //     }
      //   }
      // };
      // await checkAllParents(saveItem);

      if (!copySkill) {
        console.log(node, prevData, flatData);
        if (nextParentNode.skillId !== prevData.parent) {
          setFullLoader(true);
          try {
            await SkillService.DeleteSkillTreeV3(
              node.parentID || prevData.parent,
              "/relationship/",
              node?.skillId
            );
            await SkillService.CreateSkillTreeV3(
              "/" + nextParentNode.skillId,
              "/relationship/",
              node?.skillId
            );
          } catch (err) {
            console.log(err);
          } finally {
            setFullLoader(false);
          }
        }
      }
    }
  };

  const handleFocus = (node, path, rest) => {
    // console.log(!currentItem, !currentData);
    // if (!currentItem && !currentData) {
    setCurrentItem(rest);
    setCurrentData(node);
    if (!flatData?.length) setFlatData(makeFlatData);
    // }
  };
  // console.log(state, flatData);
  // console.log(data?.skillName);
  return (
    <div style={{ height: "450px" }}>
      {!!state && (
        <SortableTree
          treeData={state}
          onChange={(tree) => {
            // console.log(tree);
            setState(tree);
          }}
          canDrag={(tree) => (tree.treeIndex === 0 ? false : true)}
          canDrop={(tree) => {
            // console.log(tree);
            // getParentSkillIds(makeFlatData, tree);
            let parentSkillIdMap = {};
            const { nextParent, nextTreeIndex, prevTreeIndex } = tree;

            if (parentSkillIdMap[nextTreeIndex] !== undefined) {
              return parentSkillIdMap[nextTreeIndex];
            }
            parentSkillIdMap[nextTreeIndex] = getParentSkillIds(
              makeFlatData,
              tree
            );

            // console.log(
            //   parentSkillIdMap[nextTreeIndex],
            //   nextTreeIndex,
            //   prevTreeIndex
            // );

            // return parentSkillIdMap[nextTreeIndex].includes(
            //   tree?.node?.parentID && tree?.nextTreeIndex !== 0
            // );

            return (
              tree?.nextTreeIndex !== 0 &&
              tree?.node?.skillId !== nextParent?.skillId
            );
          }}
          onMoveNode={(node) => onMoveNode(node)}
          onDragStateChanged={(node) => {
            if (node.isDragging) {
              setFlatData(makeFlatData);
              // setCurrentData(node);
              // getParentSkillIds(makeFlatData, node?.draggedNode);
              // console.log("CreateSkillTree -> node", node);
            }
          }}
          isVirtualized={true}
          generateNodeProps={({ node, path, ...rest }) => ({
            title:
              node?.skillId === data?.skillId ? (
                node?.skillName
              ) : (
                <>
                  {/* {console.log(node, path, rest)} */}
                  {!!node && (
                    <AutoSelectSearch
                      freeSolo
                      width={250}
                      optionChange={(item, reason) => {
                        // console.log(item);
                        return !!item
                          ? handleChange(item, reason, node, path)
                          : undefined;
                      }}
                      onFocus={() => handleFocus(node, path, rest)}
                      // onBlur={() => setCurrentItem(null)}
                      optionLabel="skillName"
                      stateValue={node?.skillName}
                      triggerApi={(val, reason) =>
                        triggerApi(val, reason, node, path, rest)
                      }
                      // loading={skillLoading}
                      handleClearSearch={() =>
                        handleChange("", "clear", node, path, rest)
                      }
                      renderOption={(option) => option?.skillName}
                    />
                  )}
                </>
              ),

            buttons: [
              <>
                {node?.skillId !== data?.skillId &&
                currentItem?.treeIndex === rest.treeIndex ? (
                  <ButtonIcon
                    color="primary"
                    icon={<Save />}
                    onClick={() => saveSkillData(node, rest, path)}
                  />
                ) : null}
              </>,
              <>
                {node?.skillId !== data?.skillId &&
                currentItem?.treeIndex === rest.treeIndex ? (
                  <ButtonIcon
                    color="primary"
                    icon={<Cancel />}
                    onClick={() => CancelSkill(node, path)}
                  />
                ) : null}
              </>,
              <>
                {currentItem?.treeIndex !== rest.treeIndex && (
                  <ButtonIcon
                    color="primary"
                    icon={<Add />}
                    onClick={() =>
                      setState(
                        addNodeUnderParent({
                          treeData: state,
                          parentKey: path[path.length - 1],
                          expandParent: true,
                          getNodeKey: ({ treeIndex }) => treeIndex,
                          newNode: {
                            skillName: "",
                          },
                        }).treeData
                      )
                    }
                  />
                )}
              </>,
              <>
                {rest.treeIndex > 0 &&
                currentItem?.treeIndex !== rest.treeIndex ? (
                  <ButtonIcon
                    color="black"
                    icon={<Delete />}
                    onClick={() => {
                      deleteSkillData(node, rest);
                      setState(
                        removeNodeAtPath({
                          treeData: state,
                          path: path,
                          getNodeKey: ({ treeIndex }) => treeIndex,
                          ignoreCollapsed: false,
                        })
                      );
                    }}
                  />
                ) : null}
              </>,
            ],
          })}
          // theme={}
        />
      )}
    </div>
  );
};

export default CreateSkillTree;
