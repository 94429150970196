import { useContext } from "react";
import { MasterSubSectorListContext } from "../Provider/SubSectorListProvider";

export default () => {
  const context = useContext(MasterSubSectorListContext);
  if (context === undefined) {
    throw new Error("use list must be used within a Master list provider");
  }

  return context;
};
