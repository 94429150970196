import React from 'react';
import { H4 } from '../../../Common/Typography';
import Each from '../../../Common/Each';
import ModalPopup from '../../../Common/ModalPopup';
import usePersonaCreationChoiceModal from './usePersonaCreationChoiceModal';
import "./PersonaCreationChoiceModal.scss";
import ToolBar from '../../../Common/ToolBar';
import text from "../../../../locale.en.json"
import { ToolBarPlacement } from '../../../../enums/ToolBarPlacement';

function PersonaCreationChoiceModal({ open, close, actionHandlers, restrictBulkPersonaCreation = false }) {
    const { personaCards } = usePersonaCreationChoiceModal({restrictBulkPersonaCreation});

    const CreatePersonaCard = ({ imageSrc, message, action, disableCard }) => {
        return (
          <ToolBar title={disableCard ? text.persona.bulkPersonaCreationInProgress : ""} placement={ToolBarPlacement.TOP} arrow darkMode>
            <div className={`create-persona-option ${disableCard && "disable-card" }`} onClick={() => !disableCard && actionHandlers[action]()}>
                <img src={imageSrc} />
                <H4>{message}</H4>
            </div>
          </ToolBar>
        );
    };

    const CreatePersonaContainer = () => {
      return (
        <div className="create-persona-container">
          <Each 
            items = {personaCards}
            render={(item, index) => (
                <CreatePersonaCard key={index} imageSrc={item.imageSrc} message={item.message} action={item.action} disableCard={item.disableCard} />
            )}
          />
        </div>
      );
    };
        

    return (
        <ModalPopup
            open={open}
            maxWidth="sm"
            title={'Create Persona'}
            center={true}
            closePopup={close}
        >
            <CreatePersonaContainer />
        </ModalPopup>
    );
}

export default PersonaCreationChoiceModal;