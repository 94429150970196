import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: (props) =>
      props.darkMode ? "rgba(0, 0, 0, 0.75)" : theme.palette.common.white,
    color: (props) =>
      props.darkMode ? "rgba(255, 255, 255, 1)" : "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
    padding: 5,
  },
  arrow: {
    color: (props) =>
      props.darkMode ? "rgba(0, 0, 0, 0.75)" : theme.palette.common.white,
  },
}))(Tooltip);

export default ({ title, children, arrow = false, darkMode = false , placement = "bottom" }) => {
  return (
    <LightTooltip title={title} arrow={arrow} darkMode={darkMode} placement={placement}>
      {children}
    </LightTooltip>
  );
};
