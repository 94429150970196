import React, { useState, useEffect } from "react";
import "./menu.scss";
import { Images } from "./../../assets/images";
import SideDrawer from "./SideDrawer";
import { IconMenu } from "./IconMenu";
import { User, Common } from "../../store";
import useAuth from "../../Hooks/useAuth";
import useAlert from "../../Hooks/useAlert";
import { navigate } from "@reach/router";
import { makeStyles } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import COLORS from "../../assets/colors";
import ClientModal from "../Common/Compos/ClientModal";
import useRoles from "../../Hooks/useRoles";
import { UIStore, clearStore } from "../../store/store_state";
import ModalPopup from "../Common/ModalPopup";
import ConfirmationButton from "../Common/ConfirmationButton";
import { SortString } from "../../helpers/SortString";
import { AuthService } from "../../services/AuthService";
import { UserType } from "../../enums/UserType";

export default () => {
  const classes = useStyles();

  const { setIsUserLoggedIn, userDetails } = useAuth();
  // !userDetails && console.log("userDetails", userDetails);
  const { User_Type } = userDetails;

  const { showAlert } = useAlert();
  const { clientList, clientName, setClientName } = useRoles();

  const personaUpdate = UIStore.useState((s) => s.personaUpdate);

  const [confirmPopup, setConfirmPopup] = useState(false);
  const [confirmTitle, setConfirmTitle] = useState("");
  const [currentLink, setCurrentLink] = useState("");
  const [contentList, setContentList] = useState([]);

  const [open, setOpen] = useState(false);
  const [menuActive, setMenuActive] = useState(false);
  const [clientPopup, setClientPopup] = useState(false);

  // User_Type === 1 is general user, User_Type === 2 is Super user
  // User_Type === 3 is SME user, User_Type === 4 is Skilling Architect

  useEffect(() => {
    const filterContent = IconMenu.filter((el) =>
      el.accessUserTypes.includes(User_Type)
    );
    setContentList(filterContent);
  }, [User_Type]);

  const toggleDrawer = () => setOpen(false);

  useEffect(() => {
    if (!open) {
      setTimeout(() => setMenuActive(false), 200);
    } else {
      setMenuActive(true);
    }
  }, [open]);

  const handleLogout = () => {
    clearStore();
    User.clearAll();
    Common.clearAll();
    setIsUserLoggedIn(false);
    setClientName(null);
    showAlert("Logout Successful", "success", 1500);
    navigate("/");
  };

  const handleArchRole = (link) => checkClientList(link);

  const handleComparePersona = (link) => checkClientList(link);

  const checkClientList = (link) => {
    setCurrentLink(link);
    // console.log(link);
    if (
      (!!clientList &&
        typeof clientList === "object" &&
        clientList.length === 1) ||
      (!!clientList &&
        typeof clientList === "string" &&
        JSON.parse(clientList).length === 1)
    ) {
      getAllPersonas(link);
    } else {
      setClientPopup(true);
    }
  };

  const getAllPersonas = async (link) => {
    try {
      const resList = await AuthService.getPersonasListV3(
        `?clientId=${clientName}`
      );
      if (resList.length > 0) {
        await navigate(
          link === "/architect_skills"
            ? "/persona/architect-skills/" +
                SortString(
                  resList.map((el) => ({
                    ...el,
                    personaGroupName: el.personaGroup.personaGroupName,
                  })),
                  "personaGroupName"
                )[0].personaId
            : link
        );
        // window.location.reload();
      } else {
        showAlert(
          "No Personas found! Kindly define a persona first",
          "info",
          2500
        );

        window.location.pathname !== "/" && navigate("/personas");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCheckNavigate = (link) => {
    setConfirmPopup(true);
    setConfirmTitle("Proceed without saving changes?");
    setCurrentLink(link);
  };

  const handleYes = () => {
    closePopup();
    currentLink === "/architect_skills"
      ? handleArchRole(currentLink)
      : navigate(currentLink);
  };

  const closePopup = () => {
    setConfirmPopup(false);
    setConfirmTitle("");
    setCurrentLink("");
  };

  // console.log(IconMenu);

  return (
    <div className={"Side-menu " + (menuActive ? "active" : "")}>
      <SideDrawer
        open={open}
        toggleDrawer={toggleDrawer}
        roleClick={handleArchRole}
        userType={User_Type}
      />
      {!!clientPopup && (
        <ClientModal
          open={clientPopup}
          closePopup={() => setClientPopup(false)}
          title="Select Client"
          type={
            (currentLink === "/architect_skills" && "ARCH_SKILL") ||
            (currentLink === "/compare-persona" && "COMPARE_PERSONA") ||
            (currentLink === "/compare-role" && "COMPARE_ROLE")
          }
        />
      )}
      <div className="Side-menu-icons">
        <ul>
          {contentList.map((el, i) => (
            <li key={i} title={el.title}>
              <span
                // onClick={() =>
                //   el.alt === "Home" ? navigate("/") : setOpen((prev) => !prev)
                // }
                onClick={() =>
                  personaUpdate
                    ? handleCheckNavigate(el.link)
                    : el.link === "/architect_skills"
                    ? handleArchRole(el.link)
                    : el.link === "/compare-persona"
                    ? handleComparePersona(el.link)
                    : el.link === "/compare-role" && (User_Type === UserType.GENERAL_USER || User_Type === UserType.GENERAL_USER_INTERNAL)
                    ? handleComparePersona(el.link)
                    : window.location.pathname !== el.link
                    ? navigate(el.link)
                    : undefined
                }
              >
                {el.typeSrc === "img" && <img src={el.imgSrc} alt={el.alt} />}
                {el.typeSrc === "svg" && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: el.imgSrc,
                    }}
                  />
                )}
              </span>
            </li>
          ))}
        </ul>
        <ul>
          <li>
            <span style={{ padding: "10px", height: 60 }}>
              <Avatar
                alt={userDetails?.name?.toUpperCase()}
                src="/broken-image.jpg"
                className={classes.avatarBg}
                title={userDetails?.name}
              />
            </span>
          </li>
          <li>
            <span onClick={handleLogout}>
              <img src={Images.icons.logoutIcon} alt="logout" />
            </span>
          </li>
        </ul>
      </div>

      <ModalPopup
        open={confirmPopup}
        maxWidth="sm"
        title={confirmTitle}
        center={true}
      >
        <ConfirmationButton yesClick={handleYes} noClick={closePopup} />
      </ModalPopup>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  avatarBg: {
    color: "white",
    backgroundColor: COLORS.BODY_BLUE,
    margin: 0,
    padding: 0,
  },
}));
