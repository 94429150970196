import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { navigate } from "@reach/router";
import FunctionMenu from "../../utils/FunctionMenu";
import IndustryRoleMenu from "../../utils/IndustryRoleMenu";
import LeaderRoleMenu from "../../utils/LeaderRoleMenu";
import useRoles from "../../../Hooks/useRoles";
import { PrimaryButton } from "../../Common/Buttons/PrimaryButton";
import InputBox from "../../Common/FormElements/InputBox";
import { IndustryRoleListStore } from "../../../store/store_state";
import useSubSectorList from "../../../Hooks/useSubSectorList";
import SubSectorMenuList from "../../utils/SubSectorMenuList";
import { UserType } from "../../../enums/UserType";
import { getOrDefault } from "../../../helpers/CommonHelper";
import { Modes } from "../../../enums/Modes";

export default ({ data, mode, userData, roleDetail }) => {
  const {
    subsector,
    setSubsector,
    funcval,
    setFuncval,
    industryRole,
    setIndustryRole,
    laederRole,
    setLaederRole,
    clientName,
  } = useRoles();

  const { subSectorsList } = useSubSectorList();

  const IndustryRoleList = IndustryRoleListStore.useState(
    (s) => s.IndustryRoleList
  );

  const [subSectorType, setsubSectorType] = useState(1);

  const isInitialRender = useRef(true);

  useEffect(() => {
    if(mode === Modes.ADD && isInitialRender.current){
      isInitialRender.current = false;
      setSubsector("Any");
      setFuncval("Any");
      setLaederRole(0);
      setIndustryRole(0);
    }
    else if(!!data && mode !== Modes.ADD){
      setSubsector(getOrDefault(data?.subsector?.subsectorId, "Any"));
      setFuncval(getOrDefault(data?.function?.functionId, "Any"));
      setLaederRole(getOrDefault(data?.leadershipRole?.leadershipRoleId, 0));
      setsubSectorType(getOrDefault(data?.subsector?.subsectorTypeId, 0));
    }
  }, [data]);

  useEffect(() => {
    if (
      (subsector === "Any" && funcval === "Any") ||
      !IndustryRoleList.length
    ) {
      // setLaederRole(null);
    }
    // console.log(IndustryRoleList, industryRole);
    if (
      !IndustryRoleList.some((role) => role.roleId == industryRole) &&
      funcval === "Any"
    ) {
      // console.log(subsector, funcval);
      setIndustryRole(0);
    }
  }, [subsector, funcval, IndustryRoleList]);

  const handleSubSector = (val, type) => {
    setsubSectorType(type);
    setSubsector(val);

    // setSubsector("Any");
    setFuncval("Any");
    setLaederRole(0);
    setIndustryRole(0);
  };
  const handleFunction = (val) => {
    setFuncval(val);

    setLaederRole(0);
    setIndustryRole(0);
  };
  const handleIndustry = (val) => setIndustryRole(val);
  const handleLeader = (val) => setLaederRole(val);

  // console.log(subsector, funcval, industryRole);
  // console.log(userData?.User_Type === 2, userData?.User_Type);
  return (
    <>
      <DropMenuBlock>
        <SubSectorMenuList
          handleSelect={handleSubSector}
          value={subsector}
          width="200px"
          list={subSectorsList}
        />
        {mode === "add" || subsector !== "Any" ? (
          <FunctionMenu
            handleSelect={handleFunction}
            value={funcval}
            subSector={subsector}
            subSectorType={subSectorType}
            width="200px"
            passClientId={true}
          />
        ) : null}
        <IndustryRoleMenu
          handleSelect={handleIndustry}
          value={industryRole}
          subSector={subsector}
          funVal={funcval}
          width="200px"
          anyVal={false}
          passClientId={true}
        />
        <PrimaryButton
          onClick={() =>
            navigate(
              `/compare-role/${clientName}/${subsector}/${encodeURIComponent(
                funcval
              )}/${industryRole}`
            )
          }
          disabled={subsector === "Any" && funcval === "Any"}
          style={{ marginBottom: 25 }}
        >
          Compare Role
        </PrimaryButton>
      </DropMenuBlock>
      <LeaderBlock>
        {userData?.User_Type !== UserType.SME ? (
          <LeaderRoleMenu
            handleSelect={handleLeader}
            value={laederRole}
            setVal={subsector === "Any" && funcval === "Any"}
          />
        ) : (
          <InputBox
            rows="1"
            value={roleDetail?.leadershipRole.leadershipRoleName}
            disabled={true}
            label="Leadership Role"
          />
        )}
      </LeaderBlock>
    </>
  );
};

const DropMenuBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  & > div {
    margin-right: 15px;
    /* width: 30% !important; */
  }
  @media only screen and (max-width: 1024px) {
    padding-bottom: 30px;
    & > div {
      margin-right: 10px;
      /* width: 46% !important; */
    }
  }
`;

const LeaderBlock = styled.div`
  display: flex;
  align-items: center;
  & > div {
    width: 30% !important;
  }
  button {
    margin: 4px 0px 0px 15px;
  }
`;
