import React, { createContext, useState, useMemo, useEffect } from 'react';
import {
  disableBodyScroll,
  enableBodyScroll
} from 'body-scroll-lock';
//clearAllBodyScrollLocks
export const FullPageLoaderContext = createContext();

export default function FullPageLoaderProvider({ children }) {
  const [fullLoader, setFullLoader] = useState(false);
  const [messageLoader, setMessageLoader] = useState({
    message: '',
    state: false,
  });

  useEffect(() => {
    const targetElement = document.querySelector('body');
    if (fullLoader || messageLoader?.state) {
      window.scrollTo(0, 0);
      disableBodyScroll(targetElement);
    } else {
      enableBodyScroll(targetElement);
    }
  }, [fullLoader, messageLoader]);
  const value = useMemo(() => {
    return {
      fullLoader,
      setFullLoader,
      messageLoader,
      setMessageLoader,
    };
  }, [fullLoader, setFullLoader, messageLoader, setMessageLoader]);
  return (
    <FullPageLoaderContext.Provider value={value}>
      {children}
    </FullPageLoaderContext.Provider>
  );
}
