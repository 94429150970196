// *https://www.registers.service.gov.uk/registers/country/use-the-api*
import React, { useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core";
import COLORS from "../../../assets/colors";
import { SortString } from "../../../helpers/SortString";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-multiline": {
      // borderRadius: "0px",
      padding: 0,
    },
    "& .MuiOutlinedInput-input": {
      color: COLORS.TEXT_BLUE,
      fontSize: 12,
      padding: 12,
    },
  },
  autocomp: {
    "& .MuiAutocomplete-inputRoot": {
      padding: 3,
    },
    "&  .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  activeComp: {
    "&:hover .MuiAutocomplete-clearIndicator": {
      visibility: "visible",
    },
  },
}));

export default ({
  renderOption,
  PopperComponent,
  optionLabel,
  optionChange,
  stateValue,
  valueLabel,
  triggerApi,
  freeSolo = false,
  width = 300,
  insideMenu = false,
  openTrigger = () => {},
  disabled = false,
  noOptionsText = "No options",
  clearText="Clear"
}) => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [isFetched, setIsFetched] = React.useState(false);
  const loading = open && !!options && options.length === 0 && !isFetched;

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      const response = await triggerApi();
      if (active && !!response) {
        // console.log("openTrigger -> response", response);
        setOptions(
          optionLabel === "level"
            ? SortString(response, "levelNumber")
            : response
        );
        setIsFetched(true);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  useEffect(() => {
    if (!insideMenu) {
      setOpen(false);
    }
  }, [insideMenu]);
  // console.log(optionLabel, options);
  return (
    <Autocomplete
      style={{ width }}
      className={[classes.autocomp, stateValue ? classes.activeComp : ""]}
      // freeSolo={freeSolo}
      open={open}
      onOpen={() => {
        setOpen(true);
        openTrigger();
      }}
      onClose={() => {
        !insideMenu && setOpen(false);
        setIsFetched(false);
      }}
      renderOption={(option) => renderOption(option)}
      PopperComponent={
        !!PopperComponent && !!options.length ? PopperComponent : undefined
      }
      getOptionSelected={(option, value) =>
        option[optionLabel] === value[optionLabel]
      }
      onChange={(event, newValue, reason) => {
        // console.log(reason);
        optionChange(newValue, reason);
        !!insideMenu && setOpen(false);
      }}
      noOptionsText={noOptionsText}
      onInputChange={(event, newValue, reason) => {
        // console.log(reason);
        if (reason === "clear") optionChange(newValue, reason);
      }}
      getOptionLabel={(option) => option[optionLabel]}
      filterOptions={(options, state) => options}
      options={options}
      loading={loading}
      // defaultValue={stateValue}
      // value={stateValue}
      // inputValue={!open ? stateValue : ""}
      disabled={disabled}
      inputValue={stateValue}
      clearText={clearText}
      renderInput={(params) => (
        <TextField
          {...params}
          className={classes.root}
          label=""
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={15} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};
