import React, { memo, useState } from "react";
import styled from "styled-components";
import { H5, Paragraph } from "../../../Common/Typography";
import COLORS from "../../../../assets/colors";
import AutoSelectBox from "../../../Common/FormElements/AutoSelectBox";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import PopHover from "../../../Common/PopModal/PopHover";

export default memo(
  ({ data, CopyList, criticalList, handleCriticalChange }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    // console.table(CopyList);
    // console.table(data, criticalList);
    return (
      <SkillColumn>
        <H5>
          Criticality
          <InfoOutlinedIcon
            aria-owns={!!anchorEl ? "simple-popover" : undefined}
            aria-haspopup="true"
            onMouseEnter={(e) => setAnchorEl(e.currentTarget)}
            onMouseLeave={() => setAnchorEl(null)}
          />
        </H5>

        <ul>
          {data?.map((el, i) => (
            <li
              key={i}
              className={
                !!CopyList.find((elem) => elem?.skillId === el?.skillId) &&
                CopyList.find((elem) => elem?.skillId === el?.skillId)
                  .skillCriticality !== el?.skillCriticality
                  ? "active"
                  : ""
              }
            >
              <AutoSelectBox
                optionChange={(item, reason) =>
                  handleCriticalChange(item, el, reason)
                }
                optionLabel="label"
                valueLabel="skillCriticality"
                stateValue={el.skillCriticality}
                triggerApi={() => criticalList}
                renderOption={(option) => (
                  <OptionCutom>
                    <span>{option.label}</span>
                  </OptionCutom>
                )}
                disabled={!el.proficiencyLevel.level}
              />
            </li>
          ))}
        </ul>
        <PopHover anchorEl={anchorEl} hoverType={true}>
          <InfoContent>
            <Paragraph>
              Specify the criticality of the skill for the persona
            </Paragraph>
          </InfoContent>
        </PopHover>
      </SkillColumn>
    );
  }
);

const InfoContent = styled.div`
  padding: 15px;
  width: 350px;
  p {
    font-size: 14px;
    line-height: 20px;
    padding-bottom: 0;
  }
`;

const SkillColumn = styled.div`
  width: 180px;
  h5 {
    position: relative;
    small {
      cursor: pointer;
      display: block;
      position: absolute;
      bottom: 5px;
      text-decoration: underline;
    }

    svg {
      cursor: pointer;
      color: ${COLORS.DARK_GREY};
      font-size: 20px;
      position: absolute;
      top: 2px;
      margin-left: 5px;
      z-index: 1000;
    }
  }
  ul {
    li {
      border: solid 1px ${COLORS.ASH_BORDER};
      border-radius: 4px;
      color: ${COLORS.TITLE_BLUE};
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 600;
      padding: 2px !important;
      height: 44px;
      &.active {
        border: solid 2px ${COLORS.BLUE};
        box-shadow: 0 2px 4px 0 rgba(95, 130, 188, 0.26);
      }
      & > div {
        width: 100%;
      }
      .MuiFormControl-root {
        padding: 0px;
        .MuiInputBase-root {
          margin: 0px;
        }
        .MuiSelect-selectMenu {
          border: none;
        }
      }
    }
  }
`;

const OptionCutom = styled.div`
  display: flex;
  font-size: 12px;
  width: 100%;
  span {
    padding: 5px;
    &:first-child {
      flex: 0 0 30%;
    }
    &:last-child {
      flex: 0 0 70%;
    }
  }
`;
