import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

import Layout from "../Layout";
import { H2, H3, H4, H5 } from "../Common/Typography";
import { BodyContent, Container } from "../Common/GridSystem";
import COLORS from "../../assets/colors";
import BackNav from "../Common/BackNav";
import AutoSelectSearch from "../Common/FormElements/AutoSelectSearch";
import RoleSearchPopper from "./RoleSearchPopper";
import { RoleServices } from "../../services/RoleServices";
import { PrimaryButton } from "../Common/Buttons/PrimaryButton";
import SelectMenu from "../Common/FormElements/SelectMenu";
import { AuthService } from "../../services/AuthService";
import { SortString } from "../../helpers/SortString";
import CustomizedTables from "../Common/CustomizedTables";
import { Role_Search_Table_Colmns } from "./Role_Management_Table_Colmns";
import { navigate } from "@reach/router";
import useOccupation from "../../Hooks/useOccupation";
import OccupationProvider from "../../Provider/OccupationProvider";
import AutoCompleteCheckBox from "../Common/FormElements/AutoCompleteCheckBox";
import ErrorTitle from "../Common/Error/ErrorTitle";
import useFullLoader from "../../Hooks/useFullLoader";
import useAuth from "../../Hooks/useAuth";
import PaginationCustom, { CountInit } from "../Common/PaginationCustom";
import { getUpdatedPageAndOffsetBasedOnRowCount } from "../../helpers/CommonHelper";

export default ({ children }) => (
  <Layout>
    <OccupationProvider>
      <RoleComponent>{children}</RoleComponent>
    </OccupationProvider>
  </Layout>
);

export const RoleComponent = () => {
  const { occupations, setOccupations, setSubsectorIds } = useOccupation();
  const { fullLoader, setFullLoader } = useFullLoader();
  const { skillstatusMenuList } = useAuth();

  let InitialRoleStoreState = JSON?.parse(sessionStorage.getItem('roleStoreState'));

  const searchWidth = 810;
  const [showAdvance, setShowAdvance] = useState(InitialRoleStoreState?.showAdvance || false);
  const [showTable, setShowTable] = useState(false);
  const [roletableHeight, setRoletableHeight] = useState("auto");

  const [roleName, setRoleName] = useState(InitialRoleStoreState?.roleName || "");
  const [roleList, setRoleList] = useState(null);
  const [listTotal, setListTotal] = useState(0);

  const [leaderRoles, setLeaderRoles] = useState([]);
  const [leader, setLeader] = useState(null);
  const [subSectors, setSubSectors] = useState(InitialRoleStoreState?.subSectors || []);
  const [subSectorSelected, setSubSectorSelected] = useState(InitialRoleStoreState?.subSectorSelected || []);
  const [statusSelected, setStatusSelected] = useState(InitialRoleStoreState?.statusSelected || []);
  const [sourceSelected, setSourceSelected] = useState(InitialRoleStoreState?.sourceSelected || []);
  const [functionList, setFunctionList] = useState(InitialRoleStoreState?.functionList || []);
  const [functionSelected, setFunctionSelected] = useState(InitialRoleStoreState?.functionSelected || []);
  const [occupationList, setOccupationList] = useState(InitialRoleStoreState?.occupations || []);
  const [ocuupationSelected, setOcuupationSelected] = useState(InitialRoleStoreState?.ocuupationSelected || []);
  const [searchEnabled, setSearchEnabled] = useState(InitialRoleStoreState?.searchEnabled || false);
  const [searchDataState, setSearchDataState] = useState(null);
  const [frameworkList, setFrameworkList] = useState(InitialRoleStoreState?.frameworkList || []);
  const [roleSort, setRoleSort] = useState(null);
  const [defaultOccupations, setDefaultOccupations] = useState([]);
  const [defaultFunctions, setDefaultFunctions] = useState([]);
  const [currPage, setCurrPage] = useState(InitialRoleStoreState?.currPage || 1);

  // console.log("RoleComponent -> frameworkList", frameworkList, sourceSelected);

  // console.log(functionList);
  // Pagination
  const [pageOffset, setPageOffset] = useState(InitialRoleStoreState?.pageOffset || 0);
  const [rowCount, setrowCount] = useState(InitialRoleStoreState?.rowCount || CountInit);
  const handlePagination = (e, page) => {
    setCurrPage(page);
    searchAPITriggerd(rowCount, (page - 1) * rowCount);
    setPageOffset((page - 1) * rowCount);
  };
  const changeRowCount = (count) => {
    const { updatedCurrPage, updatedPageOffset } = getUpdatedPageAndOffsetBasedOnRowCount(pageOffset, count);
    setCurrPage(updatedCurrPage);
    setPageOffset(updatedPageOffset);
    searchAPITriggerd(count, updatedPageOffset);
    setrowCount(count);
  };
  // Pagination

  const getStaticList = async () => {
    try {
      const resSubSectors = await AuthService.subSectorListV3();
      const sorted = SortString(resSubSectors, "subsector").map((el) => ({
        label: el.subsector,
        value: el.subsectorId,
        group: el.subsectorTypeId === 1 ? "Industry" : "Function",
      }));

      const functionArray = sorted.filter((el) => el.group === "Function");
      const industryArray = sorted.filter((el) => el.group === "Industry");
      setSubSectors([...functionArray, ...industryArray]);

      const res = await AuthService.sourceListV3();
      const sortedSource = SortString(
        res.filter((el) => !!el.source),
        "source"
      );
      setFrameworkList(
        sortedSource.map((el) => ({ label: el.source, value: el.sourceId }))
      );
    } catch (err) {
      console.log(err);
    }
  };

  const getLeaderRoles = async () => {
    try {
      const res = await AuthService.leaderShipRolesV3();
      setLeaderRoles(
        res.map((el) => ({
          label: el.leadershipRoleName,
          value: el.leadershipRoleId,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  const getFunctionsList = async () => {
    try {
      const res = await AuthService.functionsListV3(
        subSectorSelected.length !== 0
          ? `?subSectorIds=${subSectorSelected}`
          : ""
      );
      const sorted = SortString(
        res.filter((el) => !!el.functionName),
        "functionName"
      );
      // const sorted = await res.map((el) => !!el && el.trim()).sort();
      // console.log("getFunctionsList -> sorted", sorted);
      setFunctionList(
        sorted.map((el) => ({ label: el.functionName, value: el.functionId }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  const setDefaultOcuupationsAndFunctions = async () => {
    try {
      const occupationRes =  await RoleServices.getOccupationsV3("?" + "subSectorIds=");
      const updatedOccupations = occupationRes
                                  .map((elem) => !!elem && elem.trim())
                                  .sort()
                                  .map((el) => ({ label: el, value: el }));
      setOccupations(InitialRoleStoreState?.occupations || updatedOccupations);
      setDefaultOccupations(updatedOccupations);

      const functionRes = await AuthService.functionsListV3("");
      const sorted = SortString(functionRes.filter((el) => !!el.functionName), "functionName");
      setDefaultFunctions(sorted.map((el) => ({ label: el.functionName, value: el.functionId })));
    } catch (error) {
      console.log('error', error);
    }
  }

  useEffect(() => {
    setDefaultOcuupationsAndFunctions();
    getLeaderRoles();
    if(!!InitialRoleStoreState){
      sessionStorage.removeItem('roleStoreState');
      handleSearch();
    }else{
      getStaticList();
      return () => {
        setSubSectors([]);
      };
    }
  }, []);

  useEffect(() => {
    if (!showAdvance) {
      setSubSectorSelected([]);
      setFunctionSelected([]);
      setOcuupationSelected([]);
      setStatusSelected([]);
      setSourceSelected([]);
    }
  }, [showAdvance]);

  // console.log("RoleComponent -> sourceSelected", sourceSelected);
  useEffect(() => {
    if (
      subSectorSelected?.length > 0 ||
      functionSelected?.length > 0 ||
      ocuupationSelected?.length > 0 ||
      sourceSelected?.length > 0 ||
      statusSelected?.length > 0 ||
      !!roleName
    ) {
      setSearchEnabled(true);
      setrowCount(CountInit);
      setPageOffset(0);
    } else {
      setSearchEnabled(false);
      setRoleList(null);
      setrowCount(CountInit);
      setPageOffset(0);
    }
  }, [
    roleName,
    subSectorSelected,
    functionSelected,
    ocuupationSelected,
    sourceSelected,
    statusSelected,
  ]);

  useEffect(() => {
    !!subSectorSelected && getFunctionsList();
    return () => {
      setFunctionList([]);
    };
  }, [subSectorSelected]);

  useEffect(() => {
    if (
      roleList?.length > 5 &&
      showTable &&
      !!document.querySelector(".MuiTableBody-root tr.MuiTableRow-root")
    ) {
      setRoletableHeight(
        (document.querySelector(".MuiTableBody-root tr.MuiTableRow-root")
          ?.offsetHeight +
          8) *
          5 +
          (document.querySelector(".MuiTableRow-head").offsetHeight + 8) +
          8
      );
    }
  }, [showTable]);

  useEffect(() => {
     if(!InitialRoleStoreState){
      setOccupationList(occupations);
     }
  }, [occupations]);


  // console.log("searchAPITriggerd -> roleSort", roleSort);
  const searchAPITriggerd = async (count, offset) => {
    const res = await RoleServices.searchRoleV3(
      { ...searchDataState, pagesize: count, offset },
      !!roleSort
        ? `?sortBy=${roleSort?.sortBy || ""}&sortOrder=${
            roleSort?.sortOrder || ""
          }`
        : ""
      // `?pagesize=${count}&offset=${offset}`
    );
    const filterResult = res?.roles?.filter((el) => !!el.roleName);
    // console.log("searchAPITriggerd -> filterResult", filterResult);
    setRoleList(filterResult);
    setListTotal(res?.total || 0);
  };

  const sortRoleAPI_Triggerd = async (sortValue) => {
    const res = await RoleServices.searchRoleV3(
      searchDataState,
      !!sortValue
        ? `?sortBy=${sortValue?.sortBy || ""}&sortOrder=${
            sortValue?.sortOrder || ""
          }`
        : ""
    );
    const filterResult = res?.roles?.filter((el) => !!el.roleName);
    // console.log("searchAPITriggerd -> filterResult", filterResult);
    setRoleList(filterResult);
    setListTotal(res?.total || 0);
    setRoleSort(sortValue);
  };

  const triggerApi = async (searchString, reason) => {
    // console.log("triggerApi -> reason", reason);
    setRoleName(searchString);
    setListTotal(0);
    setRoleSort(null);
    if (showTable) {
      setShowTable(false);
    }
    const searchData = {
      roleName: searchString,
      subSectorIds: subSectorSelected || [],
      functionIds: functionSelected || [],
      occupations: ocuupationSelected || [],
      roleStatuses: statusSelected || [],
      sourceIds: sourceSelected || [],
      pagesize: rowCount,
      offset: pageOffset,
    };
    // console.log("triggerApi -> searchData", searchData);

    setSearchDataState(searchData);
    //?pagesize=100&offset=0
    if (searchEnabled) {
      reason === "clickSearch" && setFullLoader(true);
      try {
        const res = await RoleServices.searchRoleV3(
          searchData,
          !!roleSort
            ? `?sortBy=${roleSort?.sortBy || ""}&sortOrder=${
                roleSort?.sortOrder || ""
              }`
            : ""
          // `?pagesize=${rowCount}&offset=${pageOffset}`
        );
        // return false; roleName
        // console.log("triggerApi -> res", reason, res?.roles);
        const filterResult = res?.roles || [];
        // console.log("triggerApi -> filterResult", filterResult);
        reason === "clickSearch" && setRoleList(filterResult);
        setListTotal(res?.total || 0);
        return filterResult;
      } catch (errors) {
        console.log(errors);
      } finally {
        setFullLoader(false);
      }
    }
  };

  const handleChange = async(item, reason) => {
    if (reason === "select-option") {
      await setRoleName(item.roleName);
      handleRowClick(item);
      // navigate(`/role/edit/${item.roleId}`);
    } else if (reason === "clear") {
      setRoleName("");
    } else if (reason === "create-option") {
      setRoleName(item);
      handleSearch();
    }
  };

  const handleRowClick = (item) => {
    const roleStoreState = {
      roleName,
      subSectors,
      subSectorSelected,
      occupations,
      ocuupationSelected,
      functionList,
      functionSelected,
      frameworkList,
      sourceSelected,
      statusSelected,
      searchEnabled,
      showAdvance,
      rowCount,
      pageOffset,
      currPage
    };
    sessionStorage.setItem('roleStoreState', JSON.stringify(roleStoreState));
    navigate(`/role/edit/${item.roleId}`);
  };

  const handleSearch = async () => {
    await triggerApi(roleName, "clickSearch");
    setShowTable(true);
  };

  const handleShowAdvanceSearch = () => setShowAdvance((prev) => !prev);

  const handleIndustry = (val) => {
    setSubSectorSelected(val.map((el) => el.value));
    if(val?.length === 0) setSubsectorIds([""]);
    else setSubsectorIds(val.map((el) => el.value));
  };
  const handleDepartment = (val) =>
    setFunctionSelected(val.map((el) => el.value));
  const handleOccupation = (val) =>
    setOcuupationSelected(val.map((el) => el.value));

  const handleStatus = (val) => setStatusSelected(val.map((el) => el.value));
  const handleSource = (val) => setSourceSelected(val.map((el) => el.value));

  const handleLeader = (val) => setLeader(val);

  return (
    <>
      <H2>Manage Industry Roles </H2>
      <BodyContent>
        <Container>
          <BackNav linkNav="/" />
          <H3 style={{ color: COLORS.TITLE_BLUE }}>Update an Industry Role</H3>
          <WhiteBlock style={{ display: "block", paddingBottom: 10 }}>
            <SearchInputBlock show={showAdvance}>
              {/* <Label>Search Role</Label> */}
              <AutoSelectSearch
                width={searchWidth}
                freeSolo={true}
                optionChange={(item, reason) =>
                  !!item ? handleChange(item, reason) : undefined
                }
                handleClearSearch={() => setRoleName("")}
                optionLabel="roleName"
                stateValue={roleName}
                triggerApi={(val, reason) => triggerApi(val, reason)}
                enterResetSearch={(val) => {
                  handleSearch();
                  setRoleName(val);
                }}
                PopperComponent={(props) => (
                  <RoleSearchPopper {...props} width={searchWidth} />
                )}
                renderOption={(option) => (
                  <OptionCutom>
                    <span>{option?.roleName}</span>
                    <span>{option?.subsector}</span>
                    <span>{option?.functionName}</span>
                    <span>{option?.occupation}</span>
                  </OptionCutom>
                )}
              />
              <PrimaryButton onClick={handleSearch} disabled={!searchEnabled}>
                Search
              </PrimaryButton>
              <H5 onClick={handleShowAdvanceSearch}>
                Filter Search Results
                <FilterIcon />
              </H5>
            </SearchInputBlock>
            {showAdvance && (
              <FilterBlock>
                <AutoCompleteCheckBox
                  width="350px"
                  label="Industry / Function"
                  defaultValue={subSectorSelected}
                  list={subSectors}
                  optionLabel={"label"}
                  optionChange={handleIndustry}
                  group={true}
                  groupLabel="group"
                  limitTags={2}
                />
                <AutoCompleteCheckBox
                  width="350px"
                  label="Department / Track"
                  defaultValue={functionSelected}
                  list={functionList}
                  defaultList={defaultFunctions}
                  optionLabel={"label"}
                  optionChange={handleDepartment}
                  limitTags={2}
                />
                <AutoCompleteCheckBox
                  width="350px"
                  label="Occupation"
                  defaultValue={ocuupationSelected}
                  list={occupationList}
                  defaultList={defaultOccupations}
                  optionLabel={"label"}
                  optionChange={handleOccupation}
                  limitTags={2}
                />
                <AutoCompleteCheckBox
                  width="200px"
                  label="Source"
                  defaultValue={sourceSelected}
                  list={frameworkList}
                  optionLabel={"label"}
                  optionChange={handleSource}
                  limitTags={2}
                />
                <AutoCompleteCheckBox
                  width="200px"
                  label="Status"
                  defaultValue={statusSelected}
                  list={skillstatusMenuList}
                  optionLabel={"label"}
                  optionChange={handleStatus}
                  limitTags={2}
                />
              </FilterBlock>
            )}
            {!!roleList && roleList?.length > 0 && !fullLoader ? (
              <>
                <div className="tableBlock">
                  <CustomizedTables
                    columns={Role_Search_Table_Colmns}
                    rowList={roleList || []}
                    stickyHeader={roleList?.length > 5}
                    tableHeight={
                      roleList?.length > 5 && showTable
                        ? roletableHeight
                        : "auto"
                    }
                    rowClick={handleRowClick}
                    sortAndFilterAPILevel={{ sort: true, filter: true }}
                    handleSortTable={(value) => sortRoleAPI_Triggerd(value)}
                    innerTabel={true}
                  />
                </div>

                {!!listTotal && (
                  <PaginationCustom
                    pageTotal={listTotal}
                    handlePagination={handlePagination}
                    countRows={rowCount}
                    changeRowCount={changeRowCount}
                    changeCurrentPage={currPage}
                  />
                )}
              </>
            ) : null}
            {!!roleList && roleList.length === 0 ? (
              <ErrorTitle text="No matching roles found, kindly change the search criteria/ keyword" />
            ) : null}
          </WhiteBlock>
          <WhiteBlock style={{ display: "block" }}>
            <H4>Add a New Industry Role</H4>
            <PrimaryButton onClick={() => navigate("/role/add")}>
              Add +
            </PrimaryButton>
          </WhiteBlock>
          <WhiteBlock>
            <SelectMenu
              width="250px"
              label="Update Leadership Role"
              value={leader}
              list={leaderRoles}
              handleSelect={handleLeader}
            />
            <PrimaryButton
              style={{ margin: "4px 0px 0px 15px" }}
              disabled={!leader}
              onClick={() => navigate(`/leader/${leader}`)}
            >
              Go
            </PrimaryButton>
          </WhiteBlock>
        </Container>
      </BodyContent>
    </>
  );
};

const WhiteBlock = styled.div`
  background-color: ${COLORS.PRIMARY_WHITE};
  border-radius: 8px;
  box-shadow: 0 1px 3px 0 rgba(97, 122, 163, 0.24);
  display: flex;
  align-items: center;
  padding: 30px;
  margin-bottom: 30px;
  .top-innerBlock {
    display: flex;
    align-items: center;
  }
  .table-block {
    overflow: hidden;

    & > div {
      min-height: 300px;
      height: calc(100vh - 420px);
      overflow: auto;
    }
  }
  .tableBlock {
    margin-bottom: 20px;
  }
  table {
    padding: 0px 10px;
  }
  & > [class^="half-"] {
    flex: 0 0 50%;
  }
  .half-right {
    border-left: solid 1px ${COLORS.ASH_BORDER};
    padding: 15px 15px 15px 50px;
    .MuiInputBase-root {
      margin-bottom: 0px;
    }
  }
`;

const SearchInputBlock = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 25px;
  .MuiFormControl-root {
    border: solid 1px ${COLORS.ASH_BORDER};
    border-radius: 5px;
  }
  button {
    margin: 0px 15px;
  }
  h5 {
    cursor: pointer;
    padding-bottom: 0px;
    text-decoration: underline;
    padding: 15px 25px 15px 0px;
    position: relative;
    svg {
      font-size: 22px;
      position: absolute;
      right: 0px;
      transform: ${({ show }) => (show ? `rotate(180deg)` : `rotate(0deg)`)};
      transition: all 0.3s ease-in-out;
    }
  }
`;

const FilterBlock = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 30px;
  h5 {
    padding: 0px 15px 0px 0px;
  }
  & > div {
    margin-right: 30px;
    label {
      font-size: 16px;
      transform: translate(0px, 1.5px) scale(1);
    }
  }
`;

const FilterIcon = styled(ArrowDownwardIcon)``;

const OptionCutom = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  width: 100%;
  span {
    padding: 10px;
    /* flex: 0 0 33.3333%; */
    &:first-child {
      flex: 0 0 40%;
    }
    &:nth-child(2) {
      flex: 0 0 20%;
    }
    &:nth-child(3) {
      flex: 0 0 20%;
    }
    &:last-child {
      flex: 0 0 20%;
    }
  }
`;
