import React from "react";
import SelectMenuGroup from "../Common/FormElements/SelectMenuGroup";

// subsector: "Accountancy"
// subsectorId: 1
// subsectorTypeId: 2

export default ({
  width = 110,
  labelShow = true,
  handleSelect,
  value = "Any",
  list = [],
}) => {
  return (
    <SelectMenuGroup
      width={width}
      label={
        !!list?.find((el) => el.subsectorId === value) &&
        list?.find((el) => el.subsectorId === value).subsectorTypeId !== 1 &&
        value !== "Any"
          ? "Function"
          : "Industry"
      }
      value={value}
      list={
        !!list
          ? list.map((el) => ({
              label: el.subsector,
              value: el.subsectorId,
              type: el.subsectorTypeId,
              group: el.subsectorTypeId === 1 ? "Industry" : "Function",
            }))
          : []
      }
      groupKey="group"
      handleSelect={(val) =>
        handleSelect(
          val,
          list.find((el) => el.subsectorId === val)?.subsectorTypeId
        )
      }
      labelShow={labelShow}
    />
  );
};
