import React, { useState, useEffect, createRef } from "react";
import Layout from "../Layout";
import { H2 } from "../Common/Typography";
import { BodyContent, Container, BodyWhitePattern } from "../Common/GridSystem";
import { BackCheck } from "../Common/BackNav";
import { Formik, Form } from "formik";
import { clientManagementValidation } from "../../validations/authValidation";
import FormTextField from "../Common/FormElements/FormTextField";
import { PrimaryButton } from "../Common/Buttons/PrimaryButton";
import useAlert from "../../Hooks/useAlert";
import { AuthService } from "../../services/AuthService";
import { navigate } from "@reach/router";
import useFullLoader from "../../Hooks/useFullLoader";
import ModalPopup from "../Common/ModalPopup";
import ConfirmationButton from "../Common/ConfirmationButton";
import { ContentService } from "../../services/ContentService";
import { SkillService } from "../../services/SkillService";

import { SortString } from "../../helpers/SortString";
import styled from "styled-components";
import AutoCompleteFormik from "../Common/FormElements/AutoCompleteFormik";
import AutoFilterLocal from "../Common/FormElements/AutoFilterLocal";
import ChipList from "../Common/Others/ChipList";
import COLORS from "../../assets/colors";
import { SecondaryButton } from "../Common/Buttons/SecondaryButton";
import { CheckSameArray } from "../utils";

import { SubSectorType } from "../../enums/SubSectorType";
import { getErrorInfo } from "../../helpers/CommonHelper";
import { AlertType } from "../../enums/AlertType";

export default ({ ...props }) => {
  const { mode, id } = props;
  const { showAlert } = useAlert();
  const { setFullLoader } = useFullLoader();
  const formikRef = createRef(null);

  const [clientDetail, setClientDetail] = useState(null);
  const [clientOrgs, setClientOrgs] = useState([]);
  const [clientInstanceRegion, setClientInstanceRegion] = useState([]);
  const [contentProviders, setContentProviders] = useState([]);
  const [subSectors, setSubSectors] = useState([]);
  const [functions, setFunctions] = useState([]);
  // console.log("subSectors", subSectors);
  const [sourceMenu, setSourceMenu] = useState([]);

  const [confirmPopup, setConfirmPopup] = useState(false);
  const [confirmTitle, setConfirmTitle] = useState("");
  const [confirmType, setConfirmType] = useState(null);

  const getOrgAndContentProviders = async () => {
    setFullLoader(true);
    try {
      const resOrgs = await AuthService.getAllClientsOrgStageV3();
      const resInstanceRegion = await AuthService.getClientsInstanceRegionV3();
      const resContentPros = await ContentService.getAllContentProvidersV3();
      const resSource = await SkillService.getSkillSourcesV3();
      setSourceMenu(
        SortString(resSource, "source").map((el) => ({
          label: el.source,
          value: el.sourceId,
          allowMultipleMappings: el.allowMultipleMappings,
        }))
      );
      // console.log(
      //   "getOrgAndContentProviders -> resContentPros",
      //   resContentPros
      // );
      if (mode === "add") {
        fetchSubSectors();
      }
      const sortedOrgs = SortString(resOrgs, "attributeValueId");
      // console.log("getOrgAndContentProviders -> sortedOrgs", sortedOrgs);
      setClientOrgs(
        sortedOrgs.map((el) => ({
          label: el.attrivuteValueName,
          value: el.attributeValueId,
        }))
      );
      const sortedRegion = SortString(resInstanceRegion, "attrivuteValueName");
      setClientInstanceRegion(
        sortedRegion.map((el) => ({
          label: el.attrivuteValueName,
          value: el.attributeValueId,
        }))
      );
      const filterContent =
        resContentPros?.filter((el) => !!el.contentProvider) || [];
      // console.log("getOrgAndContentProviders -> filterContent", filterContent);
      const sortedPros = SortString(filterContent, "contentProvider");
      setContentProviders(
        sortedPros.map((el) => ({
          label: el.contentProvider,
          value: el.contentProviderId,
        }))
      );

      setFullLoader(false);
    } catch (error) {
      console.log(error);
    } finally {
      setFullLoader(false);
    }
  };

  const getMappedList = (data) => {
    return (
      data?.map((el) => ({
        label: el.subsector,
        value: el.subsectorId,
        sourceIds: el.sourceIds,
      }))
    ) 
  }

  const fetchSubSectors = async (id = []) => {
    const sourceIdsParam = !!id.length
      ? "?" + id?.map((el) => `sourceIds=${el}`).join("&")
      : "";
    const resSubSectors = await AuthService.subSectorListV3(sourceIdsParam);
    const sortedSubSectors = SortString(
      resSubSectors?.filter((el) => el.subsectorTypeId === SubSectorType.SUBSECTOR),
      "subsector"
    );
    const sortedFunctions = SortString(
      resSubSectors?.filter((el) => el.subsectorTypeId === SubSectorType.FUNCTIONS),
      "subsector"
    )
    setFunctions(getMappedList(sortedFunctions));
    setSubSectors(getMappedList(sortedSubSectors));
  };

  useEffect(() => {
    getOrgAndContentProviders();
  }, []);

  const getDetail = async () => {
    // setFullLoader(true);
    try {
      const res = await AuthService.getClientDetailV3(Number(id));
      setClientDetail(res);
      // console.log("getDetail -> res", res);
      // console.log(subSectors, res.sourceList);
      if (mode === "edit") {
        fetchSubSectors(res.sourceList?.map((el) => el.sourceId) || []);
      }
    } catch (error) {
      showAlert(error.error, "error", 1500);
      console.log(error);
    } finally {
      // setFullLoader(false);
    }
  };

  useEffect(() => {
    if (mode === "edit") {
      getDetail();
    }
    return () => {
      setClientDetail(null);
    };
  }, [mode, id]);

  const processSubSectors = (subSectorType, clientDetail) => {
    return clientDetail?.subSectorList
      ?.map((el) => {
        if (el.subsectorTypeId === subSectorType) {
          return {
            label: el.subsector,
            value: el.subsectorId,
            sourceIds: el.sourceIds,
          };
        }
        return null;
      })
      .filter(Boolean);
  }
  
  const initValue = {
    clientName: mode === "edit" ? clientDetail?.clientName : "",
    clientDomain: mode === "edit" ? clientDetail?.clientDomain : "",
    orgGrowthStageId:
      mode === "edit"
        ? clientOrgs?.find(
            (el) => el.value === clientDetail?.orgGrowthStageId
          ) || ""
        : "",
    sourceIdList:
      mode === "edit"
        ? clientDetail?.sourceList?.map((el) => ({
            label: el.source,
            value: el.sourceId,
            allowMultipleMappings: el.allowMultipleMappings,
          }))
        : [],
    subSectorIdList:
      mode === "edit"
        ? processSubSectors(SubSectorType.SUBSECTOR, clientDetail)
        : [],
    functionIdList: 
      mode === "edit"
        ?  processSubSectors(SubSectorType.FUNCTIONS, clientDetail)
        : [],
    contentProviderList:
      mode === "edit"
        ? clientDetail?.contentProviderList?.map((el) => ({
            label: el.contentProvider,
            value: el.contentProviderId,
          }))
        : [],
    disprzInstanceName: mode === "edit" ? clientDetail?.disprzInstanceName : "",
    region:
      mode === "edit" && !!clientDetail?.region
        ? clientInstanceRegion?.find(
            (el) => el.value === Number(clientDetail?.region)
          )
        : "",
  };

  const getSubSectorIdList = (data) => {
    return (
      data?.subSectorIdList?.map(({ value }) => value).concat(data?.functionIdList?.map(({ value }) => value))
    );
  }

  const formSubmit = async (fieldValues) => {
    setFullLoader(true);
    const {
      sourceIdList,
      subSectorIdList,
      contentProviderList,
      orgGrowthStageId,
      region,
      ...postValues
    } = fieldValues;

    try {
      let payload = {
        sourceIdList: sourceIdList?.map(({ value }) => value) || [],
        subSectorIdList: getSubSectorIdList(fieldValues),
        contentProviderIdList:
          fieldValues?.contentProviderList?.map(({ value }) => value) || [],
        orgGrowthStageId: !!orgGrowthStageId ? orgGrowthStageId?.value : 0,
        region: !!region ? region.value : 0,
        ...postValues,
      };

      if (mode === "add") {
        await AuthService.addClientV3(payload);
        await showAlert("Client Creation Successful", "success", 1500);
        await setFullLoader(false);
        navigate("/clients");
      } else {
        await setFullLoader(false);
        setConfirmType("update");
        setConfirmTitle(`Confirm Client Updation`);
        setConfirmPopup(true);
      }
    } catch (errors) {
      let error = JSON.parse(errors);    
      showAlert(getErrorInfo(error), AlertType.ERROR, 1500);
    } finally {
      setFullLoader(false);
    }
  };

  const removeChip = (item, list, name, setFieldValue, formValues) => {
    setFieldValue(
      name,
      list.filter((el) => el.value !== item.value)
    );
    if (name === "sourceIdList") {
      const valueList = list.filter((el) => el.value !== item.value);
      setFieldValue(
        "subSectorIdList",
        valueList?.length > 0
          ? formValues?.subSectorIdList?.filter((el) =>
              el.sourceIds?.some((elem) => elem !== item.value)
            )
          : []
      );
      setFieldValue(
        "functionIdList",
        valueList?.length > 0
          ? formValues?.functionIdList?.filter((el) =>
              el.sourceIds?.some((elem) => elem !== item.value)
            )
          : []
      )
      fetchSubSectors(valueList?.map(({ value }) => value));
    }
  };

  const handleSearch = (
    item,
    reason,
    list,
    name,
    setFieldValue,
    setTouched
  ) => {
    if (!!item && item?.hasOwnProperty("allowMultipleMappings")) {
      console.log(item, reason, list, name);
      if (!list?.some((el) => el.value === item.value)) {
        const valueList = [...list, item].filter(
          (el) => el.allowMultipleMappings === item.allowMultipleMappings
        );
        setFieldValue(name, reason === "select-option" ? valueList : list);
        if (name === "sourceIdList") {
          if (valueList?.length === 1) {
            setFieldValue("subSectorIdList", []);
            setFieldValue("functionIdList", [])
          }
          fetchSubSectors(valueList?.map(({ value }) => value));
        }
      } else {
        showAlert("This item already added", "info", 1500);
      }
    } else {
      handleSelectValue(item, reason, list, name, setFieldValue, setTouched);
    }
  };

  const handleSelectValue = (
    item,
    reason,
    list,
    name,
    setFieldValue,
    setTouched
  ) => {
    if (reason === "select-option") {
      if (!list?.some((el) => el.value === item.value)) {
        setFieldValue(
          name,
          reason === "select-option" ? [...list, item] : list
        );
        // setTouched(name, true);
      } else {
        showAlert("This item already added", "info", 1500);
      }
    }
  };

  const handleSelectAll = (list, selectAll, name, setFieldValue) =>
    setFieldValue(name, !selectAll ? list : []);

  const handleUpdateYes = async () => {
    const { values } = formikRef.current;

    const {
      sourceIdList,
      subSectorIdList,
      contentProviderList,
      orgGrowthStageId,
      region,
      ...postValues
    } = values;

    let payload = {
      sourceIdList: values?.sourceIdList?.map(({ value }) => value),
      subSectorIdList: getSubSectorIdList(values),
      contentProviderIdList: values?.contentProviderList?.map(
        ({ value }) => value
      ),
      orgGrowthStageId: !!orgGrowthStageId ? orgGrowthStageId.value : 0,
      region: !!region ? region.value : 0,
      ...postValues,
    };
    // console.log("handleUpdateYes -> payload", payload);
    closePopup();
    setFullLoader(true);
    try {
      await AuthService.updateClientV3("/" + Number(id), payload);
      await showAlert("Client Updation Successful", "success", 1500);
      setFullLoader(false);
      navigate("/clients");
    } catch (errors) {
      let error = JSON.parse(errors);    
      showAlert(getErrorInfo(error), AlertType.ERROR, 1500);
    } finally {
      setFullLoader(false);
    }
  };

  const handleDeleteYes = async () => {
    // console.log(clientDetail);

    closePopup();
    setFullLoader(true);
    try {
      // await AuthService.deleteClient("/" + Number(clientDetail.clientId));
      await AuthService.deleteClientV3("/" + Number(clientDetail.clientId));

      await showAlert("Client Deletion Successful", "success", 1500);
      await setFullLoader(false);
      setTimeout(() => navigate("/clients"), 100);
    } catch (errors) {
      // console.log(errors);
      showAlert(errors.error, "error", 1500);
    } finally {
      setFullLoader(false);
    }
  };

  const deleteTrigger = () => {
    setConfirmPopup(true);
    setConfirmTitle(`Delete Client - ${formikRef.current.values.clientName}`);
    setConfirmType("delete");
  };

  const handleYes = () => {
    if (confirmType === "delete") {
      handleDeleteYes();
    } else if (confirmType === "update") {
      handleUpdateYes();
    } else if (confirmType === "back") {
      navigate("/clients");
    }
  };

  const handleNo = () => closePopup();
  const closePopup = () => {
    setConfirmPopup(false);
    setConfirmTitle("");
    setConfirmType(null);
  };

  const handleCheckBack = () => {
    const hasFormChange = !!Object.keys(formikRef.current.touched).length;
    if (hasFormChange) {
      setConfirmPopup(true);
      setConfirmType("back");
      setConfirmTitle(`Proceed without saving changes?`);
    } else {
      navigate("/clients");
    }
  };

  const ConfirmModal = () => (
    <ModalPopup
      open={confirmPopup}
      maxWidth="sm"
      title={confirmTitle}
      center={true}
    >
      <ConfirmationButton
        // yesClick={confirmType === "update" ? handleUpdateYes : handleDeleteYes}
        yesClick={handleYes}
        noClick={handleNo}
      />
    </ModalPopup>
  );
  // console.log({ clientDetail }, clientInstanceRegion);
  //&&
  // initValue.orgGrowthStageId !== undefined
  // console.log("subSectors", subSectors);
  return (
    <Layout>
      <H2>{mode === "add" ? "Create New Client" : "Manage Client Account"}</H2>
      <BodyContent>
        <Container>
          {/* <BackNav linkNav="/clients" /> */}
          <BackCheck handleCheckBack={handleCheckBack} />
          <BodyWhitePattern>
            {(mode === "edit" && clientOrgs?.length > 0) || mode === "add" ? (
              <Formik
                enableReinitialize={true}
                initialValues={initValue}
                innerRef={formikRef}
                validationSchema={clientManagementValidation}
                onSubmit={formSubmit}
              >
                {({
                  touched,
                  errors,
                  values,
                  setFieldValue,
                  setFieldTouched,
                  setTouched,
                }) => {
                  return (
                    <>
                      {console.log("values", values, !!values)}
                      <FormBlock>
                        <div className="clientForm-left">
                          <FormTextField
                            placeholder=""
                            name="clientName"
                            type="text"
                            label="Client Name"
                            setVal={setFieldValue}
                            setTouched={setFieldTouched}
                          />
                          <FormTextField
                            placeholder="Eg: @disprz.com"
                            name="clientDomain"
                            type="text"
                            label="Domain for Google Sign-In"
                            helperText="Used to verify Google Sign-In for Users"
                            setVal={setFieldValue}
                            setTouched={setFieldTouched}
                          />
                          <FormTextField
                            placeholder="Provide Disprz Instance Name (Eg: AngelBroking)"
                            name="disprzInstanceName"
                            type="text"
                            label="Disprz Instance Name"
                            setVal={setFieldValue}
                            setTouched={setFieldTouched}
                          />
                          {(mode === "edit" && values.region !== undefined) ||
                          mode === "add" ? (
                            <AutoCompleteFormik
                              list={clientInstanceRegion}
                              filterLabel="label"
                              name="region"
                              label="Disprz Instance Region"
                              style={{
                                padding: "0px 0px 0px 0px",
                                width: "100%",
                              }}
                              placeholder="Search Instance Regions"
                            />
                          ) : null}
                          <PrimaryButton type="submit">
                            {mode === "edit"
                              ? "Update Client"
                              : "Create Client"}
                          </PrimaryButton>
                          {mode === "edit" && (
                            <SecondaryButton
                              onClick={deleteTrigger}
                              style={{ marginLeft: 15 }}
                            >
                              Delete Client
                            </SecondaryButton>
                          )}
                        </div>
                        <div className="clientForm-right">
                          <DropDownRow>
                            {(mode === "edit" &&
                              values.orgGrowthStageId !== undefined) ||
                            mode === "add" ? (
                              <AutoCompleteFormik
                                list={clientOrgs}
                                filterLabel="label"
                                name="orgGrowthStageId"
                                label="Organisation Growth Stage"
                                style={{
                                  padding: "0px 0px 10px 0px",
                                  width: "340px",
                                }}
                                placeholder="Search Industries"
                              />
                            ) : null}
                          </DropDownRow>
                          <DropDownRow>
                            <AutoFilterLocal
                              list={sourceMenu}
                              filterLabel="label"
                              label="Source"
                              style={{
                                padding: "0px 0px 20px 0px",
                                width: "340px",
                              }}
                              placeholder="Search source"
                              optionChange={(item, reason) =>
                                handleSearch(
                                  item,
                                  reason,
                                  values?.sourceIdList,
                                  "sourceIdList",
                                  setFieldValue,
                                  setTouched,
                                  "multiple"
                                )
                              }
                              value=""
                              inputValue=""
                              // selectAll={true}
                              // unselectAll={
                              //   subSectors?.length ===
                              //   values?.subSectorIdList?.length
                              // }
                              // selectAllClick={() =>
                              //   handleSelectAll(
                              //     subSectors,
                              //     subSectors?.length ===
                              //       values?.subSectorIdList?.length,
                              //     "subSectorIdList",
                              //     setFieldValue
                              //   )
                              // }
                            />
                            {!!values?.sourceIdList && (
                              <ChipList
                                list={values.sourceIdList}
                                optionLabel="label"
                                onDelete={(item) =>
                                  removeChip(
                                    item,
                                    values.sourceIdList,
                                    "sourceIdList",
                                    setFieldValue,
                                    values
                                  )
                                }
                                chipClick={undefined}
                              />
                            )}
                          </DropDownRow>
                          <DropDownRow>
                            <AutoFilterLocal
                              list={subSectors}
                              filterLabel="label"
                              label="Associated Industries"
                              style={{
                                padding: "0px 0px 20px 0px",
                                width: "340px",
                              }}
                              placeholder="Search Industries"
                              optionChange={(item, reason) =>
                                handleSearch(
                                  item,
                                  reason,
                                  values?.subSectorIdList,
                                  "subSectorIdList",
                                  setFieldValue,
                                  setTouched,
                                  "multiple"
                                )
                              }
                              value=""
                              inputValue=""
                              selectAll={
                                (subSectors?.length > 0 &&
                                  !!values.sourceIdList?.length) ||
                                false
                              }
                              unselectAll={CheckSameArray(
                                subSectors,
                                values?.subSectorIdList
                              )}
                              selectAllClick={() =>
                                handleSelectAll(
                                  subSectors,
                                  CheckSameArray(
                                    subSectors,
                                    values?.subSectorIdList
                                  ),
                                  "subSectorIdList",
                                  setFieldValue
                                )
                              }
                              disabled={!values.sourceIdList?.length}
                            />
                            {!!values?.subSectorIdList && (
                              <ChipList
                                list={values.subSectorIdList}
                                optionLabel="label"
                                onDelete={(item) =>
                                  removeChip(
                                    item,
                                    values.subSectorIdList,
                                    "subSectorIdList",
                                    setFieldValue,
                                    values
                                  )
                                }
                                chipClick={undefined}
                              />
                            )}
                          </DropDownRow>
                          <DropDownRow>
                            <AutoFilterLocal
                              list={functions}
                              filterLabel="label"
                              label="Associated Functions"
                              style={{
                                padding: "0px 0px 20px 0px",
                                width: "340px",
                              }}
                              placeholder="Search Functions"
                              optionChange={(item, reason) =>
                                handleSearch(
                                  item,
                                  reason,
                                  values?.functionIdList,
                                  "functionIdList",
                                  setFieldValue,
                                  setTouched,
                                  "multiple"
                                )
                              }
                              value=""
                              inputValue=""
                              selectAll={
                                (functions?.length > 0 &&
                                  !!values.sourceIdList?.length)
                              }
                              unselectAll={CheckSameArray(
                              functions,
                                values?.functionIdList
                              )}
                              selectAllClick={() =>
                                handleSelectAll(
                                  functions,
                                  CheckSameArray(
                                    functions,
                                    values?.functionIdList
                                  ),
                                  "functionIdList",
                                  setFieldValue
                                )
                              }
                              disabled={!values.sourceIdList?.length}
                            />
                            {!!values?.functionIdList && (
                              <ChipList
                                list={values.functionIdList}
                                optionLabel="label"
                                onDelete={(item) =>
                                  removeChip(
                                    item,
                                    values.functionIdList,
                                    "functionIdList",
                                    setFieldValue,
                                    values
                                  )
                                }
                                chipClick={undefined}
                              />
                            )}
                          </DropDownRow>
                          <DropDownRow>
                            <AutoFilterLocal
                              list={contentProviders}
                              filterLabel="label"
                              label="Content Providers"
                              style={{
                                padding: "0px 0px 20px 0px",
                                width: "340px",
                              }}
                              placeholder="Search Content Providers"
                              optionChange={(item, reason) =>
                                handleSearch(
                                  item,
                                  reason,
                                  values?.contentProviderList,
                                  "contentProviderList",
                                  setFieldValue,
                                  setTouched,
                                  "multiple"
                                )
                              }
                              value=""
                              inputValue=""
                              selectAll={true}
                              unselectAll={
                                contentProviders?.length ===
                                values?.contentProviderList?.length
                              }
                              selectAllClick={() =>
                                handleSelectAll(
                                  contentProviders,
                                  contentProviders?.length ===
                                    values?.contentProviderList?.length,
                                  "contentProviderList",
                                  setFieldValue
                                )
                              }
                            />
                            {!!values?.contentProviderList && (
                              <ChipList
                                list={values.contentProviderList}
                                optionLabel="label"
                                onDelete={(item) =>
                                  removeChip(
                                    item,
                                    values.contentProviderList,
                                    "contentProviderList",
                                    setFieldValue,
                                    values
                                  )
                                }
                                chipClick={undefined}
                              />
                            )}
                          </DropDownRow>
                        </div>
                      </FormBlock>

                      {/* <pre>{JSON.stringify(errors, null, 2)}</pre>

                    <pre>{JSON.stringify(values, null, 2)}</pre> */}
                    </>
                  );
                }}
              </Formik>
            ) : (
              <div style={{ minHeight: 500 }}>&nbsp;</div>
            )}
          </BodyWhitePattern>
        </Container>
      </BodyContent>
      <ConfirmModal />
    </Layout>
  );
};

const FormBlock = styled(Form)`
  display: flex;
  .clientForm-left {
    flex: 0 0 30%;
    padding-right: 30px;
  }
  .clientForm-right {
    border-left: solid 1px ${COLORS.C4_BORDER};
    flex: 0 0 70%;
    padding-left: 30px;
    & > div {
      margin-bottom: 10px;
      &:first-child {
        margin-bottom: 0px;
      }
    }
  }
`;

const DropDownRow = styled.div`
  width: 100%;
  margin-bottom: 30px;
`;
