import React, { useState } from "react";
import styled from "styled-components";
import { H5, Paragraph } from "../../../Common/Typography";
import COLORS from "../../../../assets/colors";
import { Images } from "../../../../assets/images";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import PopHover from "../../../Common/PopModal/PopHover";

export default ({ data }) => {
  // console.log(data);
  // console.log("industry col");
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <SkillColumn>
      <H5>
        Industry proficiency{" "}
        <InfoOutlinedIcon
          aria-owns={!!anchorEl ? "simple-popover" : undefined}
          aria-haspopup="true"
          onMouseEnter={(e) => setAnchorEl(e.currentTarget)}
          onMouseLeave={() => setAnchorEl(null)}
        />
      </H5>
      <ul>
        {data?.map((el, i) => (
          <li key={i} title={el.skillName}>
            <span>{el?.level?.level}</span>
            <img
              src={
                !!el.level?.level
                  ? Images.icons.tickIcon
                  : Images.icons.cancelIcon
              }
              alt="level"
            />
          </li>
        ))}
      </ul>
      <PopHover anchorEl={anchorEl} hoverType={true}>
        <InfoContent>
          <Paragraph>
            This column specifies the proficiency level for a skill as suggested
            by industry frameworks. Use this as a reference to select
            recommended target proficiency for a skill
          </Paragraph>
        </InfoContent>
      </PopHover>
    </SkillColumn>
  );
};

const SkillColumn = styled.div`
  width: 180px;
  h5 {
    position: relative;
    svg {
      cursor: pointer;
      color: ${COLORS.DARK_GREY};
      font-size: 20px;
      position: absolute;
      top: 2px;
      margin-left: 5px;
    }
  }
  ul {
    li {
      border: solid 1px ${COLORS.ASH_BORDER};
      border-radius: 4px;
      color: ${COLORS.TITLE_BLUE};
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      font-weight: 600;
      span {
        font-size: 12px;
        font-weight: 400;
      }
    }
  }
`;

const InfoContent = styled.div`
  padding: 15px;
  width: 350px;
  p {
    font-size: 14px;
    line-height: 20px;
    padding-bottom: 0;
  }
`;
