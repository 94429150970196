import { AIGeneratorType } from "../enums/AIGeneratorType";
import { RoleServices } from "../services/RoleServices";
import { SkillService } from "../services/SkillService";

// Mapping between popup keys and their associated properties
export const skillGeneratorMapping = {
    showKnowledgeBasePopUp: {
      title: "Knowledge Base",
      type: AIGeneratorType.TAG,
      serviceUrl: SkillService.GetAiSkillWikiTagsV3,
      confirmText: "Add To List",
    },
    showSkillShortDescriptionPopUp: {
      title: "Skill Description",
      type: AIGeneratorType.DEFAULT,
      serviceUrl: SkillService.GetAiSkillShortDescriptionSuggestionV3,
      confirmText: "Add to Main Screen",
    },
    showSkillDetailedDescriptionsPopUp: {
      title: "Detailed Description",
      type: AIGeneratorType.DEFAULT,
      serviceUrl: SkillService.GetAiSkillDetailedDescriptionSuggestionV3,
      confirmText: "Add to Main Screen",
    }
  };

export const roleGeneratorMapping = {
  showRoleDescriptionAiSuggestion: {
    title: "Role Description",
    type: AIGeneratorType.DEFAULT,
    serviceUrl: RoleServices.GetAiRoleDescriptionSuggestionV3,
    confirmText: "Add to Main Screen"
  },
  showWorkFunctionAndTasksAiSuggestion: {
    title: "Critical Work Functions and Tasks",
    type: AIGeneratorType.ROLES,
    serviceUrl: RoleServices.GetAiWorkFunctionsAndSuggestionV3,
    confirmText: "Add to Main Screen"
  }
}