import React, { createContext, useState, useMemo, useEffect } from "react";
import { SortString } from "../helpers/SortString";
import useRoles from "../Hooks/useRoles";
import { AuthService } from "../services/AuthService";

export const MasterFunctionsListContext = createContext();

export default function MasterFunctionsProvider({
  children,
  passClientId,
  subSector = "Any",
}) {
  // console.log("props", subSector);
  const { clientName } = useRoles();
  const [functionsList, setFunctionsList] = useState(null);

  useEffect(() => {
    const getFunctionsList = async () => {
      try {
        const res = await AuthService.functionsListV3(
          subSector !== "Any" ? `?subSectorIds=${subSector}` : "",
          passClientId
            ? `${subSector !== "Any" ? "&" : "?"}clientId=${clientName}`
            : ``
        );
        const sorted = await SortString(res, "functionName");
        // console.log(
        //   "getFunctionsList -> sorted",
        //   sorted,
        //   clientName,
        //   !!clientName
        // );
        await sorted.unshift({
          functionName: "Any",
          functionId: "Any",
        });
        setFunctionsList(sorted);
      } catch (error) {
        console.log(error);
      }
    };
    console.log(clientName);
    !!clientName && getFunctionsList();
    return () => {
      setFunctionsList(null);
    };
  }, [subSector, clientName]);

  const value = useMemo(() => {
    return {
      functionsList,
      setFunctionsList,
    };
  }, [functionsList, setFunctionsList]);
  return (
    <MasterFunctionsListContext.Provider value={value}>
      {children}
    </MasterFunctionsListContext.Provider>
  );
}
