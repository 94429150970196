import React from 'react'
import { TableColumnsAssignSkills } from '../TableColumnAssignSkills'
import COLORS from '../../../../assets/colors'
import CustomizedTables from '../../../Common/CustomizedTables'
import { H5 } from '../../../Common/Typography'
import { PrimaryButton } from '../../../Common/Buttons/PrimaryButton'
import PaginationCustom from '../../../Common/PaginationCustom'
import text from '../../../../locale.en.json';

const SearchSkillTable = ({
    skillList,
    skillType,
    isNavigatedFromRoleMatch,
    handleCheckBox,
    handleCheckAll,
    handleSkillPopup,
    levelChange,
    triggerApi,
    skillListCopy,
    noSkillMessage,
    handleSelectClick,
    skillSelectedContainer,
    listTotal,
    handlePagination,
    rowCount,
    changeRowCount,
    currPage
}) => {
  return (
    <>
        {!!skillList && !!skillList.length ? (
        <CustomizedTables
          columns={TableColumnsAssignSkills({
            skillType: skillType,
            isNavigatedFromRoleMatch: isNavigatedFromRoleMatch,
            isIndustryRoleTable: false
          })}
          rowList={!!skillList ? skillList : []}
          stickyHeader={skillList?.length > 10}
          tableHeight={skillList?.length > 10 ? 488 : "auto"}
          handleCheckBox={handleCheckBox}
          handleCheckAll={handleCheckAll}
          innerTabel={true}
          rowClick={handleSkillPopup}
          levelChange={levelChange}
          triggerApi={triggerApi}
        />
      ) : (
        <>
          {!!skillList && !!skillListCopy && skillListCopy?.length === 0 ? (
            <H5
              style={{
                color: COLORS.BAR_RED,
                padding: 20,
                textAlign: "center",
              }}
            >
             {noSkillMessage}
            </H5>
          ) : null}
        </>
      )}
      <div className="search-input-bottom-block">
        {!!skillList?.length && (
          <PrimaryButton
            style={{ marginTop: 20 }}
            onClick={handleSelectClick}
            disabled={skillSelectedContainer?.length === 0}
          >
            {text.skill.selectSkills}
          </PrimaryButton>
        )}

        {!!listTotal ? (
          <PaginationCustom
            pageTotal={listTotal}
            handlePagination={handlePagination}
            countRows={rowCount}
            changeRowCount={changeRowCount}
            changeCurrentPage={currPage}
          />
        ) : null}
      </div>
    </>
  )
}

export default SearchSkillTable