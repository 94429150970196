export const AccordArrays = (arr = [], titleSplit = "$", contentSplit = "|") =>
  arr
    .trim()
    .split(titleSplit)
    .filter((el) => !!el)
    .map((el) => ({
      title: el?.trim().split(contentSplit)[0].trim(),
      content: el
        ?.trim()
        .split(contentSplit)
        .slice(1)
        .map((el) => el.trim()),
    }));
