import React, { useState, useEffect, } from "react";
import {
  Skill_Selection_Table,
} from "./SkillSelectionContentTable";
import CustomizedTables from "../../Common/CustomizedTables";
import ModalPopup from "../../Common/ModalPopup";
import ConfirmationButton from "../../Common/ConfirmationButton";
import styled from "styled-components";
import COLORS from "../../../assets/colors";
import { ButtonBlock } from "../../Common/Buttons/ButtonBlock";
import PaginationCustom from "../../Common/PaginationCustom";
import { SkillService } from "../../../services/SkillService";
import useRoles from "../../../Hooks/useRoles";
import { skillMappingStore } from "../../../store/store_state";
import useAlert from "../../../Hooks/useAlert";
import { PrimaryButton } from "../../Common/Buttons/PrimaryButton";
import { SecondaryButton } from "../../Common/Buttons/SecondaryButton";
import { SkillMappingType } from "../../../enums/SkillMappingType";
import useFullLoader from "../../../Hooks/useFullLoader";
import { SourceType } from "../../../enums/SourceType";
import { SortString, sortByDisableRow } from "../../../helpers/SortString";
import { FilterTableRow } from "../../../helpers/FilterTableRow";
import { AuthService } from "../../../services/AuthService";
import text from "../../../locale.en.json";

const SkillSelection = ({
  isSkillMappingInProgress, 
  clientSkillSelectionData, 
  id, 
  handlePartialSave,
  triggerGetIndustryRole,
  isNavigatedFromRoleMatch,
  allSkillIds,
  handleSkillPopup = () => {},
  getAssignSkillsCombinedData
}) => {
  const {clientName} = useRoles();
  const {showAlert} = useAlert();
  const {setFullLoader} = useFullLoader();
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [confirmTitle, setConfirmTitle] = useState("");
  const [confirmType, setConfirmType] = useState(null);
  const [currentItem, setCurrentItem] = useState(null);
  const [proceedConfirmModal, setProceedConfirmModal] = useState(false);
  const [skillSuggestionData, setSkillSuggestionData] = useState([]);
  const [skillSuggestionDataCopy, setSkillSuggestionDataCopy] = useState([]);
  const skillArrayOriginData = skillMappingStore.useState((s) => s.skillMatchData);
  const [contentLoading, setContentLoading] = useState(false);
  const [pageOffset, setPageOffset] = useState(0);
  const [rowCount, setrowCount] = useState(10);
  const [currPage, setCurrPage] = useState(0);
  const [isSkillFiltered, setIsSkillFiltered] = useState(false);

  useEffect(() => {
    if(clientSkillSelectionData.length  > 0 && isSkillMappingInProgress){
      setSkillSuggestionData([clientSkillSelectionData]);
      setSkillSuggestionDataCopy([clientSkillSelectionData]);
      getSkillSelectionData(10, 0);
      setCurrPage(1);
      setrowCount(10);
      setPageOffset(0);
    }else{
      setSkillSuggestionData([]);
      setSkillSuggestionDataCopy([]);
    }
  },[clientSkillSelectionData, isSkillMappingInProgress]);
  
  const handlePagination = (e, page) => {
    setCurrPage(page)
    setPageOffset((page - 1) * rowCount);
  };
  const changeRowCount = (count) => {setrowCount(count); setPageOffset(0); setCurrPage(1)};

  const getSkillSelectionData = (rowCountX, pageOffsetX) => {
    let selectionData = skillArrayOriginData.slice(
      pageOffsetX,
      rowCountX + pageOffsetX
    );
    setSkillSuggestionData(selectionData);
    setSkillSuggestionDataCopy(selectionData);
    setContentLoading(false);
  };

  useEffect(() => {
    if (skillArrayOriginData?.length > 0) {
      getSkillSelectionData(rowCount, pageOffset);
    }
  }, [pageOffset, rowCount]);

  const updateOriginSkillSelectionDataCopy = (updatedData) => {
    const updatedSkillSelectionOriginData = [...skillArrayOriginData].map(
      (data) => {
        const dataToUpdate = updatedData?.find(
          (d) => d.clientId === data.clientId
        );
        return !!dataToUpdate ? { ...dataToUpdate } : { ...data };
      }
    );
    setSkillSuggestionDataCopy(updatedSkillSelectionOriginData);
  };

  const updateSkillData = (
    dataArray, 
    updateKey, 
    toToggle, 
    itemToBeUpdated,
    clientId, 
    selectedSkillId, 
    isSearchedSkill, 
    searchedSkilCategory, 
    searchedSkillDescription, 
    proficiencyId, 
    proficiencyLevel
  ) => {
    return [...dataArray].map((data) => {
      const isAlreadySelected = toToggle === true && data[updateKey] === itemToBeUpdated;
      return data?.clientId === clientId
        ? {
            ...data,
            [updateKey]: isAlreadySelected ? "" : itemToBeUpdated,
            ["selectedSkillId"]: !!selectedSkillId ? selectedSkillId : 0,
            ["isSearchedSkill"]: isSearchedSkill,
            ["skillCategory"]: searchedSkilCategory,
            ["skillDescription"]: searchedSkillDescription,
            ["proficiencyId"]: proficiencyId,
            ["proficiencyLevel"]: proficiencyLevel,
            ["selectedLevel"]: !!proficiencyLevel ? proficiencyLevel.level : "",
            ["disableLevelDrop"]: isAlreadySelected ? true : false,
          }
        : { ...data };
    });
  };

  const updateSkillSelectionData = (
    updateKey,
    itemToBeUpdated,
    clientId,
    toToggle,
    selectedSkillId,
    isSearchedSkill = true,
    searchedSkilCategory = "",
    searchedSkillDescription = "",
    proficiencyId = 0,
    proficiencyLevel = null
  ) => {
    const commonParams = [updateKey, toToggle, itemToBeUpdated, clientId, selectedSkillId, isSearchedSkill, searchedSkilCategory, searchedSkillDescription, proficiencyId, proficiencyLevel];
    const updatedSkillSelectionOriginData = updateSkillData(skillArrayOriginData, ...commonParams) || [];
    const updatedSkillSelectionSelectionData = updateSkillData(skillSuggestionData, ...commonParams) || [];
    const updatedSkillSelectionSelectionDataCopy = updateSkillData(skillSuggestionDataCopy, ...commonParams) || [];

    skillMappingStore.update((s) => {s.skillMatchData = updatedSkillSelectionOriginData});
    setSkillSuggestionData(updatedSkillSelectionSelectionData);
    setSkillSuggestionDataCopy(updatedSkillSelectionSelectionDataCopy);
    updateOriginSkillSelectionDataCopy(updatedSkillSelectionOriginData);
  };

  const toggleChange = (key, id, colId) => {
    updateSkillSelectionData("selectedSkill", key, id, true, colId);
  }

  const ConfirmModal = () => (
    <ModalPopup
      open={confirmPopup}
      maxWidth="sm"
      title={confirmTitle}
      center={true}
    >
      <ConfirmationButton yesClick={handleYes} noClick={handleNo} yesText="Proceed" noText="Cancel" />
    </ModalPopup>
  );

  const deleteTrigger = (e, item) => {
    e.stopPropagation();
    setConfirmType("delete");
    setConfirmTitle(`Are you sure you want to delete ${item?.clientSkill}?`);
    setConfirmPopup(true);
    setCurrentItem(item);
  };

  const addSkillTrigger = () => {
    if (!isLevelSelectedForAllSkills()) {
      showAlert(text.skill.selectLevelForChoosenSkills, "info", 1500);
      return;
    } 
    setConfirmTitle(`Selected #${selectedSkillData?.length} Skill${selectedSkillData?.length > 1 ? "s" : ""} will be mapped to Skill List`);
    setConfirmType("add skill");
    setConfirmPopup(true);
  }

    const handleYes = () => {
      if (confirmType === "delete") {
        const updatedSkillSelectionOriginData = skillArrayOriginData?.filter(
          (d) => d.clientId !== currentItem?.clientId
        );
        let skillSelectionData = updatedSkillSelectionOriginData?.slice(
          pageOffset,
          rowCount + pageOffset
        );
       skillMappingStore.update((s) => {s.skillMatchData = updatedSkillSelectionOriginData});
        setSkillSuggestionData(skillSelectionData);
        setSkillSuggestionDataCopy(skillSelectionData);
        closePopup();
        setIsSkillFiltered(false);
      }
      if(confirmType === "add skill"){
        addSkillsToSkillList();
      }
    };

  const handleNo = () => closePopup();
  const closePopup = () => {
    setConfirmPopup(false);
    setConfirmType(null);
    setConfirmTitle("");
    setCurrentItem(null);
  };

  const triggerSearchApi = async (searchString, reason) => {
    const searchData = {
      searchString: searchString,
      searchInDescription: false,
      searchAcrossFields: false,
      skillCateogryId: [],
      skillFamilyId: [],
      skillSourceId: [SourceType.DISPRZ_ATOMIC_SKILLS],
      skillStatus: [],
      screenSource: 0,
      excludeSkills: [...allSkillIds]
    };
    const res = await SkillService.SearchSkillV3(searchData);
    const filterResult = res?.skills || [];
    return filterResult;
  };

  const triggerApi = (id, row) =>{
    if(row?.selectedSkillId)
     return SkillService.getSkillTypesArrayV3(row?.selectedSkillId, "proficiencies");
  }

  const selectedValue = (item, clientId, isSearchedSkill) => {
    updateSkillSelectionData("selectedSkill", item?.skillName, clientId, false, item?.skillId, isSearchedSkill, item?.skillCategory, item?.skillDescription);
  };

  const levelChange = (item, row, reason) => {
     updateSkillSelectionData("selectedSkill", row?.selectedSkill, row?.clientId, false, row?.selectedSkillId, row?.isSearchedSkill, row?.skillCategory, row?.skillDescription, item?.proficiencyId, { level: item?.level || "", levelId: item?.levelId || 0, levelNumber: item?.levelNumber || 0 });
  };

  const selectedSkillData = skillArrayOriginData?.filter(
    (r) => r?.selectedSkill && !r?.disableRow
  );

  const isLevelSelectedForAllSkills = () => {
    return selectedSkillData?.every((skill) => !!skill?.proficiencyId)
  }

  const handleSave = () => {
      handlePartialSave({isPartialSaveClientSkill: true , isAddSkillTrigger: false})
  }

  const addSkillsToSkillList = async () => {
      setConfirmPopup(false);
      setFullLoader(true);
      const dataToSave = getAssignSkillsCombinedData();
      selectedSkillData.forEach((item) => {
        if (!item.disableRow) {
          dataToSave.skillIdsToRemoveFromPersona.push(item?.selectedSkillId);
          dataToSave.skillProficiencyMappings.push({
            proficiencyId: item.proficiencyId,
            skillAddedFrom: SkillMappingType.SKILL_MATCH,
            skillCriticality: ""
          })
        }
      });
      setProceedConfirmModal(false);
      try {
        await handlePartialSave({isPartialSaveClientSkill: true, isAddSkillTrigger: isNavigatedFromRoleMatch, isSaveClientSkill: isNavigatedFromRoleMatch});
        if(!isNavigatedFromRoleMatch){
          await AuthService.updatePersonaSkillV3(id, dataToSave);
          await triggerGetIndustryRole();
        }
        setFullLoader(false);
        showAlert("Skill Mapping was successful", "success", 1500);
      } catch (error) {
        console.log(error);
        setFullLoader(false);
        showAlert(error, "error", 2000);
      }
    };

  const handleSort = (sortValue, sortBy) => {
    const updatedSkillArrayOriginData = SortString([...skillArrayOriginData], sortValue, sortBy);
    sortByDisableRow(updatedSkillArrayOriginData);
    skillMappingStore.update((s) => {
      s.skillMatchData = updatedSkillArrayOriginData;
    });
    let updatedSkillSuggestionData = updatedSkillArrayOriginData.slice(
      pageOffset,
      rowCount + pageOffset
    );
    setSkillSuggestionData(updatedSkillSuggestionData);
    setSkillSuggestionDataCopy(updatedSkillSuggestionData);
  };

  const handleFilter = (item, reason, colKey) => {
    FilterTableRow(item, reason, colKey, skillSuggestionDataCopy, setSkillSuggestionData)
    if( reason === 'clear') setIsSkillFiltered(false);
    else setIsSkillFiltered(true);
  }

  return (
    <SkillSelectionBlock>
      <>
        <div className="content-table">
          <CustomizedTables
            columns={Skill_Selection_Table}
            rowList={skillSuggestionData}
            rowListCopy={skillSuggestionDataCopy}
            toggleChange={toggleChange}
            deleteClick={deleteTrigger}
            triggerSearchApi={(val, reason) =>
              triggerSearchApi(val, reason)
            }
            selectedValue={selectedValue}
            triggerApi={triggerApi}
            levelChange={levelChange}
            theme="customizedTheme"
            noData={skillSuggestionData.length === 0}
            noDataToShow={skillSuggestionData.length === 0 ? "Import Client Skill Data" : ""}
            noDataTabelHeight={"245px"}
            handleSkillRolePopUp={(id, row) => handleSkillPopup(id, row)}
            defaultSortColumn="clientSkill"
            sortList={handleSort}
            filterChange={(item, reason, colKey, list) =>{
              handleFilter(item, reason, colKey)
            }}
            resetList={() => {
                setIsSkillFiltered(false);
                setSkillSuggestionData(skillSuggestionDataCopy)
            }}
            staticFilterList={skillArrayOriginData || []}
          />
        </div>
          {skillArrayOriginData?.length > 10 && !contentLoading && !isSkillFiltered  ? (
              <PaginationCustom
                pageTotal={skillArrayOriginData?.length}
                handlePagination={handlePagination}
                countRows={rowCount}
                changeRowCount={changeRowCount}
                changeCurrentPage={currPage}
              />
            ) : null
          }
          {isSkillMappingInProgress && <ButtonBlock 
            handlePartialSave={() => handleSave()} 
            handleAddToSkillList={() => {
              selectedSkillData.length !== 0
                ? addSkillTrigger()
                : showAlert("No skills are selected for mapping", "info", 1500)
            }} 
            disableSave={false} 
            disableAdd={false} 
          />}
          <ConfirmModal />
          {!!proceedConfirmModal && (
                <ModalPopup
                  open={proceedConfirmModal}
                  maxWidth="md"
                  closePopup={() => setProceedConfirmModal(false)}
                  title={`Selected #${selectedSkillData?.length} will be mapped to skill list`}
                  titleStyle={{ padding: "15px" }}
                >
                  <div className="btn-block" style={{ textAlign: "center" }}>
                    <SecondaryButton
                      onClick={() => setProceedConfirmModal(false)}
                      style={{ marginRight: 15 }}
                    >
                      Cancel
                    </SecondaryButton>

                    <PrimaryButton
                      type="submit"
                      onClick={() => addSkillsToSkillList()}
                    >
                      Proceed
                    </PrimaryButton>
                  </div>
                </ModalPopup>
              )}
      </>
    </SkillSelectionBlock>
  );
};

export default SkillSelection;

const SkillSelectionBlock = styled.div`
  .content-table {
    position: relative;
    > div:first-child {
      overflow-x: auto !important;
      &::-webkit-scrollbar {
        height: 8px;
      }
      &::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.2);
        border-radius: 6px;
      }
    }
    .disabled{
      opacity: 1 !important;
      td{
        opacity: 0.6 !important;
        .triggerDropSearch, .delete-icon{
           visibility: hidden;
        } 
        .triggerDrop{
          border: none;
          .MuiAutocomplete-endAdornment{
            visibility: hidden;
          }
        }
      }
    }
  }
  .btn-block {
    margin: 0px;
    padding-top: 20px;
    background-color: ${COLORS.BODY_ASH};
    text-align: center;
  }
`;
