import React, { useRef, useEffect, useState } from "react";
import * as d3 from "d3";
import Sunburst from "sunburst-chart";
import styled from "styled-components";

import Layout from "../Layout";
import { H2 } from "../Common/Typography";
import { BodyContent, Container } from "../Common/GridSystem";
import useFullLoader from "../../Hooks/useFullLoader";
import useAuth from "../../Hooks/useAuth";
import SelectMenu from "../Common/FormElements/SelectMenu";
import { RoleServices } from "../../services/RoleServices";
import { firstLevelLegendColors, secondLevelDepartmentLegendColors, secondLevelTrackLegendColors, thirdLevelLegendColors, finalLevelLegendColors } from "./../utils";
import { SubSectorType } from "../../enums/SubSectorType";
import { DataNodeLevel } from "../../enums/DataNodeLevel";

var sequenceArray = [];
var currentSubsectorType = 0;

export default function SunBurstRoleExplorer() {
  const myChart = Sunburst();
  const sunburstRef = useRef();

  const { setFullLoader } = useFullLoader();
  const { userDetails } = useAuth();
  const { userId } = userDetails;

  const [selectData, setSelectData] = useState([]);
  const [roleExplorerData, setRoleExplorerData] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState(0);
  const [currentNode, setCurrentNode] = useState(0);

  const getRoleData = async () => {
    setFullLoader(true);
    try {
      const res = await RoleServices.getRoleExplorerDataV3(`?userId=${userId}`);
      setFullLoader(false);
      res.sourceList = [{sourceId: -1, source: 'All'}].concat(res.sourceList?.filter((r) => {
          return res.children?.filter(c=> c.sourceId?.includes(r.sourceId))
      }));
      const defaultFilter = res?.sourceList?.some(s => s.sourceId === 5) ? 5 : -1;
      setRoleExplorerData(res);
      setSelectedFilter(defaultFilter);
      const updatedRoleExploredData = getUpdatedRoleExploredData(res, defaultFilter);
      madeSvg(updatedRoleExploredData);
    } catch (error) {
      console.log(error);
    } finally {
      setFullLoader(false);
    }
  };

  const getUpdatedRoleExploredData = (data, filter) => {
    let firstLevelFilter = data?.children?.filter(c => filter === -1 ? true : c.sourceId?.includes(filter));
    const finalLevelFilter = filter === -1 ? firstLevelFilter : firstLevelFilter.map(item => ({...item, children: item.children.filter(c => c.sourceId?.includes(filter)).map(item => ({...item, children: item.children?.filter(c => c.sourceId?.includes(filter))}))}));
    return {
        children: finalLevelFilter,
        name: data?.name,
        sourceList: data?.sourceList
    }
  };

  const handleFilter = (filter) => {
      const updatedRoleExploredData = getUpdatedRoleExploredData(roleExplorerData, filter);
      setSelectedFilter(filter);
      setCurrentNode(0);
      const element = document.getElementsByClassName('sunburst-viz');
      if (element?.length > 0) {
          element[0].remove();
          sequenceArray = [];
          setSelectData(sequenceArray);
      }
      madeSvg(updatedRoleExploredData);
  };

  useEffect(() => {
    if (!!userId) {
      getRoleData();
    }
    return () => (sequenceArray = []);
  }, [userId]);

  function trimText(text, threshold) {
    if (text.length <= threshold) return text;
    return text.substr(0, threshold).concat("...");
  }

  const getAncestors = (node) => {
    var path = [];
    var current = node;
    if (!!node)
      while (current.parent) {
        path.unshift(current);
        current = current.parent;
      }
    return path;
  };

  const color = d3.scaleOrdinal(d3.quantize(d3.interpolateRainbow, 44));

  const madeSvg = (res) => {
    getAncestors();
    myChart
      .data(res)
      .showLabels(true)
      .label((d) => trimText(d.name, 25))
      .tooltipTitle((node) => node.name)
      .size("value")
      .color((d, parent) => {
          return d.__dataNode.depth === DataNodeLevel.LEVEL_ONE ? d.subsectorTypeId === SubSectorType.FUNCTIONS ? "#aa529b" : "#803eb0"
              : d.__dataNode.depth === DataNodeLevel.LEVEL_TWO ? parent.data.subsectorTypeId === SubSectorType.FUNCTIONS ? "#1ad6b1" : "#309fdb"
              : d.__dataNode.depth === DataNodeLevel.LEVEL_THREE ? "#6e40aa"
              : (d.color || color(parent ? parent.data.name : null))
      })
      .width("1200")
      .height("1200")
      .labelOrientation("radial")
      .sort((a, b) => {
        /*Compare subsectorTypeId for depth 1 dataNode
        If subsectorTypeId is different, sort by that*/
        if (a?.depth === DataNodeLevel.LEVEL_ONE && b?.depth === DataNodeLevel.LEVEL_ONE) {
          const subsectorTypeComparison = b?.data?.subsectorTypeId - a?.data?.subsectorTypeId;
          if(subsectorTypeComparison !== 0){
            return subsectorTypeComparison;
          }
        }
        /*If subsectorTypeId is the same or if the depth is not 1, sort by value*/
        return b?.value - a?.value;
      })
      .maxLevels(2)
      .centerRadius(0.25)
      .radiusScaleExponent(1)
      .minSliceAngle(0.1)
      .onClick(handleSelect)
      .excludeRoot(true)(sunburstRef.current);
  };

  const handleSelect = (node) => {
    if (!!node && node?.__dataNode.depth < 4) {
      const { depth } = node?.__dataNode || 0;
      const subType = currentSubsectorType;
      const isReverseClick = sequenceArray.some(
          (el) =>
          el.data.name.toLowerCase() === node.name.toLowerCase() &&
          el.depth === node.__dataNode.depth
      );
      const currentUpdatedNode = isReverseClick ? node?.__dataNode.depth - 1 : node?.__dataNode.depth;
      setCurrentNode(currentUpdatedNode || 0);
      if (isReverseClick) {
        //reverse click
        currentSubsectorType = node?.__dataNode?.parent?.data?.subsectorTypeId ? node?.__dataNode?.parent?.data?.subsectorTypeId : subType;
        const focusNode = sequenceArray[sequenceArray?.length - 1].parent;
        myChart
          .nodeClassName((d) =>
            depth > 1 &&
            d?.name.toLowerCase() === focusNode?.data.name.toLowerCase() &&
            d.__dataNode?.depth === focusNode.depth
              ? "hide"
              : ""
          )
          .label((d) => {
            return trimText(d.name, 25);
          })
          .centerRadius(depth > 1 ? 0 : 0.25)
          .maxLevels(depth > 1 && depth < 3 ? 3 : 2)
          .focusOnNode(focusNode.data);

        const filterArray = sequenceArray.slice(0, -1);

        sequenceArray = filterArray;
      } else {
        currentSubsectorType = node?.subsectorTypeId ? node?.subsectorTypeId : subType;
        const arrLength = sequenceArray?.length;
        myChart
          .nodeClassName((d) =>
            d.name.toLowerCase() === node.name.toLowerCase() &&
            d.__dataNode?.depth === node.__dataNode.depth
              ? "hide"
              : ""
          )
          .label((d) => {
            return trimText(d.name, arrLength < 2 ? 25 : 35);
          })
          .centerRadius(0)
          .maxLevels(depth < 2 ? 3 : 2)
          .focusOnNode(node);
        sequenceArray = getAncestors(node.__dataNode);
      }
    }
    setSelectData(sequenceArray);
  };

  const legendArray = currentNode === 0 ? firstLevelLegendColors :
                      currentNode === 1 ? currentSubsectorType === 1 ? secondLevelDepartmentLegendColors : secondLevelTrackLegendColors :
                      currentNode === 2 ? thirdLevelLegendColors :
                      currentNode === 3 ? finalLevelLegendColors :
                      [];

  return (
    <Layout>
      <H2>Role Explorer</H2>
      <BodyContent>
        <Container style={{ position: "relative" }}>
          <div id="main">
            <Header>
              <div className="dropDownInner">
                <SelectMenu
                    label="Source:"
                    value={selectedFilter}
                    list={
                        !!roleExplorerData?.sourceList
                        ? roleExplorerData?.sourceList.map((el, i) => ({
                        label: el.source,
                        value: el.sourceId,
                    }))
                        : []
                    }
                    handleSelect={handleFilter}
                />
              </div>
              <Legendry>
                  {legendArray?.map((el, i) => (
                      <li key={i} data-color={el.color}>
                        <span style={{ backgroundColor: `${el.color}` }}></span>
                          {el.type}
                      </li>
                  ))}
              </Legendry>
            </Header>
            {!!sunburstRef && (
              <div id="chart">
                <div ref={sunburstRef}>
                  <div className="chart-breadCrumb">
                    {sequenceArray?.map((el, i) => (
                      <div key={i}>{el?.data.name}</div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        </Container>
      </BodyContent>
    </Layout>
  );
}

const Legendry = styled.ul`
  display: inline-block;
  position: absolute;
  right: 0;
  li {
    position: relative;
    padding-left: 20px;
    span {
      border-radius: 5px;
      display: inline-block;
      position: absolute;
      left: 0px;
      top: 6px;
      width: 10px;
      height: 10px;
      opacity: 0.6;
    }
  }
`;

const Header = styled.ul`
  display: flex;
  .dropDownInner {
    .MuiFormControl-root {
      display: flex;
      flex-direction: row;
      .MuiFormLabel-root {
        position: relative;
        margin-top: 8px;
      }
      .MuiInputBase-root {
        margin: 0;
      }
    }
  }
`;
