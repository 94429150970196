import React from "react";
import { UserTypeMenu, UserTypeMenuNumber } from "../utils";

export default [
  {
    colKey: "name",
    colValue: "Display Name",
    sortable: true,
  },
  {
    colKey: "email",
    colValue: "Email ID",
    sortable: true,
  },
  {
    // colType: "inside_Function",
    colKey: "user_Type",
    colValue: "User Type",
    sortable: true,
    // getTableData: (val) => <>{val?.userType?.userType}</>,
  },
  {
    colKey: "gLogin",
    colValue: "Login Type",
    sortable: true,
    colType: "inside_Function",
    getTableData: (val) => <>{val?.ssoType?.ssoType}</>,
  },
  {
    colKey: "userClient",
    colValue: "Client Access",
  },
  {
    colKey: "more_action",
    colValue: "",
    width: 60,
  },
];
