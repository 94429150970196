import { FunSkillKey } from "../components/utils";

export const InitialUIStore = {
  isDarkMode: true,
  isMobile: true,
  selectPersonas: [],
  selectRoles: [],
  personaUpdate: false,
  architectUpdate: false,
  fromPersonaList: false,
  department: "All",
};

export const InitialComparePersonaStore = {
  userChoosenPersonas: [],
  skillList: {},
  skillArray: [],
  skillTabs: [FunSkillKey],
  importedPersonaDetails: null,
  initialSkillArray: [],
};

export const InitialSkillStore = {
  selectedSkills: [],
  skillAdded: false,
  skillsToBeAddedForPersona: [],
  sourceId: "",
};

export const InitialIndustryRoleListStore = {
  IndustryRoleList: [],
  subSectorsStoreList: null,
};

export const InitialSkillMappingStore = {
  importedSkillList: {},
  skillMatchData: [],
};

export const InitialExportToDisprzStore = {
  statusData: null,
};

export const InitialSubSecStore = {
  statusData: null,
};

export const InitialSelectedRoleSkillsStore = {
  selectedRoleSkills: {},
};

export const InitialAIStore = {
  //skill-store
  skillDetail: {},
  aiSuggestedEncycloLists: [],
  aiSuggestedSkillShortDescriptions: [],
  aiSuggestedSkillDetailedDescriptions: [],
  aiSuggestedProficienyDescription: [],
  aiSuggestedKnowledgeDescription: [],
  aiSuggestedlevelDescriptionCopy: {
    aiSuggestedProficienyDescription: [],
    aiSuggestedKnowledgeDescription: [],
  },

  //role-store
  roleDetail: {},
  aiSuggestedRoleDescription: [],
  aiSuggestedWorkFunctionAndTasks: [],
};

export const InitialIndustreRoleStore = {
  sourceId: "",
  storeRoleId: "",
};

export const InitialAssignSkillsStore = {
  roleSkillIds: [],
  roleSkillNames: [],
  selectedSearchedSkills: [],
};

export const InitialFileUploaderStore = {
  selectedFiles: [],
};
