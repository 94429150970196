import React from "react";
import styled from "styled-components";
import COLORS from "../../assets/colors";
import { Images } from "../../assets/images";

export default ({ children, ...props }) => (
  <LandinBlock {...props}>{children}</LandinBlock>
);

const LandinBlock = styled.div`
  background-image: linear-gradient(
    143deg,
    rgba(33, 48, 111, 0.89) 7%,
    rgba(50, 23, 90, 0.89) 70%
  );
  border-radius: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 15px;
  min-height: 254px;
  padding: 30px 20px 15px;
  text-align: center;
  min-width: 222px;
  position: relative;
  &:before {
    content: "";
    background: url(${Images.bgImgs.wavesBg}) center 50px no-repeat;
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    opacity: 0.07;
  }
  &.right-sharp {
    border-bottom-right-radius: 0px;
  }
  &.left-sharp {
    border-bottom-left-radius: 0px;
  }
  h5 {
    color: ${COLORS.PRIMARY_WHITE};
    padding-bottom: 25px;
  }
  .box-block {
    display: flex;
  }
`;
