import React, { memo } from "react";
import { Handle } from "react-flow-renderer";
import styled from "styled-components";
import COLORS from "../../../../assets/colors";

export default memo(
  ({
    data: { clusterName, occupation, department, visibleHandles, isMasterNode },
  }) => {
    return (
      <HandleNodeWrapper isMasterNode={isMasterNode}>
        <div className="occupation">{department}</div>
        <div className="clusterName">{clusterName}</div>

        {visibleHandles.indexOf("source_top") > -1 ? (
          <Handle
            style={{ opacity: 0 }}
            type="source"
            position="top"
            id="top"
          />
        ) : null}
        {visibleHandles.indexOf("source_right") > -1 ? (
          <Handle
            style={{ opacity: 0, top: 10, bottom: "auto" }}
            type="source"
            position="right"
            id="right"
          />
        ) : null}
        {visibleHandles.indexOf("source_left") > -1 ? (
          <Handle
            style={{ opacity: 0, top: 10, bottom: "auto" }}
            type="source"
            position="left"
            id="left"
          />
        ) : null}
        {visibleHandles.indexOf("source_bottom") > -1 ? (
          <Handle
            style={{ opacity: 0 }}
            type="source"
            position="bottom"
            id="bottom"
          />
        ) : null}

        {visibleHandles.indexOf("target_top") > -1 ? (
          <Handle
            style={{ opacity: 0 }}
            type="target"
            position="top"
            id="top"
          />
        ) : null}
        {visibleHandles.indexOf("target_right") > -1 ? (
          <Handle
            style={{ opacity: 0, bottom: 10, top: "auto" }}
            type="target"
            position="right"
            id="right"
          />
        ) : null}
        {visibleHandles.indexOf("target_left") > -1 ? (
          <Handle
            style={{ opacity: 0, bottom: 10, top: "auto" }}
            type="target"
            position="left"
            id="left"
          />
        ) : null}
        {visibleHandles.indexOf("target_bottom") > -1 ? (
          <Handle
            style={{ opacity: 0 }}
            type="target"
            position="bottom"
            id="bottom"
          />
        ) : null}
      </HandleNodeWrapper>
    );
  }
);

export const HandleNodeWrapper = styled.div`
  padding: 2px;
  color: ${COLORS.PRIMARY_BLACK};
  font-size: 16px;
  border-radius: 5px;
  background: ${(props) =>
    props.isMasterNode ? "#dde1fb" : COLORS.LIGHT_MERON};
  box-shadow: 0 2px 14px 0px #9b9a9a;
  width: 300px;
  cursor: pointer;
  text-align: center;
  /* transform: scale(0.6); */
  .clusterName {
    color: ${(props) => (props.isMasterNode ? "black" : "black")};
    padding: 15px 30px;
  }
  .occupation {
    background: ${(props) =>
      props.isMasterNode
        ? COLORS.PRIMARY_BLUE_GRADIENT
        : COLORS.PRIMARY_PURPLE_GRADIENT};
    color: ${(props) => (props.isMasterNode ? "white" : "white")};
    padding: 10px 30px;
    border-radius: 5px;
    font-weight: bold;
    word-break: break-word;
  }
`;
