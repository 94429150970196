import { useState } from "react";
import { FileUploaderStore } from "../../../../store/store_state";

const useDownloadJDModal = ({setError}) => {
  const selectedFiles = FileUploaderStore.useState((s) => s.selectedFiles);
  const [showDragAndDropFileModal, setShowDragAndDropFileModal] = useState(false);
  const [isInvalidTemplate, setIsInvalidTemplate] = useState(false);

  const handleReuploadClick = () => {
    setError("");
    setShowDragAndDropFileModal(true);
  }

  const closeDragAndDropFileModal = () => {
    setError("");
    FileUploaderStore.update((s) => {
      s.selectedFiles = []
    });
    setShowDragAndDropFileModal(false);
  }

  return { selectedFiles, showDragAndDropFileModal, handleReuploadClick, closeDragAndDropFileModal, isInvalidTemplate };
};

export default useDownloadJDModal;
