import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { makeStyles } from "@material-ui/core";
import COLORS from "../../../assets/colors";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default ({
  width,
  list,
  optionLabel,
  label = "",
  placeholder = "",
  optionChange = () => {},
  group = false,
  groupLabel = "",
  limitTags = 2,
  classSelector = "checkboxes-tags-demo",
  defaultValue,
  defaultList = []
}) => {
  const classes = useStyles();
  const getValue = () => {
    if (!defaultValue) return undefined;
    const sourceList = defaultList?.length > 0 ? defaultList : list;
    return sourceList?.filter((option) => defaultValue?.includes(option?.value));
  };
  
  return (
    <Autocomplete
      // open={true}
      limitTags={limitTags}
      multiple
      size="small"
      className={classes.autocomp}
      id={classSelector}
      options={list}
      disableCloseOnSelect
      getOptionLabel={(option) => option[optionLabel]}
      onChange={(event, newValue, reason) => {
        optionChange(newValue, reason);
      }}
      groupBy={!!group ? (option) => option[groupLabel] : undefined}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8, padding: 5 }}
            checked={selected}
          />
          {option[optionLabel]}
        </React.Fragment>
      )}
      style={{ width }}
      value={getValue()}
      getOptionSelected={(option, value) => option?.value === value?.value}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={label}
          placeholder={placeholder}
          InputLabelProps={{ shrink: true }}
          className={classes.root}
          inputProps={{
            ...params.inputProps,
          }}
        />
      )}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-multiline": {
      // borderRadius: "0px",
      padding: 0,
    },
    "& .MuiOutlinedInput-input": {
      color: COLORS.TEXT_BLUE,
      fontSize: 12,
      padding: 12,
    },
  },
  autocomp: {
    "& .MuiAutocomplete-inputRoot": {
      padding: 3,
    },
    "&  .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      color: COLORS.TITLE_BLUE,
      fontSize: 16,
      fontWeight: "bold",
      fontFamily: "inherit",
      whiteSpace: "nowrap",
      transform: `none`,
      position: "static",
      paddingBottom: 15,
    },
    "& .MuiOutlinedInput-root": {
      border: `solid 1px ${COLORS.ASH_BORDER}`,
    },
  },
}));
