import React from "react";
import styled from "styled-components";
import COLORS from "../../../assets/colors";

export default ({ children, ...props }) => (
  <ReactFlowWrapper {...props}>{children}</ReactFlowWrapper>
);

const ReactFlowWrapper = styled.div`
  height: 100vh;
  .react-flow__node {
    &-customnode {
      background-color: ${COLORS.PRIMARY_WHITE};
      padding: 0px;
      border-radius: 3px;
      width: 150px;
      font-size: 12px;
      color: #222;
      text-align: center;
      border-width: 1px;
      border-style: solid;
    }
  }
  .node-title {
    background-color: ${COLORS.LABEL_BLUE};
    color: ${COLORS.PRIMARY_WHITE};
    font-weight: bold;
    padding: 5px 10px;
  }
  .node-subtitle {
    background-color: ${COLORS.PRIMARY_WHITE};
    border-radius: 3px;
    padding: 5px 10px;
  }
`;
