import { Children } from "react";

/**
 * Component for rendering each item in a list using a render function.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {function} props.render - The render function that takes an item and index and returns JSX to render.
 * @param {Array} props.of - The array of items to be rendered.
 * @returns {Array} - An array of JSX elements representing each item rendered using the provided render function.
 */
const Each = ({render, items}) =>
  Children.toArray(items?.map((item, index) => render(item, index))); 

export default Each;