import React from "react";
import styled from "styled-components";
import COLORS from "../../../../assets/colors";

export default ({ children, ...props }) => (
  <CompareColumn {...props}>{children}</CompareColumn>
);

const CompareColumn = styled.div`
  display: flex;
  &:last-child,
  &:last-of-type {
    padding-bottom: 0px;
  }
  & > div {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    width: 250px;
    margin-right: 10px;
    text-align: center;
    padding: 15px;
    background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0)
    );
    position: relative;
    &:first-child {
      background-image: none;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      padding: 0px;
      width: 250px;
      text-align: left;
      h4 {
        padding-bottom: 12px;
        padding-top: 14px;
      }
      h6 {
        font-weight: normal;
        text-decoration: none;
        font-size: 14px;
        line-height: 1;
      }
    }
    &.skill-wrap {
      /* border-bottom: solid 1px ${COLORS.ASH_BORDER};
      margin-bottom: -1px; */
      display: flex;
      /* align-items: flex-end; */
      justify-content: center;
      position: relative;
      &:before {
        background-image: linear-gradient(
          to left,
          rgba(198, 202, 224, 0),
          #c6cae0 50%
        );
        content: "";
        height: 1px;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
      }
    }
    &.profi-col:nth-child(even) {
      background-color: ${COLORS.BODY_ASH};
      border-radius: 0;
    }
    &.profi-col {
      padding: 7px 15px 0px;
    }
    &.detail-col {
      padding: 15px 0px 0px 0px;
    }
    .skill-col {
      h6 {
        padding: 0px;
        text-align: left;
      }
    }
    &:last-child {
      margin-right: 0px;
    }
    label {
      text-align: center;
    }
    h4 {
      padding-bottom: 0px;
    }
    h5 {
      /* padding: 10px; */
    }
    h6 {
      cursor: pointer;
      display: block;
      font-weight: 700;
      padding-bottom: 0px;
      text-decoration: underline;
      padding-bottom: 10px;
      text-align: center;
    }
    .compare-label {
      background: ${COLORS.PRIMARY_PURPLE_GRADIENT};
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      position: absolute;
      top: -46px;
      left: 50%;
      transform: translateX(-50%);
      padding: 3px 10px;
      width: 150px;
    }
    .compare-reset {
      cursor: pointer;
      line-height: 1;
      margin-left: 5px;
    }
    .info {
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 16px;
      /* margin-bottom: 10px; */
      svg {
        cursor: pointer;
        font-weight: normal;
        width: 20px;
        height: 20px;
        margin-left: 5px;
      }
    }
  }
  &.info {
    align-items: center;
    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
      &:first-child {
        justify-content: flex-start;
      }
      svg {
        cursor: pointer;
        color: ${COLORS.DARK_GREY};
        font-size: 20px;
        margin-left: 5px;
      }
    }
  }
  .MuiInputBase-root {
    margin-bottom: 0px;
    text-align: left;
  }
  .MuiSelect-select:focus {
    background-color: ${COLORS.PRIMARY_WHITE};
  }
`;
