import React from "react";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { secondaryButtonStyles } from "../SecondaryButton";
import { primaryButtonStyles } from "../PrimaryButton";
import { Button } from "@material-ui/core";
import useButtonSelectAll from "./useButtonSelectAll";
import "./ButtonSelectAll.scss";
import MenuOption from "../../MenuOption";
import PopHover from "../../PopModal/PopHover";

export const ButtonSelectAll = ({
  dropdownOptions = [],
  label = "",
  isSecondaryButton = false,
  handleDropDownClick = () => {},
  handleButtonClick = () => {},
}) => {
  const { popHoverRef, handleClick, handleClose } = useButtonSelectAll();

  return (
    <div className="button-select-all-container">
      <div className="select-drop-down">
        <Button
          variant="contained"
          onClick={(e) => {
            handleClick(e);
            handleButtonClick();
          }}
          style={{
            marginRight: 10,
            ...(isSecondaryButton
              ? secondaryButtonStyles.root
              : primaryButtonStyles.root),
          }}
          endIcon={dropdownOptions?.length > 0 && <KeyboardArrowDownIcon />}
        >
          <span className={dropdownOptions?.length > 0 && "btn-text"}>
            {label}
          </span>
        </Button>

        {dropdownOptions?.length > 0 && (
          <PopHover 
            anchorEl={popHoverRef} 
            handleClose={handleClose}
            anchorOrigin={{vertical: "bottom", horizontal: "right"}}
            transformOrigin={{vertical: "top", horizontal: "right"}}
            styles={{top: 3}}
          >
            <div className="menu-pop-hover">
              {dropdownOptions?.map((option, index) => (
                <MenuOption
                  onClick={() => {
                    handleDropDownClick(option?.value);
                    handleClose();
                  }}
                >
                  {option?.label}
                </MenuOption>
              ))}
            </div>
          </PopHover>
        )}
      </div>
    </div>
  );
};