import React, { useEffect } from "react";
import styled from "styled-components";
import { navigate } from "@reach/router";
import { Formik, Form } from "formik";
import GoogleLogin from "react-google-login";
import { useSpring, animated } from "react-spring";
// import { Keyframes } from "react-spring/renderprops";
// import useInterval from "react-useinterval";

import FormTextField from "../Common/FormElements/FormTextField";
import { loginValidationSchema } from "../../validations/authValidation";
import { Common, User } from "../../store";
import useAlert from "../../Hooks/useAlert";
import useAuth from "../../Hooks/useAuth";

import COLORS from "../../assets/colors";
import { H1, Paragraph } from "../Common/Typography";
import { Container } from "../Common/GridSystem";
import { PrimaryButton } from "../Common/Buttons/PrimaryButton";
import { SecondaryButton } from "../Common/Buttons/SecondaryButton";
import { Images } from "./../../assets/images";
import { AuthService } from "../../services/AuthService";
import { SkillStatusMenu, SkillStatusMenuSME } from "../utils";
import useFullLoader from "../../Hooks/useFullLoader";

export default function Login(props) {
  const {
    setIsUserLoggedIn,
    setUserDetails,
    setSkillStatusMenuList,
  } = useAuth();
  const { showAlert } = useAlert();
  const { setFullLoader } = useFullLoader();

  console.log("props", props);

  const loginBoxAnimate = useSpring({
    marginLeft: 30,
    opacity: 0.95,
    from: { marginLeft: -100, opacity: 0.8 },
    config: { duration: 500 },
  });

  const logoAnimate = useSpring({
    transform: `scale(1)`,
    opacity: 1,
    from: { transform: `scale(0.5)`, opacity: 0 },
    config: { duration: 500 },
    reset: true,
  });

  useEffect(() => {
    User.clearAll();
    Common.clearAll();
  }, []);

  // useInterval(increaseCount, 1000, 5);

  const formSubmit = async (fieldValues) => {
    // console.log(fieldValues);
    setFullLoader(true);
    try {
      // let payload = { ...fielxdValues };
      const res = await AuthService.loginV3(fieldValues);
      const resModify = {
        ...res,
        User_Type: res.userType.userTypeId,
        userTypeName: res.userType.userType,
      };

      // console.log(res);
      User.setUserDetails(resModify);
      setUserDetails(resModify);
      setIsUserLoggedIn(true);
      showAlert(`Welcome ${resModify?.name}`, "success", 1500);

      if (!!props.location?.state?.redirectPath) {
        navigate(props.location?.state?.redirectPath);
      } else {
        navigate("/");
      }
      if (resModify.User_Type === 3) {
        setSkillStatusMenuList(SkillStatusMenuSME);
      } else {
        setSkillStatusMenuList(SkillStatusMenu);
      }
    } catch (error) {
      console.log(error);
      showAlert(error.error, "error", 1500);
    } finally {
      setFullLoader(false);
    }
  };

  const handleGoogleSignIn = async (payload) => {
    setFullLoader(true);
    try {
      const { tokenId } = payload;
      const res = await AuthService.accountLoginV3({ tokenId });
      // console.log(res);
      const resModify = {
        ...res,
        User_Type: res.userType.userTypeId,
        userTypeName: res.userType.userType,
      };
      User.setUserDetails(resModify);
      setUserDetails(resModify);
      setIsUserLoggedIn(true);
      showAlert(`Welcome ${resModify.name}`, "success", 1500);
      if (!!props.location?.state?.redirectPath) {
        navigate(props.location?.state?.redirectPath);
      } else {
        navigate("/");
      }

      if (resModify.User_Type === 3) {
        setSkillStatusMenuList(SkillStatusMenuSME);
      } else {
        setSkillStatusMenuList(SkillStatusMenu);
      }
    } catch (error) {
      // console.log("handleGoogleSignIn -> error", error);
      showAlert(error.error, "error", 1500);
    } finally {
      setFullLoader(false);
    }
  };

  const handleGoogleFailSignIn = (msg) => console.log(msg);

  return (
    <LoginWrapper>
      <Container>
        <img className="dotsIcon" src={Images.icons.dotsIcon} alt="dots" />
        <animated.div style={logoAnimate} className="logLogoIcon">
          <Paragraph>Powered by</Paragraph>
          <img src={Images.logo.logoSVG} alt="logo" />
        </animated.div>
        <animated.div style={loginBoxAnimate} className="login-boxing">
          <H1>Welcome to Skills Architect</H1>
          <img
            className="cubesIcon"
            src={Images.icons.cubesIcon}
            alt="cubesIcon"
          />
          <Formik
            initialValues={{
              loginId: "",
              password: "",
            }}
            validationSchema={loginValidationSchema}
            onSubmit={formSubmit}
          >
            <Form>
              <FormTextField
                placeholder="User Name"
                name="loginId"
                type="text"
                label="User Name"
              />
              <FormTextField
                placeholder=". . ."
                name="password"
                type="password"
                label="Password"
              />
              <ButtonBlock>
                <PrimaryButton type="submit" style={{ width: "100%" }}>
                  Login
                </PrimaryButton>
                <Paragraph>Or</Paragraph>
                <div className="social-button">
                  <SecondaryButton
                    className="google-btn-overlap"
                    id="googleButton"
                    style={{
                      borderColor: COLORS.BLUE,
                      backgroundColor: COLORS.BLUE,
                      color: "white",
                      width: "100%",
                    }}
                  >
                    <img
                      src={Images.icons.googleIcon}
                      alt="google"
                      style={{ marginRight: 10 }}
                    />
                    Google Login
                  </SecondaryButton>
                  <GoogleLogin
                    clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                    buttonText="Login"
                    onSuccess={(res) => handleGoogleSignIn(res)}
                    onFailure={(msg) => handleGoogleFailSignIn(msg)}
                    cookiePolicy={"single_host_origin"}
                    className="google-btn"
                  />
                </div>
              </ButtonBlock>
            </Form>
          </Formik>
        </animated.div>
      </Container>
    </LoginWrapper>
  );
}

const LoginWrapper = styled.div`
  background: url(${Images.bgImgs.meetingImg}) center no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  .dotsIcon {
    top: 60px;
    position: absolute;
    right: 180px;
  }
  .logLogoIcon {
    bottom: 45px;
    position: absolute;
    right: 45px;
    text-align: center;
    img {
      width: 100px;
    }
    p {
      color: ${COLORS.PRIMARY_WHITE};
      font-size: 12px;

      padding-bottom: 0px;
    }
  }
  .login-boxing {
    background: linear-gradient(167deg, #11213b 2%, #5f099f 94%);
    border-radius: 50px;
    border-bottom-right-radius: 0px;
    margin-left: 30px;
    opacity: 0.95;
    padding: 90px 54px;
    position: relative;
    width: 420px;
    .cubesIcon {
      bottom: 10px;
      position: absolute;
      right: -100px;
    }
    form > div {
      padding-bottom: 35px;
      &:last-child {
        padding-bottom: 0px;
      }
    }
    label {
      color: ${COLORS.PRIMARY_WHITE};
      font-size: 16px;
      font-weight: 600px;
    }
    .MuiInputBase-root * {
      /* background: red !important; */
    }
    fieldset {
      border: none;
      border-bottom: solid 1px #7576a8;
      border-radius: 0px;
      top: -6px;
    }
    .MuiOutlinedInput-input {
      color: #e2e3fd;
      -webkit-appearance: none;
    }
    h1 {
      color: white;
      padding-bottom: 30px;
      font-size: 24px;
      line-height: 30px;
      small {
        color: inherit;
        display: block;
        font-size: 20px;
        font-weight: normal;
      }
    }
  }
`;

const ButtonBlock = styled.div`
  padding: 20px 0px;
  text-align: center;
  flex-direction: column;
  button {
    border-radius: 22px;
    height: 44px;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 12px;
  }
  .social-button {
    position: relative;
    .google-btn {
      border-radius: 22px !important;
      position: absolute;
      left: 0;
      opacity: 0 !important;
      width: 100%;
    }
  }
  a {
    pointer-events: none;
  }
  p {
    font-size: 12px;
    padding-bottom: 10px;
  }
  a,
  p {
    color: #e2e3fd;
  }
`;
