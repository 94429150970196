import React, { memo, useEffect, useState, useRef, createRef } from "react";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import COLORS from "../../../../assets/colors";
import { AuthService } from "../../../../services/AuthService";
import { SkillService } from "../../../../services/SkillService";
import { SkillStore, UIStore, ComparePersonaStore, resetStore, SelectedRoleSkillsStore } from "../../../../store/store_state";
import { FunSkillKey, GroupByKey } from "../../../utils";
import { H4, H6 } from "../../../Common/Typography";
import CompareColumn from "./CompareColumn";
import { SortString } from "../../../../helpers/SortString";
import AutoSelectBox from "../../../Common/FormElements/AutoSelectBox";
import RecommendPoper from "../../ArchitectSkills/SkillContent/RecommendPoper";
import { PrimaryButton } from "../../../Common/Buttons/PrimaryButton";
import ModalPopup from "../../../Common/ModalPopup";
import ConfirmationButton from "../../../Common/ConfirmationButton";
import useFullLoader from "../../../../Hooks/useFullLoader";
import useAlert from "../../../../Hooks/useAlert";
import SkillUpdatePopup from "../../ArchitectSkills/SkillContent/SkillUpdatePopup";
import { LevelIdSet } from "../../../utils/LevelId";
import { SecondaryButton } from "../../../Common/Buttons/SecondaryButton";
import ToolBar from "../../../Common/ToolBar";
import ClearIcon from "@material-ui/icons/Clear";
import { navigate, useLocation } from "@reach/router";
import _ from "lodash";
import { RoleServices } from "../../../../services/RoleServices";
import useRoles from "../../../../Hooks/useRoles";
import { SkillMappingType } from "../../../../enums/SkillMappingType";
import { getOrDefault, isUserHasBulkImportAccess } from "../../../../helpers/CommonHelper";
import useAuth from "../../../../Hooks/useAuth";
import InfoOutlined from "@material-ui/icons/InfoOutlined";
import { SkillSourceType } from "../../../../enums/SkillSourceType";
import { InitialComparePersonaStore } from "../../../../store/initial_store_state";

export default memo(() => {
  const location = useLocation();
  const { state } = location;
  const isNavigatedFromRoleMatch = getOrDefault(state?.isNavigatedFromRoleMatch, false);
  const personasToBeCompared = getOrDefault(state?.personasToBeCompared, ['','','','']);
  const importTokenId = getOrDefault(state?.importTokenId, "");
  const selectPersonas = UIStore.useState((s) => isNavigatedFromRoleMatch ? personasToBeCompared :  s.selectPersonas);
  const { setFullLoader } = useFullLoader();
  const { showAlert } = useAlert();
  const { clientName } = useRoles();
  const { userDetails } = useAuth();
  const { User_Type } = userDetails;

  const skillList = ComparePersonaStore.useState((s) => s.skillList);
  const skillArray = ComparePersonaStore.useState((s) => s.skillArray);
  const initialSkillArray = ComparePersonaStore.useState((s) => s.initialSkillArray);
  const skillTabs = ComparePersonaStore.useState((s) => s.skillTabs);
  const [selectPersonasDummy, setselectPersonasDummy] = useState(isNavigatedFromRoleMatch ? personasToBeCompared  : []);
  const userChoosenPersonas = ComparePersonaStore.useState((s) => s.userChoosenPersonas);
  const skillsToBeAddedForPersona = SkillStore.useState((s) => s.skillsToBeAddedForPersona);
  const importedPersonaDetails = ComparePersonaStore.useState((s) => s.importedPersonaDetails);

  const [skillDetail, setSkillDetail] = useState(null);
  const [skillProficiency, setSkillProficiency] = useState(null);
  const [skillPopup, setSkillPopup] = useState(false);

  const department = UIStore.useState((s) => s.department);
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [confirmTitle, setConfirmTitle] = useState("");
  const [confirmType, setConfirmType] = useState(null);

  const personaUpdate = UIStore.useState((s) => s.personaUpdate);

  const selectedRoleSkills = SelectedRoleSkillsStore.useState((s) => s.selectedRoleSkills);

  const isUserValid = isUserHasBulkImportAccess(User_Type);
  const confirmSwitch = (type) => {
    switch (type) {
      case "save":
        return setTimeout(handleSave, 500);
      case "redirect":
        return setTimeout(redirectToAddSkill, 500);
    }
  }

  const getUpdatedSkillArray = () => {
    return [...skillArray].filter((item) => !!item.proficiencyId);
  }

  const getLevelClearedSkillsBasedOnIndustryRole = () => {
    return [...skillArray].filter((skill) => !skill.proficiencyId && skill.skillAddedFrom === SkillMappingType.INDUSTRY_ROLE)
  }

  const getUpdatedPersonas = (updatedSkillArray) => {
    return updatedSkillArray.reduce((result, skill) => {
      const { personaId } = skill;
      result[personaId] = result[personaId] || [];
      result[personaId].push({
        proficiencyId: skill.proficiencyId,
        skillCriticality: skill?.skillCriticality || '',
        skillAddedFrom: skill.skillAddedFrom,
        skillCategory: skill.skillCategory,
        proficiencyLevel: {
          level: skill.recommended_proficiency,
          levelId: skill?.proficiencyLevel?.levelId || 0,
          levelNumber: skill?.proficiencyLevel?.levelNumber || 0,
        },
        skillId: skill.skillId,
        skillName: skill.skillName,
      });
      return result;
    }, {});
  }

  const getUpdatedPersonasForIndustryRoleBasedSkills = (updatedSkillArrayForIndustryRoleBasedSkills) => {
    return updatedSkillArrayForIndustryRoleBasedSkills.reduce((result, skill) => {
      const { personaId } = skill;
      result[personaId] = result[personaId] || [];
      result[personaId].push(skill);
      return result;
    }, {});
  }

  const handleSave = async() => {
    setFullLoader(true);
    const updatedSkillArray = getUpdatedSkillArray();
    const updatedSkillArrayForIndustryRoleBasedSkills = getLevelClearedSkillsBasedOnIndustryRole();
    const PostGroup = GroupByKey(updatedSkillArray, "personaId");
    try {
      if(!isNavigatedFromRoleMatch){
        await updateSkillProficienciesSequentially(PostGroup);
      }
      else{
        const updatedPersonas = getUpdatedPersonas(updatedSkillArray);
        const updatedPersonasForLevelClearedIndustryRoleSkills = getUpdatedPersonasForIndustryRoleBasedSkills(updatedSkillArrayForIndustryRoleBasedSkills);
        await getAndUpdateClientRoleProficiencies(updatedPersonas, updatedPersonasForLevelClearedIndustryRoleSkills);
      }
      setFullLoader(false);
      showAlert("Skill Mapping for Persona successful", "success", 1500);
      const resetArray = updatedSkillArray?.map((element) => ({
        ...element,
        updated: false,
        oldLevel: element.recommended_proficiency,
      }));
      ComparePersonaStore.update((s) => {s.skillArray = resetArray});
      assignedSkills(resetArray);

    } catch (errors) {
      showAlert(errors, "error", 1500);
      console.log(errors);
    } finally {
      closePopup();
      setFullLoader(false);
    }
  }

  const initOpen = null;
  let rowRefs = useRef([]);
  rowRefs.current = !!skillList
    ? Object.keys(skillList).map(
        (ref, index) => (rowRefs.current[index] = createRef())
      )
    : rowRefs;

  const [expanded, setExpanded] = useState("panel" + initOpen);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  function updateSkillArray(personaSkill) {
    const mergeWithPrev = SortString(
      [...skillArray, ...personaSkill].map((el) => ({
        ...el,
        recommended_proficiency: el.proficiencyLevel?.level,
        levelId: el.proficiencyLevel?.levelId,
        category: el.skillCategory?.skillCategory,
        oldLevel: el.proficiencyLevel?.level,
        initialProficiencyId: el?.proficiencyId,
        initialProficiencyLevel: el?.proficiencyLevel
      })),
      "skillName"
    );
    const arrLength = selectPersonas.filter((el) => !!el).length;
    ComparePersonaStore.update((s) => { s.skillArray = mergeWithPrev; });
    assignedSkills(
      arrLength === 1
        ? mergeWithPrev.filter((el) => !!el && !!el.recommended_proficiency)
        : mergeWithPrev
    );
  }

  const getPersonaSkills = async (ids, type) => {
    try {
      if (type === "add" && skillsToBeAddedForPersona?.length === 0) {
        let newlySelectedPersonaSkills = {};
        let personaSkills = [];
        for (const id of ids){
          const res = await AuthService.getPersonaSkillsV3(id);
          personaSkills = [...personaSkills, ...res]
          newlySelectedPersonaSkills[id] = res?.map((skill) => ({
            proficiencyId: skill.proficiencyId,
            skillAddedFrom: skill.skillAddedFrom,
            skillCriticality: skill.skillCriticality
          }));
        }
        updateSkillArray(personaSkills);
        ComparePersonaStore.update((s) => { s.initialSkillArray = {...initialSkillArray, ...newlySelectedPersonaSkills} });
      } else {
        const mergeWithPrev = [...skillArray].filter(
          (skill) => !ids.includes(skill.personaId)
        );
        ComparePersonaStore.update((s) => {
          s.skillArray = mergeWithPrev;
          s.initialSkillArray = Object.fromEntries(
            Object.entries(initialSkillArray || {}).filter(
              ([key, value]) => !ids.includes(parseInt(key))
            )
          );
        })
        assignedSkills(mergeWithPrev);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const recommandChange = (item, persona, reason, columnIndex) => {
    
    const copyArray = [...skillArray].map((skill) =>
      skill.personaId === persona.personaId && skill.skillId === persona.skillId
        ? {
            ...skill,
            recommended_proficiency: !!item ? item.level : "",
            proficiencyId: !!item ? item.proficiencyId : "",
            updated:
              (reason === "select-option" &&
                persona.oldLevel !== item?.level) ||
              (reason === "clear" && persona.oldLevel !== "")
                ? true
                : false,
          }
        : { ...skill }
    );
    ComparePersonaStore.update((s) => {s.skillArray = copyArray})
    assignedSkills(copyArray);
  };

  const clearSkill = (data) => {
    const skillListToClear = [...skillArray]?.map((skill) => {
        if(skill?.skillId === data?.skillId){
          return {
            ...skill,
            recommended_proficiency: "",
            proficiencyId: "",
            updated: skill?.oldLevel !== "" && !skill?.updated
          }}
        else{
            return { ...skill }
        }}
    );
    ComparePersonaStore.update((s) => {s.skillArray = skillListToClear})
    assignedSkills(skillListToClear);
  };

  const triggerApi = (id) =>
    SkillService.getSkillTypesArrayV3(id, "proficiencies");

  const handleAddSkillPersona = (personaId, category, skillIndex, colIndex) => {
    const skillObj = skillList[category][skillIndex];
    const insertObj = {
      ...skillObj,
      recommended_proficiency: "",
      oldLevel: "",
      personaId: personaId,
      skillAddedFrom: SkillMappingType.ADD_SKILL
    };
    const arr = [...skillArray];
    ComparePersonaStore.update((s) => {s.skillArray = [...arr, insertObj]})
    assignedSkills([...arr, insertObj]);
  };

  const assignedSkills = (list) => {
    try {
      const arrPersona = list.map((el) => {
        return {
          ...el,
          col: selectPersonas
            .map((elem) =>
              list.find(
                (element) =>
                  elem === element.personaId &&
                  element.skillId === el.skillId &&
                  elem !== ""
              )
            )
            .map((item, index, arr) =>
              (!!item &&
                arr.findIndex(
                  (arrItem) =>
                    arrItem?.recommended_proficiency ===
                    item?.recommended_proficiency
                ) !== index) ||
              (!!item &&
                arr.findIndex(
                  (arrItem) =>
                    arrItem?.recommended_proficiency ===
                    item?.recommended_proficiency
                ) === index &&
                (arr[index + 1]?.recommended_proficiency ===
                  item?.recommended_proficiency ||
                  arr[index + 2]?.recommended_proficiency ===
                    item?.recommended_proficiency ||
                  arr[index + 3]?.recommended_proficiency ===
                    item?.recommended_proficiency))
                ? { sameLevel: true, ...item }
                : item
            ),
        };
      });

      var setObj = new Set(); // create key value pair from array of array
      var result = arrPersona.reduce((acc, item) => {
        if (!setObj.has(item.skillId)) {
          setObj.add(item.skillId, item);
          acc.push(item);
        }
        return acc;
      }, []);
      // .filter(
      //   (el) =>
      //     !el.col
      //       .filter((col) => !!col)
      //       .every(
      //         (elem) =>
      //           elem.recommended_proficiency === "" &&
      //           selectPersonas.filter((persona) => !!persona).length > 1
      //       )
      // );
      //converting back to array from mapobject

      const makeGroup = GroupByKey(result, "category");

      if(skillsToBeAddedForPersona?.length > 0){
        const updatedSkillList = { ...skillList };
        const updatedSkillTab = [...skillTabs];
        for (const category in makeGroup) {
            if(!updatedSkillTab.includes(category)){
              updatedSkillTab.push(category);
            }
            updatedSkillList[category] = makeGroup[category];
        }
        updatedSkillTab.sort();
        if(!!updatedSkillTab.includes(FunSkillKey)){
          const index = updatedSkillTab.indexOf(FunSkillKey);
          updatedSkillTab.splice(index, 1); 
          updatedSkillTab.unshift(FunSkillKey);
        }
        ComparePersonaStore.update((s) =>{
           s.skillList = {...updatedSkillList};
           s.skillTabs = updatedSkillTab;
        });
        SkillStore.update((s) => {s.skillsToBeAddedForPersona = []})
      }
      else{
        ComparePersonaStore.update((s) => {
          s.skillList =  {...makeGroup};
          s.skillTabs = [
            FunSkillKey,
            ...Object.keys(makeGroup)
              .filter((el) => el !== FunSkillKey)
              .sort()
              .map((item) => item),
          ]
        })

      }
      if(_.isEqual(selectPersonas, ['','','','']) && skillsToBeAddedForPersona?.length === 0){
        ComparePersonaStore.update((s) => {
          s.skillList = {};
          s.skillArray = [];
          s.skillTabs = [FunSkillKey];
        })
      }

      if(skillsToBeAddedForPersona?.length > 0){
        const updatedSkillList = { ...skillList };
        const updatedSkillTab = [...skillTabs];
        for (const category in makeGroup) {
            if(!updatedSkillTab.includes(category)){
              updatedSkillTab.push(category);
            }
            updatedSkillList[category] = makeGroup[category];
        }
        updatedSkillTab.sort();
        if(!!updatedSkillTab.includes(FunSkillKey)){
          const index = updatedSkillTab.indexOf(FunSkillKey);
          updatedSkillTab.splice(index, 1); 
          updatedSkillTab.unshift(FunSkillKey);
        }
        ComparePersonaStore.update((s) =>{
           s.skillList = {...updatedSkillList};
           s.skillTabs = updatedSkillTab;
        });
        SkillStore.update((s) => {s.skillsToBeAddedForPersona = []})
      }
      else{
        ComparePersonaStore.update((s) => {
          s.skillList =  {...makeGroup};
          s.skillTabs = [
            FunSkillKey,
            ...Object.keys(makeGroup)
              .filter((el) => el !== FunSkillKey)
              .sort()
              .map((item) => item),
          ]
        })

      }
      if(_.isEqual(selectPersonas, ['','','','']) && skillsToBeAddedForPersona?.length === 0){
        ComparePersonaStore.update((s) => {
          s.skillList = {};
          s.skillArray = [];
          s.skillTabs = [FunSkillKey];
        })
      }
      setselectPersonasDummy(selectPersonas);
    } catch (errors) {
      console.log(errors);
    }
  };
  const updateSkillProficienciesSequentially = async (postGroup) => {
    const personaIds = Object.keys(postGroup);
    for (const personaId of personaIds) {
      const skills = postGroup[personaId].map(({ proficiencyId, skillCriticality, skillAddedFrom }) => ({
        proficiencyId,
        skillCriticality,
        skillAddedFrom
      }));
      await AuthService.updatePersonaSkillV3(personaId, {skillProficiencyMappings: skills, skillIdsToRemoveFromPersona: [], ExcludedSkillIds: []});
    }
  }

  const isProficiencyConditionMet = (updatedProficiencies, existingProficiency) => {
      return (
          (updatedProficiencies.length === 0 ||
              !updatedProficiencies.some((proficiency) => proficiency.skillId === existingProficiency.skillId)) &&
          (existingProficiency.skillAddedFrom === SkillMappingType.SKILL_MATCH || existingProficiency.skillAddedFrom === SkillMappingType.INFERRED_SKILL)
      );
  };

  const updatedIndustryRoleSkillsForSelectedPersona = (data, levelClearedIndustryRoleSkills) => {
    const existingIndustryRoleSkills = data?.industryRoleSkills || [];

    const skillsToBeAdded = levelClearedIndustryRoleSkills
        .map(skill => selectedRoleSkills[data.personaName].find(s => skill.skillId === s.skillId))
        .filter(Boolean);

    return [...existingIndustryRoleSkills, ...skillsToBeAdded];
  };

  const getAndUpdateClientRoleProficiencies = async (updatedPersonas = {}, updatedPersonasForLevelClearedIndustryRoleSkills = {}) => {
    setFullLoader(true);
    try{
      const response = await RoleServices.getImportPersonaDetails(clientName);
      const updatedRoleSelectionDataToSave = response.map(data => ({
        ...data,
        proficiencies: personasToBeCompared.includes(data.personaName)
          ? getOrDefault(updatedPersonas[data?.personaName], [])
          : data.proficiencies,
        industryRoleSkills: personasToBeCompared.includes(data.personaName) && updatedPersonasForLevelClearedIndustryRoleSkills[data?.personaName]
          ? updatedIndustryRoleSkillsForSelectedPersona(data, updatedPersonasForLevelClearedIndustryRoleSkills[data?.personaName])
          : data?.industryRoleSkills
      }));
      await RoleServices.partialSaveClientRoles(updatedRoleSelectionDataToSave, clientName, importTokenId);
      setFullLoader(false);
    } catch(error) {
      showAlert(error, 'error', 2000);
      setFullLoader(false);
    } 
  }

  const handleYes = async () => {
    closePopup();
    confirmSwitch(confirmType);
  };

  const handleSkillPopup = async (item) => {
    try {
      const resDetail = await SkillService.getSkillDetailV3(
        "/" + item.skillId,
        "/?includeProficiencies=true"
      );
      setSkillDetail(resDetail);
      const resProficiency = await SkillService.getSkillTypesArrayV3(item.skillId, "proficiencies");

      setSkillProficiency(SortString(resProficiency, "levelNumber"));
      setSkillPopup(true);
    } catch (errors) {
      console.log(errors);
    } finally {
      setFullLoader(false);
    }
  };

  const closePopup = () => {
    setConfirmPopup(false);
    setSkillPopup(false);
    setConfirmTitle("");
    setConfirmType(null);
  };

  useEffect(() => {
    setExpanded("panel" + initOpen);
    return () => {
      setExpanded(null);
    };
  }, [initOpen]);

  const getProficienciesToBeDisplayed = async () => {
    const proficienciesToBeDisplayed = (importedPersonaDetails || [])
      .filter((item) => {
        const matchingPersona = personasToBeCompared.includes(item.personaName);
        return matchingPersona && item.proficiencies !== null;
      })
      .flatMap((item) => {
        return item.proficiencies.map((proficiency) => ({
          personaId: item.personaName,
          ...proficiency
        }));
      });
    return proficienciesToBeDisplayed;
  };
  

  useEffect(() => {
    const fetchData = async () => {
        try {
         const proficienciesToBeDisplayed = await getProficienciesToBeDisplayed();
         updateSkillArray(proficienciesToBeDisplayed);
        } catch (error) {
          console.log('error', error);
          showAlert(error, 'error', 2000);
        }
    };
  
    if(isNavigatedFromRoleMatch && !!importedPersonaDetails && skillsToBeAddedForPersona.length === 0 && skillArray.length === 0) {
      fetchData();
    }
  }, [isNavigatedFromRoleMatch, importedPersonaDetails]);

  useEffect(() => {
  if(skillsToBeAddedForPersona?.length > 0 && !_.isEqual(selectPersonas, ['','','',''])){
    UIStore.update((s) => {s.selectPersonas = userChoosenPersonas});
    const skillsToBeNewlyAdded = [...skillArray];
    skillsToBeAddedForPersona.forEach(skill => {
      const isNewSkill = !skillArray.some(skillInArray => skillInArray.skillId === skill.skillId);
      if(!!isNewSkill){
        let newSkill = {
          ...skill,
          category : skill.skillCategory.skillCategory,
          recommended_proficiency : "",
          proficiencyId : "",
          updated: false,
          oldLevel: "",
          levelId: "",
          personaId: "",
          col: [undefined, undefined, undefined, undefined]
        }
        skillsToBeNewlyAdded.push(newSkill);
      }
    });
    if(skillsToBeNewlyAdded?.length > 0){
      ComparePersonaStore.update((s) => {s.skillArray = [...skillsToBeNewlyAdded]})
      assignedSkills(skillsToBeNewlyAdded);
    }
  }
  }, [skillsToBeAddedForPersona, selectPersonas])

  useEffect(() => {
    if(userChoosenPersonas?.length > 0){
       setselectPersonasDummy(userChoosenPersonas);
    }
 },[userChoosenPersonas])

  useEffect(() => { 
    if (!isNavigatedFromRoleMatch){
      const personaIds = selectPersonas.filter(
        (el) => !selectPersonasDummy.includes(el) && typeof el === "number"
      );
      const removeIds = selectPersonasDummy.filter(el => !selectPersonas.includes(el));
      if (personaIds?.length > 0) {
        getPersonaSkills(personaIds, "add");
        setselectPersonasDummy(selectPersonas);
      } else if(removeIds?.length > 0){
        getPersonaSkills(removeIds, "remove");
        setselectPersonasDummy(selectPersonas);
      }
      return () => {
        // setSkillList([]);
      };
    }
  }, [selectPersonas]);

  useEffect(() => {
    if(skillsToBeAddedForPersona?.length === 0){
      UIStore.update((s) => {
        s.personaUpdate = [...skillArray].some((item) => !!item.updated);
      });
    }
    if(skillsToBeAddedForPersona?.length === 0){
      UIStore.update((s) => {
        s.personaUpdate = [...skillArray].some((item) => !!item.updated);
      });
    }
    return () => {
      // setSkillList([]);
    };
  }, [skillArray]);

  const navigateConfirmTrigger = (navUrl, state={}) => {
    setConfirmTitle("Proceed without Saving Changes?");
    setConfirmPopup(true);
    setConfirmType("redirect");
  };

  const redirectToAddSkill = () => {
    const targetUrl = '/add-skills/persona';
    const skillIdsToBeExcluded = isNavigatedFromRoleMatch ? [] :  skillArray?.map((skill) => skill?.skillId);
    if(!isNavigatedFromRoleMatch) {
      sessionStorage.setItem("department", JSON.stringify(department));
      sessionStorage.setItem("personasCompared", JSON.stringify(selectPersonas));
    }
    resetStore(ComparePersonaStore, InitialComparePersonaStore);
    navigate(targetUrl, {state: { 
      isNavigatedFromRoleMatch, 
      isNavigatedFromComparePersona: !isNavigatedFromRoleMatch, 
      skillIdsToBeExcluded, 
      isExcludeSkills: true, 
      personasSkillsCompared: initialSkillArray, 
      personasCompared: selectPersonas, 
      importTokenId
    }});
  }

  const handleAddSkill = () => {
    ComparePersonaStore.update((s) => {s.userChoosenPersonas = selectPersonas});
    personaUpdate ? navigateConfirmTrigger() : redirectToAddSkill();
  };

  const checkSkillsHasProficiencies = (skill) => (
    skillArray?.some(skillInArray => skillInArray?.skillId === skill?.skillId && !!skillInArray?.proficiencyId)
  )

  const shouldRenderClearSkills = (skill) => {
    if(isUserValid){
      return checkSkillsHasProficiencies(skill);
    }
    const isValidSource = skill?.col?.filter((skill) => !!skill).every((skill) => skill.skillAddedFrom !== SkillMappingType.SKILL_MATCH && skill.skillAddedFrom !== SkillMappingType.INFERRED_SKILL);
    return isValidSource ? checkSkillsHasProficiencies(skill) : false;
  }

  return (
    <AccordionList>
      {skillTabs.map((item, i) => (
        <Accordion
          square
          ref={rowRefs.current[i]}
          expanded={expanded === "panel" + i}
          onChange={handleChange("panel" + i)}
          key={i}
        >
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls={`panel${i}d-content`}
            id={`panel${i}d-header`}
            disabled={!skillList[item]?.length}
          >
            <H4>{item}</H4>
          </AccordionSummary>
          <AccordionDetails>
            {!!Object.keys(skillList) && !!Object.keys(skillList).length ? (
              <>
                {skillList[item]?.map((skill, index) => (
                  <CompareColumn key={index} row={true}>
                    <div className="skill-wrap clear">
                      <div className="skill-col" title={skill?.skillName} >
                        <H6 onClick={() => handleSkillPopup(skill)}>
                          {skill.skillName}
                        </H6>
                      </div>
                      {shouldRenderClearSkills(skill) && (
                        <div 
                          title="Remove skill from the chosen personas"
                          className="clear-skill"
                          onClick={() => clearSkill(skill)}
                        >
                          <ClearIcon className="clear-skill-icon" />
                        </div>
                      )}
                      {!isUserValid && !shouldRenderClearSkills(skill) && skill?.col?.filter((skill) => !!skill).some((skill) => !!skill?.proficiencyId) && (
                        <div 
                          title="No access to remove this skill for some personas due to its source (Skill Match or Inferred Skill)"
                          className="clear-skill"
                        >
                          <InfoOutlined className="clear-skill-icon" />
                        </div>
                      )}
                    </div>
                    {skill.col?.map((persona, idx) => (
                      <div key={idx} className={"profi-col "}>
                        {!!persona ? (
                          <div
                            className={
                              "col-box " +
                              (!!persona.recommended_proficiency
                                ? "bg" +
                                  LevelIdSet(persona.recommended_proficiency)
                                : "bg0") +
                              (!!persona?.sameLevel &&
                              persona.recommended_proficiency !== ""
                                ? " green-box "
                                : "") +
                              (!!persona.updated ? " active " : "")
                            }
                          >
                            <AutoSelectBox
                              width={"100%"}
                              optionChange={(item, reason) => {
                                return item?.level !==
                                  persona?.recommended_proficiency
                                  ? recommandChange(item, persona, reason, idx)
                                  : undefined;
                              }}
                              optionLabel="level"
                              valueLabel="levelId"
                              stateValue={persona.recommended_proficiency}
                              triggerApi={() => triggerApi(persona.skillId)}
                              PopperComponent={(props) => (
                                <RecommendPoper {...props} />
                              )}
                              renderOption={(option) => (
                                <OptionCutom>
                                  <span>{option.level}</span>
                                  <span>{option.description}</span>
                                </OptionCutom>
                              )}
                            />
                          </div>
                        ) : (
                          <>
                            {selectPersonas.map((dummy, dummyID) =>
                              dummy !== "" && dummyID === idx ? (
                                <div className="col-box red-box">
                                  <SecondaryButton
                                    style={{ height: "100%", border: "none" }}
                                    onClick={() =>
                                      handleAddSkillPersona(
                                        dummy,
                                        item,
                                        index,
                                        dummyID
                                      )
                                    }
                                  >
                                    <ToolBar
                                      title="Click to Add Skill to Persona"
                                      arrow={true}
                                    >
                                      <AddCircleOutlineIcon
                                        style={{ color: COLORS.BODY_BLUE }}
                                      />
                                    </ToolBar>
                                  </SecondaryButton>
                                </div>
                              ) : null
                            )}
                          </>
                        )}
                      </div>
                    ))}
                  </CompareColumn>
                ))}
              </>
            ) : null}
          </AccordionDetails>
        </Accordion>
      ))}
      <div className="btn-block">
        <SecondaryButton 
          className="add-skill" 
          disabled={_.isEqual(selectPersonas, ['','','',''])} 
          onClick={handleAddSkill} 
          style={{backgroundColor: !!Object.keys(skillList)?.length && `${COLORS.PRIMARY_WHITE}`}}
        >
            Add Skill +
        </SecondaryButton>
        <PrimaryButton
          onClick={() => {
            setConfirmTitle("Confirm Changes");
            setConfirmPopup(true);
            setConfirmType("save");
          }}
          disabled={
            [...skillArray].filter((item) => !!item.updated).length === 0 && skillsToBeAddedForPersona?.length === 0
          }
          className="save-changes"
        >
          Save
        </PrimaryButton>
      </div>
      <ModalPopup
        open={confirmPopup}
        maxWidth="sm"
        title={confirmTitle}
        center={true}
      >
        <ConfirmationButton yesClick={handleYes} noClick={closePopup} />
      </ModalPopup>
      <ModalPopup
        open={skillPopup}
        maxWidth="lg"
        closePopup={closePopup}
        title={!!skillDetail && skillDetail.skillName}
      >
        <SkillUpdatePopup
          data={skillDetail}
          listProficiency={skillProficiency}
          closePopup={closePopup}
          withCilentID={true}
          clientView
        />
      </ModalPopup>
    </AccordionList>
  );
});

const AccordionList = styled.div`
   background-color: ${COLORS.PRIMARY_WHITE};
  .btn-block {
    display: flex;
    justify-content: space-between;
    padding: 30px;
    .save-changes{
      margin: 0 auto;
      right: 38px;
    }
  }
  .accodion-icon {
    border: solid 1px ${COLORS.ASH_BORDER};
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
    height: 24px;
    width: 38px;
  }

  .skill-col,
  .profi-col {
    h6,
    .col-box {
      height: 40px;
      margin: 2px 0px;
    }
    h6 {
      display: flex;
      align-items: center;
    }
    .col-box {
      border: solid 1px ${COLORS.ASH_BORDER};
      border-radius: 5px;
      font-size: 12px;
      width: 100%;
      &.red-box {
        background-color: ${COLORS.PRIMARY_WHITE};
      }
      &.green-box {
        /* border-color: ${COLORS.GREEN};
        border-width: 2px; */
      }
      &.bg0 {
        background-color: ${COLORS.BLUE_BG0};
      }
      &.bg1 {
        background-color: ${COLORS.BLUE_BG1};
      }
      &.bg2 {
        background-color: ${COLORS.BLUE_BG2};
      }
      &.bg3 {
        background-color: ${COLORS.BLUE_BG3};
      }
      &.bg4 {
        background-color: ${COLORS.BLUE_BG4};
      }
      &.bg5 {
        background-color: ${COLORS.BLUE_BG5};
        .MuiOutlinedInput-input {
          color: ${COLORS.PRIMARY_WHITE};
        }
      }
      &.bg6 {
        background-color: ${COLORS.BLUE_BG6};
        .MuiOutlinedInput-input {
          color: ${COLORS.PRIMARY_WHITE};
        }
      }
      &.bg7 {
        background-color: ${COLORS.BLUE_BG7};
        .MuiOutlinedInput-input {
          color: ${COLORS.PRIMARY_WHITE};
        }
      }
      &.active {
        border: solid 2px ${COLORS.BLUE};
        box-shadow: 0 2px 4px 0 rgba(95, 130, 188, 0.26);
      }
    }
  }

  .skill-col{
    width: 100%;
    H6{
      width: calc(100% - 30px);
    }
  }

  .clear-skill {
    cursor: pointer;
    position: absolute;
    right: 0px;
    top: 14px;  
    .clear-skill-icon{
      font-size: 26px;
      padding: 4px;
      border-radius: 50%;
      :hover{
        background-color: #ECECEC;
      }
    }
  }
`;

const Accordion = withStyles({
  root: {
    // borderBottom: "1px solid #ccc !important",
    boxShadow: "none",
    marginBottom: "0px !important",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      height: 0,
    },
    "&$expanded": {
      margin: "auto",
    },
    "& .MuiAccordionSummary-root": {
      borderBottom: `solid 1px ${COLORS.ASH_BORDER}`,
      marginBottom: 0,
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "transparent",
    // borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    padding: `0px 28px`,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
    "& h4": {
      paddingBottom: 0,
    },
  },
  expandIcon: {
    border: `solid 1px ${COLORS.ASH_BORDER}`,
    borderRadius: 8,
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.16)",
    height: 24,
    marginRight: 0,
    width: 38,
    "&.Mui-expanded": {
      boxShadow: "none",
      opacity: 0.4,
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    flexDirection: "column",
    padding: "0 28px 10px",
  },
}))(MuiAccordionDetails);

const OptionCutom = styled.div`
  display: flex;
  font-size: 12px;
  width: 100%;
  span {
    padding: 10px;
    &:first-child {
      flex: 0 0 30%;
    }
    &:last-child {
      flex: 0 0 70%;
    }
  }
`;
