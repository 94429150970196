import React from "react";
import styled from "styled-components";
import COLORS from "../../assets/colors";
const { Box } = require("@material-ui/core");

export default (props) => {
  const { children, value, index, ...other } = props;

  return (
    <TabpanelCutom role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box p={3}>{children}</Box>}
    </TabpanelCutom>
  );
};

const TabpanelCutom = styled.div`
  background-color: ${COLORS.PRIMARY_WHITE};
  border-radius: 14px;
`;
