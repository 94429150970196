import React from "react";
import styled from "styled-components";
import COLORS from "../../../../assets/colors";
import { useSkillTableData } from "../../../../Provider/SkillTableProvider";
import { CheckHumanizerTime } from "../../../utils/DurationConvert";

export const Skill_Proficiencies_Table = [
  { colKey: "level", colValue: "Proficiency Level", width: 100 },
  { colKey: "description", colValue: "Description", width: 200 },
  { colKey: "ability", colValue: "Abilities", width: 200 },
  { colKey: "knowledge", colValue: "Knowledge", width: 200 },
];

export const Skill_Proficiencies_TableOnClient = [
  { colKey: "level", colValue: "Proficiency Level", width: 160 },
  { colKey: "description", colValue: "Description" },
];

export const Skill_Content_Table = [
  { colKey: "content_type", colValue: "Content Type" },
  { colKey: "title", colValue: "Title" },
  { colKey: "provider_name", colValue: "Provider Name" },
  { colKey: "proficiency", colValue: "Proficiency" },
  { colKey: "rating", colValue: "Rating" },
];

export const Skill_ContentItems_Table = [
  {
    colKey: "contentType",
    colValue: "Type",
    sortable: true,
    width: 150,
  },
  {
    colKey: "contentTitle",
    colValue: "Title",
    colType: "column_render",
    sortable: true,
    searchable: true,
    RenderColumn: (val) => {
      const {
        setContentDetailModal,
        setContentDetailData,
      } = useSkillTableData();

      const handleContentClick = () => {
        setContentDetailModal(true);
        setContentDetailData(val);
      };
      return (
        <LinkColumn onClick={handleContentClick}>{val.contentTitle}</LinkColumn>
      );
    },
  },
  {
    colKey: "contentProvider",
    colValue: "Provider",
    sortable: true,
    width: 180,
  },
  { colKey: "level", colValue: "Proficiency", sortable: true, width: 180 },
  {
    colKey: "contentProviderRating",
    colValue: "Rating",
    sortable: true,
    width: 150,
  },
  {
    colKey: "contentDuration",
    colValue: "Duration",
    width: 140,
    colType: "column_render",
    sortable: true,
    RenderColumn: (data) => {
      return (
        <div>
          {!!data?.contentDuration && data?.contentDuration > 0
            ? CheckHumanizerTime(data?.contentDuration * 1000)
            : 0}
        </div>
      );
    },
  },
  {
    colKey: "contentLanguage",
    colValue: "Language",
    sortable: true,
    width: 140,
  },
];

export const Skill_ContentItems_Table_Relevancy = [
  {
    colKey: "checkbox",
    colValue: "checkbox",
    width: 60,
  },
  {
    colKey: "contentType",
    colValue: "Type",
    width: 120,
    sortable: true,
  },
  {
    colKey: "contentTitle",
    colValue: "Title",
    sortable: true,
    searchable: true,
    width: 150,
    colType: "column_render",
    RenderColumn: (val) => {
      const {
        setContentDetailModal,
        setContentDetailData,
      } = useSkillTableData();

      const handleContentClick = () => {
        setContentDetailModal(true);
        setContentDetailData(val);
      };
      return (
        <LinkColumn onClick={handleContentClick}>{val.contentTitle}</LinkColumn>
      );
    },
  },
  {
    colKey: "contentProvider",
    colValue: "Provider",
    sortable: true,
    width: 100,
  },
  { colKey: "level", colValue: "Proficiency", sortable: true, width: 150 },
  {
    colKey: "contentProviderRating",
    colValue: "Rating",
    sortable: true,
    width: 120,
  },
  {
    colKey: "contentDuration",
    colValue: "Duration",
    sortable: true,
    width: 100,
    colType: "column_render",
    RenderColumn: (data) => {
      return (
        <div>
          {!!data?.contentDuration && data?.contentDuration > 0
            ? CheckHumanizerTime(data?.contentDuration * 1000)
            : 0}
        </div>
      );
    },
  },
  {
    colKey: "contentLanguage",
    colValue: "Language",
    sortable: true,
    width: 140,
  },
  {
    colKey: "relevanceScore",
    colValue: "Relevance Score",
    sortable: true,
    width: 150,
    colType: "column_render",
    RenderColumn: (data) => {
        return (
            `${data.relevanceScore}%`
        );
    },
  },
  {
    colKey: "relevancy",
    select: true,
    sortable: true,
    dynamic: true,
    width: 140,
    colValue: "Relevancy",
    selectMenu: [
      { label: "Yes", value: "Yes" },
      { label: "No", value: "No" },
    ],
  },
  {
    colKey: "buttonEdit",
    saveBtn: false,
    colValue: "",
    dynamic: false,
    width: 90,
  },
  // {
  //   colKey: "relevancy",
  //   switchAble: true,
  //   colValue: "",
  //   dynamic: false,
  //   width: 60,
  // },
];

export const Skill_Role_Table = [
  {
    colKey: "source",
    colValue: "Source",
    select: false,
    dynamic: false,
    width: 150,
    sortable: true,
    filterValue: "",
  },
  {
    colKey: "subsector",
    colValue: "Industry / Function",
    select: true,
    dynamic: false,
    width: 200,
    sortable: true,
    filterValue: "",
  },
  {
    colKey: "functionName",
    colValue: "Department / Track",
    select: true,
    dynamic: false,
    width: 200,
    sortable: true,
    filterValue: "",
  },
  {
    colKey: "occupation",
    colValue: "Occupation",
    // select: true,
    dynamic: false,
    width: 200,
    sortable: true,
    filterValue: "",
  },
  {
    colKey: "roleName",
    colValue: "Industry Role",
    select: true,
    dynamic: false,
    width: 200,
    sortable: true,
    filterValue: "",
  },
  {
    colKey: "level",
    colValue: "Proficiency",
    select: true,
    dynamic: true,
    width: 150,
    sortable: true,
    filterValue: "",
  },

  {
    colKey: "buttonEdit",
    saveBtn: false,
    colValue: "",
    dynamic: false,
    width: 30,
  },
  {
    colKey: "buttonDelete",
    colValue: "",
    dynamic: false,
    width: 30,
  },
];

export const Skill_Persona_Table = [
  {
    colKey: "personaName",
    colValue: "Persona Name",
    sortable: true,
    width: 330,
  },
  { colKey: "clientName", colValue: "Client", sortable: true },
  {
    colKey: "personaGroup",
    colValue: "Persona Group",
    sortable: true,
    width: 500,
  },
  {
    colKey: "level",
    colValue: "Recommended Proficiency",
    sortable: true,
    width: 280,
  },
];

export const Skill_Proficiencies_Table_array = [
  {
    level: "Level 1",
    description: "Long description.... 1",
    abilities: "Long description.... ",
    knowledge: "Long description.... ",
  },
  {
    level: "Level 2",
    description: "Long description.... 2",
    abilities: "Long description.... ",
    knowledge: "Long description.... ",
  },
  {
    level: "Level 3",
    description: "Long description.... 3",
    abilities: "Long description.... ",
    knowledge: "Long description.... ",
  },
  {
    level: "Level 4",
    description: "Long description.... 4",
    abilities: "Long description.... ",
    knowledge: "Long description.... ",
  },
];

export const Skill_Content_Table_array = [
  {
    content_type: "Course",
    title: "Short description",
    provider_name: "edX",
    proficiency: "Level 1, Level 2",
    rating: "3.5 star",
  },
  {
    content_type: "Course",
    title: "Short description",
    provider_name: "edX",
    proficiency: "Level 1, Level 2",
    rating: "3.5 star",
  },
  {
    content_type: "Course",
    title: "Short description",
    provider_name: "edX",
    proficiency: "Level 1, Level 2",
    rating: "3.5 star",
  },
];

const LinkColumn = styled.div`
  color: ${COLORS.TITLE_BLUE};
  text-decoration: underline;
`;
