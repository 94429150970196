import { AssessmentType } from "../enums/AssessmentType";
import { SourceType } from "../enums/SourceType";
import { UserType } from "../enums/UserType";

export const isAssessmentAllowed = (assessment) => {
  return (assessment === AssessmentType.HACKEREARTH || assessment === AssessmentType.IMOCHA || assessment === AssessmentType.METTL)
}

//routing to AssignSkills screen via compare-persona screen
export const isAddSkillsPath = () => {
  return window?.location?.pathname === '/add-skills/persona';
}

//Navigates to a URL when provided, showing an alert if the URL is missing.
export const navigateToTagURL = (val, showAlert) => {
  if (!val?.url) {
    showAlert("This knowledge base doesn't have URL", "info", 5000);
  } else {
    console.log(16, val);
    window.open(val?.url, "_blank");
  }
};

//Generates an object with level descriptions, initializing them with empty strings.
export const generateLevelDescriptionValues = (leves) => {
  return Array.from({ length: leves }, (_, index) => ({
      [`Level ${index + 1}`]: ''
    })).reduce((acc, cur) => ({ ...acc, ...cur }), {})
}

//Checks if the user has access to the Generate AI feature.
export const isDisprzAtomicSkillSource = (sourceId) => {
  return sourceId === SourceType.DISPRZ_ATOMIC_SKILLS
}

// Function to check if any popup is open
export const showAiGeneratorModal = (showAiGeneratorPopUp) => {
  return Object.values(showAiGeneratorPopUp).some((value) => value);
}

// Function to get the generator type based on which popup is open
export const getGeneratorType = (showAiGeneratorPopUp, generatorMapping) => {
  for (const key in showAiGeneratorPopUp) {
    if (showAiGeneratorPopUp[key]) {
      return generatorMapping[key].type;
    }
  }
  return '';
}

// Function to get the title of the active popup
export const getAiGeneratorTitle = (showAiGeneratorPopUp, generatorMapping) => {
  for (const key in showAiGeneratorPopUp) {
    if (showAiGeneratorPopUp[key]) {
      return generatorMapping[key].title;
    }
  }
  return '';
}

// Function to get the service URL for the active popup
export const getServiceUrl = (showAiGeneratorPopUp, generatorMapping) => {
  for (const key in showAiGeneratorPopUp) {
    if (showAiGeneratorPopUp[key]) {
      return generatorMapping[key].serviceUrl;
    }
  }
  return '';
}

// Function to get the confirmation text for the active popup
export const getConfirmText = (showAiGeneratorPopUp, generatorMapping) => {
  for (const key in showAiGeneratorPopUp) {
    if (showAiGeneratorPopUp[key]) {
      return generatorMapping[key].confirmText;
    }
  }
  return '';
}

/**
 * Checks if the user(logged in user) matches the specified user type.
 *
 * @param {string} user - The user to be checked (logged in user).
 * @param {string} userType - The type of user to compare against.
 * @returns {boolean} - Returns true if the user matches the specified user type, otherwise false.
 */
export const isValidUser = (user, userType) => {
  return user === userType
}

/**
 * Checks if the user(logged in user) has bulk import access.
 * Users who are not SME have bulk import access.
 *
 * @param {string} user - The user to be checked for bulk import access.
 * @returns {boolean} - Returns true if the user has bulk import access, otherwise false.
 */
export const isUserHasBulkImportAccess = (user) => {
  return (!isValidUser(user, UserType.SME));
}

/**
 * Checks if the user type is restricted based on the provided array of allowed types.
 * @param {number} userType - The user type to check.
 * @param {number[]} allowedTypes - An array of user types that are allowed.
 * @returns {boolean} - Returns true if the user type is restricted, false otherwise.
 */
export const isUserRestricted = (userType, allowedTypes = []) => {
  return !allowedTypes.includes(userType);
};


/**
 * Returns the provided data if it exists, otherwise returns a default value.
 *
 * @param {*} data - The data to be returned if it exists.
 * @param {*} defaultValue - The default value to return if data is undefined or null.
 * @returns {*} The data or the default value.
 */
export const getOrDefault = (data, defaultValue = {}) => (
  data ?? defaultValue
);


/**
 * Calculates updated current page and page offset based on the given page offset and row count.
 *
 * @param {number} pageOffset - The current page offset.
 * @param {number} rowCount - The number of rows per page.
 * @returns {Object} - An object containing updated current page and page offset.
 */
export const getUpdatedPageAndOffsetBasedOnRowCount = (pageOffset, rowCount) => {
  var updatedCurrPage = pageOffset === 0 ? 1 : Math.floor((pageOffset + rowCount) / rowCount);
  var updatedPageOffset = updatedCurrPage === 1 ? 0 : (updatedCurrPage * rowCount) - rowCount;
  return { updatedCurrPage, updatedPageOffset };
} 

/**
 * Handles the checkbox state for an individual table row.
 *
 * @param {boolean} checked - The new checked state for the checkbox.
 * @param {object} item - The current item in the list.
 * @param {array} list - The list of items in the table.
 * @returns {array} - A new array with the updated checkbox state for the specified item.
 */
export const handleTableCheckBox = (checked, item, list, key) => {
  return list?.map((el) => (el[key] === item[key] ? { ...el, checked } : { ...el }));
}

/**
 * Handles the checkbox state for all items in the table.
 *
 * @param {boolean} checked - The new checked state for all checkboxes.
 * @param {array} list - The list of items in the table.
 * @returns {array} - A new array with the updated checkbox state for all items.
 */
export const handleTableCheckAll = (checked, list) => {
  return list?.map((el) => ({ ...el, checked }));
}

/**
 * Parses and retrieves the error message from the provided error object.
 * @param {string|object} error - The error object to parse. If a string, it must be a JSON string representing an array of objects with a 'message' property.
 * @returns {string} The error message extracted from the provided error object. 
 * If the input is an object, the value of its 'message' property is returned. 
 * If the input is a string, it is parsed as a JSON array of objects, and the message from the first object is returned.
 * If parsing fails or the input is not in the expected format, returns the original error string.
 */
export const getErrorInfo = (error) => {
  try {
    if (typeof error === 'object') return getOrDefault(error?.message, "server-error");
    else if (typeof error === 'string') return getOrDefault(JSON.parse(error)[0]?.message, "server-error");
  } catch (parseError) {
      console.error('Error parsing error message:', parseError);
  }
  return error;
};

export const isUserAllowed = (userType, allowedUserTypes = []) => {
  return !isUserRestricted(userType, allowedUserTypes);
}; 
