import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import styled from "styled-components";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import { RoleServices } from "../../../services/RoleServices";
import Layout from "../../Layout";
import { H2 } from "../../Common/Typography";
import { BodyContent, Container } from "../../Common/GridSystem";
import BackNav from "../../Common/BackNav";
import SelectMenu from "../../Common/FormElements/SelectMenu";
import useFullLoader from "../../../Hooks/useFullLoader";
import { AuthService } from "../../../services/AuthService";
import Personas_Column from "./Personas_Column";
import AccordianTable from "../../Common/CustomizedTables/AccordianTable";
import { navigate, useLocation } from "@reach/router";
import { SecondaryButton } from "../../Common/Buttons/SecondaryButton";
import useRoles from "../../../Hooks/useRoles";
import DownloadFile from "../../../helpers/DownloadFile";
import useAlert from "../../../Hooks/useAlert";
import { Common } from "../../../store";
import MenuOption from "../../Common/MenuOption";
import ModalPopup from "../../Common/ModalPopup";
import PopHover from "../../Common/PopModal/PopHover";
import useAuth from "../../../Hooks/useAuth";
import { SortString } from "../../../helpers/SortString";
import COLORS from "../../../assets/colors";
import ToolBar from "../../Common/ToolBar";
import ButtonIcon from "../../Common/Buttons/ButtonIcon";
import { Images } from "../../../assets/images";
import { Videos } from "../../../assets/videos";
import PaginationCustom from "../../Common/PaginationCustom";
import AutoSelectSearch from "../../Common/FormElements/AutoSelectSearch";
import ProgressIndicator from "../../Common/ProgressIndicator";
import { getErrorInfo, getUpdatedPageAndOffsetBasedOnRowCount } from "../../../helpers/CommonHelper";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { PageType } from "../../../enums/PageType";
import { ExportProcessType } from "../../../enums/ExportProcessType";
import { ButtonSelectAll } from "../../Common/Buttons/ButtonSelectAll/ButtonSelectAll";
import usePopUp from "../../../Hooks/usePopUp";
import AutoCompleteCheckBox from "../../Common/FormElements/AutoCompleteCheckBox";
import text from "../../../locale.en.json";
import { getRouteForPersonaListScreen } from "../../../helpers/RouteHelper";
import { PrimaryButton } from "../../Common/Buttons/PrimaryButton";
import PersonaCreationChoiceModal from "../PersonaCreationModals/PersonaCreationChoiceModal/PersonaCreationChoiceModal";
import { PersonaCreationActionTypes } from "../../../enums/PersonaCreationActionType";
import BulkPersonaCreationModal from "../PersonaCreationModals/BulkPersonaCreationModal/BulkPersonaCreationModal";
import FetchingPersonasModal from "../PersonaCreationModals/FetchingPersonasModal/FetchingPersonasModal";
import DownloadJDModal from "../PersonaCreationModals/DownloadJDModal/DownloadJDModal";
import ProgressBar from "../../Common/ProgressBar/ProgressBar";
import { PersonaService } from "../../../services/PersonaService";
import { EntityType } from "../../../enums/EntityType";
import SignalRService from "../../../services/SignalRService";
import { SignalREvents } from "../../../enums/SignalREvents";
import { BulkPersonaImportStatus } from "../../../enums/BulkPersonaImportStatus";
import { BulkJDImportStatus } from "../../../enums/BulkJDImportStatus";
import { AlertType } from "../../../enums/AlertType";
import { PersonaCreationTypes } from "../../../enums/PersonaCreationType";

let selectedPersonasContainer = [];
let pagesToBeExcludedForPersonaSelection = [];
let personasToBeExcluded = [];
let openAccordian = false;
export default () => {
    const { setFullLoader } = useFullLoader();
    const { showAlert } = useAlert();
    const { userDetails } = useAuth();
    const { userId } = userDetails;
    const { clientName, setClientName, setClientList } = useRoles();
    const { popUp, closePopUp } = usePopUp();
    const location = useLocation();
    const currentPath = location.pathname;


    const [clientDetail, setClientDetail] = useState(null);
    const [personasList, setPersonasList] = useState(null);
    const [listTotal, setListTotal] = useState(0);
    const [totalPersonasCount, setTotalPersonasCount] = useState(0);
    const [isBulkImportEnabled, setIsBulkImportEnabled] = useState(false);
    const [anchorTemplate, setAnchorTemplate] = useState(null);
    const [selectPersonaDropDown, setSelectPersonaDropDown] = useState([]);
    const [isPersonasSelectedWithinCurrPage, setIsPersonasSelectedWithinCurrPage] = useState(false);
    const [isPersonasSelectedAcrossAllPages, setIsPersonasSelectedAcrossAllPages] = useState(false);
    const [isBulkExportInProcess, setIsBulkExportInProcess] = useState(false);

    const [currentItem, setCurrentItem] = useState(null);
    const [clientListMenu, setclientListMenu] = useState([]);

    const [showVideoPopup, setShowVideoPopup] = useState(false);
    const [mainListLoad, setMainListLoad] = useState(false);

    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorExport, setAnchorExport] = useState(null);
    const [showSearch, setShowSearch] = useState(true);
    const [searchString, setSearchString] = useState("");
    const [selectedPersona, setSelectedPersona] = useState("");

    const [templateURL, setTemplateURL] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isUpdatingPersonas, setIsUpdatingPersonas] = useState(false);
    const [isRoleMatchInProgress, setIsRoleMatchInProgress] = useState(false);
    const allowedFileTypes = [
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];
    const [groupList, setGroupList] = useState([]);
    const [selectedGroupIds, setSelectedGroupIds] = useState([]);

    const [showCreatePersonaModal, setShowCreatePersonaModal] = useState(false);
    const [bulkPersonaCreateModal, setBulkPersonaCreateModal] = useState(false);
    const [createPersonaInProgressModal, setCreatePersonaInProgressModal] = useState(false);
    const [showDownloadJDModal, setShowDownloadJDModal] = useState(false);
    const [error, setError] = useState('');
    const [fetchingPersonas, setFetchingPersonas] = useState(false);
    const [jdParserStatus, setJDParserStatus] = useState(null);
    const [personaCreationTokenId, setPersonaCreationTokenId] = useState(null);

    const isInitialMountRef = useRef(true);
    const accordianTableRef = useRef(null);


    // Pagination
    const [showPage, setShowPage] = useState(true);
    const [pageOffset, setPageOffset] = useState(0);
    const [rowCount, setrowCount] = useState(50);
    const [currPage, setCurrPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const handlePagination = (e, page) => {
        setCurrPage(page);
        setPageOffset((page - 1) * rowCount);
    };
    const changeRowCount = (count) => {
        const { updatedCurrPage, updatedPageOffset } = getUpdatedPageAndOffsetBasedOnRowCount(pageOffset, count);
        setrowCount(count); 
        setCurrPage(updatedCurrPage);
        setPageOffset(updatedPageOffset);
    };

    const resetPersonaSelection = () => {
        selectedPersonasContainer = [];
        pagesToBeExcludedForPersonaSelection = [];
        personasToBeExcluded = [];
        setIsPersonasSelectedWithinCurrPage(false);
        setIsPersonasSelectedAcrossAllPages(false);
    }

    const exportToDisprzStatusData = JSON.parse(Common?.getExportData());
    const [exportStatusData, setExportStatusData] = useState(
        exportToDisprzStatusData
    );

    const handleClientChange = (val) => {
        Common.setClientName(val);
        setClientName(val);
        setSearchString("");
        setSelectedPersona("");
        setSelectedGroupIds([]);
        setGroupList([])
        setShowSearch(false);
        setPageOffset(0);
        setrowCount(50);
        setCurrPage(1);
        setLastPage(1);
        resetPersonaSelection();
        isInitialMountRef.current = true;
        navigate(currentPath);
    };

    useEffect(() => {
     const getImportTemplateUrl = async() => {
        try{
           const roleSkillsTemplateUrl = await RoleServices.getImportTemplateUrl();
           const clientSkillsTemplateUrl = await PersonaService.getPersonasClientSkillsTemplateUrlV3();
           setTemplateURL({
            roleSkillsTemplateUrl,
            clientSkillsTemplateUrl: clientSkillsTemplateUrl?.value
           });
        }catch (error){
          showAlert(error, "error", 2000)
        }
     };
     getImportTemplateUrl();
    },[])

    /**
     * Updates the JD parser status based on the provided data.
     * @param {Object} jdParserData - Data containing JD parser status information.
     */
    const updateJDParserStatus = (jdParserData) => {
        if (jdParserData.status !== BulkJDImportStatus.NOT_STARTED) {
            setJDParserStatus(jdParserData.totalItems ? jdParserData : null);
            setFetchingPersonas(jdParserData.status === BulkJDImportStatus.FILE_UPLOADED);
        } else {
            setJDParserStatus(null);
        }
    }

    /**
     * Callback function to handle received JDParser data.
     * Modifies received data to include 'entity' field with value 'persona'.
     * @param {Object} jdParserData - Data received from JDParser.
     */
    const handleJDParserCallback = (jdParserData) => {
        if(jdParserData) {
            jdParserData.entity = EntityType.PERSONA;
            jdParserData.showDownloadData = !!jdParserData?.successfulResponsesFileUrl || !!jdParserData?.failedResponsesFileUrl;
            // Update JDParser status state with modified data
            updateJDParserStatus(jdParserData);
        }
    };

    useEffect(() => {
        if((clientName && userId)){
             // Get initial JD parser status

            // Instantiate SignalRService with hubUrl
            const signalRService = new SignalRService(`${process.env.REACT_APP_BASE_API_URL_DEV_V2}hubs/jd-parser-status?clientId=${clientName}&userId=${userId}`);
            
            // Add listener for receiving JD parser status updates
            signalRService.addEventListener(handleJDParserCallback, SignalREvents.RECEIVE_JD_PARSER_STATUS);
        
            return () => {
                // Cleanup function to stop SignalR connection when component unmounts or dependencies change
                signalRService.stop();
            };
        }
    }, [clientName, userId])

    useEffect(() => {
        if(selectedGroupIds?.length > 0 && selectedGroupIds?.length !== groupList?.length) {
            const updatedSelectedGroupIds = selectedGroupIds.filter(id => groupList.some(obj => obj.value === id));
            setSelectedGroupIds(updatedSelectedGroupIds);
            const updatedPath = getRouteForPersonaListScreen(currentPath, searchString, updatedSelectedGroupIds);
            navigate(updatedPath);
        }
        else if(groupList?.length > 0) {
            const { persona, departmentArray, currentUrl} = getUpdatedCurrentUrl();
            navigate(currentUrl);
            const updatedPersona = !persona.trim() ? "" : persona
            setSearchString(updatedPersona);
            setSelectedPersona(updatedPersona);
            setSelectedGroupIds(departmentArray);
            if(!!persona || departmentArray.length > 0) openAccordian = true;
        }
    }, [groupList]);

    useEffect(() => {
      if(listTotal > 50) {
        setLastPage(Math.ceil(listTotal / rowCount));
      }
    }, [listTotal, rowCount])

    useEffect(() => {
      if(!isPersonasSelectedAcrossAllPages){
        personasToBeExcluded = [];
      }
    }, [isPersonasSelectedAcrossAllPages])

    useEffect(() => {
        if (clientName) {
          const checkForImport = async () => {
            setFullLoader(true)
            try {
              const isEnabled = await RoleServices.verifyBulkImportEnabled("?clientId=" + clientName);
              setIsBulkImportEnabled(isEnabled);
              await checkStatusAndGetPersona(isEnabled);
              setFullLoader(false);
            } catch (error) {
                console.log("error", error);
            }
          };
          checkForImport();
        }
      }, [clientName]);


    useEffect(() => {
        const getClients = async () => {
            try {
                const res = await AuthService.getClientsV3(`/${userId}`);
                const clientArray = SortString(res, "clientName")?.map((el) => ({
                    ...el,
                    label: el.clientName,
                    value: el.clientId,
                }));
                const clientDetail =
                    clientArray?.find((el) => el.clientId === Number(clientName)) || null;
                setClientDetail(clientDetail);
                setclientListMenu(clientArray);
                Common.setClientList(clientArray);
                setClientList(clientArray);
            } catch (error) {
                console.log(error);
            }
        };

        !!userId && getClients();
    }, [clientName, userId]);

    const getPersonaGroups = async () => {
        const personaGroups = await AuthService.getPersonaGroupV3("/" + clientName);
        const sorted = SortString(
        personaGroups.filter((el) => !!el.personaGroupName),
        "personaGroupName"
        );
        const res = sorted.map((group) => {
        return ({
            label: group.personaGroupName,
            value: group.personaGroupId,
        })
        })
        setGroupList(res);
    }

    const getPersonasList = async (hideSearch, isPersonasDeleted = false) => {
        setFullLoader(true);
        setMainListLoad(hideSearch);
        try {
            const payload = {
                searchString: selectedPersona,
                personaGroupIds: selectedGroupIds,
                clientId: clientName
            }
            const resList = await AuthService.getPersonasListBoundaryV3(payload, rowCount, pageOffset);
            if(isPersonasSelectedAcrossAllPages && !pagesToBeExcludedForPersonaSelection.includes(currPage) && !isPersonasDeleted){
                const newlySelectedPersonas = (resList?.data || [])
                                                .filter(persona => !selectedPersonasContainer.includes(persona.personaId) && !personasToBeExcluded.includes(persona.personaId))
                                                .map(persona => persona.personaId);
                selectedPersonasContainer.push(...newlySelectedPersonas);
            }
            if(groupList.length === 0) {
                await getPersonaGroups();
            }
            makeStateList(resList);
            setMainListLoad(false);
            if(openAccordian) {
                accordianTableRef.current.handleChangeFromChild('panel0');
                openAccordian = false;
            }            
        } catch (error) {
            console.log(error);
        } finally {
            setFullLoader(false);
        }
    };

    const makeStateList = async (list) => {
        const filteredPersonasCount = list?.filteredPersonasCount;
        const personasForCurrPage = list?.data;
        setListTotal(filteredPersonasCount);
        setTotalPersonasCount(list?.total);
        if(filteredPersonasCount > 50){
            setSelectPersonaDropDown([
                {label: `Within this page (${personasForCurrPage?.length || 0})`, value: PageType.CURRENT_PAGE}, 
                {label: `Across all pages (${(filteredPersonasCount || 0)})`, value: PageType.All_PAGE}
            ])
        } else {
            setSelectPersonaDropDown([]);
        }
        const modList = personasForCurrPage?.map((el, i) => ({
            ...el,
            checked: selectedPersonasContainer.includes(el?.personaId),
            personaGroupName: el.personaGroup.personaGroupName,
            button: (
                <SecondaryButton
                    onClick={(e) => handleColClick(el, i, e)}
                    style={{ width: 140 }}
                >
                  Architect Skills
                </SecondaryButton>
            ),
        }));
        setIsPersonasSelectedWithinCurrPage(!!modList?.every((persona) => persona?.checked));
        await setPersonasList(
            _.groupBy(SortString(modList, "personaGroupName"), "personaGroupName")
        );
        setShowPage(!!list?.total > 0);
    };

    useEffect(() => {
        if(clientName && !isInitialMountRef.current) {
            getPersonasList();
        }
    }, [pageOffset, rowCount, selectedGroupIds, selectedPersona, clientName, isInitialMountRef.current]);

    const checkStatusAndGetPersona = async (isBulkImportEnabled = false) => {
        setLoading(true);
        setIsUpdatingPersonas(true);
        const interval = setInterval(async () => {
            try{
                const statusResponse = await RoleServices.postImportClientRolesStatus("?clientId=" + clientName);
                if (!!statusResponse || statusResponse === "") {
                    setIsRoleMatchInProgress(statusResponse?.status === BulkPersonaImportStatus.ROLE_RECOMMENDATION_SUCCEEDED);
                    if (statusResponse?.status !== BulkPersonaImportStatus.IN_PROGRESS || statusResponse?.status === BulkPersonaImportStatus.ROLE_RECOMMENDATION_SUCCEEDED) {
                        isInitialMountRef.current = false;
                        clearInterval(interval);
                        setLoading(false);
                        setIsUpdatingPersonas(false);
                        setFullLoader(false);
                    } 
                }
                statusResponse?.status === BulkPersonaImportStatus.ROLE_RECOMMENDATION_SUCCEEDED
                  ? setPersonaCreationTokenId(statusResponse?.importTokenId) 
                  : !isBulkImportEnabled && setPersonaCreationTokenId(null);
            }catch(error){
                showAlert(error, AlertType.ERROR, 2000);
                setPersonaCreationTokenId(null);
                clearInterval(interval);
                setIsRoleMatchInProgress(false);
                setFullLoader(false);
                isInitialMountRef.current = false;
            }
        }, 2000);
    };

    const handleColClick = (val, i, e) => {
        e.stopPropagation();
        navigate(`persona/architect-skills/${val.personaId}`);
        resetPersonaSelection();
    };

    const handleCheckBox = (e, item, index) => {
        const res = Object.values(personasList)
            .flat(Infinity)
            .map((el) =>
                el.personaId === item.personaId
                    ? { ...el, checked: e.target.checked }
                    : { ...el }
            );
        setIsPersonasSelectedWithinCurrPage(!!res?.every((persona) => persona?.checked));
        if(isPersonasSelectedAcrossAllPages){
            !e.target.checked
                ? personasToBeExcluded.push(item.personaId)
                : personasToBeExcluded = personasToBeExcluded.filter((id) => id !== item.personaId);
        }
        const indexToRemove = selectedPersonasContainer.indexOf(item.personaId);
        if(indexToRemove !== -1) selectedPersonasContainer.splice(indexToRemove, 1);
        else selectedPersonasContainer.push(item.personaId);

        setPersonasList(_.groupBy(res, "personaGroupName"));
    };

    const updatePersonasToBeExcluded = (isChecked, res) => {
        if(isChecked)  personasToBeExcluded = personasToBeExcluded.filter((id) => !selectedPersonasContainer.includes(id));
        else {
            const unselectedPersonaIdList = res.filter(persona => !persona.checked && !personasToBeExcluded.includes(persona.personaId)).map(persona => persona.personaId);
            personasToBeExcluded = [...personasToBeExcluded, ...unselectedPersonaIdList];
        }
    }

    const handleCheckAll = (e, item, index) => {
        const res = Object.values(personasList)
            .flat(Infinity)
            .map((el) =>
                el.personaGroupName.trim().toLowerCase() === item.trim().toLowerCase()
                    ? { ...el, checked: e.target.checked }
                    : { ...el }
        );
        setPersonasList(_.groupBy(res, "personaGroupName"));
        setIsPersonasSelectedWithinCurrPage(!!res?.every((persona) => persona?.checked));
        if(e.target.checked) {
            const newlySelectedPersonasList = res?.filter(persona => persona.checked && !selectedPersonasContainer.includes(persona.personaId))?.map(persona => persona.personaId)
            selectedPersonasContainer = [...selectedPersonasContainer, ...newlySelectedPersonasList];
        } 
        else {
            const unselectedPersonaIdList = res.filter(persona => !persona.checked).map(persona => persona.personaId);
            selectedPersonasContainer = selectedPersonasContainer?.filter(id => !unselectedPersonaIdList.includes(id));
        }
        if(isPersonasSelectedAcrossAllPages) updatePersonasToBeExcluded(e.target.checked, res);
    };

    const handleAddGroup = () => {
        closePopUp();
        navigate(`persona/add`);
        resetPersonaSelection();
    };

    const handleRow = (val) => {
        navigate(`persona/edit/${val.personaId}/${false}`);
        resetPersonaSelection();
    };

    const handleExportPersona = async () => {
        setAnchorExport(null);
        if ((selectedPersonasContainer.length === 0 && !isPersonasSelectedAcrossAllPages) || personasToBeExcluded.length === listTotal) {
            showAlert("Select Persona(s) to export to excel", "info", 1500);
            return;
        } 
        popUp.confirm(
            `Are you sure want to export ${getPersonaCountMessage()} to excel?`, 
            `Export Persona(s) to Excel`, 
            handleExportToExcel
        );
    }

    const handleExportToExcel = async () => {
        setIsBulkExportInProcess(true);
        setFullLoader(true);
        setLoading(true);
        try {
            const payload = {
                personaIds: isPersonasSelectedAcrossAllPages ? personasToBeExcluded : selectedPersonasContainer, 
                excludePersonas: isPersonasSelectedAcrossAllPages,
                personaGroupIds: isPersonasSelectedAcrossAllPages ? selectedGroupIds : []
            } ;
            const exportTokenId = await AuthService.getPersonaExportV3(clientName, payload);
            const interval = setInterval(async () => {
                try {
                    const statusResponse = await AuthService.getBulkPersonaExportStatusV3(clientName, exportTokenId);
                    if (statusResponse.status === ExportProcessType.COMPLETED) {
                        clearInterval(interval);
                        setLoading(false);
                        setFullLoader(false);
                        setIsBulkExportInProcess(false);
                        DownloadFile(statusResponse.fileUrl, "Persona.xlsx");
                        showAlert("Excel Export Successful", "success", 1500);
                    } else if (statusResponse.status === ExportProcessType.ERROR) {
                        clearInterval(interval);
                        setLoading(false);
                        setFullLoader(false);
                        setIsBulkExportInProcess(false);
                        showAlert(statusResponse.errorMessage, "error", 2000);
                    }
                } catch (error) {
                    console.log(error);
                    clearInterval(interval);
                    setLoading(false);
                    setFullLoader(false);
                    setIsBulkExportInProcess(false);
                    showAlert(error, "error", 2000);
                }
            }, 10000);
        } catch (errors) {
            console.log(errors);
            setLoading(false);
            setFullLoader(false);
            setIsBulkExportInProcess(false);
        }
    }

    const uncheckAllPersonasWithinOrAcrossPage = (value) => {
        const res = Object.values(personasList)
            .flat(Infinity)
            .map((el) => ({ ...el, checked: false }));
        setPersonasList(_.groupBy(res, "personaGroupName"));

        if(value === PageType.CURRENT_PAGE){
             selectedPersonasContainer = selectedPersonasContainer.filter((id) => res.every((persona) => id !== persona.personaId));
             isPersonasSelectedAcrossAllPages && pagesToBeExcludedForPersonaSelection.push(currPage);
        } else {
            selectedPersonasContainer = [];
            setIsPersonasSelectedAcrossAllPages(false);
        }

        if(isPersonasSelectedAcrossAllPages && value === PageType.CURRENT_PAGE){
            const unselectedPersonaIdList = res.filter(persona => !persona.checked && !personasToBeExcluded.includes(persona.personaId)).map(persona => persona.personaId);
            personasToBeExcluded = [...personasToBeExcluded, ...unselectedPersonaIdList];
        }

        showAlert(`Cleared Persona selection ${value === PageType.CURRENT_PAGE ? "within this page" : "across all pages"}`, "info", 1500);
    };

    const checkAllPersonasWithinOrAcrossPage = (value) => {
        const res = Object.values(personasList)
            .flat(Infinity)
            .map((el) => ({ ...el, checked: true }));
        setPersonasList(_.groupBy(res, "personaGroupName"));

        const newlySelectedPersonas = (res || [])
                                        .filter(persona => !selectedPersonasContainer.includes(persona.personaId))
                                        .map(persona => persona.personaId);
        selectedPersonasContainer.push(...newlySelectedPersonas);

        setIsPersonasSelectedAcrossAllPages(value === PageType.All_PAGE);

        const indexToRemove = pagesToBeExcludedForPersonaSelection.indexOf(currPage);
        if(indexToRemove !== -1) pagesToBeExcludedForPersonaSelection.splice(indexToRemove, 1);

        if(isPersonasSelectedAcrossAllPages) personasToBeExcluded = personasToBeExcluded.filter((id) => !selectedPersonasContainer.includes(id));

        showAlert(`${value === PageType.CURRENT_PAGE ? res.length : listTotal} Persona(s) selected ${value === PageType.CURRENT_PAGE ? "within this page" : "across all pages"}`, "success", 1500);
    };

    const handleSelectPersona = (value) => {
        !isPersonasSelectedWithinCurrPage ? checkAllPersonasWithinOrAcrossPage(value) : uncheckAllPersonasWithinOrAcrossPage(value);
        setIsPersonasSelectedWithinCurrPage(!isPersonasSelectedWithinCurrPage);
    }

    const handleSelectPersonaButtonCLick = () => {
        handleSelectPersona(PageType.CURRENT_PAGE);
    }

    const handleMoreOpen = (e, item, index) => {
        e.stopPropagation();
        setAnchorEl(e.currentTarget);
        setCurrentItem(item);
    };

    const handleMoreClose = () => {
        setAnchorEl(null);
        setAnchorExport(null);
        setAnchorTemplate(null);
    };

    const getPersonaCountMessage = () => {
        return `${isPersonasSelectedAcrossAllPages ? listTotal - personasToBeExcluded.length : selectedPersonasContainer.length} persona${selectedPersonasContainer.length > 1 ? 's' : ''}`;
    }

    const handleDelete = () => {
        handleMoreClose();
        popUp.confirm(
            `Are you sure want to delete persona - ${currentItem.personaName}?`,
            `Delete Persona`, 
            handleDeleteYes
        )
    };

    const handleBulkDelete = () => {
      setAnchorExport(null);
      if((selectedPersonasContainer.length === 0 && !isPersonasSelectedAcrossAllPages) || personasToBeExcluded.length === listTotal){
        showAlert("Select Personas to delete", "info", 1500);
        return;
      }
      handleMoreClose();
      popUp.confirm(
        `Are you sure want to delete ${getPersonaCountMessage()}?`,
        `Delete Persona(s)`, 
        () => handleDeleteYes(true)
      )
    }
 
    const resetPageSettings = () => {
        if(pageOffset === 0 && rowCount === 50 && currPage === 1) {
            getPersonasList(true, true);
        } else {
            setPageOffset(0);
            setrowCount(50);
            setCurrPage(1);
            setLastPage(1);
        }
    }

    const handleDeleteYes = async (deleteMultiplePersonas = false) => {
        setFullLoader(true);
    
        try {
            if (deleteMultiplePersonas) {
                await handleDeleteMultiplePersonas();
            } else {
                await handleDeleteSinglePersona();
            }
            await showAlert(`Persona${deleteMultiplePersonas && selectedPersonasContainer.length > 1 ? 's' : ''} deleted successfully`, "success", 1500);
            getPersonaGroups();
            resetPersonaSelection();
            if(isPersonasSelectedAcrossAllPages && deleteMultiplePersonas) {
                resetPageSettings();
            } else if (shouldHandlePagination(deleteMultiplePersonas) || ((Object?.values(personasList).flat(Infinity).length === 1 ) && currPage !== 1)) {
                handlePagination('', currPage - 1);
            } else if(selectedGroupIds.length === 0) {
                getPersonasList(true, true);
            }
        } catch (errors) {
            console.log(errors);
            setFullLoader(false);
        } finally {
            setFullLoader(false);
        }
    };
    
    const handleDeleteMultiplePersonas = async () => {
        if (isPersonasSelectedAcrossAllPages) {
            await AuthService.deletePersonasByClientV3(clientName, {ExcludePersonaIds: personasToBeExcluded, PersonaGroupIds: selectedGroupIds});
            personasToBeExcluded = [];
        } else {
            await AuthService.deletePersonaV3(selectedPersonasContainer);
            selectedPersonasContainer = [];
        }
    };
    
    const handleDeleteSinglePersona = async () => {
        await AuthService.deletePersonaV3([currentItem.personaId]);
        const indexOfItemRemoved = selectedPersonasContainer.indexOf(currentItem.personaId);
        selectedPersonasContainer.splice(indexOfItemRemoved, 1);
    };

    const shouldHandlePagination = (deleteMultiplePersonas = false) => {
        return (
            currPage === lastPage &&
            currPage !== 1 &&
            deleteMultiplePersonas &&
            Object?.values(personasList).flat(Infinity).every((persona) => !!persona?.checked)
        );
    };
    

    const handleDiscardRoleMatch = async () => {
        setFullLoader(true);
        try {
            //api call
            await RoleServices.discardClientRoleMatchingV3(clientName, personaCreationTokenId);
            setIsRoleMatchInProgress(false);
            setPersonaCreationTokenId(null);
        } catch (errors) {
            console.log(errors);
        } finally {
            setFullLoader(false);
        }
    };

    const getUpdatedCurrentUrl = () => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const persona = urlSearchParams.get('persona') || '';
        const updatedPersona = !!persona.trim() ? persona : '';
        const departments = urlSearchParams.getAll('department') || '';
        const groupIds = groupList.map((group) => group.value);
        const departmentArray = departments.filter((dep, i) => ( 
                                    !!Number(parseInt(dep, 10)) && 
                                    groupIds.includes(Number(parseInt(dep, 10))) && 
                                    departments.indexOf(dep) == i 
                                )).map(dep => parseInt(dep, 10));
        const currentUrl = getRouteForPersonaListScreen(currentPath, updatedPersona, departmentArray);
        return { persona, departmentArray, currentUrl }
    }

    const handleSearchClick = () => {
        
            const updatedUrl = getRouteForPersonaListScreen(currentPath, searchString, selectedGroupIds);
            const { currentUrl  } = getUpdatedCurrentUrl()
            if(updatedUrl !== currentUrl) {
                navigate(updatedUrl);
                setSelectedPersona(searchString);
                openAccordian = true;
                setPageOffset(0);
                setrowCount(50); 
                setCurrPage(1);
                resetPersonaSelection();
            } 
        if(selectedPersona) {
            setPageOffset(0);
            setrowCount(50);
            setCurrPage(1);
            setSelectedPersona("");
            resetPersonaSelection();
        }
    };

    const handleExportToDisprzTrigger = () => {
        setAnchorExport(null);
        if((selectedPersonasContainer.length === 0 && !isPersonasSelectedAcrossAllPages) || personasToBeExcluded.length === listTotal) {
            showAlert("Select Personas to export to Disprz", "info", 2000);
            return;
        }
        setAnchorExport(null);
        popUp.confirm(
            `Are you sure want to export ${getPersonaCountMessage()} to Disprz?`,
            `Export Persona(s) to Disprz`, 
            handleExportToDisprz
        )
    };

    const handleExportToDisprz = async () => {

        if (selectedPersonasContainer?.length > 0) {
            try {
                if (exportStatusData === null) {
                    //if prev export don't have, create one
                    ExportAPITrigger();
                } else {
                    if (exportStatusData.processStatus < 3) {
                        try {
                            const resExportStatus =
                                await AuthService.personasExportToDisprzStatusV3(
                                    exportStatusData
                                );
                            if (resExportStatus.processStatus > 2) {
                                //if prev export is pass or fail
                                ExportAPITrigger();
                            } else {
                                //if prev export is start process or pending
                                showAlert(resExportStatus.statusMessage, "info", 2000);
                                Common.setExportData(resExportStatus);
                                setExportStatusData(resExportStatus);
                            }
                        } catch (errors) {
                            console.log(errors);
                        }
                    } else {
                        //if prev export is pass or fail
                        ExportAPITrigger();
                    }
                }
            } catch (errors) {
                showAlert(errors, "error", 2000);
            } finally {
                setFullLoader(false);
            }
        } else {
            showAlert("Select Personas to export to Disprz", "info", 2000);
        }
    };

    const ExportAPITrigger = async () => {
        try {  
            const payload = {
                personaIds: isPersonasSelectedAcrossAllPages ? personasToBeExcluded : selectedPersonasContainer, 
                excludePersonas: isPersonasSelectedAcrossAllPages,
                personaGroupIds: isPersonasSelectedAcrossAllPages ? selectedGroupIds : []
            };

            const resExport = await AuthService.personasExportToDisprzV3(clientName, payload);

            const resExportStatus = await AuthService.personasExportToDisprzStatusV3(
                resExport
            );
            showAlert(resExportStatus.statusMessage, "info", 2000);
            Common.setExportData(resExportStatus);
            setExportStatusData(resExportStatus);
        } catch (errors) {
            console.log(errors);
        } finally {
        }
    };

    const handleExportIcon = (e) => {
        e.stopPropagation();
        setAnchorExport(e.currentTarget);
    };

    const triggerApi = async (str, count, offset) => {
        setSearchString(str);

        const postData = {
            clientId: Number(clientName),
            searchString: str,
            personaGroupId: selectedGroupIds,
        };

        const params = `?pagesize=${count}&offset=${offset}`;

        try {
            const res = await AuthService.getPersonasSearchListV3(postData, params);
            return res?.data;
        } catch (errors) {
            console.log(errors);
        } 
    };

    const handleChange = (item, reason) => {
        setPageOffset(0);
        setrowCount(50);
        setCurrPage(1);
        if (reason === "select-option") {
            setSearchString(item.personaName);
            setSelectedPersona(item.personaName);
            openAccordian = true;
        } else if (reason === "clear") {
            setSearchString("");
            setSelectedPersona("");
            setShowSearch(false);
            const updatedPath = getRouteForPersonaListScreen(currentPath, '', selectedGroupIds);
            navigate(updatedPath);
        } else if (reason === "create-option") {
            // press enter
            setShowPage(false);
            setSearchString(item);
            setSelectedPersona(item);
        }
    };


    const TableMoreActionContent = () => (
        <div>
          <MenuOption
              onClick={() => {
                  navigate(`persona/edit/${currentItem.personaId}/${true}`);
                  resetPersonaSelection();
              }}
          >
            Duplicate Persona
          </MenuOption>
          <MenuOption onClick={handleDelete}>Delete Persona</MenuOption>
        </div>
    );

    const MoreActionContent = () => (
        <div>
            <MenuOption onClick={handleBulkDelete}>
                Delete Persona(s)
            </MenuOption>
           {!!clientDetail && !!clientDetail?.disprzInstanceName && !!clientDetail?.region 
              ? <MenuOption onClick={handleExportToDisprzTrigger}>
                   Export to Disprz
                </MenuOption>
              : null}
            {!!personasList && Object.keys(personasList).length > 0 
                ? <MenuOption onClick={handleExportPersona}>
                    Export to Excel
                  </MenuOption>
                : null}
        </div>
    )

    const VideoModal = () => (
        <ModalPopup
            open={showVideoPopup}
            maxWidth="lg"
            title={'Define Client Personas (Job Roles)'}
            center={true}
            closePopup={() => setShowVideoPopup(false)}
        >
            <video src={Videos.Persona_Creation} width="100%" height="100%" controls />
        </ModalPopup>
    );

    const bulkImportJDComplete = async() => {
        await PersonaService.bulkImportJDCompleteV3(clientName);
    }

    const startUploadInterval = (importToken, closeModals) => {
        setLoading(true);
        const interval = setInterval(async () => {
            try {
                const statusResponse = await RoleServices.postImportClientRolesStatus("?clientId=" + clientName, "&importTokenId=" + personaCreationTokenId);
                if (statusResponse.status === BulkPersonaImportStatus.ROLE_RECOMMENDATION_SUCCEEDED) {
                    clearInterval(interval);
                    setLoading(false);
                    setFullLoader(false);
                    showAlert(text.common.fileImportSuccessful, AlertType.SUCCESS, 1500);
                    closeModals();
                    closeDownloadJDModal();
                    navigateToRoleMatchScreen(importToken);
                }
            } catch (error) {
                console.log(error);
                clearInterval(interval);
                setLoading(false);
                setFullLoader(false);
                showAlert(error, AlertType.ERROR, 2000);
            }
        }, 5000);
    }

    const handleFileSelect = async (fileArray, closeDragAndDropFileModal, personaCreationType) => {
        const selectedFile = fileArray[0];
        if (selectedFile && allowedFileTypes.includes(selectedFile.type)) {
            const formData = new FormData();
            formData.append("file", selectedFile);
            setFullLoader(true);
            try {
                const response = await RoleServices.postImportClientRolesV3(formData, clientName, personaCreationType === PersonaCreationTypes.UPLOAD_SKILLS_EXCEL);
                if(personaCreationType === PersonaCreationTypes.UPLOAD_ZIP_FILE){
                    await bulkImportJDComplete();
                }
                setJDParserStatus(null);
                startUploadInterval(response.value, closeDragAndDropFileModal)
            } catch (error) {
                setLoading(false);
                setFullLoader(false);
                const errorMessage = getErrorInfo(error);
                errorMessage === text.fileSelection.invalidColumnHeader 
                    ? setError(text.fileSelection.invalidExcelTemplate)
                    : setError(errorMessage)
            }
        }
    };

    const discardCurrentRoleMatching = (e) => {
        e.stopPropagation();
        popUp.confirm(
            text.persona.discardRoleMatchingConfirmation,
            text.persona.discardRoleMatching, 
            handleDiscardRoleMatch
        )
    };

    const getLoaderMessage = () => {
        if(isBulkExportInProcess){
            return text.common.exportIsInProgress
        } else if (isUpdatingPersonas) {
            return text.persona.updatingPersonas
        } else if (fetchingPersonas) {
            return text.persona.fetchingPersonas
        } return text.persona.importingClientRoles
    }

    const handleGroupSelection = (value, reason) => {
        setCurrPage(1);
        setPageOffset(0);
        if(reason === 'select-option' || reason === 'remove-option') {
            const selectedIds = value.map((el) => el.value)
            setSelectedGroupIds(selectedIds);
            const updatedPath =  getRouteForPersonaListScreen(currentPath, searchString, selectedIds);
            navigate(updatedPath);
            openAccordian = true;
        } else if(reason === 'clear') {
            setSelectedGroupIds([]);
            const updatedPath = getRouteForPersonaListScreen(currentPath, searchString, []);
            navigate(updatedPath);
        }
    }

    const navigateToRoleMatchScreen = (token) => {
        navigate(`personas/role-matching`, {state: {clientName, importTokenId: token}})
    }

    const closeDownloadJDModal = () => {
        setShowDownloadJDModal(false);
    }

    const importPersonaFromUrl = async() => {
        try {
            setFullLoader(true);
            const response = await PersonaService.importPersonaFromUrlV3(clientName, jdParserStatus.successfulResponsesFileUrl);
            await bulkImportJDComplete();
            setJDParserStatus(null)
            startUploadInterval(response.value, closeDownloadJDModal)
        } catch (error) {
            setFullLoader(false);
            setError(getErrorInfo(error));
        }
    };

    const handleRoleSuggestionCreation = () => {
        importPersonaFromUrl();
    }

    const closeCreatePersonaInProgressModal = () => {
        setCreatePersonaInProgressModal(false);
    }

    const closeBlukCreatePersonaModal = () => {
        setBulkPersonaCreateModal(false);
        setError("");
    }

    const handleDownloadData = () => {
        setShowDownloadJDModal(true)
    }

    const handleClearExtractedPersonasConfirmation = async() => {
        setFullLoader(true);
        try {
            await bulkImportJDComplete(); 
            setFullLoader(false);
            setJDParserStatus(null); 
            showAlert(text.persona.clearedBulkPersonaCreation, AlertType.SUCCESS, 1500);
        } catch (error) {
            setFullLoader(false);
            showAlert(error, AlertType.ERROR, 1500);
        }
    }
    const clearExtractedPersonas = () => {
        popUp.confirm(
            text.persona.extractedPersonasWillBeDeletedConfirmation,
            text.persona.cancelBulkPersonaCreation,
            handleClearExtractedPersonasConfirmation
        ) 
    }

    const handleBulkImportJD = async (formData, handleBulkPersonaCreateClose) => {
        try {
            setFullLoader(true);
            await PersonaService.bulkImportJDV3(formData, clientName);
            handleBulkPersonaCreateClose();
            closeBlukCreatePersonaModal();
            setCreatePersonaInProgressModal(true);
            setFetchingPersonas(true);
            setFullLoader(false);
        } catch (error) {
            setFullLoader(false);
            setError(getErrorInfo(error));
        }
    }

    const handleBulkImportJDConfirm = async (selectedFile, handleBulkPersonaCreateClose) => {
        const file = selectedFile[0]
        const formData = new FormData();
        formData.append("file", file);
        handleBulkImportJD(formData, handleBulkPersonaCreateClose);
    }

    const personaCreationHandlers = {
        [PersonaCreationTypes.UPLOAD_ZIP_FILE]: handleBulkImportJDConfirm,
        [PersonaCreationTypes.UPLOAD_SKILLS_EXCEL]: handleFileSelect,
        [PersonaCreationTypes.UPLOAD_ROLES_SKILLS_EXCEL]: handleFileSelect,
    };
    
    const handleBulkImportConfirm = (selectedFile, handleBulkPersonaCreateClose, personaCreationType) => (
        personaCreationHandlers[personaCreationType](selectedFile, handleBulkPersonaCreateClose, personaCreationType)
    );
    

    const closeCreatePersonaModal = () => {
        setShowCreatePersonaModal(false);
    }

    const handleBulkCreateClick = () => {
        closeCreatePersonaModal();
        setBulkPersonaCreateModal(true);
    };

    const createPersonasActionHanlder = {
        [PersonaCreationActionTypes.CREATE_BULK]: handleBulkCreateClick,
        [PersonaCreationActionTypes.CREATE_SINGLE]: handleAddGroup,
    };

    const handleCreatePersonasClick = () => {
        if(isRoleMatchInProgress){
            navigateToRoleMatchScreen(personaCreationTokenId);
        } else if (isBulkImportEnabled) {
            setShowCreatePersonaModal(true);
        } else {
            handleAddGroup()
        }
    };

    return (
        <Layout showContent={true}>
          <H2 id="tempTitle">
              Define Client Personas (Job Roles)
              <ToolBar title="Click to watch how a client persona is defined" arrow={true}>
                  <img
                      src={Images.icons.videoIcon}
                      alt="persona video"
                      onClick={() => setShowVideoPopup(true)}
                      style={{ maxWidth: 20, position: 'relative', top: '3px', left: '15px', cursor: 'pointer' }}
                  />
              </ToolBar>
          </H2>
          <BodyContent>
            <Container>
              <BackNav linkNav="/" />
              <DropBlock>
                <div className="btn-wrap">
                    {!!clientListMenu ? (
                        <SelectMenu
                            width="200px"
                            label={
                                "Client " +
                                `<span class='subText'>(No. of Personas: ${
                                totalPersonasCount || 0
                                    })</span>`
                            }
                            value={clientName}
                            list={clientListMenu}
                            handleSelect={handleClientChange}
                        />
                    ) : null}
                </div>
                <div className="btn-wrap">
                  <div>
                    <PrimaryButton
                        className={"create-persona-btn"}
                        onClick={handleCreatePersonasClick}
                    >
                        {isRoleMatchInProgress ? text.persona.continuePersonaCreation : text.persona.createPersonas}
                        {isRoleMatchInProgress && (
                            <ToolBar title={text.persona.discardCurrentRoleMatching} arrow={true}>
                                <div className="clear-role-matching">
                                    <ButtonIcon
                                        icon={<ClearIcon />}
                                        onClick={discardCurrentRoleMatching}
                                        className="clear-icon"
                                    />
                                </div>
                            </ToolBar>
                        )}
                    </PrimaryButton>
                  </div>
                  {totalPersonasCount > 0 && 
                    <div style={{display: "flex"}}>
                        <ButtonSelectAll 
                            handleDropDownClick={handleSelectPersona}
                            dropdownOptions={selectPersonaDropDown}
                            label={!isPersonasSelectedWithinCurrPage ? "Select All Personas" : "Deselect All Personas"}
                            isSecondaryButton={true}
                            handleButtonClick={listTotal <= 50 ? handleSelectPersonaButtonCLick : () => {}}
                        />
                        <div className="more-action">
                            <MoreVertIcon 
                                color="action"
                                onClick={handleExportIcon}
                            />
                        </div>
                    </div>
                  }
                </div>
              </DropBlock>

              <SearchBlock>
              {!mainListLoad && totalPersonasCount > 0 && (
                <>
                    <div>   
                        <div className="search-block">
                            <AutoSelectSearch
                            freeSolo
                            width={200}
                            label={text.persona.searchPersona}
                            optionChange={(item, reason) => {
                                return !!item ? handleChange(item, reason) : undefined;
                            }}
                            enterResetSearch={() => {
                                if (!!searchString) {
                                    const updatedPath = getRouteForPersonaListScreen(currentPath, searchString, selectedGroupIds);
                                    navigate(updatedPath);
                                }
                            }}
                            optionLabel="personaName"
                            stateValue={searchString}
                            triggerApi={(val, reason) =>
                                triggerApi(val, 50, 0)
                            }
                            handleClearSearch={() => { handleChange("", "clear"); }}
                            renderOption={(option) => option?.personaName}
                            placeholder={text.persona.searchAnyPersona}
                            />
                            <div className="search-icon">
                                <ToolBar title={text.persona.searchAnyPersona} arrow={true}>
                                    <div
                                    className="search-icon_inner"
                                    onClick={handleSearchClick}
                                    >
                                    <SearchIcon />
                                    </div>
                                </ToolBar>
                            </div>
                        </div>
                    </div>
                    <div className="group-box">
                        <AutoCompleteCheckBox
                            list={groupList}
                            defaultValue={selectedGroupIds}
                            width={"200px"}
                            label="Department"
                            optionLabel={"label"}
                            optionChange={(val, reason) => handleGroupSelection(val, reason)}
                            limitTags={1}
                            placeholder={!selectedGroupIds.length ? "Select Department" : "" }
                        />
                    </div>
                </>
            )}
            </SearchBlock>

                {!!personasList && (
                    <AccordianTable
                        columns={Personas_Column}
                        rowList={[]}
                        groupList={personasList}
                        countRows={10}
                        borderCollapse={false}
                        selectItemKey="personaId"
                        rowClick={handleRow}
                        handleCheckBox={handleCheckBox}
                        handleCheckAll={handleCheckAll}
                        moreActionClick={handleMoreOpen}
                        autoFocus={showSearch}
                        includeListCount = {true}
                        ref={accordianTableRef}
                    />
                )}

                {!!showPage && listTotal > 50 ? (
                    <PaginationCustom
                        pageTotal={listTotal}
                        handlePagination={handlePagination}
                        countRows={rowCount}
                        changeRowCount={changeRowCount}
                        countRowsList={[
                            { label: 50, value: 50 },
                            { label: 100, value: 100 },
                        ]}
                       changeCurrentPage={currPage}
                    />
                ) : null}
                {loading || fetchingPersonas ? (
                    <ProgressIndicator message={getLoaderMessage()}/>
                ) : jdParserStatus ? (
                    <ProgressBar download={handleDownloadData} cancel={clearExtractedPersonas} status={jdParserStatus} />
                ) : null}
            </Container>
          </BodyContent>
          <VideoModal />
          <PopHover 
            anchorEl={anchorEl} 
            handleClose={handleMoreClose} 
            anchorOrigin={{vertical: "bottom", horizontal: "right"}}
            transformOrigin={{vertical: "top", horizontal: "right"}}
            styles={{top: 10}}
          >
            <TableMoreActionContent />
          </PopHover>
          <PopHover 
            anchorEl={anchorExport} 
            handleClose={handleMoreClose}
            anchorOrigin={{vertical: "bottom", horizontal: "right"}}
            transformOrigin={{vertical: "top", horizontal: "right"}}
            styles={{top: 10, left: 5}}
          >
            <MenuPopHover>
                <MoreActionContent />
            </MenuPopHover>
          </PopHover>
          <PopHover 
            anchorEl={anchorTemplate}
            handleClose={handleMoreClose} 
            anchorOrigin={{vertical: "bottom", horizontal: "right"}}
            transformOrigin={{vertical: "top", horizontal: "right"}}
            styles={{left: 17}}
          >
          </PopHover>
          <PersonaCreationChoiceModal
            open={showCreatePersonaModal}
            close={closeCreatePersonaModal}
            actionHandlers={createPersonasActionHanlder}
            restrictBulkPersonaCreation={!!jdParserStatus || fetchingPersonas}
          />
          <BulkPersonaCreationModal
            open={bulkPersonaCreateModal}
            close={closeBlukCreatePersonaModal}
            handleBulkImportConfirm={handleBulkImportConfirm}
            error={error}
            setError={setError}
            templateUrl={templateURL}
          />
          <FetchingPersonasModal
            open={createPersonaInProgressModal}
            close={closeCreatePersonaInProgressModal}
            closeDurationInSec={5}
          />
          <DownloadJDModal
            open={showDownloadJDModal}
            close={closeDownloadJDModal}
            status={jdParserStatus}
            handleFileSelect={handleFileSelect}
            handleYesClick={handleRoleSuggestionCreation}
            error={error}
            setError={setError}
          />
        </Layout>
    );
};

const MenuPopHover = styled.div`
  li.MuiButtonBase-root {
    position: relative;
    &:before {
      content: "";
      background-color: ${COLORS.PRIMARY_WHITE};
      height: 1px;
      width: 78%;
      position: absolute;
      bottom: 0px;
      left: 11%;
    }
    &:last-child {
      &:before {
        display: none;
      }
    }
  }
`;

const TemplatePopHover = styled.div`
    .template-container {
      top: 34px;
      background: ${COLORS.BAR_RED};
      min-width: max-content;
      color: ${COLORS.PRIMARY_WHITE};
      padding: 0px 6px;
      cursor: default;
      .download-section{
        font-size: 11px;
        a {
            color: ${COLORS.PRIMARY_WHITE};
            text-decoration: underline;
            cursor: pointer;
        }
      }
      &.name {
        min-width: unset;
        max-width: 340px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
`

const DropBlock = styled.div`
  /* padding-bottom: 20px; */
  background-color: ${COLORS.BODY_ASH};
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 10px 0px 20px;
  height: 101px;
  position: sticky;
  top: 0px;
  z-index: 1;
  gap: 15px;
  :before,
  :after {
    content: "";
    background-color: ${COLORS.BODY_ASH};
    height: 100%;
    position: absolute;
    top: 0px;
  }
  :before {
    left: -15px;
  }
  :after {
    right: -15px;
  }
  .btn-wrap {
    display: inherit;
    align-items: center;
    h5 {
      padding-bottom: 0px;
    }
    .import-roles-btn {
      margin-right: 10px;
      position: relative;
      padding-right: 45px;
    }
    .role-match-btn {
      margin-right: 10px;
      position: relative;
      &.in-progress {
        padding-right: 45px;
      }
    }
    .create-persona-btn{
        margin-right: 30px;
        .clear-icon {
            width: 18px;
            height: 18px;
            margin-left: 5px;
            color: ${COLORS.PRIMARY_WHITE} !important;

            svg {
                &.MuiSvgIcon-root {
                    width: 0.8em;
                    height: 0.8em;
                }
            }
        }
    }
  }
  .more-action{
    cursor: pointer;
    background-color: transparent;
    display: flex;
    align-items: center;
    justy-content: center;
    padding: 5px;
    border-radius: 15px;
    transition: all 0.2s linear;
    &:hover {
      background-color:  #e0e0e0;
    }
  }
  .MuiInputBase-root {
    margin-bottom: 0px;
  }
  `;

  const SearchBlock = styled.div`
    background-color: ${COLORS.BODY_ASH};
    display: flex;
    gap: 20px;
    padding-bottom: 30px;
    position: sticky;
    top: 101px;
    z-index: 2;
    :before,
    :after {
        content: "";
        background-color: ${COLORS.BODY_ASH};
        height: 100%;
        position: absolute;
        top: 0px;
    }
    :before {
        left: -15px;
    }
    :after {
        right: -15px;
    }
  .search {
    &-block {
      position: relative;
      label {
        padding-bottom: 6px;
      }
      .MuiAutocomplete-popupIndicator {
        opacity: 0;
      }
      .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]
        .MuiAutocomplete-endAdornment {
        right: 40px;
      }
      .MuiAutocomplete-inputRoot {
        background-color: ${COLORS.PRIMARY_WHITE};
        border: solid 1px ${COLORS.C4_BORDER};
        height: 36px;
        padding: 0px 66px 0px 0px !important;
      }
    }
    &-icon {
      background-color: ${COLORS.BODY_ASH};
      position: absolute;
      line-height: 1;
      right: 0px;
      bottom: 0px;
      height: 36px;
      z-index: 2;
      &_inner {
        background-color: ${COLORS.PRIMARY_WHITE};
        cursor: pointer;
        padding: 5px;
        border: solid 1px ${COLORS.C4_BORDER};
        border-radius: 4px;
        width: 36px;
        height: 36px;
      }
    }
  }
  .group-box{
    margin-top: 4px;
    .MuiFormControl-root{
        label{
        color: #495281;
        padding-bottom: 10px;
        font-size: 16px;
        font-weight: 600;
        font-family: inherit
      }
        .MuiAutocomplete-inputRoot {
        background-color: ${COLORS.PRIMARY_WHITE};
        border: solid 1px ${COLORS.C4_BORDER};
        padding: 0px 30px 0px 3px !important;
        min-height: 36px;
        width: 250px;
        .MuiAutocomplete-input{
            width: 50px
        }
      }
        .MuiAutocomplete-tagSizeSmall{
            max-width: calc(100% - 95px);
        }
    }
  }
`;
