import React from "react";
import { withStyles, Button } from "@material-ui/core";
import COLORS from "../../../assets/colors";

export const primaryButtonStyles = {
  root: {
    background: COLORS.PRIMARY_PURPLE_GRADIENT,
    color: COLORS.PRIMARY_WHITE,
    fontFamily: "inherit",
    fontWeight: 600,
    fontSize: 14,
    textTransform: "none",
    borderRadius: 4,
    minHeight: 38,
    width: "auto",
    padding: "8px 22px",
    maxwidth: "auto",
    "&:hover": {
      backgroundColor: "linear-gradient(111deg, #fa1565 22%, #7314ad 155%)",
      boxShadow: "none",
      borderColor: "linear-gradient(111deg, #fa1565 22%, #7314ad 155%)",
      opacity: "0.9",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "linear-gradient(111deg, #fa1565 22%, #7314ad 155%)",
      borderColor: "linear-gradient(111deg, #fa1565 22%, #7314ad 155%)",
    },
    "&:focus": {
      boxShadow: "none",
      borderColor: "linear-gradient(111deg, #fa1565 22%, #7314ad 155%)",
    },
    "&:disabled": {
      background: "#abafb5",
      color: COLORS.PRIMARY_WHITE,
    },
  },
  label: {
    lineHeight: 1,
  },
};

export const PrimaryButton = withStyles(primaryButtonStyles)((props) => <Button {...props} />);
