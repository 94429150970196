import React, { useState, useEffect, useRef, createRef, useImperativeHandle, forwardRef } from "react";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CustomizedTables from ".";
import { PrimaryButton } from "../Buttons/PrimaryButton";
import COLORS from "../../../assets/colors";
import { H4 } from "../Typography";

const Accordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    borderRadius: 12,
    boxShadow: "0 2px 5px 0 rgba(95, 130, 188, 0.26)",
    marginBottom: "15px !important",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      height: 0,
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "transparent",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
    "& h4": {
      paddingBottom: 0,
    },
  },
  expandIcon: {
    border: `solid 1px ${COLORS.ASH_BORDER}`,
    borderRadius: 8,
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.16)",
    height: 24,
    marginRight: 0,
    width: 38,
    "&.Mui-expanded": {
      boxShadow: "none",
      opacity: 0.4,
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: 0,
    flexDirection: "column",
  },
}))(MuiAccordionDetails);

export default forwardRef(({
  addText,
  addClick,
  groupList,
  initOpen,
  handleCheckAll,
  includeListCount = false,
  ...props
}, ref) => {
  let rowRefs = useRef([]);
  rowRefs.current = !!groupList
    ? Object.keys(groupList).map(
        (ref, index) => (rowRefs.current[index] = createRef()),
      )
    : rowRefs;

  const [expanded, setExpanded] = useState("panel" + initOpen);

  const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
  };

  useEffect(() => {
    setExpanded("panel" + initOpen);
    return () => {
      setExpanded(null);
    };
  }, [initOpen]);

  useImperativeHandle(ref, () => ({
    handleChangeFromChild(panel) {
      setExpanded(panel);
    },
  }));

  return (
    <AccordionTable ref={ref}>
      {Object.keys(groupList).map((item, i) => (
        <Accordion
          square
          ref={rowRefs.current[i]}
          expanded={expanded === "panel" + i}
          onChange={handleChange("panel" + i)}>
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls={`panel${i}d-content`}
            id={`panel${i}d-header`}>
            <H4>{item} <span className="list-count">{includeListCount ? `(${groupList[item]?.length})` : ''}</span></H4>
          </AccordionSummary>
          <AccordionDetails>
            <CustomizedTables
              {...props}
              rowList={groupList[item]}
              handleCheckAll={(e) => handleCheckAll(e, item, i)}
            />
            {!!addText && (
              <div className="accord-add-btn">
                <PrimaryButton onClick={() => addClick(item, i)}>
                  {addText}
                </PrimaryButton>
              </div>
            )}
          </AccordionDetails>
        </Accordion>
      ))}
    </AccordionTable>
  );
});

const AccordionTable = styled.div`
  .accodion-icon {
    border: solid 1px ${COLORS.ASH_BORDER};
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
    height: 24px;
    width: 38px;
  }
  .accord-add-btn {
    padding: 15px 20px;
  }
  .list-count{
    font-size: 16px;
    font-weight: 400;
  }
`;
