import { useContext } from "react";
import { FullPageLoaderContext } from "../Provider/FullPageLoaderProvider";

export default function useFullLoader() {
  const context = useContext(FullPageLoaderContext);
  if (context === undefined) {
    throw new Error("useFullLoader must be used within a HOC");
  }
  return context;
}
