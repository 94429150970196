import React from "react";
import { SecondaryButton } from "./SecondaryButton";
import { PrimaryButton } from "./PrimaryButton";

export function ButtonBlock({
  handlePartialSave = () => {},
  handleAddToSkillList = () => {},
  disableSave = false,
  disableAdd = false,
  showSave = true 
}) {
  return (
    <div className="btn-block">
      {showSave && (
        <SecondaryButton
          onClick={handlePartialSave}
          style={{
            marginRight: 15,
          }}
          disableSave={disableSave}
        >
          Save
        </SecondaryButton>
      )}
      
      <PrimaryButton onClick={handleAddToSkillList} disableAdd={disableAdd}>
        Add to Skill List
      </PrimaryButton>
    </div>
  );
}
