import * as Yup from "yup";

const roleManageMentValidation = Yup.object({
  roleName: Yup.string().required("Role Name is required").nullable(),
  subsectorId: Yup.number()
    .required("Industry is required")
    .moreThan(0, "Industry is required"),
  functionId: Yup.string().required("Department is required").nullable(),
  occupation: Yup.string().required("Occupation is required").nullable(),
  leadershipRoleId: Yup.number()
    .required("Leadership Role is required")
    .moreThan(0, "Leadership Role is required"),
  roleDescription: Yup.string().required("Role Description is required"),
  roleandResponsibilities: Yup.string().required(
    "Critical Work Functions and Tasks is required"
  ),
  sourceId: Yup.string().required("source is required").nullable(),
  roleStatus: Yup.number()
    .required("Status is required")
    .moreThan(0, "Status is required"),
});

export { roleManageMentValidation };
