import React from "react";
import TitleIcon from "../../Common/TitleIcon";
import { Paragraph } from "../../Common/Typography";
import { Images } from "../../../assets/images";
import { AccordArrays } from "../../../helpers/AccordArray";
import CustomAccordianList from "../../Common/CustomAccordianList";

export default ({ data }) => {
  // console.log("data", data);
  const accContent = AccordArrays(data?.roleandResponsibilities) || [];
  return (
    <>
      <TitleIcon src={Images.icons.rolesIcon} title="Roles as per Industry" />
      <Paragraph>{data?.roleDescription}</Paragraph>
      <TitleIcon
        src={Images.icons.starIcon}
        title="Critical Work Functions and Tasks"
      />
      <CustomAccordianList list={accContent} initOpen={null} />
    </>
  );
};
