import React, { useState, useEffect, useLayoutEffect } from "react";
import styled from "styled-components";
import { navigate } from "@reach/router";

import COLORS from "../../../assets/colors";
import CustomizedTables from "../../Common/CustomizedTables";
import TabCustom from "../../Common/TabCustom";
import TabPanel from "../../Common/TabPanel";

import { AuthService } from "../../../services/AuthService";
import { RoleServices } from "../../../services/RoleServices";
import { GroupByKey } from "../../utils";
import { Role_Skill_Table_Colmns } from "../Role_Management_Table_Colmns";
import useAlert from "../../../Hooks/useAlert";
import ModalPopup from "../../Common/ModalPopup";
import { SkillService } from "../../../services/SkillService";
import SkillUpdatePopup from "../../Personas/ArchitectSkills/SkillContent/SkillUpdatePopup";
import { PrimaryButton } from "../../Common/Buttons/PrimaryButton";
import { SortString } from "../../../helpers/SortString";
import ConfirmationButton from "../../Common/ConfirmationButton";
import RowCount from "../../Common/Others/RowCount";
import { FilterTableRowObject } from "../../../helpers/FilterTableRow";
import { useTableContext } from "../../../Provider/TableProvider";
import { isValidUser } from "../../../helpers/CommonHelper";
import { Disprz_Skills_Table, Non_Disprz_Skills_Table } from "../AIGeneratorSkillTable";
import { H5 } from "../../Common/Typography";
import useFullLoader from "../../../Hooks/useFullLoader";
import { IndustreRoleStore } from "../../../store/store_state";
import { AISuggestedSkills } from "../../../enums/AISuggestedSkills";
import { Images } from "../../../assets/images";
import { renderAIButton } from "../../Common/Buttons/RenderAiButton";
import useAuth from "../../../Hooks/useAuth";
import { UserType } from "../../../enums/UserType";
import { RoleType } from "../../../enums/RoleType";

export default ({ roleId, type }) => {
  const { showAlert } = useAlert();
  const { setSortAndFilterReset } = useTableContext();
  const {setFullLoader} = useFullLoader();

  const { userDetails } = useAuth();
  const { User_Type } = userDetails;

  const tabTitle =
    type === "INDUSTRY"
      ? ["Functional Skills", "Digital Tools"]
      : ["Leadership Skills", "Meta Skills"];

  const skillsTabTitle = ["Disprz Skills", "Non Disprz Skills"];

  const [roleSkillList, setRoleSkillList] = useState(null);
  const [roleSkillListCopy, setRoleSkillListCopy] = useState(null);
  const [currentTab, setCurrentTab] = useState(0);
  const [tableHeight, setTableHeight] = useState("auto");
  const [currentSkillRow, setCurrentSkillRow] = useState(null);
  const [roleSkillColumn, setRoleSkillColumn] = useState(
    Role_Skill_Table_Colmns
  );
  const [criticalList, setCriticalList] = useState([]);

  const [skillDetail, setSkillDetail] = useState(null);
  const [skillProficiency, setSkillProficiency] = useState(null);
  const [skillPopup, setSkillPopup] = useState(false);

  const [confirmPopup, setConfirmPopup] = useState(false);
  const [confirmType, setConfirmType] = useState(null);
  const [confirmTitle, setConfirmTitle] = useState("");
  const [currentItem, setCurrentItem] = useState(null);

  const [showSkillsTablePopUp, setShowSkillsTablePopUp] = useState(false);
  const [skillsCurrentTab, setSkillsCurrentTab] = useState(0);
  const [roletableHeight, setRoletableHeight] = useState("auto");

  const [skillArray, setSkillArray] = useState({
    [AISuggestedSkills.Disprz_Skills]: [], 
    [AISuggestedSkills.Non_Disprz_Skills]: []
  });
  const roleSourcedId = IndustreRoleStore.useState((s) => s.sourceId);
  const storeRoleId = IndustreRoleStore.useState((s) => s.storeRoleId);
  const [excludeSkillIds, setExcludeSkillIds] = useState([]);

  const handleTab = (val) => {
    setCurrentSkillRow(null);
    setCurrentTab(val);
  };

  useLayoutEffect(() => {
    document.querySelector(".MuiTableBody-root tr.MuiTableRow-root") &&
      setTableHeight(
        (document.querySelector(".MuiTableBody-root tr.MuiTableRow-root")
          .offsetHeight +
          8) *
          10 +
          (document.querySelector(".MuiTableRow-head").offsetHeight + 8) +
          8
      );
    return () => {
      setTableHeight("auto");
    };
  }, [currentTab, roleSkillList]);

  useEffect(() => {
    const fetchData = async () => {
      if (showSkillsTablePopUp && (skillArray[AISuggestedSkills.Disprz_Skills].length === 0 || (storeRoleId !== roleId))) {
        try {
          setFullLoader(true);
          const res = await RoleServices.GetExistingSkillIndustryRoleV3(roleId);
          IndustreRoleStore.update((s) => {s.storeRoleId = roleId});
          updateSkillsWithDuplicates(res);
        } catch (error) {
          console.error(error);
        } finally {
          setFullLoader(false);
        }
      }
    };
    fetchData();
  },[showSkillsTablePopUp])

  const getRoleSkills = async () => {
    const SkillAPI =
      type === "INDUSTRY"
        ? AuthService.getSkillListV3(
            "/" + roleId + "/skills?includeUnPublished=true"
          )
        : AuthService.getLeaderSkillListV3("/" + roleId + "/skills");

    try {
      const resSkill = await SkillAPI;
      const sorted = SortString(
        resSkill?.map((el) => ({
          ...el,
          skill_Category: el.skillCategory.skillCategory,
          source: el.skillSource.source,
          sourceId: el.skillSource.sourceId,
          level: el.proficiencyLevel.level,
          levelId: el.proficiencyLevel.levelId,
          skill_Family: el.skillFamily.skillFamily,
          skill_Criticality: el.skillCriticality,
        })),
        "skillName"
      );
      const groupSkill = GroupByKey(sorted, "skill_Category");
      setRoleSkillList(groupSkill);
      setExcludeSkillIds(sorted?.map((skill) => skill?.skillId));
      setRoleSkillListCopy(groupSkill);
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  const handleSkillEdit = async (e, item, index) => {
    e.stopPropagation();
    if (!currentSkillRow) {
      await getSkillProficiency(item);
      setCurrentSkillRow(index);
    } else {
      showAlert("Kindly save the existing Role Mapping!", "info", 1500);
    }
  };

  const handleSkillSave = async (e, item, i) => {
    // console.log("handleSkillSave -> item", item);
    // return false;
    const {
      level,
      leadershipRoleId,
      levelId,
      skillName,
      skill_Criticality,
      proficiencyId,
      needId,
      roleId,
    } = item;

    const UpdateAPI =
      type === "INDUSTRY"
        ? RoleServices.updateRoleSkillV3(
            "/" + roleId,
            "/proficiency/",
            proficiencyId,
            {
              proficiencyId: needId || proficiencyId,
              skillCriticality: skill_Criticality,
            }
          )
        : RoleServices.updateLeaderSkillV3(
            "/" + leadershipRoleId,
            "/proficiency/",
            proficiencyId,
            {
              proficiencyId: needId || proficiencyId,
              skillCriticality: skill_Criticality,
            }
          );

    try {
      await UpdateAPI;
      await showAlert(
        // `Successfully updated proficiency level for ${skillName}`,
        `Successfully updated the ${skillName}`,
        "success",
        1500
      );
      updateReset();
    } catch (err) {
      console.log(err);
    }
  };

  const updateDisprzSkills = (updatedArray) => {
    const itemsWithDisableRow = updatedArray.filter(item => item?.disableRow === true);
    const itemsWithoutDisableRow = updatedArray.filter(item => !item?.disableRow);
    const finalArray = [...itemsWithoutDisableRow, ...itemsWithDisableRow];
    setSkillArray((prevSkillArray) => ({
     ...prevSkillArray,
      [AISuggestedSkills.Disprz_Skills]: finalArray,
    }));
  };

  const handleSkillDelete = async (item) => {
    // e.stopPropagation();

    const DeleteAPI =
      type === "INDUSTRY"
        ? RoleServices.deleteRoleSkillV3(
            "/" + item.roleId,
            "/proficiency/",
            item.proficiencyId
          )
        : RoleServices.deleteLeaderSkillV3(
            "/" + item.leadershipRoleId,
            "/proficiency/",
            item.proficiencyId
          );

    await DeleteAPI;
    await showAlert(`Skill Successfully deleted`, "success", 1500);
    if(skillArray[AISuggestedSkills.Disprz_Skills]?.length > 0){
      let updateAiSuggestedDisprzSkills = skillArray[AISuggestedSkills.Disprz_Skills];
      updateAiSuggestedDisprzSkills = updateAiSuggestedDisprzSkills?.map((aiSkill) => 
      aiSkill?.skillId === currentItem?.skillId && aiSkill?.disableRow === true
        ? {
            ...aiSkill,
            aiSuggestedLevel: "",
            level:  "",
            proficiencyId: "",
            disableRow: false
         }
        : { ...aiSkill }
      )
      const updatedArray = SortString(
        updateAiSuggestedDisprzSkills?.map((el) => ({
          ...el,
        })), "skillName"
      )
      updateDisprzSkills(updatedArray);
    }
    setSortAndFilterReset(true);
    closePopup();
    updateReset();
  };
  const updateReset = () => {
    setCurrentSkillRow(null);
    getRoleSkills();
  };

  const handleSkillSelect = (e, val, colKey, index, item) =>
    updateRoleList(e, val, colKey, index, item);

  const updateRoleList = (e, val, colKey, index, item) => {
    const arr = Object.values(roleSkillList).flat(1);
    const updatedArr = arr.map((el) =>
      item.skillId === el.skillId
        ? colKey === "levelId"
          ? {
              ...el,
              [colKey]: e,
              needId:
                val?.selectMenu.find((el) => el.value === e)?.needId ||
                item?.proficiencyId,
            }
          : { ...el, [colKey]: e }
        : { ...el }
    );
    setRoleSkillList(GroupByKey(updatedArr, "skill_Category"));
  };

  const handleSkillPopup = async (item, index) => {
    try {
      const resDetail = await SkillService.getSkillDetail(item.skillId, true);
      setSkillDetail(resDetail);
      await getSkillProficiency(item);
      setSkillPopup(true);
    } catch (errors) {
      console.log(errors);
    }
  };

  const getSkillProficiency = async (item) => {
    try {
      const resProficiency = await SkillService.getSkillTypesArrayV3(item.skillId, "proficiencies");
      const sortedArray = SortString(resProficiency, "levelNumber");
      setSkillProficiency(sortedArray);
      const profTableCol = Role_Skill_Table_Colmns;
      const resTable = profTableCol.map((el) =>
        el.colKey === "level"
          ? {
              ...el,
              selectMenu: sortedArray.map((el) => ({
                label: el.level,
                value: el.levelId,
                levelNumber: el.levelNumber,
                needId: el.proficiencyId,
              })),
            }
          : el.colKey === "skill_Criticality"
          ? { ...el, selectMenu: criticalList }
          : { ...el }
      );
      setRoleSkillColumn(resTable);
    } catch (errors) {
      console.log(errors);
    }
  };

  const skillPopupClose = () => {
    setSkillPopup(false);
  };

  const getCriticality = async () => {
    const skillCriticalityList = await SkillService.getSkillCriticalityListV3();

    setCriticalList([
      ...skillCriticalityList.map((el) => ({ label: el, value: el })),
    ]);
  };

  useEffect(() => {
    getRoleSkills();
    getCriticality();
    return () => {};
  }, []);

  const deleteTrigger = (e, item) => {
    e.stopPropagation();
    setConfirmType("delete");
    setConfirmTitle("Delete skill - " + item.skillName + " ?");
    setConfirmPopup(true);
    setCurrentItem(item);
  };

  const handleYes = () => {
    if (confirmType === "delete") {
      handleSkillDelete(currentItem);
    }
  };

  const handleNo = () => closePopup();
  const closePopup = () => {
    setConfirmPopup(false);
    setConfirmType(null);
    setConfirmTitle("");
    setCurrentItem(null);
  };

  const ConfirmModal = () => (
    <ModalPopup
      open={confirmPopup}
      maxWidth="sm"
      title={confirmTitle}
      center={true}
    >
      <ConfirmationButton yesClick={handleYes} noClick={handleNo} />
    </ModalPopup>
  );

  console.log(roleSkillColumn, roleSkillList);

  // const skillArray = [ Disprz_Skill_Array, Non_Disprz_Skill_Array ]
  const skillTable = [ Disprz_Skills_Table, Non_Disprz_Skills_Table ];

  //to check ai-suggested disprz-skills contains already selected skills
  const updateSkillsWithDuplicates = (arrayToBeChecked) => {
    let updatedArray = [...arrayToBeChecked];
    updatedArray.forEach((item) => {
      let category = item.skillCategory;
      let matchingSkill = roleSkillList[category]?.find((skill) => skill.skillId === item.skillId);
      if(!!matchingSkill){
        item.disableRow = true;
        item.aiSuggestedLevel = matchingSkill.level;
        item.level = matchingSkill.level;
        item.proficiencyId = matchingSkill.proficiencyId
      }
    });
    updatedArray = SortString(
      updatedArray?.map((el) => ({
        ...el,
      })), "skillName"
    )
    updateDisprzSkills(updatedArray);
  };

  const handleGenAiClick = () => {
    setShowSkillsTablePopUp(true);
    if(skillArray[AISuggestedSkills.Disprz_Skills].length > 0){
      updateSkillsWithDuplicates(skillArray[AISuggestedSkills.Disprz_Skills]);
    }
  }

  const triggerApi = (id) =>
    SkillService.getSkillTypesArrayV3(id, "proficiencies");

  const levelChange = (val, item, reason) => {
      const copyArray = [...skillArray[skillsCurrentTab]].map((skill) =>
        skill.skillId === item.skillId
          ? {
              ...skill,
              level: !!val ? val.level : "",
              proficiencyId: val?.proficiencyId || "",
              updated: true,
            }
          : { ...skill }
      );
      setSkillArray({...skillArray, [skillsCurrentTab]: copyArray});
    };
  const handleCheckAll = (e, list, i) => {
    const skillArrayToBeUpdated = skillArray[skillsCurrentTab];
     const updatedArr = [...skillArrayToBeUpdated].map((item) => ({...item, checked: e.target.checked}));
     setSkillArray({...skillArray, [skillsCurrentTab]: updatedArr});
  };

  const handleCheckBox = (e, row, i) => {
    const skillArrayToBeUpdated = skillArray[skillsCurrentTab];
    const updatedArr = [...skillArrayToBeUpdated].map((el) =>
      el.skillId === row.skillId
        ? { ...el, checked: e.target.checked }
        : { ...el }
    );
    setSkillArray({...skillArray, [skillsCurrentTab]: updatedArr});
  };

  const toggleSkillsTab = async (val) => {
    setSkillsCurrentTab(val);
    if(val === 1 && (skillArray[AISuggestedSkills.Non_Disprz_Skills].length === 0 || (storeRoleId !== roleId))){
      try{
        setFullLoader(true);
        const res = await RoleServices.GetNonExistingSkillIndustryRoleV3(roleId);
        const sortedArray = SortString(
          res.map((el) => ({
            ...el
          })),"skillName"
        )
        IndustreRoleStore.update((s) => {s.storeRoleId = roleId});
        setSkillArray({...skillArray, [AISuggestedSkills.Non_Disprz_Skills] : sortedArray});
      }catch(error){
        console.log(error);
      }finally{
        setFullLoader(false);
      }
    }
  };

  const addSkillsToIndustryRole = async() => {
    setFullLoader(true);
    const postRoleData = [...skillArray[AISuggestedSkills.Disprz_Skills]]
        .filter((skill) => (!skill?.disableRow && !!skill?.proficiencyId && !!skill.checked))
        .map(({ proficiencyId }) => ({ proficiencyId, skillCriticality: "" }));
    try{
      await SkillService.addSkillToIndustryRoleV3(roleId, "proficiencies", postRoleData);
      setFullLoader(false);
      showAlert(`Added ${postRoleData.length} skills to Industry Role`, "success", 1500);
      const updatedSkillArray = skillArray[AISuggestedSkills.Disprz_Skills].map(skill => ({
        ...skill,
        checked: false,
        level: !!skill.level ? skill.level : '',
        proficiencyId: !!skill.proficiencyId ? skill.proficiencyId : '',
        updated: false,
      }));
      setSkillArray({...skillArray, [AISuggestedSkills.Disprz_Skills]: updatedSkillArray});
      getRoleSkills();
    }catch (errors) {
       console.log(errors);
    }finally{
      setFullLoader(false);
    }
    handleNoClick();
  }

  const handleDisableYesClick = () => {
    return !skillArray[AISuggestedSkills.Disprz_Skills]?.some((item) => item?.checked === true);
  };

  const handleNoClick = () => {
    setShowSkillsTablePopUp(false);
  };

  const handleYesClick = () => {
    const isLevelSelectedForAllSkills = skillArray[AISuggestedSkills.Disprz_Skills]?.some((item) => item?.checked === true && (item?.proficiencyId === undefined || item?.proficiencyId === ""));
    if(isLevelSelectedForAllSkills){
      showAlert("please select levels for selected skills", "info", 1500);
    }else{
      addSkillsToIndustryRole();
    }
  }
  return (
    <SkillTableBlock>
      {!!roleSkillList ? (
        <>
          <div className="skills-header">
            <h3>Skills</h3>
            {type !== RoleType.LEADER && renderAIButton({sourceId: roleSourcedId, clickHandler:()=> handleGenAiClick()})}
          </div>
        <TabCustom
          tabTitle={tabTitle}
          getTabNumber={handleTab}
          currentTab={currentTab}
        >
          {tabTitle.map((el, i) => (
            <TabPanel
              value={currentTab}
              index={i}
              style={{ padding: 0 }}
              className="top-table"
              key={i}
            >
              <RowCount
                text={`No. of ${el}: `}
                count={roleSkillList[el]?.length}
              />
              <CustomizedTables
                columns={roleSkillColumn}
                rowList={!!roleSkillList[el] ? roleSkillList[el] : []}
                rowClick={handleSkillPopup}
                currentRow={currentSkillRow}
                handleSelect={handleSkillSelect}
                editClick={handleSkillEdit}
                saveClick={handleSkillSave}
                deleteClick={deleteTrigger}
                cancelClick={() => updateReset()}
                stickyHeader={roleSkillList[el]?.length > 10}
                tableHeight={
                  roleSkillList[el]?.length > 10 ? tableHeight : "auto"
                }
                innerTabel={true}
                rowListCopy={!!roleSkillListCopy ? roleSkillListCopy[el] : []}
                sortList={(sortValue, sortBy) => {
                  setCurrentSkillRow(null);
                  setRoleSkillList({
                    ...roleSkillList,
                    [el]: SortString(roleSkillList[el], sortValue, sortBy),
                  });
                }}
                filterChange={(item, reason, colKey, list) =>
                  FilterTableRowObject(
                    item,
                    reason,
                    colKey,
                    roleSkillListCopy,
                    setRoleSkillList,
                    el
                  )
                }
                resetList={() => {
                  setCurrentSkillRow(null);
                  setRoleSkillList(roleSkillListCopy);
                }}
                resetRow={() => setCurrentSkillRow(null)}
              />
            </TabPanel>
          ))}
        </TabCustom>
        </>
      ) : null}
      <PrimaryButton
        style={{ display: "flex", margin: `30px auto 0px` }}
        onClick={() =>
          navigate(
            (`/role/${roleId}/${
              type === "INDUSTRY" ? "industry" : "leader"
            }/skills`),
            {state: {skillSourceIds: [roleSourcedId], skillIdsToBeExcluded: excludeSkillIds, isExcludeSkills: true, isNavigatedFromRoleScreen: true}}
          )
        }
      >
        Add Skill
      </PrimaryButton>
      <ModalPopup
        open={skillPopup}
        maxWidth="lg"
        closePopup={skillPopupClose}
        title={!!skillDetail && skillDetail.skillName}
      >
        <SkillUpdatePopup
          data={skillDetail}
          listProficiency={skillProficiency}
          closePopup={skillPopupClose}
        />
      </ModalPopup>
      <ConfirmModal />
      <ModalPopup open={showSkillsTablePopUp} title={"Skills"} maxWidth="lg" customStyle={{height: 'fit-content', paddingLeft: "0px", paddingRight: "0px"}} titleStyle={{padding: '15px'}}>
        <ModaBody>
        <TabCustom tabTitle={skillsTabTitle} getTabNumber={(val) => {toggleSkillsTab(val)}} currentTab={skillsCurrentTab}>
            {skillsTabTitle.map((el, i) => (
                <TabPanel
                  value={skillsCurrentTab}
                  index={i}
                  style={{ padding: 0 }}
                  className=""
                  key={i} 
                >
                  <div className="ai-suggestion">
                    <img src={Images.icons.aiIcon} alt="ai.png" />
                    <H5>AI Suggestions</H5>
                  </div>
                  <div className={`skills-table ${skillsCurrentTab && "non-disprz-table"} ${isValidUser(User_Type, UserType.SME) && 'disable-skill-link'}`}>
                    <CustomizedTables 
                      columns={skillTable[i]}
                      rowList={skillArray[i]}
                      rowListCopy={skillArray[i]}
                      tableHeight={skillArray[i]  ? roletableHeight : "auto"}
                      handleCheckBox={handleCheckBox}
                      handleCheckAll={handleCheckAll}
                      triggerApi={triggerApi}
                      stickyHeader={true}
                      levelChange={levelChange}
                    />
                  </div>
                </TabPanel>
            ))}
        </TabCustom>
        <div className={skillsCurrentTab === AISuggestedSkills.Non_Disprz_Skills ? 'non-disprz-btn' : ''}> 
          <ConfirmationButton 
            noText="Close" 
            yesText="Add to Main Screen" 
            disableYesClick={handleDisableYesClick()}
            yesClick={handleYesClick} 
            noClick={handleNoClick}
          />
        </div>
        </ModaBody>
      </ModalPopup>
    </SkillTableBlock>
  );
};

const SkillTableBlock = styled.div`
    border-radius: 8px;
    box-shadow: 0 1px 3px 0 rgba(97, 122, 163, 0.24);
    padding: 30px;
    background-color: ${COLORS.PRIMARY_WHITE};
  .skills-header{
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 20px;
  }
  &:last-child {
    margin-bottom: 0px;
  }
  .MuiBox-root {
    padding: 0px;
  }
  table {
    padding: 0px 10px;
  }
  .MuiInputBase-root {
    margin: 0;
  }
  .MuiFormControl-root {
    padding-top: 0;
  }
  .MuiSelect-selectMenu {
    padding: 13px 26px 14px 12px;
  }
  .btn-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
  }
`;

const ModaBody = styled.div`
  > div > div >div >div .MuiTabs-flexContainer{
   gap: 0;
   width: fit-content;
   border-bottom: 1px solid #b0b4d0;
   margin-left: 25px;
   button{
    margin: 0px;
   };
  }
  >div >div > div{
    border-bottom: 0px !important;
    .MuiTabs-flexContainer{
      width: 280px;
    }
    .MuiTabs-indicator{
      left: 25;
    }
  }
  .MuiBox-root{
    padding-top: 0px;
  }
  .MuiTable-root{
    padding: 0px 10px !important;
  }
  .MuiTableContainer-root{
    height: 330px;
  }
  .ai-suggestion{
    display: flex;
    align-items: center;
    gap: 10px;
    img{
      padding-bottom: 15px;
    }
  }
  .skills-table{
    a{
      color: ${COLORS.TEXT_BLUE} !important
    }
  }
  .disable-skill-link{
    a{
      text-decoration: none !important;
      pointer-events: none !important;
    }
  }
  .non-disprz-table{
    table{
      thead th:first-child {
        padding-left: 30px !important;
      }
      thead th:last-child {
        padding-right: 30px !important;
      }
      tbody tr td:first-child {
        padding-left: 30px !important;
      }
      tbody tr td:last-child {
        padding-right: 30px !important;
      }
    }
  }
  .non-disprz-btn > div > button:last-child {
   display: none;
  }
`
