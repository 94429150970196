import React, { useState, useEffect, useRef } from "react";
import * as d3 from "d3";
import styled from "styled-components";

import Layout from "../Layout";
import { H2 } from "../Common/Typography";
import { BodyContent, Container } from "../Common/GridSystem";
import { RoleServices } from "../../services/RoleServices";
import useFullLoader from "../../Hooks/useFullLoader";
import "./chart.scss";
import useAuth from "../../Hooks/useAuth";

const colorArray = [
  {
    type: "Functional Skills",
    color: "rgba(48, 159, 219, 1)",
    hexCode: "#309fdb",
  },
  { type: "Digital Tools", color: "rgba(216, 134, 13, 1)", hexCode: "#d8860d" },
  {
    type: "Leadership Skills",
    color: "rgba(170, 82, 155, 1)",
    hexCode: "#aa529b",
  },
  {
    type: "Mindset / Meta Skills",
    color: "rgba(109, 99, 193, 1)",
    hexCode: "#6d63c1",
  },
];

export default () => (
  <>
    {/* <RoleExplorerProvider> */}
    <RoleExplorer />
    {/* </RoleExplorerProvider> */}
  </>
);

const width = 1200;
const radius = width / 6;
export const RoleExplorer = () => {
  const { setFullLoader } = useFullLoader();
  // const { roleExplorerData, setRoleExplorerData } = useRoleExplorerContext();
  const { userDetails } = useAuth();
  const { userId } = userDetails;
  const [dataRole, setDataRole] = useState(null);

  const ref = useRef();
  const refBread = useRef();

  const getRoleData = async () => {
    setFullLoader(true);
    try {
      const res = await RoleServices.getRoleExplorerDataV3(`?userId=${userId}`);
      setFullLoader(false);
      setDataRole(res);
      madeSvg(res);
    } catch (error) {
      console.log(error);
    } finally {
      setFullLoader(false);
    }
  };

  useEffect(() => {
    if (!!userId) {
      getRoleData();
    }
  }, [userId]);

  const madeSvg = (data) => {
    if (!!data) {
      const partition = (data) => {
        const root = d3
          .hierarchy(data)
          .sum((d) => d.value)
          .sort((a, b) => b.value - a.value);
        return d3.partition().size([2 * Math.PI, root.height + 1])(root);
      };

      const color = d3.scaleOrdinal(
        d3.quantize(d3.interpolateRainbow, data?.children.length + 1)
      );

      const initializeBreadcrumbTrail = () => {
        let sequence = d3.select("#sequence");
        sequence = d3.select(refBread.current);
      };

      const getAncestors = (node) => {
        var path = [];
        var current = node;
        if (!!node)
          while (current.parent) {
            path.unshift(current);
            current = current.parent;
          }
        return path;
      };

      initializeBreadcrumbTrail();
      getAncestors();

      const arc = d3
        .arc()
        .startAngle((d) => d.x0)
        .endAngle((d) => d.x1)
        .padAngle((d) => Math.min((d.x1 - d.x0) / 2, 0.005))
        .padRadius(radius * 1.5)
        .innerRadius((d) => d.y0 * radius)
        .outerRadius((d) => Math.max(d.y0 * radius, d.y1 * radius - 1));

      const root = partition(data);
      // console.log("root", root);

      root.each((d) => (d.current = d));
      let svg = d3.select(ref.current);

      function arcVisible(d) {
        return d.y1 <= 3 && d.y0 >= 1 && d.x1 > d.x0;
      }

      function labelVisible(d) {
        return d.y1 <= 3 && d.y0 >= 1 && (d.y1 - d.y0) * (d.x1 - d.x0) > 0.03;
      }

      function labelTransform(d) {
        const x = (((d.x0 + d.x1) / 2) * 180) / Math.PI;
        const y = ((d.y0 + d.y1) / 2) * radius;
        return `rotate(${x - 90}) translate(${y},0) rotate(${
          x < 180 ? 0 : 180
        })`;
      }

      const g = svg
        .append("g")
        .attr("transform", `translate(${width / 2},${width / 2})`);

      let path = g
        .append("g")
        .selectAll("path")
        .data(root.descendants().slice(1))
        .join("path")
        .attr("fill-opacity", (d) =>
          arcVisible(d.current) ? (d.children ? 0.6 : 0.4) : 0
        )
        .attr("fill", function (d) {
          while (d.depth > 1 && d.depth < 4) d = d.parent;
          return d.data.color || color(d.data.name);
        })
        .filter((d) => d.depth < 3)
        .attr("d", (d) => arc(d.current));

      path.append("title").text((d) => d.data.name || "");
      path.filter((d) => d.children).on("click", clicked);

      function trimText(text, threshold) {
        if (text.length <= threshold) return text;
        return text.substr(0, threshold).concat("...");
      }

      let label = g
        .append("g")
        .attr("pointer-events", "none") // none
        .attr("text-anchor", "middle")
        .selectAll("text")
        .data(root.descendants().slice(1))
        .join("text")
        .attr("text-rendering", "geometricPrecision")
        .attr("fill-opacity", (d) => +labelVisible(d.current))
        .attr("transform", (d) => labelTransform(d.current))
        .attr("dy", "0.35em")
        // .filter((d) => d.depth < 3)
        .text((d) => trimText(d.data.name, 25));

      const parent = g
        .append("circle")
        .datum(root)
        .attr("r", radius)
        .attr("fill", "none")
        .attr("pointer-events", "all")
        .on("click", clicked);

      function clicked(event, p) {
        parent.datum(p.parent || root);

        root.each(
          (d) =>
            (d.target = {
              x0:
                Math.max(0, Math.min(1, (d.x0 - p.x0) / (p.x1 - p.x0))) *
                2 *
                Math.PI,
              x1:
                Math.max(0, Math.min(1, (d.x1 - p.x0) / (p.x1 - p.x0))) *
                2 *
                Math.PI,
              y0: Math.max(0, d.y0 - p.depth),
              y1: Math.max(0, d.y1 - p.depth),
            })
        );
        console.log(p);

        const timing = g.transition().duration(750);

        // Transition the data on all arcs, even the ones that aren’t visible,
        // so that if this transition is interrupted, entering arcs will start
        // the next transition from the desired position.
        path
          .transition(timing)
          .tween("data", (d) => {
            const i = d3.interpolate(d.current, d.target);
            return (t) => (d.current = i(t));
          })
          .filter(function (d) {
            return +this.getAttribute("fill-opacity") || arcVisible(d.target);
          })
          .attr("fill-opacity", (d) =>
            arcVisible(d.target) ? (d.children ? 0.6 : 0.4) : 0
          )
          .attrTween("d", (d) => () => arc(d.current));

        label
          .transition(timing)
          .tween("data", (d) => {
            const i = d3.interpolate(d.current, d.target);
            return (t) => (d.current = i(t));
          })
          .filter(function (d) {
            return +this.getAttribute("fill-opacity") || labelVisible(d.target);
          })
          .attr("fill-opacity", (d) => +labelVisible(d.target))
          .attrTween("transform", (d) => () => labelTransform(d.current));

        var sequenceArray = getAncestors(p || root);
        updateBreadcrumbs(sequenceArray);
      }

      function updateBreadcrumbs(nodeArray) {
        var g = d3
          .select("#sequence")
          .selectAll("p")
          .data(nodeArray, function (d) {
            return d.data.name + d.depth;
          });

        var entering = g.enter().append("div");

        entering
          .append("p")
          .text(function (d) {
            return d.data.name;
          })
          .on("click", clicked);

        g.exit().remove();
        var node = document.getElementById("sequence");
        node
          .querySelectorAll("div")
          .forEach((n) => !n.firstChild && n.remove());
      }
    }
  };

  return (
    <Layout>
      <H2>Role Explorer</H2>
      <BodyContent>
        <Container style={{ position: "relative" }}>
          <div id="main">
            <Legendry>
              {colorArray.map((el, i) => (
                <li key={i} data-color={el.color}>
                  <span style={{ backgroundColor: `${el.color}` }}></span>
                  {el.type}
                </li>
              ))}
            </Legendry>

            <div id="chart">
              <VisualWrap>
                {!!dataRole ? (
                  <>
                    <div id="sequence" ref={refBread} />
                    <svg ref={ref} className="chartsvg" />
                  </>
                ) : null}
              </VisualWrap>
            </div>
          </div>
        </Container>
      </BodyContent>
    </Layout>
  );
};

const Legendry = styled.ul`
  display: inline-block;
  position: absolute;
  right: 0;
  li {
    position: relative;
    padding-left: 20px;
    span {
      border-radius: 5px;
      display: inline-block;
      position: absolute;
      left: 0px;
      top: 6px;
      width: 10px;
      height: 10px;
      opacity: 0.6;
    }
  }
`;

const VisualWrap = styled.div`
  text-align: center;
  width: 100%;
  .chartsvg {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 50%;
    margin-left: -600px;
    font-size: 11px;
    path {
      cursor: pointer;
    }
    text {
      user-select: none;
    }
  }
`;
