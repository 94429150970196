import React, { useState, useEffect } from "react";
import styled from "styled-components";
import ShowMoreText from "react-show-more-text";

import COLORS from "../../assets/colors";
import { H4, H5, Paragraph } from "../Common/Typography";
import { Container, Row, Col4, Col6 } from "../Common/GridSystem";
import { Images } from "../../assets/images";
import AnnotateBlock from "./AnnotateBlock";
import { AuthService } from "../../services/AuthService";
import BackNav from "../Common/BackNav";
import SelectMenu from "../Common/FormElements/SelectMenu";
import { PrimaryButton } from "../Common/Buttons/PrimaryButton";
import useAlert from "../../Hooks/useAlert";
import useFullLoader from "../../Hooks/useFullLoader";
import { SortString } from "../../helpers/SortString";

export default ({ data, subSectors, functions, ...props }) => {
  const [industryRole, setIndustryRole] = useState("Any");
  const [skillList, setSkillList] = useState(null);
  const [industrySkill, setIndustrySkill] = useState(null);
  const [leader, setLeader] = useState("Any");
  const [leaderRoles, setLeaderRoles] = useState(null);
  const { showAlert } = useAlert();
  const { setFullLoader } = useFullLoader();

  const handleLeader = (leader) => {
    console.log(leader);
    setLeader(leader);
  };
  const handleRoleChange = (val) => setIndustryRole(val);

  useEffect(() => {
    setSkillList(null);
    setIndustrySkill(null);
    // setLeader("Any");
    // setLeaderRoles(null);
    if (!!data) {
      setLeader(
        data.leadership_role_id !== 0 ? data.leadership_role_id : "Any"
      );
      setIndustryRole(
        data.industry_role_id !== "" ? data.industry_role_id : "Any"
      );
    }
  }, [data]);

  useEffect(() => {
    const getIndustryRoles = async () => {
      setFullLoader(true);
      try {
        const res = await AuthService.getSkillList("/" + industryRole);
        const resSkill = await AuthService.getSkillList(
          "/" + industryRole + "/skills"
        );
        setSkillList(res);
        setIndustrySkill(resSkill);
      } catch (error) {
        console.log(error);
      } finally {
        setFullLoader(false);
      }
    };
    if (industryRole !== "Any" && industryRole !== 0) getIndustryRoles();
    return () => {
      setSkillList(null);
      setIndustrySkill(null);
    };
  }, [industryRole]);

  useEffect(() => {
    const getIndustryRoles = async () => {
      try {
        const res = await AuthService.leaderShipRoles();
        setLeaderRoles(res);
        // setLeaderRoles(SortString(res, "leadership_role_name"));
      } catch (error) {
        console.log(error);
      }
    };

    getIndustryRoles();
    return () => {
      setLeaderRoles(null);
    };
  }, []);

  const handleAnnotateRole = async () => {
    setFullLoader(true);
    try {
      if (industryRole !== "Any" && industryRole !== 0 && leader !== "Any") {
        await AuthService.postAnnotateRole(
          `?leaderShipRoleId=${leader}`,
          "&roleId=" + industryRole
        );
        showAlert("Successfully role annotated", "success", 1500);
      } else {
        showAlert(
          "Before annotate role, Get industry role and leader role",
          "info",
          1500
        );
      }
    } catch (error) {
      showAlert(error.Message, "error", 1500);
    } finally {
      setFullLoader(false);
    }
  };

  // console.log(leader);

  return (
    <DetailContent>
      <Container>
        <Row>
          <Col4>
            <BackNav linkNav="/annotate-jobs" />
            <H4>Jobs Description</H4>
            {data ? (
              <Paragraph>
                <ShowMoreText
                  lines={8}
                  more="Show more"
                  less="Show less"
                  anchorClass=""
                  onClick={undefined}
                  expanded={false}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data.job_posting_description.replace(
                        /\//g,
                        "<br /><br />"
                      ),
                    }}
                  />
                </ShowMoreText>
              </Paragraph>
            ) : null}
            <H4>Role Sentences</H4>
            <Paragraph>
              {!!data && (
                <ShowMoreText
                  lines={12}
                  more="Show more"
                  less="Show less"
                  anchorClass=""
                  onClick={undefined}
                  expanded={false}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data.role_sents_extracted.replace(
                        /\|/g,
                        "<br /><br />"
                      ),
                    }}
                  />
                </ShowMoreText>
              )}
            </Paragraph>
          </Col4>
          <Col6>
            <ManBg>
              <AnnotateBlock
                data={data}
                subSectors={subSectors}
                functions={functions}
                industryRole={industryRole}
                handleRoleChange={handleRoleChange}
                {...props}
              />
            </ManBg>
            <WhiteBlock>
              <div className="dropDownBlock">
                <div className="dropDownInner">
                  <SelectMenu
                    width="100%"
                    label="Leadership Role"
                    value={leader}
                    list={
                      !!leaderRoles
                        ? leaderRoles.map((el, i) => ({
                            label: el.leadership_role_name,
                            value: el.leadership_role_id,
                          }))
                        : []
                    }
                    handleSelect={handleLeader}
                  />
                </div>
                <PrimaryButton onClick={handleAnnotateRole}>
                  Annotate Role
                </PrimaryButton>
              </div>
            </WhiteBlock>
            {!!skillList && (
              <WhiteBlock>
                <TitleIcon>
                  <img src={Images.icons.rolesIcon} alt="roles" />
                  <H5>Roles Description</H5>
                </TitleIcon>
                <Paragraph>
                  {!!skillList && skillList.role_description}
                </Paragraph>

                <TitleIcon>
                  <img src={Images.icons.starIcon} alt="roles" />
                  <H5>Responsibilities</H5>
                </TitleIcon>
                <Paragraph style={{ lineHeight: "28px" }}>
                  {!!skillList && (
                    <ShowMoreText
                      lines={12}
                      more="Show more"
                      less="Show less"
                      anchorClass=""
                      onClick={undefined}
                      expanded={false}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: skillList.roles_and_responsibilities.replace(
                            /\|/g,
                            "<br />"
                          ),
                        }}
                      />
                    </ShowMoreText>
                  )}
                </Paragraph>

                <TitleIcon>
                  <img src={Images.icons.bulpIcon} alt="roles" />
                  <H5>Industry Skills</H5>
                </TitleIcon>
                <RoleListSplit>
                  {!!industrySkill &&
                    industrySkill.map((el, i) => <li key={i}>{el.skill}</li>)}
                </RoleListSplit>
              </WhiteBlock>
            )}
          </Col6>
        </Row>
      </Container>
    </DetailContent>
  );
};

const DetailContent = styled.div`
  background-color: ${COLORS.BODY_ASH};
  border-top-left-radius: 30px;
  min-height: calc(100vh - 152px);
  padding: 25px 20px;
  p {
    font-size: 12px;
    line-height: 22px;
    letter-spacing: -0.06px;
    a {
      color: ${COLORS.PRIMARY_RED};
    }
  }
`;

const WhiteBlock = styled.div`
  background-color: ${COLORS.PRIMARY_WHITE};
  border-radius: 8px;
  box-shadow: 0 1px 3px 0 rgba(97, 122, 163, 0.24);
  margin-bottom: 10px;
  padding: 25px;

  .dropDownBlock {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .dropDownInner {
      margin-right: 15px;
      flex: 1;
    }
  }
`;

const ManBg = styled(WhiteBlock)`
  /* background: #ffffff url(${Images.bgImgs
    .ManLapImg}) right -10px top no-repeat; */
`;

const TitleIcon = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  img {
    margin-right: 10px;
  }
  h5 {
    padding-bottom: 0px;
  }
`;

const RoleList = styled.ul`
  padding-left: 30px;
  li {
    color: ${COLORS.TEXT_BLUE};
    font-size: 12px;
    line-height: 18px;
    padding-bottom: 20px;
  }
`;

const RoleListSplit = styled(RoleList)`
  display: flex;
  flex-wrap: wrap;
  li {
    flex: 0 0 50%;
  }
`;
