import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import styled from "styled-components";
import { Link } from "@reach/router";
import COLORS from "../../assets/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& .MuiDrawer-paper": {
      boxShadow: "1px 0 10px 0 rgba(0, 0, 0, 0.03)",
      background: "linear-gradient(170deg, #db0852 -10%, #5d0a90 101%)",
      borderRadius: 15,
      width: 320,
    },
  },
}));

export default ({ open, toggleDrawer, roleClick, userType }) => {
  const classes = useStyles();

  return (
    <Drawer
      anchor={"left"}
      open={open}
      onClose={toggleDrawer}
      className={classes.root}
    >
      <MainMenu onClick={toggleDrawer}>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/personas">Define Client Personas / Roles</Link>
        </li>
        <li>
          <a href="javascript:void()" onClick={roleClick}>
            Architect Skills for Roles
          </a>
        </li>
        <li>
          <Link to="/role_explorer">Role Explorer</Link>
        </li>
        {userType === 2 && (
          <>
            <li>
              <Link to="/annotate-jobs">Annotate Jobs</Link>
            </li>
            <li>
              <Link to="/skill/search">Add / Maintain Skills</Link>
            </li>
            <li>
              <Link to="/users">Add / Manage Users</Link>
            </li>
            <li>
              <Link to="/clients">Add / Manage Clients</Link>
            </li>
          </>
        )}
      </MainMenu>
    </Drawer>
  );
};

const MainMenu = styled.ul`
  padding: 66px 0px 20px 60px;
  li {
    height: 65px;
    line-height: 20px;
    display: flex;
    align-items: center;
    a {
      color: ${COLORS.PRIMARY_WHITE};
      display: block;
      font-weight: 600;
      padding: 10px 25px;
      opacity: 0.8;
      transition: all 0.2 linear;
      &:hover {
        opacity: 1;
      }
    }
  }
`;
