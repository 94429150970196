import React, { memo } from "react";
import RoleCompareBody from "./RoleCompareBody";
import RoleCompareHeader from "./RoleCompareHeader";

export default memo((props) => {
  return (
    <div
      style={{
        backgroundColor: "white",
        // paddingTop: props.stickyVal ? 252 : 0,
      }}
    >
      <RoleCompareHeader {...props} />
      <RoleCompareBody {...props} />
    </div>
  );
});
