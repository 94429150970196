import React, { useState } from "react";
import clsx from "clsx";

import { makeStyles, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import COLORS from "../../../assets/colors";
import styled from "styled-components";

export default ({
  list = [],
  filterLabel,
  style,
  placeholder,
  label,
  value,
  inputVal,
  optionChange = () => {},
  groupLabel,
  inputBoxChange = () => {},
  PopperComponent,
  autoFocus = false,
  valueCarry = false,
  selectAll = false,
  unselectAll = false,
  selectAllClick = () => {},
  disabled = false,
  focusCheckDisabled,
  open = false,
  onFocus = () => {},
}) => {
  const classes = useStyles();
  const [valueChanged, setValueChanged] = useState(false);

  const classText = clsx(
    classes.root,
    valueCarry ? classes.valueCarryCSS : null
  );

  // console.log(list);
  // console.log(value, " - ", inputVal, valueCarry, valueChanged);
  return (
    <div style={{ position: "relative" }}>
      <Autocomplete
        // open={open && !valueChanged}
        // open
        id="combo-box-demo"
        className={classes.autocomp}
        options={list}
        getOptionLabel={(option) =>
          option[filterLabel] ? option[filterLabel] : ""
        }
        // getOptionDisabled={(option) =>
        //   option[filterLabel] === placeholder || value
        // }
        style={style}
        value={value}
        onChange={(event, newValue, reason) => {
          setValueChanged(true);
          optionChange(newValue, reason);
        }}
        onFocus={() =>
          valueCarry && !focusCheckDisabled ? setValueChanged(true) : undefined
        }
        // onBlur={alert("lo22")}
        inputValue={(valueCarry && valueChanged) || !valueCarry ? inputVal : ""}
        onInputChange={(event, newInputValue) => {
          !!event && inputBoxChange(newInputValue);
        }}
        groupBy={!!groupLabel ? (option) => option[groupLabel] : undefined}
        PopperComponent={
          !!PopperComponent && !!list && !!list.length
            ? PopperComponent
            : undefined
        }
        openOnFocus={true}
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            defaultValue={valueCarry ? placeholder : undefined}
            inputProps={{
              ...params.inputProps,
            }}
            placeholder={placeholder}
            className={classText}
            label={label}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            autoFocus={autoFocus}
            title={value || inputVal}
          />
        )}
      />
      {selectAll && (
        <SelectAll onClick={selectAllClick}>
          {!unselectAll ? "Select All" : "Unselect All"}
        </SelectAll>
      )}
    </div>
  );
};

const SelectAll = styled.div`
  color: ${COLORS.BLUE};
  width: max-content;
  cursor: pointer;
  position: absolute;
  left: 340px;
  top: -2px;
  transform: translateX(-100%);
`;

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-multiline": {
      // borderRadius: "0px",
      padding: 0,
    },
    "& .MuiOutlinedInput-input": {
      color: COLORS.TEXT_BLUE,
      fontSize: 12,
      padding: "6.5px !important",
      "&::placeholder": {
        color: "inherit",
      },
    },
    "& .MuiAutocomplete-groupLabel": {
      fontWeight: "bold",
    },
  },
  valueCarryCSS: {
    "& .MuiOutlinedInput-input": {
      "&::placeholder": {
        opacity: 1,
      },
    },
  },
  autocomp: {
    "& .MuiAutocomplete-inputRoot": {
      backgroundColor: COLORS.PRIMARY_WHITE,
      border: `solid 1px ${COLORS.C4_BORDER}`,
      padding: 3,
    },
    "&  .MuiOutlinedInput-notchedOutline": {
      border: "none",
      background: "none",
    },
    "& .MuiInputLabel-formControl": {
      position: "static",
      transform: "none",
      paddingBottom: 10,
      color: "#333860",
      fontSize: 16,
      fontFamily: "inherit",
      fontWeight: "600",
      whiteSpace: "nowrap",
    },
    "& .MuiAutocomplete-groupLabel": {
      fontWeight: "bold",
    },
  },
  activeComp: {
    "&:hover .MuiAutocomplete-clearIndicator": {
      visibility: "visible",
    },
  },
}));
