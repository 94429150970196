import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import InputBox from "./InputBox";
import { SecondaryButton } from "../Buttons/SecondaryButton";
import Chip from "@material-ui/core/Chip";
import Label from "./Label";
import { PrimaryButton } from "../Buttons/PrimaryButton";
import { Images } from "../../../assets/images";
import COLORS from "../../../assets/colors";

export default ({ listValue, saveChip, chipClick, removeChip, label }) => {
  const classes = useStyles();
  const [showBox, setShowBox] = useState(false);
  const [chipVal, setChipVal] = useState("");
  const chipAdd = () => setShowBox((prev) => !prev);

  useEffect(() => {
    setChipVal("");
  }, [listValue, showBox]);

  const savaClick = () => {
    saveChip(chipVal);
    document.getElementById("chipBox").focus();
  };

  const saveDisabled =
    listValue
      .split("|")
      .some((el) => el.trim().toLowerCase() === chipVal.trim().toLowerCase()) ||
    !chipVal;

  return (
    <>
      {!!label && <Label>{label}</Label>}
      <div className={classes.root}>
        {!!listValue &&
          listValue
            .split("|")
            .map((el, i) => (
              <>
                {!!el && (
                  <Chip
                    key={i}
                    size="medium"
                    label={el}
                    onDelete={() => removeChip(el)}
                    onClick={() => chipClick(el)}
                    color="primary"
                  />
                )}
              </>
            ))}
        <AddCancelButton
          onClick={chipAdd}
          showBox={showBox}
          title={showBox ? "Cancel" : "Add"}
        >
          <div class="leftright"></div>
          <div class="rightleft"></div>
        </AddCancelButton>
      </div>
      {showBox && (
        <ChipBlock>
          <InputBox
            autoFocus={true}
            value={chipVal}
            onChange={(e) => setChipVal(e.target.value)}
            id="chipBox"
          />
          <PrimaryButton
            disabled={saveDisabled}
            onClick={savaClick}
            className="save-btn"
            style={{ opacity: chipVal.length > 0 ? 1 : 0 }}
            title="Save"
          >
            <img src={Images.icons.saveIcon} alt="save" />
          </PrimaryButton>
        </ChipBlock>
      )}
    </>
  );
};

const ChipBlock = styled.div`
  display: flex;
  position: relative;
  width: 336px;
  .MuiOutlinedInput-root {
    border-radius: 20px;
    padding-right: 34px;
  }
  .save-btn {
    border-radius: 16px;
    position: absolute;
    min-width: 32px;
    min-height: 32px;
    padding: 5px;
    top: 3.22px;
    right: 3.22px;
    visibility: visible;
    transition: all 0.2s ease-in;
  }
  button {
    margin-left: 15px;
    &:disabled {
      border-color: transparent;
    }
  }
`;

const AddCancelButton = styled(SecondaryButton)`
  padding: 0px !important;
  min-width: 32px !important;
  min-height: 32px !important;
  border-radius: 16px !important;

  div {
    background-color: ${COLORS.PRIMARY_PURPLE};
    height: 1px;
    width: 16px;
    position: absolute;
    transition: all 0.2s ease-in;
  }

  .leftright {
    transform: ${(props) =>
      props.showBox ? `rotate(-45deg)` : `rotate(90deg)`};
  }

  .rightleft {
    transform: ${(props) => (props.showBox ? `rotate(45deg)` : `rotate(0deg)`)};
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    marginBottom: 15,
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
}));
