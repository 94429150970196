import React from "react";
import { Scrollbars } from "react-custom-scrollbars";

import { Popper } from "@material-ui/core";
import styled from "styled-components";
import COLORS from "../../../../assets/colors";

export default (props) => {
  return (
    <Scrollbars>
      <Popper {...props} placement="bottom-start">
        <PopperTitle>
          <li>Skill Name</li>
          <li>Skill Description</li>
          <li>Skill Family</li>
          <li>Category</li>
        </PopperTitle>
        <PopperBlock>{props.children}</PopperBlock>
      </Popper>
    </Scrollbars>
  );
};

const PopperTitle = styled.ul`
  background-color: ${COLORS.LIGHT_MERON};
  color: ${COLORS.TITLE_BLUE};
  font-size: 14px;
  font-weight: 600;
  display: flex;
  li {
    padding: 10px;
    &:first-child {
      flex: 0 0 15%;
    }
    &:nth-child(2) {
      flex: 0 0 55%;
    }
    &:nth-child(3) {
      flex: 0 0 15%;
    }
    &:last-child {
      flex: 0 0 15%;
    }
  }
`;

const PopperBlock = styled.div`
  padding: 0px;
  .MuiAutocomplete-option {
    padding: 5px 0px;
  }
  .MuiAutocomplete-paper {
    margin: 0px;
  }
`;
