export default (dataUrl, filename) => {
  const link = document.createElement("a");
  link.href = dataUrl;
  // link.setAttribute("target", "_blank");
  link.setAttribute("download", filename);
  document.body.appendChild(link);

  link.click();
  link.parentNode.removeChild(link);
};
