import React from "react";
import { H5 } from "../Typography";

export default ({
  text = "Total records:",
  count = 0,
  textAlign = "right",
}) => (
  <H5 style={{ textAlign, padding: "0px 15px 0px 0px" }}>
    {text + " "} {count}
  </H5>
);
