import { FileTypes } from "../enums/FileType";
import ImageIcon from '@material-ui/icons/Image';
import AudiotrackIcon from '@material-ui/icons/Audiotrack';
import MovieIcon from '@material-ui/icons/Movie';
import DescriptionIcon from '@material-ui/icons/Description';
import React from "react";
import { Images } from "../assets/images";
import { FileFormats } from "../enums/FileFormats";

/**
 * Converts a string representation of file size to bytes.
 * 
 * @param {string} sizeString - The string representation of the file size, e.g., "10kb", "2.5mb".
 * @returns {number} The size in bytes.
 */
export const convertToBytes = (sizeString) => {
  const units = {
    kb: 1024, 
    mb: 1024 * 1024, 
    gb: 1024 * 1024 * 1024, 
    tb: 1024 * 1024 * 1024 * 1024, 
  };
  const [, number, unit] = sizeString.match(/^(\d+(?:\.\d+)?)\s*(kb|mb|gb|tb)$/i);
  return parseFloat(number) * units[unit.toLowerCase()];
};

export const fileTypeFromExtension = (extension) => {
    switch (extension) {
      case FileFormats.PNG:
      case FileFormats.JPG:
      case FileFormats.JPEG:
        return FileTypes.IMAGE;
      case FileFormats.MP4:
      case FileFormats.MOV:
        return FileTypes.VIDEO;
      case FileFormats.MP3:
      case FileFormats.WAV:
      case FileFormats.AAC:
        return FileTypes.AUDIO;
      case FileFormats.ZIP:
      case FileFormats.RAR:
        return FileTypes.ZIP;
      case FileFormats.XLSX:
        return FileTypes.XLSX;
      default:
        return FileTypes.DOCUMENT;
    }
  };

export const getFileExtensionFromFileName = (fileName) => {
  return fileName.slice(fileName.lastIndexOf(".")).toLowerCase();
};

export const isValidFileType = (extension, allowedFileTypes) => {
  if (allowedFileTypes.length === 0) return true;
  return allowedFileTypes.includes(extension);
};

export const getFileIcon = (extension) => {
    const fileType = fileTypeFromExtension(extension);
    switch (fileType) {
        case FileTypes.ZIP:
          return <img src={Images.icons.zipIcon} />
        case FileTypes.XLSX:
          return <img src={Images.icons.xlsxIcon} />
        case FileTypes.IMAGE:
          return <ImageIcon />;
        case FileTypes.AUDIO:
          return <AudiotrackIcon />;
        case FileTypes.VIDEO:
          return <MovieIcon />;
        default:
        return <DescriptionIcon />;
    }
};
