import React, { createContext, useState, useMemo, useEffect } from "react";
import { SortString } from "../helpers/SortString";
import { AuthService } from "../services/AuthService";
import useRoles from "../Hooks/useRoles";
import { Common } from "../store";

export const MasterListContext = createContext();

export default function MasterListProvider({ children }) {
  const [industryRolesMasterList, setIndustryRolesMasterList] = useState([]);
  const [personasMasterList, setPersonasMasterList] = useState(null);
  const clientNameCommon = Common.getClientName();
  const { clientName } = useRoles();

  // useEffect(() => {
  //   const initData = {
  //     sector: [],
  //     subSector: [],
  //     function: [],
  //     framework: [],
  //   };

  //   const getIndustryRoles = async () => {
  //     try {
  //       const res = await AuthService.getIndustryRoleList(initData);
  //       setIndustryRolesMasterList(SortString(res, "role_cluster"));
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };

  //   getIndustryRoles();

  //   return () => {
  //     setIndustryRolesMasterList(null);
  //   };
  // }, []);

  // useEffect(() => {
  //   // console.log(clientName);
  //   const getAllPersonas = async () => {
  //     try {
  //       if (!!clientName && clientNameCommon === clientName) {
  //         const resList = await AuthService.getPersonasList(
  //           `?clientId=${clientName}`,
  //           `&pagesize=${1000}`,
  //           `&offset=${0}`
  //         );
  //         setPersonasMasterList(SortString(resList, "persona_group"));
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   getAllPersonas();
  //   return () => {
  //     setPersonasMasterList(null);
  //   };
  // }, [clientName]);

  const value = useMemo(() => {
    return {
      industryRolesMasterList,
      setIndustryRolesMasterList,
      personasMasterList,
      setPersonasMasterList,
    };
  }, [
    industryRolesMasterList,
    setIndustryRolesMasterList,
    personasMasterList,
    setPersonasMasterList,
  ]);
  return (
    <MasterListContext.Provider value={value}>
      {children}
    </MasterListContext.Provider>
  );
}
