export const Disprz_Skills_Table = [
    {
      colKey: "checkbox",
      colValue: "checkbox",
      width: 60,
    },
    {
      colType: "skillId",
      colKey: "skillName",
      colValue: "Skill Name",
      disableToolTip: true,
      width: 300
    },
    {
      colKey: "skillCategory",
      colValue: "Skill Category",
      width: 300
    },
    {
      colKey: "skillDescription",
      colValue: "Skill Description",
      width: 300
    },
    {
      colType: "levelSelect",
      colKey: "selectLevel",
      colValue: "Industry Proficiency",
      editable: true,
      width: 260,
    }
  ];

export const Non_Disprz_Skills_Table = [
    {
      colKey: "skillName",
      colValue: "Skill Name",
      width: 300
    },
    {
      colKey: "skillDescription",
      colValue: "Skill Description",
      width: 300
    }
  ];
