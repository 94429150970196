import React, { useEffect, useState, createRef, useRef } from "react";
import {
  BodyContent,
  Container,
  Col6,
  Row,
  Col4,
} from "../../Common/GridSystem";
import styled from "styled-components";

import Layout from "../../Layout";
import { H2, H4 } from "../../Common/Typography";
import { BackCheck } from "../../Common/BackNav";
import MatchRole from "./MatchRole";
import { AuthService } from "../../../services/AuthService";
import COLORS from "../../../assets/colors";
import useRoles from "../../../Hooks/useRoles";
import Suggestions from "../../JobDetail/Suggestions";
import PersonaForm from "./PersonaForm";
import { PrimaryButton } from "../../Common/Buttons/PrimaryButton";
import { SecondaryButton } from "../../Common/Buttons/SecondaryButton";
import RolesDescription from "./RolesDescription";
import useFullLoader from "../../../Hooks/useFullLoader";
import useAlert from "../../../Hooks/useAlert";
import { Images } from "../../../assets/images";
import { navigate } from "@reach/router";
import ToolBar from "../../Common/ToolBar";
import useAuth from "../../../Hooks/useAuth";
import ModalPopup from "../../Common/ModalPopup";
import { SortString } from "../../../helpers/SortString";
import ConfirmationButton from "../../Common/ConfirmationButton";
import { UIStore } from "../../../store/store_state";

import useSubSectorList from "../../../Hooks/useSubSectorList";
import SubSectorMenuList from "../../utils/SubSectorMenuList";
import MasterSubSectorListProvider from "../../../Provider/SubSectorListProvider";
import { SourceType } from "../../../enums/SourceType";
import text from "../../../locale.en.json";
import { RoleServices } from "../../../services/RoleServices";
import { PersonaService } from "../../../services/PersonaService";
import { FileFormats } from "../../../enums/FileFormats";
import { getErrorInfo } from "../../../helpers/CommonHelper";
import { AlertType } from "../../../enums/AlertType";
import { Modes } from "../../../enums/Modes";

export default (props) => (
  <Layout>
    <MasterSubSectorListProvider passClientId={true}>
      <AddUpdatePersona {...props} />
    </MasterSubSectorListProvider>
  </Layout>
);

export const AddUpdatePersona = (props) => {
  const { mode, id, copy, uri } = props;
  const dublicate = mode === "edit" && JSON.parse(copy);

  const {
    industryRole,
    setIndustryRole,
    setSubsector,
    subsector,
    funcval,
    setFuncval,
    clientName,
    laederRole,
    setLaederRole,
  } = useRoles();

  // console.log("AddUpdatePersona -> clientName", clientName);
  const { userDetails } = useAuth();
  const { subSectorsList, personaGroupList } = useSubSectorList();

  const { setFullLoader } = useFullLoader();
  const { showAlert } = useAlert();

  const fileInputRef = useRef(null);
  const [personaList, setPersonaList] = useState(null);
  const [suggestionBar, setSuggestionBar] = useState(false);
  const [popupNavgate, setPopupNavgate] = useState(false);
  const [redirectValue, setRedirectValue] = useState("");
  const [personaIDAdded, setPersonaIDAdded] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [roleDetail, setRoleDetail] = useState(null);
  const [suggestionRoles, setSuggestionRoles] = useState(null);
  const [personaDetail, setPersonaDetail] = useState(null);
  const [topSubSector, setTopSubSector] = useState("Any");

  const [confirmPopup, setConfirmPopup] = useState(false);
  const [confirmType, setConfirmType] = useState(null);
  const [confirmTitle, setConfirmTitle] = useState("");
  const [isClientBasedOnDisprzAtomicSource, setIsClientBasedOnDisprzAtomicSource] = useState(false);
  const [refreshKey, setRefreshKey] = useState(new Date());
  const allowedFileFormats = [FileFormats.DOC, FileFormats.DOCX, FileFormats.PDF];

  const handleSubSector = (val) => setTopSubSector(val);

  useEffect(() => {
    setTopSubSector(subsector);
    return () => {
      setTopSubSector("Any");
    };
  }, [subsector]);

  let personaFormRef = createRef();

  const getAndUpdateClientSource = async () => {
    const clientDetail = await AuthService.getClientDetailV3(clientName);
    const clientSourceList = clientDetail?.sourceList;
    if(clientSourceList && clientSourceList?.length === 1 && clientDetail?.sourceList[0]?.sourceId === SourceType.DISPRZ_ATOMIC_SKILLS){
      setIsClientBasedOnDisprzAtomicSource(true);
    }
  }

  useEffect(() => {
    if(!!clientName){
      getPersonas();
      getAndUpdateClientSource();
    }
  }, [clientName]);

  useEffect(() => {
    const getDetail = async () => {
      setFullLoader(true);
      try {
        const res = await AuthService.getPersonaDetailV3("/" + Number(id));
        setPersonaDetail(res);
        setRefreshKey(new Date());
        // console.log("getDetail -> res", res);

        setIndustryRole(res.industryRole.roleId);
        // console.log(res);
        if(mode === "edit"){
          setLaederRole(res.leadershipRole.leadershipRoleId);
        }
      } catch (error) {
        showAlert(error.error, "error", 1500);
        console.log(error);
      } finally {
        setFullLoader(false);
      }
    };
    if (mode === "edit") getDetail();
    return () => {
      setIndustryRole("Any");
      setSubsector("Any");
      setFuncval("Any");
      setPersonaDetail(null);
    };
  }, [mode, id]);

  useEffect(() => {
    if (!!personaList)
      setCurrentIndex(
        SortString(
          personaList.map((el) => ({
            ...el,
            personaGroupName: el.personaGroup.personaGroupName,
          })),
          "personaGroupName"
        ).findIndex((el) => el.personaId === Number(id))
      );
    return () => {
      setCurrentIndex(0);
    };
  }, [personaDetail, personaList]);

  useEffect(() => {
    const getIndustryRoles = async () => {
      setFullLoader(true);
      try {
        const res = await AuthService.getSkillListV3("/" + industryRole);
        setRoleDetail(res);
        if (subsector === "Any" || subsector === 0) {
          setSubsector(res.subsectorId);
        }
        if (funcval === "Any") {
          setFuncval(res.functionId);
        }
        if(mode === "add"){
          setLaederRole(
            !!res.leadershipRole ? res?.leadershipRole?.leadershipRoleId : "Any"
          );
        }
      } catch (error) {
        console.log(error);
      } finally {
        setFullLoader(false);
      }
    };
    if (industryRole !== "Any" && industryRole !== 0) getIndustryRoles();
    return () => {
      setRoleDetail(null);
      // setSubsector("Any");
      // setFuncval("Any");
    };
  }, [industryRole]);

  const getSuggestionList = async () => {
    setFullLoader(true);
    try {
      const roleSuggestionsFilter = {
        clientId: Number(clientName),
        roleSuggestionsRequest: [{
          rolesAndResponsibilities: personaFormRef.current.values.rolesResponsibilities,
          personaName: personaFormRef.current.values.personaName,
          groupName: personaFormRef.current.values.personaGroupId,
          RoleDescription: personaFormRef.current.values.roleDescription,
          Industry: topSubSector === "Any" ? "" : subSectorsList?.find((subsector) => subsector.subsectorId === topSubSector)?.subsector,//check and remove
        }]
      };
      const response = await RoleServices.GetRoleSuggestionsInBulkV3(roleSuggestionsFilter);
      if(!!response && response?.length > 0){
        const resRoleIds = response[0]?.roles?.map(r => ({
            roleId: r.roleId,
            roleName: r.roleName
        })) || [];
        setSuggestionRoles(resRoleIds);
      };
      setSuggestionBar(true);
    } catch (error) {
      showAlert("No suggestions found!", "info", 1500);
    } finally {
      setFullLoader(false);
    }
  };

  const roleSuggestionsAlert = {
    'bothEmpty': text.persona.groupNameAndPersonaNameCannotBeEmpty,
    'groupNameEmpty': text.persona.groupNameEmpty,
    'personaNameEmpty': text.persona.personaNameEmpty
  };

  const showSuggestion = () => {
    const values = personaFormRef?.current?.values;
    const conditions = {
      'bothEmpty': !values?.personaGroupId && !values?.personaName,
      'groupNameEmpty': !values?.personaGroupId,
      'personaNameEmpty': !values?.personaName
    };
    const conditionKeys = Object.keys(conditions);
    for (const key of conditionKeys) {
      if (conditions[key]) {
        showAlert(roleSuggestionsAlert[key], "info", 2500);
        return;
      }
    }
    getSuggestionList();
  };

  const handleListClick = async (val) => {
    const getIndustryRoles = async () => {
      try {
        const res = await AuthService.getSkillListV3("/" + val.roleId);
        // console.log(res);
        await setSubsector(res.subsectorId !== "" ? res.subsectorId : "Any");
        await setFuncval(res.functionId !== "" ? res.functionId : "Any");
        await setIndustryRole(res.roleId !== 0 ? res.roleId : "Any");
        setRoleDetail(res);
      } catch (error) {
        showAlert(error.Message, "error", 1500);
      }
    };

    getIndustryRoles();
  };

  const getGroupId = async (val) => {
    const groupId =
      personaGroupList?.find((el) => el.label === val.personaGroupId)?.value ||
      0;

    const postData = {
      personaGroupName: val.personaGroupId,
      clientId: Number(clientName),
    };

    if (groupId) {
      return groupId;
    } else {
      const res = await AuthService.createGroupIdV3(postData);
      return res;
    }
  };
  // console.log(personaGroupList);
  const formSubmit = async () => {
    if (!!industryRole && industryRole !== "Any") {
      const { isValid, values, resetForm } = personaFormRef.current;
      console.log("formSubmit -> values", values, personaFormRef.current);

      await personaFormRef.current.handleSubmit();

      setTimeout(async () => {
        if (
          isValid &&
          Object.keys(personaFormRef.current.errors).length === 0
        ) {
          const personaGroupId = await getGroupId(values);
          try {
            setFullLoader(true);
            const personaDetail = {
              ...values,
              personaGroupId,
              clientId: Number(clientName),
              industryRoleId: Number(industryRole),
              leadershipRoleId: Number(laederRole)
            };
            // console.log(personaDetail);
            // return false;
            if (mode === "add" || dublicate) {
              const res = await AuthService.addPersonaV3(personaDetail);
              setPersonaIDAdded(res);
              // open popup
              await setFullLoader(false);
              setTimeout(() => setPopupNavgate(true), 100);
              // navigate("/personas");
            } else {
              await AuthService.updatePersonaV3(personaDetail, "/" + id);
              await setFullLoader(false);
              closePopup();
              resetForm({ values });
              await showAlert("Persona Updated Successfully", "success", 1500);
              // setTimeout(() => navigate("/personas"), 100);
            }
          } catch (errors) {
            // console.log(errors);
            showAlert(errors.error, "error", 1500);
          } finally {
            setFullLoader(false);
          }
        }
      }, 100);
    } else {
      showAlert("Please select an Industry Role!", "info", 1500);
    }
  };

  const handleDelete = async () => {
    setFullLoader(true);
    try {
      await AuthService.deletePersonaV3([id]);
      await setFullLoader(false);
      await showAlert("Persona Deleted Successfully", "success", 1500);
      setTimeout(() => navigate("/personas"), 100);
      // navigate("/personas");
    } catch (errors) {
      console.log(errors);
    } finally {
      setFullLoader(false);
    }
  };

  const handleArchTech = () => {
    UIStore.update((s) => {
      s.fromPersonaList = true;
    });
    setTimeout(
      () => navigate(`/persona/architect-skills/${personaDetail.personaId}`),
      100
    );
  };

  const handleAnnotateRole = async () => {
    setFullLoader(true);
    try {
      if (
        industryRole !== "Any" &&
        industryRole !== 0 &&
        laederRole !== "Any" &&
        laederRole !== 0
      ) {
        await AuthService.postAnnotateRoleV3(
          `?leaderShipRoleId=${laederRole}`,
          "&roleId=" + industryRole
        );
        showAlert("Role Annotation Successful", "success", 1500);
      } else {
        showAlert(
          "Kindly select an Industry Role before annotating the Leadership Role",
          "info",
          1500
        );
      }
    } catch (error) {
      showAlert(error.Message, "error", 1500);
    } finally {
      setFullLoader(false);
    }
  };

  const handlePrev = () => {
    setSuggestionRoles(null);
    const navPersona = personaList[currentIndex - 1];
    navigate(`/persona/edit/${navPersona.personaId}/${JSON.parse(copy)}`);
  };

  const handleNext = () => {
    setSuggestionRoles(null);
    const navPersona = personaList[currentIndex + 1];
    // console.log("handleNext -> navPersona", navPersona);
    // navigate(
    //   `/persona/edit/${encodeURIComponent(
    //     navPersona?.personaGroup?.personaGroupName || ""
    //   )}/${navPersona.personaId}/${JSON.parse(copy)}`
    // );
    navigate(`/persona/edit/${navPersona.personaId}/${JSON.parse(copy)}`);
  };
  
  const renderUploadJDBtn = () => (
    <>
      <input
        type="file"
        ref={fileInputRef}
        accept= {allowedFileFormats.join(",")} // Restrict file types
        onClick={(e) => {
          e.target.value = null;
        }}
        onChange={handleJDFileSelect}
        style={{ display: "none" }}
      />
      <SecondaryButton
        onClick={handleJDFileUpload}
        style={{ backgroundColor: COLORS.PRIMARY_WHITE, fontWeight: "normal" }}
      >
        <b>{text.persona.uploadJDBtnLabel}</b>({allowedFileFormats.join(", ").replaceAll(".", "").toUpperCase()})
      </SecondaryButton>
    </>
  );

  const ButtonWrap = ({ showUploadJDBtn = false }) => (
    <ButtonWrapper>
      <UpdateBtns>
        {(showUploadJDBtn && renderUploadJDBtn())}
        <PrimaryButton
          onClick={mode === "add" || dublicate ? formSubmit : updateTrigger}
        >
          {mode === "add" && "Add Persona +"}
          {mode === "edit" && !dublicate ? "Update Persona" : ""}
          {mode === "edit" && dublicate ? "Duplicate Persona" : ""}
        </PrimaryButton>
        {mode === "edit" && !dublicate ? (
          <SecondaryButton
            onClick={deleteTrigger}
            style={{ backgroundColor: COLORS.PRIMARY_WHITE }}
          >
            <img
              src={Images.icons.trashIcon}
              alt="delete"
              style={{ marginRight: 8 }}
            />
            <span>Delete Persona</span>
          </SecondaryButton>
        ) : null}
        {mode === "edit" && !dublicate ? (
                    <SecondaryButton
            onClick={handleArchTech}
            style={{ backgroundColor: COLORS.PRIMARY_WHITE }}
          >
            Architect Skills
          </SecondaryButton>
        ) : null}
      </UpdateBtns>
      {mode === "edit" && !dublicate ? (
        <NavBtns>
          <ToolBar title="Previous Persona">
            <span>
              <SecondaryButton
                onClick={handlePrev}
                disabled={currentIndex === 0}
              >
                <img src={Images.icons.lftArrowIcon} alt="prev" />
              </SecondaryButton>
            </span>
          </ToolBar>
          <ToolBar title="Next Persona">
            <span>
              <PrimaryButton
                onClick={handleNext}
                disabled={
                  !!personaList && currentIndex === personaList.length - 1
                }
              >
                <img src={Images.icons.ritArrowIcon} alt="next" />
              </PrimaryButton>
            </span>
          </ToolBar>
        </NavBtns>
              ) : null}
    </ButtonWrapper>
  );

  const getPersonas = async () => {
    const resList = await AuthService.getPersonasListV3(
      `?clientId=${clientName}`
    );
    setPersonaList(
      SortString(
        resList.map((el) => ({
          ...el,
          personaGroupName: el.personaGroup.personaGroupName,
        })),
        "personaGroupName"
      )
    );
  };

  const architectLink = `/persona/architect-skills/${personaIDAdded}`;

  const handleRedirect = () => {
    redirectValue !== architectLink
      ? navigate(redirectValue)
      : navigate(redirectValue, { replace: true });
  };

  const navOptions = [
    { label: "Go back to Home Screen", value: "/personas" },
    { label: "Add another Persona", value: "/persona/add" },
    {
      label: "Architect Skills for Persona",
      value: architectLink,
    },
  ];

  useEffect(() => {
    if (redirectValue !== "") {
      uri === "/persona/add" && redirectValue === "/persona/add"
        ? window.location.reload()
        : setTimeout(() => {
            popupNavgate === false && handleRedirect();
          }, 100);
    }
  }, [redirectValue]);

  const handleYes = () => {
    if (confirmType === "delete") {
      handleDelete();
    } else if (confirmType === "update") {
      formSubmit();
    } else if (confirmType === "redirect") {
      navigate("/personas");
    }
  };

  const handleNo = () => closePopup();
  const closePopup = () => {
    setConfirmPopup(false);
    setConfirmType(null);
    setConfirmTitle("");
  };

  const ConfirmModal = () => (
    <ModalPopup
      open={confirmPopup}
      maxWidth="sm"
      title={confirmTitle}
      center={true}
    >
      <ConfirmationButton yesClick={handleYes} noClick={handleNo} />
    </ModalPopup>
  );

  const updateTrigger = () =>
    confirmClick(true, "update", "Confirm Persona Updation");
  const deleteTrigger = () =>
    confirmClick(true, "delete", "Confirm Delete Persona");

  const handleCheckBack = () => {
    // console.log(personaFormRef?.current);
    const hasFormChange =
      (!!personaFormRef?.current && !!personaFormRef?.current?.dirty) || false;
    if (hasFormChange) {
      confirmClick(true, "redirect", "Proceed without saving changes?");
    } else {
      navigate("/personas");
    }
  };

  const confirmClick = (hasPop = true, cfType, cfTitle) => {
    setConfirmPopup(hasPop);
    setConfirmType(cfType);
    setConfirmTitle(cfTitle);
  };

  const handleJDFileUpload = () => {
    fileInputRef.current.click();
  };

  const handleJDFileSelect = async (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
        const formData = new FormData();
        formData.append("file", selectedFile);
        setFullLoader(true);
        try {
            const response = await PersonaService.parsePersonaJDV3(formData);
            let newPersona = {
              personaGroup: {
                personaGroupName: response.personaGroupName,
              },
              personaName: response.personaName,
              rolesResponsibilities: response.rolesAndResponsibilities,
              roleDescription: response.roleDescription
            };

            setPersonaDetail(prevState => ({
              ...prevState,
              ...newPersona
            }));
            setRefreshKey(new Date());
            await showAlert(text.persona.jdParsingSuccessResponse, AlertType.SUCCESS, 1500);
        } catch (error) {
          showAlert(getErrorInfo(error), AlertType.ERROR, 2000);
        }finally{
          setFullLoader(false);
        }

        
    }
};
  // console.log(personaFormRef);
  console.log(personaDetail);
  return (
    <>
      <HeaderNav>
        <H2>Persona Management</H2>
        <ButtonWrap showUploadJDBtn={!dublicate} />
      </HeaderNav>
      <BodyContent>
        <Container>
          {/* <BackNav linkNav="/personas" /> */}
          <BackCheck handleCheckBack={handleCheckBack} />
          {/* <NaviPopup /> */}
          <Row>
            <Col4 style={{ flex: "0 0 35%" }}>
              <WhiteBlock>
                {!!clientName && ((mode === Modes.EDIT && !!personaDetail) || mode === Modes.ADD) && (
                  <div key={refreshKey}>
                    <PersonaForm
                      ref={personaFormRef}
                      mode={mode}
                      data={personaDetail}
                      dublicate={dublicate}
                      clientName={clientName}
                      personaList={personaList || []}
                    />
                  </div>
                )}
              </WhiteBlock>
            </Col4>
            <Col6 style={{ flex: "0 0 65%" }}>
              <H4>Matching Industry Role</H4>
              <HeaderBlock>
                <SubSectorMenuList
                  handleSelect={handleSubSector}
                  value={topSubSector}
                  label="Limit sub-sector to"
                  width={150}
                  list={subSectorsList}
                />
                {isClientBasedOnDisprzAtomicSource && (
                  <SecondaryButton onClick={showSuggestion}>
                    Get Suggestions
                  </SecondaryButton>
                )}
              </HeaderBlock>

              <WhiteBlock>
                {suggestionBar && suggestionRoles ? (
                  <Suggestions
                    // list={Array.from(new Set(suggestionRoles))}
                    list={suggestionRoles}
                    keyName="roleName"
                    handleListClick={handleListClick}
                  />
                ) : null}
                <MatchRole
                  data={personaDetail}
                  mode={mode}
                  userData={userDetails}
                  roleDetail={roleDetail}
                />
                {!!roleDetail &&
                industryRole !== 0 &&
                industryRole !== "Any" ? (
                  <RolesDescription data={roleDetail} />
                ) : null}
              </WhiteBlock>
              <ButtonWrap />
            </Col6>
          </Row>
        </Container>
      </BodyContent>

      {popupNavgate && (
        <ModalPopup open={popupNavgate} fullWidth={false} center={true}>
          <NavOptionBlock>
            <H4>Persona Creation Successful!</H4>
            <div>
              {navOptions.map((el, i) => (
                <>
                  {i === 1 ? (
                    <SecondaryButton
                      onClick={() => {
                        setPopupNavgate(false);
                        setRedirectValue(el.value);
                      }}
                    >
                      {el.label}
                    </SecondaryButton>
                  ) : (
                    <PrimaryButton
                      onClick={() => {
                        setPopupNavgate(false);
                        setRedirectValue(el.value);
                      }}
                    >
                      {el.label}
                    </PrimaryButton>
                  )}
                </>
              ))}
            </div>
          </NavOptionBlock>
        </ModalPopup>
      )}
      <ConfirmModal />
    </>
  );
};

const WhiteBlock = styled.div`
  background-color: ${COLORS.PRIMARY_WHITE};
  border-radius: 8px;
  box-shadow: 0 1px 3px 0 rgba(97, 122, 163, 0.24);
  margin-bottom: 10px;
  padding: 25px;
  a {
    color: ${COLORS.PRIMARY_RED};
  }
  ul {
    padding-bottom: 30px;
  }
`;

const HeaderNav = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 35px 15px 0px;
  h2 {
    padding-bottom: 0px;
  }
`;

const HeaderBlock = styled.div`
  display: flex;
  align-items: center;
  h4 {
    padding: 0px 20px 0px 0px;
  }
  button {
    margin-left: 15px;
  }
  label {
    font-weight: 600;
    margin-right: 10px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  button {
    margin-left: 15px;
  }
`;
const UpdateBtns = styled.div`
  button {
    margin-left: 15px;
    &:first-child {
      margin-left: 0px;
    }
  }
`;
const NavBtns = styled.div`
  button {
    background-color: ${COLORS.BODY_BLUE};
    /* border-color: ${COLORS.PRIMARY_WHITE}; */
    min-width: 38px;
    padding: 5px;
    &:first-child {
      border-color: ${COLORS.PRIMARY_WHITE} !important;
    }
  }
`;

const NavOptionBlock = styled.div`
  text-align: center;
  & > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    & > button {
      margin: 15px;
    }
  }
`;
