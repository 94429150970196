import React from "react";
import Marquee from "react-fast-marquee";
import styled from "styled-components";
import COLORS from "../../../../assets/colors";
import { Images } from "../../../../assets/images";

export default ({ trendingSkills = [] }) => {
  return (
    <MarqueeTag>
      <Marquee pauseOnHover speed={80}>
        <span className="white-trending">
          <img src={Images.icons.icTrending} alt="up" />
        </span>
        <strong>Trending Skills: &nbsp;</strong>
        {trendingSkills.map((el, i) => (
          <span className="trend-skill" key={i}>
            {el.skill} {el.value}%{" "}
            <img
              src={!!el.value ? Images.icons.icUpHead : Images.icons.icDownHead}
              alt="up"
            />
            {trendingSkills?.length - 1 !== i && ", "}&nbsp;&nbsp;
          </span>
        ))}
      </Marquee>
    </MarqueeTag>
  );
};

const MarqueeTag = styled.div`
  margin: 0px -25px;
  .marquee-container {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    top: -24px;
    .overlay:before,
    .overlay:after {
      display: none;
    }
    .white-trending {
      background-color: ${COLORS.PRIMARY_WHITE};
      height: 22px;
      margin-right: 5px;
      width: 20px;
      svg {
        fill: ${COLORS.ASH_BORDER};
      }
    }
    .marquee {
      background-color: ${COLORS.BODY_BLUE};
      color: ${COLORS.PRIMARY_WHITE};
      padding: 5px;
    }
    .trend-skill {
      display: flex;
      align-items: center;
    }
  }
`;
