import React, { createContext, useState, useMemo, useEffect } from "react";
import { RoleServices } from "../services/RoleServices";

export const OcuupationContext = createContext();

export default function OcuupationProvider({ children }) {
  const [subsectorIds, setSubsectorIds] = useState([]);
  const [occupations, setOccupations] = useState([]);

  // console.log("OcuupationProvider -> subsectorIds", subsectorIds);
  let subsectorIdsParam =
    typeof subsectorIds === "number"
      ? `subSectorIds=${subsectorIds}`
      : subsectorIds?.map((el) => `subSectorIds=${el}`)?.join("&") ||
        "subSectorIds=";

  // console.log("OcuupationProvider -> subsectorIdsParam", subsectorIdsParam);

  const getOccupations = async () => {
    try {
      const res = await RoleServices.getOccupationsV3("?" + subsectorIdsParam);
      setOccupations(
        res
          .map((elem) => !!elem && elem.trim())
          .sort()
          .map((el) => ({ label: el, value: el }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if(!!subsectorIds && subsectorIds?.length > 0){
      getOccupations();
    }
  }, [subsectorIds]);

  const value = useMemo(() => {
    return {
      subsectorIds,
      setSubsectorIds,
      occupations,
      setOccupations,
    };
  }, [subsectorIds, setSubsectorIds, occupations, setOccupations]);
  return (
    <OcuupationContext.Provider value={value}>
      {children}
    </OcuupationContext.Provider>
  );
}
