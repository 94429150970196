import React, { useState, useRef } from "react";
import { H4, H5 } from "../../Common/Typography";
import styled from "styled-components";
import COLORS from "../../../assets/colors";
import { Images } from "../../../assets/images";
import ToolBar from "../../Common/ToolBar";
import { GenAiButton } from "../../Common/Buttons/GenAiButton";
import TextField from "@material-ui/core/TextField";
import ConfirmationButton from "../../Common/ConfirmationButton";
import { AIGeneratorType } from "../../../enums/AIGeneratorType";
import { useEffect } from "react";
import useFullLoader from "../../../Hooks/useFullLoader";
import useAlert from "../../../Hooks/useAlert";
import { AIStore } from "../../../store/store_state";
import ChipList from "../Others/ChipList";
import { navigateToTagURL, generateLevelDescriptionValues } from "../../../helpers/CommonHelper";

export default function AIGenerator ({
    cancelText='',
    generatorType=AIGeneratorType.DEFAULT,
    confirmText='',
    onCancel = () => {},
    onConfirm = () => {},
    getServiceUrl = () => {},
    params = {},
    placeholder = "",
    encycloCurrentList=[],
    levels = 0,
    aiStoreKey = "",
    updateLevelsDescriptions = () => {},
    ...props
}) {
    const suggestionRef = useRef(null);
    const scratchPadRef = useRef(null);
    const { setFullLoader } = useFullLoader();
    const { showAlert } = useAlert();

    const aiState = AIStore.useState((s) => s[aiStoreKey]);

    const aiSuggestedlevelDescriptionCopy = AIStore.useState((s) => s.aiSuggestedlevelDescriptionCopy);

    //states for handling ai-suggestion container
    const [hoveredItem, setHoveredItem] = useState(null);

    //staes for handling sratch-pad container
    const [descriptionValue, setDescriptionValue] = useState('');
    const [levelDescriptionValues, setLevelDescriptionValues] = useState(generateLevelDescriptionValues(levels));


    const handleDescriptionChange = (event) => {
        setDescriptionValue(event.target.value);
    };

    useEffect(() => {
     if(aiState?.length === 0){
       reGenerateAI();
     }
    },[])

    const handleAiCalls = async () => {
       try{
        setFullLoader(true);
        return await getServiceUrl(params);
       }catch (err) {
         showAlert("Please try again", "info", 1500);
       }finally{
         setFullLoader(false);
       }
    }

    const handleScroll = (ref) => {
        setTimeout(() => {
            ref.current.scroll({
                top: ref.current.scrollHeight,
                behavior: "smooth",
            });
        }, 10);
    }

    const reGenerateAI = async () => {
        //for handling wiki-tags
        if(generatorType === AIGeneratorType.TAG){
            const wikiTags = await handleAiCalls();
            const updatedAiSuggestedEncycloLists = [...wikiTags]?.map((wiki) => {
                if(!wiki?.encyclopediaId){
                  wiki.isInValidId = true
                }else if(encycloCurrentList.some((el) => el.encyclopediaId === wiki.encyclopediaId)){
                  wiki.isDisabled = true
                }
                return wiki;
              });
            AIStore.update((s) => {s[aiStoreKey] = [...updatedAiSuggestedEncycloLists]});
        }

        //for handling description-values (short & detailed)
        else if (generatorType === AIGeneratorType.DEFAULT || generatorType === AIGeneratorType.ROLES) {
            const res = await handleAiCalls();
            if(res?.value === 'Invalid skill'){
                showAlert("Please try again","info", 1500);
            }else if(!!res){
                AIStore.update((s) => { s[aiStoreKey] = [...aiState, res.value]} );
            }
            handleScroll(suggestionRef);
        }

        //for handling level-values (proficiency & knowledge)
        else if (generatorType === AIGeneratorType.LEVEL) {
          if(aiStoreKey === 'aiSuggestedProficienyDescription' && aiSuggestedlevelDescriptionCopy.aiSuggestedProficienyDescription.length > 0){
            updateLevelsDescriptions(aiSuggestedlevelDescriptionCopy.aiSuggestedProficienyDescription[0], {});
            AIStore.update((s) => {
              s.aiSuggestedlevelDescriptionCopy.aiSuggestedProficienyDescription = s.aiSuggestedlevelDescriptionCopy.aiSuggestedProficienyDescription.slice(1);
            });
          }
          else if(aiStoreKey === 'aiSuggestedKnowledgeDescription' && aiSuggestedlevelDescriptionCopy.aiSuggestedKnowledgeDescription.length > 0){
            updateLevelsDescriptions({}, aiSuggestedlevelDescriptionCopy.aiSuggestedKnowledgeDescription[0]);
            AIStore.update((s) => {
              s.aiSuggestedlevelDescriptionCopy.aiSuggestedKnowledgeDescription = s.aiSuggestedlevelDescriptionCopy.aiSuggestedKnowledgeDescription.slice(1);
            });
          }
          else{
            const res = await handleAiCalls(); 
            if(res?.length > 0){
                const updatedDescriptionValues = {};
                const updatedKnowledgeDescriptionValues = {};
                res.forEach(item => {
                    updatedDescriptionValues[`Level ${item.levelNumber}`] = item.description
                    updatedKnowledgeDescriptionValues[`Level ${item.levelNumber}`] = item.knowledge;
                });
                const isUpdateProficiencyDescription =  aiStoreKey === 'aiSuggestedProficienyDescription';
                updateLevelsDescriptions(isUpdateProficiencyDescription ? updatedDescriptionValues : {}, !isUpdateProficiencyDescription ? updatedKnowledgeDescriptionValues : {})
                AIStore.update((s) => {
                  isUpdateProficiencyDescription
                    ? s.aiSuggestedlevelDescriptionCopy.aiSuggestedKnowledgeDescription.push(updatedKnowledgeDescriptionValues)
                    : s.aiSuggestedlevelDescriptionCopy.aiSuggestedProficienyDescription.push(updatedDescriptionValues);
                });
            }else{
               showAlert("Please try again", "info", 1500);
            }
          }
          handleScroll(suggestionRef);
        }
    };

    const toggleWikiTagSelection = (val) => {
        const updatedEncycloLists = [...aiState].map((el) => {
          if (el.encyclopedia === val.encyclopedia && el.url === val.url) {
            return { ...el, isSelected: !el.isSelected };
          } else {
            return el;
          }
        });
      AIStore.update((s) => {s[aiStoreKey] = [...updatedEncycloLists]})
    }

    const renderSuggestionContentHeader = (index, item) => (
        <div className="output">
          <H5>Output {index + 1}</H5>
          <ToolBar title={"Copy to scratch pad"} arrow={true} darkMode={true}>
            <img
              src={Images.icons.copyIcon}
              alt="copy.png"
              className="copy-icon"
              onClick={() => {
                let sentences = item;
                if(generatorType === AIGeneratorType.ROLES){
                  let updatedSentences = sentences.trim().replace(/\n/g, '\n\n');
                  sentences = descriptionValue ? '\n\n' + updatedSentences : updatedSentences
                }
                setDescriptionValue(`${descriptionValue}${sentences}`);
                handleScroll(scratchPadRef);
              } } />
          </ToolBar>
          {index + 1 === aiState.length && <span className="new-option">new</span>}
        </div>
    )

    const  handleSuggestionContentClick = (sentence, sentences, i) => {
        let updatedDescription = '';
        if (generatorType === AIGeneratorType.DEFAULT) {
          updatedDescription = !!descriptionValue.trim() ? `${descriptionValue.trim()} ${sentence.trim()}` : sentence.trim()
        } else if (generatorType === AIGeneratorType.ROLES) {
          updatedDescription = !!descriptionValue.trim() ? `${descriptionValue}\n\n$${sentence.trim().replace(/\n/g, '')}` : `$${sentence.trim().replace(/\n/g, '')}`;
        }
        setDescriptionValue(`${updatedDescription}${generatorType === AIGeneratorType.DEFAULT ? (i === sentences.length - 1 ? '' : '.') : ''}`);
        handleScroll(scratchPadRef);
    }
  
    const renderSuggestionContent = (sentence, index, i, sentences) => (
        <ToolBar
          title={"Tap to add this line to scratch-pad"}
          darkMode={true}
          placement={'top'}
          arrow={"true"}
        >
          <span
            className={`${hoveredItem === sentence.trim() + index ? 'hovered' : ''}`}
            onMouseOver={() => setHoveredItem(sentence.trim() + index)}
            onMouseLeave={() => setHoveredItem(null)}
            onClick={() => {
              handleSuggestionContentClick(sentence, sentences, i);
            } }
          >
            {generatorType === AIGeneratorType.DEFAULT
              ? sentence + (i === sentences?.length - 1 ? '' : '. ') :
              generatorType === AIGeneratorType.ROLES
                ? (i === 0 ? sentence : '$' + sentence) :
                ''}
          </span>
        </ToolBar>
    )

    const renderLevelSuggestionHeader = (index) => (
      <div className="output">
        <H5>Output {index + 1}</H5>
        <ToolBar title={"Copy levels to scratch pad"} arrow={true} darkMode={true}>
          <img src={Images.icons.copyIcon}
            alt="copy.png" className="copy-icon"
            onClick={() => {
              const updatedLevelDescriptionValues = { ...levelDescriptionValues };
              for (let i = 1; i <= levels; i++) {
                const currentValue = updatedLevelDescriptionValues[`Level ${i}`].trim();
                const newValue = aiState[index][`Level ${i}`].trim();
                updatedLevelDescriptionValues[`Level ${i}`] =
                  currentValue && newValue ? `${currentValue} ${newValue}` : `${currentValue}${newValue}`;
              }
              setLevelDescriptionValues(updatedLevelDescriptionValues);
              // handleScroll(scratchPadRef);
            } } />
        </ToolBar>
        {index + 1 === aiState.length && <span className="new-option">new</span>}
      </div>
    )

    const renderLevelSuggestionContent = (i, item, key) => (
      <div style={{ display: "flex", gap: '10px' }}>
        <div style={{ minWidth: 'fit-content', color: '#333860' }}>Level {i + 1}: </div>
        <div>
          {item[key].split(/\.\s/).map((sentence, index, sentences) => (
            sentence.trim() &&
            <ToolBar title={"Tap to add this line to scratch-pad"} darkMode={true} placement={'top'} arrow={"true"}>
              <span
                key={key}
                className={`${hoveredItem === sentence.trim() ? 'hovered' : ''}`}
                onMouseEnter={() => setHoveredItem(sentence.trim())}
                onMouseLeave={() => setHoveredItem(null)}
                onClick={() => {
                  const updatedLevelDescription = !!levelDescriptionValues[`Level ${i + 1}`].trim() ? levelDescriptionValues[`Level ${i + 1}`].trim() + ' ' + sentence.trim() : sentence.trim();
                  setLevelDescriptionValues({ ...levelDescriptionValues, [`Level ${i + 1}`]: updatedLevelDescription + (index === sentences.length - 1 ? '' : '.') });
                  // handleScroll(scratchPadRef);
                } }
                style={{ color: '#333860' }}
              >
                {sentence + (index === sentences.length - 1 ? '' : '. ')}
              </span>
            </ToolBar>
          ))}
        </div>
      </div>
    )

    const renderAIGenerateSuggestion = () => {
        let aiSuggestion = null;
        switch (generatorType) {
            case AIGeneratorType.DEFAULT:
                const array = aiState;
                aiSuggestion = <>
                    {array.map((item, index) => (
                        <div className="option">
                            {renderSuggestionContentHeader(index, item)}
                            <div className={`ai-description short-skill`}>
                                {item.split(/\.\s/).map((sentence, i, sentences) => (
                                  renderSuggestionContent(sentence, index, i, sentences)
                                ))}
                            </div>
                        </div>
                    ))}
                </>;
                break;
                
            case AIGeneratorType.LEVEL:
                aiSuggestion = <>
                    {[...aiState]?.map((item, index) => (
                        <div className="option" key={index}>
                            {renderLevelSuggestionHeader(index)}
                            <div className="ai-description pl-suggestions">
                                {Object.keys(item).map((key, i) => (
                                    renderLevelSuggestionContent(i, item, key)
                                ))}
                            </div>
                        </div>
                    ))}
                </>;
                break;
            case AIGeneratorType.ROLES:
              aiSuggestion = <>
                    {[...aiState].map((item, index) => (
                        <div className="option">
                            {renderSuggestionContentHeader(index, item)}
                            <div className={`ai-description short-skill`}>
                              {item.split('$').map((sentence, i, sentences) => (
                                <div key = {i} style={{paddingBottom: '10px'}}>
                                  {renderSuggestionContent(sentence, index, i, sentences)}
                                </div>
                              ))}
                            </div>
                        </div>
                    ))}
                </>
              break;
            default: 
             aiSuggestion = ''
            break;
        }
        return aiSuggestion;
    };

    const renderScratchPad = () => {
        let scratchPad = null;
        switch (generatorType) {
            case AIGeneratorType.DEFAULT:
            default:
                scratchPad = <>
                    {<TextField
                        multiline
                        className="text-field"
                        placeholder={placeholder}
                        value={descriptionValue}
                        onChange={handleDescriptionChange}
                    />
                    }
                </>;
                break;
            case AIGeneratorType.LEVEL:
                scratchPad = <>
                {Object.keys(levelDescriptionValues).map((level) => (
                    <div>
                        <div>{level}</div>
                        <TextField
                            key={level}
                            multiline
                            className="text-field"
                            name={level}
                            placeholder={placeholder}
                            value={levelDescriptionValues[level]}
                            onChange={(e) =>  {setLevelDescriptionValues({ ...levelDescriptionValues, [level]: e.target.value })}}
                        />
                    </div>
                ))}
                </>;
                break;
        }
        return scratchPad;
    };

    const renderChipList = () => {
        return (
            <ChipList
                list={aiState}
                optionLabel="encyclopedia"
                onDelete={(item) => {toggleWikiTagSelection(item)}}
                chipClick={(val) => {navigateToTagURL(val, showAlert)}}
                style={{ paddingBottom: 0 }}
                toggleIcon={true}
            />
        )
    }

const renderSuggestionHeader = () => {
   return (
    <>
        <img src={Images.icons.aiIcon} alt="ai.png" />
        <H4>AI Suggestions</H4>
        {generatorType !== AIGeneratorType.TAG && <GenAiButton onClick={reGenerateAI}>Regenerate</GenAiButton>}
    </>
   )
}

const disableYesClick = () => {
   if(generatorType === AIGeneratorType.TAG){
      return !aiState.some((item) => item.isSelected === true)
   }else if(generatorType === AIGeneratorType.DEFAULT || generatorType === AIGeneratorType.ROLES){
      return descriptionValue.trim() === ''
   }else if(generatorType === AIGeneratorType.LEVEL){
        // enable YesClick if levelDescriptionValues is not an empty array and if at least one property of each object has a value
        const hasNonEmptyProperty = Object.values(levelDescriptionValues).some((value) => value.trim() !== '');
        return Object.keys(levelDescriptionValues).length === 0 || !hasNonEmptyProperty;
   }
}

const handleYesClick = () => {
    if(generatorType === AIGeneratorType.TAG){
      onConfirm();
    }else if(generatorType === AIGeneratorType.DEFAULT || generatorType === AIGeneratorType.ROLES){
      setDescriptionValue('');
      onConfirm(descriptionValue);
    }else if(generatorType === AIGeneratorType.LEVEL){
      onConfirm(levelDescriptionValues);
    }
}

     return (
        <div className={"ai-generation-container"}>
            <SuggestionBlock>
                <div className="suggestion-container">
                    <div className="suggestion-header">
                      {renderSuggestionHeader()}
                    </div>
                    {generatorType === AIGeneratorType.TAG 
                      ? renderChipList()
                      : <div className="skill-description-suggestions">
                            <div className="ai-suggestions common-styles" ref={suggestionRef}>
                                {renderAIGenerateSuggestion()}
                            </div>
                            <div className="user-selected-suggestions common-styles" ref={!levels ? scratchPadRef : null}>
                                <H5>Scratch Pad</H5>
                                <div className={`user-chosen-desciption ${generatorType === AIGeneratorType.LEVEL ? 'pl-chosen' : ''}`}>
                                    {renderScratchPad()}
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </SuggestionBlock>
            <ConfirmationButton
                noText={cancelText}
                yesText={confirmText}
                noClick={onCancel}
                disableYesClick={disableYesClick()}
                yesClick={handleYesClick}
            />
        </div>
    );
};

const SuggestionBlock = styled.div`
 .suggestion-container{
  display: flex;
  flex-direction: column;
  row-gap: 14px;
  padding: 0px 15px;
  overflow: hidden;
  .suggestion-header{
    display: flex;
    align-items: center;
    gap: 6px;
    h4{
      padding: 0px !important;
    }
    button{
      width: 110px !important;
      margin-left: 20px;
    }
  }
  .knowledge-base-suggestions{
    max-height: 180px;
    overflow-y: auto;
    margin-top: 5px;
    margin-bottom: 10px;
  }
  .skill-description-suggestions{
    display: flex;
    height: 300px !important;
    gap: 15px;
    overflow: hidden;
    .common-styles{
      padding: 24px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 8px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #888;
        border-radius: 4px; 
      }
      scrollbar-width: thin;
      scrollbar-color: #888 #F1F3F7;
      h5{
        padding: 0px;
      }
    }
    .ai-suggestions{
      width: 55%;
      border-radius: 8px;
      background: #F1F3F7;
      display: flex;
      flex-direction: column;
      row-gap: 15px;
      .option{
        display: flex;
        flex-direction: column;
        row-gap: 4px;
        .output{
          display: flex;
          align-item: center;
          gap: 10px;
          .copy-icon{
            cursor: pointer;
          }
          .copied-message {
            font-size: 12px; 
            margin-left: -5px;
          }
          .new-option{
            background: ${COLORS.PRIMARY_PURPLE_GRADIENT};
            color: ${COLORS.PRIMARY_WHITE};
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0px 4px;
            height: 14px;
            margin-top: 5px;
            cursor: context-menu;
          }
        }
        .ai-description{
          line-height: 19px;
          &.short-skill{
            span{
                ::selection{
                    background: rgba(249, 20, 101, 0.22);
                }
            }
          }
        }
        .pl-suggestions{
          display: flex;
          flex-direction: column;
          row-gap: 6px;
        }
      }
    }
    .hovered{
      background: rgba(249, 20, 101, 0.22);
      cursor: pointer
    }
    .user-selected-suggestions{
      width: 45%;
      border-radius: 8px;
      border: 1px solid #E0E0E0;
      .user-chosen-desciption{
        .text-field{
          width: 100%;
          >div{
            font-size: 14px;
            line-height: 19px;
          }
          .MuiInput-underline:before{
            border-bottom: 1px solid #E0E0E0 !important;
          }
        }
      }
      .pl-chosen{
            display: flex;
            flex-direction: column;
            row-gap: 10px;
            margin: 10px 0px;
           .text-field{
              border-radius: 4px;
              border: 1px solid #E0E0E0;
              padding: 4px 8px;
              .MuiInput-underline:before{
                 border-bottom: 0px !important;
               }
               .MuiInput-underline:after{
                 border-bottom: 0px !important;
               }
           }
        }
    }
    margin-top: 5px;
    margin-bottom: 10px;
  }
}
`