import { useEffect, useState } from "react"
import useRoles from "../../../../Hooks/useRoles";
import { AuthService } from "../../../../services/AuthService";
import { useLocation } from "@reach/router";
import { SkillService } from "../../../../services/SkillService";
import { handleTableCheckAll, handleTableCheckBox } from "../../../../helpers/CommonHelper";
import { SortString } from "../../../../helpers/SortString";
import useAlert from "../../../../Hooks/useAlert";
import useFullLoader from "../../../../Hooks/useFullLoader";
import { navigate } from "@reach/router";
import { getAndSaveClientRoles } from "../AssignSkillsHelper";
import usePopUp from "../../../../Hooks/usePopUp";
import { AssignSkillsStore } from "../../../../store/store_state";
import text from "../../../../locale.en.json";

const useSelectRoleSkills = ({roleId, personaId, roleSelectionData, excludedRoleSkills}) => {
    const { popUp } = usePopUp();
    const { showAlert } = useAlert();
    const { clientName } = useRoles();
    const { setFullLoader } = useFullLoader();
    const location = useLocation();
    const isNavigatedFromRoleMatch = location.state?.isNavigatedFromRoleMatch || false;
    const skillsMappedToPersona = location.state?.skillsMappedToPersona || {};
    const skillIdsToBeExcluded = location.state?.skillIdsToBeExcluded || [];
    const [roleSkillList, setRoleSkillList] = useState([]);


    const getRoleSkills = async () => {
        try {
            setFullLoader(true);
            const roleSkills = isNavigatedFromRoleMatch 
                                ? excludedRoleSkills
                                : await AuthService.getExcludedSkillsFromPersonaV3(personaId);
            const filteredSkills = roleSkills.filter((skill) => !skillIdsToBeExcluded.includes(skill.skillId))
            const sortedRoleSkills = SortString(filteredSkills, "skillName");
            const updatedRoleSkills = sortedRoleSkills.map((skill) => ({
              ...skill,
              proficiencyLevel: "",
              skillCriticality: "",
              proficiencyId: "",
              industryProficiencyLevel: skill.proficiencyLevel,
              industryProficiencyId: skill.proficiencyId,
              target_proficiency: ""
            }))
            AssignSkillsStore.update((s) => {
              s.roleSkillIds = sortedRoleSkills.map((skill) => skill.skillId);
              s.roleSkillNames = sortedRoleSkills.map((skill) => skill.skillName);
            })
            setRoleSkillList(updatedRoleSkills);
            setFullLoader(false);
        } catch (err) {
            console.log(err);
            setFullLoader(false);
        }
    }

    useEffect(() => {
        if((roleId && clientName) || excludedRoleSkills?.length > 0) {
          getRoleSkills();
        } 
    }, [roleId, clientName, excludedRoleSkills]);

    const triggerApi = (personaId) => SkillService.getSkillTypesArrayV3(personaId, "proficiencies");

    const levelChange = (val, item, reason) => {
        let updatedRoleSkill = {
          level: !!val ? val.level : "",
          levelId: !!val ? val.levelId : "",
          levelNumber: !!val ? val.levelNumber : "",
          proficiencyId: !!val ? val?.proficiencyId : "",
        };  
    
        const updetedRoleSkillList = [...roleSkillList].map((skill) =>
          skill.skillId === item.skillId
            ? { ...skill, ...updatedRoleSkill, updated: true, target_proficiency: !!val ? val.level : ""  }
            : { ...skill }
        );
    
        setRoleSkillList(updetedRoleSkillList);
      };

      const handleCheckBox = (e, item) => {
        const checked = e.target.checked;
        const updatedRoleSkill = handleTableCheckBox(checked, item, roleSkillList, "skillId");
        setRoleSkillList(updatedRoleSkill);
      }

      const handleCheckAll = (e) => {
        const checked = e.target.checked;
        const updatedRoleSkill = handleTableCheckAll(checked, roleSkillList);
        setRoleSkillList(updatedRoleSkill);
      }

      const handleRoleSkillAdd = async() => {
        try{
          setFullLoader(true);
          const filteredSkills = roleSkillList.filter((el) => !!el.checked && !!el.proficiencyId);
          const skillsToAdd = filteredSkills.map(({ proficiencyId, skillCriticality, skillAddedFrom }) => ({
            proficiencyId,
            skillCriticality,
            skillAddedFrom
          }));
          const skillIdsToRemove = filteredSkills.map(({ skillId }) => skillId);
          const  updatedSkillsMappedToPersona =  {
            skillProficiencyMappings: [...skillsMappedToPersona.skillProficiencyMappings, ...skillsToAdd],
            skillIdsToRemoveFromPersona: [...skillsMappedToPersona.skillIdsToRemoveFromPersona, ...skillIdsToRemove],
            ExcludedSkillIds: [...skillIdsToRemove]
          }
          await AuthService.updatePersonaSkillV3(personaId, updatedSkillsMappedToPersona);
          await showAlert(text.skill.addedSkillsToPersona.replace('{{count}}', filteredSkills.length), "success", 1500);
          setFullLoader(false);
          navigate(-1);
        } catch (errors) {
          showAlert(errors, "error", 1500);
        } 
      }

      const handleAddClick = () => {
        const isLevelSelectedForAllSkills = roleSkillList?.some((item) => item?.checked === true && (item?.proficiencyId === undefined || item?.proficiencyId === ""));
        if(isLevelSelectedForAllSkills) showAlert(text.skill.pleaseSelectLevelsForSelectedSkills, "info", 1500);
        else if(!isNavigatedFromRoleMatch) handleRoleSkillAdd();
        else getAndSaveClientRoles(roleSkillList, roleSelectionData, [location.state?.personaName], clientName, location.state.importTokenId, setFullLoader, showAlert, true)
      }

      const recommandDefaultClick = () => {
        const updatedRoleSkillList = roleSkillList.map((skill) => ({
          ...skill,
          level: skill.industryProficiencyLevel.level,
          levelId: skill.industryProficiencyLevel.levelId,
          levelNumber: skill.industryProficiencyLevel.levelNumber,
          proficiencyId: skill.industryProficiencyId,
          target_proficiency: skill.industryProficiencyLevel.level,
        }));
        setRoleSkillList(updatedRoleSkillList);
      }

      const handleDefaultRecommend = () => {
        popUp.confirm(
          text.skill.confirmDefaultIndustryProfLevelMapping,
          text.skill.defaultProfLevel,
          recommandDefaultClick
        )
      }

    return {
        roleSkillList,
        isNavigatedFromRoleMatch,
        triggerApi,
        levelChange,
        handleCheckBox,
        handleCheckAll,
        handleAddClick,
        handleDefaultRecommend
    }
}

export default useSelectRoleSkills;