import { useContext } from "react";
import { PersonaRoleContext } from "../Provider/PersonaRolesProvider";

export default () => {
  const context = useContext(PersonaRoleContext);
  if (context === undefined) {
    throw new Error("useRoles must be used within a Persona Roles Provider");
  }

  return context;
};
