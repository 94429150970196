import React, { useEffect, useState } from "react";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

import { navigate } from "@reach/router";
import styled from "styled-components";
import BackNav from "../../Common/BackNav";
import Layout from "../../Layout";
import { H2, H4, H3, H5 } from "../../Common/Typography";
import { BodyContent, Container } from "../../Common/GridSystem";
import COLORS from "../../../assets/colors";
import { SkillService } from "../../../services/SkillService";
import SkillSearchPopper from "./SkillSearchPopper";
import { PrimaryButton } from "../../Common/Buttons/PrimaryButton";
import AutoSelectSearch from "../../Common/FormElements/AutoSelectSearch";
import CustomizedTables from "../../Common/CustomizedTables";
import { Skill_Search_Table_Col } from "./SkillReqTable";
import AutoCompleteCheckBox from "../../Common/FormElements/AutoCompleteCheckBox";
import ErrorTitle from "../../Common/Error/ErrorTitle";
import useFullLoader from "../../../Hooks/useFullLoader";
import useAuth from "../../../Hooks/useAuth";
import PaginationCustom, { CountInit } from "../../Common/PaginationCustom";
import { SortString } from "../../../helpers/SortString";
import { getUpdatedPageAndOffsetBasedOnRowCount } from "../../../helpers/CommonHelper";

export default () => {
  const { setFullLoader } = useFullLoader();
  const { skillstatusMenuList } = useAuth();

  const [showTable, setShowTable] = useState(false);
  const [roletableHeight, setRoletableHeight] = useState("auto");

  let InitialSkillStoreState = JSON?.parse(sessionStorage.getItem('skillStoreState'));

  const [showAdvance, setShowAdvance] = useState(InitialSkillStoreState?.showAdvance || false);  

  const [skillName, setSkillName] = useState(InitialSkillStoreState?.skillName || '');
  const [suggestionSkills, setSuggestionSkills] = useState(null);

  const [categoryMenu, setCategoryMenu] = useState(InitialSkillStoreState?.categoryMenu || []);
  const [categoryMenuSelected, setCategoryMenuSelected] = useState(InitialSkillStoreState?.categoryMenuSelected || []);
  const [familyMenu, setFamilyMenu] = useState(InitialSkillStoreState?.familyMenu || []);
  const [familyMenuSelected, setFamilyMenuSelected] = useState(InitialSkillStoreState?.familyMenuSelected || []);
  const [sourceMenu, setSourceMenu] = useState(InitialSkillStoreState?.sourceMenu || []);
  const [sourceMenuSelected, setSourceMenuSelected] = useState(InitialSkillStoreState?.sourceMenuSelected || []);
  const [statusMenuSelected, setStatusMenuSelected] = useState(InitialSkillStoreState?.statusMenuSelected || []);
  const [searchEnabled, setSearchEnabled] = useState(InitialSkillStoreState?.searchEnabled || false);
  const [currPage, setCurrPage] = useState(InitialSkillStoreState?.currPage || 1);

  // Pagination
  const [listTotal, setListTotal] = useState(0);
  const [pageOffset, setPageOffset] = useState(InitialSkillStoreState?.pageOffset || 0);
  const [rowCount, setrowCount] = useState(InitialSkillStoreState?.rowCount || CountInit);
  const handlePagination = (e, page) => {
    setCurrPage(page);
    handlePageSearch(rowCount, (page - 1) * rowCount);
    setPageOffset((page - 1) * rowCount);
  };
  const changeRowCount = (count) => {
    const { updatedCurrPage, updatedPageOffset } = getUpdatedPageAndOffsetBasedOnRowCount(pageOffset, count);
    setCurrPage(updatedCurrPage);
    setPageOffset(updatedPageOffset);
    handlePageSearch(count, updatedPageOffset);
    setrowCount(count);
    setListTotal(0);
  };
  // Pagination

  useEffect(() => {
    if(!!InitialSkillStoreState){
      sessionStorage.removeItem('skillStoreState');
      handleSearch();
    }else{
      getListData();
    }
  }, []);

  useEffect(() => {
    if (
      suggestionSkills?.length > 5 &&
      showTable &&
      !!document.querySelector(".MuiTableBody-root tr.MuiTableRow-root")
    ) {
      setRoletableHeight(
        (document.querySelector(".MuiTableBody-root tr.MuiTableRow-root")
          ?.offsetHeight +
          8) *
          5 +
          (document.querySelector(".MuiTableRow-head").offsetHeight + 8) +
          8
      );
    }
  }, [showTable]);

  useEffect(() => {
    if (
      categoryMenuSelected?.length > 0 ||
      familyMenuSelected?.length > 0 ||
      sourceMenuSelected?.length > 0 ||
      statusMenuSelected?.length > 0 ||
      !!skillName
    ) {
      setSearchEnabled(true);
      setPageOffset(0);
    } else {
      setSearchEnabled(false);
      setSuggestionSkills(null);
    }
  }, [
    skillName,
    categoryMenuSelected,
    familyMenuSelected,
    sourceMenuSelected,
    statusMenuSelected,
  ]);

  useEffect(() => {
    if(!showAdvance){
      setCategoryMenuSelected([]);
      setFamilyMenuSelected([]);
      setSourceMenuSelected([]);
      setStatusMenuSelected([]);
    }
  }, [showAdvance]);

  console.log(categoryMenuSelected, familyMenuSelected);

  const getListData = async () => {
    try {
      const resCat = await SkillService.getSkillCategoriesV3();
      setCategoryMenu(
        SortString(resCat, "skillCategory").map((el) => ({
          label: el.skillCategory,
          value: el.skillCategoryId,
        }))
      );
      //?categoryIds=2&categoryIds=3
      const resFam = await SkillService.getSkillFamiliesV3();
      setFamilyMenu(
        SortString(resFam, "skillFamily").map((el) => ({
          label: el.skillFamily,
          value: el.skillFamilyId,
        }))
      );

      const resSrc = await SkillService.getSkillSourcesV3();
      // console.log("getListData -> resSrc", resSrc);
      setSourceMenu(
        SortString(resSrc, "source")
          .filter((el) => !!el.source)
          .map((el) => ({
            label: el?.source,
            value: el.sourceId,
          }))
      );
    } catch (err) {
      console.log(err);
    }
  };

  const triggerApi = async (searchString, reason) => {
    setSkillName(searchString);
    setPageOffset(0);
    const postData = {
      searchString,
      searchInDescription: false,
      searchAcrossFields: false,
      skillCateogryId: categoryMenuSelected,
      skillFamilyId: familyMenuSelected,
      skillSourceId: sourceMenuSelected,
      skillStatus: statusMenuSelected,
      screenSource: 0,
    };
    if (searchEnabled) {
      try {
        const res = await SkillService.SearchSkillV3(postData);
        return res?.skills;
      } catch (errors) {
        console.log(errors);
      }
    }
  };

  const handleChange = async(item, reason) => {
    if (reason === "select-option") {
      await setSkillName(item.skillName);
      handleRow(item);
      // navigate(`/skill/edit/${item.skillId}`);
    } else if (reason === "clear") {
      setSkillName("");
    } else if (reason === "create-option") {
      setSkillName(item);
      handleSearch();
    }
  };

  const postData = {
    searchString: skillName,
    searchInDescription: true,
    searchAcrossFields: false,
    skillCateogryId: categoryMenuSelected,
    skillFamilyId: familyMenuSelected,
    skillSourceId: sourceMenuSelected,
    skillStatus: statusMenuSelected,
    screenSource: 0,
  };

  const handleSearch = async () => {
    if (searchEnabled) {
      setFullLoader(pageOffset === 0);
      try {
        const res = await SkillService.SearchSkillV3(
          { ...postData, pagesize: rowCount, offset: pageOffset }
        );
        setSuggestionSkills(res?.skills);
        setListTotal(res?.total);
      } catch (errors) {
        console.log(errors);
      } finally {
        setFullLoader(false);
      }
    }
  };
  const handleSorting = async(sortObject) => {
    try {
      setFullLoader(true);
      const res = await SkillService.SearchSkillV3(
        { ...postData, pagesize: rowCount, offset: pageOffset },
        sortObject.sortBy,
        sortObject.sortOrder
      );
      setSuggestionSkills(res?.skills);
      setListTotal(res?.total);
    } catch (errors) {
      console.log(errors);
    } finally {
      setFullLoader(false);
    }
  }
  const handlePageSearch = async (count = 0, offset = 0) => {
    if (searchEnabled || !!count) {
      setFullLoader(offset === 0);
      try {
        const res = await SkillService.SearchSkillV3(
          { ...postData, pagesize: count || 30, offset: offset || 0 }
        );
        setSuggestionSkills(res?.skills);
        setListTotal(res?.total || res?.length);
      } catch (errors) {
        console.log(errors);
      } finally {
        setFullLoader(false);
      }
    }
  };

  const handleAddSkill = () => navigate(`/skill/add`);
  const handleRow = (row) => {
    const skillStoreState = {
      skillName,
      categoryMenu,
      categoryMenuSelected,
      familyMenu,
      familyMenuSelected,
      sourceMenu,
      sourceMenuSelected,
      statusMenuSelected,
      searchEnabled,
      showAdvance,
      rowCount,
      pageOffset,
      currPage
    };
    sessionStorage.setItem('skillStoreState', JSON.stringify(skillStoreState));
    navigate(`/skill/edit/${row.skillId}`)
  };

  const changeFamilyMenu = async (categoryId) => {
    const resFam = await SkillService.getSkillFamiliesV3(categoryId);

    setFamilyMenu(
      SortString(resFam, "skillFamily").map((el) => ({
        label: el.skillFamily,
        value: el.skillFamilyId,
      }))
    );
  };

  // console.log(sourceMenu);

  return (
    <Layout>
      <H2>Manage Skills</H2>
      <BodyContent>
        <Container>
          <BackNav linkNav="/" />
          <H3 style={{ color: COLORS.TITLE_BLUE }}>Update a Skill</H3>
          <WhiteBlock>
            <SearchInputBlock show={showAdvance}>
              <AutoSelectSearch
                width={700}
                freeSolo={true}
                optionChange={(item, reason) =>
                  !!item ? handleChange(item, reason) : undefined
                }
                handleClearSearch={() => setSkillName("")}
                optionLabel="skillName"
                // valueLabel="skillName"
                stateValue={skillName}
                enterResetSearch={(val) => {
                  handleSearch();
                  setSkillName(val);
                }}
                triggerApi={(val, reason) => triggerApi(val, reason)}
                PopperComponent={(props) => <SkillSearchPopper {...props} />}
                renderOption={(option) => (
                  <OptionCutom>
                    <span>{option.skillName}</span>
                    <span>{option.skillCategory.skillCategory}</span>
                    <span>{option.skillFamily.skillFamily}</span>
                  </OptionCutom>
                )}
              />
              <PrimaryButton onClick={handleSearch} disabled={!searchEnabled}>
                Search
              </PrimaryButton>
              <H5 onClick={() => setShowAdvance((prev) => !prev)}>
                Filter Search Results
                <ArrowDownwardIcon />
              </H5>
            </SearchInputBlock>
            {showAdvance && (
              <FilterBlock>
                <AutoCompleteCheckBox
                  width="25%"
                  label="Category"
                  defaultValue={categoryMenuSelected}
                  list={categoryMenu}
                  optionLabel={"label"}
                  optionChange={(val) => {
                    const mapCatValue = val?.map((el) => el.value) || [];
                    setCategoryMenuSelected(mapCatValue);
                    // console.log("val", val);
                    if (val.length > 0) {
                      changeFamilyMenu(
                        "?" +
                          mapCatValue
                            ?.map((el) => `categoryIds=${el}`)
                            .join("&")
                      );
                    } else {
                      // setFamilyMenuSelected([]);
                      changeFamilyMenu("");
                    }
                    // ?categoryIds=2&categoryIds=3
                  }}
                />
                <AutoCompleteCheckBox
                  width="25%"
                  label="Family"
                  defaultValue={familyMenuSelected}
                  list={familyMenu}
                  optionLabel={"label"}
                  optionChange={(val) =>
                    setFamilyMenuSelected(val.map((el) => el.value))
                  }
                />
                <AutoCompleteCheckBox
                  width="25%"
                  label="Source"
                  defaultValue={sourceMenuSelected}
                  list={sourceMenu}
                  optionLabel={"label"}
                  optionChange={(val) =>
                    setSourceMenuSelected(val.map((el) => el.value))
                  }
                />
                <AutoCompleteCheckBox
                  width="25%"
                  label="Status"
                  defaultValue={statusMenuSelected}
                  list={skillstatusMenuList}
                  optionLabel={"label"}
                  optionChange={(val) =>
                    setStatusMenuSelected(val.map((el) => el.value))
                  }
                />
              </FilterBlock>
            )}
            {!!suggestionSkills && suggestionSkills.length > 0 ? (
              <div className="table-block">
                <CustomizedTables
                  columns={Skill_Search_Table_Col}
                  rowList={suggestionSkills}
                  rowClick={handleRow}
                  stickyHeader={suggestionSkills?.length > 5}
                  sortAndFilterAPILevel={{ sort: true, filter: true }}
                  handleSortTable={(value) => handleSorting(value)}
                  tableHeight={
                    suggestionSkills?.length > 5 && showTable
                      ? roletableHeight
                      : "auto"
                  }
                  innerTabel={true}
                />
              </div>
            ) : null}
            {!!suggestionSkills &&
            suggestionSkills.length > 0 &&
            !!listTotal ? (
              <PaginationCustom
                pageTotal={listTotal}
                handlePagination={handlePagination}
                countRows={rowCount}
                changeRowCount={changeRowCount}
                changeCurrentPage={currPage}
              />
            ) : null}
            {!!suggestionSkills && suggestionSkills.length === 0 ? (
              <ErrorTitle text="No matching skills found, kindly change the search criteria / keyword" />
            ) : null}
          </WhiteBlock>
          <H4>Add a new Skill</H4>
          <PrimaryButton onClick={handleAddSkill}>Add Skill</PrimaryButton>
        </Container>
      </BodyContent>
    </Layout>
  );
};

const WhiteBlock = styled.div`
  background-color: ${COLORS.PRIMARY_WHITE};
  border-radius: 8px;
  box-shadow: 0 1px 3px 0 rgba(97, 122, 163, 0.24);
  padding: 30px;
  margin-bottom: 30px;
  .table-block {
    overflow: hidden;
    & > div {
      min-height: 300px;
      height: calc(100vh - 420px);
      overflow: auto;
    }
  }
  table {
    padding: 0px 10px;
  }
`;

const SearchInputBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
  .MuiFormControl-root {
    border: solid 1px ${COLORS.ASH_BORDER};
    border-radius: 5px;
  }
  button {
    margin: 0px 15px;
  }
  h5 {
    cursor: pointer;
    padding-bottom: 0px;
    text-decoration: underline;
    padding: 15px 25px 15px 0px;
    position: relative;
    svg {
      font-size: 22px;
      position: absolute;
      right: 0px;
      transform: ${({ show }) => (show ? `rotate(180deg)` : `rotate(0deg)`)};
      transition: all 0.3s ease-in-out;
    }
  }
`;

const OptionCutom = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  width: 100%;
  span {
    padding: 10px;
    /* flex: 0 0 33.3333%; */
    &:first-child {
      flex: 0 0 50%;
    }
    &:nth-child(2) {
      flex: 0 0 25%;
    }
    &:last-child {
      flex: 0 0 25%;
    }
  }
`;

const FilterBlock = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 30px;
  h5 {
    padding: 0px 15px 0px 0px;
  }
  & > div {
    margin-right: 30px;
    label {
      font-size: 16px;
      transform: translate(0px, 1.5px) scale(1);
    }
  }
`;
