import React from "react";
import { makeStyles, MenuItem } from "@material-ui/core";

export default ({ children, ...props }) => {
  const classes = useStyles();
  return (
    <MenuItem className={classes.root} {...props}>
      {children}
    </MenuItem>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: "inherit",
    fontSize: 14,
    padding: "10px 15px",
  },
}));
