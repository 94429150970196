import { makeStyles } from "@material-ui/core/styles";
import COLORS from "../../../assets/colors";

export const useStylesCustomizedTheme = makeStyles({
  table: {
    overflowX: "auto !important",
    "&::-webkit-scrollbar": {
      height: "8px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "rgba(0, 0, 0, 0.2)",
      borderRadius: "6px",
    },
    "&.MuiTable-root": {
      borderSpacing: 0,
    },
    "& .MuiCheckbox-root": {
      color: COLORS.PRIMARY_WHITE,
      padding: "3px 6px !important",
      margin: "0px !important",
      "&.Mui-checked svg": {
        fill: COLORS.PRIMARY_RED,
        background: COLORS.PRIMARY_WHITE,
        borderRadius: "4px",
      },
    },
    "& thead th": {
      backgroundColor: COLORS.BODY_ASH,
      border: "none",
      position: "relative",
      textAlign: "center",
      padding: "0 15px !important",
      whiteSpace: "nowrap",
      boxSizing: "border-box",
      "& > span": {
        background:
          "linear-gradient(111deg, rgb(250, 21, 101) 22%, rgb(115, 20, 173) 155%)",
        borderRadius: "5px",
        fontWeight: 600,
        padding: "5px 30px",
        color: COLORS.PRIMARY_WHITE,
        position: "relative",
        zIndex: 1,
        top: "12px",
        height: "30px",
        "&:empty": {
          display: "none",
        },
        "& div": {
          color: COLORS.PRIMARY_WHITE,
        },
      },
      "& .MuiTableSortLabel-icon": {
        color: "white !important",
      },
      '& div[title="Open Filter"], div[title="Clear"]': {
        width: "20px",
        position: "relative",
        display: "inline-flex",
        top: "22px",
        right: "30px",
        opacity: "1",
        zIndex: "9999",
        "& .hamburger .line": {
          backgroundColor: COLORS.PRIMARY_WHITE,
        },
      },
      "& .filter-box": {
        "& > div":{
        position: "absolute",
        top: "34px",
        width: "80%", 
        },
      },
      "& .MuiAutocomplete-endAdornment > button": {
        "&:hover": {
          background: "#e0e0e0 !important",
        },
      },
    },
    "& tbody tr": {
      backgroundColor: "rgb(54, 60, 105) !important",
      borderSpacing: 0,
      border: 0,
      margin: 0,
      borderRadius: "10px !important",
      "&:first-child td": {
        paddingTop: "45px !important",
      },
      "&:last-child td": {
        paddingBottom: "45px !important",
      },
      "& td": {
        color: "#fff",
        textAlign: "center",
        border: "none !important",
        borderRadius: "0px",
        "&:first-child": {
          borderTopLeftRadius: "0px",
          borderBottomLeftRadius: "0px",
        },
        "&:last-child": {
          borderTopRightRadius: "0px",
          borderBottomRightRadius: "0px",
        },
        "& input": {
          color: "#fff",
        },
        "& .MuiOutlinedInput-input": {
          color: COLORS.PRIMARY_WHITE,
        },
        "& button": {
          background: "rgb(255, 239, 243)",
          minWidth: "25px !important",
          minHeight: "25px",
        },
        "& .triggerDrop": {
          "& .MuiAutocomplete-endAdornment": {
            "& button": {
              background: "none",
              color: `${COLORS.PRIMARY_WHITE}`,
            },
          },
        },
        "& .triggerDropSearch": {
          borderRadius: "5px",
          border: `1px solid ${COLORS.ASH_BORDER}`,
          "& .MuiAutocomplete-endAdornment": {
            "& button": {
              background: "rgb(255, 239, 243)",
            },
          },
        },
      },
      "&.disabled": {
        opacity: "0.9",
        "& td": {
          "& .chip": {
            pointerEvents: "none",
          },
          "& a": {
            pointerEvents: "auto !important",
          },
          "& .delete-icon": {
            pointerEvents: "none",
          },
        },
        "& .selected": {
          backgroundColor: "rgb(172, 203, 247) !important",
        },
        "& .triggerDrop": {
          pointerEvents: "none",
          visibility: "visible",
          width: "228px"
        },
      },
      "& .disabledTriggerDrop": {
        width: "228px",
        borderRadius: "5px",
        border: `1px solid ${COLORS.ASH_BORDER}`,
        visibility: "visible",
        opacity: "0.3",
        "& .MuiAutocomplete-endAdornment": {
          visibility: 'visible',
          cursor: 'pointer'
        },
        "& button":{
          background: "none",
          color: `${COLORS.PRIMARY_WHITE}`
        }
      }
    },
    "& tbody tr:first-child td:first-child": {
      borderTopLeftRadius: "10px !important",
      borderBottomLeftRadius: "0px",
    },
    "& tbody tr:first-child td:last-child": {
      borderTopRightRadius: "10px !important",
      borderBottomRightRadius: "0px",
    },
    "& .MuiInputBase-formControl": {
      margin: "0px !important",
    },
    "& .select-item": {
      "& .MuiFormControl-root": {
        paddingTop: "0px !important",
        border: `1px solid ${COLORS.ASH_BORDER}`,
        borderRadius: "5px",
      },
      "& .MuiSelect-selectMenu": {
        backgroundColor: "transparent",
        border: `1px solid ${COLORS.ASH_BORDER}`,
        color: COLORS.PRIMARY_WHITE,
      },
    },
    "& .chip": {
      backgroundColor: "rgb(255, 239, 243)",
      color: "rgb(51, 56, 96)",
      borderRadius: "28px",
      padding: "5px 15px",
      textAlign: "center",
      display: "flex",
      minWidth: "100%",
      minHeight: "48px",
      alignItems: "center",
      justifyContent: "space-between",
      "&.selected": {
        backgroundColor: "rgb(172, 203, 247)",
      },
      "&:empty": {
        display: "none",
      },
      "& .highlight": {
        fontWeight: "bold",
      },
      "& .ellipsis": {
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        "-webkit-line-clamp": "2",
        "-webkit-box-orient": "vertical",
        borderRight: "1px solid #fa1565",
        width: "calc(100% - 30px)",
        paddingRight: "10px",
        marginRight: "10px",
        textDecoration: "underline",
        "& a": {
          color: "black !important",
        },
      },
    },
    "& .disabled-chip": {
      opacity: "0.6 !important",
    },
  },
});
