import React, { useCallback, useEffect, useState } from "react";
import { navigate } from "@reach/router";
import styled from "styled-components";

import { SortString } from "../../../helpers/SortString";
import useAuth from "../../../Hooks/useAuth";
import useRoles from "../../../Hooks/useRoles";
import { AuthService } from "../../../services/AuthService";
import { Common } from "../../../store";
import { PrimaryButton } from "../Buttons/PrimaryButton";
import SelectMenu from "../FormElements/SelectMenu";
import ModalPopup from "../ModalPopup";
import useAlert from "../../../Hooks/useAlert";

export default ({
  open,
  closePopup,
  title,
  fullWidth = false,
  maxWidth = "sm",
  assignName = false,
  type = "",
}) => {
  const { userDetails } = useAuth();
  const { userId } = userDetails;
  const {
    clientName,
    setClientName,
    setClientList,
    setClientCount,
  } = useRoles();
  const { showAlert } = useAlert();
  const [list, setList] = useState([]);
  const [archPage, setArchPage] = useState(false);
  const [clientNameCurrent, setClientNameCurrent] = useState(clientName);

  const getClients = async () => {
    try {
      const res = await AuthService.getClientsV3(`/${userId}`);
      const clientArray = SortString(res, "clientName")?.map((el) => ({
        ...el,
        label: el.clientName,
        value: el.clientId,
      }));
      setList(clientArray);
      setClientCount(clientArray.length);
      //   console.log(clientName);
      if (!clientName && assignName) {
        Common.setClientName(clientArray[0].value);
        setClientName(clientArray[0].value);
        console.log(clientArray[0].value);
        Common.setClientList(clientArray);
        setClientList(clientArray);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAllPersonas = async (id) => {
    try {
      const resList = await AuthService.getPersonasListV3(`?clientId=${id}`);
      if (resList.length > 0) {
        setArchPage(true);
        await navigate(
          type === "ARCH_SKILL"
            ? "/persona/architect-skills/" +
                SortString(
                  resList.map((el) => ({
                    ...el,
                    personaGroupName: el.personaGroup.personaGroupName,
                  })),
                  "personaGroupName"
                )[0].personaId
            : "/compare-persona"
        );
        window.location.reload();
      } else {
        if (open) {
          showAlert(
            "No Personas found! Kindly define a persona first",
            "info",
            2500
          );
          setArchPage(false);
        }
        window.location.pathname !== "/" && navigate("/personas");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    !!userDetails && getClients();
  }, [clientNameCurrent]);

  const handleClientChange = useCallback(
    (val) => {
      setClientNameCurrent(val);
    },
    [clientName]
  );

  const handleGo = async () => {
    await Common.setClientName(
      !!clientNameCurrent ? clientNameCurrent : clientName
    );
    await setClientName(!!clientNameCurrent ? clientNameCurrent : clientName);
    // console.log(clientName, clientNameCurrent);
    await closePopup();
    setTimeout(
      () =>
        type === "COMPARE_ROLE"
          ? navigate("/compare-role")
          : getAllPersonas(
              !!clientNameCurrent ? clientNameCurrent : clientName
            ),
      1000
    );
  };

  return (
    <ModalPopup
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      open={open}
      closePopup={closePopup}
      title={title}
    >
      <MidAlign>
        <SelectMenu
          width="250px"
          label=""
          value={!!clientNameCurrent ? clientNameCurrent : clientName}
          list={list}
          handleSelect={handleClientChange}
        />

        <PrimaryButton onClick={handleGo}>Go</PrimaryButton>
      </MidAlign>
    </ModalPopup>
  );
};

const MidAlign = styled.div`
  text-align: center;
  padding: 0px 30px;
  & > div {
    margin: 0 auto;
  }
`;
