import React from "react";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  makeStyles,
} from "@material-ui/core";
import Label from "./Label";

export default ({
  name,
  options = [],
  radioVal,
  radioGroupChange = () => {},
  labelTitle = "",
}) => {
  const classes = useStyles();

  return (
    <FormControl component="fieldset" className={classes.root}>
      {!!labelTitle && <Label>{labelTitle}</Label>}
      <RadioGroup
        name={name}
        value={radioVal}
        onChange={(e) => radioGroupChange(e.target.value)}
      >
        {options.map((el, i) => (
          <FormControlLabel
            key={i}
            value={el.value}
            control={<Radio />}
            label={el.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormGroup-root": {
      flexDirection: "row",
    },
  },
}));
