const COLORS = {
  PRIMARY_WHITE: "#FFFFFF",
  PRIMARY_BLACK: "#2f323c",
  PRIMARY_RED: "#FA1565",
  SECONDARY_RED: "#e3126c",
  PRIMARY_PURPLE: "#7314ad",
  PRIMARY_PURPLE_GRADIENT: "linear-gradient(111deg, #fa1565 22%, #7314ad 155%)",
  PRIMARY_BLUE_GRADIENT: "linear-gradient(111deg, #6465ca 22%, #424a79 155%)",
  DARK_GREY: "#6F747A",
  DARK_ASH: "#C4CAD2",
  ASH_WHITE: "#F1F3F5",
  DARK_BLUE: "#162C4C",
  AQUA_BLUE: "#01D4DF",
  YELLOW: "#FFDD00",
  GREEN: "#76B043",
  SECONDARY_PURPLE: "#7958ED",
  BLUE: "#1673F4",
  BLUE_ASH: "#797da5",
  BODY_ASH: "#f0f3f8",
  TITLE_BLUE: "#333860",
  TEXT_BLUE: "#495281",
  BAR_ASH: "#d3d6ed",
  BAR_RED: "#dc1576",
  NUMBER_BLUE: "#626b91",
  LABEL_BLUE: "#424a79",
  ASH_RED: "#b0b4d0",
  ASH_BORDER: "#a5b8d8",
  LIGHT_MERON: "#fce5eb",
  C4_BORDER: "#c4c4c4",
  BODY_BLUE: "#393e65",
  ICON_PURPLE: "#f71467",
  LIGHT_GREEN: "#e5fbe7",
  DARK_BLUE_HEADER: "#363c69",
  AQUA_GREEN: "#47cae2",
  AQUA_ASH: "#f7f9fc",
  BLUE_BG0: "#ffffff",
  BLUE_BG1: "#FBE2E6",
  BLUE_BG2: "#F1C3CB",
  BLUE_BG3: "#EFA9BC",
  BLUE_BG4: "#D590B1",
  BLUE_BG5: "#B78BB3",
  BLUE_BG6: "#987CB0",
  BLUE_BG7: "#7F75B2",
  ERROR_RED: "#f44336",
  POPEPER_BLUE: "#3A3F62",
  DOWNLOAD_GREEN: "#3AB876",
  PRIMARY_PURPLE_BACKGROUND: "#d5b2e8"
};

export default COLORS;
