import React from "react";
import useSearchSkills from "./useSearchSkills";
import "./SearchSkills.scss";
import SelectedSearchedSkills from "./SelectedSearchedSkills";
import SearchInputField from "./SearchInputField";
import SearchSkillTable from "./SearchSkillTable";

export default ({
  categoryMenu,
  handleSkillPopup,
  skillType,
  rolePersonaSkillIds,
  skillSourceIds = [],
  isNavigatedFromRoleMatch,
  roleId,
  personaId,
  personaSkills,
  isNavigatedFromRoleScreen,
  roleSelectionData
}) => {
  const {
    skillName,
    updateSkillName,
    handleCategory,
    familyMenu,
    handleFamily,
    selectFamily,
    selectCategory,
    skillList,
    handleCheckBox,
    handleCheckAll,
    levelChange,
    triggerApi,
    skillListCopy,
    handleSelectClick,
    listTotal,
    handlePagination,
    rowCount,
    changeRowCount,
    sourceList,
    handleSource,
    selectSource,
    handleSearchButtonClick,
    skillSelectedContainer,
    currPage,
    noSkillMessage,
    selectedSearchedSkills,
    handleSkillAdd,
    handleSelectedSearchedSkillCheckBox,
    handleSelectedSearchedSkillCheckAll,
    handleSelectedSearchedSkillLevelChange,
  } = useSearchSkills({
    rolePersonaSkillIds,
    roleId,
    personaId,
    personaSkills,
    categoryMenu,
    skillType,
    skillSourceIds,
    isNavigatedFromRoleMatch,
    isNavigatedFromRoleScreen,
    roleSelectionData
  });

  return (
    <div className="search-accordian-block">
      <SelectedSearchedSkills
        selectedSearchedSkills={selectedSearchedSkills}
        skillType={skillType}
        isNavigatedFromRoleMatch={isNavigatedFromRoleMatch}
        handleSelectedSearchedSkillCheckBox={handleSelectedSearchedSkillCheckBox}
        handleSelectedSearchedSkillCheckAll={handleSelectedSearchedSkillCheckAll}
        handleSkillPopup={handleSkillPopup}
        handleSelectedSearchedSkillLevelChange={handleSelectedSearchedSkillLevelChange}
        triggerApi={triggerApi}
        handleSkillAdd={handleSkillAdd}
      />
      <div className="search-skills-container">
        <SearchInputField
          skillName={skillName}
          updateSkillName={updateSkillName}
          handleSearchButtonClick={handleSearchButtonClick}
          categoryMenu={categoryMenu}
          handleCategory={handleCategory}
          familyMenu={familyMenu}
          handleFamily={handleFamily}
          sourceList={sourceList}
          handleSource={handleSource}
          skillSourceIds={skillSourceIds}
          selectFamily={selectFamily}
          selectCategory={selectCategory}
          selectSource={selectSource}
          isNavigatedFromRoleScreen={isNavigatedFromRoleScreen}
        />
        <SearchSkillTable
          skillList={skillList}
          skillType={skillType}
          isNavigatedFromRoleMatch={isNavigatedFromRoleMatch}
          handleCheckBox={handleCheckBox}
          handleCheckAll={handleCheckAll}
          handleSkillPopup={handleSkillPopup}
          levelChange={levelChange}
          triggerApi={triggerApi}
          skillListCopy={skillListCopy}
          noSkillMessage={noSkillMessage}
          handleSelectClick={handleSelectClick}
          skillSelectedContainer={skillSelectedContainer}
          listTotal={listTotal}
          handlePagination={handlePagination}
          rowCount={rowCount}
          changeRowCount={changeRowCount}
          currPage={currPage}
        />
      </div>
    </div>
  );
};
