import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputBase from "@material-ui/core/InputBase";
import COLORS from "../../../assets/colors";
import { GroupByKey } from "../../utils";
import { ListSubheader } from "@material-ui/core";

const BootstrapInput = withStyles((theme) => ({
  root: {
    marginBottom: 25,
    "label + &": {
      marginTop: 22,
      fontSize: 12,
    },
    "label.MuiFormLabel-root": {
      fontSize: 20,
    },
    li: {
      display: "none",
    },
  },
  input: {
    borderRadius: 4,
    color: COLORS.TEXT_BLUE,
    position: "relative",
    backgroundColor: COLORS.PRIMARY_WHITE,
    border: "1px solid #ced4da",
    fontSize: 12,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: "inherit",
    // Use the system font instead of the default Roboto font.
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.1rem rgba(0,123,255,.05)",
    },
    ".MuiSelect-icon": {
      color: "#fff",
    },
    "& .MuiListSubheader-root": {
      display: "none",
    },
    width: "100%",
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: 0,
    width: "100%",
    paddingTop: 8,
  },
  label: {
    color: COLORS.TITLE_BLUE,
    fontSize: 20,
    fontWeight: "bold",
    fontFamily: "inherit",
    whiteSpace: "nowrap",
  },
  subHeader: {
    backgroundColor: `#a6b8d8`,
    color: COLORS.PRIMARY_WHITE,
    fontWeight: 700,
    padding: `7px 15px`,
  },
}));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    fontSize: 12,
    "&:focus": {
      backgroundColor: COLORS.ASH_WHITE,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

export default ({
  label,
  width,
  list,
  value,
  handleSelect,
  disabled = false,
  labelShow = true,
  groupKey,
}) => {
  const classes = useStyles();
  const handleChange = (event) => handleSelect(event.target.value);
  // console.log(list);
  return (
    <div style={{ width }}>
      <FormControl className={classes.margin}>
        {labelShow && (
          <InputLabel
            className={classes.label}
            id="group-select-label"
            htmlFor="group-select"
          >
            {label}
          </InputLabel>
        )}
        <Select
          labelId="group-select-label"
          id="group-select"
          disabled={list.length === 0}
          value={!!value ? value : "Any"}
          onChange={handleChange}
          placeholder={value}
          disabled={disabled}
          input={<BootstrapInput />}
          title={
            !!value &&
            !!list.length &&
            value !== 0 &&
            list.find((el) => el.value === value)?.label !== "Any"
              ? list.find((el) => el.value === value)?.label
              : ""
          }
        >
          {list.some((el) => el.value === "Any") && (
            <StyledMenuItem value="Any">Any</StyledMenuItem>
          )}
          {!!list.length && list.some((el) => el.type === 2) ? (
            <ListSubheader className={classes.subHeader}>
              Functions
            </ListSubheader>
          ) : null}
          {!!list.length &&
            list
              .filter((el) => el.type === 2)
              .map((item, i) => (
                <StyledMenuItem value={item.value} key={i}>
                  {item.label}
                </StyledMenuItem>
              ))}
          {!!list.length && list.some((el) => el.type === 1) ? (
            <ListSubheader className={classes.subHeader}>
              Industries
            </ListSubheader>
          ) : null}
          {!!list.length &&
            list
              .filter((el) => el.type === 1)
              .map((item, i) => (
                <StyledMenuItem value={item.value} key={i}>
                  {item.label}
                </StyledMenuItem>
              ))}
        </Select>
      </FormControl>
    </div>
  );
};
