import { Store } from "pullstate";
import {
  InitialUIStore,
  InitialComparePersonaStore,
  InitialSkillStore,
  InitialIndustryRoleListStore,
  InitialSkillMappingStore,
  InitialExportToDisprzStore,
  InitialSubSecStore,
  InitialAIStore,
  InitialIndustreRoleStore,
  InitialSelectedRoleSkillsStore,
  InitialAssignSkillsStore,
  InitialFileUploaderStore,
} from "./initial_store_state";

export const UIStore = new Store(InitialUIStore);

export const ComparePersonaStore = new Store(InitialComparePersonaStore);

export const SkillStore = new Store(InitialSkillStore);

export const IndustryRoleListStore = new Store(InitialIndustryRoleListStore);

export const skillMappingStore = new Store(InitialSkillMappingStore);

export const exportToDisprzStore = new Store(InitialExportToDisprzStore);

export const subSecStore = new Store(InitialSubSecStore);

export const AIStore = new Store(InitialAIStore);

export const IndustreRoleStore = new Store(InitialIndustreRoleStore);

export const SelectedRoleSkillsStore = new Store(InitialSelectedRoleSkillsStore);

export const AssignSkillsStore = new Store(InitialAssignSkillsStore);

export const FileUploaderStore = new Store(InitialFileUploaderStore);

export const ToggleMode = (s) => {
  s.isDarkMode = !s.isDarkMode;
};

export const resetStore = (store, initialState) => {
  store.update(s => {
    for (const key in s) {
      if (s.hasOwnProperty(key)) {
        s[key] = initialState[key];
      }
    }
  });
};

export const clearStore = () => {
  resetStore(UIStore, InitialUIStore);
  resetStore(ComparePersonaStore, InitialComparePersonaStore);
  resetStore(SkillStore, InitialSkillStore);
  resetStore(IndustryRoleListStore, InitialIndustryRoleListStore);
  resetStore(skillMappingStore, InitialSkillMappingStore);
  resetStore(exportToDisprzStore, InitialExportToDisprzStore);
  resetStore(subSecStore, InitialSubSecStore);
  resetStore(AIStore, InitialAIStore);
  resetStore(IndustreRoleStore, InitialIndustreRoleStore);
  resetStore(SelectedRoleSkillsStore, InitialSelectedRoleSkillsStore);
  resetStore(AssignSkillsStore, InitialAssignSkillsStore);
  resetStore(FileUploaderStore, InitialFileUploaderStore);
};
