import React, { useEffect, useState } from "react";
import { AuthService } from "../../services/AuthService";
import SelectMenu from "../Common/FormElements/SelectMenu";

export default ({
  width = 170,
  label = "Leadership Role",
  handleSelect,
  value = "Any",
}) => {
  const [leaderRoles, setLeaderRoles] = useState(null);

  useEffect(() => {
    const getLeaderRoles = async () => {
      try {
        const res = await AuthService.leaderShipRolesV3();
        // const sorted = await SortString(res, "leadershipRoleName");
        // res.push({
        //   leadershipRoleName: "Any",
        //   leadershipRoleId: 0,
        // });
        setLeaderRoles(res);
      } catch (error) {
        console.log(error);
      }
    };

    getLeaderRoles();
    return () => {
      setLeaderRoles(null);
    };
  }, []);
  // console.log(value);
  return (
    <SelectMenu
      width={width}
      label={label}
      value={value}
      list={
        !!leaderRoles
          ? leaderRoles.map((el, i) => ({
              label: el.leadershipRoleName,
              value: el.leadershipRoleId,
            }))
          : []
      }
      handleSelect={handleSelect}
    />
  );
};
