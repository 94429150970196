import React, { useState } from "react";
import styled from "styled-components";
import TextField from "@material-ui/core/TextField";
import COLORS from "./../../../assets/colors";
import { useField } from "formik";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-multiline": {
      // borderRadius: "0px",
      padding: 0,
    },
    "& .MuiOutlinedInput-input": {
      color: COLORS.TEXT_BLUE,
      fontSize: 12,
      fontFamily: "inherit",
      padding: 12,
    },
    input: {
      // backgroundColor: "red",
    },
    "& .MuiFormHelperText-contained": {
      margin: 0,
    },
  },
}));

const StyledErrorMessage = styled.div`
  font-size: 12px;
  color: ${COLORS.PRIMARY_RED};
  &:before {
    content: "";
    font-size: 10px;
  }
`;

const Label = styled.label`
  color: ${COLORS.TITLE_BLUE};
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 10px;
  .astric {
    color: ${COLORS.PRIMARY_RED};
  }
`;

const TextInputContainer = styled.div`
  position: relative;
  padding-bottom: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: start;
`;

const FormTextField = ({ label, isRequired, children, customValidate, ...props }) => {
  const [field, meta] = useField(props);
  const [customErrorMessage, setCustomErrorMessage] = useState("");
  const classes = useStyles();

  const trim = async (evt) => {
    props.setVal(evt.target.name, evt.target.value.trim());
    props.setTouched(evt.target.name, true);
    if (!!customValidate) {
      const errorMessage = await customValidate(evt.target.value.trim());
      setCustomErrorMessage(errorMessage);
    }
  };
  // console.log(!!meta.touched, !!meta.error, props.name);
  return (
    <TextInputContainer>
      {props.labelShow !== false && (
        <Label htmlFor={props.id || props.name}>
          {label}
          {isRequired && <span className="astric"> *</span>}
        </Label>
      )}
      <TextField
        {...field}
        {...props}
        className={classes.root}
        variant="outlined"
        InputProps={{
          className: classes.input,
        }}
        onBlur={!!props.setVal ? trim : undefined}
        error={(!!meta.touched && !!meta.error) || (!!customErrorMessage)}
        // onChange={
        //   !!props.setVal
        //     ? (evt) =>
        //         props.setVal(
        //           evt.target.name,
        //           evt.target.value.replace(/^\s+/g, "")
        //         )
        //     : undefined
        // }
      />
      {children}
      {(meta.touched && meta.error) || (!!customErrorMessage) ? (
        <StyledErrorMessage>{meta.error || customErrorMessage}</StyledErrorMessage>
      ) : null}
    </TextInputContainer>
  );
};

export default FormTextField;
