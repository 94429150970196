import React from "react";
import PropTypes from "prop-types";
import { Box, TextField, IconButton } from "@material-ui/core";
import COLORS from "../../../assets/colors";
import { Images } from "../../../assets/images";
import { H5, H6 } from "../Typography";
import "./FileUploader.scss";
import useFileUploader from "./useFileUploader";
import ToolBar from "../ToolBar";
import { FileFormats } from "../../../enums/FileFormats";
import { FileUploaderStore } from "../../../store/store_state";
import { getFileExtensionFromFileName, getFileIcon } from "../../../helpers/FileUploadHelper";
import text from "../../../locale.en.json";

const FileUploader = ({
  allowedFileTypes,
  multiSelect,
  maxFileSize,
  isJdFileUpload,
  error,
  setError
}) => {
  const { handleDrop, handleBrowse, deleteFile, customError, key } = useFileUploader({ allowedFileTypes, multiSelect, maxFileSize, error, setError });
  const selectedFiles = FileUploaderStore.useState((s) => s.selectedFiles);

  return (
    <div
      className="file-uploader-container"
      onDrop={handleDrop}
      onDragOver={(event) => event.preventDefault()}
      key={key}
    >
      <div className="file-input-container">
        <input
          type="file"
          accept={allowedFileTypes.join(",")}
          onChange={handleBrowse}
          multiple={multiSelect}
          className="input"
          id="file-input"
        />
        <label htmlFor="file-input">
          <Box
            sx={{
              border: "2px dashed #ccc",
              borderRadius: "8px",
              padding: "20px",
              textAlign: "center",
              cursor: "pointer",
            }}
          >
            <img src={Images.icons.browseFileIcon} alt="browse icon" />
            <div>
              <H5 className="file-uploader-text">
                {text.fileSelection.dragFiles}{" "}
                <span style={{ color: COLORS.PRIMARY_PURPLE }}>{text.fileSelection.browse}</span>
              </H5>
              <H6 className="support-file-types">
                {text.fileSelection.supportedFiles} :{" "}
                {allowedFileTypes.map((fileType, index) => (
                  <span key={index}>
                    {index > 0 && ", "}
                    {fileType.toUpperCase()}
                  </span>
                ))}
              </H6>
              {isJdFileUpload &&
                <H6 className="support-file-size">
                  {text.persona.jobDescription.jdSupportedFormat}
                </H6>
              }
              {maxFileSize && (
                <H6 className="support-file-size">
                  {text.fileSelection.maxFileSize} {maxFileSize}
                </H6>
              )}
            </div>
          </Box>
        </label>
        <div className={!!selectedFiles?.length && "selected-files"}>
        {selectedFiles.map((file, index) => (
            <TextField
              key={index}
              variant="outlined"
              fullWidth
              value={file.name}
              InputProps={{
                startAdornment: getFileIcon(
                  getFileExtensionFromFileName(file.name)
                ),
                endAdornment: (
                  <ToolBar
                    title={"Remove File"}
                    placement="right"
                    arrow
                    darkMode
                  >
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        deleteFile(index);
                      }}
                      className="delete-icon-btn"
                    >
                      <img src={Images.icons.deleteIcon} />
                    </IconButton>
                  </ToolBar>
                ),
                readOnly: true,
              }}
              onClick={(e) => e.stopPropagation()}
            />
          ))}
        </div>
        {(!!error || !!customError) && (
          <div className="invalid-template-error-message">
            <img src={Images.icons.errorIcon} />
            <div>
              <span className="error">{text.fileSelection.error}: </span>
              <span className="error-info">
               {customError ? customError : error}
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

FileUploader.propTypes = {
  allowedFileTypes: PropTypes.arrayOf(PropTypes.string),
  multiSelect: PropTypes.bool,
  maxFileSize: PropTypes.string,
  isJdFileUpload: PropTypes.bool,
  error: PropTypes.string,
  setError: PropTypes.func,
};

FileUploader.defaultProps = {
  allowedFileTypes: [FileFormats.PNG, FileFormats.MP4],
  multiSelect: true,
  maxFileSize: "",
  isJdFileUpload: false,
  error: "",
  setError: () => {}
};

export default FileUploader;
