export const SortString = (arr = [], key, sortBy = "asc") => {
  return arr.sort(function (x, y) {
    let a =
        typeof x[key] === "string"
          ? x[key].trim().toUpperCase()
          : x[key] === null
          ? ""
          : x[key],
      b =
        typeof y[key] === "string"
          ? y[key].trim().toUpperCase()
          : y[key] === null
          ? ""
          : y[key];
    if (sortBy === "desc") {
      return a === b ? 0 : a > b ? -1 : 1;
    }
    return a === b ? 0 : a > b ? 1 : -1;
  });
};

export const sortByDisableRow = (array) => {
  return array.sort((a, b) => (a?.disableRow ? 1 : 0) - (b?.disableRow ? 1 : 0));
}

export const sortArrayWithDesireOrder = (array, key, desiredOrder, ascending) => {
  const sortedArray = array.slice().sort((a,b) => {
     const indexA = desiredOrder.indexOf(a[key]);
     const indexB = desiredOrder.indexOf(b[key]);
     if (!ascending) {
       return indexA - indexB;
     } else {
       return indexB - indexA;
     }
  })
 return sortedArray;
}