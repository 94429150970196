import React, { useState, useEffect } from "react";

import styled from "styled-components";
import COLORS from "../../../assets/colors";
import useFullLoader from "../../../Hooks/useFullLoader";
import { useSkillTableData } from "../../../Provider/SkillTableProvider";
import { ContentService } from "../../../services/ContentService";
import StarRating from "../../Common/StarRating";
import { CheckHumanizerTime, HumanizerTime } from "../../utils/DurationConvert";

const SkillContentDetail = () => {
  const [data, setData] = useState(null);
  const { setFullLoader } = useFullLoader();

  const {
    contentDetailData: { contentId },
  } = useSkillTableData();
  //   console.log("SkillContentDetail -> contentDetailData", contentId);

  useEffect(() => {
    getContentDetailApiCall();
    return () => {
      setData(null);
    };
  }, []);

  const getContentDetailApiCall = async () => {
    setFullLoader(true);
    try {
      const res = await ContentService.getSkillContentDetailV3("/" + contentId);
      setData(res);
    } catch (error) {
      console.log(error);
    } finally {
      setFullLoader(false);
    }
  };

  console.log(data);

  //   console.log(HumanizerTime(data?.contentDuration), data?.contentDuration);

  return (
    <>
      {!!data?.contentType && <ContentType>{data?.contentType}</ContentType>}
      <SkillContentDetailBlock>
        <div className="skill-content-detail-left">
          <div className="skill-content-detail-row">
            <strong>Title</strong>
            <p>
              <a href={data?.contentUrl} target="_blank">
                {data?.contentTitle || "-"}
              </a>
            </p>
          </div>
          <div className="skill-content-detail-row">
            <strong>Provider</strong>
            <p>{data?.contentProvider?.contentProvider || "-"}</p>
          </div>
          <div className="skill-content-detail-row">
            <strong>Description</strong>
            <p
              dangerouslySetInnerHTML={{
                __html: data?.contentFullDescription || "-",
              }}
            />
          </div>
          <div className="skill-content-detail-row">
            <strong>Additional Information</strong>
            <p
              dangerouslySetInnerHTML={{
                __html: data?.contentAdditionalDescription || "-",
              }}
            />
            {/* <p>{data?.contentAdditionalDescription || "-"}</p> */}
          </div>
        </div>

        <div className="skill-content-detail-right">
          <div className="skill-content-detail-row">
            <strong>Duration</strong>
            <p>
              {!!data?.contentDuration && data?.contentDuration > 0
                ? CheckHumanizerTime(data?.contentDuration * 1000)
                : 0}
            </p>
          </div>
          <div className="skill-content-detail-row">
            <strong>Language</strong>
            <p>{data?.contentLanguage || "-"}</p>
          </div>
          <div className="skill-content-detail-row">
            <strong>Provider Rating</strong>
            <p>
              {!!data && (
                <StarRating
                  defaultValue={Number(data?.contentProviderRating || 0)}
                  name="contentProviderRating"
                />
              )}
            </p>
          </div>
          <div className="skill-content-detail-row">
            <strong>Disprz Rating</strong>
            <p>
              {!!data && (
                <StarRating
                  defaultValue={Number(data?.contentDisprzRating || 0)}
                  name="contentDisprzRating"
                />
              )}
            </p>
          </div>
        </div>
      </SkillContentDetailBlock>
    </>
  );
};

const ContentType = styled.div`
  background-color: #3f51b5;
  border-radius: 16px;
  color: ${COLORS.PRIMARY_WHITE};
  font-size: 13px;
  padding: 4px 14px;
  position: absolute;
  top: 26px;
`;

const SkillContentDetailBlock = styled.div`
  display: flex;
  .skill-content-detail {
    &-left {
      width: 70%;
      border-right: 1px solid ${COLORS.C4_BORDER};
      padding-right: 15px;
      margin-right: 15px;
    }
    &-right {
      width: 30%;
    }
    &-row {
      display: flex;
      align-items: flex-start;
      padding-bottom: 15px;
      strong {
        line-height: 1.5;
        width: 120px;
      }
      & > p {
        flex: 1;
      }
      p {
        word-break: break-word;
      }

      a {
        color: ${COLORS.TITLE_BLUE};
        text-decoration: underline;
      }
    }
  }
`;

export default SkillContentDetail;
