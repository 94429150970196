import { Images } from "../../assets/images";
import { UserType } from "../../enums/UserType";

// User_Type === 1 is general user, User_Type === 2 is Super user
// User_Type === 3 is SME user, User_Type === 4 is Skilling Architect

export const IconMenu = [
  {
    imgSrc: Images.icons.logoIcon,
    alt: "Disprz",
    typeSrc: "img",
    title: "Disprz",
    accessUserTypes: [UserType.GENERAL_USER, UserType.GENERAL_USER_INTERNAL, UserType.SUPER_USER, UserType.SME, UserType.SKILLING_ARCHITECT],
  },
  {
    imgSrc: Images.sideNavIcons.home,
    alt: "Home",
    typeSrc: "svg",
    title: "Home",
    link: "/",
    accessUserTypes: [UserType.GENERAL_USER, UserType.GENERAL_USER_INTERNAL, UserType.SUPER_USER, UserType.SME, UserType.SKILLING_ARCHITECT],
  },
  {
    imgSrc: Images.sideNavIcons.define_client_persona,
    typeSrc: "svg",
    title: "Define Client Personas (Job Roles)",
    alt: "define_client_persona",
    link: "/personas",
    accessUserTypes: [UserType.GENERAL_USER, UserType.GENERAL_USER_INTERNAL, UserType.SUPER_USER, UserType.SKILLING_ARCHITECT],
  },
  {
    imgSrc: Images.sideNavIcons.architect_skills_for_roles,
    typeSrc: "svg",
    title: "Assign Skills for Client Persona (Job Roles)",
    alt: "architect_skills_for_roles",
    link: "/architect_skills",
    accessUserTypes: [UserType.GENERAL_USER, UserType.GENERAL_USER_INTERNAL, UserType.SUPER_USER, UserType.SKILLING_ARCHITECT],
  },
  {
    imgSrc: Images.sideNavIcons.role_explorer,
    typeSrc: "svg",
    title: "Role Explorer",
    alt: "role_explorer",
    link: "/role_explorer",
    accessUserTypes: [UserType.GENERAL_USER, UserType.GENERAL_USER_INTERNAL, UserType.SUPER_USER, UserType.SKILLING_ARCHITECT],
  },
  {
    imgSrc: Images.sideNavIcons.compare_personas,
    typeSrc: "svg",
    title: "Compare Personas",
    alt: "compare_persona",
    link: "/compare-persona",
    accessUserTypes: [UserType.GENERAL_USER, UserType.GENERAL_USER_INTERNAL, UserType.SUPER_USER, UserType.SKILLING_ARCHITECT],
  },
  {
    imgSrc: Images.sideNavIcons.compare_roles,
    typeSrc: "svg",
    title: "Compare Industry Roles",
    alt: "compare_role",
    link: "/compare-role",
    accessUserTypes: [UserType.GENERAL_USER, UserType.GENERAL_USER_INTERNAL, UserType.SUPER_USER, UserType.SKILLING_ARCHITECT],
  },
  {
    imgSrc: Images.sideNavIcons.add_maintain_skills,
    typeSrc: "svg",
    title: "Manage Skills",
    alt: "add_maintain_skills",
    link: "/skill/search",
    accessUserTypes: [UserType.SUPER_USER, UserType.SME, UserType.SKILLING_ARCHITECT],
  },
  {
    imgSrc: Images.sideNavIcons.add_maintain_roles,
    typeSrc: "svg",
    title: "Manage Industry Roles",
    alt: "add_maintain_roles",
    link: "/role",
    accessUserTypes: [UserType.SUPER_USER, UserType.SME, UserType.SKILLING_ARCHITECT],
  },
  {
    imgSrc: Images.sideNavIcons.add_maintain_disprzopedia,
    typeSrc: "svg",
    title: "Manage Disprzopedia",
    alt: "add_maintain_disprzopedia",
    link: "/disprzopedia",
    accessUserTypes: [UserType.SUPER_USER, UserType.SKILLING_ARCHITECT],
  },
  {
    imgSrc: Images.sideNavIcons.add_manage_users,
    typeSrc: "svg",
    title: "User Management",
    alt: "add_manage_users",
    link: "/users",
    accessUserTypes: [UserType.SUPER_USER],
  },
  {
    imgSrc: Images.sideNavIcons.add_manage_clients,
    typeSrc: "svg",
    title: "Client Management",
    alt: "add_manage_clients",
    link: "/clients",
    accessUserTypes: [UserType.SUPER_USER],
  },
];
