import React from "react";
import { navigate } from "@reach/router";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import styled from "styled-components";
import COLORS from "../../assets/colors";

export default ({ linkNav }) => {
  return (
    <BackNav
      onClick={() => navigate(!!linkNav ? linkNav : window.history.back())}
    >
      <ArrowBackIcon style={{ color: COLORS.PRIMARY_PURPLE }} />{" "}
      <span>Back</span>
    </BackNav>
  );
};

export const BackCheck = ({ handleCheckBack }) => {
  return (
    <BackNav onClick={handleCheckBack}>
      <ArrowBackIcon style={{ color: COLORS.PRIMARY_PURPLE }} />{" "}
      <span>Back</span>
    </BackNav>
  );
};

const BackNav = styled.div`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  margin-bottom: 20px;
  span {
    color: ${COLORS.TITLE_BLUE};
    margin-left: 10px;
  }
`;
