export const FileFormats = {
    ZIP: ".zip",
    RAR: ".rar",
    XLSX: ".xlsx",
    PNG: ".png",
    JPG: ".jpg",
    JPEG: ".jpeg",
    GIF: ".gif",
    WEBP: ".webp",
    MP4: ".mp4",
    WEBM: ".webm",
    MOV: ".mov",
    MP3: ".mp3",
    WAV: ".wav",
    AAC: ".aac",
    DOC: ".doc",
    DOCX: ".docx",
    PDF: ".pdf"
  };