import React, {
  useState,
  useEffect,
  useMemo,
  createContext,
  useContext,
  useCallback,
} from "react";
import useAuth from "../Hooks/useAuth";
import { RoleServices } from "../services/RoleServices";

export const RoleExplorerContext = createContext();

export default function RoleExplorerProvider({ children }) {
  const { userDetails } = useAuth();
  const { userId } = userDetails;
  const [roleExplorerData, setRoleExplorerData] = useState(null);

  useEffect(() => {
    if (!roleExplorerData && !!userId) {
      //   getRoleData();
    }
  }, [userId]);

  const getRoleData = useCallback(async () => {
    try {
      const res = await RoleServices.getRoleExplorerDataV3(`?userId=${38}`);
      setRoleExplorerData(res);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const value = useMemo(() => {
    return {
      roleExplorerData,
      setRoleExplorerData,
    };
  }, [roleExplorerData, setRoleExplorerData]);

  return (
    <RoleExplorerContext.Provider value={value}>
      {children}
    </RoleExplorerContext.Provider>
  );
}

export function useRoleExplorerContext() {
  const context = useContext(RoleExplorerContext);
  if (context === undefined) {
    throw new Error("useRoleExplorerContext must be used within a Provider");
  }
  return context;
}
