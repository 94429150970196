import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { H5 } from "../../Common/Typography";
import AutoFilterLocal from "../../Common/FormElements/AutoFilterLocal";
import { SecondaryButton } from "../../Common/Buttons/SecondaryButton";
import COLORS from "../../../assets/colors";
import ButtonIcon from "../../Common/Buttons/ButtonIcon";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { ClickAwayListener } from "@material-ui/core";
import ToolBar from "../../Common/ToolBar";
import ClearIcon from "@material-ui/icons/Clear";
import { SkillService } from "../../../services/SkillService";
import useAlert from "../../../Hooks/useAlert";
import ModalPopup from "../../Common/ModalPopup";
import ConfirmationButton from "../../Common/ConfirmationButton";
import useFullLoader from "../../../Hooks/useFullLoader";
import ProgressIndicator from "../../Common/ProgressIndicator";
import useAuth from "../../../Hooks/useAuth";
import { isUserHasBulkImportAccess, isValidUser } from "../../../helpers/CommonHelper";
import { UserType } from "../../../enums/UserType";
import SaveAltIcon from '@material-ui/icons/SaveAlt';

export default ({ data, list, changePersona, roleData, isNavigatedFromRoleMatch , isSkillMappingInProgress, id, getImportSkillData, handleSkillMatchingDone, triggerExportExcel, showDownload, loading, setLoading}) => {
  const {showAlert} = useAlert();
  const { setFullLoader } = useFullLoader();
  const fileInputRef = useRef(null);

  const { userDetails } = useAuth();
  const { User_Type } = userDetails;

  const [personaVal, setPersonaVal] = useState(null);
  const [inputValue, setInputValue] = useState(null);
  const [showInputTemplate, setShowInputTemplate] = useState(false);
  const allowedFileTypes = [
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
 ];
 
 const [confirmPopUp, setConfirmPopup] = useState(false);
 const [confirmTitle, setConfirmTitle] = useState("");
 const [skillMappingTemplateUrl, setSkillMappingTemplateUrl] = useState(null);


  const handleSearch = (val, reason) => {
    if (reason === "select-option") {
      changePersona(val);
      setPersonaVal(val.label);
    }
  };
  useEffect(() => {
    if (!!data) {
      setPersonaVal(data?.personaName);
      !isNavigatedFromRoleMatch ? setInputValue(data?.personaName) : setInputValue("");
    }
    return () => {
      // setPersonaVal(null);
      setInputValue(null);
    };
  }, [data]);

  useEffect(() => {
    const getImportTemplateUrl = async () => {
       try{
          const res = await SkillService.getSkillMappingTemplateUrlV3();
          setSkillMappingTemplateUrl(res.value);
       }catch (error){
         showAlert(error, "error", 2000)
       }
    };
    if(!isNavigatedFromRoleMatch && isUserHasBulkImportAccess(User_Type)){
      getImportTemplateUrl();
    }
   },[User_Type])

  const skillMatch = () => (
    <>
      <span
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        Continue skill mapping
      </span>
      <ToolBar title="Discard the current skill mapping" arrow={true}>
        <div className="clear-skill-mapping">
          <ButtonIcon
            icon={<ClearIcon fontSize={"small"} />}
            className="clear-icon"
            onClick={() => {
              setConfirmPopup(true);
              setConfirmTitle("Are you sure want to discard skill mapping?");
            }}
          />
        </div>
      </ToolBar>
    </>
  )

  const handleInputChange = (val) => {
    setInputValue(val)
  };
  
  const handleFileUpload = () => {
    fileInputRef.current.click();
  }

  const handleFileSelect = async (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile && allowedFileTypes.includes(selectedFile.type)) {
        const formData = new FormData();
        formData.append("file", selectedFile);
        setLoading(true);
        try {
            await SkillService.ImportClientSkillDataV3(id, formData);
            await getImportSkillData();
        } catch (error) {
            setLoading(false);
            console.log('error', error);
            const errorArray = JSON.parse(error);
            if (Array.isArray(errorArray)) {
                const errorMessages = errorArray.map((errorItem) => errorItem.message);
                showAlert(errorMessages.join(", "), "error", 2000);
            } else {
                showAlert(error?.message, "error", 2000);
            }
        }
    }
  };

  const handleNo = () => closePopup();
  const closePopup = () => {
    setConfirmPopup(false);
    setConfirmTitle("");
  }
  const handleConfirmYes = async () => {
    if(confirmTitle === "Are you sure want to discard skill mapping?"){
      await handleSkillMatchingDone();
      closePopup();
    }
  };

  return (
    <>
      {(!!data.personaName || isNavigatedFromRoleMatch) && (
        <SkillHeader>
          <ClientDetails>
          <div style={{ width: 300 }}>
            {((!!data.personaName && !!list) || isNavigatedFromRoleMatch) ? (
              <AutoFilterLocal
                list={list}
                filterLabel="label"
                label="Client Persona / Role"
                style={{ padding: "0px 0px 20px 0px", width: "100%" }}
                placeholder={personaVal}
                optionChange={handleSearch}
                value={personaVal}
                inputVal={inputValue}
                inputBoxChange={handleInputChange}
                groupLabel="groupName"
              />
            ) : null}
          </div>
          {!isNavigatedFromRoleMatch &&
              <DetailsBolck>
              <H5>
                Reporting To<small>{data?.reportingTo}</small>
              </H5>
            </DetailsBolck>}
          {!!roleData && (
            <DetailsBolck>
              <H5>
                Matching Industry Role
                <small className="industry-role">
                  {roleData?.subsector} | {roleData?.functionName} |{" "}
                  {roleData?.roleName}
                </small>
              </H5>
            </DetailsBolck>
          )}
          <DetailsBolck>
            <H5>
              Leadership Role
              <small>{data?.leadershipRole?.leadershipRoleName}</small>
            </H5>
          </DetailsBolck>
          </ClientDetails>
          <ExcelSection>
          {(!isNavigatedFromRoleMatch && isUserHasBulkImportAccess(User_Type)) &&
            <DetailsBolck>
              {!isSkillMappingInProgress && 
                <SecondaryButton className = "import-skill btn" onClick={handleFileUpload}>
                  Import Client Skills Data
                    <ButtonIcon
                      icon={<HelpOutlineIcon fontSize={'small'}/>}
                      className={`help-icon`}
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowInputTemplate(!showInputTemplate);
                      }}
                    />
                  {!!showInputTemplate && (
                    <ClickAwayListener
                      onClickAway={() => setShowInputTemplate(false)}
                    >
                      <div
                        className="input-template"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <span>
                          Download the input template{" "}
                          <a
                            href={skillMappingTemplateUrl}
                            onClick={() => setShowInputTemplate(false)}
                          >
                            here.
                          </a>
                        </span>
                      </div>
                    </ClickAwayListener>
                  )}
                </SecondaryButton>}
                <input
                  type="file"
                  ref={fileInputRef}
                  onClick={(e) => {
                    e.target.value = null;
                  }}
                  onChange={handleFileSelect}
                  style={{ display: "none" }}
                />
                {(!!isSkillMappingInProgress) && <SecondaryButton
                        className={`import-skill inprogress btn`}
                    >
                      {skillMatch()}
                </SecondaryButton>}
                {loading ? (
                    <ProgressIndicator message="Importing Client Skills" />
                  ) : null}
              </DetailsBolck>
            }
            {
              !isValidUser(User_Type, UserType.GENERAL_USER) && !isValidUser(User_Type, UserType.GENERAL_USER_INTERNAL) && isUserHasBulkImportAccess(User_Type) &&
              <DetailsBolck>
                <div className={showDownload ? "export-btn" : ""}>
                  <SecondaryButton
                    type="submit"
                    onClick={() => triggerExportExcel()}
                    className = "export-skill btn"
                    style={{ background: showDownload ? COLORS.DOWNLOAD_GREEN : COLORS.PRIMARY_WHITE }}
                  >
                    {showDownload && <SaveAltIcon fontSize={"small"}/>}
                    {showDownload ? "Download" : "Export to Excel" }
                  </SecondaryButton>
                </div>
              </DetailsBolck>
            }
          </ExcelSection>
        </SkillHeader>
      )}
      <ModalPopup
        open={confirmPopUp}
        maxWidth="sm"
        title={confirmTitle}
        closePopup={closePopup}
      >
        <ConfirmationButton yesClick={handleConfirmYes} noClick={handleNo}/>
      </ModalPopup>
    </>
  );
};

const SkillHeader = styled.div`
  display: flex;
  justify-content: space-between;
  & > div {
    ${'' /* min-width: 100px; */}
    margin-right: 40px;
  }
`;
const ClientDetails = styled.div`
  display: flex;
  & > div {
    ${'' /* min-width: 100px; */}
    margin-right: 40px;
  }
`;

const ExcelSection = styled.div`
 display: flex;
 gap: 20px;
 justify-content: flex-end;
 margin-right: 0px !important;
 position: relative;
 .import-skill{
    min-width: 183px;
    display: flex;
    justify-content: center;
    .help-icon {
      position: absolute;
      right: 2px;
      ${'' /* fontsize: 1.25rem; */}
      color: ${COLORS.PRIMARY_PURPLE};
      ${'' /* &.help-icon-disable {
        color: rgba(0, 0, 0, 0.12);
      } */}
      padding: 5px;
        width: 30px;
        height: 30px;
    }
    .input-template {
      position: absolute;
      top: 110%;
      left: 0px;
      background: ${COLORS.BAR_RED};
      min-width: max-content;
      color: ${COLORS.PRIMARY_WHITE};
      padding: 3px 6px;
      cursor: default;
      a {
        color: ${COLORS.PRIMARY_WHITE};
        text-decoration: underline;
        cursor: pointer;
      }
    }
    &.inprogress{
      min-width: 180px;
    }
    .clear-skill-mapping {
      position: absolute;
      right: 2px;
      .clear-icon {
        color: ${COLORS.PRIMARY_PURPLE};
        padding: 5px;
        width: 30px;
        height: 30px;
        svg{
          ${'' /* font-size: 1.10rem; */}
        }
      }
    }
    &.btn{
      padding-top: 0px !important;
      padding-left: 0px !important;
      padding-bottom: 0px !important;
      padding-right: 11px !important;
    }
  }
  .export-skill{
    min-width: 135px;
    &.btn{
      padding: 0px !important;
    }
  }
  .btn{
    background-color: ${COLORS.PRIMARY_WHITE};
    ${'' /* padding-left: 15px !important */}
    span{
      font-size: 11px;
    }
  }
`

const DetailsBolck = styled.div`
  h5 {
    small {
      display: block;
      font-size: 12px;
      font-weight: 400;
    }
    .industry-role{
      max-width: 300px;
    }
  }
  .export-btn{
      button{
        color: ${COLORS.PRIMARY_WHITE};
        background: ${COLORS.DOWNLOAD_GREEN};
        border: solid 1px ${COLORS.DOWNLOAD_GREEN};
        span{
          display: flex; 
          justify-content: center; 
          align-items: center;
          gap: 5px;
        }
      }
    }
`;
