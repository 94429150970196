import React, { useEffect, useState } from "react";
import styled from "styled-components";
import COLORS from "../../assets/colors";
import { H2 } from "../Common/Typography";
import { Images } from "../../assets/images";
import { AuthService } from "../../services/AuthService";
import SelectMenu from "../Common/FormElements/SelectMenu";
import { Percentage } from "../../helpers/PercentageCalc";

export default ({
  count,
  sectorValue,
  sourceValue,
  handleSector,
  handleSource,
}) => {
  const [sectorList, setSectorList] = useState(null);
  const [sourceList, setSourceList] = useState(null);
  const [annotatePer, setAnnotatePer] = useState(0);
  const [unannotatePer, setUnAnnotatePer] = useState(0);

  useEffect(() => {
    const getIndustrySectors = async () => {
      try {
        const resultSectors = await AuthService.jobSectorList();
        const resultSources = await AuthService.jobSourceList();
        setSectorList(resultSectors.map((el) => ({ label: el, value: el })));
        setSourceList(resultSources.map((el) => ({ label: el, value: el })));
      } catch (error) {
        console.log(error);
      }
    };

    getIndustrySectors();

    return () => {
      setSectorList(null);
      setSourceList(null);
    };
  }, []);

  useEffect(() => {
    if (!!count) {
      setAnnotatePer(Percentage(count.AllJobsCount, count.AnnotatedJobsCount));
      setUnAnnotatePer(
        Percentage(count.AllJobsCount, count.UnannotatedJobsCount),
      );
    }
    return () => {
      setAnnotatePer(0);
      setUnAnnotatePer(0);
    };
  }, [count]);

  const handleSectorChange = (val) => handleSector(val);
  const handleSourceChange = (val) => handleSource(val);

  return (
    <div>
      <CounteBlock>
        <WhiteBoxBg>
          <div className="dropDownBlock">
            <div className="dropDownListTop">
              <SelectMenu
                width="200px"
                label="Sectors"
                value={sectorValue}
                list={
                  !!sectorList
                    ? [{ label: "All", value: "All" }, ...sectorList]
                    : []
                }
                handleSelect={handleSectorChange}
              />
            </div>
            <div className="dropDownListTop">
              <SelectMenu
                width="200px"
                label="Source"
                value={sourceValue}
                list={
                  !!sourceList
                    ? [{ label: "All", value: "All" }, ...sourceList]
                    : []
                }
                handleSelect={handleSourceChange}
              />
            </div>
          </div>
        </WhiteBoxBg>
        <WhiteBox>
          <H2>
            {!!count.AllJobsCount ? count.AllJobsCount : "000"}

            <small>Jobs</small>
          </H2>
          <div className="count-bar">
            <span style={{ width: "100%" }}>&nbsp;</span>
          </div>
        </WhiteBox>
        <WhiteBox>
          <H2>
            {!!count ? count.AnnotatedJobsCount : "000"}{" "}
            <small>Annotated</small>
          </H2>
          <div className="count-bar">
            <span style={{ width: annotatePer + "%" }}>&nbsp;</span>
          </div>
        </WhiteBox>
        <WhiteBox>
          <H2>
            {!!count ? count.UnannotatedJobsCount : "000"}{" "}
            <small>Unannotated</small>
          </H2>
          <div className="count-bar">
            <span style={{ width: unannotatePer + "%" }}>&nbsp;</span>
          </div>
        </WhiteBox>
      </CounteBlock>
    </div>
  );
};

const CounteBlock = styled.div`
  display: flex;
  margin: 0 -8px;
  padding-bottom: 20px;
`;

const WhiteBox = styled.div`
  background-color: ${COLORS.PRIMARY_WHITE};
  border-radius: 14px;
  box-shadow: 0 1px 3px 0 rgba(97, 122, 163, 0.24);
  margin: 0 8px;
  min-height: 160px;
  min-width: 130px;
  padding: 28px 16px;

  .dropDownBlock {
    display: flex;
    align-items: flex-end;
    .dropDownListTop {
      margin-right: 15px;
      width: 222px;
    }
  }

  h2 {
    color: ${COLORS.TITLE_BLUE};
    font-size: 31px;
    line-height: 36px;
    padding-bottom: 28px;
    small {
      display: block;
      font-size: 16px;
      font-weight: normal;
      line-height: 22px;
      opacity: 0.75;
    }
  }

  .count-bar {
    background-color: ${COLORS.BAR_ASH};
    border-radius: 2px;
    height: 4px;
    position: relative;
    width: 100%;
    span {
      background-color: ${COLORS.BAR_RED};
      border-radius: 2px;
      display: block;
      position: absolute;
      top: 0px;
      left: 0px;
      height: 100%;
    }
  }
`;

const WhiteBoxBg = styled(WhiteBox)`
  background: #ffffff url(${Images.bgImgs.ManLapImg}) right -10px bottom no-repeat;
  flex: 1;
  width: 765px;
`;
