import React, { useState } from "react";
import { Add, Delete } from "@material-ui/icons";
import SortableTree, {
  addNodeUnderParent,
  removeNodeAtPath,
  changeNodeAtPath,
} from "react-sortable-tree";
import "react-sortable-tree/style.css";
import ButtonIcon from "../../Common/Buttons/ButtonIcon";

const data = [
  {
    title: "Master Skill",
    // subtitle: "`subtitle`",
    expanded: true,
    children: [],
  },
];

const CreateSkillTree = () => {
  const [state, setState] = useState(data);
  const getNodeKey = ({ treeIndex }) => treeIndex;
  console.log(state);

  return (
    <div style={{ height: "500px" }}>
      <SortableTree
        treeData={state}
        onChange={(tree) => {
          console.log(tree);
          setState(tree);
        }}
        canDrag={(tree) => (tree.treeIndex === 0 ? false : true)}
        canDrop={(tree) => tree.nextTreeIndex !== 0}
        isVirtualized={true}
        generateNodeProps={({ node, path }) => ({
          title: !node.needsTitle ? (
            node.title
          ) : (
            <form
              onSubmit={(event) => {
                event.preventDefault();
                const { needsTitle, ...nodeWithoutNeedsTitle } = node;
                setState(
                  changeNodeAtPath({
                    treeData: state,
                    path,
                    getNodeKey,
                    newNode: nodeWithoutNeedsTitle,
                  })
                );
              }}
            >
              <input
                autoFocus
                value={node.title}
                onChange={(event) => {
                  const title = event.target.value;

                  setState(
                    changeNodeAtPath({
                      treeData: state,
                      path,
                      getNodeKey,
                      newNode: { ...node, title },
                    })
                  );
                }}
              />
            </form>
          ),
          buttons: [
            <ButtonIcon
              color="primary"
              icon={<Add />}
              onClick={() =>
                setState(
                  addNodeUnderParent({
                    treeData: state,
                    parentKey: path[path.length - 1],
                    expandParent: true,
                    getNodeKey: ({ treeIndex }) => treeIndex,
                    newNode: {
                      title: "",
                      needsTitle: true,
                    },
                  }).treeData
                )
              }
            />,
            <ButtonIcon
              color="black"
              icon={<Delete />}
              onClick={() =>
                setState(
                  removeNodeAtPath({
                    treeData: state,
                    path: path,
                    getNodeKey: ({ treeIndex }) => treeIndex,
                    ignoreCollapsed: false,
                  })
                )
              }
            />,
          ],
        })}
        // theme={}
      />
    </div>
  );
};

export default CreateSkillTree;
