import { RoleServices } from "../../../../services/RoleServices";

export const getPersonasMappedToRole = async (roleId) => {
  try {
    const personasList = await RoleServices.GetPersonasMappedToRoleV3(roleId);
    return personasList;
  } catch (error) {
    console.error('Error fetching personas:', error);
    throw error;
  }
};
