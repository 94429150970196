import React, { createContext } from "react";
import { useSnackbar } from "notistack";

export const AlertContext = createContext();

function AlertProvider({ children }) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  function checkMessage(message) {
    if (typeof message === "string") {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: message,
          }}
        />
      );
    } else if (typeof message === "object" && message.hasOwnProperty("error")) {
      return message.error;
    } else {
      return "Something went wrong";
    }
  }

  function showAlert(
    message = "Something went wrong",
    variant = "error",
    interval = 3000,
    persist = false
  ) {
    enqueueSnackbar(checkMessage(message), {
      variant,
      autoHideDuration: interval,
      anchorOrigin: {
        vertical: "top",
        horizontal: "right",
      },
      persist,
    });
  }
  // closeSnackbar();
  return (
    <AlertContext.Provider value={{ showAlert, closeSnackbar }}>
      {children}
    </AlertContext.Provider>
  );
}

export default AlertProvider;
