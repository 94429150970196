export default [
  {
    colKey: "clientName",
    colValue: "Client Name",
    sortable: true,
  },
  {
    colKey: "clientDomain",
    colValue: "Domain for Google Sign-In",
  },
  {
    colKey: "orgGrowthStageName",
    colValue: "Org Growth Stage",
    sortable: true,
  },
  {
    colKey: "subSectorList",
    colValue: "Industries",
    colType: "listing",
    colListName: "subsector",
    moreCount: 2,
    width: 300,
  },
  {
    colKey: "more_action",
    colValue: "",
    width: 60,
  },
];
