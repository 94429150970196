import  { useEffect, useRef, useState } from "react";
import { SkillMappingType } from "../../../../enums/SkillMappingType";
import { skillStatusType } from "../../../../enums/SkillStatusType";
import { AuthService } from "../../../../services/AuthService";
import { useLocation } from "@reach/router";
import { CountInit } from "../../../Common/PaginationCustom";
import { AssignSkillsStore, SkillStore } from "../../../../store/store_state";
import { SortString } from "../../../../helpers/SortString";
import { SkillService } from "../../../../services/SkillService";
import { navigate } from "@reach/router";
import { getOrDefault, getUpdatedPageAndOffsetBasedOnRowCount, handleTableCheckAll, handleTableCheckBox } from "../../../../helpers/CommonHelper";
import useAlert from "../../../../Hooks/useAlert";
import useFullLoader from "../../../../Hooks/useFullLoader";
import useRoles from "../../../../Hooks/useRoles";
import { getAndSaveClientRoles } from "../AssignSkillsHelper";
import text from "../../../../locale.en.json";

const roleTypes = ['industry', 'leader'];
const useSearchSkills = ({rolePersonaSkillIds, roleId, personaId, personaSkills, categoryMenu, skillType, skillSourceIds, isNavigatedFromRoleMatch, isNavigatedFromRoleScreen, roleSelectionData}) => {
  const { setFullLoader } = useFullLoader();
  const { clientName } = useRoles();
  const { showAlert } = useAlert();
 
  const [selectCategory, setSelectCategory] = useState([]);
  const [selectFamily, setSelectFamily] = useState([]);
  const [skillName, setSkillName] = useState("");
  const [skillList, setSkillList] = useState(null);
  const [skillListCopy, setSkillListCopy] = useState(null);
  const [screenSource, setScreenSource] = useState(0);
  const [familyMenu, setFamilyMenu] = useState([]);
  const [sourceList, setSourceList] = useState([]);
  const [selectSource, setSelectSource] = useState([]);
  const [roleSelectionDataSkills, setRoleSelectionDataSkills] = useState([]);
  const [skillSelectedContainer , setSkillSelectedContainer] = useState([]);
  const [levelSelectedContainerDictionary, setLevelSelectedContainerDictionary] = useState({});
  const [noSkillMessage, setNoSkillMessage] = useState("");
  const roleSkillIds = AssignSkillsStore.useState((s) => s.roleSkillIds);
  const roleSkillNames = AssignSkillsStore.useState((s) => s.roleSkillNames);
  const selectedSearchedSkills = AssignSkillsStore.useState((s) => s.selectedSearchedSkills);

  const location = useLocation();
  const { state } = location;
  const searchSkillsForRoleRef = useRef(false);

  const getPersonasToBeFiltered = () => {
    return state?.isNavigatedFromArchitectSkills ? [state?.personaName] : state?.personasCompared;
  }

  const isSearchConditionsMet = () => {
     return (
      !skillList && 
      !skillListCopy && 
      !skillSelectedContainer.length && 
      !Object.keys(levelSelectedContainerDictionary || {}).length
     )
  }

  const checkAndHandleSearchForRoleTypes = () => {
    if(searchSkillsForRoleRef.current) {
      handleSearch();
      searchSkillsForRoleRef.current = false;
    }
  };

  useEffect(() => {
    if (isSearchConditionsMet()) {
      !roleTypes.includes(skillType) ? handleSearch() : checkAndHandleSearchForRoleTypes();
    }
  }, [skillList, skillListCopy, skillSelectedContainer, levelSelectedContainerDictionary])

  useEffect(() => {
    if(clientName && isNavigatedFromRoleMatch && !!roleSelectionData && !!roleSelectionData?.length) {
      const skillIdsToBeExcludedSet = new Set();
      const personasToBeFiltered = getPersonasToBeFiltered();
      roleSelectionData
        .filter((data) => personasToBeFiltered.includes(data.personaName))
        .forEach((data) => {
          if (!!data?.proficiencies && data.proficiencies.length > 0) {
            data.proficiencies.forEach((skill) => {
              skillIdsToBeExcludedSet.add(skill.skillId);
            });
          }
        });
      setRoleSelectionDataSkills(Array.from(skillIdsToBeExcludedSet));
    }
  }, [clientName, isNavigatedFromRoleMatch, roleSelectionData])

  //navigated via architect-skills-screen
  const personaSkillsMapped =
    !!personaSkills?.length > 0
      ? personaSkills?.map(({ proficiencyId, skillCriticality, skillAddedFrom  }) => ({
          proficiencyId,
          skillCriticality,
          skillAddedFrom: !!skillAddedFrom ? skillAddedFrom : SkillMappingType.ADD_SKILL
         }))
      : [];

  const personasSkillsCompared = getOrDefault(state?.personasSkillsCompared, []);
  const isNavigatedFromComparePersona = getOrDefault(state?.isNavigatedFromComparePersona, false);
  const isPersonasCompared = !!state?.personasCompared && state?.personasCompared?.length > 0 ? true : false;

  useEffect(() => {
    setScreenSource(checkScreenSource());
  }, []);

  useEffect(() => {
    if(clientName && !isNavigatedFromRoleScreen) getSourcesForClient();
  },[clientName, isNavigatedFromRoleScreen])

  useEffect(() => {
    if (!!selectCategory.length) {
      familyAPICall(selectCategory);
    } else {
      setFamilyMenu([]);
    }
  }, [selectCategory]);

  useEffect(() => {
    if(!skillName && !selectCategory?.length && !selectFamily?.length && !selectSource?.length){
      setListTotal(null);
      setSkillList(null);
      setSkillListCopy(null);
    }
  }, [skillName, selectCategory, selectFamily, selectSource])

  const familyAPICall = async (req) => {
    const resFam = await SkillService.getSkillFamiliesV3(
      "?" + req?.map((el) => `categoryIds=${el}`).join("&")
    );
    setFamilyMenu(
      SortString(resFam, "skillFamily").map((el) => ({
        label: el.skillFamily,
        value: el.skillFamilyId,
      }))
    );
  };

  const getSourcesForClient = async () => {
    const res = await AuthService.getClientDetailV3(clientName);
    setSourceList(SortString(res?.sourceList, "source").map((el) => ({
      label: el.source,
      value: el.sourceId,
    })));
  }

  const checkScreenSource = () => {
    switch (skillType) {
      case "industry":
        return 1;
      case "persona":
        return 1;
      default:
        return 0;
    }
  };

  const handleCategory = async (val) => {
    setSelectCategory(val.map((el) => el.value));
  };
  const handleFamily = (val) => setSelectFamily(val.map((el) => el.value));

  const sourceListIds = () => {
    if(sourceList?.length > 0){
      return sourceList.map((source) => source?.value);
    } return [];
  }

  const postData = {
    searchString: skillName,
    searchInDescription: false,
    searchAcrossFields: false,
    skillCateogryId: !!selectCategory.length
      ? selectCategory
      : categoryMenu.map(({ value }) => value),
    skillFamilyId: selectFamily,
    skillStatus: [skillStatusType.PUBLISHED],
    clientId: skillType === "persona" ? clientName : 0,
    skillSourceId: isNavigatedFromRoleScreen ? skillSourceIds : selectSource?.length === 0 ? sourceListIds() : selectSource,
    screenSource,
    excludeSkills: isNavigatedFromRoleMatch ? roleSelectionDataSkills : [],
  };
  const handleSource = (val) => setSelectSource(val.map((el) => el.value));

  const [listTotal, setListTotal] = useState(null);
  const [pageOffset, setPageOffset] = useState(0);
  const [rowCount, setrowCount] = useState(CountInit); //CountInit
  const [currPage, setCurrPage] = useState(1);

  const handlePagination = (e, page) => {
    setCurrPage(page);
    const params = {
      pagesize: rowCount,
      offset: (page - 1) * rowCount,
    };
    handleSearch(params);
    setPageOffset((page - 1) * rowCount);
  };
  const changeRowCount = (count) => {
    const { updatedCurrPage, updatedPageOffset } = getUpdatedPageAndOffsetBasedOnRowCount(pageOffset, count);
    const params = {
      pagesize: count,
      offset: updatedPageOffset,
    };
    handleSearch(params);
    setrowCount(count);
    setCurrPage(updatedCurrPage);
    setPageOffset(updatedPageOffset);
  };


  const getUpdatedModifiedList = (modyfiedList, list) => {
    let updatedModifiedList = [];
    if (!list) {
        return modyfiedList;
    } else {
        updatedModifiedList = modyfiedList.filter(modifiedItem => {
            const skillIdPresent = list.some(listItem => listItem.skillId === modifiedItem.skillId);
            return !skillIdPresent;
        });
    }
    return updatedModifiedList;
};

  const handleSearchButtonClick = async () => {
     setSkillList(null);
     setSkillListCopy(null);
     setSkillSelectedContainer([]);
     setLevelSelectedContainerDictionary({});
     setPageOffset(0);
     setrowCount(CountInit);
     setCurrPage(1);
     if (roleTypes.includes(skillType)) {
      searchSkillsForRoleRef.current = true;
    }
  }

  const selectedSkillIds = selectedSearchedSkills.map((s) => s.skillId);

  const handleSearch = async (paramsStr = null, excludeSkills) => {
    if (!paramsStr) {
      setListTotal(null);
      setPageOffset(0);
      setrowCount(CountInit);
    }
    if (!!skillName || selectFamily?.length > 0 || selectCategory?.length > 0 || selectSource?.length > 0 || isNavigatedFromRoleScreen) {
      setFullLoader(true);
      try {
        const res = await SkillService.SearchSkillV3(
          {
            ...postData,
            pagesize: paramsStr?.pagesize || rowCount,
            offset: paramsStr?.offset || 0,
            excludeSkills: !!excludeSkills
              ? [...rolePersonaSkillIds, ...excludeSkills, ...roleSkillIds]
              : [...rolePersonaSkillIds, ...postData.excludeSkills, ...roleSkillIds, ...selectedSkillIds],
          }
        );
        const filterSkillArray = res?.skills || [];
        
        if(filterSkillArray.length === 0){
          setNoSkillMessage(roleSkillNames.includes(skillName) && !isPersonasCompared ? text.skill.duplicateSkillInIndustryRoleSection : text.skill.searchTermNoMatch);
        } else if(noSkillMessage){
          setNoSkillMessage("");
        }
        setListTotal(res?.total);
        const modyfiedList = SortString(filterSkillArray, "skillName").map(
          (el) => ({
            ...el,
            checked: skillSelectedContainer.includes(el.skillId),
            ...(levelSelectedContainerDictionary[el.skillId] || {}),
            target_proficiency: !!levelSelectedContainerDictionary[el.skillId] ? levelSelectedContainerDictionary[el.skillId][levelKey] : ""
          })
        );
        const updatedSkillListCopy = !!skillListCopy ? [...skillListCopy, ...getUpdatedModifiedList(modyfiedList, skillListCopy)] : modyfiedList;
        setSkillList(modyfiedList);
        setSkillListCopy(updatedSkillListCopy);
      } catch (errors) {
        console.log(errors);
      } finally {
        setFullLoader(false);
      }
    }
  };

  const handleSelectedItems = (array, item) => {
    const indexToRemove = array.indexOf(item.skillId);
      const isItemSelected = array?.includes(item.skillId);
      if (!isItemSelected) {
        array.push(item.skillId);
      } else {
        indexToRemove !== -1 && array.splice(indexToRemove, 1);
      }
    setSkillSelectedContainer(array);
  }

  const handleCheckBox = (e, item) => {
    handleSelectedItems(skillSelectedContainer, item);
    const updatedSkillList =  handleTableCheckBox(e.target.checked, item, skillList, "skillId");
    setSkillList(updatedSkillList);
    const updatedSkillListCopy = handleTableCheckBox(e.target.checked, item, skillListCopy, "skillId");
    setSkillListCopy(updatedSkillListCopy);
  };

  const handleCheckAll = (e) => {
    const skillIds = skillList?.map((skill) => skill.skillId) || [];
    if(e.target.checked) {
      const updatedSkillSelectedContainer = [...skillSelectedContainer, ...skillIds];
      setSkillSelectedContainer(updatedSkillSelectedContainer);
    } else {
      const updatedSkillSelectedContainer = skillSelectedContainer.filter((skillId) => !skillIds.includes(skillId));
      setSkillSelectedContainer(updatedSkillSelectedContainer);
    }
    setSkillList(skillList.map((el) => ({ ...el, checked: e.target.checked })));
    setSkillListCopy(skillListCopy.map((el) => ({ ...el, checked: e.target.checked })));
  }

  const handleSelectedSearchedSkillCheckBox = (e, item) => {
    const updatedSelectedSearchedSkills =  handleTableCheckBox(e.target.checked, item, selectedSearchedSkills, "skillId");
    AssignSkillsStore.update((s) => {
      s.selectedSearchedSkills = updatedSelectedSearchedSkills
    });
  };

  const handleSelectedSearchedSkillCheckAll = (e) => {
    const updatedSelectedSearchedSkills =  handleTableCheckAll(e.target.checked, selectedSearchedSkills);
    AssignSkillsStore.update((s) => {
      s.selectedSearchedSkills = updatedSelectedSearchedSkills
    });
  }

    const updateComparedPersonasSkillProficienciesSequentially = async () => {
      const postRoleData = [...skillListCopy, ...selectedSearchedSkills]
        .filter((skill) => !!skill?.checked && !!skill?.proficiencyId)
        .map(({ proficiencyId }) => ({ proficiencyId,  skillAddedFrom: SkillMappingType.ADD_SKILL, skillCriticality: "" }));

        for (const key in personasSkillsCompared) {
          const personaId = parseInt(key);
          const proficiencies = personasSkillsCompared[key];
          await AuthService.updatePersonaSkillV3(personaId, {skillProficiencyMappings: [...proficiencies, ...postRoleData], skillIdsToRemoveFromPersona: [], ExcludedSkillIds: []});
        }
        await showAlert(text.skill.addedSkillsToPersonas.replace('{{count}}', postRoleData.length), "success", 1500);
    }

    const handleSkillAdd = async () => {
      if(selectedSearchedSkills.some((skill) => skill.checked && !skill.proficiencyId)){
        showAlert(text.skill.pleaseSelectLevelsForSelectedSkills, "info", 1500);
        return;
      }
      if(isNavigatedFromRoleMatch){
        const personasCompared = getPersonasToBeFiltered();
        await getAndSaveClientRoles(selectedSearchedSkills, roleSelectionData, personasCompared, clientName, location.state.importTokenId, setFullLoader, showAlert);
        return;
      } else if(isNavigatedFromComparePersona){
        await updateComparedPersonasSkillProficienciesSequentially();
        navigate(-1);
        return;
      }

      setFullLoader(true);
      const postRoleData = [...selectedSearchedSkills]
        .filter((skill) => !!skill?.checked && !!skill?.proficiencyId)
        .map(({ proficiencyId }) => ({ proficiencyId, skillCriticality: "", skillAddedFrom: SkillMappingType.ADD_SKILL }));
      try {
        if (skillType === "industry") {
          await SkillService.addSkillToIndustryRoleV3(roleId, "proficiencies", postRoleData);
          await setFullLoader(false);
          await showAlert(text.skill.addedSkillsToIndustryRole.replace('{{count}}', postRoleData.length), "success",1500);
          navigate(-1);
        } else if (skillType === "leader") {
          await SkillService.addSkillToLeaderRoleV3(roleId, "proficiencies", postRoleData);
          await setFullLoader(false);
          await showAlert(text.skill.addedSkillsToLeadershipRole.replace('{{count}}', postRoleData.length), "success", 1500);
          navigate(-1);
        } else if (skillType === "persona") {
          await AuthService.updatePersonaSkillV3(personaId, { skillProficiencyMappings: [...personaSkillsMapped, ...postRoleData], skillIdsToRemoveFromPersona: [], ExcludedSkillIds: [] });
          await setFullLoader(false);
          await showAlert(text.skill.addedSkillsToPersona.replace('{{count}}', postRoleData.length), "success", 1500);
          SkillStore.update((s) => { s.skillAdded = true; });
          navigate(-1);
        }
      } catch (errors) {
        console.log(errors);
        showAlert(errors, "error", 1500);
      } finally {
        setFullLoader(false);
      }
    };

  const handleSelectClick = () => {
    const isLevelSelectedForAllSkills = skillListCopy?.some((item) => item?.checked === true && (item?.proficiencyId === undefined || item?.proficiencyId === ""));
    if(isLevelSelectedForAllSkills) showAlert(text.skill.pleaseSelectLevelsForSelectedSkills, "info", 1500);
    else{
      const selectedSkills = [...skillListCopy].filter((skill) => !!skill?.checked && !!skill?.proficiencyId)
      AssignSkillsStore.update((s) => {
        s.selectedSearchedSkills = [...selectedSearchedSkills, ...selectedSkills];
      });
      handleSearchButtonClick();
    }
  };

  const levelKey =
    skillType === "persona" ? "recommended_proficiency" : "level";

  const levelChange = (val, item, reason) => {
    let updatedSkill = {
      [levelKey]: !!val ? val.level : "",
      levelId: !!val ? val.levelId : "",
      levelNumber: !!val ? val.levelNumber : "",
      proficiencyId: !!val ? val?.proficiencyId : "",
    };  

    const updetedSkillList = [...skillList].map((skill) =>
      skill.skillId === item.skillId
        ? { ...skill, ...updatedSkill, updated: true, target_proficiency: !!val ? val.level : ""  }
        : { ...skill }
    );

    const updatedSkillListCopy = [...skillListCopy].map((skill) =>
      skill.skillId === item.skillId
          ? { ...skill, ...updatedSkill, updated: true, target_proficiency: !!val ? val.level : ""  }
          : { ...skill }
    );

    setLevelSelectedContainerDictionary(prevDictionary => ({
      ...prevDictionary,
      [item.skillId]: !val ? null : updatedSkill,
    }))

    setSkillList(updetedSkillList);
    setSkillListCopy(updatedSkillListCopy);
  };

  const handleSelectedSearchedSkillLevelChange = (val, item, reason) => {
    let updatedSkill = {
      [levelKey]: !!val ? val.level : "",
      levelId: !!val ? val.levelId : "",
      levelNumber: !!val ? val.levelNumber : "",
      proficiencyId: !!val ? val?.proficiencyId : "",
    };  

    const updatedSelectedSearchedSkills = [...selectedSearchedSkills].map((skill) =>
      skill.skillId === item.skillId
        ? { ...skill, ...updatedSkill, updated: true, target_proficiency: !!val ? val.level : ""  }
        : { ...skill }
    );

    AssignSkillsStore.update((s) => {
      s.selectedSearchedSkills = updatedSelectedSearchedSkills
    });
  };

  const triggerApi = (id) => SkillService.getSkillTypesArrayV3(id, "proficiencies");

  const updateSkillName = (name) => setSkillName(name);

  return {
    skillName,
    updateSkillName,
    handleSearchButtonClick,
    handleCategory,
    familyMenu,
    handleFamily,
    selectFamily,
    selectCategory,
    skillList,
    handleCheckBox,
    handleCheckAll,
    levelChange,
    triggerApi,
    skillListCopy,
    handleSelectClick,
    listTotal,
    handlePagination,
    rowCount,
    changeRowCount,
    sourceList,
    handleSource,
    selectSource,
    skillSelectedContainer,
    currPage,
    noSkillMessage,
    selectedSearchedSkills,
    handleSkillAdd,
    handleSelectedSearchedSkillCheckBox,
    handleSelectedSearchedSkillCheckAll,
    handleSelectedSearchedSkillLevelChange,
  }
}

export default useSearchSkills;