import React, { memo, useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "@reach/router";

import Layout from "../../Layout";
import { BodyContent, Container } from "../../Common/GridSystem";
import BackNav, { BackCheck } from "../../Common/BackNav";
import { H2 } from "../../Common/Typography";
import RadioGroupNormal from "../../Common/FormElements/RadioGroupNormal";
import PersonaCompare from "./PersonaCompare";
import IndustryRoleCompare from "./IndustryRoleCompare";
import { SortString } from "../../../helpers/SortString";
import { ComparePersonaStore, UIStore } from "../../../store/store_state";
import styled from "styled-components";
import COLORS from "../../../assets/colors";
import ModalPopup from "../../Common/ModalPopup";
import ConfirmationButton from "../../Common/ConfirmationButton";
import useRoles from "../../../Hooks/useRoles";
import { AuthService } from "../../../services/AuthService";
import useAlert from "../../../Hooks/useAlert";
import { RoleServices } from "../../../services/RoleServices";
import { getOrDefault } from "../../../helpers/CommonHelper";
import useFullLoader from "../../../Hooks/useFullLoader";

export default (props) => (
  <Layout showContent={true}>
    {/* <MasterIndustryListProvider> */}
    <ComparePersonaIndustryRole {...props} />
    {/* </MasterIndustryListProvider> */}
  </Layout>
);

export const ComparePersonaIndustryRole = memo((props) => {
  const { personaId, roleId } = props;
  const { showAlert } = useAlert();
  // console.log("ComparePersonaIndustryRole -> props", props, roleId);

  const location = useLocation();
  const { state } = location;

  const { setFullLoader } = useFullLoader();
  
  const isNavigatedFromRoleMatch = getOrDefault(state?.isNavigatedFromRoleMatch, false);

  const rolePath = props.path.split("/")[1] === "compare-role" || false;
  // const { industryRolesMasterList } = useIndustryRolesList();
  const { clientName } = useRoles();
  // console.log(
  //   "ComparePersonaIndustryRole -> clientName",
  //   props,
  //   clientName,
  //   personaId,
  //   roleId
  // );
  const [personaList, setPersonaList] = useState(null);
  const [groupList, setGroupList] = useState([]);

  const personaUpdate = UIStore.useState((s) => s.personaUpdate);
  const copmareOptions = [
    { label: "Compare Personas", value: "persona" },
    { label: "Compare Industry Roles", value: "role" },
  ];
  const [compareValue, setCompareValue] = useState(
    rolePath ? copmareOptions[1].value : copmareOptions[0].value
  );
  const [stickyTopHeader, setStickyTopHeader] = useState(false);

  const [confirmPopup, setConfirmPopup] = useState(false);
  const [confirmTitle, setConfirmTitle] = useState("");
  const [hasNavigate, setHasNavigate] = useState(false);
  const navigate = useNavigate();

  const refDiv = useRef();

  const getImportPersonaDetails = async () => {
    setFullLoader(true);
    try{
      const response = await RoleServices.getImportPersonaDetails(clientName);
      ComparePersonaStore.update((s) => {s.importedPersonaDetails = response});
      setFullLoader(false);
    } catch (error){
      showAlert(error, 'error', 2000);
      setFullLoader(false);
    }
  }

  useEffect(() => {
    // console.log(props.path);
    if (!rolePath && !!clientName) {
      !isNavigatedFromRoleMatch ? getPersonasList() : getImportPersonaDetails();
    }
    return () => {
      // window.location.reload();
    };
  }, [props.location, clientName]);

  useEffect(() => {
    if (!!personaId && !!roleId) {
      UIStore.update((s) => {
        s.selectPersonas = [Number(personaId), "", "", ""];
        s.selectRoles = [Number(roleId), "", "", ""];
      });
    } else {
      UIStore.update((s) => {
        s.selectPersonas = ["", "", "", ""];
        s.selectRoles = ["", "", "", ""];
      });
    }
    setStickyTopHeader(false);
    return () => {
      UIStore.update((s) => {
        s.selectPersonas = [];
        s.selectRoles = [];
      });
    };
  }, [compareValue]);

  // useLayoutEffect(() => {
  //   window.addEventListener("scroll", handleScroll, true);

  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, []);

  // const handleScroll = () =>
  //   !!refDiv.current &&
  //   setStickyTopHeader(refDiv.current.getBoundingClientRect().top + 12 < 10);

  const getPersonasList = async () => {
    // setPersonasList([]);
    // setFullLoader(true);
    try {
      const resList = await AuthService.getPersonasListV3(
        `?clientId=${clientName}`
      );
      if(!!resList && Array.isArray(resList)){
        setPersonaList(SortString(
          resList?.map((el) => ({
            ...el,
            personaGroupName: el.personaGroup.personaGroupName,
          })),
          "personaGroupName"
        ))
      };
      const grp = await AuthService.getPersonaGroupV3(`/${clientName}`);
        const grpSorted = [
          ...SortString(grp, "personaGroupName").map(
            ({ personaGroupName, personaGroupId }) => ({
              label: personaGroupName,
              value: personaGroupId,
            })
          )
        ];
        grpSorted.unshift({
          label: "All",
          value: -1,
        });
        setGroupList(grpSorted);
    } catch (error) {
      showAlert(error.error, "error", 1500);
      console.log(error);
    } finally {
      // setFullLoader(false);
    }
  };

  const handleCheckChange = () =>
    openConfirmBox(false, true, "Proceed without saving changes?");

  const handleCheckBack = () =>
    openConfirmBox(true, true, "Proceed without saving changes?");

  const openConfirmBox = (navi, confirm, text) => {
    setHasNavigate(navi);
    setConfirmPopup(confirm);
    setConfirmTitle(text);
  };

  const handleYes = () => {
    closePopup();
    if (hasNavigate) {
      navigate(-1);
    } else {
      setCompareValue("role");
      UIStore.update((s) => {
        s.personaUpdate = false;
      });
    }
  };

  const closePopup = () => {
    setConfirmPopup(false);
    setHasNavigate(false);
    setConfirmTitle("");
  };

  // console.log(personaUpdate);
  // console.log(
  //   industryRolesMasterList,
  //   roleId,
  //   industryRolesMasterList
  //     .map(({ roleId }) => roleId)
  //     ?.includes(Number(roleId)),
  //   industryRolesMasterList.find((el) => el.roleId === Number(roleId))
  // );
  return (
    <>
      <H2>
        Compare {compareValue === "persona" ? "Personas" : "Industry Roles"}
      </H2>
      <BodyContent>
        <Container>
          <TopChangeButton>
            {personaUpdate ? (
              <BackCheck handleCheckBack={handleCheckBack} />
            ) : (
              <BackNav />
            )}
            {!!props.personaId && (
              <RadioGroupNormal
                name="nav-option"
                options={copmareOptions}
                radioVal={compareValue}
                radioGroupChange={(val) =>
                  personaUpdate ? handleCheckChange() : setCompareValue(val)
                }
                labelTitle=""
              />
            )}
          </TopChangeButton>
          {compareValue === "persona" && (!!personaList || isNavigatedFromRoleMatch) ? (
            <PersonaCompare
              {...props}
              initValue={
                !!props.personaId && !isNavigatedFromRoleMatch
                  ? personaList?.find(
                      (el) => el.personaId === Number(personaId)
                    )
                  : ""
              }
              list={
                !isNavigatedFromRoleMatch
                  ? SortString(
                  personaList?.map((el) => ({
                    label: el.personaName,
                    value: el.personaId,
                    groupName: el.personaGroup.personaGroupName,
                  })),
                  "groupName"
                )
                : []
              }
              masterList={!isNavigatedFromRoleMatch ? personaList : []}
              stickyVal={stickyTopHeader}
              inputWidth={
                !!refDiv.current && refDiv.current.getBoundingClientRect().width
              }
              departmentList={!isNavigatedFromRoleMatch ? groupList : []}
            />
          ) : null}
          {compareValue === "role" ? (
            <IndustryRoleCompare
              {...props}
              //compareValue === "role" && !!industryRolesMasterList.length
              // initValue={industryRolesMasterList.find(
              //   (el) => el.roleId === Number(roleId)
              // )}
              initValue={Number(roleId)}
              // list={SortString(
              //   industryRolesMasterList.map((el) => ({
              //     label: el.roleName,
              //     value: el.roleId,
              //   })),
              //   "roleName"
              // )}
              // masterList={industryRolesMasterList}
              stickyVal={stickyTopHeader}
              inputWidth={
                !!refDiv.current && refDiv.current.getBoundingClientRect().width
              }
            />
          ) : null}
        </Container>
      </BodyContent>
      <ModalPopup
        open={confirmPopup}
        maxWidth="sm"
        title={confirmTitle}
        center={true}
      >
        <ConfirmationButton yesClick={handleYes} noClick={closePopup} />
      </ModalPopup>
    </>
  );
});

const TopChangeButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
  & > div {
    margin: 0px;
  }
  .MuiFormControl-root {
    background-color: ${COLORS.PRIMARY_WHITE};
    box-shadow: 0 3px 5px 0 rgba(97, 122, 163, 0.24);
    border-radius: 25px;
    padding: 5px 15px;
  }
`;
