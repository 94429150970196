import Cookies from "js-cookie";

const storeKeys = {
  user: {
    userDetails: "user-details",
  },
  token: "token",
  clientName: "clientName",
  clientList: "clientList",
  exportToDisprzStatusData: "exportToDisprzStatusData",
};

function cookieStorageHandler(key, value, method) {
  switch (method) {
    case "save":
      Cookies.set(key, value);
      break;
    case "get":
      return Cookies.get(key);
    case "remove":
      Cookies.remove(key);
      break;
    default:
      break;
  }
}

const User = {
  setUserDetails(details) {
    cookieStorageHandler(storeKeys.user.userDetails, details, "save");
  },
  getUserDetails() {
    const details = cookieStorageHandler(
      storeKeys.user.userDetails,
      null,
      "get"
    );
    return details ? JSON.parse(details) : null;
  },
  clearUserDetails() {
    cookieStorageHandler(storeKeys.user.userDetails, null, "remove");
  },
  clearAll() {
    Object.values(storeKeys.user).forEach((value) => {
      cookieStorageHandler(value, null, "remove");
    });
  },
};

const Common = {
  setToken(token) {
    cookieStorageHandler(storeKeys.token, token, "save");
  },
  setClientName(clientName) {
    cookieStorageHandler(storeKeys.clientName, clientName, "save");
  },
  setClientList(clientList) {
    cookieStorageHandler(storeKeys.clientList, clientList, "save");
  },
  getToken() {
    const token = cookieStorageHandler(storeKeys.token, null, "get");
    return token ? token : null;
  },
  getClientName() {
    const clientName = cookieStorageHandler(storeKeys.clientName, null, "get");
    return clientName ? clientName : null;
  },
  getClientList() {
    const clientList = cookieStorageHandler(storeKeys.clientList, null, "get");
    return clientList ? clientList : null;
  },
  setExportData(exportData) {
    cookieStorageHandler(
      storeKeys.exportToDisprzStatusData,
      exportData,
      "save"
    );
  },
  getExportData() {
    const exportToDisprzStatus = cookieStorageHandler(
      storeKeys.exportToDisprzStatusData,
      null,
      "get"
    );
    return exportToDisprzStatus ? exportToDisprzStatus : null;
  },
  clearToken() {
    cookieStorageHandler(storeKeys.token, null, "remove");
  },
  clearAll() {
    Object.values(storeKeys).forEach((value) => {
      cookieStorageHandler(value, null, "remove");
    });
    Object.values(storeKeys.user).forEach((value) => {
      cookieStorageHandler(value, null, "remove");
    });
  },
};

export { User, Common };
