import React from "react";
import { H6 } from "../Common/Typography";
import styled from "styled-components";
import COLORS from "../../assets/colors";

export default ({ list, keyName, title = "Suggestions", handleListClick }) => {
  return (
    <Suggestions>
      <H6>{title}</H6>
      <ul>
        {list.map((el, i) => (
          <li
            key={i}
            // style={{ backgroundColor: ListOfColors[i] }}
            onClick={() => handleListClick(el)}>
            {!!keyName ? el[keyName] : el}
          </li>
        ))}
      </ul>
    </Suggestions>
  );
};

const Suggestions = styled.div`
  margin-top: 5px;
  ul {
    margin: -5px;
    li {
      border-radius: 28px;
      background-color: #d6e7ff;
      color: ${COLORS.TITLE_BLUE};
      cursor: pointer;
      display: inline-flex;
      font-size: 12px;
      font-weight: 400;
      margin: 5px;
      padding: 5px 15px;
      &:nth-child(2n) {
        background-color: #ffeff3;
      }
      &:nth-child(3n) {
        background-color: #faebc5;
      }
      &:nth-child(4n) {
        background-color: #d5efb8;
      }
      &:nth-child(5n) {
        background-color: #c5fafa;
      }
    }
  }
`;
