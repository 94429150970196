import React from 'react';
import { H4 } from '../../../Common/Typography';
import Each from '../../../Common/Each';
import FileUploader from '../../../Common/FileUploader/FileUploader';
import ModalPopup from '../../../Common/ModalPopup';
import { FileFormats } from '../../../../enums/FileFormats';
import ConfirmationButton from '../../../Common/ConfirmationButton';
import useBulkPersonaCreationModal from './useBulkPersonaCreationModal';
import text from "../../../../locale.en.json";
import "./BulkPersonaCreationModal.scss";
import { SecondaryButton } from '../../../Common/Buttons/SecondaryButton';
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ToolBar from '../../../Common/ToolBar';
import { ToolBarPlacement } from '../../../../enums/ToolBarPlacement';
import DownloadFile from '../../../../helpers/DownloadFile';

function BulkPersonaCreationModal({ open, close, handleBulkImportConfirm, error, setError, templateUrl }) {
    const { bulkPersonaCards, showDragAndDropFileModal, selectedFiles, actionHandlers, handleClosePopUp, handleYesClick, fileUrl, fileName } = useBulkPersonaCreationModal({close, setError, handleBulkImportConfirm, templateUrl});

    const BulkPersonaContainerCard = ({imageSrc, message, disableCard = false, action }) => {
        return (
            <div className={`bulk-persona-container-card ${disableCard && "hrms-card"}`} onClick={() => actionHandlers[action]()}>
                <img src={imageSrc} />
                <H4>{message}</H4>
                {disableCard && 
                <button className="hrms-card-btn">{text.common.comingSoon}</button>}
            </div>
        );
    };
    const renderProceedText = () => (
        <>
            {text.common.proceed}
            {showDragAndDropFileModal.showInfoIcon && (
                <ToolBar title={text.persona.bulkJDImportInfo} arrow darkMode placement={ToolBarPlacement.TOP}>
                    <InfoOutlinedIcon className='info-icon' style={{paddingLeft: 5}} />
                </ToolBar>
            )}
        </>
    );
    const renderConfirmationButton = () => {
        return <ConfirmationButton
        noText={text.common.cancel}
        yesText={renderProceedText()}
        noClick={handleClosePopUp}
        yesClick={handleYesClick}
        disableYesClick={!selectedFiles.length}
    />
    }
    const BulkPersonaContainer = () => {
        return (
            <div className="create-bulk-persona-container">
                <H4>{text.fileSelection.uploadPrompt}</H4>
                <div className="persona-cards-container">
                    <Each
                        items={bulkPersonaCards}
                        render={(card, index) => (
                            <BulkPersonaContainerCard
                                key={index}
                                imageSrc={card.imageSrc}
                                message={card.message}
                                action={card.action}
                                disableCard={card.disableCard}
                            />
                        )}
                    />
                </div>
                {showDragAndDropFileModal.showZipFileUploader &&
                    <FileUploader
                        allowedFileTypes={[FileFormats.ZIP, FileFormats.RAR]}
                        isJdFileUpload={true}
                        multiSelect={false}
                        maxFileSize={"25 mb"}
                        error={error}
                        setError={setError}
                    />
                }
                {(showDragAndDropFileModal.showRoleSkillsFileUploader || showDragAndDropFileModal.showClientSkillsFileUploader) &&
                    <div className='skills-uploader-container'>
                        <div className='download-template-container'>
                            <SecondaryButton onClick={() => {DownloadFile(fileUrl, fileName)}}>
                                {text.common.downloadTemplate}
                            </SecondaryButton>
                            <ToolBar title={text.fileSelection.templateInstruction} arrow darkMode placement={ToolBarPlacement.BOTTOM}>
                                <InfoOutlinedIcon className='info-icon'/>
                            </ToolBar>
                        </div>
                        <FileUploader
                            allowedFileTypes={[FileFormats.XLSX]}
                            multiSelect={false}
                            // maxFileSize={"25 mb"}
                            error={error}
                            setError={setError}
                        />
                    </div>
                }
            </div>
        );
    };


    return (
        <ModalPopup
            open={open}
            title={'Create Personas in Bulk'}
            center={true}
            closePopup={handleClosePopUp}
            customStyle={{maxHeight: "325px"}}
            actions={renderConfirmationButton()}
        >
            <BulkPersonaContainer />
        </ModalPopup>
    );   
}

export default BulkPersonaCreationModal;