import React from "react";
import styled from "styled-components";
import { H5 } from "./Typography";

export default ({ src, title }) => {
  return (
    <TitleIcon>
      <img src={src} alt="roles" />
      <H5>{title}</H5>
    </TitleIcon>
  );
};

const TitleIcon = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  img {
    margin-right: 10px;
  }
  h5 {
    padding-bottom: 0px;
  }
`;
