import React, { useEffect, useState } from "react";
import styled from "styled-components";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";

import AutoFilterLocal from "../../../Common/FormElements/AutoFilterLocal";
import { H4, H5, H6 } from "../../../Common/Typography";
import { UIStore, ComparePersonaStore, SelectedRoleSkillsStore } from "../../../../store/store_state";
import PopHover from "../../../Common/PopModal/PopHover";
import ModalPopup from "../../../Common/ModalPopup";
import CompareColumn from "./CompareColumn";
import useAlert from "../../../../Hooks/useAlert";
import COLORS from "../../../../assets/colors";
import ToolBar from "../../../Common/ToolBar";
import { useLocation } from "@reach/router";
import { RoleServices } from "../../../../services/RoleServices";
import { getOrDefault } from "../../../../helpers/CommonHelper";
import useRoles from "../../../../Hooks/useRoles";

let personaList = [];
export default ({
  initValue,
  list,
  masterList,
  stickyVal = false,
  inputWidth,
  departmentList = [],
}) => {
  const location = useLocation();
  const { state } = location;
  const { clientName } = useRoles();
  const isNavigatedFromRoleMatch = getOrDefault(state?.isNavigatedFromRoleMatch, false);
  const personasToBeCompared = getOrDefault(state?.personasToBeCompared, ['','','','']);

  const { showAlert } = useAlert();
  const dep = UIStore.useState((s) => s.department);
  const [inputDep, setInputDep] = useState('');
  const selectPersonas = UIStore.useState((s) => s.selectPersonas);
  const [detailPopup, setDetailPopup] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentEl, setCurrentEl] = useState(null);
  const [personaDetailArray, setPersonaDetailArray] = useState([
    "",
    "",
    "",
    "",
  ]);
  const [personaVal, setPersonaVal] = useState([
    !!initValue ? initValue?.personaName : "",
    "",
    "",
    "",
  ]);

  const [inputValue, setInputValue] = useState([
    !!initValue ? initValue?.personaName : "",
    "",
    "",
    "",
  ]);
  const userChoosenPersonas = ComparePersonaStore.useState((s) => s.userChoosenPersonas);
  const importedPersonaDetails  = ComparePersonaStore.useState((s) => s.importedPersonaDetails);
  const selectedRoleSkills = SelectedRoleSkillsStore.useState((s) => s.selectedRoleSkills);

  useEffect(() => {
    const arr = selectPersonas.map((el) =>
      masterList.find((elem) => el === elem.personaId)
    );
    setPersonaDetailArray(arr);
    // console.log(masterList, selectPersonas, arr);
  }, [selectPersonas]);

  useEffect(() => {
    if(dep === 'All'){
      personaList = list;
    }
    if((sessionStorage.getItem("department")) && sessionStorage.getItem("personasCompared")){
      UIStore.update((s) => {
        s.department = JSON.parse(sessionStorage.getItem("department"));
        s.selectPersonas = JSON.parse(sessionStorage.getItem("personasCompared"));
      })
      sessionStorage.clear();
    }
  },[])

  useEffect(() => {
    if(isNavigatedFromRoleMatch){
      setPersonaVal(personasToBeCompared);
      setInputValue(personasToBeCompared);
    }
  },[isNavigatedFromRoleMatch])

  const fetchDataForPersona = async (persona) => {
    const commonObj = {
      personaId: persona?.personaName || "",
      personaName: persona?.personaName || "",
      rolesResponsibilities: persona?.rolesAndResponsibilities || "",
    };
  
    try {
      const selectedRole = persona?.roleRecommendations?.find((r) => r.isSelected);
  
      if (!!selectedRole) {
        const selectedRoleDetail = await RoleServices.getRoleV3(`/${selectedRole.roleId}`);
        const selectedRoleSkillsDetails = await RoleServices.GetSkillsForRoleIdV3(selectedRole.roleId, false, clientName);
        SelectedRoleSkillsStore.update((s) => {
          s.selectedRoleSkills[persona?.personaName] = [...(selectedRoleSkills[persona?.personaName] ? selectedRoleSkills[persona?.personaName] : []), ...selectedRoleSkillsDetails]
        });
        let updatedRes = {
          ...commonObj,
          function: { functionId: selectedRoleDetail?.functionId, functionName: selectedRoleDetail?.functionName },
          leadershipRole: selectedRoleDetail?.leadershipRole,
          industryRole: { roleId: selectedRole?.roleId, roleName: selectedRole?.roleName },
          subsector: { subsectorId: selectedRoleDetail?.subsectorId, subsector: selectedRoleDetail?.subsector },
        };
        return { ...updatedRes };
      } else {
        return {
          ...commonObj,
          function: "",
          leadershipRole: "",
          industryRole: "",
          subsector: "",
        };
      }
    } catch (error) {
      console.error("Error fetching role details:", error);
      showAlert(error, "error", 2000);
      return { ...commonObj };
    }
  };
  
  useEffect(() => {
    if (!!importedPersonaDetails) {
      const updatedPersonasDetails = importedPersonaDetails?.filter((persona) =>
        personasToBeCompared.includes(persona.personaName)
      );
  
      const fetchRoleDetails = async () => {
        const tempPersonaDetailArrays = [];
  
        for (const persona of updatedPersonasDetails) {
          const personaDetail = await fetchDataForPersona(persona);
          tempPersonaDetailArrays.push(personaDetail);
        }
  
        while (tempPersonaDetailArrays.length < 4) {
          tempPersonaDetailArrays.push("");
        }
  
        setPersonaDetailArray(tempPersonaDetailArrays);
      };
  
      fetchRoleDetails();
    }
  }, [importedPersonaDetails]);
  

  const handleSearch = (val, reason, index) => {
    console.log("handleSearch -> val, reason", val, reason);
    let arr = [...personaVal];
    let arrPersonaIds = [...selectPersonas];

    // let el = document.getElementById("combo-box-demo");
    // el.addEventListener("focus", (event) => console.log(event.target));

    if (reason === "select-option") {
      if (selectPersonas?.includes(val.value)) {
        showAlert("Persona is already selected", "info", 1500);
        const findIndexValue = selectPersonas?.findIndex(
          (el) => el === val.value
        );
        if (findIndexValue !== index) {
          arr.splice(index, 1, "");
          arrPersonaIds.splice(index, 1, "");

          setPersonaVal(arr);
          setInputValue(arr);
          UIStore.update((s) => {
            s.selectPersonas = arrPersonaIds;
          });
        }
      } else {
        arr.splice(index, 1, val.label);
        setPersonaVal(arr);
        arrPersonaIds.splice(index, 1, val.value);
        UIStore.update((s) => {
          s.selectPersonas = arrPersonaIds;
        });
      }
    } else if (reason === "clear") {
      arr.splice(index, 1, "");
      arrPersonaIds.splice(index, 1, "");

      setPersonaVal(arr);
      setInputValue(arr);
      UIStore.update((s) => {
        s.selectPersonas = arrPersonaIds;
      });
    }
  };
  const handleInputChange = (val, index) => {
    const arr = [...inputValue];
    let arrPersonaVal = [...personaVal];
    let arrPersonaIds = [...selectPersonas];

    if (!!arr.includes(val)) {
      // val && setInputValue(arr);
      arr.splice(index, 1, "");
      setInputValue(arr);
    } else {
      // const arr = [...inputValue];
      arr.splice(index, 1, val);
      setInputValue(arr);

      arrPersonaVal.splice(index, 1, val);
      setPersonaVal(arrPersonaVal);
    }
  };

  const handleView = (i) => {
    setCurrentEl(i);
    setDetailPopup(true);
  };
  // console.log(selectPersonas);
  // console.log(
  //   personaVal,
  //   personaDetailArray,
  //   personaDetailArray[currentEl],
  //   currentEl
  // );

  const handleDepartmentSearch = async (value, reason) => {
    if(dep === value?.label){
      return;
    }
    UIStore.update((s) => {
      s.department = value?.label || '';
      s.selectPersonas = ['','','',''];
    })
    setInputDep(value?.label || '');
    setPersonaVal(["","","",""]);
    setInputValue(["","","",""]);
    setPersonaDetailArray(["","","",""])
    personaList=[];
     const label = !!value && departmentList?.find((el) => el.value === value?.value)?.label
    if (!!label && value?.value !== -1) {
      personaList = list.filter(element => element.groupName === label);
    } else if(value?.value === -1 || value?.value === ''){
      personaList = list;
    }
  }

  useEffect(() => {
     if(userChoosenPersonas?.length > 0){
        UIStore.update((s) => {s.selectPersonas = userChoosenPersonas});
     }
  },[userChoosenPersonas])

  return (
    <>
        {!isNavigatedFromRoleMatch &&
          <AutoFilterLocal
          list={departmentList}
          filterLabel="label"
          label="Department"
          style={{ padding: "0px 0px 15px 0px", width: "300px", marginBottom: "30px" }}
          placeholder={!!dep ? dep : "Select Department"}
          optionChange={(val, reason) => handleDepartmentSearch(val, reason)}
          value={dep}
          inputVal={inputDep}
          inputBoxChange={(val) => setInputDep(val)}
          groupLabel={""}
          disabled={isNavigatedFromRoleMatch}
        />}
      <ComapareHeader
        sticky={stickyVal}
        inputWidth={inputWidth}
        // style={{ position: stickyVal ? "fixed" : "relative" }}
      >
        <CompareColumn>
          <div style={{ paddingTop: 20 }}>
            <H4>Persona Name</H4>
            <H6>Matching Industry Role</H6>
          </div>
          {personaDetailArray.map((el, i) => (
            <div key={i}>
              {!!el ? (
                <AutoFilterLocal
                  list={personaList}
                  filterLabel="label"
                  label={""}
                  style={{ padding: "0px 0px 15px 0px", width: "100%" }}
                  optionChange={(val, reason) => handleSearch(val, reason, i)}
                  // value={el?.personaName}
                  inputVal={el?.personaName || inputValue}
                  inputBoxChange={(val) => handleInputChange(val, i)}
                  groupLabel="groupName"
                  placeholder={el?.personaName}
                  // valueCarry
                  focusCheckDisabled
                  disabled={isNavigatedFromRoleMatch}
                />
              ) : (
                <AutoFilterLocal
                  list={personaList}
                  filterLabel="label"
                  label={""}
                  style={{ padding: "0px 0px 15px 0px", width: "100%" }}
                  optionChange={(val, reason) => handleSearch(val, reason, i)}
                  value={personaVal[i]}
                  selectedList={selectPersonas}
                  inputVal={inputValue[i]}
                  inputBoxChange={(val) => handleInputChange(val, i)}
                  groupLabel="groupName"
                  placeholder="Select Persona"
                  disabled={isNavigatedFromRoleMatch}
                  // valueCarry
                />
              )}

              {/* <AutoFilterLocal
                list={list}
                filterLabel="label"
                label={""}
                style={{ padding: "0px 0px 15px 0px", width: "100%" }}
                optionChange={(val, reason) => handleSearch(val, reason, i)}
                // value={el?.personaName}
                inputVal={inputValue[i]}
                inputBoxChange={(val) => handleInputChange(val, i)}
                groupLabel="groupName"
                placeholder={!!inputValue[i] ? inputValue[i] : "Select Persona"}
                valueCarry={!!inputValue[i] && i === 0}
              /> */}

              <div className="info">
                {el?.industryRole?.roleName}{" "}
                {!!el && !!el?.industryRole?.roleName && (
                  <InfoOutlinedIcon
                    style={{ color: "#fff" }}
                    aria-owns={!!anchorEl ? "simple-popover" : undefined}
                    aria-haspopup="true"
                    onMouseEnter={(e) => {
                      setAnchorEl(e.currentTarget);
                      setCurrentEl(i);
                    }}
                    onMouseLeave={() => {
                      setAnchorEl(null);
                      setCurrentEl(null);
                    }}
                  />
                )}
              </div>

              <div className="compare-label">
                Persona {i + 1}
                {!!el && el !== "" && !!el.personaId && !isNavigatedFromRoleMatch ? (
                  <div className="compare-reset">
                    <ToolBar title="Reset">
                      <RotateLeftIcon
                        onClick={() => handleSearch("", "clear", i)}
                      />
                    </ToolBar>
                  </div>
                ) : (
                  <div key={i}>&nbsp;</div>
                )}
              </div>
            </div>
          ))}
        </CompareColumn>

        {!!detailPopup && (
          <ModalPopup
            open={detailPopup}
            closePopup={() => {
              setDetailPopup(false);
              setCurrentEl(null);
            }}
            maxWidth="md"
            title="Roles & Responsibilities as per Company"
            center={true}
            className="detailPopup"
          >
            <div
              className="line-text"
              dangerouslySetInnerHTML={{
                __html: personaDetailArray[
                  currentEl
                ].rolesResponsibilities.replace(/\|/g, "<br /><br />"),
              }}
            />
          </ModalPopup>
        )}
        {
          <PopHover anchorEl={anchorEl} hoverType={true}>
            <IndustryInfo>
              {currentEl !== null && (
                <H5>
                  {personaDetailArray[currentEl].subsector?.subsectorTypeId ===
                  1
                    ? "Industry"
                    : "Function"}
                </H5>
              )}
              {currentEl !== null && (
                <small>
                  {personaDetailArray[currentEl].subsector?.subsector}
                </small>
              )}
            </IndustryInfo>
            <IndustryInfo>
              {currentEl !== null && (
                <H5>
                  {personaDetailArray[currentEl].subsector?.subsectorTypeId ===
                  1
                    ? "Department"
                    : "Track"}
                </H5>
              )}
              {currentEl !== null && (
                <small>
                  {personaDetailArray[currentEl].function?.functionName}
                </small>
              )}
            </IndustryInfo>
            <IndustryInfo>
              <H5>Industry Role</H5>
              {currentEl !== null && (
                <small>
                  {personaDetailArray[currentEl].industryRole?.roleName}
                </small>
              )}
            </IndustryInfo>
            <IndustryInfo>
              <H5>Leadership Role</H5>
              {currentEl !== null && (
                <small>
                  {
                    personaDetailArray[currentEl].leadershipRole
                      .leadershipRoleName
                  }
                </small>
              )}
            </IndustryInfo>
          </PopHover>
        }
      </ComapareHeader>
      <CompareColumn style={{ backgroundColor: "white" }}>
        <div style={{ marginLeft: 28 }}></div>
        {personaDetailArray.map((el, i) => (
          <div key={i} className="detail-col">
            {!!el && (
              <H6 onClick={() => handleView(i)} key={i}>
                View Roles & Responsibilities
              </H6>
            )}
          </div>
        ))}
      </CompareColumn>
    </>
  );
};
const ComapareHeader = styled.div`
  box-shadow: 0 1px 3px 0 rgba(97, 122, 163, 0.24);
  background-color: ${COLORS.DARK_BLUE_HEADER};
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  color: ${COLORS.PRIMARY_WHITE};
  padding: 30px 28px 0px;
  position: sticky;
  top: 16px;
  z-index: 1;
  &:before {
    background-color: ${COLORS.BODY_ASH};
    content: "";
    height: 16px;
    width: 100%;
    position: absolute;
    left: 0;
    top: -16px;
  }
  h4,
  h6,
  h5 {
    color: ${COLORS.PRIMARY_WHITE};
  }
  h5 {
    font-weight: normal;
  }
`;

const IndustryInfo = styled.div`
  display: flex;
  padding: 15px;
  width: 300px;
  &:last-child {
    padding-bottom: 15px;
  }
  h5,
  small {
    line-height: 18px;
    padding-bottom: 0px;
    width: 50%;
    /* word-break: break-all; */
  }
`;

const OptionCutom = styled.div`
  font-size: 12px;
`;
