import React, { useState, useEffect, createRef } from "react";

import Layout from "../../Layout";
import { H2, H4, H5 } from "../../Common/Typography";
import { BodyContent, Container } from "../../Common/GridSystem";
import styled from "styled-components";
import COLORS from "../../../assets/colors";
import BackNav from "../../Common/BackNav";
import { PrimaryButton } from "../../Common/Buttons/PrimaryButton";
import { AddCancelButton } from "../../Common/Buttons/ButtonIcon";

import { Formik, Form } from "formik";
import FormTextField from "../../Common/FormElements/FormTextField";
import { skillManageMentValidation } from "../../../validations/personaValidation";
import { SkillService } from "../../../services/SkillService";
import DropDown from "../../Common/FormElements/DropDown";
import AIGenerator from "../../Common/AIGenerator";
import SkillsManageTable from "./SkillsManageTable";
import { SecondaryButton } from "../../Common/Buttons/SecondaryButton";
import useAlert from "../../../Hooks/useAlert";
import useFullLoader from "../../../Hooks/useFullLoader";
import { navigate } from "@reach/router";
import InputChip from "../../Common/FormElements/InputChip";
import ConfirmationButton from "../../Common/ConfirmationButton";
import ModalPopup from "../../Common/ModalPopup";
import { Images } from "../../../assets/images";
import { SkillStatusMenu, WikiLink } from "../../utils";
import AutoSelect from "../../Common/FormElements/AutoSelect";
import SkillTree from "../../SkillTree/SkillTree";
import CreateSkillTree from "./CreateSkillTree";
import CreateSkillTreeTest from "./CreateSkillTreeTest";
import useAuth from "../../../Hooks/useAuth";
import { SortString } from "../../../helpers/SortString";
import ChipList from "../../Common/Others/ChipList";
import AutoSelectSearch from "../../Common/FormElements/AutoSelectSearch";
import DropDownState from "../../Common/FormElements/DropDownState";
import Label from "../../Common/FormElements/Label";
import { useSkillTableData } from "../../../Provider/SkillTableProvider";
import ToolBar from "../../Common/ToolBar";
import { UserType } from "../../../enums/UserType";
import { navigateToTagURL,  showAiGeneratorModal, getGeneratorType, getAiGeneratorTitle, getServiceUrl, getConfirmText, } from "../../../helpers/CommonHelper";
import { skillGeneratorMapping } from "../../../helpers/GeneratorMappingHelper";
import { AIStore, SkillStore } from "../../../store/store_state";
import { SourceType } from "../../../enums/SourceType";
import { renderAIButton } from "../../Common/Buttons/RenderAiButton";

export default (props) => {
  // console.log(props);
  const { mode, id } = props;
  const { showAlert } = useAlert();
  const { skillstatusMenuList, userDetails } = useAuth();
  const { User_Type } = userDetails;
  const { setFullLoader } = useFullLoader();

  let formRef = createRef();

  const [encyclopediaSize, setEncyclopediaSize] = useState(0);
  const [encyclopediaState, setEncyclopediaState] = useState("");
  const [encyclopediaTypeState, setEncyclopediaTypeState] = useState(1);
  const [encycloCurrentList, setEncycloCurrentList] = useState([]);
  const aiSuggestedEncycloLists = AIStore.useState((s) => s.aiSuggestedEncycloLists);
  const skillDetail = AIStore.useState((s) => s.skillDetail);

  const [detailData, setDetailData] = useState(null);
  const [categoryMenu, setCategoryMenu] = useState([]);
  const [familyMenu, setFamilyMenu] = useState([]);
  const [sourceMenu, setSourceMenu] = useState([]);
  const [encyclopediaTypes, setEncyclopediaTypes] = useState([]);

  const [confirmPopup, setConfirmPopup] = useState(false);
  const [confirmType, setConfirmType] = useState(null);
  const [confirmTitle, setConfirmTitle] = useState("");
  const [fieldValues, setFieldValues] = useState(null);
  const [skillTreeModal, setSkillTreeModal] = useState(false);
  const [showDetailedDescription, setShowDetailedDescription] = useState(false);
  const [treeData, setTreeData] = useState(null);

  const checkSkillNameExist = async (name) => {
    var response = await SkillService.checkSkillExistsV3(name, Number(id))
    if (response.value)
      return "This skill already exists";
    return "";
  }
  // console.log(skillstatusMenuList);

  const initialStateForAiGeneratorPopUp = {
    showKnowledgeBasePopUp: false,
    showSkillShortDescriptionPopUp: false,
    showSkillDetailedDescriptionsPopUp: false
  }
  const [showAiGeneratorPopUp, setShowAiGeneratorPopUp] = useState(initialStateForAiGeneratorPopUp);
  const skillSourceId = SkillStore.useState((s) => s.sourceId);

  const [showBox, setShowBox] = useState(false);
  const chipAdd = () => setShowBox((prev) => !prev);

  const getListData = async () => {
    setFullLoader(true);
    try {
      const resCat = await SkillService.getSkillCategoriesV3();
      const categorySorted = SortString(resCat, "skillCategory").map((el) => ({
        label: el.skillCategory,
        value: el.skillCategoryId,
      }));
      setCategoryMenu(categorySorted);

      if (mode === "add") {
        changeFamilyMenu(categorySorted[0]?.value);
      }

      const resSrc = await SkillService.getSkillSourcesV3();
      setSourceMenu(
        SortString(resSrc, "source").map((el) => ({
          label: el.source,
          value: el.sourceId,
        }))
      );
      // setSourceMenu({label: "Disprz Atomic Skills Model", value: SourceType.DISPRZ_ATOMIC_SKILLS})

      const resGetEncyclopediaTypes = await SkillService.GetEncyclopediaTypesV3();
      setEncyclopediaTypes(
        SortString(resGetEncyclopediaTypes, "encyclopediaType").map((el) => ({
          label: el.encyclopediaType,
          value: el.encyclopediaTypeId,
        }))
      );
    } catch (err) {
      console.log(err);
    } finally {
      setFullLoader(false);
    }
  };

  const resetAiSkillStore = () => {
    AIStore.update((s) => {
     s.aiSuggestedEncycloLists = [];
     s.aiSuggestedSkillShortDescriptions =  [];
     s.aiSuggestedSkillDetailedDescriptions =  [];
     s.aiSuggestedProficienyDescription = [];
     s.aiSuggestedKnowledgeDescription = [];
      s.aiSuggestedlevelDescriptionCopy = {
        aiSuggestedProficienyDescription: [],
        aiSuggestedKnowledgeDescription: []
      };
    })
   }

  const getDetailData = async (arrangeFamilyMenu = false) => {
    try {
      const res = await SkillService.getSkillDetailV3(
        "/" + Number(id),
        "?includeProficiencies=true"
      );
      // setKnowledgeBase("test1 | test2");

      setDetailData(res);
      SkillStore.update((s) => {s.sourceId = mode === "add" ? SourceType.DISPRZ_ATOMIC_SKILLS  : res?.skillSource?.sourceId});
      let newskillDetail = {skillName: res.skillName, skillFamily: res.skillFamily.skillFamily}
      if(JSON.stringify(skillDetail) !== JSON.stringify(newskillDetail)){
         AIStore.update((s) => {
            s.skillDetail = newskillDetail;
            // s.aiSuggestedEncycloLists = [];
            // s.aiSuggestedSkillShortDescriptions = [];
            // s.aiSuggestedSkillDetailedDescriptions = [];
            // s.aiSuggestedProficienyDescription = [];
            // s.aiSuggestedKnowledgeDescription = []
         })
         resetAiSkillStore();
      };
      setEncycloCurrentList(res.encyclopedia);
      if(JSON.stringify(skillDetail) === JSON.stringify(newskillDetail)){
        let newEncyclopedia = [...res.encyclopedia];
        console.log(147, newEncyclopedia, aiSuggestedEncycloLists, newskillDetail, skillDetail)
        let currAiSuggestedEncyclopedia = [...aiSuggestedEncycloLists];
        currAiSuggestedEncyclopedia = currAiSuggestedEncyclopedia?.map((item) => {
          if(newEncyclopedia.every((el) => el.encyclopediaId !== item.encyclopediaId)){
            const { isSelected, isDisabled, ...newItem } = item;
            return { ...item, isSelected: false, isDisabled: false };
          }
          return item
        })
        AIStore.update((s) => {s.aiSuggestedEncycloLists = [...currAiSuggestedEncyclopedia]});
      }
      if (arrangeFamilyMenu) {
        changeFamilyMenu(res?.skillCategory?.skillCategoryId);
      }
      // setKnowledgeBase(!!res.wikipedia ? res.wikipedia : "");
    } catch (error) {
      showAlert(error.error, "error", 1500);
      // console.log(errors);
    }
  };

  useEffect(() => {
    if (!!skillstatusMenuList?.length && !!detailData) {
      const checkStatus = skillstatusMenuList.some(
        (status) => status.value === detailData.skillStatus
      );
      if (!checkStatus) {
        showInfoAndNavigate();
      }
    }
  }, [skillstatusMenuList, detailData]);

  const showInfoAndNavigate = async () => {
    await showAlert("User doesn't have access to this page", "Info", 1500);
    navigate("/");
  };

  useEffect(() => {
    getListData();
    // console.log(mode === "edit", mode);
    if (mode === "edit") {
      getDetailData(true);
    }
    return () => {
      // setDetailData(null);
    };
  }, [mode]);

  const formSubmit = (values) => {
    try {
      const setupValues = {
        ...values,
        encyclopediaId: values?.encyclopediaId?.map(
          ({ encyclopediaId }) => encyclopediaId
        ),
        skillFamilyId:
          typeof values.skillFamilyId === "string"
            ? familyMenu?.find((el) => el.label === values.skillFamilyId)
                ?.value || values.skillFamilyId
            : values.skillFamilyId.value,
      };

      setFieldValues(setupValues);
      setConfirmType(mode);
      if (mode === "edit") {
        setConfirmTitle("Confirm Changes");
      } else {
        setConfirmTitle("Confirm Skill Addition");
      }
      setConfirmPopup(true);
      // } else {
      //   showAlert("Please select valid Family value", "info", 1500);
      // }
    } catch (err) {
      console.log(err);
    }
  };

  const addOrUpdate = async (values) => {
    setFullLoader(true);
    try {
      const submitValues = values || fieldValues;
      if (typeof submitValues.skillFamilyId === "number") {
        if (mode === "edit") {
          await SkillService.updateSkillDetailV3(
            "/" + Number(id),
            submitValues
          );
          await showAlert("Skill Updation Successful", "success", 1500);
          closePopup();
          getDetailData(false);
          // setTimeout(() => navigate(`/skill/search`), 100);
        } else {
          const skillDetail = await SkillService.addSkillDetailV3(submitValues);
          await showAlert("Skill Creation Successful", "success", 1500);
          closePopup();
          setTimeout(() => navigate(`edit/${skillDetail.skillId}`), 100);
        }
      } else {
        const familyPostData = {
          skillCategoryId: fieldValues.skillCategoryId,
          skillFamily: fieldValues.skillFamilyId,
        };
        const res = await SkillService.createFamilyV3(familyPostData);
        const resObj = { ...fieldValues, skillFamilyId: res };
        addOrUpdate(resObj);
      }
    } catch (err) {
      showAlert(err.error, "error", 1500);
    } finally {
      setFullLoader(false);
    }
  };

  const editMode = mode === "edit" && !!detailData;

  const handleDeleteSkill = async () => {
    setFullLoader(true);
    try {
      await SkillService.deleteSkillDetailV3("?skillId=" + Number(id));
      await showAlert("Skill Deletion Successful", "success", 2500);
      closePopup();
      setTimeout(() => navigate(`/skill/search`), 100);
    } catch (err) {
      // console.log("handleDeleteSkill -> err", err);
      showAlert(err.error, "error", 2500);
    } finally {
      setFullLoader(false);
    }
  };

  const handleDelete = () => {
    setConfirmType("delete");
    setConfirmTitle("Delete Skill - " + detailData.skillName + " ?");
    setConfirmPopup(true);
  };

  const handleYes = () => {
    if (confirmType === "delete") {
      handleDeleteSkill();
    } else if (confirmType === "redirect") {
      navigate("/skill/search");
    } else {
      addOrUpdate();
    }
  };
  const handleNo = () => closePopup();

  const closePopup = () => {
    setConfirmPopup(false);
    setConfirmType(null);
    setConfirmTitle("");
  };

  const handleSkillTree = () => {
    setSkillTreeModal(true);
  };

  const closeSkillTree = () => {
    setSkillTreeModal(false);
    setTreeData(null);
  };

  // const handleCheckBack = () => {
  //   const hasFormChange = !!Object.keys(formRef.current.touched).length;
  //   if (hasFormChange) {
  //     confirmClick(true, "redirect", "Proceed without saving changes?");
  //   } else {
  //     navigate("/skill/search");
  //   }
  // };

  const confirmClick = (hasPop = true, cfType, cfTitle) => {
    setConfirmPopup(hasPop);
    setConfirmType(cfType);
    setConfirmTitle(cfTitle);
  };

  const handleChangeWiki = (item, reason, setFieldValue) => {
    console.log("handleChangeWiki -> reason", reason);
    if (reason === "select-option") {
      // setEncyclopediaList([]);
      if (
        !encycloCurrentList.some(
          (el) => el.encyclopediaId === item.encyclopediaId
        )
      ) {
        setEncycloCurrentList((prev) =>
          reason === "select-option" ? [...prev, item] : prev
        );
        // setEncyclopediaState(item);
        setFieldValue("encyclopediaId", [...encycloCurrentList, item]);
      } else {
        showAlert("Encyclopedia already added", "info", 1500);
      }
    }
    // else if (reason === "clear") {
    //   setEncyclopediaState("");
    // } else if (reason === "create-option") {
    //   setEncyclopediaState(item);
    //   // handleSearch();
    // }
  };

  useEffect(() => {
    if(showAiGeneratorPopUp.showKnowledgeBasePopUp){
      const updatedAiSuggestedEncycloLists = aiSuggestedEncycloLists.map((el) => {
        const foundItem = encycloCurrentList.some((item) => item?.encyclopediaId === el?.encyclopediaId);
        if (foundItem) {
          return {
            ...el,
            isSelected: false,
            isDisabled: true,
          };
        } else if (!el?.isInValidId) {
          return {
            ...el,
            isDisabled: false,
          };
        }
        return el;
      });
      AIStore.update((s) => {
        s.aiSuggestedEncycloLists = updatedAiSuggestedEncycloLists;
      });
    }
  },[showAiGeneratorPopUp.showKnowledgeBasePopUp])

  const removeChip = (item, values, name, setFieldValue) => {
    setFieldValue(
      name,
      values.filter((el) => el.url !== item.url)
    );
    setEncycloCurrentList((prev) =>
      prev.filter((el) => el.url !== item.url)
    );
  };

  const triggerApi = (searchString, _, pageSize) => {
    setEncyclopediaState(searchString);
    return triggerBounce(searchString, pageSize);
  };

  const triggerBounce = async (searchString, pageSize) => {
    try {
      // if (searchString?.length > 3) {
      const pageParam = `?offset=${0}&pagesize=${pageSize}`;
      const res = await SkillService.GetEncyclopediaV3(
        encyclopediaTypeState,
        searchString,
        pageParam
      );
      setEncyclopediaSize(res?.total);
      return res.data;
      // }
    } catch (errors) {
      console.log(errors);
    }
  };

  const handleEncyclopediaTypes = (e) =>
    setEncyclopediaTypeState(e.target.value);

  const handleCategoryChange = async (e, setFieldValue) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
    setFieldValue("skillFamilyId", "");
    if (!!editMode) {
      showAlert(
        "Message on Update page - <br />Changing Skill Category might result in a cross-category skill tree, <br />please review the skill tree before updating the skill!",
        "info",
        5000
      );
    }
    changeFamilyMenu(value);
  };

  const changeFamilyMenu = async (categoryId) => {
    const resFam = await SkillService.getSkillFamiliesV3(
      `?categoryIds=${categoryId}`
    );

    setFamilyMenu(
      SortString(resFam, "skillFamily").map((el) => ({
        label: el.skillFamily,
        value: el.skillFamilyId,
      }))
    );
  };

  // Function to handle cancel click of AiGeneratorComponent and reset the popup state
  const handleAiGeneratorCancelClick = () => {
    const resetAiGeneratorPopUp = { ...initialStateForAiGeneratorPopUp };
    setShowAiGeneratorPopUp(resetAiGeneratorPopUp);
    // if(showAiGeneratorPopUp.showKnowledgeBasePopUp){
    //   AIStore.update((s) => {s.aiSuggestedEncycloLists = []});
    // }
  }

  //Function to handle confirm click of AiGeneratorComponent and update the states
  const handleAiGeneratorConfirmClick = (value = "", values = "", setFieldValue) => {
      if(showAiGeneratorPopUp.showKnowledgeBasePopUp){
        let updatedEncycloLists = [];
        let updatedAiSuggestedEncycloListst = [...aiSuggestedEncycloLists];
        updatedAiSuggestedEncycloListst =  updatedAiSuggestedEncycloListst.map((el) => {
          if (!!el.isSelected) {
            const { isSelected, isDisabled, ...newEl } = el;
            updatedEncycloLists.push(newEl);
            return { ...el, isSelected: false, isDisabled: true };
          }
          return el;
        })
        AIStore.update((s) => {s.aiSuggestedEncycloLists = [...updatedAiSuggestedEncycloListst]});
        setFieldValue(
          'encyclopediaId',
          [...encycloCurrentList, ...updatedEncycloLists]
        );
        setEncycloCurrentList((prev) => ([...prev, ...updatedEncycloLists]));
      }
      else if(showAiGeneratorPopUp.showSkillShortDescriptionPopUp){
        setFieldValue('skillDescription', values.skillDescription ? `${values.skillDescription} ${value}` : value);
      }
      else if(showAiGeneratorPopUp.showSkillDetailedDescriptionsPopUp){
        setFieldValue('embeddingDescription', values.embeddingDescription ? `${values.embeddingDescription} ${value}` : value);
      }
      handleAiGeneratorCancelClick();
  }

  const getStoreKey = () => {
    if(showAiGeneratorPopUp.showKnowledgeBasePopUp){
      return 'aiSuggestedEncycloLists';
    }else if(showAiGeneratorPopUp.showSkillShortDescriptionPopUp){
      return 'aiSuggestedSkillShortDescriptions';
    }else if(showAiGeneratorPopUp.showSkillDetailedDescriptionsPopUp){
      return 'aiSuggestedSkillDetailedDescriptions'
    }
  }

  // const resetAiSkillStore = () => {
  //  AIStore.update((s) => {
  //   s.aiSuggestedEncycloLists = [];
  //   s.aiSuggestedSkillShortDescriptions =  [];
  //   s.aiSuggestedSkillDetailedDescriptions =  [];
  //   s.aiSuggestedProficienyDescription = [];
  //   s.aiSuggestedKnowledgeDescription = [];
  //  })
  // }

  const checkAndShowAiGeneratorPopUp = (values, popUpToShow) => {
    let skillName = values?.skillName;
    let skillFamily = (values?.skillFamilyId && typeof values?.skillFamilyId === 'object')
                        ? values?.skillFamilyId?.label
                        : values?.skillFamilyId;
    const emptyFields = [];
    if (!skillName) {
      emptyFields.push('Skill Name');
    }
    if (!skillFamily) {
      emptyFields.push('Skill Family');
    }
    if (emptyFields.length > 0) {
      const emptyFieldsList = emptyFields.join(', ');
      showAlert(`Please fill in the following fields: ${emptyFieldsList}`, "info", 1500);
      return;
    }else {
      let currSkillDetail = {skillName: skillName, skillFamily: skillFamily};
      if(JSON.stringify(currSkillDetail) !== JSON.stringify(skillDetail)){
        
        AIStore.update((s) => {s.skillDetail = currSkillDetail});
        resetAiSkillStore();
      }
      setShowAiGeneratorPopUp({[popUpToShow]: true});
    }
  }

  
  const initData = {
    skillName: editMode ? detailData.skillName : "",
    skillDescription: editMode ? detailData.skillDescription : "",
    embeddingDescription: editMode ? detailData.embeddingDescription : "",
    skillCategoryId: editMode ? detailData.skillCategory.skillCategoryId : "",
    skillFamilyId: editMode ? detailData.skillFamily.skillFamily : "",
    sourceId: editMode ? detailData?.skillSource?.sourceId : SourceType.DISPRZ_ATOMIC_SKILLS,
    skillStatus: editMode ? detailData.skillStatus : "",
    encyclopediaId: editMode ? detailData?.encyclopedia || [] : [],
  };

  return (
    <Layout>
      <H2>Skill Management</H2>
      <BodyContent>
        <Container>
          <BackNav linkNav="/skill/search" />
          <WhiteBg>
            <Formik
              enableReinitialize={true}
              initialValues={initData}
              validationSchema={skillManageMentValidation}
              onSubmit={formSubmit}
              innerRef={formRef}
            >
              {({ setFieldValue, setValue, setFieldTouched, fieldValues, values, touched, errors }) => {
                return (
                  <FormBlock>
                    <SearchBlock>
                      <FormTextField
                        label="Skill Name"
                        name="skillName"
                        setVal={setFieldValue}
                        setTouched={setFieldTouched}
                        className="skillName"
                        customValidate={checkSkillNameExist}
                      />
                      <DropDown
                        list={categoryMenu}
                        name="skillCategoryId"
                        width="190px"
                        label="Category"
                        handleChange={(e) =>
                          handleCategoryChange(e, setFieldValue)
                        }
                      />
                      {!!familyMenu && (
                        <div className="auto-search">
                          <AutoSelect
                            label="Family"
                            name="skillFamilyId"
                            list={familyMenu}
                            renderOption={(option) => (
                              <OptionCutom>{option.label}</OptionCutom>
                            )}
                            optionLabel={"label"}
                            freeSolo={true}
                          />
                        </div>
                      )}
                      <div className="source-dropDown">
                        <DropDown
                          list={sourceMenu}
                          name="sourceId"
                          width="175px"
                          label="Source"
                          disabled={true}
                        />
                      </div>
                      <DropDown
                        list={skillstatusMenuList || []}
                        name="skillStatus"
                        width="190px"
                        label="Status"
                      />
                    </SearchBlock>
                    <ContainerX>
                      <Label style={{ color: COLORS.TITLE_BLUE }}>
                        Knowledge Base
                      </Label>
                     {renderAIButton({mode, sourceId: skillSourceId, clickHandler:() => checkAndShowAiGeneratorPopUp(values, 'showKnowledgeBasePopUp')})}
                    </ContainerX>
                    <ChipBlock showBox={!!values.encyclopediaId?.length}>
                      {!!values?.encyclopediaId && (
                        <ChipList
                          list={values.encyclopediaId}
                          optionLabel="encyclopedia"
                          onDelete={(item) =>
                            removeChip(
                              item,
                              values.encyclopediaId,
                              "encyclopediaId",
                              setFieldValue
                            )
                          }
                          chipClick={(val) => navigateToTagURL(val, showAlert)}
                          style={{ paddingBottom: 0 }}
                        />
                      )}
                      <AddCancelButton
                        onClick={chipAdd}
                        showBox={showBox}
                        title={showBox ? "Cancel" : "Add"}
                      >
                        <div class="leftright"></div>
                        <div class="rightleft"></div>
                      </AddCancelButton>
                    </ChipBlock>
                    <div style={{ display: showBox ? "block" : "none" }}>
                      <SearchBlock>
                        <DropDownState
                          list={encyclopediaTypes || []}
                          name="leadership_role_id"
                          width="190px"
                          // label="Encyclopedia Types"
                          handleChange={handleEncyclopediaTypes}
                          value={encyclopediaTypeState}
                        />
                        <DropDownRow>
                          <AutoSelectSearch
                            width={320}
                            freeSolo={false}
                            optionChange={(item, reason) =>
                              !!item
                                ? handleChangeWiki(item, reason, setFieldValue)
                                : undefined
                            }
                            handleClearSearch={() => setEncyclopediaState("")}
                            optionLabel="encyclopedia"
                            // label="&nbsp;"
                            placeholder="Search encyclopedia"
                            stateValue={encyclopediaState}
                            enterResetSearch={(val) => {
                              // handleSearch();
                              setEncyclopediaState(val);
                            }}
                            triggerApi={(val, reason, pageSize) =>
                              triggerApi(val, reason, pageSize)
                            }
                            // PopperComponent={(props) => <div>}
                            renderOption={(option) => (
                              <div style={{ width: "100%" }}>
                                {option?.encyclopedia}
                              </div>
                            )}
                            listSize={encyclopediaSize}
                          />
                        </DropDownRow>
                      </SearchBlock>
                    </div>
                    <SkillDescriptionX>
                      {renderAIButton({mode,sourceId: skillSourceId, clickHandler:() => checkAndShowAiGeneratorPopUp(values, 'showSkillShortDescriptionPopUp'), style: { position: 'absolute', top: '-5px', left: '137px' }})}
                      <FormTextField
                          multiline={true}
                          disabled={false}
                          rows="5"
                          rowsMax="1000"
                          name="skillDescription"
                          label="Skill Description"
                          setVal={setFieldValue}
                          setTouched={setFieldTouched}
                      />
                        {(User_Type === UserType.SUPER_USER || User_Type === UserType.SKILLING_ARCHITECT) && (
                          <ToolBar title="Click to see the detailed description" arrow={true}>
                            <div style={{position: 'absolute', bottom: (!values?.skillDescription && !!touched?.skillDescription && !!errors?.skillDescription) ? '54.5px' : '30px', right: "10px"}}>
                              <SecondaryButton 
                                onClick={() => setShowDetailedDescription(!showDetailedDescription)}
                                style={{padding: '4px 9px', minWidth: '12px', minHeight: '17px', border: '1px solid #fa1565'}}
                              >
                               <img src={Images.icons.rightArrowIcon} alt="next" />
                              </SecondaryButton>
                            </div>
                          </ToolBar>
                        )}
                    </SkillDescriptionX>
                    {showDetailedDescription && (
                      <SkillDescriptionX>
                          {renderAIButton({mode, sourceId: skillSourceId, clickHandler: () => checkAndShowAiGeneratorPopUp(values, 'showSkillDetailedDescriptionsPopUp'), style: { position: 'absolute', top: '-5px', left: '165px' }})}
                          <div className="description-container">
                            <FormTextField
                              multiline={true}
                              disabled={false}
                              rows="5"
                              rowsMax="1000"
                              name="embeddingDescription"
                              label="Detailed Description"
                              setVal={setFieldValue}
                              setTouched={setFieldTouched}
                            />
                            <div className="description-text">
                              <i>Description generated through AI. Any changes made here could affect the content relevancy scores.</i>
                            </div>
                           </div>
                      </SkillDescriptionX>
                    )}
                    {mode === "edit" && (
                      <SkillsManageTable {...props} detailData={detailData} skillDetail={skillDetail}/>
                    )}
                    <PrimaryButton type="submit">
                      {mode === "edit" ? "Update Skill" : "Add Skill"}
                    </PrimaryButton>
                    {mode === "edit" && (
                      <SecondaryButton
                        onClick={handleDelete}
                        style={{ marginLeft: 15 }}
                      >
                        Delete Skill
                      </SecondaryButton>
                    )}
                    {/* {mode === "edit" && (
                      <PrimaryButton
                        onClick={handleSkillTree}
                        style={{ marginLeft: 15 }}
                      >
                        Skill Tree
                      </PrimaryButton>
                    )} */}

                    <ModalPopup
                      open={showAiGeneratorModal(showAiGeneratorPopUp)}
                      maxWidth={!!showAiGeneratorPopUp?.showKnowledgeBasePopUp ? "sm" : "lg"}
                      title={getAiGeneratorTitle(showAiGeneratorPopUp, skillGeneratorMapping)}
                      center={true}
                    >
                      <AIGenerator
                        generatorType={getGeneratorType(showAiGeneratorPopUp, skillGeneratorMapping)}
                        getServiceUrl={getServiceUrl(showAiGeneratorPopUp, skillGeneratorMapping)}
                        params={skillDetail}
                        cancelText="Close"
                        confirmText={getConfirmText(showAiGeneratorPopUp, skillGeneratorMapping)}
                        onCancel={() => handleAiGeneratorCancelClick()}
                        onConfirm={(value) => handleAiGeneratorConfirmClick(value, values, setFieldValue)}
                        placeholder=""
                        encycloCurrentList={showAiGeneratorPopUp.showKnowledgeBasePopUp ? encycloCurrentList : []}
                        detailedDescription={showAiGeneratorPopUp.showSkillDetailedDescriptionsPopUp}
                        aiStoreKey={getStoreKey()}
                      />
                    </ModalPopup>

                  </FormBlock>
                );
              }}
            </Formik>
          </WhiteBg>
        </Container>
        {skillTreeModal && (
          <ModalPopup
            open={skillTreeModal}
            maxWidth="lg"
            title="Skill Tree"
            center={true}
            closePopup={closeSkillTree}
          >
            {/* <SkillTree /> */}
            <CreateSkillTree
              data={detailData}
              getTreeData={(data) => setTreeData(data)}
            />
            {/* <CreateSkillTreeTest /> */}
            {/* <ConfirmationButton
              yesClick={handleSkillSave}
              noClick={closeSkillTree}
              yesText="Save"
              noText="Cancel"
            /> */}
          </ModalPopup>
        )}
        <ModalPopup
          open={confirmPopup}
          maxWidth="sm"
          title={confirmTitle}
          center={true}
        >
          <ConfirmationButton yesClick={handleYes} noClick={handleNo} />
        </ModalPopup>
      </BodyContent>
    </Layout>
  );
};

const WhiteBg = styled.div`
  background-color: ${COLORS.PRIMARY_WHITE};
  border-radius: 8px;
  box-shadow: 0 1px 3px 0 rgba(97, 122, 163, 0.24);
  padding: 30px;
  margin-bottom: 30px;
  &:last-child {
    margin-bottom: 0px;
  }
  table {
    padding: 0px 10px;
  }
`;

const FormBlock = styled(Form)`
  margin-top: 15px;
  width: 80%;
  @media (max-width: 1100px) {
    width: 100%;
  }
  .description-container {
    position: relative
    .toggle-detailed-description {
      position: absolute;
      bottom: 30px;
      right: 10px;
    }
    >:nth-child(1){
      padding-bottom: 8px;
    }
    .description-text{
      display: flex;
      justify-content: flex-end;
      font-size: 12px;
      line-height: 120%;
      color: ${COLORS.PRIMARY_BLACK};
      margin-top: 0px;
    }
  }
`;

const ChipBlock = styled.div`
  display: flex;
  align-items: flex-start;
  padding-bottom: ${(props) => (props.showBox ? `10px` : `10px`)};
`; 

const DropDownRow = styled.div`
  width: 100%;
  /* margin-bottom: 30px; */
  label {
    padding-bottom: 8px;
    color: ${COLORS.TITLE_BLUE};
    font-weight: 600;
  }
  .MuiAutocomplete-inputRoot {
    border: solid 1px ${COLORS.C4_BORDER};
    margin-top: 0px !important;

    &[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
      padding: 7px;
    }
  }
`;

const SearchBlock = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;

  & > div {
    padding-bottom: 0px;
    margin-left: 15px;
    label[for="skillName"] {
      padding-bottom: 8px;
    }
    .MuiInputBase-root {
      margin-top: 26px;
    }
    &:first-child:not(.MuiFormControl-root) {
      width: 40%;
      .MuiInputBase-root {
        margin-top: 0px;
      }
    }
    &:first-child {
      margin-left: 0px;
    }
  }
  button {
    margin-left: 30px;
  }
  .auto-search {
    width: 300px;
    & > div .MuiInputBase-root {
      margin-top: -2px;
    }
    button {
      margin-left: 0px;
    }

    label {
      line-height: 24px;
    }
    & > div {
      padding-bottom: 0px;
    }
    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]
      .MuiAutocomplete-input {
      padding: 9px 4px;
    }
  }
  .source-dropDown {
    .MuiSelect-selectMenu{
      color: ${COLORS.PRIMARY_BLACK};
      padding-right: 10px;
      text-align: center
    }
    .MuiSelect-icon{
      display: none;
    }
  }
`;

const OptionCutom = styled.div`
  font-size: 12px;
`;

const ContainerX = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px !important;
  gap: 15px;
  >label{
    padding-bottom: 0px !important;
  }
`

const SkillDescriptionX = styled.div`
 position: relative;
 label{
  max-width: fit-content;
 }
 >div{
  margin-top: 10px;
  >div{
    >div{
      margin-top: 10px;
      > textarea[name="skillDescription"] {
        margin-bottom: 20px;
      }
    }
  }
 }
`

const SuggestionBlock = styled.div`
 .suggestion-container{
  display: flex;
  flex-direction: column;
  row-gap: 14px;
  padding: 0px 15px;
  overflow: hidden;
  .suggestion-header{
    display: flex;
    align-items: center;
    gap: 6px;
    h4{
      padding: 0px !important;
    }
    button{
      width: 110px !important;
      margin-left: 20px;
    }
  }
  .knowledge-base-suggestions{
    max-height: 180px;
    overflow-y: auto;
    margin-top: 5px;
    margin-bottom: 10px;
  }
  .skill-description-suggestions{
    display: flex;
    height: 300px !important;
    gap: 15px;
    overflow: hidden;
    .common-styles{
      padding: 24px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 8px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #888;
        border-radius: 4px; 
      }
      scrollbar-width: thin;
      scrollbar-color: #888 #F1F3F7;
      h5{
        padding: 0px;
      }
    }
    .ai-suggestions{
      width: 55%;
      border-radius: 8px;
      background: #F1F3F7;
      display: flex;
      flex-direction: column;
      row-gap: 15px;
      .option{
        display: flex;
        flex-direction: column;
        row-gap: 4px;
        .output{
          display: flex;
          align-item: center;
          gap: 10px;
          .copy-icon{
            cursor: pointer;
          }
          .copied-message {
            font-size: 12px; 
            margin-left: -5px;
          }
          .new-option{
            background: ${COLORS.PRIMARY_PURPLE_GRADIENT};
            color: ${COLORS.PRIMARY_WHITE};
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0px 4px;
            height: 14px;
            margin-top: 5px;
            cursor: context-menu;
          }
        }
        .ai-description{
          line-height: 19px;
        }
        .pl-suggestions{
          display: flex;
          flex-direction: column;
          row-gap: 5px;
        }
      }
    }
    .hovered{
      background: rgba(249, 20, 101, 0.22);
      cursor: pointer
    }
    .user-selected-suggestions{
      width: 45%;
      border-radius: 8px;
      border: 1px solid #E0E0E0;
      .user-chosen-desciption{
        .text-field{
          width: 100%;
          >div{
            font-size: 14px;
            line-height: 19px;
          }
        }
      }
      .pl-chosen{
            display: flex;
            flex-direction: column;
            row-gap: 5px;
            margin: 10px 0px;
        }
    }
    margin-top: 5px;
    margin-bottom: 10px;
  }
}
`