import React from "react";
import styled from "styled-components";
import { PrimaryButton } from "./Buttons/PrimaryButton";
import { SecondaryButton } from "./Buttons/SecondaryButton";

export default ({ yesClick, noClick, yesText = "Yes", noText = "No", disableNoClick = false, disableYesClick = false, showNoButton = true, primaryButtonStyle={}, secondaryButtonStyle={} }) => {
  return (
    <ConfirmBlock>
      { showNoButton && <SecondaryButton onClick={noClick} disabled={disableNoClick} style={secondaryButtonStyle}>{noText}</SecondaryButton> }
      <PrimaryButton onClick={yesClick} disabled={disableYesClick} style={primaryButtonStyle}>{yesText}</PrimaryButton>
    </ConfirmBlock>
  );
};

const ConfirmBlock = styled.div`
  display: flex;
  justify-content: center;
  button {
    margin: 10px;
  }
`;
