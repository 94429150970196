// const humanizeDuration = require("humanize-duration");

import humanizeDuration from "humanize-duration";

export const CheckDecimal = (value) =>
  value % 1 < 0.25
    ? Number(Math.trunc(value))
    : value % 1 > 0.25 && value % 1 <= 0.5
    ? Number(Math.trunc(value) + ".5")
    : value % 1 > 0.5 && value % 1 <= 0.75
    ? Number(Math.trunc(value) + ".5")
    : value % 1 > 0.75
    ? Number(Math.round(value))
    : value;

export const HrsMinsConvert = (value) =>
  Math.floor(value) === 0
    ? Math.floor(value * 60) +
      (!!value ? " " + (Math.floor(value * 60) > 1 ? "mins" : "min") : "")
    : CheckDecimal(value) + " hrs";

export const SecondsToMinutes = (seconds) =>
  Math.floor(seconds / 60) + ":" + ("0" + Math.floor(seconds % 60)).slice(-2);

// var m = moment('2017-02-17 12:01:01');
// cons
export const RoundUp = (m) =>
  m.second() || m.millisecond()
    ? m.add(1, "minute").startOf("minute")
    : m.startOf("minute");
// console.log(roundUp.toString());

export const HumanizerTime = (value) =>
  humanizeDuration(value, {
    largest: 1,
    round: true,
    units: ["h", "m"],
    language: "shortEn",
    languages: {
      shortEn: {
        y: () => "y",
        mo: () => "mo",
        w: () => "w",
        d: () => "d",
        h: () => "h",
        m: () => "m",
        s: () => "s",
        ms: () => "ms",
      },
    },
  });

export const CheckHumanizerTime = (value) =>
  HumanizerTime(value) === "0 m" ? "1 m" : HumanizerTime(value);
