import React from "react";
import Layout from "../../Layout";
import { H2 } from "../../Common/Typography";
import { BodyContent, Container, BodyWhitePattern } from "../../Common/GridSystem";
import BackNav from "../../Common/BackNav";
import SearchSkills from "./SearchSkills/SearchSkills";
import ModalPopup from "../../Common/ModalPopup";
import SkillUpdatePopup from "../../Personas/ArchitectSkills/SkillContent/SkillUpdatePopup";
import "./AssignSkills.scss";
import useAssignSkills from "./useAssignSkills";
import CustomAccordian from "../../Common/CustomAccordian";
import SelectRoleSkills from "./SelectRoleSkills/SelectRoleSkills";
import { useLocation } from "@reach/router";
import text from "../../../locale.en.json"

const AssignSkills = ({ roleId, type, personaId = "" }) => {
  const location = useLocation();

  const {
    categoryMenu,
    familyMenu,
    handleSkillPopup,
    isNavigatedFromRoleMatch,
    isExcludeSkills,
    skillIdsToBeExcluded,
    personaSkills,
    skillSourceIds,
    skillPopup,
    closePopup,
    skillDetail,
    skillProficiency,
    isNavigatedFromRoleScreen,
    roleSelectionData,
    excludedRoleSkills
  } = useAssignSkills({ type, personaId });

  const accordSections = {};

  if(!!location?.state?.showIndustryRoleSkills) {
    accordSections[text.skill.industryRoleMappedSkills] = (
      <SelectRoleSkills
        roleId={Number(roleId)}
        personaId={personaId}
        skillType={type}
        roleSelectionData={roleSelectionData}
        excludedRoleSkills={excludedRoleSkills}
      />
    )
  }

  accordSections[text.skill.searchSkills] = (
    <SearchSkills
      categoryMenu={categoryMenu}
      familyMenu={familyMenu}
      roleId={Number(roleId)}
      handleSkillPopup={handleSkillPopup}
      skillType={type}
      isNavigatedFromRoleMatch={isNavigatedFromRoleMatch}
      rolePersonaSkillIds={
        isExcludeSkills
          ? skillIdsToBeExcluded
          : personaSkills?.map(({ skillId }) => skillId) || []
      }
      skillSourceIds={skillSourceIds}
      personaSkills={personaSkills}
      personaId={personaId}
      isNavigatedFromRoleScreen={isNavigatedFromRoleScreen}
      roleSelectionData={roleSelectionData}
    />
  );

  return (
    <Layout>
      <H2>
        {text.skill.assignSkillsTo}{" "}
        {type === "persona" || isNavigatedFromRoleMatch ? text.persona.Persona : text.role.Role}
      </H2>
      <BodyContent>
        <Container>
          <BackNav />
          <BodyWhitePattern>
            <CustomAccordian
              list={accordSections}
              initOpen={false}
            />
          </BodyWhitePattern>
        </Container>
        <ModalPopup
          open={skillPopup}
          maxWidth="lg"
          closePopup={closePopup}
          title={!!skillDetail && skillDetail.skillName}
        >
          <SkillUpdatePopup
            data={skillDetail}
            listProficiency={skillProficiency}
            closePopup={closePopup}
            withCilentID={type === "persona"}
            clientView={type === "persona"}
          />
        </ModalPopup>
      </BodyContent>
    </Layout>
  );
};

export default AssignSkills;