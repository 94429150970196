import React, { useState, useEffect  } from "react";
import styled from "styled-components";
import { H5 } from "../../../Common/Typography";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import COLORS from "../../../../assets/colors";
import AutoFilterLocal from "../../../Common/FormElements/AutoFilterLocal";
import { SkillSourceType } from "../../../../enums/SkillSourceType";
import { SkillMappingType } from "../../../../enums/SkillMappingType";

export default ({ data, dataCopy, handleSourceSort, handleFilter }) => {
  const [sortSourceAscending, setSortSourceAscending] = useState(true);
  const [filter, setFilter] = useState(false);
  const category = (data ? data[0].category : null);
  useEffect(()=>{
   setFilter(false);
   setSortSourceAscending(true);
  },[category])
  const uniqueSkillSources = [...new Set(dataCopy?.map((item) => item.skillAddedFrom))]?.map((source) => ({
    label: SkillSourceType[source],
    value: SkillSourceType[source]
}));


    return (
      <SourceColumnX>
        <SourceHeader>
          <H5>
            Source{" "}
            <TableSortLabel
              active={true}
              direction={(sortSourceAscending) ? "asc" : "desc"}
              onClick={() => {
                if (category) {
                  handleSourceSort(data, sortSourceAscending);
                  setSortSourceAscending(!sortSourceAscending);
                }
              }}
            ></TableSortLabel>
            <FiletrIcon
              onClick={() => {
                console.log("clicked");
                if(filter === true){
                  handleFilter('', 'clear', category);
                }
                setFilter(!filter)
              }}
              active={false}
              title={!!filter ? "Clear" : "Open Filter"}
            >
              <div className={"hamburger " + (!!filter ? "is-active" : "")} id="hamburger-1">
                <span className="line">&nbsp;</span>
                <span className="line">&nbsp;</span>
                <span className="line">&nbsp;</span>
              </div>
            </FiletrIcon>
            {
              !!filter && 
              <AutoFilterLocal
                list={ uniqueSkillSources }
                filterLabel="label"
                style={{ padding: "0px", width: "100%" }}
                placeholder="Filter by Source"
                optionChange={(val, reason) => {
                  handleFilter(val, reason, category);
                }}
                autoFocus={true}
              />
            }
          </H5>
        </SourceHeader>
        <ul>
          {data?.map((el, i) => (
            <li
              key={i}
              style={{
                width: "100%",
                background:
                el?.skillAddedFrom === SkillMappingType.INDUSTRY_ROLE
                    ? "rgba(100, 187, 255, 0.10)"
                    : el?.skillAddedFrom === SkillMappingType.ADD_SKILL
                    ? "rgba(123, 201, 123, 0.1)"
                    : el?.skillAddedFrom === SkillMappingType.SKILL_MATCH
                    ? "rgba(250, 218, 104, 0.10)"
                    : el?.skillAddedFrom === SkillMappingType.INFERRED_SKILL
                    ? "rgba(169, 113, 241, 0.10)"
                    : "",
              }}
            >
              <span>{el?.skillAddedFrom ? SkillSourceType[el.skillAddedFrom] : ''} </span>
            </li>
          ))}
        </ul>
      </SourceColumnX>
    );
  };

 const SourceColumnX =  styled.div`
   width: 180px;
  h5 {
    position: relative;
    padding-bottom: 38px;
    width: 180px;
    svg {
      cursor: pointer;
      color: ${COLORS.DARK_GREY};
      font-size: 20px;
    }
  }
  ul {
    li {
      border: solid 1px ${COLORS.ASH_BORDER};
      border-radius: 4px;
      color: ${COLORS.TITLE_BLUE};
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      font-weight: 600;
      span {
        font-size: 12px;
        font-weight: 400;
      }
    }
  }
`;

const SourceHeader = styled.div`
width: 180px;
display: flex;
gap: 6px;
h5 {
  position: relative;
  .MuiAutocomplete-root{
    position: absolute;
    margin-left: 2px;
  }
}
.MuiTableSortLabel-root {
  bottom: 2px;
}
.MuiTableSortLabel-icon{
  opacity: 0.3
}
`;

const FiletrIcon = styled.div`
  cursor: pointer;
  display: inline-flex;
  position: absolute;
  top: 8px;
  margin-left: 4px;
  opacity: ${(props) => (props.active ? 1 : 0.3)};
  &:hover {
    opacity: ${(props) => (props.active ? 1 : 0.5)};
  }
  .hamburger .line {
    width: 16px;
    height: 1px;
    background-color: #000;
    display: block;
    margin: 0px auto 3px auto;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }

  .hamburger:hover {
    cursor: pointer;
  }

  #hamburger-1 .line:nth-child(2) {
    width: 10px;
  }
  #hamburger-1.is-active .line {
    width: 12px;
  }
  #hamburger-1.is-active .line:nth-child(2) {
    opacity: 0;
  }

  #hamburger-1.is-active .line:nth-child(1) {
    transform: translateY(4px) rotate(45deg);
  }

  #hamburger-1 .line:nth-child(3) {
    width: 4px;
  }

  #hamburger-1.is-active .line:nth-child(3) {
    width: 12px;
    transform: translateY(-4px) rotate(-45deg);
  }
`;