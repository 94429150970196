import React, { useState, useEffect } from "react";
import Layout from "../Layout";
import DetailHeader from "./DetailHeader";
import { AuthService } from "../../services/AuthService";
import PrimaryDetail from "./PrimaryDetail";
import DetailContent from "./DetailContent";
import useFullLoader from "../../Hooks/useFullLoader";
import { SortString } from "../../helpers/SortString";

export default (props) => {
  const { id, count, rowNumber } = props;
  const [pageSize, setPageSize] = useState(Number(rowNumber));
  const [data, setData] = useState(null);
  const [subSector, setSubSector] = useState("Any");
  const [subSectors, setSubSectors] = useState(null);
  const [functions, setfunctions] = useState(null);

  const { setFullLoader } = useFullLoader();

  const handleSubSector = (val) => setSubSector(val);

  const handlePageSize = (page) => {
    setPageSize(Number(page));
    const initData = {
      sectorList: [],
      sourceList: [],
    };

    const getJobData = async () => {
      setFullLoader(true);
      try {
        const res = await AuthService.getJobList(
          initData,
          `?pagesize=${1}`,
          `&offset=${Number(page)}`
        );
        const result = await AuthService.getJobDetail("/" + res[0].id);
        setData(result);
      } catch (error) {
        console.log(error);
      } finally {
        setFullLoader(false);
      }
    };
    getJobData();
  };

  useEffect(() => {
    const getJobData = async () => {
      try {
        const result = await AuthService.getJobDetail("/" + id);
        setData(result);
      } catch (error) {
        console.log(error);
      }
    };

    const getSelectOptions = async () => {
      try {
        const res = await AuthService.subSectorList();
        const sorted = SortString(res, "sub_sector_name");
        sorted.unshift({
          sub_sector_name: "Any",
          sub_sector_id: "Any",
          sub_sector_type: 1,
        });
        setSubSectors(sorted);
      } catch (error) {
        console.log(error);
      }
    };

    getJobData();
    getSelectOptions();

    return () => {
      setData(null);
    };
  }, []);

  useEffect(() => {
    const getFunctionsList = async () => {
      try {
        const resultFuns = await AuthService.functionsList(
          subSector !== "Any" ? `?subSectorIds=${subSector}` : ""
        );
        setfunctions(resultFuns.sort());
      } catch (error) {
        console.log(error);
      }
    };
    if (subSector !== "Any") getFunctionsList();
    return () => {
      setfunctions(null);
    };
  }, [subSector]);

  useEffect(() => {
    setfunctions(null);
  }, [pageSize]);

  useEffect(() => {
    if (!!data)
      setSubSector(data.sub_sector_id !== "" ? data.sub_sector_id : "Any");
  }, [data]);

  // console.log(data);
  return (
    <Layout>
      <DetailHeader
        pageSize={pageSize}
        handlePageSize={handlePageSize}
        count={count}
        rowNumber={rowNumber}
      />
      {!!data && <PrimaryDetail data={data} />}
      <DetailContent
        data={data}
        subSector={subSector}
        subSectors={subSectors}
        handleSubSector={handleSubSector}
        functions={functions}
        page={pageSize}
      />
    </Layout>
  );
};
