import React, { useEffect, useState } from "react";
import { navigate } from "@reach/router";
import styled from "styled-components";

import Layout from "../Layout";
import { H2 } from "../Common/Typography";
import { BodyContent, Container } from "../Common/GridSystem";
import BackNav from "../Common/BackNav";
import { AuthService } from "../../services/AuthService";
import CustomizedTables from "../Common/CustomizedTables";
import client_table from "./client_table";
import ModalPopup from "../Common/ModalPopup";
import MenuOption from "../Common/MenuOption";
import useFullLoader from "../../Hooks/useFullLoader";
import ConfirmationButton from "../Common/ConfirmationButton";
import PopHover from "../Common/PopModal/PopHover";
import { PrimaryButton } from "../Common/Buttons/PrimaryButton";
import useAlert from "../../Hooks/useAlert";
import { SortString } from "../../helpers/SortString";
import {
  FilterSortTableCondition,
  FilterTableRow,
} from "../../helpers/FilterTableRow";
import RowCount from "../Common/Others/RowCount";
import { useTableContext } from "../../Provider/TableProvider";

export default () => {
  const { setFullLoader } = useFullLoader();
  const { showAlert } = useAlert();
  const { sortAndFilterValues } = useTableContext();
  const { getList } = FilterSortTableCondition();
  const [clientList, setClientList] = useState(null);
  const [clientListCopy, setClientListCopy] = useState(null);

  const [currentItem, setCurrentItem] = useState(null);
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [confirmTitle, setConfirmTitle] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);

  const getClientList = async () => {
    setFullLoader(true);
    try {
      const res = await AuthService.getAllClientsV3();
      const list = SortString(
        res.map((el) => ({
          ...el,
          subSector: "",
        })),
        "clientName"
      );

      if (!!sortAndFilterValues) {
        setClientList(getList(list));
      } else {
        setClientList(list);
      }
      setClientListCopy(list);

      // console.log(res);
    } catch (error) {
      console.log(error);
    } finally {
      setFullLoader(false);
    }
  };

  useEffect(() => {
    getClientList();
  }, []);

  const handleRow = (col) => navigate(`/clients/edit/${Number(col.clientId)}`);

  const handleMoreOpen = (e, item, index) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
    setCurrentItem(item);
  };

  const handleMoreClose = () => setAnchorEl(null);

  const handleDelete = () => {
    handleMoreClose();
    setConfirmTitle(`Delete Client - ${currentItem.clientName} ?`);
    setConfirmPopup(true);
  };

  const handleDeleteYes = async () => {
    closePopup();
    setFullLoader(true);
    try {
      await AuthService.deleteClientV3("/" + Number(currentItem.clientId));
      await showAlert("Client Deletion Successful", "success", 1500);
      getClientList();
    } catch (errors) {
      console.log(errors);
    } finally {
      setFullLoader(false);
    }
  };

  const handleNo = () => closePopup();
  const closePopup = () => {
    setConfirmPopup(false);
    setConfirmTitle("");
  };

  const MoreActionContent = () => (
    <div>
      <MenuOption
        onClick={() =>
          navigate(`/clients/edit/${Number(currentItem.clientId)}`)
        }
      >
        Manage Client Account
      </MenuOption>
      <MenuOption onClick={handleDelete}>Delete Client Account</MenuOption>
    </div>
  );

  const DeleteModal = () => (
    <ModalPopup
      open={confirmPopup}
      maxWidth="sm"
      title={confirmTitle}
      center={true}
    >
      <ConfirmationButton yesClick={handleDeleteYes} noClick={handleNo} />
    </ModalPopup>
  );

  // console.log(clientList);

  return (
    <Layout>
      <H2>Client Management</H2>
      <BodyContent>
        <Container>
          <TopBlock>
            <BackNav linkNav="/" />
            <div>
              <RowCount count={clientListCopy?.length} />
              <PrimaryButton onClick={() => navigate("/clients/add")}>
                Add Client
              </PrimaryButton>
            </div>
          </TopBlock>
          <CustomizedTables
            columns={client_table}
            defaultSortColumn="clientName"
            rowList={clientList}
            rowClick={handleRow}
            moreActionClick={handleMoreOpen}
            rowListCopy={clientListCopy}
            sortList={(sortValue, sortBy) =>
              setClientList(SortString(clientList, sortValue, sortBy))
            }
            filterChange={(item, reason, colKey, list) =>
              FilterTableRow(item, reason, colKey, list, setClientList)
            }
            resetList={() => setClientList(clientListCopy)}
          />

          <DeleteModal />
          <PopHover anchorEl={anchorEl} handleClose={handleMoreClose}>
            <MoreActionContent />
          </PopHover>
        </Container>
      </BodyContent>
    </Layout>
  );
};

const TopBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  & > div {
    margin: 0px;
    display: flex;
    align-items: center;
  }
`;
