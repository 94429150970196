import React from "react";
import { SkillStatusMenu } from "../../utils";

export const Skill_Req_Table = [
  { colKey: "title", colValue: "Title" },
  { colKey: "subSector", colValue: "Sub-Sector" },
  { colKey: "role", colValue: "Normalised Role" },
];

export const Skill_Search_Table_Col = [
  { 
    colKey: "skillName", 
    colValue: "Skill",
    sortable: true
  },
  {
    colKey: "skillCategory",
    colValue: "Category",
    colType: "inside_Function",
    sortable: true,
    getTableData: (val) => {
      return <>{val.skillCategory.skillCategory}</>;
    },
  },
  {
    colKey: "skillFamily",
    colValue: "Family",
    colType: "inside_Function",
    sortable: true,
    getTableData: (val) => {
      return <>{val.skillFamily.skillFamily}</>;
    },
  },
  {
    colKey: "skillSource",
    colValue: "Source",
    colType: "inside_Function",
    sortable: true,
    getTableData: (val) => {
      return <>{val.skillSource.source}</>;
    },
  },
  {
    colKey: "skillStatus",
    colValue: "Status",
    colType: "inside_Function",
    getTableData: (val) => {
      return (
        <>{SkillStatusMenu.find((el) => el.value === val.skillStatus)?.label}</>
      );
    },
  },
];

export const Skill_Req_Table_array = [
  {
    title: "Title 1",
    subSector: "Retail Banking",
    role: "Data Analyst",
  },
  {
    title: "Title 2",
    subSector: "Retail Banking",
    role: "Data Analyst",
  },
  {
    title: "Title 3",
    subSector: "Retail Banking",
    role: "Data Analyst",
  },
  {
    title: "Title 4",
    subSector: "Retail Banking",
    role: "Data Analyst",
  },
  {
    title: "Title 5",
    subSector: "Retail Banking",
    role: "Data Analyst",
  },
];
