import React from 'react';
import './ErrorLabel.scss';
import { Images } from "../../../assets/images";
import text from "../../../locale.en.json";

const ErrorLabel = ({ message }) => {
    return (
        <div className="error-label">
            <img src={Images.icons.errorIcon} />
            <span className="error-message"> <b>{text.fileSelection.error}:</b> {message}</span>
        </div>
    );
};

export default ErrorLabel;
