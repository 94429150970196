import React, { useEffect } from "react";
import Login from "../components/Login";
import { Redirect } from "@reach/router";
import useAlert from "../Hooks/useAlert";

const AuthRoute = (props) => {
  const { showAlert } = useAlert();

  let { as: Comp, isAuth, restricted, ...rest } = props;
  useEffect(() => {
    if (!!restricted && !!isAuth) {
      showAlert("This user not authorized to access this page", "info", 1500);
    }
  }, [restricted, isAuth]);
  // console.log("AuthRoute -> isAuth", isAuth, restricted);

  return isAuth ? (
    <>{!restricted ? <Comp {...rest} /> : <Redirect noThrow={true} to="/" />}</>
  ) : (
    <Login {...props} />
  );
};

export default AuthRoute;
