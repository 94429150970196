export const Inferred_Disprz_Skills_Table = [
    {
      colKey: "checkbox",
      colValue: "checkbox",
      width: 60,
    },
    {
      colType: "skillId",
      colId: "skillId",
      colKey: "skillName",
      colValue: "Skill Name",
      sortable: true,
      width: 200
    },
    {
      colKey: "skillCategory",
      colValue: "Skill Category",
      sortable: true,
      width: 200
    },
    {
      colKey: "skillDescription",
      colValue: "Skill Description",
      sortable: true,
      width: 280
    },
    {
      colType: "levelSelect",
      colKey: "selectedLevel",
      colValue: "Target Proficiency",
      editable: true,
      width: 280
    }
  ];

export const Inferred_Non_Disprz_Skills_Table = [
    {
      colKey: "skillName",
      colValue: "Skill Name",
      sortable: true,
      width: 200
    },
    {
      colKey: "skillDescription",
      colValue: "Skill Description",
      sortable: true,
      width: 450
    },
  ];

