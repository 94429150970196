import React, { useState, useEffect } from "react";
import { navigate } from "@reach/router";

import styled from "styled-components";
import COLORS from "../../assets/colors";
import BackNav from "../Common/BackNav";
import AutoSelectSearch from "../Common/FormElements/AutoSelectSearch";
import { BodyContent, Container } from "../Common/GridSystem";
import { H2, H3, H4 } from "../Common/Typography";
import Layout from "../Layout";
import useFullLoader from "../../Hooks/useFullLoader";
import { PrimaryButton } from "../Common/Buttons/PrimaryButton";
import { SkillService } from "../../services/SkillService";

export default ({ children }) => (
  <Layout>
    <DisprzopediaSearchComponent>{children}</DisprzopediaSearchComponent>
  </Layout>
);

function DisprzopediaSearchComponent() {
  const searchWidth = 810;
  const pageSize = 1000;
  const { setFullLoader } = useFullLoader();

  const [searchText, setSearchText] = useState("");
  const [listTotal, setListTotal] = useState(0);
  const [roleList, setResponseList] = useState(null);

  const [showTable, setShowTable] = useState(false);
  const [roleSort, setRoleSort] = useState(null);
  const [showAdvance, setShowAdvance] = useState(false);
  const [searchDataState, setSearchDataState] = useState(null);
  const [searchEnabled, setSearchEnabled] = useState(false);

  useEffect(() => {
    if (!!searchText) {
      setSearchEnabled(true);
    } else {
      setSearchEnabled(false);
    }
  }, [searchText]);

  const triggerApi = async (searchString, reason) => {
    setSearchText(searchString);
    setListTotal(0);
    setRoleSort(null);
    if (showTable) {
      setShowTable(false);
    }
    const searchData = {
      encyclopedia: searchString,
    };
    // console.log("triggerApi -> searchData", searchData);

    setSearchDataState(searchData);
    //?pagesize=100&offset=0
    if (!!searchString.length > 0) {
      reason === "clickSearch" && setFullLoader(true);
      try {
        const pageParam = `?offset=${0}&pagesize=${pageSize}`;
        const res = await SkillService.GetEncyclopediaV3(
          2,
          searchString,
          pageParam
        );
        // setEncyclopediaSize(res?.total);
        // return res.data;
        const filterResult = res?.data || [];
        // console.log("triggerApi -> filterResult", filterResult);
        reason === "clickSearch" && setResponseList(filterResult);
        setListTotal(res?.total || 0);
        return filterResult;
      } catch (errors) {
        console.log(errors);
      } finally {
        setFullLoader(false);
      }
    }
  };

  const handleChange = (item, reason) => {
    if (reason === "select-option") {
      navigate(`/disprzopedia/edit?id=${item.encyclopediaId}`);
    } else if (reason === "clear") {
      setSearchText("");
    } else if (reason === "create-option") {
      setSearchText(item);
      handleSearch();
    }
  };
  const handleSearch = async () => {
    await triggerApi(searchText, "clickSearch");
    setShowTable(true);
  };

  return (
    <div>
      <H2>Manage Disprzopedia</H2>
      <BodyContent>
        <Container>
          <BackNav linkNav="/" />
          <H3 style={{ color: COLORS.TITLE_BLUE }}>Update a Disprzopedia</H3>
          <WhiteBlock style={{ display: "block", paddingBottom: 10 }}>
            <SearchInputBlock show={showAdvance}>
              {/* <Label>Search Role</Label> */}
              <AutoSelectSearch
                width={searchWidth}
                freeSolo={true}
                optionChange={(item, reason) =>
                  !!item ? handleChange(item, reason) : undefined
                }
                handleClearSearch={() => setSearchText("")}
                optionLabel="encyclopedia"
                stateValue={searchText}
                triggerApi={(val, reason) => triggerApi(val, reason)}
                enterResetSearch={(val) => {
                  handleSearch();
                  setSearchText(val);
                }}
                // PopperComponent={(props) => (
                //   <RoleSearchPopper {...props} width={searchWidth} />
                // )}
                renderOption={(option) => (
                  <OptionCutom>
                    <span>{option?.encyclopedia}</span>
                  </OptionCutom>
                )}
              />
              {/* <PrimaryButton onClick={handleSearch} disabled={!searchEnabled}>
                Search
              </PrimaryButton> */}
            </SearchInputBlock>
          </WhiteBlock>
          <WhiteBlock style={{ display: "block" }}>
            <H4>Add a New Disprzopedia</H4>
            <PrimaryButton onClick={() => navigate("/disprzopedia/add")}>
              Add Disprzopedia
            </PrimaryButton>
          </WhiteBlock>
        </Container>
      </BodyContent>
    </div>
  );
}

const WhiteBlock = styled.div`
  background-color: ${COLORS.PRIMARY_WHITE};
  border-radius: 8px;
  box-shadow: 0 1px 3px 0 rgba(97, 122, 163, 0.24);
  display: flex;
  align-items: center;
  padding: 30px;
  margin-bottom: 30px;
`;

const SearchInputBlock = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 25px;
  .MuiFormControl-root {
    border: solid 1px ${COLORS.ASH_BORDER};
    border-radius: 5px;
  }
  button {
    margin: 0px 15px;
  }
  h5 {
    cursor: pointer;
    padding-bottom: 0px;
    text-decoration: underline;
    padding: 15px 25px 15px 0px;
    position: relative;
    svg {
      font-size: 22px;
      position: absolute;
      right: 0px;
      transform: ${({ show }) => (show ? `rotate(180deg)` : `rotate(0deg)`)};
      transition: all 0.3s ease-in-out;
    }
  }
`;

const OptionCutom = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  width: 100%;
`;
