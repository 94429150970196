import React from "react";
import { Popper, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import styled from "styled-components";
import COLORS from "../../../../assets/colors";
import SelectMenu from "../../../Common/FormElements/SelectMenu";
import Label from "../../../Common/FormElements/Label";

export default ({
  data,
  criticalList,
  handleCriticalChange = () => {},
  insideMenu = false,
  closePopper = () => {},
  ...props
}) => {
  // console.log(data, !!data);
  return (
    <Popper {...props} placement="bottom-start" style={{ width: 500 }}>
      <PopperTitle>
        <li>Level</li>
        <li>Description</li>
      </PopperTitle>
      <PopperBlock>{props.children}</PopperBlock>
    </Popper>
  );
};

const PopperDropDown = styled.div`
  background-color: ${COLORS.PRIMARY_WHITE};
  display: flex; // flex
  align-items: center;
  padding: 15px;
  label {
    padding: 0px 15px 0px 0px;
    font-size: 14px;
  }
  .MuiInputBase-root,
  .MuiFormControl-root {
    margin: 0px;
    padding: 0px;
  }
`;

const PopperTitle = styled.ul`
  background-color: ${COLORS.LIGHT_MERON};
  color: ${COLORS.TITLE_BLUE};
  font-size: 14px;
  font-weight: 600;
  display: flex;
  li {
    padding: 10px;
  }
  li:first-child {
    flex: 0 0 30%;
  }
  li:last-child {
    flex: 0 0 70%;
  }
`;

const PopperBlock = styled.div`
  padding: 0px;
  .MuiAutocomplete-option {
    padding: 5px 0px;
  }
  .MuiAutocomplete-paper {
    margin: 0px;
  }
`;
