import React from "react";
import styled from "styled-components";
import Chip from "@material-ui/core/Chip";
import CheckIcon from "@material-ui/icons/Check";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import COLORS from "../../../assets/colors";
import ToolBar from "../ToolBar";

const selectedChipStyle = {
    
};
const checkIconStyle = {
  backgroundColor: '#C6CBEA',
  borderRadius: '50%',
  color: '#4050B5',
  width: '20px',
  height: '20px',
};

const nonSelectedChipStyle = {
  backgroundColor: COLORS.PRIMARY_WHITE,
  color: '#434A79',
  border: '1px #7314AE solid',
};
const radioIconStyle = {
  color: '#7314AE',
  width: '20px',
  height: '20px',
  borderRadius: '50%',
  border: '1px #7314AE solid',
  cursor: 'pointer'
};
const inValidChip = {
  backgroundColor: 'rgba(249, 20, 101, 0.40)',
  // color: COLORS.PRIMARY_WHITE,
  cursor: 'pointer'
}

export default ({
  list,
  optionLabel,
  onDelete,
  size = "medium",
  color = "primary",
  chipClick = () => {},
  isDisabled = false,
  toggleIcon = false,
  toggleSelect = () => {},
  ...props
}) => {

  return (
    <ChipBlcok {...props}>
      {list.map((el, i) => (
        <ToolBar key={i} title={!!el?.isInValidId ? "Currently not part of Disprz wiki database" : el.isDisabled ? "Already added to Knowledge Base" : ""} placement="top" arrow={true} darkMode={true}>
          <Chip
            key={i}
            size={size}
            label={!!optionLabel ? el[optionLabel] : el}
            onDelete={(!!onDelete && !el?.isDisabled && !el?.isInValidId) ? () => onDelete(el) : undefined}
            onClick={() => !el.isDisabled && chipClick(el)}
            color={!el?.isDisabled ? color : ''}
            disabled={el.isDisabled}
            deleteIcon={(toggleIcon && !el.isDisabled) ? el.isSelected ? <CheckIcon style={checkIconStyle} onClick={(e) => {e.stopPropagation()}}/> : <div style={radioIconStyle} onClick={(e) => {e.stopPropagation()}} /> : ''} 
            style={(toggleIcon && !el.isDisabled) ?  el.isSelected ? selectedChipStyle : !!el?.isInValidId ? inValidChip : nonSelectedChipStyle : {pointerEvents: 'auto'}}
          />
        </ToolBar>
      ))}
    </ChipBlcok>
  );
};

const ChipBlcok = styled.div`
  padding-bottom: 15px;
  & > div {
    margin: 0px 10px 10px 0px;
  }
`;
