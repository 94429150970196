import { Images } from "../../../assets/images";
import { PersonaCreationActionTypes } from "../../../enums/PersonaCreationActionType";
import text from "../../../locale.en.json"

export const PersonaCreationOptions = [
    { imageSrc: Images.icons.fileIcon, message: text.persona.createPersonasInBulk, action: PersonaCreationActionTypes.CREATE_BULK },
    { imageSrc: Images.icons.createNewIcon, message: text.persona.createIndividualPersona, action: PersonaCreationActionTypes.CREATE_SINGLE },
    { imageSrc: Images.icons.zipFileIcon, message: text.persona.uploadZipFolderContainingJDs, action: PersonaCreationActionTypes.UPLOAD_ZIP_FILE },
    { imageSrc: Images.icons.excelFileIcon, message: text.persona.excleFileContainingClientSkills, action: PersonaCreationActionTypes.UPLOAD_SKILLS_EXCEL },
    { imageSrc: Images.icons.excelFileNewIcon, message: text.persona.excleFileContainingRoleAndSkills, action: PersonaCreationActionTypes.UPLOAD_ROLES_SKILLS_EXCEL },
    { imageSrc: Images.icons.imageDisabledIcon, message: text.persona.uploadJDFromHrms, disableCard: true, action: PersonaCreationActionTypes.UPLOAD_FROM_HRMS }
];
