import React from "react";
import { Router } from "@reach/router";
import Landing from "../components/Landing";
import AnnotateJobs from "../components/AnnotateJobs";
import JobDetail from "../components/JobDetail";
import NotFound from "../components/NotFound";
import PersonaList from "../components/Personas/PersonaList";
import AddPersona from "../components/Personas/AddPersona";
import RoleSelection from "../components/Personas/RoleSelection";
import ArchitectSkills from "../components/Personas/ArchitectSkills";
import SkillSearch from "../components/Personas/SkillManagement";
import SkillManagement from "../components/Personas/SkillManagement/SkillMaintainForm";
import Users from "../components/Users";
import Clients from "../components/Clients";
import UserManagement from "../components/Users/UserManagement";
import ClientManagement from "../components/Clients/ClientManagement";
import Visual from "../components/Visual";
import Roles from "../components/Roles";
import RoleManagement from "../components/Roles/RoleManagement";
import LeaderManagement from "../components/Roles/RoleManagement/LeaderManagement";
import ComparePersonaIndustryRole from "../components/Personas/ComparePersonaIndustryRole";
import AssignSkills from "../components/Roles/AssignSkills/AssignSkills";
import CareerProgression from "../components/Personas/ArchitectSkills/CareerProgression";
import CareerProgress from "../components/Personas/ArchitectSkills/CareerProgress";
import SkillTree from "../components/SkillTree/SkillTree";
import AuthRoute from "./AuthRoute";
import useAuth from "../Hooks/useAuth";
import { User } from "../store";
import Disprzopedia from "../components/Disprzopedia";
import DisprzopediaManagement from "../components/Disprzopedia/DisprzopediaManagement";
import SunBurstRoleExplorer from "../components/Visual/SunBurstRoleExplorer";
import { isUserRestricted } from "../helpers/CommonHelper";
import { UserType } from "../enums/UserType";

// User_Type === 1 is general user, User_Type === 2 is Super user
// User_Type === 3 is SME user, User_Type === 4 is Skilling Architect

export default () => {
  const { isUserLoggedIn } = useAuth();
  const userDetailsFromCookies = User.getUserDetails();
  const User_Type = userDetailsFromCookies?.User_Type;
  // console.log("userDetails", User_Type, userDetailsFromCookies);
  // const isUserLoggedIn = !!userDetailsFromCookies;

  return (
    <Router>
      <AuthRoute
        restricted={false}
        isAuth={isUserLoggedIn}
        as={Landing}
        path="/"
      />
      <AuthRoute
        restricted={isUserRestricted(User_Type, [UserType.GENERAL_USER, UserType.GENERAL_USER_INTERNAL, UserType.SUPER_USER, UserType.SKILLING_ARCHITECT])}
        isAuth={isUserLoggedIn}
        as={SunBurstRoleExplorer}
        path="/role_explorer"
      />
      {/* <AuthRoute
        restricted={![1, 2, 4].some((el) => el === User_Type)}
        isAuth={isUserLoggedIn}
        as={Visual}
        path="/role_explorer1"
      /> */}

      {/* Users management */}
      <AuthRoute
        restricted={isUserRestricted(User_Type, [UserType.SUPER_USER])}
        isAuth={isUserLoggedIn}
        as={Users}
        path="/users"
      />
      <AuthRoute
        restricted={isUserRestricted(User_Type, [UserType.SUPER_USER])}
        isAuth={isUserLoggedIn}
        as={UserManagement}
        path="/user/:mode"
      />
      <AuthRoute
        restricted={isUserRestricted(User_Type, [UserType.SUPER_USER])}
        isAuth={isUserLoggedIn}
        as={UserManagement}
        path="/user/:mode/:id"
      />
      {/* Users management */}

      {/* Clients management */}
      <AuthRoute
        restricted={isUserRestricted(User_Type, [UserType.SUPER_USER])}
        isAuth={isUserLoggedIn}
        as={Clients}
        path="/clients"
      />
      <AuthRoute
        restricted={isUserRestricted(User_Type, [UserType.SUPER_USER])}
        isAuth={isUserLoggedIn}
        as={ClientManagement}
        path="/clients/:mode"
      />
      <AuthRoute
        restricted={isUserRestricted(User_Type, [UserType.SUPER_USER])}
        isAuth={isUserLoggedIn}
        as={ClientManagement}
        path="/clients/:mode/:id"
      />
      {/* Clients management */}

      {/* Job management */}
      <AuthRoute
        restricted={isUserRestricted(User_Type, [UserType.GENERAL_USER, UserType.GENERAL_USER_INTERNAL, UserType.SUPER_USER, UserType.SME, UserType.SKILLING_ARCHITECT])}
        isAuth={isUserLoggedIn}
        as={AnnotateJobs}
        path="/annotate-jobs"
      />
      <AuthRoute
        restricted={isUserRestricted(User_Type, [UserType.GENERAL_USER, UserType.GENERAL_USER_INTERNAL, UserType.SUPER_USER, UserType.SME, UserType.SKILLING_ARCHITECT])}
        isAuth={isUserLoggedIn}
        as={JobDetail}
        path="/job/:id/:count/:rowNumber"
      />
      {/* Job management */}

      {/* Persona management */}
      <AuthRoute
        restricted={isUserRestricted(User_Type, [UserType.GENERAL_USER, UserType.GENERAL_USER_INTERNAL, UserType.SUPER_USER, UserType.SKILLING_ARCHITECT])}
        isAuth={isUserLoggedIn}
        as={PersonaList}
        path="/personas"
      />
      <AuthRoute
        restricted={isUserRestricted(User_Type, [UserType.GENERAL_USER, UserType.GENERAL_USER_INTERNAL, UserType.SUPER_USER, UserType.SKILLING_ARCHITECT])}
        isAuth={isUserLoggedIn}
        as={AddPersona}
        path="/persona/:mode"
      />
      <AuthRoute
        restricted={isUserRestricted(User_Type, [UserType.GENERAL_USER, UserType.GENERAL_USER_INTERNAL, UserType.SUPER_USER, UserType.SKILLING_ARCHITECT])}
        isAuth={isUserLoggedIn}
        as={RoleSelection}
        path="/personas/role-matching"
      />
      {/* <AuthRoute
        restricted={![1, 2, 4].some((el) => el === User_Type)}
        isAuth={isUserLoggedIn}
        as={AddPersona}
        path="/persona/:mode"
      /> */}
      <AuthRoute
        restricted={isUserRestricted(User_Type, [UserType.GENERAL_USER, UserType.GENERAL_USER_INTERNAL, UserType.SUPER_USER, UserType.SKILLING_ARCHITECT])}
        isAuth={isUserLoggedIn}
        as={AddPersona}
        path="/persona/:mode/:id/:copy"
      />
      {/* Persona management */}

      {/* Skill Architect */}

      {/* <AuthRoute restricted={![1, 2, 3, 4].some((el) => el === User_Type)} isAuth={isUserLoggedIn} as={ArchitectSkills} path="/persona/architect-skills/:name/:id" />
      <AuthRoute restricted={![1, 2, 3, 4].some((el) => el === User_Type)} isAuth={isUserLoggedIn} as={ArchitectSkills} path="/persona/architect-skills" /> */}
      {/* <AuthRoute
        restricted={![1, 2, 3, 4].some((el) => el === User_Type)}
        isAuth={isUserLoggedIn}
        as={CareerProgression}
        path="/career"
      /> */}
      <AuthRoute
        restricted={isUserRestricted(User_Type, [UserType.GENERAL_USER, UserType.GENERAL_USER_INTERNAL, UserType.SUPER_USER, UserType.SKILLING_ARCHITECT])}
        isAuth={isUserLoggedIn}
        as={ArchitectSkills}
        path="/persona/architect-skills/:id"
      />
      <AuthRoute
        restricted={isUserRestricted(User_Type, [UserType.GENERAL_USER, UserType.GENERAL_USER_INTERNAL, UserType.SUPER_USER, UserType.SKILLING_ARCHITECT])}
        isAuth={isUserLoggedIn}
        as={ArchitectSkills}
        path="/personas/:mode/architect-skills/:roleId"
      />
      <AuthRoute
        restricted={isUserRestricted(User_Type, [UserType.GENERAL_USER, UserType.GENERAL_USER_INTERNAL, UserType.SUPER_USER, UserType.SKILLING_ARCHITECT])}
        isAuth={isUserLoggedIn}
        as={RoleSelection}
        path="/personas/role-matching"
      />

      <AuthRoute
        restricted={isUserRestricted(User_Type, [UserType.GENERAL_USER, UserType.GENERAL_USER_INTERNAL, UserType.SUPER_USER, UserType.SKILLING_ARCHITECT])}
        isAuth={isUserLoggedIn}
        as={CareerProgress}
        path="/career-progress/:careerRoleId"
      />
      <AuthRoute
        restricted={isUserRestricted(User_Type, [UserType.GENERAL_USER, UserType.GENERAL_USER_INTERNAL, UserType.SUPER_USER, UserType.SKILLING_ARCHITECT])}
        isAuth={isUserLoggedIn}
        as={ComparePersonaIndustryRole}
        path="/persona_role/compare-skills/:clientId/:personaId/:roleId"
      />
      <AuthRoute
        restricted={isUserRestricted(User_Type, [UserType.GENERAL_USER, UserType.GENERAL_USER_INTERNAL, UserType.SUPER_USER, UserType.SKILLING_ARCHITECT])}
        isAuth={isUserLoggedIn}
        as={ComparePersonaIndustryRole}
        path="/compare-persona"
      />
      <AuthRoute
        restricted={isUserRestricted(User_Type, [UserType.GENERAL_USER, UserType.GENERAL_USER_INTERNAL, UserType.SUPER_USER, UserType.SKILLING_ARCHITECT])}
        isAuth={isUserLoggedIn}
        as={ComparePersonaIndustryRole}
        path="/compare-role"
      />
      <AuthRoute
        restricted={isUserRestricted(User_Type, [UserType.GENERAL_USER, UserType.GENERAL_USER_INTERNAL, UserType.SUPER_USER, UserType.SKILLING_ARCHITECT])}
        isAuth={isUserLoggedIn}
        as={ComparePersonaIndustryRole}
        path="/compare-role/:clientId/:subsectorID/:funcval/:roleId"
      />
      {/* Skill Architect */}

      {/* skill management */}
      <AuthRoute
        restricted={isUserRestricted(User_Type, [UserType.SUPER_USER, UserType.SME, UserType.SKILLING_ARCHITECT])}
        isAuth={isUserLoggedIn}
        as={SkillSearch}
        path="/skill/search"
      />
      <AuthRoute
        restricted={isUserRestricted(User_Type, [UserType.SUPER_USER, UserType.SME, UserType.SKILLING_ARCHITECT])}
        isAuth={isUserLoggedIn}
        as={SkillManagement}
        path="/skill/:mode"
      />
      <AuthRoute
        restricted={isUserRestricted(User_Type, [UserType.SUPER_USER, UserType.SME, UserType.SKILLING_ARCHITECT])}
        isAuth={isUserLoggedIn}
        as={SkillManagement}
        path="/skill/:mode/:id"
      />
      {/* skill management */}

      {/* Manage disprzopedia */}
      <AuthRoute
        restricted={isUserRestricted(User_Type, [UserType.SUPER_USER, UserType.SKILLING_ARCHITECT])}
        isAuth={isUserLoggedIn}
        as={Disprzopedia}
        path="/disprzopedia"
      />
      <AuthRoute
        restricted={isUserRestricted(User_Type, [UserType.SUPER_USER, UserType.SKILLING_ARCHITECT])}
        isAuth={isUserLoggedIn}
        as={DisprzopediaManagement}
        path="/disprzopedia/:mode"
      />
      {/* Manage disprzopedia */}

      {/* Role management */}
      <AuthRoute
        restricted={isUserRestricted(User_Type, [UserType.SUPER_USER, UserType.SME, UserType.SKILLING_ARCHITECT])}
        isAuth={isUserLoggedIn}
        as={Roles}
        path="/role"
      />
      <AuthRoute
        restricted={isUserRestricted(User_Type, [UserType.SUPER_USER, UserType.SME, UserType.SKILLING_ARCHITECT])}
        isAuth={isUserLoggedIn}
        as={RoleManagement}
        path="/role/:mode"
      />
      <AuthRoute
        restricted={isUserRestricted(User_Type, [UserType.SUPER_USER, UserType.SME, UserType.SKILLING_ARCHITECT])}
        isAuth={isUserLoggedIn}
        as={RoleManagement}
        path="/role/:mode/:id"
      />
      <AuthRoute
        restricted={isUserRestricted(User_Type, [UserType.SUPER_USER, UserType.SKILLING_ARCHITECT])}
        isAuth={isUserLoggedIn}
        as={LeaderManagement}
        path="/leader/:id"
      />
      <AuthRoute
        restricted={isUserRestricted(User_Type, [UserType.SUPER_USER, UserType.SME, UserType.SKILLING_ARCHITECT])}
        isAuth={isUserLoggedIn}
        as={AssignSkills}
        path="/role/:roleId/:type/skills" 
      />
      <AuthRoute
        restricted={isUserRestricted(User_Type, [UserType.GENERAL_USER, UserType.GENERAL_USER_INTERNAL, UserType.SUPER_USER, UserType.SME, UserType.SKILLING_ARCHITECT])}
        isAuth={isUserLoggedIn}
        as={AssignSkills}
        path="/role/:roleId/:type/skills/:personaId/:personaGroup"
      />
      {/* Role management */}
      <AuthRoute
        restricted={isUserRestricted(User_Type, [UserType.GENERAL_USER, UserType.GENERAL_USER_INTERNAL, UserType.SUPER_USER, UserType.SME, UserType.SKILLING_ARCHITECT])}
        isAuth={isUserLoggedIn}
        as={AssignSkills}
        path="/add-skills/:type"
      />

      <AuthRoute
        restricted={isUserRestricted(User_Type, [UserType.GENERAL_USER, UserType.GENERAL_USER_INTERNAL, UserType.SUPER_USER, UserType.SKILLING_ARCHITECT])}
        isAuth={isUserLoggedIn}
        as={AssignSkills}
        path="/role/:roleId/recommended_proficiency/skills" 
      /> 
       
      <NotFound default />
    </Router>
  );
};
