import React from "react";
import { Field } from "formik";
import { FormLabel, Radio, FormControlLabel } from "@material-ui/core";
import { RadioGroup } from "formik-material-ui";
import styled from "styled-components";
import COLORS from "../../../assets/colors";

export default ({ name, label, radioList, halfWidth = false, onChange, ...props }) => {
  return (
    <RadioBlock
      name={name}
      label={label}
      component={RadioGroup}
      className="radio-group"
      {...props}
    >
      {!!label && <FormLabel component="legend">{label}</FormLabel>}
      <div className="radio-group-inner">
        {radioList.map((el, i) => (
          <FormControlLabel
            onChange={onChange}
            value={el.value.toString()}
            control={<Radio />}
            label={el.label}
            key={i}
            style={{ width: !!halfWidth ? "45%" : "auto" }}
          />
        ))}
      </div>
    </RadioBlock>
  );
};

const RadioBlock = styled(Field)`
  padding-bottom: 20px;
  .MuiFormLabel-root {
    font-family: inherit;
    color: ${COLORS.TITLE_BLUE};
    display: inline-block;
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 5px;
  }
  .MuiFormControlLabel-root {
    color: ${COLORS.TITLE_BLUE};
    display: inline-flex;
    font-size: 14px;
    font-weight: 600;
  }
  .MuiFormControlLabel-label {
    color: ${COLORS.TITLE_BLUE};
    display: inline-flex;
    font-size: 14px;
    font-weight: 600;
  }
  .radio-group-inner {
    display: flex;
    flex-wrap: no-wrap;
  }
`;
