import React from 'react';
import PropTypes from 'prop-types';

const PaginationInfo = ({ currentPage, pageSize, totalCount }) => {
  const start = (currentPage - 1) * pageSize + 1;
  const end = Math.min(currentPage * pageSize, totalCount);

  return (
    <div className='pagination-info'>
      {`Showing ${start}-${end} of ${totalCount}`}
    </div>
  );
};

PaginationInfo.propTypes = {
  currentPage: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
};

export default PaginationInfo;
