import React, { useState, useEffect } from "react";
import { SortString } from "../../helpers/SortString";
import AutoFilterLocal from "../Common/FormElements/AutoFilterLocal";

export default ({
  value,
  inputVal,
  list = [],
  filterLabel = "subsector",
  placeholder = "",
  handleSearch = () => {},
  valueCarry = false,
  label = "",
  groupLabel = "group",
}) => {
  const [inputValueLocal, setInputValueLocal] = useState(inputVal);

  useEffect(() => {
    setInputValueLocal("");
  }, [inputVal]);

  const subSectorsListIndustry = list
    .filter((el) => el.subsectorTypeId === 1)
    .map((el) => ({ ...el, group: "Industry" }));
  const subSectorsListFunction = list
    .filter((el) => el.subsectorTypeId === 2)
    .map((el) => ({ ...el, group: "Function" }));
  const mergeList = [
    // subSectorsListAny,
    ...SortString(subSectorsListFunction, filterLabel),
    ...SortString(subSectorsListIndustry, filterLabel),
  ];

  return (
    <>
      <AutoFilterLocal
        list={mergeList}
        filterLabel={filterLabel}
        label={label}
        style={{ padding: 0, width: "100%" }}
        optionChange={(val, reason) => handleSearch(val, reason)}
        value={value || undefined}
        inputVal={inputValueLocal}
        inputBoxChange={(val) => setInputValueLocal(val)}
        groupLabel={groupLabel}
        placeholder={placeholder}
        valueCarry={valueCarry}
        PopperComponent={(props) => (
          <div {...props} className="subSectorPopper" />
        )}
        disabled={!mergeList?.length}
      />
    </>
  );
};
