import React, { useState, createContext, useContext } from "react";

export const TableContext = createContext();

export default function TableProvider({ children }) {
  const [sortAndFilterReset, setSortAndFilterReset] = useState(false);
  const [sortAndFilterValues, setSortAndFilterValues] = useState(null);

  const value = {
    sortAndFilterReset,
    setSortAndFilterReset,
    sortAndFilterValues,
    setSortAndFilterValues,
  };

  return (
    <TableContext.Provider value={value}>{children}</TableContext.Provider>
  );
}

export function useTableContext() {
  const context = useContext(TableContext);
  if (context === undefined) {
    throw new Error("useTableContext must be used within a TableProvider");
  }
  return context;
}
