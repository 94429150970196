import { useState } from "react";

const useButtonSelectAll = () => {
  const [popHoverRef, setPopHoverRef] = useState(null);

  const handleClick = (event) => {
    setPopHoverRef(event.currentTarget);
  };

  const handleClose = () => {
    setPopHoverRef(null);
  };

  return {
    popHoverRef,
    handleClick,
    handleClose,
  };
};

export default useButtonSelectAll;
