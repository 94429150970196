import React, { useEffect, useState } from "react";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import { H4, H5, H6 } from "../../../Common/Typography";
import { UIStore } from "../../../../store/store_state";
import ModalPopup from "../../../Common/ModalPopup";

import useAlert from "../../../../Hooks/useAlert";
import CompareColumn from "../PersonaCompare/CompareColumn";
import { AuthService } from "../../../../services/AuthService";
import styled from "styled-components";
import COLORS from "../../../../assets/colors";
import ToolBar from "../../../Common/ToolBar";
import CustomAccordianList from "../../../Common/CustomAccordianList";
import { AccordArrays } from "../../../../helpers/AccordArray";
import useAuth from "../../../../Hooks/useAuth";
import MasterSubSectorListProvider from "../../../../Provider/SubSectorListProvider";
import useSubSectorList from "../../../../Hooks/useSubSectorList";
import MasterFunctionsProvider from "../../../../Provider/functionsListProvider";
import useFunctionsList from "../../../../Hooks/useFunctionsList";
import ButtonIcon from "../../../Common/Buttons/ButtonIcon";
import { Images } from "../../../../assets/images";
import SubSectorMenuListSearch from "../../../utils/SubSectorMenuListSearch";
import FunctionMenuSearch from "../../../utils/FunctionMenuSearch";
import IndustryRoleMenuSearch from "../../../utils/IndustryRoleMenuSearch";
import { isUserAllowed } from "../../../../helpers/CommonHelper";
import { UserType } from "../../../../enums/UserType";

export default (props) => {
  const { userDetails } = useAuth();
  const { User_Type } = userDetails;
  const allowedUserTypes = [UserType.GENERAL_USER, UserType.GENERAL_USER_INTERNAL];
  return (
    <MasterSubSectorListProvider passClientId={ isUserAllowed(User_Type, allowedUserTypes) }>
      <MasterFunctionsProvider passClientId={isUserAllowed(User_Type, allowedUserTypes)}>
        <RoleCompareHeader {...props} UserType={User_Type} />
      </MasterFunctionsProvider>
    </MasterSubSectorListProvider>
  );
};

export const RoleCompareHeader = ({
  initValue,
  stickyVal = false,
  inputWidth,
  UserType,
  ...props
}) => {
  // console.log(props);
  const { subsectorID, funcval } = props;
  // console.log("subsectorID, funcval", subsectorID, funcval);
  const { showAlert } = useAlert();
  const { subSectorsList } = useSubSectorList();
  const { functionsList } = useFunctionsList();
  // console.log("functionsList", functionsList, subSectorsList);
  const selectRoles = UIStore.useState((s) => s.selectRoles);
  // console.log("selectRoles initValue", selectRoles, initValue);

  const [detailPopup, setDetailPopup] = useState(false);
  const [resetList, setResetList] = useState(false);
  const [currentEl, setCurrentEl] = useState(null);
  const [detailArray, setDetailArray] = useState(["", "", "", ""]);

  useEffect(() => {
    if (!!initValue) {
      // console.log(!!subsectorID, !!funcval, !!initValue);
      async function getDetailData() {
        const detailData = await AuthService.getSkillListV3("/" + initValue);
        // console.log("getDetailData -> detailData", detailData);
        const modifyArr = detailArray.map((el, i) =>
          i === 0
            ? {
                ...detailData,
                subsectorId: {
                  subsectorId: detailData?.subsectorId,
                  subsector: detailData?.subsector,
                  subsectorTypeId: detailData?.subsectorTypeId,
                },
                functionId: {
                  functionId: detailData?.functionId,
                  functionName: detailData?.functionName,
                },
                roleId: {
                  roleId: detailData?.roleId,
                  roleName: detailData?.roleName,
                },
              }
            : {
                functionId: {
                  functionId: detailData?.functionId,
                  functionName: detailData?.functionName,
                },
                subsectorId: {
                  subsectorId: detailData?.subsectorId,
                  subsector: detailData?.subsector,
                  subsectorTypeId: detailData?.subsectorTypeId,
                },
              }
        );
        setDetailArray(modifyArr);
        !!detailData &&
          UIStore.update((s) => {
            s.selectRoles = [detailData?.roleId, "", "", ""];
          });
      }
      getDetailData();
    } else if (
      (!!subsectorID && subsectorID !== "Any") ||
      (!!funcval && funcval !== "Any")
    ) {
      const intObj = {
        functionId: !!funcval ? decodeURIComponent(funcval) : "",
        subsectorId: !!subsectorID ? subsectorID : "",
      };
      // console.log("intObj", intObj);
      const modifyArr = detailArray.map((el) => intObj);
      setDetailArray(modifyArr);
    }
  }, []);

  const makedetailArray = async (id, index, type) => {
    // console.log("makedetailArray -> id, index, type", id, index, type);
    let arrDetail = [...detailArray];
    try {
      if (type === "add") {
        const detailData = await AuthService.getSkillListV3("/" + id);
        const updateData = {
          ...detailData,
          functionId: {
            functionId: detailData?.functionId,
            functionName: detailData?.functionName,
          },
          subsectorId: {
            subsectorId: detailData?.subsectorId,
            subsector: detailData?.subsector,
            subsectorTypeId: detailData?.subsectorTypeId,
          },
          roleId: {
            roleId: detailData?.roleId,
            roleName: detailData?.roleName,
          },
        };
        arrDetail.splice(index, 1, updateData);
        // console.log("makedetailArray -> arrDetail", arrDetail);
        setDetailArray(arrDetail);
      } else {
        //type === remove
        arrDetail.splice(index, 1, "");
        setDetailArray(arrDetail);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleView = (i) => {
    setCurrentEl(i);
    setDetailPopup(true);
  };

  const makeRoles = (i, remove = false) => {
    let arrRoleIds = [...selectRoles];
    if (remove) {
      makedetailArray(0, i, "remove");
    }
    arrRoleIds.splice(i, 1, "");
    UIStore.update((s) => {
      s.selectRoles = arrRoleIds;
    });
  };

  const handleSearch = (val, reason, i, key) => {
    console.log(selectRoles, val, reason, i, key);
    //functionId
    if (reason === "select-option") {
      if (key === "subsectorId") {
        setDetailArray((prev) =>
          prev.map((el, idx) => (i === idx ? { [key]: val } : el))
        );
        makeRoles(i);
      } else if (key === "functionId") {
        makeRoles(i);
        setDetailArray((prev) =>
          prev.map((el, idx) =>
            i === idx ? { ...el, [key]: val, roleId: null } : el
          )
        );
      } else if (key === "roleId") {
        let arrRoleIds = [...selectRoles];
        if (!arrRoleIds.some((el) => el === val?.roleId)) {
          setDetailArray((prev) =>
            prev.map((el, idx) => (i === idx ? { ...el, [key]: val } : el))
          );
          // console.log("handleChange -> arrRoleIds", arrRoleIds);
          makedetailArray(
            Number(val.roleId),
            i,
            val?.roleId !== 0 ? "add" : "remove"
          );
          arrRoleIds.splice(i, 1, val?.roleId !== 0 ? val?.roleId : "");
          UIStore.update((s) => {
            s.selectRoles = arrRoleIds;
          });
        }
      }
    } else if (reason === "clear") {
      if (key === "subsectorId") {
        makeRoles(i, true);
      } else if (key === "functionId") {
        setDetailArray((prev) =>
          prev.map((el, idx) =>
            i === idx ? { ...el, [key]: null, roleId: null } : el
          )
        );
      } else if (key === "roleId") {
        let arrRoleIds = [...selectRoles];

        setDetailArray((prev) =>
          prev.map((el, idx) =>
            i === idx ? { ...el, [key]: null, roleName: "" } : el
          )
        );
        // makedetailArray(Number(val.roleId), i, "remove");
        arrRoleIds.splice(i, 1, "");
        UIStore.update((s) => {
          s.selectRoles = arrRoleIds;
        });
      }
    }
  };

  const handleChange = (val, i, key) => {
    // console.log("handleChange -> val, i, key", val, i, key);
    setResetList(false);
    if (key === "roleId") {
      let arrPersonaIds = [...selectRoles];
      if (arrPersonaIds.some((el) => el === val)) {
        showAlert("Industry Role is already selected", "info", 1500);
      } else {
        setDetailArray((prev) =>
          prev.map((el, idx) => (i === idx ? { ...el, [key]: val } : el))
        );
        // console.log("handleChange -> arrPersonaIds", arrPersonaIds);
        makedetailArray(Number(val), i, val !== 0 ? "add" : "remove");
        arrPersonaIds.splice(i, 1, val !== 0 ? val : "");
        UIStore.update((s) => {
          s.selectRoles = arrPersonaIds;
        });
      }
    } else if (key === "subsectorId") {
      setDetailArray((prev) =>
        prev.map((el, idx) => (i === idx ? { [key]: val } : el))
      );

      let arrPersonaIds = [...selectRoles];
      // makedetailArray(0, i, "remove");
      arrPersonaIds.splice(i, 1, "");
      UIStore.update((s) => {
        s.selectRoles = arrPersonaIds;
      });
    } else {
      let arrPersonaIds = [...selectRoles];

      arrPersonaIds.splice(i, 1, "");
      UIStore.update((s) => {
        s.selectRoles = arrPersonaIds;
      });
      setDetailArray((prev) =>
        prev.map((el, idx) =>
          i === idx ? { ...el, [key]: val, roleId: 0 } : el
        )
      );
    }
  };

  const handleResetCol = (i) => {
    // console.log("handleResetCol -> i", i);
    setResetList(true);
    let arrPersonaIds = [...selectRoles];
    makedetailArray(0, i, "remove");
    arrPersonaIds.splice(i, 1, "");
    UIStore.update((s) => {
      s.selectRoles = arrPersonaIds;
    });
  };
  const handleCopyColumn = (item, index) => {
    const { functionId, subsectorId } = item;
    let i = index + 1;
    let arrPersonaIds = [...selectRoles];

    arrPersonaIds.splice(i, 1, "");
    UIStore.update((s) => {
      s.selectRoles = arrPersonaIds;
    });
    setDetailArray((prev) =>
      prev.map((el, idx) =>
        i === idx ? { functionId, roleId: null, subsectorId } : el
      )
    );
  };

  console.log("detailArray", detailArray);

  return (
    <>
      <ComapareHeader
        sticky={stickyVal}
        inputWidth={inputWidth}
        // style={{ position: stickyVal ? "fixed" : "relative" }}
      >
        <CompareColumn style={{ paddingBottom: 0 }}>
          <div style={{ paddingTop: 10 }}>
            <H4>Industry / Function</H4>
            <H4>Department / Track</H4>
            <H4>Industry Role</H4>
            <H4 style={{ padding: "12px 0px 0px 0px" }}>Leadership Role</H4>
          </div>
          {detailArray.map((el, i) => (
            <div key={i}>
              {!!subSectorsList?.length && (
                <>
                  {!!el.subsectorId ? (
                    <SubSectorMenuListSearch
                      list={
                        subSectorsList?.filter(
                          (elem) => elem.subsector !== "Any"
                        ) || []
                      }
                      inputVal={el.subsectorId?.subsector}
                      handleSearch={(val, reason) =>
                        handleSearch(val, reason, i, "subsectorId")
                      }
                      placeholder={el.subsectorId?.subsector}
                      valueCarry
                    />
                  ) : (
                    <SubSectorMenuListSearch
                      placeholder="Search"
                      list={
                        subSectorsList?.filter(
                          (elem) => elem.subsector !== "Any"
                        ) || []
                      }
                      value={el.subsectorId?.subsector || ""}
                      inputVal={el.subsectorId?.subsector || ""}
                      handleSearch={(val, reason) =>
                        handleSearch(val, reason, i, "subsectorId")
                      }
                    />
                  )}
                </>
              )}
              {i < 3 && (
                <ButtonIcon
                  onClick={() => handleCopyColumn(el, i)}
                  icon={
                    <img
                      src={Images.icons.icEqual}
                      alt="equal"
                      style={{ maxWidth: 30 }}
                    />
                  }
                  disabled={!el?.subsectorId?.subsectorId && !el.functionId}
                  style={{
                    position: "absolute",
                    top: 42,
                    right: -24,
                    zIndex: 1,
                  }}
                />
              )}
              {!!functionsList?.length && (
                <>
                  {!!el?.functionId ? (
                    <FunctionMenuSearch
                      list={
                        functionsList?.filter(
                          (elem) => elem.functionName !== "Any"
                        ) || []
                      }
                      inputVal={el?.functionId?.functionName || ""}
                      subSector={el.subsectorId?.subsectorId || ""}
                      subSectorType={el.subsectorId?.subsectorTypeId || ""}
                      handleSearch={(val, reason) =>
                        handleSearch(val, reason, i, "functionId")
                      }
                      placeholder={el.functionId?.functionName}
                      passClientId={UserType === 1}
                      triggerApi={!!el || resetList}
                      labelShow={false}
                      valueCarry
                    />
                  ) : (
                    <FunctionMenuSearch
                      list={
                        functionsList?.filter(
                          (elem) => elem?.functionName !== "Any"
                        ) || []
                      }
                      value={el?.functionId?.functionName || ""}
                      inputVal={el?.functionId?.functionName || ""}
                      subSector={el?.subsectorId?.subsectorId || ""}
                      subSectorType={el?.subsectorId?.subsectorTypeId || ""}
                      handleSearch={(val, reason) =>
                        handleSearch(val, reason, i, "functionId")
                      }
                      placeholder="Search"
                      triggerApi={!!el || resetList}
                      labelShow={false}
                    />
                  )}
                </>
              )}
              <>
                {!!el?.roleId ? (
                  <IndustryRoleMenuSearch
                    inputVal={el?.roleId?.roleName || ""}
                    subSector={el.subsectorId?.subsectorId || ""}
                    funVal={el.functionId?.functionId || ""}
                    handleSearch={(val, reason) =>
                      handleSearch(val, reason, i, "roleId")
                    }
                    placeholder={el.roleId?.roleName}
                    valueCarry
                  />
                ) : (
                  <IndustryRoleMenuSearch
                    value={el?.roleId?.roleName}
                    inputVal={el?.roleId?.roleName}
                    subSector={el.subsectorId?.subsectorId || ""}
                    funVal={el.functionId?.functionId || ""}
                    handleSearch={(val, reason) =>
                      handleSearch(val, reason, i, "roleId")
                    }
                    placeholder="Search"
                    labelShow={false}
                  />
                )}
              </>

              {!!el && el !== "" && !!el.roleId ? (
                <div key={i}>
                  <H5 style={{ padding: `0px` }}>
                    {!!el?.leadershipRole?.leadershipRoleName
                      ? el?.leadershipRole?.leadershipRoleName
                      : `N/A`}
                  </H5>
                </div>
              ) : (
                <div key={i}>&nbsp;</div>
              )}
              <div className="compare-label">
                Industry Role {i + 1}{" "}
                {!!el && el !== "" && !!el.roleId ? (
                  <div className="compare-reset">
                    <ToolBar title="Reset">
                      <RotateLeftIcon onClick={() => handleResetCol(i)} />
                    </ToolBar>
                  </div>
                ) : (
                  <div key={i}>&nbsp;</div>
                )}
              </div>
            </div>
          ))}
        </CompareColumn>
        {!!detailPopup && (
          <ModalPopup
            open={detailPopup}
            closePopup={() => {
              setDetailPopup(false);
              setCurrentEl(null);
            }}
            maxWidth="md"
            title=""
            center={true}
            className="detailPopup"
          >
            <RolePopupContent>
              <H5>Roles as per Industry</H5>
              <div
                dangerouslySetInnerHTML={{
                  __html: detailArray[currentEl]?.roleDescription.replace(
                    /\|/g,
                    "<br /><br />"
                  ),
                }}
              />
              <div className="border-btm">&nbsp;</div>
              <H5>Critical Work Functions and Tasks</H5>
              <CustomAccordianList
                list={AccordArrays(
                  detailArray[currentEl]?.roleandResponsibilities
                )}
                initOpen={null}
              />
            </RolePopupContent>
          </ModalPopup>
        )}
      </ComapareHeader>
      <CompareColumn>
        <div style={{ marginLeft: 28 }}></div>
        {detailArray.map((el, i) => (
          <div key={i} className="detail-col">
            {!!el && el !== "" && !!el?.roleId && (
              <H6 onClick={() => handleView(i)} key={i}>
                View Roles & Responsibilities
              </H6>
            )}
          </div>
        ))}
      </CompareColumn>
    </>
  );
};

const ComapareHeader = styled.div`
  box-shadow: 0 1px 3px 0 rgba(97, 122, 163, 0.24);
  background-color: ${COLORS.DARK_BLUE_HEADER};
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  color: ${COLORS.PRIMARY_WHITE};
  padding: 30px 28px 0px;
  position: sticky;
  top: 16px;
  z-index: 1;
  &:before {
    background-color: ${COLORS.BODY_ASH};
    content: "";
    height: 16px;
    width: 100%;
    position: absolute;
    left: 0;
    top: -16px;
  }
  h4,
  h6,
  h5 {
    color: ${COLORS.PRIMARY_WHITE};
  }
  h4 {
    padding: 15px 0px;
  }
  h5 {
    font-weight: normal;
  }
  .MuiFormControl-root {
    padding: 0px 0px 15px 0px !important;
  }
`;

const RolePopupContent = styled.div`
  h5 {
    text-transform: uppercase;
  }
  .border-btm {
    border-bottom: solid 1px ${COLORS.ASH_BORDER};
    margin-bottom: 30px;
  }
`;

const IndustryInfo = styled.div`
  display: flex;
  padding: 15px 15px 0px 15px;
  width: 250px;
  &:last-child {
    padding-bottom: 15px;
  }
  h5,
  small {
    line-height: 18px;
    padding-bottom: 0px;
    width: 50%;
  }
`;
