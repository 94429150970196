import axios from "axios";
import { Common, User } from "../store";
import { navigate } from "@reach/router";
import ApiConfig from "./config";

/**
 * Create an Axios Client with defaults
 */
const axiosClient = axios.create({
  baseURL: ApiConfig.baseUrl,
  timeout: 30000,
  withCredentials: true,
});

/**
 * Request Wrapper with default success/error actions
 */
const Api = async (
  config = { method: "GET", data: {}, url: "" },
  shouldAppendToken = true
) => {
  // Success
  const onSuccess = (response) => {
    return Promise.resolve(response.data);
  };

  // Error  || error.response?.status === 403
  const onError = (error) => {
    // console.log("onError -> error", error.messages, error.response);
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      /**
       * If 401
       * Clear the token from offline store
       * and navigate to Initial Stack using Navigation Service
       */
      // if (error?.response?.status === 401) {
      //   Common.clearToken();
      //   User.clearAll();
      // }
      // setTimeout(
      //   () =>
      //     navigate("/", { state: { message: error?.response.data.message } }),
      //   100
      // );
    }

    if (error?.response) {
      // Request was made but server responded with something
      // other than 2xx
      return Promise.reject(error?.response?.data);
    } else {
      // Something else happened while setting up the request
      // triggered the error?
      return Promise.reject(error?.message);
    }
  };

  // Append headers
  let headers = {
    Accept: "application/json",
  };

  // Append auth token
  if (shouldAppendToken) {
    try {
      // const token = await Common.getToken();
      const token = process.env.REACT_APP_API_KEY;
      headers["Portal"] = "Common";
      headers["Api-Token"] = token;
    } catch (error) {
      // Token is not found
      return Promise.reject(error.message);
    }
  }

  // Set headers
  axiosClient.defaults.headers = headers;

  return axiosClient(config).then(onSuccess).catch(onError);
};

export default Api;
