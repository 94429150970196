import React from "react";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ToolBar from "../../Common/ToolBar";

export const Skill_Selection_Table = [
    {
        colKey: "showError",
        colValue: "",
        colType: "column_render",
        RenderColumn: (data) => {
            return !!data?.showError && (
                <ToolBar title="Not Selected" arrow={true}>
                    <ErrorOutlineIcon style={{ marginTop: '6px' }}/>
                </ToolBar>
            );
        },
    },
    {
        colKey: "clientSkill",
        colValue: "Client Skill",
        sortable: true,
    },
    {
        colKey: "skillMatch1",
        colId: "skillId1",
        highLighColKey: "skillScore1",
        colValue: "Skill Match 1",
        colType: "chip",
        width: 300
    },
    {
        colKey: "skillMatch2",
        colId : "skillId2", 
        highLighColKey: "skillScore2",
        colValue: "Skill Match 2",
        colType: "chip",
        width: 300
    },
    {
        colKey: "skillMatch3",
        colId : "skillId3", 
        highLighColKey: "skillScore3",
        colValue: "Skill Match 3",
        colType: "chip",
        width: 300
    },
    {
        colKey: "searchSkills",
        colValue: "Search Skills",
        colType: "autoSelect",
        noOptionsText: "No Skills Available",
        width: 300
    },
    {
        colType: "selectedSkill",
        colKey: "selectedSkill",
        colId: "selectedSkillId",
        colValue: "Disprz Skill Selected",
    },
    {
        colType: "levelSelect",
        colKey: "selectedLevel",
        colValue: "Target Proficiency",
        editable: true,
        width: 300
    },
    {
        colKey: "buttonDelete",
        colValue: "",
        dynamic: false,
        toIncludeWhiteIcon: true
    }
];