import API from "../api/manager";
import config from "../api/config";
import APIV2 from "../api/managerV2";

const getRoleExplorerData = (userId) => {
  return API(
    {
      method: "GET",
      url: config.urls.auth.roleExplorerData + userId,
    },
    true
  );
};

const getRoleExplorerDataV3 = (userId) => {
  return APIV2(
    {
      method: "GET",
      url: config.urls.auth.roleExplorerDataV3 + userId,
    },
    true
  );
};

const addUpdateRoleSkill = (roleID, type, skillID, data, method) => {
  return API(
    {
      method,
      url: config.urls.auth.industryRoles + roleID + type + skillID,
      data,
    },
    true
  );
};

const addUpdateRoleSkillV3 = (roleID, type, skillID, data, method) => {
  return APIV2(
    {
      method,
      url: config.urls.auth.industryRolesV3 + roleID + type + skillID,
      data,
    },
    true
  );
};

const deleteRoleSkill = (roleID, type, skillID) => {
  return API(
    {
      method: "DELETE",
      url: config.urls.auth.industryRoles + roleID + type + skillID,
    },
    true
  );
};

const deleteRoleSkillV3 = (roleID, type, skillID) => {
  return APIV2(
    {
      method: "DELETE",
      url: config.urls.auth.industryRolesV3 + roleID + type + skillID,
    },
    true
  );
};

const searchRole = (data, params = "") => {
  return API(
    {
      method: "POST",
      url: config.urls.auth.getRoles + params,
      data,
    },
    true
  );
};

const searchRoleV3 = (data, params = "") => {
  return APIV2(
    {
      method: "POST",
      url: config.urls.auth.getRoleSearchV3 + params,
      data,
    },
    true
  );
};

const addRole = (data) => {
  return API(
    {
      method: "POST",
      url: config.urls.auth.industryRoles,
      data,
    },
    true
  );
};

const addRoleV3 = (data) => {
  return APIV2(
    {
      method: "POST",
      url: config.urls.auth.industryRolesV3,
      data,
    },
    true
  );
};

const addFunctionIdV3 = (data) => {
  return APIV2(
    {
      method: "POST",
      url: config.urls.auth.functionCreateV3,
      data,
    },
    true
  );
};

const getRole = (roleID) => {
  return API(
    {
      method: "GET",
      url: config.urls.auth.industryRoles + roleID,
    },
    true
  );
};

const getRoleV3 = (roleID) => {
  return APIV2(
    {
      method: "GET",
      url: config.urls.auth.industryRolesV3 + roleID,
    },
    true
  );
};

const updateRole = (roleID, data) => {
  return API(
    {
      method: "PATCH",
      url: config.urls.auth.industryRoles + roleID,
      data,
    },
    true
  );
};

const updateRoleV3 = (roleID, data) => {
  return APIV2(
    {
      method: "PATCH",
      url: config.urls.auth.industryRolesV3 + roleID,
      data,
    },
    true
  );
};

const deleteRole = (roleID) => {
  return API(
    {
      method: "DELETE",
      url: config.urls.auth.industryRoles + roleID,
    },
    true
  );
};

const deleteRoleV3 = (roleID) => {
  return APIV2(
    {
      method: "DELETE",
      url: config.urls.auth.industryRolesV3 + roleID,
    },
    true
  );
};

const getOccupations = (subSectorIds) => {
  return API(
    {
      method: "GET",
      url: config.urls.auth.occupations + subSectorIds,
    },
    true
  );
};

const getOccupationsV3 = (subSectorIds) => {
  return APIV2(
    {
      method: "GET",
      url: config.urls.auth.occupationsV3 + subSectorIds,
    },
    true
  );
};

const updateRoleSkill = (roleID, type, skillID, data) => {
  return API(
    {
      method: "PATCH",
      url: config.urls.auth.industryRoles + roleID + type + skillID,
      data,
    },
    true
  );
};

const updateRoleSkillV3 = (roleID, type, skillID, data) => {
  return APIV2(
    {
      method: "PATCH",
      url: config.urls.auth.industryRolesV3 + roleID + type + skillID,
      data,
    },
    true
  );
};

const updateLeaderSkill = (roleID, type, skillID, data) => {
  return API(
    {
      method: "PATCH",
      url: config.urls.auth.leadershipRoles + roleID + type + skillID,
      data,
    },
    true
  );
};

const updateLeaderSkillV3 = (roleID, type, proficiencyId, data) => {
  return APIV2(
    {
      method: "PATCH",
      url: config.urls.auth.leadershipRolesV3 + roleID + type + proficiencyId,
      data,
    },
    true
  );
};

const deleteLeaderSkill = (roleID, type, skillID) => {
  return API(
    {
      method: "DELETE",
      url: config.urls.auth.leadershipRoles + roleID + type + skillID,
    },
    true
  );
};

const deleteLeaderSkillV3 = (roleID, type, proficiencyId) => {
  return APIV2(
    {
      method: "DELETE",
      url: config.urls.auth.leadershipRolesV3 + roleID + type + proficiencyId,
    },
    true
  );
};

const getCareerList = () => {
  return API(
    {
      method: "GET",
      url: config.urls.auth.careerList,
    },
    true
  );
};

const getCareerListTest = () => {
  return API(
    {
      method: "GET",
      url: config.urls.auth.careerListTest,
    },
    true
  );
};

const getCareerData = (id) => {
  return API(
    {
      method: "GET",
      url: config.urls.auth.careerList + id,
    },
    true
  );
};

const getCareerDataV3 = (id) => {
  return APIV2(
    {
      method: "GET",
      url: config.urls.auth.careerListV3 + id,
    },
    true
  );
};

const postImportClientRolesV3 = (data, clientId, isClientSkillsImport) => {
  return APIV2(
    {
      method: "POST",
      url: `${config.urls.auth.importClientRolesV3}?clientId=${clientId}&isClientSkillsImport=${isClientSkillsImport}`,
      data,
    },
    true
  );
};

const discardClientRoleMatchingV3 = (clientId, importTokenId) => {
  return APIV2(
    {
      method: "POST",
      url: `${config.urls.auth.discardRoleMatching}?clientId=${clientId}&importTokenId=${importTokenId}`,
    },
    true
  );
}

const partialSaveClientRoles = (data, clientId, importTokenId) => {
  return APIV2(
    {
      method: "POST",
      url: `${config.urls.auth.partialSaveClientRoles}?clientId=${clientId}&importTokenId=${importTokenId}`,
      data,
    },
    true
  );
};

const saveClientRoles = (data) => {
  return APIV2(
    {
      method: "POST",
      url: config.urls.auth.saveClientRoles,
      data,
    },
    true
  );
};

const postImportClientRolesStatus = (clientId) => {
  return APIV2(
    {
      method: "POST",
      url: config.urls.auth.importClientRolesStatus + clientId,
    },
    true
  );
};

const getImportPersonaDetails = (clientId) => {
  return APIV2(
    {
      method: "GET",
      url: `${config.urls.auth.importPersonaDetails}?clientId=${clientId}`,
    },
    true
  );
};

const verifyBulkImportEnabled = (clientId) => {
  return APIV2(
    {
      method: "GET",
      url: config.urls.auth.checkBulkImportEnabled + clientId,
    },
    true
  );
};

const getImportTemplateUrl = () => {
  return APIV2(
    {
      method: "GET",
      url: config.urls.auth.importTemplateUrl,
    },
    true
  );
};

const GetAiRoleDescriptionSuggestionV3 = (data) => {
  return APIV2(
    {
      method: "POST",
      url: config.urls.auth.roleDescriptionSuggestionV3,
      data
    },
    true
  )
}

const GetAiWorkFunctionsAndSuggestionV3 = (data) => {
  return APIV2(
    {
      method: "POST",
      url: config.urls.auth.workFunctionsAndTasksSuggetionV3,
      data
    },
    true
  )
}

const GetExistingSkillIndustryRoleV3 = (roleId) => {
  return APIV2(
    {
      method: "POST",
      url: `${config.urls.auth.aiIndustryRolesSuggestionV3}/${roleId}/suggestion/skills`
    },
    true
  )
}

const GetNonExistingSkillIndustryRoleV3 = (roleId) => {
  return APIV2(
    {
      method: "POST",
      url: `${config.urls.auth.aiIndustryRolesSuggestionV3}/${roleId}/suggestion/non-existing-skills`
    },
    true
  )
}

const GetPersonasMappedToRoleV3 = (roleId) => {
  return APIV2(
    {
      method: "GET",
      url: `${config.urls.auth.industryRolesV3}/${roleId}/personas`
    },
    true
  )
}

const GetRoleSelectionDataExportExcelStatusV3 = (clientId, exportTokenId, personaName = "") => {
  var queryParam = {
    clientId: clientId,
    exportTokenId: exportTokenId,
    personaName: personaName
  };
  var queryString = new URLSearchParams(queryParam).toString();
  return APIV2(
    {
      method: "GET",
      url: `${config.urls.auth.roleSelectionExportStatusV3}?${queryString}`
    },
    true
  )
}

const UpdateRoleSelectionDataExportExcelStatusV3 = (clientId, data, personaName = "") => {
  var queryParam = {
    clientId: clientId,
    personaName: personaName
  };
  var queryString = new URLSearchParams(queryParam).toString();
  return APIV2(
    {
      method: "POST",
      url: `${config.urls.auth.roleSelectionExportStatusV3}?${queryString}`,
      data
    },
    true
  )
}

const ExportRoleSelectionDataV3 = (clientId, importTokenId, personaName = "") => {
  var queryParam = {
    clientId: clientId,
    importTokenId: importTokenId,
    personaName: personaName
  };
  var queryString = new URLSearchParams(queryParam).toString();
  return APIV2(
    {
      method: "POST",
      url: `${config.urls.auth.roleSelectionExportV3}?${queryString}`
    },
    true
  )
}

const GetSkillsForRoleIdV3 = (roleId, includeUnPublished, clientId) => {
  var queryParam = {
    includeUnPublished: includeUnPublished,
    clientId: clientId
  };
  var queryString = new URLSearchParams(queryParam).toString();
  return APIV2(
    {
      method: "GET",
      url: `${config.urls.auth.industryRolesV3}/${roleId}/skills?${queryString}`
    },
    true
  )
}

const GetRoleSuggestionsInBulkV3 = (data) => {
  return APIV2(
    {
      method: "POST",
      url: config.urls.auth.bulkRoleSuggestionsV3,
      data
    },
    true
  )
}

export const RoleServices = {
  getRoleExplorerData,
  getRoleExplorerDataV3,
  addUpdateRoleSkill,
  addUpdateRoleSkillV3,
  deleteRoleSkill,
  deleteRoleSkillV3,
  searchRole,
  searchRoleV3,
  addRole,
  addRoleV3,
  addFunctionIdV3,
  getRole,
  getRoleV3,
  updateRole,
  updateRoleV3,
  deleteRole,
  deleteRoleV3,
  getOccupations,
  getOccupationsV3,
  updateRoleSkill,
  updateRoleSkillV3,
  updateLeaderSkill,
  updateLeaderSkillV3,
  deleteLeaderSkill,
  deleteLeaderSkillV3,
  getCareerList,
  getCareerListTest,
  getCareerData,
  getCareerDataV3,
  postImportClientRolesV3,
  postImportClientRolesStatus,
  getImportPersonaDetails,
  partialSaveClientRoles,
  saveClientRoles,
  verifyBulkImportEnabled,
  getImportTemplateUrl,
  GetAiRoleDescriptionSuggestionV3,
  GetAiWorkFunctionsAndSuggestionV3,
  GetExistingSkillIndustryRoleV3,
  GetNonExistingSkillIndustryRoleV3,
  discardClientRoleMatchingV3,
  GetPersonasMappedToRoleV3,
  GetRoleSelectionDataExportExcelStatusV3,
  UpdateRoleSelectionDataExportExcelStatusV3,
  ExportRoleSelectionDataV3,
  GetSkillsForRoleIdV3,
  GetRoleSuggestionsInBulkV3
};
