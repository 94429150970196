import { HubConnectionBuilder, HttpTransportType  } from "@microsoft/signalr";

/**
 * Service class for managing SignalR connections.
 */
class SignalRService {
  /**
   * Creates an instance of SignalRService.
   * @param {string} hubUrl - The URL of the SignalR hub.
   */
  constructor(hubUrl) {
    // Create a new SignalR connection using the provided hub URL
    this.connection = new HubConnectionBuilder()
      .withUrl(hubUrl, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets
      })
      .withAutomaticReconnect()
      .build();

      this.startConnection();
      

      this.connection.onreconnecting((error) => {
        console.log("SignalR connection is reconnecting...", error);
      });
  
      this.connection.onreconnected((connectionId) => {
        console.log("SignalR connection is reconnected. Connection ID:", connectionId);
      });
  
      this.connection.onclose((error) => {
        console.log("SignalR connection is closed. Error:", error);
      });

  }

  /**
   * Starts the SignalR connection.
   */
  startConnection = () => {
    this.connection
      .start()
      .then(() => console.log("SignalR Connected"))
      .catch((err) => {
        console.log("SignalR Connection Error: ", err);

      });
  };

  /**
   * Adds an event listener to the SignalR connection.
   * @param {function} callback - The callback function to be executed when an event is received.
   * @param {string} eventName - The name of the event to listen for.
   */
  addEventListener = (callback, eventName) => {
    // Listen for the "ReceiveJDParserStatus" event from the SignalR connection
    this.connection.on(eventName, (data) => {
      console.log('data', data);
      // Execute the callback function with the received data
      callback(data);
    });

  };

  stop = () => {
    this.connection.stop();
  }

}

export default SignalRService;
