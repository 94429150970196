import React, { useEffect, useState } from "react";
import { SortString } from "../../helpers/SortString";
import { IndustryRoleListStore, UIStore } from "../../store/store_state";
import useRoles from "../../Hooks/useRoles";
import { RoleServices } from "../../services/RoleServices";
import AutoFilterLocal from "../Common/FormElements/AutoFilterLocal";
import useAlert from "../../Hooks/useAlert";

export default ({
  label = "",
  subSector = "",
  funVal = "",
  passClientId = false,
  value,
  inputVal,
  filterLabel = "roleName",
  placeholder = "",
  handleSearch = () => {},
  valueCarry = false,
  groupLabel = "",
}) => {
  const { clientName } = useRoles();
  const { showAlert } = useAlert();

  const selectRoles = UIStore.useState((s) => s.selectRoles);

  const [inputValueLocal, setInputValueLocal] = useState(
    !!subSector ? inputVal : ""
  );
  const [inputValue, setInputValue] = useState(!!subSector ? value : "");
  const [industryRoleList, setIndustryRoleList] = useState([]);
  const [industryRoleListLoading, setIndustryRoleListLoading] = useState(false);

  useEffect(() => {
    setIndustryRoleList([]);
    setInputValueLocal("");
    const initData = {
      roleName: "",
      subSectorIds: subSector !== "" ? [subSector] : [],
      functionIds: funVal !== "" ? [funVal] : [],
      occupations: [],
      roleStatuses: [4],
      // framework: [],
      // sector: [],
    };

    const postData = passClientId
      ? { ...initData, clientId: Number(clientName) }
      : initData;

    const getIndustryRoles = async () => {
      // console.log(subSector, funVal);
      setIndustryRoleListLoading(true);
      try {
        const res = await RoleServices.searchRoleV3(postData);
        // console.log(initData, res?.roles);
        // const sorted = SortString(res?.roles, "roleName");
        // anyVal && sorted.unshift({ roleId: 0, roleName: "Any" });
        setIndustryRoleList(SortString(res?.roles, "roleName"));
        IndustryRoleListStore.update((s) => {
          s.IndustryRoleList = SortString(res?.roles, "roleName");
        });

        // console.log(value, res?.roles);
      } catch (error) {
        console.log(error);
      } finally {
        setIndustryRoleListLoading(false);
      }
    };

    if (!!subSector || !!funVal) {
      getIndustryRoles();
    } else {
      // console.log("both null");

      IndustryRoleListStore.update((s) => {
        s.IndustryRoleList = [];
      });
    }

    return () => {
      // setIndustryRoleList([]);
    };
  }, [subSector, funVal]);

  console.log("role", value, inputValueLocal, placeholder, valueCarry);
  return (
    <>
      <AutoFilterLocal
        list={industryRoleList || []}
        filterLabel={filterLabel}
        label={label}
        style={{ padding: 0, width: "100%" }}
        optionChange={(val, reason) => {
          let arrRoleIds = [...selectRoles];
          if (arrRoleIds.some((el) => el === val?.roleId)) {
            showAlert("Industry Role is already selected", "info", 1500);
            setInputValueLocal("");
            setInputValue("");
          } else {
            handleSearch(val, reason);
          }
        }}
        value={inputValue}
        inputVal={inputValueLocal}
        inputBoxChange={(val) => setInputValueLocal(val)}
        groupLabel={groupLabel}
        placeholder={placeholder}
        valueCarry={valueCarry}
        PopperComponent={(props) => (
          <div {...props} className="subSectorPopper" />
        )}
        disabled={industryRoleListLoading || !industryRoleList?.length}
      />
    </>
  );
};
