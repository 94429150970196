// *https://www.registers.service.gov.uk/registers/country/use-the-api*
import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core";
import COLORS from "../../../assets/colors";
import Label from "./Label";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-multiline": {
      // borderRadius: "0px",
      padding: 0,
    },
    "& .MuiOutlinedInput-input": {
      color: COLORS.TEXT_BLUE,
      fontSize: 12,
      padding: 12,
    },
  },
  autocomp: {
    "& .MuiAutocomplete-inputRoot": {
      padding: 3,
    },
    "&  .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
}));

export default ({
  renderOption,
  PopperComponent,
  optionLabel,
  optionChange,
  stateValue,
  placeholder = "",
  valueLabel,
  triggerApi,
  freeSolo = false,
  width = 300,
  enterResetSearch = () => {},
  handleClearSearch = () => {},
  loading = false,
  onFocus = () => {},
  onBlur = () => {},
  label = "",
  listSize = 0,
}) => {
  const classes = useStyles();
  // console.log("stateValue", stateValue)

  const [open, setOpen] = React.useState(false);
  const [pageSize, setPageSize] = React.useState(30);
  const [options, setOptions] = React.useState([]);
  // const loading = open && !!options && options.length === 0;
  // console.log(loading);
  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      try {
        const response = await triggerApi();
        if (active && !!response) {
          console.log("onBlur -> response", response);
          setOptions(response);
        }
      } catch (error) {
        console.log(error);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);
  // console.log(loading, open, options);
  return (
    <>
      {!!label && <Label>{label}</Label>}
      <Autocomplete
        style={{ width }}
        className={classes.autocomp}
        freeSolo={freeSolo}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        renderOption={(option) => renderOption(option)}
        PopperComponent={
          !!PopperComponent && !!options.length ? PopperComponent : undefined
        }
        getOptionSelected={(option, value) =>
          option[optionLabel] === value[optionLabel]
        }
        onChange={(event, newValue, reason) => {
          optionChange(newValue, reason);
          console.log(newValue, reason);
        }}
        getOptionLabel={(option) => option[optionLabel]}
        options={options}
        // defaultValue={[]}
        loading={false}
        ListboxProps={{
          onScroll: async (event) => {
            const listboxNode = event.currentTarget;
            if (
              listboxNode.scrollTop + listboxNode.clientHeight ===
                listboxNode.scrollHeight &&
              listSize > pageSize
            ) {
              // console.log("ko");
              setPageSize(pageSize + 30);
              const res = await triggerApi(stateValue, "input", pageSize + 30);
              setOptions(!!res ? res : []);
            }
          },
        }}
        onInputChange={async (event, value, reason) => {
          setPageSize(30);
          if (!!event)
            try {
              if (reason === "input") {
                const res = await triggerApi(value, reason, pageSize);
                setOptions(!!res ? res : []);
                console.log("onBlur -> res", res);
                if (res?.length === 0 || !!res === false) setOpen(false);
              } else if (reason === "reset") {
                enterResetSearch(value);
              } else if (reason === "clear") {
                handleClearSearch("");
              }
              // console.log(event, value, reason);
            } catch (error) {
              console.log(error);
            }
        }}
        inputValue={stateValue}
        onFocus={onFocus}
        onBlur={onBlur}
        // value={stateValue}
        renderInput={(params) => (
          <TextField
            {...params}
            className={classes.root}
            placeholder={placeholder}
            label=""
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={15} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </>
  );
};
