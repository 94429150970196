import React, { useState} from "react";
import { Link } from "@reach/router";
import styled from "styled-components";
import ModalPopup from "../Common/ModalPopup";
import COLORS from "../../assets/colors";
import { Images } from "../../assets/images";
import ToolBar from "../Common/ToolBar";
import { SecondaryButton } from "../Common/Buttons/SecondaryButton";

export default ({ pageLink, imgSrc, list, clickable = false, listClick }) => {

    const [showVideoPopup, setShowVideoPopup] = useState(false);
    const [videoSource, setVideoSource] = useState('');
    const [videoTitle, setVideoTitle] = useState('');

    const VideoModal = () => (
        <ModalPopup
            open={showVideoPopup}
            maxWidth="lg"
            title={videoTitle}
            center={true}
            closePopup={closePopup}
        >
            <video src={videoSource} width="100%" height="100%" controls />
        </ModalPopup>
    );

    const showPopup = (e, data) => {
        e.preventDefault();
        e.stopPropagation();
        setVideoSource(data.videoSrc);
        setVideoTitle(data.videoTitle);
        setShowVideoPopup(true);
    };

    const closePopup = () => {
        setShowVideoPopup(false);
        setVideoSource('');
        setVideoTitle('');
    };

  return (
    <LandinBlock>
      {clickable ? (
        // <img src={imgSrc} alt="icon"  />
        <div
          dangerouslySetInnerHTML={{
            __html: imgSrc,
          }}
          onClick={() => listClick(pageLink)}
        />
      ) : (
        <Link to={pageLink}>
          {/* <img src={imgSrc} alt="icon" /> */}
          <div
            dangerouslySetInnerHTML={{
              __html: imgSrc,
            }}
          />
        </Link>
      )}
      <ul>
        {list.map((el, i) => (
          <li
            key={i}
            onClick={clickable ? () => listClick(pageLink) : undefined}
          >
            {clickable ? (
              <span>{el.linkText}</span>
            ) : (
              <Link to={el.link}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: el.linkText,
                  }}
                />
              </Link>
            )}
              {el.showVideoIcon === true ?
                  <ToolBar title={el.tooltipTitle} arrow={true}>
                      <div className={"video-trigger"}>
                          <SecondaryButton onClick={(e) => showPopup(e, el)}>
                              <img
                                  src={Images.icons.videoIcon}
                                  alt="persona video"
                              />
                          </SecondaryButton>
                      </div>
                  </ToolBar> : ""}
          </li>
        ))}
      </ul>
      <VideoModal />
    </LandinBlock>
  );
};

const LandinBlock = styled.div`
/* display: none; */
    width: 180px;
    padding: 12px;
    position: relative;
    &:before {
      content: "";
      background-color: ${COLORS.PRIMARY_WHITE};
      height: 92px;
      width: 1px;
      position: absolute;
      right: 0px;
      top: 0px;
    }
    &:last-child {
      &:before {
        display: none;
      }
    }
  }
  

  img, svg {
    margin-bottom: 10px;
    cursor: pointer;
  }

  svg {
    height: 65px;
    width: 65px;
    path {
      fill: ${COLORS.ICON_PURPLE};
    }
  }

  ul {
    li {
      cursor: pointer;
      color: ${COLORS.PRIMARY_WHITE};
      a {
        color: ${COLORS.PRIMARY_WHITE};
        display: block;
        font-weight: 400;
        line-height: 20px;
        padding: 10px 0px;
        position: relative;
        transition: all 0.2s linear;
      }
      span {
        display: inline-block;
        padding: 10px 0px;
      }
      .video-trigger {
        button {
            padding: 0;
            width: auto;
            height: auto !important;
            min-width: auto;
            min-height: auto !important;
            border: 0;
        }
        span {
            padding: 0;
            line-height: 0;
        }
        img {
            margin: 0;
        }
      }
    }
  
`;
