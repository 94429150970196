import React, { useEffect, useState } from "react";
import { navigate } from "@reach/router";
import styled from "styled-components";

import Layout from "../Layout";
import { H2 } from "../Common/Typography";
import { BodyContent, Container } from "../Common/GridSystem";
import BackNav from "../Common/BackNav";
import { AuthService } from "../../services/AuthService";
import CustomizedTables from "../Common/CustomizedTables";
import user_table from "./user_table";
import ModalPopup from "../Common/ModalPopup";
import MenuOption from "../Common/MenuOption";
import useFullLoader from "../../Hooks/useFullLoader";
import ConfirmationButton from "../Common/ConfirmationButton";
import PopHover from "../Common/PopModal/PopHover";
import { PrimaryButton } from "../Common/Buttons/PrimaryButton";
import useAlert from "../../Hooks/useAlert";
import { SortString } from "../../helpers/SortString";
import {
  FilterSortTableCondition,
  FilterTableRow,
} from "../../helpers/FilterTableRow";
import RowCount from "../Common/Others/RowCount";
import { UserTypeMenu } from "../utils";
import { useTableContext } from "../../Provider/TableProvider";

export default () => {
  const { setFullLoader } = useFullLoader();
  const { showAlert } = useAlert();
  const { sortAndFilterValues, setSortAndFilterReset } = useTableContext();
  const { getList } = FilterSortTableCondition();

  const [userList, setUserList] = useState(null);
  const [userListCopy, setUserListCopy] = useState(null);

  const [currentItem, setCurrentItem] = useState(null);
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [confirmTitle, setConfirmTitle] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);

  const getUserList = async () => {
    setFullLoader(true);
    try {
      const clients = await AuthService.getAllClientsV3();
      const res = await AuthService.getUsersV3();
      const list = SortString(
        res.map((el) => ({
          ...el,
          userClient:
            el.clientList.length != clients.length
              ? el.clientList
                  ?.map(({ clientName }) => clientName)
                  ?.join(", ") || "---"
              : "All Clients",
          // type: UserTypeMenu.find( el.User_Type = ? "General User" : "Super User",
          gLogin: el.ssoType.ssoTypeId === 1 ? "Application" : "Google",
          user_Type: el?.userType?.userType,
        })),
        "name"
      );

      if (!!sortAndFilterValues) {
        setUserList(getList(list));
      } else {
        setUserList(list);
      }
      setUserListCopy(list);
    } catch (error) {
      console.log(error);
    } finally {
      setFullLoader(false);
    }
  };

  useEffect(() => {
    getUserList();
  }, []);

  const handleRow = (item) => navigate(`/user/edit/${item.userId}`);

  const handleMoreOpen = (e, item, index) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
    setCurrentItem(item);
  };

  const handleMoreClose = () => setAnchorEl(null);

  const handleDelete = () => {
    handleMoreClose();
    setConfirmTitle(`Delete User - ${currentItem.name} ?`);
    setConfirmPopup(true);
  };

  const handleDeleteYes = async () => {
    closePopup();
    setFullLoader(true);
    try {
      await AuthService.deleteUsersV3("/" + Number(currentItem.userId));
      await showAlert("User Deletion Successful", "success", 1500);
      setSortAndFilterReset(true);
      getUserList();
    } catch (errors) {
      console.log(errors);
      showAlert(errors, "error", 1500);
    } finally {
      setFullLoader(false);
    }
  };

  const handleNo = () => closePopup();
  const closePopup = () => {
    setConfirmPopup(false);
    setConfirmTitle("");
  };

  const MoreActionContent = () => (
    <div>
      <MenuOption onClick={() => navigate(`/user/edit/${currentItem.userId}`)}>
        Manage User Account
      </MenuOption>
      <MenuOption onClick={handleDelete}>Delete User Account</MenuOption>
    </div>
  );

  const DeleteModal = () => (
    <ModalPopup
      open={confirmPopup}
      maxWidth="sm"
      title={confirmTitle}
      center={true}
    >
      <ConfirmationButton yesClick={handleDeleteYes} noClick={handleNo} />
    </ModalPopup>
  );

  return (
    <Layout>
      <H2>User Management</H2>
      <BodyContent>
        <Container>
          <TopBlock>
            <BackNav linkNav="/" />
            <div>
              <RowCount count={userListCopy?.length} />
              <PrimaryButton onClick={() => navigate("/user/add")}>
                Add User
              </PrimaryButton>
            </div>
          </TopBlock>
          <CustomizedTables
            columns={user_table}
            defaultSortColumn="name"
            rowList={userList}
            rowClick={handleRow}
            moreActionClick={handleMoreOpen}
            rowListCopy={userListCopy}
            sortList={(sortValue, sortBy) =>
              setUserList(SortString(userList, sortValue, sortBy))
            }
            filterChange={(item, reason, colKey, list) =>
              FilterTableRow(item, reason, colKey, list, setUserList)
            }
            resetList={() => setUserList(userListCopy)}
          />

          <DeleteModal />
          <PopHover anchorEl={anchorEl} handleClose={handleMoreClose}>
            <MoreActionContent />
          </PopHover>
        </Container>
      </BodyContent>
    </Layout>
  );
};

const TopBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  & > div {
    margin: 0px;
    display: flex;
    align-items: center;
    h5 {
      padding: 0px 15px 0px;
    }
  }
`;
