import { GenAiButton } from "./GenAiButton";
import { isDisprzAtomicSkillSource } from "../../../helpers/CommonHelper";
import React from "react";

export const renderAIButton = ({mode = "", sourceId = "", clickHandler = () => {}, style={}}) => {
    if (mode === "add" || isDisprzAtomicSkillSource(sourceId)) {
      return (
        <GenAiButton
          style={style}
          onClick={() => clickHandler()}
        >
          Generate Using AI
        </GenAiButton>
      );
    } else {
      return null;
    }
}