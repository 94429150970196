import axios from "axios";
import { Common, User } from "../store";
import { navigate } from "@reach/router";
import ApiConfig from "./config";

/**
 * Create an Axios Client with defaults
 */
const axiosClient = axios.create({
  baseURL: ApiConfig.baseUrlV2,
  withCredentials: true,
});

/**
 * Request Wrapper with default success/error actions
 */
const APIV2 = async (
  config = { method: "GET", data: {}, url: "" , responseType: 'application/json' },
  shouldAppendToken = true,
  timeout= 30000
) => {
  // Success
  const onSuccess = (response) => {
    if(config?.responseType === 'blob'){
      return response?.data;
    }else{
      return Promise.resolve(response?.data);
    }
  };

  // console.log("window", window.location);

  // Error  || error.response.status === 403
  const onError = (error) => {
    // console.log("onError -> error", error);
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      /**
       * If 401
       * Clear the token from offline store
       * and navigate to Initial Stack using Navigation Service
       */
      Common.clearToken();
      User.clearAll();
      console.log(error?.response?.status);
      const redirectPath = window.location?.pathname + window.location?.search;
      if (error?.response?.status === 401) {
        console.log(error?.response?.status);
        navigate("/", {
          state: {
            redirectPath,
          },
        });
      } else {
        navigate("/");
      }

    }

    if (error?.response) {
      // Request was made but server responded with something
      // other than 2xx
      return Promise.reject(error?.response?.data);
    } else {
      // Something else happened while setting up the request
      // triggered the error
      return Promise.reject(error?.message);
    }
  };

  // Append headers
  let headers = {
    Accept: "application/json",
  };

  // Append auth token
  if (shouldAppendToken) {
    try {
      // const token = await Common.getToken();
      const token = process.env.REACT_APP_API_KEY;
      headers["Portal"] = "Common";
      headers["Api-Token"] = token;
    } catch (error) {
      // Token is not found
      return Promise.reject(error.message);
    }
  }

  // Set headers
  axiosClient.defaults.headers = headers;
  axiosClient.defaults.timeout = timeout;

  return axiosClient(config).then(onSuccess).catch(onError);
};

export default APIV2;
