import React from "react";
import "./RemoveSkillColumn.scss";
import Each from "../../../../Common/Each";
import RemoveIcon from "./RemoveIcon";
import useRemoveSkillColumn from "./useRemoveSkillColumn";

/**
 * RemoveSkillColumn component for rendering a column of Remove Icons.
 *
 * @param {Object} props - Component properties.
 * @param {Array} props.data - Array of data items.
 * @param {Function} props.cancelSkill - Function to cancel a skill.
 * @returns {JSX.Element} - Rendered RemoveSkillColumn component.
 */
const RemoveSkillColumn = ({ data, cancelSkill }) => {
  const { handleRemoveIconClick  } = useRemoveSkillColumn({cancelSkill});

  return (
    <ul className="remove-column">
      <Each
        items={data}
        render={(item, index) =>
          RemoveIcon(item, index, handleRemoveIconClick)
        }
      />
    </ul>
  );
};

export default RemoveSkillColumn;
