import React from 'react'
import InputBox from '../../../Common/FormElements/InputBox';
import Label from '../../../Common/FormElements/Label';
import AutoCompleteCheckBox from '../../../Common/FormElements/AutoCompleteCheckBox';
import { PrimaryButton } from '../../../Common/Buttons/PrimaryButton';
import { SourceMappingType } from '../../../../enums/SourceMappingType';
import text from '../../../../locale.en.json';


const SearchInputField = ({
    skillName,
    updateSkillName,
    handleSearchButtonClick,
    categoryMenu,
    handleCategory,
    familyMenu,
    handleFamily,
    sourceList,
    handleSource,
    skillSourceIds,
    selectFamily,
    selectCategory,
    selectSource,
    isNavigatedFromRoleScreen
}) => {
  return (
    <div className="search-input-block">
        <div className="search-block">
          <InputBox
            style={{ paddingBottom: 0, width: 250 }}
            label="Skill Name"
            value={skillName}
            onChange={(e) => updateSkillName(e.target.value)}
            onKeyPress={(ev) => {
              if (ev.key === "Enter") {
                handleSearchButtonClick();
              }
            }}
          />
        </div>
        <div className="filter-block">
          <Label>Filter:</Label>
          <AutoCompleteCheckBox
            width="200px"
            label="Category"
            list={categoryMenu}
            optionLabel={"label"}
            optionChange={handleCategory}
          />
          <AutoCompleteCheckBox
            width="200px"
            label="Family"
            list={familyMenu}
            optionLabel={"label"}
            optionChange={handleFamily}
          />
        {sourceList?.length > 0
          ? <AutoCompleteCheckBox
              width="200px"
              label="Source"
              list={sourceList}
              optionLabel={"label"}
              optionChange={handleSource}
             />
          : <div className="source-drop-down">
              <InputBox
                style={{ paddingBottom: 0, width: 200 }}
                label="Source"
                value={SourceMappingType[skillSourceIds]}
                disabled={true}
              />
            </div>
          }
        </div>
        <PrimaryButton
          style={{ marginTop: 36 }}
          onClick={() => handleSearchButtonClick()}
          disabled={
            (!skillName.trim() &&
            selectFamily?.length === 0 &&
            selectCategory?.length === 0 &&
            selectSource?.length === 0 && !isNavigatedFromRoleScreen)
          }
        >
          {text.common.search}
        </PrimaryButton>
      </div>
  )
}

export default SearchInputField