import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import COLORS from "../../../assets/colors";
import { SecondaryButton } from "./SecondaryButton";
import styled from "styled-components";

const useStyles = makeStyles((theme) => ({
  margin: {
    height: 38,
    margin: 0,
    width: 38,
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

export default function ButtonIcon({
  icon,
  onClick,
  color = "primary",
  className,
  ...props
}) {
  const classes = useStyles();

  return (
    <IconButton
      className={classes.margin + " " + className}
      onClick={onClick}
      color={color}
      {...props}
    >
      {icon}
    </IconButton>
  );
}

export const AddCancelButton = styled(SecondaryButton)`
  padding: 0px !important;
  min-width: 32px !important;
  min-height: 32px !important;
  border-radius: 16px !important;

  div {
    background-color: ${COLORS.PRIMARY_PURPLE};
    height: 1px;
    width: 16px;
    position: absolute;
    transition: all 0.2s ease-in;
  }

  .leftright {
    transform: ${(props) =>
      props.showBox ? `rotate(-45deg)` : `rotate(90deg)`};
  }

  .rightleft {
    transform: ${(props) => (props.showBox ? `rotate(45deg)` : `rotate(0deg)`)};
  }
`;
