import { useState } from "react";
import { PersonaCreationActionTypes } from "../../../../enums/PersonaCreationActionType";
import { FileUploaderStore } from "../../../../store/store_state";
import { PersonaCreationOptions } from "../PersonaCreationOptions";
import { PersonaCreationTypes } from "../../../../enums/PersonaCreationType";

const useBulkPersonaCreationModal = ({close, setError, handleBulkImportConfirm, templateUrl}) => {
    const [, , ...bulkPersonaCards] = PersonaCreationOptions;
    const initialState = {
        showZipFileUploader: false,
        showClientSkillsFileUploader: false,
        showRoleSkillsFileUploader: false,
        showInfoIcon: false
    };
    const [showDragAndDropFileModal, setShowDragAndDropFileModal] = useState(initialState);
    const selectedFiles = FileUploaderStore.useState(s => s.selectedFiles);

    const actionHandlers = {
        [PersonaCreationActionTypes.UPLOAD_ZIP_FILE]: () => { 
           if(!showDragAndDropFileModal.showZipFileUploader){
                closeDragAndDrapModal(); 
                setShowDragAndDropFileModal({...initialState, showZipFileUploader: true, showInfoIcon: true}) 
           }
        },
        [PersonaCreationActionTypes.UPLOAD_SKILLS_EXCEL]: () => { 
           if(!showDragAndDropFileModal.showClientSkillsFileUploader){
                closeDragAndDrapModal(); 
                setShowDragAndDropFileModal({...initialState, showClientSkillsFileUploader: true}) 
            }
        },
        [PersonaCreationActionTypes.UPLOAD_ROLES_SKILLS_EXCEL]: () => { 
           if(!showDragAndDropFileModal.showRoleSkillsFileUploader){
                closeDragAndDrapModal(); 
                setShowDragAndDropFileModal({...initialState, showRoleSkillsFileUploader: true}) 
           }
        },
        [PersonaCreationActionTypes.UPLOAD_FROM_HRMS]: () => {}
    };

    const handleClosePopUp = () => {
        setShowDragAndDropFileModal(initialState)
        FileUploaderStore.update((s) => {
            s.selectedFiles = [];
        });
        close();
    }

    const closeDragAndDrapModal = () => {
        setShowDragAndDropFileModal(initialState)
        FileUploaderStore.update((s) => {
            s.selectedFiles = [];
        });
        setError("");
    }

    const handleYesClick = () => {
        const personaCreationType =
            showDragAndDropFileModal.showZipFileUploader
                ? PersonaCreationTypes.UPLOAD_ZIP_FILE
                : showDragAndDropFileModal.showClientSkillsFileUploader
                    ? PersonaCreationTypes.UPLOAD_SKILLS_EXCEL
                    : PersonaCreationTypes.UPLOAD_ROLES_SKILLS_EXCEL;

    
        handleBulkImportConfirm(selectedFiles, handleClosePopUp, personaCreationType);
    };

    const fileUrl = showDragAndDropFileModal.showRoleSkillsFileUploader
        ? templateUrl?.roleSkillsTemplateUrl
        : templateUrl?.clientSkillsTemplateUrl;
    const fileName = showDragAndDropFileModal.showRoleSkillsFileUploader
        ? "RoleSkillsTemplate.xlsx"
        : "ClientSkillsTemplate.xlsx";

  return { bulkPersonaCards, showDragAndDropFileModal, selectedFiles, actionHandlers, handleClosePopUp, handleYesClick, fileUrl, fileName };
};

export default useBulkPersonaCreationModal;
