import React from "react";
import { SecondaryButton } from "./SecondaryButton";
import { Images } from "../../../assets/images";
import styled from "styled-components";
import COLORS from "../../../assets/colors";

const GenAiButtonWrapper = styled(SecondaryButton)`
  position: relative;
  padding: 0px 12px !important;
  min-height: 35px !important;
  width: 140px !important;
  z-index: 999;
  background-color: ${COLORS.PRIMARY_WHITE} !important;
`;

const BottomLeftImage = styled.img`
  position: absolute;
  bottom: 0;
  left: 3px;
`;

const TopRightImage = styled.img`
  position: absolute;
  top: 0;
  right: -3px;
`;

export const GenAiButton = ({children, onClick, style}) => {
  return (
    <GenAiButtonWrapper onClick={onClick} style={style}>
      <BottomLeftImage src={Images.icons.starFall} alt="star.png" />
         {children}
      <TopRightImage src={Images.icons.groupStarFall} alt="group-star.png" />
    </GenAiButtonWrapper>
  );
};