export const LevelIdSet = (key) => {
  switch (key) {
    case "Level 1":
    case "1":
      return 1;
    case "Level 2":
    case "2":
      return 2;
    case "Level 3":
    case "3":
      return 3;
    case "Level 4":
    case "4":
      return 4;
    case "Level 5":
    case "5":
      return 5;
    case "Level 6":
    case "6":
      return 6;
    case "Level 7":
    case "7":
      return 7;
    case "Novice":
      return 1;
    case "Beginner":
      return 2;
    case "Intermediate":
      return 3;
    case "Mastery":
      return 4;
    case "Distinguished":
      return 5;
    default:
      break;
  }
};
