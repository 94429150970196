import React, { useEffect, useState } from "react";
import { SortString } from "../../helpers/SortString";
import useRoles from "../../Hooks/useRoles";
import { AuthService } from "../../services/AuthService";
import SelectMenu from "../Common/FormElements/SelectMenu";

export default ({
  width = 140,
  label = "Function / Track",
  handleSelect,
  value = "Any",
  subSector = "Any",
  subSectorType,
  labelShow = true,
  passClientId = false,
  triggerApi = true,
  list = null,
}) => {
  const { clientName } = useRoles();

  const [functions, setFunctions] = useState(list);
  useEffect(() => {
    // console.log("subSector", subSector, typeof subSector);
    const getFunctionList = async () => {
      try {
        //passClientId ? `?clientId=${clientName}` : ""
        const res = await AuthService.functionsListV3(
          subSector !== "Any" ? `?subSectorIds=${subSector}` : "",
          passClientId
            ? `${subSector !== "Any" ? "&" : "?"}clientId=${clientName}`
            : ""
        );
        const sorted = await SortString(res, "functionName");
        await sorted.unshift({ functionId: "Any", functionName: "Any" });
        setFunctions(sorted);
      } catch (error) {
        console.log(error);
      }
    };
    // console.log(functions, triggerApi);
    if (!!subSector && !!clientName) triggerApi && getFunctionList();
    return () => {
      setFunctions(null);
    };
  }, [subSector, clientName]);
  // console.log(value, functions, list);
  return (
    <SelectMenu
      width={width}
      label={subSectorType === 1 ? "Department" : "Track"}
      value={value}
      list={
        !!functions
          ? functions.map((el) => ({
              label: el.functionName,
              value: el.functionId,
            }))
          : []
      }
      handleSelect={handleSelect}
      labelShow={labelShow}
    />
  );
};
