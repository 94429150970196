import * as React from "react";
import { Field, useField } from "formik";
import { makeStyles } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { Autocomplete } from "formik-material-ui-lab";

import COLORS from "../../../assets/colors";
import styled from "styled-components";

export default ({ list = [], label = "", filterLabel, ...props }) => {
  const [field, meta] = useField(props);
  const classes = useStyles();
  // console.log(field);
  // console.log(field, meta, props);
  // console.log(list);
  return (
    <div
      style={{
        padding: "0px 0px 20px 0px",
      }}
    >
      <Field
        component={Autocomplete}
        className={classes.autocomp}
        options={list}
        getOptionLabel={(option) => option[filterLabel]}
        // style={{ width: 300 }}
        {...props}
        renderInput={(params) => (
          <TextField
            {...params}
            // {...field}
            // {...props}
            error={meta.touched && meta.error}
            // helperText={touched["autocomplete"] && errors["autocomplete"]}
            label={label}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            className={classes.root}
          />
        )}
      />
      {meta.touched && meta.error ? (
        <StyledErrorMessage>{meta.error}</StyledErrorMessage>
      ) : null}
    </div>
  );
};

const StyledErrorMessage = styled.div`
  font-size: 12px;
  color: ${COLORS.PRIMARY_RED};
  &:before {
    content: "";
    font-size: 10px;
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-multiline": {
      // borderRadius: "0px",
      padding: 0,
    },
    "& .MuiOutlinedInput-input": {
      color: COLORS.TITLE_BLUE,
      fontSize: 12,
      padding: 12,
    },
  },
  autocomp: {
    "& .MuiAutocomplete-inputRoot": {
      padding: 3,
    },
    "&  .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      color: COLORS.TITLE_BLUE,
      fontSize: 16,
      fontWeight: 600,
      fontFamily: "inherit",
      whiteSpace: "nowrap",
      transform: `none`,
      position: "static",
      paddingBottom: 15,
    },
    "& .MuiOutlinedInput-root": {
      border: `solid 1px ${COLORS.C4_BORDER}`,
      "&.Mui-error": {
        border: `solid 1px ${COLORS.ERROR_RED}`,
      },
    },
  },
}));
