import React from "react";
import { makeStyles, Popover } from "@material-ui/core";

export default ({
  handleClose,
  anchorEl,
  hoverType = false,
  styles,
  children,
  anchorOrigin={
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin={
    vertical: "top",
    horizontal: "center",
  }
}) => {
  const classes = useStylesPop();
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <Popover
        id={id}
        open={open}
        className={hoverType ? classes.popover : null}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        style={styles}
      >
        {children}
      </Popover>
    </div>
  );
};

const useStylesPop = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
  popover: {
    pointerEvents: "none",
  },
}));
