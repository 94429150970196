import { PersonaCreationTypes } from "../../../../enums/PersonaCreationType";
import { PersonaCreationOptions } from "../PersonaCreationOptions";

const usePersonaCreationChoiceModal = ({restrictBulkPersonaCreation}) => {
  const [createBulkPersonaCard, createSinglePersonaCard, ...remainingPersonaCards] = PersonaCreationOptions;
  const personaCards = [createBulkPersonaCard, createSinglePersonaCard]

  if(restrictBulkPersonaCreation){
    personaCards[PersonaCreationTypes.CREATE_BULK].disableCard = true;
  } else {
    personaCards[PersonaCreationTypes.CREATE_BULK].disableCard = false;
  }

  return { personaCards };
};

export default usePersonaCreationChoiceModal;
