import React from "react";
import { Rating } from "@material-ui/lab";

export default function StarRating({
  size = "small",
  precision = 0.5,
  defaultValue = 0,
  readOnly = true,
  name,
}) {
  // console.log({ defaultValue });
  return (
    <Rating
      name={name}
      defaultValue={defaultValue}
      size={size}
      precision={precision}
      readOnly={readOnly}
    />
  );
}
