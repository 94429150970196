import API from "../api/manager";
import config from "../api/config";
import APIV2 from "../api/managerV2";

const getAllContentProviders = () => {
  return API(
    {
      method: "GET",
      url: config.urls.auth.contentsProviders,
    },
    true
  );
};

const getAllContentProvidersV3 = () => {
  return APIV2(
    {
      method: "GET",
      url: config.urls.auth.contentsProvidersV3,
    },
    true
  );
};

const getSkillContentDetail = (id) => {
  return API(
    {
      method: "GET",
      url: config.urls.auth.contents + id,
    },
    true
  );
};

const getSkillContentDetailV3 = (id) => {
  return APIV2(
    {
      method: "GET",
      url: config.urls.auth.contentsV3 + id,
    },
    true
  );
};

export const ContentService = {
  getAllContentProviders,
  getAllContentProvidersV3,
  getSkillContentDetail,
  getSkillContentDetailV3,
};
