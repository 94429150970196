import API from "../api/manager";
import config from "../api/config";
import APIV2 from "../api/managerV2";

const login = (data) => {
  return API(
    {
      method: "POST",
      url: config.urls.auth.login,
      data,
    },
    true
  );
};

const loginV3 = (data) => {
  return APIV2(
    {
      method: "POST",
      url: config.urls.auth.loginV3,
      data,
    },
    true
  );
};

const accountLogin = (data) => {
  return API(
    {
      method: "POST",
      url: config.urls.auth.googleLogin,
      data,
    },
    true
  );
};

const accountLoginV3 = (data) => {
  return APIV2(
    {
      method: "POST",
      url: config.urls.auth.googleLoginV3,
      data,
    },
    true
  );
};

const localData = () => {
  return API(
    {
      method: "GET",
      url: "/visual/visual.json",
    },
    true
  );
};

const getUsers = () => {
  return API(
    {
      method: "GET",
      url: config.urls.auth.users,
    },
    true
  );
};

const getUsersV3 = () => {
  return APIV2(
    {
      method: "GET",
      url: config.urls.auth.usersV3,
    },
    true
  );
};

const getUserDetail = (id) => {
  return API(
    {
      method: "GET",
      url: config.urls.auth.users + id,
    },
    true
  );
};

const getUserDetailV3 = (id) => {
  return APIV2(
    {
      method: "GET",
      url: config.urls.auth.usersV3 + id,
    },
    true
  );
};

const addUsers = (data) => {
  return API(
    {
      method: "POST",
      url: config.urls.auth.users,
      data,
    },
    true
  );
};

const addUsersV3 = (data) => {
  return APIV2(
    {
      method: "POST",
      url: config.urls.auth.usersV3,
      data,
    },
    true
  );
};

const updateUsers = (id, data) => {
  return API(
    {
      method: "PATCH",
      url: config.urls.auth.users + id,
      data,
    },
    true
  );
};

const updateUsersV3 = (id, data) => {
  return APIV2(
    {
      method: "PATCH",
      url: config.urls.auth.usersV3 + id,
      data,
    },
    true
  );
};

const userAssociateClients = (id, clientIDs) => {
  return API(
    {
      method: "POST",
      url: config.urls.auth.users + id + clientIDs,
    },
    true
  );
};

const userAssociateClientsV3 = (param, data) => {
  return APIV2(
    {
      method: "POST",
      url: config.urls.auth.usersV3 + param,
      data,
    },
    true
  );
};

const deleteUsersV3 = (id) => {
  return APIV2(
    {
      method: "DELETE",
      url: config.urls.auth.usersV3 + id,
    },
    true
  );
};

const deleteUsers = (id) => {
  return API(
    {
      method: "DELETE",
      url: config.urls.auth.users + id,
    },
    true
  );
};

const getAllClients = (id) => {
  return API(
    {
      method: "GET",
      url: config.urls.auth.clients,
    },
    true
  );
};

const getAllClientsV3 = (id) => {
  return APIV2(
    {
      method: "GET",
      url: config.urls.auth.clientsV3,
    },
    true
  );
};

const getAllClientsOrgStage = (id) => {
  return API(
    {
      method: "GET",
      url: config.urls.auth.clientsOrgStage,
    },
    true
  );
};

const getAllClientsOrgStageV3 = () => {
  return APIV2(
    {
      method: "GET",
      url: config.urls.auth.ORG_AttributesV3,
    },
    true
  );
};

const getClientsInstanceRegion = (id) => {
  return API(
    {
      method: "GET",
      url: config.urls.auth.clientsInstanceRegion,
    },
    true
  );
};

const getClientsInstanceRegionV3 = () => {
  return APIV2(
    {
      method: "GET",
      url: config.urls.auth.InstanceRegion_AttributesV3,
    },
    true
  );
};

const addClient = (data) => {
  return API(
    {
      method: "POST",
      url: config.urls.auth.clients,
      data,
    },
    true
  );
};

const addClientV3 = (data) => {
  return APIV2(
    {
      method: "POST",
      url: config.urls.auth.clientsV3,
      data,
    },
    true
  );
};

const updateClient = (id, data) => {
  return API(
    {
      method: "PATCH",
      url: config.urls.auth.clients + id,
      data,
    },
    true
  );
};

const updateClientV3 = (id, data) => {
  return APIV2(
    {
      method: "PATCH",
      url: config.urls.auth.clientsV3 + id,
      data,
    },
    true
  );
};

const getClientDetail = (id) => {
  return API(
    {
      method: "GET",
      url: config.urls.auth.clients + id,
    },
    true
  );
};

const getClientDetailV3 = (id) => {
  return APIV2(
    {
      method: "GET",
      url:`${config.urls.auth.clientsV3}/${id}`,
    },
    true
  );
};

const deleteClient = (id) => {
  return API(
    {
      method: "DELETE",
      url: config.urls.auth.clients + id,
    },
    true
  );
};

const deleteClientV3 = (id) => {
  return APIV2(
    {
      method: "DELETE",
      url: config.urls.auth.clientsV3 + id,
    },
    true
  );
};

const getClients = (id) => {
  return API(
    {
      method: "GET",
      url: config.urls.auth.users + id + "/clients",
    },
    true
  );
};

const getClientsV3 = (id) => {
  return APIV2(
    {
      method: "GET",
      url: config.urls.auth.usersV3 + id + "/clients",
    },
    true
  );
};

const jobSectorList = () => {
  return API(
    {
      method: "GET",
      url: config.urls.auth.jobSectors,
    },
    true
  );
};

const jobSourceList = () => {
  return API(
    {
      method: "GET",
      url: config.urls.auth.jobSources,
    },
    true
  );
};

const leaderShipRoles = () => {
  return API(
    {
      method: "GET",
      url: config.urls.auth.leadershipRoles,
    },
    true
  );
};

const leaderShipRolesV3 = () => {
  return APIV2(
    {
      method: "GET",
      url: config.urls.auth.leadershipRolesV3,
    },
    true
  );
};

const subSectorList = (id = "") => {
  return API(
    {
      method: "GET",
      url: config.urls.auth.subSectors + id,
    },
    true
  );
};

const subSectorListV3 = (id = "") => {
  return APIV2(
    {
      method: "GET",
      url: config.urls.auth.subSectorsV3 + id,
    },
    true
  );
};

const subSectorWithSourceListV3 = (id = "") => {
  return APIV2(
    {
      method: "GET",
      url: config.urls.auth.subSectorV3 + id,
    },
    true
  );
};

const functionsList = (subSector, clientId = "") => {
  return API(
    {
      method: "GET",
      url: config.urls.auth.functions + subSector + clientId,
    },
    true
  );
};

const functionsListV3 = (subSector, clientId = "") => {
  return APIV2(
    {
      method: "GET",
      url: config.urls.auth.functionsV3 + subSector + clientId,
    },
    true
  );
};

const frameWorkList = () => {
  return API(
    {
      method: "GET",
      url: config.urls.auth.frameworks,
    },
    true
  );
};

//frameWorkList replace
const sourceListV3 = () => {
  return APIV2(
    {
      method: "GET",
      url: config.urls.auth.getSourcesV3,
    },
    true
  );
};

const getIndustryRoleList = (data) => {
  return API(
    {
      method: "POST",
      url: config.urls.auth.getIndustryRoles,
      data,
    },
    true
  );
};

const getIndustryRoleListV3 = (data) => {
  return APIV2(
    {
      method: "POST",
      url: config.urls.auth.getIndustryRolesV3,
      data,
    },
    true
  );
};

const getSkillList = (id) => {
  return API(
    {
      method: "GET",
      url: config.urls.auth.industryRoles + id,
    },
    true
  );
};

const getSkillListV3 = (id) => {
  return APIV2(
    {
      method: "GET",
      url: config.urls.auth.industryRolesV3 + id,
    },
    true
  );
};

const getLeaderSkillList = (id) => {
  return API(
    {
      method: "GET",
      url: config.urls.auth.leadershipRoles + id,
    },
    true
  );
};

const getLeaderSkillListV3 = (id) => {
  return APIV2(
    {
      method: "GET",
      url: config.urls.auth.leadershipRolesV3 + id,
    },
    true
  );
};

const getJobList = (data, pagesize, offset) => {
  return API(
    {
      method: "POST",
      url: config.urls.auth.jobs + pagesize + offset,
      data,
    },
    true
  );
};

const getJobCount = (data) => {
  return API(
    {
      method: "POST",
      url: config.urls.auth.jobsCount,
      data,
    },
    true
  );
};

const getJobDetail = (id) => {
  return API(
    {
      method: "GET",
      url: config.urls.auth.jobs + id,
    },
    true
  );
};

const postAnnotateJob = (jobPostingId, roleId) => {
  return API(
    {
      method: "POST",
      url: config.urls.auth.annotateJob + jobPostingId + roleId,
    },
    true
  );
};

const postAnnotateRole = (leaderShipRoleId, roleId) => {
  return API(
    {
      method: "POST",
      url: config.urls.auth.annotateRole + leaderShipRoleId + roleId,
    },
    true
  );
};

const postAnnotateRoleV3 = (leaderShipRoleId, roleId) => {
  return APIV2(
    {
      method: "POST",
      url: config.urls.auth.annotateRoleV3 + leaderShipRoleId + roleId,
    },
    true
  );
};

/* Personas */
const getPersonasList = (clientId, pagesize, offset) => {
  return API(
    {
      method: "GET",
      url: config.urls.auth.getAllPersonas + clientId,
    },
    true
  );
};

const getPersonasListV3 = (clientId) => {
  return APIV2(
    {
      method: "GET",
      url: config.urls.auth.getAllPersonasV3 + clientId,
    },
    true
  );
};

const getPersonasListBoundary = (clientId, pagesize, offset) => {
  return API(
    {
      method: "GET",
      url: config.urls.auth.getAllPersonas + clientId + pagesize + offset,
    },
    true
  );
};

const getPersonasListBoundaryV3 = (data, pagesize, offset) => {
  const params = new URLSearchParams();
  params.append("searchString", data?.searchString || "");
  (data.personaGroupIds || []).forEach(el => params.append("personaGroupId", el));
  params.append("clientId", data?.clientId || "");
  params.append("pagesize", pagesize);
  params.append("offset", offset);
  return APIV2(
    {
      method: "GET",
      url: `${config.urls.auth.getAllPersonasV3}?${params.toString()}`,
    },
    true
  );
};

const getPersonasSearchList = (data, params) => {
  return API(
    {
      method: "POST",
      url: config.urls.auth.personaSearch + params,
      data,
    },
    true
  );
};

const getPersonasSearchListV3 = (data, params) => {
  return APIV2(
    {
      method: "POST",
      url: config.urls.auth.personaSearchV3 + params,
      data,
    },
    true
  );
};

const addPersona = (data) => {
  return API(
    {
      method: "POST",
      url: config.urls.auth.getAllPersonas,
      data,
    },
    true
  );
};

const createGroupIdV3 = (data) => {
  return APIV2(
    {
      method: "POST",
      url: config.urls.auth.personaGroupV3,
      data,
    },
    true
  );
};

const addPersonaV3 = (data) => {
  return APIV2(
    {
      method: "POST",
      url: config.urls.auth.getAllPersonasV3,
      data,
    },
    true
  );
};

const updatePersona = (data, id) => {
  return API(
    {
      method: "PATCH",
      url: config.urls.auth.getAllPersonas + id,
      data,
    },
    true
  );
};

const updatePersonaV3 = (data, id) => {
  return APIV2(
    {
      method: "PATCH",
      url: config.urls.auth.getAllPersonasV3 + id,
      data,
    },
    true
  );
};

const deletePersona = (id) => {
  return API(
    {
      method: "DELETE",
      url: config.urls.auth.getAllPersonas + id,
    },
    true
  );
};

const deletePersonaV3 = (data) => {
  return APIV2(
    {
      method: "DELETE",
      url: config.urls.auth.getAllPersonasV3,
      data
    },
    true
  );
};

const deletePersonasByClientV3 = (id, data) => {
  return APIV2(
    {
      method: "DELETE",
      url: `${config.urls.auth.getAllPersonasV3}/${id}`,
      data
    }
  )
}

const getSuggestedRole = (data) => {
  return API(
    {
      method: "POST",
      url: config.urls.auth.getIndustrySuggestedRole,
      data,
    },
    true
  );
};

const getSuggestedRoleV3 = (data) => {
  return APIV2(
    {
      method: "POST",
      url: config.urls.auth.getIndustrySuggestedRoleV3,
      data,
    },
    true
  );
};

const getPersonaGroup = (clientId) => {
  return API(
    {
      method: "GET",
      url: config.urls.auth.personaGroup + clientId,
    },
    true
  );
};

const getPersonaGroupV3 = (clientId) => {
  return APIV2(
    {
      method: "GET",
      url: config.urls.auth.personaGroupV3 + clientId,
    },
    true
  );
};

const getPersonaDetail = (id) => {
  return API(
    {
      method: "GET",
      url: config.urls.auth.getAllPersonas + id,
    },
    true
  );
};

const getPersonaDetailV3 = (id) => {
  return APIV2(
    {
      method: "GET",
      url: config.urls.auth.personaDetailV3 + id,
    },
    true
  );
};

const getPersona = (id) => {
  return API(
    {
      method: "GET",
      url: config.urls.auth.getPersona + id,
    },
    true
  );
};

const getPersonaSkillsV3 = (personaId) => {
  return APIV2(
    {
      method: "GET",
      url: `${config.urls.auth.getPersonaV3}/${personaId}/skills`,
    },
    true
  );
};

const getPersonaExport = (id) => {
  return API(
    {
      method: "GET",
      url: config.urls.auth.personaExport + id,
    },
    true
  );
};

const getPersonaExportV3 = (clientId, data) => {
  return APIV2(
    {
      method: "POST",
      url: `${config.urls.auth.personas}/${clientId}/export-excel`,
      data,
      // onDownloadProgress: (data) => {
      //   console.log(data);
      // },
    },
    true
  );
};

const updatePersonaSkill = (id, data) => {
  return API(
    {
      method: "POST",
      url: config.urls.auth.getPersona + id,
      data,
    },
    true
  );
};

const updatePersonaSkillV3 = (id, data) => {
  return APIV2(
    {
      method: "POST",
      url: `${config.urls.auth.getPersonaV3}/${id}/proficiencies`,
      data,
    },
    true
  );
};

const personasExportToDisprz = (personaIds, clientId) => {
  return API(
    {
      method: "POST",
      url: config.urls.auth.personasExportToDisprz + personaIds + clientId,
    },
    true
  );
};

const personasExportToDisprzV3 = (clientId, data) => {
  return APIV2(
    {
      method: "POST",
      url: `${config.urls.auth.personas}/${clientId}/export-disprz`,
      data,
    },
    true
  );
};

const personasExportToDisprzStatus = (data) => {
  return API(
    {
      method: "POST",
      url: config.urls.auth.personasExportToDisprzStatus,
      data,
    },
    true
  );
};

const personasExportToDisprzStatusV3 = (data) => {
  return APIV2(
    {
      method: "POST",
      url: config.urls.auth.personasExportToDisprzStatusV3,
      data,
    },
    true
  );
};

const getBulkPersonaExportStatusV3 = (clientId, exportTokenId) => {
  return APIV2(
    {
      method: "POST",
      url: `${config.urls.auth.bulkPersonaExportStatusV3}?clientId=${clientId}&exportTokenId=${exportTokenId}`
    },
    true
  );
};

const getExcludedSkillsFromPersonaV3 = (personaId) => {
  return APIV2(
    {
      method: "GET",
      url: `${config.urls.auth.personas}/${personaId}/skills/excluded`
    },
    true
  );
};

const checkPersonaNameExistsV3 = (name, clientId, excludePersonaId) => {
  return APIV2(
    {
      method: "GET",
      url: `${config.urls.auth.personaNameExistsV3}?name=${name}&clientId=${clientId}&excludePersonaId=${excludePersonaId}`
    },
    true
  );
}

export const AuthService = {
  login,
  loginV3,
  accountLogin,
  accountLoginV3,
  localData,
  getUsers,
  getUsersV3,
  getUserDetail,
  getUserDetailV3,
  addUsers,
  addUsersV3,
  updateUsers,
  updateUsersV3,
  userAssociateClients,
  userAssociateClientsV3,
  deleteUsers,
  deleteUsersV3,
  getAllClients,
  getAllClientsV3,
  getClientsV3,
  getAllClientsOrgStage,
  getAllClientsOrgStageV3,
  getClientsInstanceRegion,
  getClientsInstanceRegionV3,
  getClientDetail,
  getClientDetailV3,
  deleteClient,
  deleteClientV3,
  getClients,
  addClient,
  addClientV3,
  updateClient,
  updateClientV3,
  jobSectorList,
  jobSourceList,
  getJobDetail,
  getJobCount,
  getJobList,
  subSectorList,
  subSectorListV3,
  subSectorWithSourceListV3,
  functionsList,
  functionsListV3,
  getIndustryRoleList,
  getIndustryRoleListV3,
  getSkillList,
  getSkillListV3,
  leaderShipRoles,
  leaderShipRolesV3,
  postAnnotateJob,
  postAnnotateRole,
  postAnnotateRoleV3,
  getPersonasList,
  getPersonasListV3,
  getSuggestedRole,
  getSuggestedRoleV3,
  getPersonaGroup,
  getPersonaGroupV3,
  createGroupIdV3,
  addPersona,
  addPersonaV3,
  getPersonaDetail,
  getPersonaDetailV3,
  updatePersona,
  updatePersonaV3,
  deletePersona,
  deletePersonaV3,
  getPersona,
  getPersonaSkillsV3,
  getPersonaExport,
  getPersonaExportV3,
  updatePersonaSkill,
  updatePersonaSkillV3,
  getLeaderSkillList,
  getLeaderSkillListV3,
  personasExportToDisprz,
  personasExportToDisprzStatus,
  personasExportToDisprzV3,
  personasExportToDisprzStatusV3,
  frameWorkList,
  sourceListV3,
  getPersonasListBoundary,
  getPersonasListBoundaryV3,
  getPersonasSearchList,
  getPersonasSearchListV3,
  deletePersonasByClientV3,
  getBulkPersonaExportStatusV3,
  getExcludedSkillsFromPersonaV3,
  checkPersonaNameExistsV3
};
