import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputBase from "@material-ui/core/InputBase";
import COLORS from "../../../assets/colors";
import { InfoRounded } from "@material-ui/icons";

const BootstrapInput = withStyles((theme) => ({
  root: {
    marginBottom: 25,
    "label + &": {
      marginTop: 22,
      fontSize: 12,
    },
    "label.MuiFormLabel-root": {
      fontSize: 20,
    },
  },
  input: {
    borderRadius: 4,
    color: COLORS.TEXT_BLUE,
    position: "relative",
    backgroundColor: COLORS.PRIMARY_WHITE,
    border: "1px solid #ced4da",
    fontSize: 12,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: "inherit",
    // Use the system font instead of the default Roboto font.
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.1rem rgba(0,123,255,.05)",
    },
    ".MuiSelect-icon": {
      color: "#fff",
    },
    width: "100%",
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: 0,
    width: "100%",
    paddingTop: 8,
  },
  label: {
    color: COLORS.TITLE_BLUE,
    fontSize: 20,
    fontWeight: "bold",
    fontFamily: "inherit",
    whiteSpace: "nowrap",
    "& .subText": {
      fontSize: 18,
      fontWeight: 400,
    },
  },
}));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    fontSize: 12,
    "&:focus": {
      backgroundColor: COLORS.ASH_WHITE,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

export default ({
  label,
  width,
  list,
  value,
  handleSelect,
  disabled = false,
  labelShow = true,
}) => {
  const classes = useStyles();
  const handleChange = (event) => handleSelect(event.target.value);

  return (
    <div style={{ width }}>
      <FormControl className={classes.margin}>
        {!!labelShow && (
          <InputLabel
            className={classes.label}
            id="demo-customized-select-label"
          >
            <div
              dangerouslySetInnerHTML={{
                __html: label,
              }}
            />
          </InputLabel>
        )}
        <Select
          labelId="demo-customized-select-label"
          id="demo-customized-select"
          // disabled={list.length === 0}
          value={!!value ? value : "Any"}
          onChange={handleChange}
          placeholder={value}
          disabled={disabled}
          input={<BootstrapInput />}
          title={
            !!value &&
            !!list?.length &&
            value !== 0 &&
            list?.find((el) => el.value === value)?.label !== "Any"
              ? list?.find((el) => el.value === value)?.label
              : ""
          }
        >
          {list?.map((el, i) => (
            <StyledMenuItem value={el.value} key={i}>
              {el.label}
            </StyledMenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
