import React, { useEffect, useState, useRef } from "react";
import ReactFlow, {
  ReactFlowProvider,
  Controls,
  useStoreState,
} from "react-flow-renderer";

import FlowNode from "./../../Common/Flow/FolwNode";
import Layout from "../../Layout";
import { BodyContent, Container } from "../../Common/GridSystem";
import { H2 } from "../../Common/Typography";
import { RoleServices } from "../../../services/RoleServices";
import useFullLoader from "../../../Hooks/useFullLoader";
import { PrimaryButton } from "../../Common/Buttons/PrimaryButton";
import ReactFlowWrapper from "../../Common/Flow/ReactFlowWrapper";
import { DataArrayValue } from "./careerProgreesArray";
const position = { x: 0, y: 0 };
const edgeType = "smoothstep";

const testData = [
  {
    id: "1",
    type: "input",
    data: { label: "input" },
    position,
  },
  {
    id: "2",
    data: { label: "node 2" },
    position,
  },
  {
    id: "2a",
    data: { label: "node 2a" },
    position,
  },
  {
    id: "2b",
    data: { label: "node 2b" },
    position,
  },
  {
    id: "2c",
    data: { label: "node 2c" },
    position,
  },
  {
    id: "2d",
    data: { label: "node 2d" },
    position,
  },
  {
    id: "3",
    data: { label: "node 3" },
    position,
  },
  {
    id: "4",
    data: { label: "node 4" },
    position,
  },
  {
    id: "5",
    data: { label: "node 5" },
    position,
  },
  {
    id: "6",
    type: "output",
    data: { label: "output" },
    position,
  },
  { id: "7", type: "output", data: { label: "output" }, position },
  { id: "e12", source: "1", target: "2", type: edgeType, animated: true },
  { id: "e13", source: "1", target: "3", type: edgeType, animated: true },
  { id: "e22a", source: "2", target: "2a", type: edgeType, animated: true },
  { id: "e22b", source: "2", target: "2b", type: edgeType, animated: true },
  { id: "e22c", source: "2", target: "2c", type: edgeType, animated: true },
  { id: "e2c2d", source: "2c", target: "2d", type: edgeType, animated: true },
  { id: "e45", source: "4", target: "5", type: edgeType, animated: true },
  { id: "e56", source: "5", target: "6", type: edgeType, animated: true },
  { id: "e57", source: "5", target: "7", type: edgeType, animated: true },
];

const careerJsonArray = [
  {
    id: "edges-1",
    type: "input",
    data: {
      label: (
        <>
          <strong>Role Name!</strong>
          <div>Sub role</div>
        </>
      ),
    },
    position: { x: 250, y: 0 },
  },
  { id: "edges-2", data: { label: "Node 2" }, position: { x: 150, y: 100 } },
  { id: "edges-2a", data: { label: "Node 2a" }, position: { x: 0, y: 180 } },
  { id: "edges-3", data: { label: "Node 3" }, position: { x: 250, y: 200 } },
  { id: "edges-4", data: { label: "Node 4" }, position: { x: 400, y: 300 } },
  { id: "edges-3a", data: { label: "Node 3a" }, position: { x: 150, y: 300 } },
  { id: "edges-5", data: { label: "Node 5" }, position: { x: 250, y: 400 } },
  {
    id: "edges-6",
    type: "output",
    data: { label: "Output 6" },
    position: { x: 50, y: 550 },
  },
  {
    id: "edges-7",
    type: "output",
    data: { label: "Output 7" },
    position: { x: 250, y: 550 },
  },
  {
    id: "edges-8",
    type: "output",
    data: { label: "Output 8" },
    position: { x: 525, y: 600 },
  },
  {
    id: "edges-e1-2",
    source: "edges-1",
    target: "edges-2",
    label: "bezier edge (default)",
    className: "normal-edge",
  },
  {
    id: "edges-e2-2a",
    source: "edges-2",
    target: "edges-2a",
    type: "smoothstep",
    label: "smoothstep edge",
  },
  {
    id: "edges-e2-3",
    source: "edges-2",
    target: "edges-3",
    type: "smoothstep",
    label: "step edge",
  },
  {
    id: "edges-e3-4",
    source: "edges-3",
    target: "edges-4",
    type: "smoothstep",
    // label: "straight edge",
  },
  {
    id: "edges-e3-3a",
    source: "edges-3",
    target: "edges-3a",
    type: "smoothstep",
    // label: "label only edge",
    // style: { stroke: "none" },
  },
  {
    id: "edges-e3-5",
    source: "edges-4",
    target: "edges-5",
    // animated: true,
    // label: "animated styled edge",
    // style: { stroke: "red" },
    type: "smoothstep",
  },
  {
    id: "edges-e5-6",
    source: "edges-5",
    target: "edges-6",
    // label: "styled label",
    labelStyle: { fill: "red", fontWeight: 700 },
    arrowHeadType: "arrow",
    type: "smoothstep",
  },
  {
    id: "edges-e5-7",
    source: "edges-5",
    target: "edges-7",
    // label: "label with styled bg",
    labelBgPadding: [8, 4],
    labelBgBorderRadius: 4,
    labelBgStyle: { fill: "#FFCC00", color: "#fff", fillOpacity: 0.7 },
    arrowHeadType: "arrow",
    type: "smoothstep",
  },
  {
    id: "edges-e5-8",
    source: "edges-5",
    target: "edges-8",
    type: "smoothstep",
    data: { text: "custom edge" },
    arrowHeadType: "arrowclosed",
  },
];

const testDataEgde1 = [
  {
    id: "1",
    type: "input",
    data: { label: "input" },
    position: { x: 200, y: 0 },
  },
  {
    id: "2",
    data: { label: "node 2" },
    position: { x: 200, y: 180 },
  },
  {
    id: "3",
    data: { label: "node 3" },
    position: { x: 200, y: 280 },
    // targetPosition: "bottom",
  },
  {
    id: "4",
    type: "customnode",
    data: { label: "custom node", title: "Main Title", subTitle: "Sub Title" },
    position: { x: 200, y: 380 },
  },
  {
    id: "5",
    type: "customnode",
    data: { label: "custom node", title: "Main Title", subTitle: "Sub Title" },
    position: { x: 200, y: 480 },
    // targetPosition: "top",
  },
  {
    id: "6",
    type: "customnode",
    data: { label: "custom node", title: "Main Title", subTitle: "Sub Title" },
    position: { x: 400, y: 480 },
    // targetPosition: "top",
  },
  { id: "e12", source: "1", target: "2", type: edgeType },
  {
    id: "e23",
    source: "2",
    target: "3",
    type: edgeType,
    arrowHeadType: "arrowclosed",
  },
  {
    id: "e4-3",
    source: "4",
    target: "3",
    type: edgeType,
    arrowHeadType: "arrowclosed",
  },
  {
    id: "e4-5",
    source: "4",
    sourceHandle: "sourceBottom",
    target: "5",
    type: edgeType,
    arrowHeadType: "arrowclosed",
    targetHandle: "targetTop",
    style: { stroke: "#000" },
  },
  {
    id: "e5-4",
    source: "5",
    sourceHandle: "sourceTop",
    target: "4",
    type: edgeType,
    arrowHeadType: "arrowclosed",
    targetHandle: "targetBottom",
    style: { stroke: "#000" },
  },
  {
    id: "e5-6",
    source: "5",
    sourceHandle: "sourceRight",
    target: "6",
    type: edgeType,
    arrowHeadType: "arrowclosed",
    targetHandle: "targetLeft",
    style: { stroke: "#000" },
  },
];

export default (...props) => {
  return (
    <Layout>
      <ReactFlowProvider>
        <CareerProgressionComponent {...props} />
      </ReactFlowProvider>
    </Layout>
  );
};

export const CareerProgressionComponent = () => {
  const { setFullLoader } = useFullLoader();
  const nodes = useStoreState((store) => store.nodes);

  const [data, setData] = useState(null);
  const reactFlowWrapper = useRef(null);

  const nodeTypes = {
    customnode: FlowNode,
  };

  const getLocalData = async () => {
    setFullLoader(true);
    try {
      // const res = await RoleServices.getCareerList();
      // const resMapped = res.map((el) =>
      //   !!el.data
      //     ? {
      //         ...el,
      //         position: {
      //           x: 0,
      //           y: 0,
      //         },
      //       }
      //     : { ...el }
      // );
      // const assignDepth = (arr, depth = 0, index = 0) => {
      //   if (index < 4) {
      //     arr[index].depth = depth;
      //     console.log(arr);
      //     if (arr[index].hasOwnProperty("child")) {
      //       return assignDepth(arr[index].child, depth + 1, 0);
      //     }
      //     return assignDepth(arr, depth, index + 1);
      //   }
      //   return;
      // };
      // assignDepth([res.master]);
      // console.log(res);
      // setData(res);

      var dataArray = [
        {
          name: "master name",
          child: [
            {
              name: "child 1a",
              child: [
                { name: "child 1a2a" },
                {
                  name: "child 1a2b",
                  child: [
                    { name: "child 2b3a" },
                    {
                      name: "child 2b3b",
                      child: [
                        { name: "child 3b4a" },
                        { name: "child 3b4b" },
                        { name: "child 3b4c" },
                      ],
                    },
                    { name: "child 2b3c" },
                  ],
                },
                { name: "child 1a2c" },
              ],
            },
            {
              name: "child 1b",
              child: [
                { name: "child 1b2a" },
                { name: "child 1b2b" },
                { name: "child 1b2c" },
              ],
            },
          ],
        },
      ];
      // console.log(dataArray);
      // const assignDepth = (arr, row = 0, index = 0) => {
      //   // console.log(index, arr.length);
      //   if (index < arr.length) {
      //     console.log(arr[index], index, arr.length);
      //     // arr[index].row = row;
      //     for (let i = 0; i < arr.length; i++) {
      //       console.log(arr);
      //       return (arr[i].row = row);
      //     }
      //     if (arr[index].child?.length) {
      //       return assignDepth(arr[index].child, row + 1, 0);
      //     }
      //     return assignDepth(arr, row, index + 1);
      //   }
      //   // console.log(arr);
      //   return;
      // };
      // await assignDepth(dataArray);
      const mapData = [];
      const mapEdgeData = [];
      const nodeWidth = 160;
      let updateMapData = [];
      const totalCoumnCount = 6;
      let ranking = { 1: 1 };
      let addRowColumn = (data, row = 1) => {
        data.forEach((item, itemIndex) => {
          // console.log(item, item.child, item.child?.length, !!item.child);
          const childLength = !!item.child ? item.child?.length : 0;
          item.row = row;
          item.column = ranking[row];
          const positionX = ranking[row] * nodeWidth;
          const columnCount = 6;
          // if (item.child && item.child.length) {
          //   for (let i = 0; i <= item.child.length - 1; i++) {
          //     const childCount = item.child.length;
          //     const childCountHalf = item.child.length / 2;

          //     let Xvalue = 0;
          //     if ((columnCount - childCount) / 2 === 0) {
          //       Xvalue = (columnCount / 2) * 80;
          //       item.colX = (6 - 2) / 2;
          //       item.position = {
          //         x: (columnCount / 2) * 80,
          //         y: row * 100,
          //       };
          //     } else {
          //       Xvalue = ((columnCount - childCount) / 2) * nodeWidth;
          //       item.colX = (columnCount - childCount) / 2;

          //       item.position = {
          //         x: (columnCount / 2) * 80,
          //         y: row * 100,
          //       };
          //     }
          //   }
          // } else {
          //   item.position = { x: positionX * childLength, y: row * 100 };
          // }
          item.position = { x: positionX, y: row * 100 };
          ranking[row] += 1;

          mapData.push(item);

          if (item.child && item.child.length) {
            for (let i = 0; i <= item.child.length - 1; i++) {
              // console.log(item.name, item.child[i]);
              const edge = {
                id: "e-" + item.id + "-" + item.child[i].id,
                source: item.id,
                target: item.child[i].id,
                type: edgeType,
              };
              mapEdgeData.push(edge);
            }
            ranking[row + 1] = ranking[row + 1] || 1;
            addRowColumn(item.child, row + 1);
          }
        });
      };

      addRowColumn(DataArrayValue);
      var maxNumberVolumn = Math.max(...mapData.map((item) => item.column));
      var maxNumberRow = Math.max(...mapData.map((item) => item.row));
      let mapColData = mapData.map((el) => {
        return {
          ...el,
          row: Math.abs(el.row - (maxNumberRow + 1)),
          sourcePosition: "top",
          targetPosition: "bottom",
          position: {
            x:
              el.row === 1
                ? (maxNumberVolumn / 2) * nodeWidth + nodeWidth / 2
                : !!el.child
                ? Math.round(
                    el.child[el.child.length - 1].column - el.child.length / 2
                  ) * nodeWidth
                : el.position.x,
            y: Math.abs(el.row - (maxNumberRow + 1)) * 100,
          },
          updateCol: !!el.child
            ? Math.round(
                el.child[el.child.length - 1].column - el.child.length / 2
              )
            : 0,
        };
      });
      console.log(mapColData, mapEdgeData);
      setData([...mapColData, ...mapEdgeData]);
      // const assignDepth = (arr, row = 0) => {
      //   // console.log(arr[index]);
      //   if (arr[row].child) {
      //     // console.log(arr[row].child, row + 1);
      //     assignDepth(arr[row].child, row + 1);
      //   }

      // for (let index = 0; index < arr.length; index++) {
      //   console.log(arr);
      //   return (arr[index].row = index);
      // }
      // };
      // await assignDepth(dataArray);

      // const mapArray = (arr) => {
      //   return arr.map((el, i) => {
      //     // return !!el.child && 3 <= i ? mapArray(dataArray) : { ...el, row: i };
      //     if (!!el.child) {
      //       return el.child.map((elem, idx) => {
      //         return { ...elem, row: i + 1 };
      //       });
      //     }
      //     return { ...el, row: i };
      //   });
      // };

      // console.log(mapData, updateMapData);
      // console.log(DataArrayValue, mapData, mapEdgeData);
      // console.log(JSON.stringify(dataArray, undefined, 2));
    } catch (error) {
      console.log(error);
    } finally {
      setFullLoader(false);
    }
  };

  useEffect(() => {
    getLocalData();
  }, []);

  // console.log(nodes);
  const connectionLineStyle = { stroke: "#fff" };
  return (
    <>
      <H2>Career Progressions</H2>
      <BodyContent>
        <Container>
          <ReactFlowWrapper>
            {!!data && (
              <ReactFlow
                elements={data}
                nodeTypes={nodeTypes}
                markerEndId="my-marker"
              >
                {/* <Controls /> */}
              </ReactFlow>
            )}
            <svg width="0" height="0">
              <defs>
                <marker
                  className="react-flow__arrowhead"
                  id="my-marker"
                  markerWidth="14.5"
                  markerHeight="14.5"
                  viewBox="-10 -10 20 20"
                  orient="auto"
                  refX="0"
                  refY="0"
                >
                  <polyline
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                    fill="#000"
                    points="-5,-4 0,0 -5,4 -5,-4"
                  />
                </marker>
              </defs>
            </svg>
          </ReactFlowWrapper>
        </Container>
      </BodyContent>
    </>
  );
};
