import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import COLORS from "../../assets/colors";
import ToolBar from "./ToolBar";

const AntTabs = withStyles({
  root: {
    backgroundColor: "transparent",
    borderBottom: `1px solid ${COLORS.ASH_RED}`,
    marginBottom: 30,
  },
  indicator: {
    backgroundColor: COLORS.PRIMARY_RED,
    borderRadius: 4,
    bottom: -2,
    height: 4,
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    color: COLORS.TEXT_BLUE,
    fontSize: 14,
    fontFamily: "inherit",
    fontWeight: 600,
    marginRight: theme.spacing(4),
    "&:hover": {
      color: COLORS.TITLE_BLUE,
      opacity: 1,
    },
    "&$selected": {
      color: COLORS.TITLE_BLUE,
      fontWeight: 700,
    },
    "&:focus": {
      color: COLORS.TITLE_BLUE,
    },
  },
  selected: {},
}))((props) => 
    <ToolBar title={!!props.tooltip ? props.tooltip : ""} arrow={true}>
      <Tab disableRipple {...props} />
    </ToolBar>
);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  padding: {
    padding: theme.spacing(3),
  },
  custom: {
    backgroundColor: "transparent",
    position: "relative",
    "& .right-element": {
      cursor: "pointer",
      position: "absolute",
      top: 0,
      lineHeight: 3.5,
      right: 0,
    },
  },
}));

export default ({ toolTipTitle = [], tabTitle, getTabNumber, currentTab = 0, children }) => {
  const classes = useStyles();
  const handleChange = (event, newValue) => getTabNumber(newValue);

  return (
    <div className={classes.root}>
      <div className={classes.custom}>
        <AntTabs
          value={currentTab}
          onChange={handleChange}
          aria-label="ant example"
        >
          {tabTitle.map((el, i) => (
            <AntTab label={el} tooltip={!!toolTipTitle[i] ? toolTipTitle[i] : ""} key={i} />
          ))}
        </AntTabs>
        {children}
      </div>
    </div>
  );
};
