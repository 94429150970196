import config from "../api/config";
import APIV2 from "../api/managerV2";

const bulkImportJDV3 = (formData, clientId) => {
    return APIV2(
      {
        method: "POST",
        url: `${config.urls.auth.bulkImportJDV3}?clientId=${clientId}`,
        data: formData,
      },
      true,
      0
    );
};

const bulkImportJDCompleteV3 = (clientId) => {
    return APIV2(
      {
        method: "POST",
        url: `${config.urls.auth.bulkImportJDCompleteV3}?clientId=${clientId}`
      },
      true,
    );
};

const importPersonaFromUrlV3 = (clientId, url) => {
    return APIV2(
      {
        method: "POST",
        url: `${config.urls.auth.importPersonaFromUrlV3}?ClientId=${clientId}&Url=${url}` 
      },
      true,
    );
}

const getPersonasClientSkillsTemplateUrlV3 = () => {
  return APIV2(
    {
      method: "GET",
      url: config.urls.auth.personasClientSkillTemplateUrlV3
    },
    true,
  );
}

const getBulkImportJDParserStatusV3 = (clientId) => {
  return APIV2(
    {
      method: "POST",
      url: `${config.urls.auth.bulkImportJDParserStatusV3}?clientId=${clientId}`
    },
    true,
  );
}
const parsePersonaJDV3 = (fileData) => {
  return APIV2(
    {
      method: "POST",
      url: `${config.urls.auth.parsePersonaJDV3}`,
      data: fileData
    },
    true
  );
}

const getInferredSkillsV3 = (personaId) => {
  return APIV2(
    {
      method: "GET",
      url: `${config.urls.auth.getInferredSkillsV3.replace("{personaId}", personaId)}`
    },
    true
  );
}

export const PersonaService = {
    bulkImportJDV3,
    bulkImportJDCompleteV3,
    importPersonaFromUrlV3,
    getPersonasClientSkillsTemplateUrlV3,
    getBulkImportJDParserStatusV3,
    parsePersonaJDV3,
    getInferredSkillsV3
}