import { useCallback, useEffect, useState } from "react";
import { FileUploaderStore } from "../../../store/store_state";
import { convertToBytes, getFileExtensionFromFileName, isValidFileType } from "../../../helpers/FileUploadHelper";
import text from "../../../locale.en.json";
import useAlert from "../../../../src/Hooks/useAlert";
import {AlertType} from  "../../../../src/enums/AlertType";

const useFileUploader = ({allowedFileTypes, multiSelect, maxFileSize, error, setError }) => {
  const { showAlert } = useAlert()
  const selectedFiles = FileUploaderStore.useState(s => s.selectedFiles);
  const [customError, setCustomError] = useState("");
  const [key, setKey] = useState(new Date());

  const handleFiles = useCallback(
    (files) => {
      if(!files?.length) return;

      if(!multiSelect && (!!selectedFiles?.length) ){
        showAlert(text.fileSelection.removeCurrentFile, AlertType.ERROR, "1500");
        setCustomError("");
        setError("");
        return;
      }

      if(files?.length > 1) {
        showAlert(text.fileSelection.onlyOneFileCanBeSelected, AlertType.ERROR, "1500");
        setCustomError("");
        setError("");
        return;
      }

      //if multiselect is false
      const file = files[0];
      const extension = getFileExtensionFromFileName(file.name);
      if(!isValidFileType(extension, allowedFileTypes)){
        setCustomError(text.fileSelection.invalidFormat);
      } else if(maxFileSize &&  file.size > convertToBytes(maxFileSize)) {
        setCustomError(text.fileSelection.fileSizeExceedsLimit.replace("{{size}}", maxFileSize));
      } else {
          setCustomError("");
          setError("");
          FileUploaderStore.update((s) => {
            s.selectedFiles = [file]
          })
      }

    },
    [selectedFiles]
  );

  const handleDrop = useCallback(
    (event) => {
      event.preventDefault();
      const files = event.dataTransfer.files;
      handleFiles(files);
    },
    [handleFiles]
  );

  const handleBrowse = useCallback(
    (event) => {
      const files = event.target.files;
      handleFiles(files);
    },
    [handleFiles]
  );

  const updateKey = () => {
    setKey(new Date()); 
  }

  useEffect(() => {
    if(!!error){
      FileUploaderStore.update((s) => {
        s.selectedFiles = []
      })
      updateKey(); 
    }
  }, [error])

  const deleteFile = (index) => {
    FileUploaderStore.update((s) => {
        s.selectedFiles = selectedFiles.filter((file, i) => i !== index);
    });
    updateKey(); 
  };

  return { handleDrop, handleBrowse,  deleteFile, customError, key };
};

export default useFileUploader;
