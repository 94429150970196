import React from "react";
import styled from "styled-components";
import { H2 } from "../Common/Typography";
import { PrimaryButton } from "../Common/Buttons/PrimaryButton";
import { Images } from "../../assets/images";
import COLORS from "../../assets/colors";

export default ({ pageSize, handlePageSize, count }) => {
  return (
    <DetailHeader>
      <H2>Annotate Jobs</H2>
      <div className="button-block">
        <NavButton
          style={{ background: "transparent", border: "solid 1px #c5c9d9" }}
          onClick={() => handlePageSize(Number(pageSize) - 1)}
          disabled={Number(pageSize) === 0}
        >
          <img src={Images.icons.lftArrowIcon} alt="prev" />
        </NavButton>
        <div className="counter">
          {Number(pageSize) + 1} / {count}
        </div>
        <NavButton
          onClick={() => handlePageSize(Number(pageSize) + 1)}
          disabled={Number(count) === Number(pageSize) + 1}
        >
          <img src={Images.icons.ritArrowIcon} alt="next" />
        </NavButton>
      </div>
    </DetailHeader>
  );
};

const DetailHeader = styled.div`
  border-bottom: solid 1px ${COLORS.BLUE_ASH};
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-right: 32px;
  .button-block {
    display: flex;
    margin: 0 -5px;
    button {
      height: 25px;
      width: 25px;
    }
  }
  .counter {
    color: ${COLORS.PRIMARY_WHITE};
  }
`;

const NavButton = styled(PrimaryButton)`
  padding: 5px !important;
  min-height: 24px !important;
  min-width: 24px !important;
  margin: 0px 5px !important;
`;
