import React, { useEffect, useState } from "react";
import { navigate } from "@reach/router";

import ReactFlow, { ReactFlowProvider, isNode } from "react-flow-renderer";
import dagre from "dagre";
import multiHandleNode from "./MultiHandleNode";
import bidirectionalEdge from "./BidirectionalEdge";

import { BodyContent, Container } from "../../../Common/GridSystem";
import { H2, H4, H6 } from "../../../Common/Typography";
import Layout from "../../../Layout";
import { PrimaryButton } from "../../../Common/Buttons/PrimaryButton";
import styled from "styled-components";
import COLORS from "../../../../assets/colors";
import DotLoader from "../../../Common/Loader/DotLoader";
import { RoleServices } from "../../../../services/RoleServices";
import useAlert from "../../../../Hooks/useAlert";
import BackNav from "../../../Common/BackNav";
import { Images } from "../../../../assets/images";
import { HandleNodeWrapper } from "./MultiHandleNode";

const isLateralRole = (d) => (d.direction || "").toLowerCase() === "lateral";

const getChildSafely = (node) => {
  return [].concat((node || {}).child || []);
};

const getRenderElements = (root, rank) => {
  if (!root) {
    return [];
  }
  const sourceId = root.roleId;
  let nodes = [
    {
      id: sourceId,
      data: {
        clusterName: root.roleName,
        occupation: root.occupation,
        isMasterNode: rank === 0,
        department: root.function,
      },
      position: { x: 0, y: 0 },
      direction: root.direction,
      type: "multiHandleNode",
      rank: isLateralRole(root) ? rank - 1 : rank,
    },
  ];
  getChildSafely(root).forEach((node, i) => {
    const targetId = node.roleId;
    // console.log(node);
    nodes = nodes.concat({
      id: `e_${sourceId}_${targetId}`,
      source: sourceId,
      target: targetId,
      direction: node.direction,
      arrowHeadType: "arrowclosed",
      sourceHandle: isLateralRole(node) ? "right" : "top",
      targetHandle: isLateralRole(node) ? "left" : "bottom",
      type:
        node?.bidirectional.toLowerCase() === "yes"
          ? "bidirectionalEdge"
          : node.direction === "Lateral"
          ? "straight"
          : "smoothstep",
      style: {
        strokeWidth: "3px",
        ...(isLateralRole(node) ? { strokeDasharray: "5,5" } : {}),
      },
    });
    nodes = nodes.concat(getRenderElements(node, rank + 1));
  });
  return nodes;
};

const isRoleNode = (node) => isNode(node) || node.type === "multiHandleNode";

const getLayoutedElements = (data) => {
  let elements = Object.values(
    getRenderElements(data?.master, 0).reduce(
      (res, curr) => ({ ...res, [curr.id]: curr }),
      {}
    )
  );

  const targetEdgeMap = elements
    .filter((e) => typeof e.target !== "undefined")
    .reduce((res, curr) => ({ ...res, [curr.target]: curr }), {});

  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));
  dagreGraph.setGraph({
    rankdir: "BT",
    edgesep: 50,
    nodesep: 100,
    ranksep: 50,
  });

  elements.forEach((el) => {
    if (isRoleNode(el)) {
      dagreGraph.setNode(el.id, {
        width: 300,
        height: 150,
      });
    } else if (isLateralRole(el)) {
      //find non-lateral parent
      let currParent = targetEdgeMap[el.source];

      // dagreGraph.setGraph({ align: "UL" });
      while (currParent && isLateralRole(currParent)) {
        currParent = targetEdgeMap[currParent.source];
      }
      if (currParent) {
        dagreGraph.setEdge(currParent.source, el.target);
      }
    } else {
      dagreGraph.setEdge(el.source, el.target);
    }
  });
  dagre.layout(dagreGraph);

  elements.forEach((el) => {
    if (isRoleNode(el)) {
      const nodeWithPosition = dagreGraph.node(el.id);
      console.log("getLayoutedElements -> nodeWithPosition", nodeWithPosition);
      // unfortunately we need this little hack to pass a slighltiy different position
      // in order to notify react flow about the change
      el.position = {
        x: nodeWithPosition.x + Math.random() / 1000,
        y: nodeWithPosition.y,
      };

      if (isLateralRole(el) && !!targetEdgeMap[el.id]) {
        //check for the node position and assign the

        const sourceElId = targetEdgeMap[el.id].source;
        const edgeId = `e_${sourceElId}_${el.id}`;
        const edge = elements.filter((e) => e.id === edgeId).pop();
        const sourceNodePosition = dagreGraph.node(sourceElId);
        if (!!edge) {
          edge.sourceHandle =
            sourceNodePosition.x < nodeWithPosition.x ? "right" : "left";
          edge.targetHandle =
            sourceNodePosition.x < nodeWithPosition.x ? "left" : "right";
        }
      }
    }

    elements.forEach((el) => {
      if (isRoleNode(el)) {
        //get all the handles that have connectors
        //the list contains 'left', 'right', 'top', 'bottom'
        const visibleHandles = Object.keys(
          elements.reduce(
            (res, curr) => ({
              ...res,
              ...(curr.source === el.id
                ? { [`source_${curr.sourceHandle}`]: "" }
                : {}),
              ...(curr.target === el.id
                ? { [`target_${curr.targetHandle}`]: "" }
                : {}),
            }),
            {}
          )
        );
        el.data.visibleHandles = visibleHandles;
      }
    });
    return el;
  });
  return elements;
};

export default (props) => {
  const { careerRoleId } = props;
  const { showAlert } = useAlert();

  const [data, setData] = useState(null);
  const [roleId, setRoleId] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getRoleCareerProgression(careerRoleId);
  }, []);

  const getRoleCareerProgression = async (selectedRoleId) => {
    try {
      if (selectedRoleId != roleId) {
        setIsLoading(true);
        const id = "/" + selectedRoleId;
        const data = await RoleServices.getCareerDataV3(id);
        console.log("getRoleCareerProgression -> data", data);
        setData(data);
        setRoleId(selectedRoleId);
        setIsLoading(false);
      }
    } catch (errors) {
      console.log(errors);
      await setIsLoading(false);

      if (careerRoleId === selectedRoleId) {
        await showAlert(errors, "error", 2000);
        setTimeout(() => navigate(-1), 2500);
      }
    } finally {
      setIsLoading(false);
    }
  };
  if (isLoading) {
    return (
      <Backdrop>
        <DotLoader />
      </Backdrop>
    );
  }
  return (
    <Layout>
      <CareerProgressionComponent
        key={roleId}
        data={data}
        width={"80vw"}
        height={"80vh"}
        onRoleSelect={getRoleCareerProgression}
      />
      <ResetButton onClick={() => getRoleCareerProgression(careerRoleId)}>
        Reset
      </ResetButton>
      <LegendBox>
        <H4>Legends</H4>
        <div className="arrow-part ">
          <H6>Vertical Progression</H6>
        </div>
        <div className="arrow-part right-arrow">
          <H6>Lateral Progression</H6>
        </div>
        <HandleNodeWrapper
          isMasterNode={false}
          style={{
            width: "auto",
            boxShadow: "0 1px 2px 0px #9b9a9a",
            marginBottom: 10,
          }}
        >
          <div className="occupation">Department / Track</div>
          <div className="clusterName">Industry Role</div>
        </HandleNodeWrapper>
        <HandleNodeWrapper
          isMasterNode
          style={{ width: "auto", boxShadow: "0 1px 2px 0px #9b9a9a" }}
        >
          <div className="occupation">Department / Track</div>
          <div className="clusterName">Industry Role</div>
        </HandleNodeWrapper>
      </LegendBox>
    </Layout>
  );
};

const ResetButton = styled(PrimaryButton)`
  background: ${COLORS.DARK_BLUE} !important;
  position: absolute !important;
  right: 15px;
  bottom: 15px;
  z-index: 9;
`;

const LegendBox = styled.div`
  border-radius: 5px;
  padding: 0px 5px;
  position: absolute !important;
  right: 15px;
  top: 75px;
  z-index: 9;
  .occupation,
  .clusterName {
    padding: 2px;
    font-weight: normal;
    font-size: 12px;
  }
  h4 {
    padding-bottom: 0px;
  }
  & > div.arrow-part {
    display: flex;
    align-items: center;
    margin: 8px 5px;
    padding: 0px 0px 0px 26px;
    position: relative;

    &:before {
      background: url(${Images.icons.upArrow}) left top no-repeat;
      background-size: 50%;
      content: "";
      position: absolute;
      left: 0px;
      top: 0px;
      width: 20px;
      height: 20px;
    }
    &.right-arrow {
      &:before {
        background: url(${Images.icons.rightArrowDashed}) left center no-repeat;
        background-size: 100%;
        width: 24px;
      }
    }
    h5,
    h6 {
      padding: 0px 0px 0px 5px;
    }
    svg {
      transform: scale(0.4);
    }
    .darkShade {
      background: linear-gradient(
        to right,
        #fa1565 0%,
        #fa1565 50%,
        #7314ad 50%,
        #7314ad 50%,
        #6465ca 100%
      );
      border-radius: 5px;
      height: 20px;
      width: 20px;
      margin-right: 5px;
    }
    .lightShade {
      background: linear-gradient(
        to right,
        #fce5eb 0%,
        #fce5eb 50%,
        #fce5eb 50%,
        #dde1fb 50%,
        #dde1fb 100%
      );
      border-radius: 5px;
      height: 20px;
      width: 20px;
      margin-right: 5px;
    }
  }
`;

const Backdrop = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${COLORS.BODY_BLUE};
  z-index: 9999999;
  top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CareerProgressionComponent = ({
  data = {},
  width = "90vw",
  height = "90vh",
  onRoleSelect = () => {},
}) => {
  const onLoad = async (reactFlowInstance) => {
    const nodeLength =
      (await reactFlowInstance.getElements()?.filter(({ data }) => !!data)
        ?.length) || 0;

    const hasLateralNode =
      (await reactFlowInstance
        .getElements()
        ?.some((el) => el.direction === "Lateral")) || false;

    console.log(nodeLength, reactFlowInstance.getElements(), hasLateralNode);

    if (hasLateralNode) {
      reactFlowInstance.fitView({
        padding:
          nodeLength < 3
            ? 1.8
            : nodeLength === 3
            ? 1
            : nodeLength === 5
            ? 0.3
            : nodeLength === 6
            ? 0.2
            : 0.1,
      });
    } else {
      reactFlowInstance.fitView({
        padding:
          nodeLength < 3
            ? 1.8
            : nodeLength === 3
            ? 0.8
            : nodeLength === 4
            ? 0.3
            : 0.1,
      });
    }
  };
  const elements = getLayoutedElements(data);
  return (
    <>
      <H2>Career Progressions</H2>
      <BodyContent>
        <Container>
          <BackNav />
          <ReactFlowWrap height={height}>
            <ReactFlowProvider>
              <ReactFlow
                nodesDraggable={false}
                nodesConnectable={false}
                elements={elements}
                onLoad={onLoad}
                nodeTypes={{ multiHandleNode }}
                edgeTypes={{ bidirectionalEdge }}
                zoomOnScroll={false}
                onElementClick={(e, element) => onRoleSelect(element.id)}
              />
            </ReactFlowProvider>
          </ReactFlowWrap>
        </Container>
      </BodyContent>
    </>
  );
};

const ReactFlowWrap = styled.div`
  height: ${(props) => props.height};
  width: 100%;
`;
