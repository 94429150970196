import React, { useEffect, useState } from "react";
import { AuthService } from "../../services/AuthService";
import SelectMenu from "../Common/FormElements/SelectMenu";
import { SortString } from "../../helpers/SortString";
import { IndustryRoleListStore } from "../../store/store_state";
import useRoles from "../../Hooks/useRoles";
import { RoleServices } from "../../services/RoleServices";

export default ({
  width = 170,
  label = "Industry Role",
  handleSelect,
  value = "Any",
  subSector = "Any",
  funVal = "Any",
  disabled = false,
  labelShow = true,
  anyVal = true,
  passClientId = false,
}) => {
  const { clientName } = useRoles();

  const [industryRoleList, setIndustryRoleList] = useState([]);
  useEffect(() => {
    // const initData = {
    //   sector: [],
    //   subSectorIds: subSector !== "Any" ? [subSector] : [],
    //   functionIds: funVal !== "Any" ? [funVal] : [],
    //   framework: [],
    // };

    const initData = {
      roleName: "",
      subSectorIds: subSector !== "Any" ? [subSector] : [],
      functionIds: funVal !== "Any" ? [funVal] : [],
      occupations: [],
      roleStatuses: [4],
      // framework: [],
      // sector: [],
    };

    const postData = passClientId
      ? { ...initData, clientId: Number(clientName) }
      : initData;

    const getIndustryRoles = async () => {
      // console.log(subSector, funVal);
      try {
        const res = await RoleServices.searchRoleV3(postData);
        // console.log(initData, res?.roles);
        const sorted = SortString(res?.roles, "roleName");
        anyVal && sorted.unshift({ roleId: 0, roleName: "Any" });
        setIndustryRoleList(SortString(res?.roles, "roleName"));
        IndustryRoleListStore.update((s) => {
          s.IndustryRoleList = SortString(res?.roles, "roleName");
        });

        // console.log(value, res?.roles);
      } catch (error) {
        console.log(error);
      }
    };

    if (subSector !== "Any" || funVal !== "Any") {
      getIndustryRoles();
    } else if (subSector === "Any" && funVal === "Any") {
      // console.log("both null");
      setIndustryRoleList(null);
      IndustryRoleListStore.update((s) => {
        s.IndustryRoleList = [];
      });
    }
    // console.log("subSector, funVal", subSector, funVal);

    return () => {
      setIndustryRoleList(null);
    };
  }, [subSector, funVal]);
  // console.log(industryRoleList);
  return (
    <SelectMenu
      width={width}
      label={label}
      value={value}
      list={
        !!industryRoleList
          ? industryRoleList.map((el, i) => ({
              label: el.roleName,
              value: el.roleId,
            }))
          : []
      }
      handleSelect={handleSelect}
      disabled={!industryRoleList || industryRoleList.length === 0}
      labelShow={labelShow}
    />
  );
};
