import React, { forwardRef } from "react";
import { Formik, Form } from "formik";
import FormTextField from "../../Common/FormElements/FormTextField";
import { personaValidation } from "../../../validations/personaValidation";
import AutoSelect from "../../Common/FormElements/AutoSelect";
import { PlaceholderText } from "./../../utils";
import usePersonaForm from "./usePersonaForm";
import './PersonaForm.scss'

const PersonaForm = forwardRef((props, ref) => {
  
  const {
    initData,
    groupList,
    checkPersonaNameExists,
    personaList
  } = usePersonaForm({props});

  return (
    <div>
      <Formik
        innerRef={ref}
        initialValues={initData}
        validationSchema={personaValidation}
      >
        {({ setFieldValue, setFieldTouched }) => {
          return (
            <Form>
              <AutoSelect
                label="Group Name / Department Name"
                name="personaGroupId"
                list={groupList}
                renderOption={(option) => (
                  <div className="persona-form-option-label">{option.label}</div>
                )}
                optionLabel={"label"}
                valueLabel="value"
                setVal={setFieldValue}
                setTouched={setFieldTouched}
                isRequired={true}
              />
              <FormTextField
                placeholder=""
                name="personaName"
                type="text"
                label="Client persona / role"
                setVal={setFieldValue}
                setTouched={setFieldTouched}
                customValidate={checkPersonaNameExists}
                isRequired={true}
              />
              <AutoSelect
                label="Reporting To"
                name="reportingTo"
                list={
                  !!personaList ? personaList.map((el) => el.personaName) : []
                }
                renderOption={(option) => <div className="persona-form-option-label">{option}</div>}
                setVal={setFieldValue}
                setTouched={setFieldTouched}
              />
              <FormTextField
                placeholder=""
                name={"roleDescription"}
                type="text"
                label="Role Description"
                multiline={true}
                rows="5"
                rowsMax="1000"
                setVal={setFieldValue}
                setTouched={setFieldTouched}
              />
              <FormTextField
                placeholder={PlaceholderText}
                name={"rolesResponsibilities"}
                type="text"
                label="Roles &amp; Responsibilities"
                multiline={true}
                rows="15"
                rowsMax="1000"
                setVal={setFieldValue}
                setTouched={setFieldTouched}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
});


export default PersonaForm;
