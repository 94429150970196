import React from "react";
import { SkillStatusMenu } from "../../utils";
import text from "../../../locale.en.json";


export const TableColumnsAssignSkills = ({
  skillType = "persona", 
  isNavigatedFromRoleMatch = false, 
  isIndustryRoleTable = false
}) => {
  return ( [
    {
      colKey: "checkbox",
      colValue: "checkbox",
      width: 60,
    },
    {
      colKey: "skillName",
      colValue: text.skill.skillName,
      width: 300
    },
    {
      colKey: "skillFamily",
      colValue: text.skill.skillFamily,
      colType: "column_render",
      width: 300,
      RenderColumn: (data) => {
        return <>{data?.skillFamily?.skillFamily}</>;
      },
    },
    {
      colKey: "skillCategory",
      colValue: text.skill.skillCategory,
      colType: "column_render",
      width: 300,
      RenderColumn: (data) => {
        return <>{data?.skillCategory?.skillCategory}</>;
      },
    },
    {
      colKey: "source",
      colValue: text.common.source,
      colType: "column_render",
      width: 300,
      RenderColumn: (data) => {
        return <>{data?.skillSource?.source}</>;
      },
    },
    {
      colKey: "skillStatus",
      colValue: text.common.status,
      colType: "column_render",
      width: 300,
      RenderColumn: (data) => {
        return (
          <>
            {SkillStatusMenu?.find((el) => el.value === data?.skillStatus)
              ?.label || ""}
          </>
        );
      },
    },
    (isIndustryRoleTable &&
      {
        colKey: "industryProficiencyLevel",
        colValue: text.common.industryProficiency,
        colType: "column_render",
        width: 425,
        RenderColumn: (data) => {
          return <>{data?.industryProficiencyLevel?.level}</>;
        },
      }
    ),
    {
      colKey: "target_proficiency",
      colMessage: (isIndustryRoleTable) ? text.common.defaultToIndustryProficiency : "",
      colValue: (skillType === "persona" || isNavigatedFromRoleMatch) ? text.common.targetProficiency : text.common.defaultToIndustryProficiency,
      editable: true,
      colType: "levelSelect",
      infoText: (skillType === "persona" || isNavigatedFromRoleMatch)
        ? text.persona.recommendedTargetProficiencyMessage 
        : text.role.industryFrameworkProficiencyColumnDescription,
      width: 300,
    },
  ])
}
