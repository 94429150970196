const APIVersion = {
  V1: "v1/",
  V2: "v2/",
  V3: "v3/",
};

const { V1, V2, V3 } = APIVersion;

const ApiConfig = {
  baseUrl: process.env.REACT_APP_BASE_API_URL_DEV,
  baseUrlV2: process.env.REACT_APP_BASE_API_URL_DEV_V2, // graph DB
  urls: {
    auth: {
      //version 1
      login: `${V1}account/login`,
      googleLogin: `${V1}account/verifyGoogleAuth`,
      users: `${V1}users`,
      clients: `${V1}clients`,
      clientsOrgStage: `${V1}clients/orgStage`,
      clientsInstanceRegion: `${V1}clients/instanceRegions`,
      jobSectors: `${V1}jobs/sectors`,
      jobSources: `${V1}jobs/sources`,
      subSectors: `${V1}industryRoles/subSectors`,
      functions: `${V1}industryRoles/functions`,
      frameworks: `${V1}industryRoles/frameworks`,
      getIndustryRoles: `${V1}getIndustryRoles`,
      industryRoles: `${V1}industryRoles`,
      leadershipRoles: `${V1}leadershipRoles`,
      jobs: `${V1}jobs`,
      jobsCount: `${V1}jobs/jobsCount`,
      annotateJob: `${V1}jobs/annotateJob`,
      annotateRole: `${V1}industryRoles/annotateRole`,
      getAllPersonas: `${V1}personas`,
      personaSearch: `${V1}personas/search`,
      getIndustrySuggestedRole: `${V1}industryRoles/getRoleSuggestions`,
      personaGroup: `${V1}personas/personaGroup`,
      getPersona: `${V1}personas`,
      getSkill: `${V1}skills`,
      getSuggestedskills: `${V1}skills/getSuggestedskills`,
      personaExport: `${V1}personas/exportAsExcel`,
      skillCategories: `${V1}skills/categories`,
      skillFamilies: `${V1}skills/skillFamilies`,
      skillSources: `${V1}skills/sources`,
      skillProficiencyLevels: `${V1}skills/proficiencyLevels`,
      roleExplorerData: `${V1}industryRoles/roleExplorerData`,
      getRoles: `${V1}industryRoles/search`,
      occupations: `${V1}industryRoles/occupations`,
      contentsProviders: `${V1}contents/providers`,
      contentsProvidersV3: `${V3}contentProviders`,
      contents: `${V1}contents`,
      skillCriticality: `${V1}skills/skillCriticality`,
      careerList: `${V1}industryRoles/careerProgressionData`,
      careerListTest: `${V1}industryRoles/careerProgressionDataTest`,
      personasExportToDisprz: `${V1}personas/exportToDisprz`,
      personasExportToDisprzStatus: `${V1}personas/exportToDisprzStatus`,

      // version 2
      getSkillV2: `${V2}skills`,
      createSkillTreeV2: `${V2}skills/createSkillTree`,
      getSkillTreeV2: `${V2}skills/getSkillTree`,

      // version 3
      loginV3: `${V3}account/login`,
      googleLoginV3: `${V3}account/verifyGoogleAuth`,
      getSkillV3: `${V3}skills`,
      familiesV3: `${V3}skills/families`,
      getSuggestedskillsV3: `${V3}skills/getSuggestedskills`,
      createSkillTreeV3: `${V3}skills`,
      deleteSkillTreeV3: `${V3}skills/`,
      checkSkillExistsV3: `${V3}skills/name/exists`,
      getSkillTreeV3: `${V3}skills/getSkillTree`,
      skillCategoriesV3: `${V3}skills/categories`,
      skillFamiliesV3: `${V3}skills/families`,
      skillSourcesV3: `${V3}skills/sources`,
      skillProficiencyLevelsV3: `${V3}skills/proficiencyLevels`,
      getSourcesV3: `${V3}sources`,
      usersV3: `${V3}users`,
      encyclopediaList: `${V3}encyclopedia`,
      disprzopedia: `${V3}disprzopedia`,
      disprzopediaMappingSample: `${V3}disprzopedia/sample-contents`,
      disprzopediaAllSample: `${V3}disprzopedia/contents`,
      encyclopediaTypesList: `${V3}encyclopedia/types`,
      clientsV3: `${V3}clients`,
      ORG_AttributesV3: `${V3}attributes/1/attributeValues`,
      InstanceRegion_AttributesV3: `${V3}attributes/8/attributeValues`,
      subSectorsV3: `${V3}subsectors`,
      subSectorV3: `${V3}subsector`,
      getAllPersonasV3: `${V3}personas`,
      personaSearchV3: `${V3}personas/search`,
      personasExportToDisprzStatusV3: `${V3}personas/export-disprz/status`,
      personaGroupV3: `${V3}personaGroup`,
      personaDetailV3: `${V3}personas`,
      getPersonaV3: `${V3}personas`,
      industryRolesV3: `${V3}industryRoles`,
      functionsV3: `${V3}industryRoles/functions`,
      functionCreateV3: `${V3}functions`,
      getIndustryRolesV3: `${V3}getIndustryRoles`,
      getRoleSearchV3: `${V3}industryRoles/search`,
      leadershipRolesV3: `${V3}leadershipRoles`,
      skillCriticalityV3: `${V3}skills/skillCriticality`,
      getIndustrySuggestedRoleV3: `${V3}industryRoles/getRoleSuggestions`,
      annotateRoleV3: `${V3}industryRoles/annotateRole`,
      roleExplorerDataV3: `${V3}industryRoles/roleExplorerData`,
      occupationsV3: `${V3}industryRoles/occupations`,
      rolseSelectionDataV3: `${V3}personas/bulk-import-data`,
      careerListV3: `${V3}industryRoles/careerProgressionData`,
      contentsV3: `${V3}contents`,
      assessments: `${V3}assessments`,
      assessmentTypes: `${V3}types`,
      assessmentSubTypes: `${V3}sub-types`,
      assessmentEvaluatorType: `${V3}evaluator-types`,
      importClientRolesV3: `${V3}personas/bulk-import`,
      discardRoleMatching: `${V3}personas/bulk-import/cancel`,
      partialSaveClientRoles: `${V3}personas/bulk/partial-save`,
      saveClientRoles: `${V3}personas/bulk`,
      importClientRolesStatus: `${V3}personas/bulk-import/status`,
      importPersonaDetails: `${V3}personas/bulk-import-data`,
      checkBulkImportEnabled: `${V3}personas/is-bulk-import-enabled`,
      importTemplateUrl: `${V3}personas/bulk-import-template`,
      personas: `${V3}personas`,
      wikisV3: `${V3}skills/suggestion/wikis`,
      skillDesctionSuggestionV3: `${V3}skills/suggestion/description`,
      skillProficiencyLevelDescriptionSuggestionV3: `${V3}skills/suggestion/proficiency-levels`,
      skillDetailedDescriptionSuggestionV3: `${V3}skills/suggestion/detailed-description`,
      roleDescriptionSuggestionV3: `${V3}industry-roles/suggestion/description`,
      workFunctionsAndTasksSuggetionV3: `${V3}industry-roles/suggestion/roles-responsibilities`,
      aiIndustryRolesSuggestionV3: `${V3}industry-roles`,
      sourceV3: `${V3}sources`,
      skillMappingTemplateV3: `${V3}personas/skill-import/template`,
      exportSkillDetailV3: `${V3}personas/architect-skills`,
      newClientSkillDataV3: `${V3}personas/bulk-import/skill-mapping/skills`,
      bulkPersonaExportStatusV3: `${V3}personas/export-excel/status`,
      roleSelectionExportStatusV3: `${V3}personas/role-selection/excel-export/status`,
      roleSelectionExportV3: `${V3}personas/role-selection/export`,
      personaNameExistsV3: `${V3}personas/name/exists`,
      bulkRoleSuggestionsV3: `${V3}industryRoles/bulk-roleSuggestions`,
      bulkImportJDV3: `${V3}personas/bulk-import/jd`,
      bulkImportJDCompleteV3: `${V3}personas/bulk-import/jd/complete`,
      importPersonaFromUrlV3: `${V3}personas/bulk-import/url`,
      personasClientSkillTemplateUrlV3: `${V3}personas/client-skills/bulk-import/template`,
      bulkImportJDParserStatusV3: `${V3}personas/bulk-import/jd/status`,
      parsePersonaJDV3: `${V3}personas/jd-parser`,
      getInferredSkillsV3: `${V3}personas/{personaId}/inferred-skills`,
      hasEvaluationAssessmentV3: `${V3}skills/{skillId}/proficiencies/{proficiencyId}/has-evaluation-assessment`
    },
  },
};

export default ApiConfig;
