import React from "react";
import ModalPopup from "../ModalPopup";
import usePopUp from "../../../Hooks/usePopUp";
import { PrimaryButton } from "../../Common/Buttons/PrimaryButton";
import { SecondaryButton } from "../../Common/Buttons/SecondaryButton";
import { H4 } from "../../Common/Typography";
import "./PopUp.scss";

const PopUp = () => {
  const { popUpState, closePopUp } = usePopUp();

  return (
    <ModalPopup
      open={popUpState.isOpen}
      title={popUpState.title}
      closePopup={closePopUp}
      center={popUpState.center}
      titleStyle={popUpState.titleStyle}
      maxWidth={popUpState.maxWidth}
    >
      <div
        className={`pop-up-message-container ${
          !popUpState.message && "empty-message"
        }`}
      >
        <H4>{popUpState.message}</H4>
      </div>
      <div className="pop-up-btn-block">
        {popUpState.noText && (
          <SecondaryButton onClick={closePopUp}>
            {popUpState.noText}
          </SecondaryButton>
        )}
        <PrimaryButton
          onClick={async () => {
            await closePopUp();
            setTimeout(() => {
              popUpState.onConfirm();
            }, 500);
          }}
        >
          {popUpState.yesText}
        </PrimaryButton>
      </div>
    </ModalPopup>
  );
};

export default PopUp;
