import React from "react";
import { SkillStatusMenu } from "../utils";

export const Role_Search_Table_Colmns = [
  {
    colKey: "roleName",
    colValue: "Role",
    sortable: true,
    searchable: false,
  },
  {
    colKey: "subsector",
    colValue: "Industry / Function",
    sortable: true,
    width: 200,
  },
  {
    colKey: "functionName",
    colValue: "Department / Track",
    sortable: true,
    width: 200,
  },
  {
    colKey: "occupation",
    colValue: "Occupation",
    sortable: true,
  },
  {
    colType: "inside_Function",
    colKey: "leadershipRoleName",
    colValue: "Leadership Role",
    width: 200,
    getTableData: (val) => {
      return <>{val?.leadershipRole?.leadershipRoleName}</>;
    },
    sortable: true,
  },
  {
    colKey: "source",
    colValue: "Source",
    sortable: true,
  },
  {
    colType: "inside_Function",
    colKey: "role_status",
    colValue: "Status",
    sortable: false,
    getTableData: (val) => {
      return (
        <>
          {SkillStatusMenu?.find((el) => el?.value === val?.roleStatus)?.label}
        </>
      );
    },
  },
];

export const Role_Skill_Table_Colmns = [
  {
    colKey: "skillName",
    colValue: "Skill Name",
    sortable: true,
    filterValue: "",
  },
  {
    colKey: "skill_Family",
    colValue: "Skill Family",
    sortable: true,
    filterValue: "",
  },
  {
    colKey: "source",
    colValue: "Source",
    sortable: true,
    filterValue: "",
  },
  {
    colKey: "level",
    labelValue: "levelId",
    colValue: "Industry Proficiency",
    updateValue: "",
    sortable: true,
    filterValue: "",
    select: true,
    dynamic: true,
    width: 210,
    colType: "inside_Function",
    getTableData: (val) => {
      return <>{val.proficiencyLevel.level}</>;
    },
  },
  {
    colKey: "skill_Criticality",
    colValue: "Skill Criticality",
    sortable: true,
    filterValue: "",
    select: true,
    dynamic: true,
    width: 210,
  },
  {
    colKey: "buttonEdit",
    saveBtn: false,
    colValue: "",
    dynamic: false,
    width: 40,
  },
  {
    colKey: "buttonDelete",
    colValue: "",
    dynamic: false,
    width: 40,
  },
];