import React from 'react';
import useRolePersonas from './useRolePersonas';
import RowCount from '../../../Common/Others/RowCount';
import { Role_Persona_Table_Colmns } from './RolePersonasTableColumn';
import CustomizedTables from '../../../Common/CustomizedTables';
import PaginationCustom from '../../../Common/PaginationCustom';
import "./RolePersonas.scss"

export const RolePersonas  = ({ roleId }) => {
  const {
    rolePersonaList,
    rolePersonaOriginDataCopy,
    rowCount,
    currPage,
    changeRowCount,
    handlePagination,
    handleSort,
    handleFilter,
    resetPersonaOriginData,
  } = useRolePersonas({ roleId });

  return (
    <div className='role-persona-section'>
        <div className='role-persona-container'>
            <div className='persona-header'><h3>Personas</h3></div>
            <RowCount
                text={"Personas mapped: "}
                count={rolePersonaOriginDataCopy?.length}
              />
            <CustomizedTables 
                columns={Role_Persona_Table_Colmns}
                rowList={!!rolePersonaList?.length > 0 ? rolePersonaList : []}
                rowListCopy={!!rolePersonaOriginDataCopy?.length > 0 ? rolePersonaOriginDataCopy : []}
                sortList={(sortValue, sortBy) => 
                  handleSort(sortValue, sortBy)
                }
                filterChange={(item, reason, colKey, originDataCopy) => 
                  handleFilter(item, reason, colKey, originDataCopy)
                }
                resetList={resetPersonaOriginData}
            />
            {rolePersonaOriginDataCopy?.length > 10 &&
                <PaginationCustom
                  pageTotal={rolePersonaOriginDataCopy?.length}
                  handlePagination={handlePagination}
                  countRows={rowCount}
                  changeRowCount={changeRowCount}
                  changeCurrentPage={currPage}
                />
            }
        </div>
    </div>
  );
};
