import API from "../api/manager";
import config from "../api/config";
import APIV2 from "../api/managerV2";
import { EventEmitter } from "../helpers/Event";

const getSkillDetail = (skillId, includeProficiencies) => {
  return APIV2(
    {
      method: "GET",
      url: `${config.urls.auth.getSkillV3}/${skillId}/?includeProficiencies=${includeProficiencies}`,
    },
    true
  );
};

const getSkillDetailV3 = (id, bool) => {
  return APIV2(
    {
      method: "GET",
      url: config.urls.auth.getSkillV3 + id,
    },
    true
  );
};

const addSkillDetail = (data) => {
  return API(
    {
      method: "POST",
      url: config.urls.auth.getSkill,
      data,
    },
    true
  );
};

const addSkillDetailV3 = (data) => {
  return APIV2(
    {
      method: "POST",
      url: config.urls.auth.getSkillV3,
      data,
    },
    true
  );
};

const createFamilyV3 = (data) => {
  return APIV2(
    {
      method: "POST",
      url: config.urls.auth.familiesV3,
      data,
    },
    true
  );
};

const updateSkillDetail = (id, data) => {
  return API(
    {
      method: "PATCH",
      url: config.urls.auth.getSkill + id,
      data,
    },
    true
  );
};

const updateSkillDetailV3 = (id, data) => {
  return APIV2(
    {
      method: "PATCH",
      url: config.urls.auth.getSkillV3 + id,
      data,
    },
    true
  );
};

const deleteSkillDetail = (id) => {
  return API(
    {
      method: "DELETE",
      url: config.urls.auth.getSkill + id,
    },
    true
  );
};

const deleteSkillDetailV3 = (id) => {
  return APIV2(
    {
      method: "DELETE",
      url: config.urls.auth.getSkillV3 + id,
    },
    true
  );
};

const getSkillTypesArray = (id, type) => {
  return API(
    {
      method: "GET",
      url: config.urls.auth.getSkill + id + type,
    },
    true
  );
};

const getSkillTypesArrayV3 = (skillId, type) => {
  return APIV2(
    {
      method: "GET",
      url: `${config.urls.auth.getSkillV3}/${skillId}/${type}`,
    },
    true
  );
};

const searchSkill = (data, params = "") => {
  return API(
    {
      method: "POST",
      url: config.urls.auth.getSkill + "/search" + params,
      data,
    },
    true
  );
};

const SearchSkillTree = (searchString) => {
  return API(
    {
      method: "GET",
      url:
        "https://disprztechskillsapi.azurewebsites.net/TechSkills/search/" +
        searchString,
    },
    false
  );
};

const deleteSkillTypeItem = (id, type, itemId) => {
  return API(
    {
      method: "DELETE",
      url: config.urls.auth.getSkill + id + type + itemId,
    },
    true
  );
};

const deleteSkillTypeItemV3 = (id, type, itemId) => {
  return APIV2(
    {
      method: "DELETE",
      url: config.urls.auth.getSkillV3 + id + type + itemId,
    },
    true
  );
};

const updateProficiency = (id, type, itemId, data) => {
  return API(
    {
      method: "PATCH",
      url: config.urls.auth.getSkill + id + type + itemId,
      data,
    },
    true
  );
};

const updateProficiencyV3 = (id, type, itemId, data) => {
  return APIV2(
    {
      method: "PATCH",
      url: config.urls.auth.getSkillV3 + id + type + itemId,
      data,
    },
    true
  );
};

const addProficiency = (id, type, data) => {
  return API(
    {
      method: "POST",
      url: config.urls.auth.getSkill + id + type,
      data,
    },
    true
  );
};

const addProficiencyV3 = (id, type, data) => {
  return APIV2(
    {
      method: "POST",
      url: config.urls.auth.getSkillV3 + id + type,
      data,
    },
    true
  );
};

const getSkillCategories = (mapping = "") => {
  return API(
    {
      method: "GET",
      url: config.urls.auth.skillCategories + mapping,
    },
    true
  );
};

const getSkillCategoriesV3 = (type = "persona") => {
  return APIV2(
    {
      method: "GET",
      url: `${config.urls.auth.skillCategoriesV3}${type !== "persona" ? `?mappingAsFunctional=${type === "industry"}&mappingAsLeadership=${type === "leader"}`: ""}`,
    },
    true
  );
};

const getSkillFamilies = () => {
  return API(
    {
      method: "GET",
      url: config.urls.auth.skillFamilies,
    },
    true
  );
};

const getSkillFamiliesV3 = (id = "") => {
  return APIV2(
    {
      method: "GET",
      url: config.urls.auth.skillFamiliesV3 + id,
    },
    true
  );
};

const getSkillSources = () => {
  return API(
    {
      method: "GET",
      url: config.urls.auth.skillSources,
    },
    true
  );
};

const getSkillSourcesV3 = () => {
  return APIV2(
    {
      method: "GET",
      url: config.urls.auth.getSourcesV3,
    },
    true
  );
};

const getSkillProficiencyLevels = () => {
  return API(
    {
      method: "GET",
      url: config.urls.auth.skillProficiencyLevels,
    },
    true
  );
};

const getSkillProficiencyLevelsV3 = (sourceId) => {
  return APIV2(
    {
      method: "GET",
      url: `${config.urls.auth.sourceV3}/${sourceId}/proficiency-levels`
    },
    true
  );
};

const getSkillContentItems = (
  skillId,
  type,
  pagesize = 10,
  offset,
  clientId = "",
  showIrrelevantCourses = false,
  sort = "",
  filter = ""
) => {
  return API(
    {
      method: "GET",
      url:
        config.urls.auth.getSkill +
        skillId +
        type +
        clientId +
        pagesize +
        offset +
        showIrrelevantCourses +
        sort +
        filter,
    },
    true
  );
};

const getSkillContentItemsV3 = (
  skillId,
  type,
  pagesize = 10,
  offset,
  clientId = "",
  showIrrelevantCourses = false,
  sort = "",
  filter = ""
) => {
  return APIV2(
    {
      method: "GET",
      url:
        config.urls.auth.getSkillV3 +
        skillId +
        type +
        clientId +
        pagesize +
        offset +
        showIrrelevantCourses +
        sort +
        filter,
    },
    true
  );
};

const updateSkillContentItem = (skillId, contentId, relevancy) => {
  return API(
    {
      method: "PATCH",
      url:
        config.urls.auth.getSkill +
        skillId +
        "/contentItems/" +
        contentId +
        `?relevancy=${relevancy}`,
    },
    true
  );
};

const bulkUpdateSkillContentItemV3 = (skillId, data, relevancy) => {
    return APIV2(
        {
            method: "PATCH",
            url: `${config.urls.auth.getSkillV3}${skillId}/contentItems?relevancy=${relevancy}`,
            data
        },
        true
    );
};

const getSuggestedskills = (data) => {
  return API(
    {
      method: "POST",
      url: config.urls.auth.getSuggestedskills,
      data,
    },
    true
  );
};

const getSuggestedskillsV3 = (data) => {
  return APIV2(
    {
      method: "POST",
      url: config.urls.auth.getSuggestedskillsV3,
      data,
    },
    true
  );
};

const addSkillToIndustryRole = (id, type, data) => {
  return API(
    {
      method: "POST",
      url: config.urls.auth.industryRoles + id + type,
      data,
    },
    true
  );
};

const addSkillToIndustryRoleV3 = (id, type, data) => {
  return APIV2(
    {
      method: "POST",
      url: `${config.urls.auth.industryRolesV3}/${id}/${type}`,
      data,
    },
    true
  );
};

const addSkillToLeaderRole = (id, type, data) => {
  return API(
    {
      method: "POST",
      url: config.urls.auth.leadershipRoles + id + type,
      data,
    },
    true
  );
};

const addSkillToLeaderRoleV3 = (id, type, data) => {
  return APIV2(
    {
      method: "POST",
      url: `${config.urls.auth.leadershipRolesV3}/${id}/${type}`,
      data,
    },
    true
  );
};

const getSkillCriticalityList = () => {
  return API(
    {
      method: "GET",
      url: config.urls.auth.skillCriticality,
    },
    true
  );
};

const getSkillCriticalityListV3 = () => {
  return APIV2(
    {
      method: "GET",
      url: config.urls.auth.skillCriticalityV3,
    },
    true
  );
};

//version2

const GetSkillTreeV2 = (id) => {
  return APIV2(
    {
      method: "GET",
      url: config.urls.auth.getSkillTreeV2 + id,
    },
    true
  );
};

const SearchSkillV2 = (data) => {
  return APIV2(
    {
      method: "POST",
      url: config.urls.auth.getSkillV2 + "/search",
      data,
    },
    true
  );
};

const CreateSkillTreeV2 = (data) => {
  return APIV2(
    {
      method: "POST",
      url: config.urls.auth.createSkillTreeV2,
      data,
    },
    true
  );
};

//version3

const SearchSkillV3 = (data, sortBy = "skillName", sortOrder = "asc") => {
  return APIV2(
    {
      method: "POST",
      url: config.urls.auth.getSkillV3 + `/search?sortBy=${sortBy}&sortOrder=${sortOrder}`,
      data,
    },
    true
  );
};

const GetSkillTreeV3 = (id) => {
  return APIV2(
    {
      method: "GET",
      url: config.urls.auth.getSkillTreeV3 + id,
    },
    true
  );
};

const CreateSkillTreeV3 = (parentSkillId, type, childSkillId) => {
  return APIV2(
    {
      method: "POST",
      url:
        config.urls.auth.createSkillTreeV3 +
        parentSkillId +
        type +
        childSkillId,
    },
    true
  );
};

const CreateSkillTreeWithoutIDV3 = (data) => {
  return APIV2(
    {
      method: "POST",
      url: config.urls.auth.createSkillTreeV3,
      data,
    },
    true
  );
};

const DeleteSkillTreeV3 = (parentSkillId, type, childSkillId) => {
  return APIV2(
    {
      method: "DELETE",
      url:
        config.urls.auth.deleteSkillTreeV3 +
        parentSkillId +
        type +
        childSkillId,
    },
    true
  );
};

const GetEncyclopediaTypesV3 = (id) => {
  return APIV2(
    {
      method: "GET",
      url: config.urls.auth.encyclopediaTypesList,
    },
    true
  );
};

const GetEncyclopediaV3 = (id, text, pageParam) => {
  return APIV2(
    {
      method: "GET",
      url:
        config.urls.auth.encyclopediaList + "/" + id + "/" + text + pageParam,
    },
    true
  );
};

const CreateDisprzopediaV3 = (data) => {
  return APIV2(
    {
      method: "POST",
      url: config.urls.auth.disprzopedia,
      data,
      // onUploadProgress: function (progressEvent) {
      //   console.log(
      //     "progressEvent",
      //     progressEvent,
      //     progressEvent.loaded,
      //     progressEvent.total
      //     // progressEvent.currentTarget.onprogress((ev) => ev)
      //   );

      //   // Do whatever you want with the native progress event
      //   let percentCompleted = Math.round(
      //     (progressEvent?.loaded * 100) / progressEvent?.total
      //   );
      //   EventEmitter.dispatch("progress", percentCompleted);
      // },
    },
    true
  );
};

const GetDisprzopediaV3 = (data) => {
  return APIV2(
    {
      method: "GET",
      url: config.urls.auth.disprzopedia + data,
    },
    true
  );
};

const EditDisprzopediaV3 = (id, data) => {
  return APIV2(
    {
      method: "PATCH",
      url: config.urls.auth.disprzopedia + id,
      data,
    },
    true
  );
};

const PostSampleContentDisprzopediaV3 = (id) => {
  return APIV2(
    {
      method: "POST",
      url: config.urls.auth.disprzopediaMappingSample + id,
    },
    true
  );
};

const MappingSampleContentDisprzopediaV3 = (id) => {
  return APIV2(
    {
      method: "GET",
      url: config.urls.auth.disprzopediaMappingSample + id,
    },
    true
  );
};

const MappingAllContentDisprzopediaV3 = (id) => {
  return APIV2(
    {
      method: "POST",
      url: config.urls.auth.disprzopediaAllSample + id,
    },
    true
  );
};

const GetAssessmentsTypeV3 = (assessmentText) => {
  return APIV2(
    {
      method: "GET",
      url: config.urls.auth.assessments + "/" + assessmentText,
    },
    true
  );
};

const AddAssessmentV3 = (skillId, data) => {
  return APIV2(
    {
      method: "POST",
      url: `${config.urls.auth.getSkillV3}/${skillId}/assessments`,
      data,
    },
    true
  );
};

const EditAssessmentV3 = (skillId, data, assessmentId) => {
  return APIV2(
    {
      method: "PATCH",
      url: `${config.urls.auth.getSkillV3}/${skillId}/assessments/${assessmentId}`,
      data,
    },
    true
  );
};

const DeleteAssessmentV3 = (skillId, assessmentId) => {
  return APIV2(
    {
      method: "DELETE",
      url: `${config.urls.auth.getSkillV3}/${skillId}/assessments/${assessmentId}`,
    },
    true
  );
};

const GenerateAssessmentV3 = (skillId) => {
  return APIV2(
    {
      method: "POST",
      url: `${config.urls.auth.getSkillV3}/${skillId}/assessments/generate`,
    },
    true
  );
};

const publishEvaluations = (skillId) => {
  return APIV2(
    {
      method: "POST",
      url: `${config.urls.auth.getSkillV3}/${skillId}/assessments/evaluations/publish`,
    },
    true
  );
}

const ExportAdaptiveAssessmentQuestions = (skillId) => {
  return APIV2(
    {
      method: "POST",
      url: `${config.urls.auth.getSkillV3}/${skillId}/assessments/export`,
    },
    true
  );
};

const GetAiSkillWikiTagsV3 = (data) => {
   return APIV2(
    {
      method: "POST",
      url: config.urls.auth.wikisV3,
      data
    },
    true
   )
};

const GetAiSkillShortDescriptionSuggestionV3 = (data) => {
  return APIV2(
    {
      method: "POST",
      url: config.urls.auth.skillDesctionSuggestionV3,
      data
    },
    true
  )
};

const GetAiSkillDetailedDescriptionSuggestionV3 = (data) => {
  return APIV2(
    {
      method: "POST",
      url: config.urls.auth.skillDetailedDescriptionSuggestionV3,
      data
    },
    true
  )
}

const GetAiSkillLevelDescriptionSuggestionV3 = (data) => {
  return APIV2(
    {
      method: "POST",
      url: config.urls.auth.skillProficiencyLevelDescriptionSuggestionV3,
      data
    },
    true
  )
};

const GetClientSkillDataV3 = (personaId) => {
  return APIV2(
    {
      method: "GET",
      url: `${config.urls.auth.personas}/${personaId}/skill-import`
    },
    true
  )
};

const getClientSkillsSuggestionGenerationStatus = (personaId) => {
  return APIV2(
    {
      method: "GET",
      url:`${config.urls.auth.personas}/${personaId}/skill-import/status`
    }
  );
};

const ImportClientSkillDataV3 = (personaId, data) => {
  return APIV2(
      {
        method: "POST",
        url: `${config.urls.auth.personas}/${personaId}/skill-import`,
        data
      },
      true
  )
};

const PartialSaveSkillDataV3 = (personaId, data) => {
  return APIV2(
    {
      method: "POST",
      url: `${config.urls.auth.personas}/${personaId}/skill-import/partial-save`,
      data
    },
    true
  )
};

const SaveSkillDataV3 = (personaId) => {
  return APIV2(
    {
      method: "POST",
      url: `${config.urls.auth.personas}/${personaId}/skill-import/complete`
    },
    true
  )
};

const AddSkillsToSkillListV3 = (personaId, data) => {
  return APIV2(
    {
      method: "POST",
      url: `${config.urls.auth.personas}/${personaId}/skill-mapping`,
      data
    },
    true
  )
};

const DeleteSkillFromSkillListV3 = (personaId, skillId, isIndustryRoleSkill) => {
  return APIV2(
    {
      method: "DELETE",
      url: `${config.urls.auth.personas}/${personaId}/skill-mapping?skillId=${skillId}&isIndustryRoleSkill=${isIndustryRoleSkill}`,
    },
    true
  )
}

const getSkillMappingTemplateUrlV3 = () => {
  return APIV2(
    {
    method: "GET",
    url: config.urls.auth.skillMappingTemplateV3
    },
    true
  )
}

const DeleteSkillProficiencyV3 = (personaId, proficiencyId ) => {
  return APIV2(
    {
      method: "DELETE",
      url: `${config.urls.auth.personas}/${personaId}/proficiency/${proficiencyId}`,
    },
    true
  )
}

const exportSkillDetailsV3 = (clientId, personaId, industryRoleId) => {
  return APIV2(
    {
      method: "GET",
      url: `${config.urls.auth.exportSkillDetailV3}/export?personaId=${personaId}&clientId=${clientId}&industryRoleId=${industryRoleId}`,
      responseType: 'blob'
    },
    true
  )
}

const newClientSkillDataV3 = (data) => {
   return APIV2(
     {
       method: "POST",
       url: `${config.urls.auth.newClientSkillDataV3}`,
       data
     },
     true
   )
}

const checkSkillExistsV3 = (name, excludeSkillId) => {
  const params = new URLSearchParams({ name, excludeSkillId });
  return APIV2(
    {
      method: "GET",
      url: `${config.urls.auth.checkSkillExistsV3}?${params.toString()}`
    },
    true
  );
}

const hasEvaluationAssessment = (skillId, proficiencyId) => {
  var url = `${config.urls.auth.hasEvaluationAssessmentV3.replace("{skillId}", skillId).replace("{proficiencyId}", proficiencyId)}`
  return APIV2({
      method: "GET",
      url: url
    },
    true
  );
}

export const SkillService = {
  getSkillDetail,
  getSkillDetailV3,
  addSkillDetail,
  addSkillDetailV3,
  createFamilyV3,
  updateSkillDetail,
  updateSkillDetailV3,
  getSkillTypesArray,
  getSkillTypesArrayV3,
  updateSkillContentItem,
  bulkUpdateSkillContentItemV3,
  searchSkill,
  deleteSkillTypeItem,
  deleteSkillTypeItemV3,
  updateProficiency,
  updateProficiencyV3,
  getSkillCategories,
  getSkillCategoriesV3,
  getSkillFamilies,
  getSkillFamiliesV3,
  getSkillSources,
  getSkillSourcesV3,
  deleteSkillDetail,
  deleteSkillDetailV3,
  getSkillProficiencyLevels,
  getSkillProficiencyLevelsV3,
  addProficiency,
  addProficiencyV3,
  getSuggestedskills,
  getSuggestedskillsV3,
  addSkillToIndustryRole,
  addSkillToIndustryRoleV3,
  addSkillToLeaderRole,
  addSkillToLeaderRoleV3,
  getSkillContentItems,
  getSkillContentItemsV3,
  getSkillCriticalityList,
  getSkillCriticalityListV3,
  SearchSkillTree,
  SearchSkillV2,
  GetSkillTreeV3,
  CreateSkillTreeV2,
  CreateSkillTreeV3,
  CreateSkillTreeWithoutIDV3,
  GetSkillTreeV2,
  DeleteSkillTreeV3,
  SearchSkillV3,
  GetEncyclopediaTypesV3,
  GetEncyclopediaV3,
  CreateDisprzopediaV3,
  GetDisprzopediaV3,
  EditDisprzopediaV3,
  PostSampleContentDisprzopediaV3,
  MappingSampleContentDisprzopediaV3,
  MappingAllContentDisprzopediaV3,
  GetAssessmentsTypeV3,
  AddAssessmentV3,
  EditAssessmentV3,
  DeleteAssessmentV3,
  GenerateAssessmentV3,
  ExportAdaptiveAssessmentQuestions,
  GetClientSkillDataV3,
  ImportClientSkillDataV3,
  PartialSaveSkillDataV3,
  SaveSkillDataV3,
  GetAiSkillWikiTagsV3,
  GetAiSkillShortDescriptionSuggestionV3,
  GetAiSkillDetailedDescriptionSuggestionV3,
  GetAiSkillLevelDescriptionSuggestionV3,
  AddSkillsToSkillListV3,
  DeleteSkillFromSkillListV3,
  getSkillMappingTemplateUrlV3,
  DeleteSkillProficiencyV3,
  exportSkillDetailsV3,
  newClientSkillDataV3,
  checkSkillExistsV3,
  getClientSkillsSuggestionGenerationStatus,
  publishEvaluations,
  hasEvaluationAssessment
};
