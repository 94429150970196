// import * as Yup from "yup";
import * as Yup from "yup";

const patterns = {
  domain: /^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/,
  punycode: /^([A-Za-z0-9](?:(?:[-A-Za-z0-9]){0,61}[A-Za-z0-9])?(?:\.[A-Za-z0-9](?:(?:[-A-Za-z0-9]){0,61}[A-Za-z0-9])?)*)(\.?)$/,
  cyrillicDomain: /^((http|https):\/\/)?[a-zа-я0-9]+([\-\.]{1}[a-zа-я0-9]+)*\.[a-zа-я]{2,5}(:[0-9]{1,5})?(\/.*)?$/i,
};

Yup.addMethod(Yup.string, "domain", function pattern(name, message = "") {
  const domainRules = [
    patterns.domain,
    patterns.punycode,
    patterns.cyrillicDomain,
  ];

  return this.test({
    message,
    test: (value) =>
      value === null ||
      value === "" ||
      value === undefined ||
      domainRules.some((regex) => regex.test(value)),
  });
});

export const signUpValidationSchema = Yup.object({
  name: Yup.string().required("User name is required"),
  email: Yup.string()
    .email()
    .matches(
      /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9!@#$%&*.]{7,}$/,
      "Please enter a valid email with alphanumeric and special characters"
    )
    .required("Email is required"),
  password: Yup.string()
    .matches(
      /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9!@#$%&*.]{7,}$/,
      "Password be min 7 characters with alphanumeric and special characters"
    )
    .required("Password is required"),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref("password"), null], "passwords must match")
    .required("Confirm Password is required"),
});

export const loginValidationSchema = Yup.object({
  loginId: Yup.string().required("User name is required"),
  password: Yup.string().required("Password is required"),
});

export const dummyloginValidationSchema = Yup.object({
  name: Yup.string().required("User name is required"),
  password: Yup.string().required("Password is required"),
});

export const userManagementValidation = Yup.object({
  name: Yup.string().required("Display name is required").nullable(),
  email: Yup.string()
    .email("Enter valid mail ID")
    .required("Email ID is required"),
  userTypeId: Yup.number().required("User Type is required"),
  ssoTypeId: Yup.number().required("User Login Type is required"),
  loginId: Yup.string().when("ssoTypeId", {
    is: (val) => val == "1",
    then: Yup.string().required("Login name is required"),
    otherwise: Yup.string().nullable(),
  }),
  password: Yup.string().when("ssoTypeId", {
    is: (val) => val == "1",
    then: Yup.string().required("Password is required"),
    otherwise: Yup.string().nullable(),
  }),
});

export const userManagementValidationApplication = Yup.object({
  name: Yup.string().required("Display name is required").nullable(),
  email: Yup.string()
    .email("Enter valid mail ID")
    .required("Email ID is required"),
  userTypeId: Yup.number().required("User Type is required"),
  ssoTypeId: Yup.number().required("User Lgoin Type is required"),
});

// const initValue = {
//   name: editData ? detailData.name : "",
//   email: editData ? detailData.email : "",
//   userTypeId: editData ? String(detailData.userType.userTypeId) : "1",
//   ssoTypeId: editData ? String(detailData.userSSOType.ssoTypeId) : "1",
//   loginId: "",
//   password: "",
// };

// {
//   "clientName": "string",
//   "clientDomain": "string",
//   "subSectorIdList": [
//     0
//   ],
//   "contentProviderIdList": [
//     0
//   ],
//   "orgGrowthStageId": 0,
//   "disprzInstanceName": "string",
//   "region": 0
// }

export const clientManagementValidation = Yup.object().shape(
  {
    clientName: Yup.string().required("Client name is required").nullable(),
    clientDomain: Yup.string(),
    disprzInstanceName: Yup.string().when("region", {
      is: (val) => val != "" && val != null,
      then: Yup.string().required("Disprz Instance Name is required"),
      otherwise: Yup.string().nullable(),
    }),
    region: Yup.string().when("disprzInstanceName", {
      is: (val) => val != "" && val != null,
      then: Yup.string().required("region is required").nullable(),
      otherwise: Yup.string().nullable(),
    }),
  },
  [["disprzInstanceName", "region"]]
);
